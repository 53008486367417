/* eslint-disable prefer-const */
/* eslint-disable camelcase */
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack
} from '@mui/material';
import PropTypes from 'prop-types';
import { Add, ExpandLess, ExpandMore } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-cycle
import { StyledListItemButton } from '../UserProfileNav';
// eslint-disable-next-line import/no-cycle
import ProjectListItem from '../Components/ProjectListItem/ProjectListItem';
import UserProfileStore from '../../../store/UserProfile';
import MyProjectsIcon from '../../../assets/svgs/UserMenu/MyProjectsIcon';

const ProjectsList = ({ handleClick, handleCreateProject, projects }) => {
  const { profileDispatch } = useContext(UserProfileStore);

  const [projectsList, setProjectsList] = useState([]);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setProjectsList(projects);
    if (projects) {
      const projectObject = {};
      const descriptionObject = {};
      projects.forEach(project => {
        let { id, project_id = 0, name, description } = project;
        if (project_id !== 0) {
          id = project_id;
        }
        projectObject[id] = name;
        descriptionObject[id] = description;
      });
      profileDispatch({ type: 'SET_PROJECT_NAME', value: projectObject });
      profileDispatch({ type: 'SET_PROJECT_DESCRIPTION', value: descriptionObject });
    }
  }, [projects]);
  useEffect(() => {
    if (location.pathname === '/account/project' && projectsList?.length !== 0) {
      history.push(`/account/project/${projectsList[0].id}`);
    }
  }, [location.pathname, projectsList]);
  return (
    <Box sx={{ backgroundColor: open === true ? 'white.aliceBlue' : '', borderRadius: '16px' }}>
      <ListItem
        secondaryAction={
          open ? (
            <IconButton onClick={() => setOpen(false)}>
              <ExpandLess />
            </IconButton>
          ) : (
            <Stack direction='row' justifyContent='flex-end'>
              <IconButton onClick={() => setOpen(true)}>
                <ExpandMore />
              </IconButton>
              <IconButton onClick={handleCreateProject}>
                <Add />
              </IconButton>
            </Stack>
          )
        }
        disablePadding>
        <StyledListItemButton
          selected={location.pathname.match('project')}
          onClick={() => setOpen(!open)}>
          <ListItemIcon>
            <MyProjectsIcon sx={{ fontSize: 16 }} />
          </ListItemIcon>
          <ListItemText primary='My projects' />
        </StyledListItemButton>
      </ListItem>
      <Collapse in={open} timeout='auto'>
        <List component='div' disablePadding sx={{ maxHeight: 210, overflow: 'auto' }}>
          {projectsList?.map(project => {
            let { id, project_id = 0 } = project;
            if (project_id !== 0) id = project_id;
            return (
              <ProjectListItem
                key={`${id}-project-id`}
                project={{ ...project, id }}
                projectsList={projectsList}
                loading={loading}
                handleClick={handleClick}
                setLoading={setLoading}
                setProjectsList={setProjectsList}
              />
            );
          })}
        </List>
        <List component='div' disablePadding>
          <StyledListItemButton disabled={loading} onClick={handleCreateProject} sx={{ pl: 9 }}>
            <Add sx={{ fontSize: 10, mr: 1 }} />
            {loading && <CircularProgress size={20} sx={{ position: 'absolute' }} />}
            <ListItemText primary='New project' />
          </StyledListItemButton>
        </List>
      </Collapse>
    </Box>
  );
};

ProjectsList.propTypes = {
  handleCreateProject: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projects: PropTypes.array.isRequired
};
export default ProjectsList;
