import React from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';

const NameConfirmationDialog = ({
  name,
  description,
  onNameChange,
  onDescriptionChange,
  onSave,
  onClose,
  open,
  buttonText
}: any) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{`File name is "Untitled". Do you want to update it?`}</DialogTitle>
      <DialogContent>
        <TextField
          label='Title'
          variant='outlined'
          fullWidth
          value={name}
          onChange={onNameChange}
          margin='dense'
        />
        <TextField
          label='Description'
          multiline
          variant='outlined'
          fullWidth
          value={description}
          onChange={onDescriptionChange}
          margin='dense'
        />
      </DialogContent>
      <Box display='flex' width='100%' justifyContent='center' p={2}>
        <Button variant='contained' onClick={onSave} sx={{ color: 'white.main' }}>
          {buttonText}
        </Button>
      </Box>
    </Dialog>
  );
};

export default React.memo(NameConfirmationDialog);
