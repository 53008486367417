const downloadLabelFile = (blob: Blob, fileType: string) => {
  try {
    const url = window.URL.createObjectURL(
      new Blob([blob], {
        type:
          fileType === 'html'
            ? 'text/html'
            : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      })
    );

    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download =
      fileType === 'html' ? 'Latest-Label-Comparison.html' : 'Collated-summary.docx';

    // Append the download link to the DOM
    document.body.appendChild(downloadLink);

    // Optionally, simulate a click on the link to trigger the download
    downloadLink.click();

    setTimeout(() => {
      document.body.removeChild(downloadLink);
    }, 1000);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export default downloadLabelFile;
