import React, { useContext, useEffect, useState } from 'react';
import { Box, Fab } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Slide from '@mui/material/Slide';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import PageviewIcon from '@mui/icons-material/Pageview';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { DeleteRounded } from '@mui/icons-material';
import Actions from '../../../store/actions';
import GlobalStore from '../../../store';
import {
  chipStyle,
  ContainerStyle,
  FAB,
  optionsContainerStyle,
  badgeStyle,
  exitButton,
  buttonIcon
} from '../styles/CompareView.styles';
import PDFComparisonDialog from '../../../components/Comparison/PDFComparisonDialog';
import ResultsAction from '../../../store/SearchResults/actions';
import ComparisonDialogComp, {
  ModeOfComparisonDialog
} from '../../../components/Comparison/ComparisonDialog';
import ResultsStore from '../../../store/SearchResults';
import { cnfToAdvancedSearch } from '../../../components/Header/utils/advanceSearchHelpers';
import { getPdfResource } from '../../../api/pages/ResultsPage';
import { COMPARE_LABEL_TYPES } from '../../SearchResults/constants';
import { ViewLabelsIcon } from '../../../assets/svgs/Icons';

interface CompareViewProps {
  applications: any[];
  // eslint-disable-next-line no-unused-vars
  removeFromComparison: (id: string) => void;
  clearComparison: () => void;
  exitCompareMode: () => void;
  compareMode: boolean;
  feature: string;
  sources: any;
}

// no-unused-vars
let tooltipTimer: ReturnType<typeof setTimeout>;
const mode: ModeOfComparisonDialog = {
  child_labelList_dependent_on_parent: false,
  section_binding: false,
  allsections: true,
  default_section_all_section: true
};
const CompareView = ({
  compareMode,
  clearComparison,
  exitCompareMode,
  applications,
  removeFromComparison,
  feature = '',
  sources = {}
}: CompareViewProps) => {
  const { dispatch } = useContext(GlobalStore) as any;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { resultsState, resultsDispatch } = useContext(ResultsStore);

  const [showSelections, setShowSelections] = useState<boolean>(false);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const [applicationsLength, setApplicationsLength] = useState<number>(0);
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState<string[]>(['']);

  const [applicationData, setApplicationData] = useState<any[]>([]);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen
  };

  useEffect(() => {
    setApplicationData(applications);
  }, [applications]);

  const fetchS3url = async (path: string, bucket: string) => {
    const record: any = {};
    record.s3_path = decodeURIComponent(path);
    record.s3_bucket = bucket;
    try {
      const response = await getPdfResource({
        ...record
      });
      if (response?.data?.status === 200) {
        return response?.data?.body.s3_url;
      }
      return '';
    } catch (error) {
      return '';
    }
  };

  const gets3Link = async () => {
    try {
      const applData = [...applicationData];
      await Promise.all(
        applData.map(async data => {
          // eslint-disable-next-line no-param-reassign
          data.url = await fetchS3url(data.s3_label_url, data.s3_bucket);
        })
      );

      setApplicationData(applData);
      setOpenDialog(!openDialog);
    } catch (error) {
      console.error('Error fetching S3 URLs:', error);
    }
  };

  const openComparisonPopup = async () => {
    if (!openDialog) {
      dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'Please wait while we prepare the comparison',
          duration: 3000,
          color: 'warning'
        }
      });
      await gets3Link();
    } else {
      setOpenDialog(!openDialog);
    }
  };

  useEffect(() => {
    if (compareMode) {
      if (applicationData.length > 1 && applicationsLength !== applicationData.length) {
        if (tooltipTimer) clearTimeout(tooltipTimer);
        tooltipTimer = setTimeout(() => {
          setOpenTooltip(false);
        }, 3000);
        setOpenTooltip(true);
      }
      setApplicationsLength(applicationData.length);
    }
  }, [compareMode, applicationData]);
  useEffect(() => {
    if (resultsState?.decryptedPayload) {
      let term = [];
      if (resultsState?.decryptedPayload?.searchType === 'advanced') {
        term =
          cnfToAdvancedSearch(resultsState?.decryptedPayload?.query)?.advanceSearchRowData?.map(
            (item: any) => item?.query
          ) ?? [];
      } else {
        term = [resultsState?.decryptedPayload?.query];
      }
      setSearchTerm(term);
    }
  }, [resultsState?.decryptedPayload]);

  if (!compareMode) return null;

  const FABHtml = (
    <Tooltip
      title={
        resultsState?.labelComparisonSelectionMethod?.value === COMPARE_LABEL_TYPES.VIEW
          ? 'View up to 15 labels side-by-side'
          : 'Compare 2 labels'
      }
      arrow
      placement='left'
      open={openTooltip}>
      <Fab
        sx={{
          ...FAB
        }}
        aria-label='compare-label'
        onClick={() => {
          if (applicationData.length > 1) {
            openComparisonPopup();
          } else {
            dispatch({
              type: Actions.SET_ALERT,
              value: { status: true, message: 'Please select at least 2 labels', duration: 3000 }
            });
          }
        }}
        onMouseEnter={() => {
          setShowSelections(true);
        }}
        variant='extended'>
        <Badge badgeContent={applicationData.length} color='error' showZero sx={{ ...badgeStyle }}>
          {resultsState?.labelComparisonSelectionMethod?.value === COMPARE_LABEL_TYPES.VIEW ? (
            <ViewLabelsIcon sx={buttonIcon} />
          ) : (
            <PageviewIcon sx={buttonIcon} />
          )}
        </Badge>
        <Box ml={0.5}>
          {resultsState?.labelComparisonSelectionMethod?.value === COMPARE_LABEL_TYPES.VIEW
            ? `View Labels`
            : 'Compare Labels'}
        </Box>
      </Fab>
    </Tooltip>
  );

  return (
    <>
      <Box sx={ContainerStyle}>
        <Box
          onMouseLeave={() => {
            setShowSelections(false);
          }}>
          <Zoom
            in={compareMode}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${compareMode ? transitionDuration.exit : 0}ms`
            }}
            unmountOnExit>
            {FABHtml}
          </Zoom>

          <Box sx={{ position: 'relative', width: '100%' }}>
            <Box sx={optionsContainerStyle}>
              {applicationData.map((item, index) => (
                <Slide
                  in={showSelections}
                  style={{ transformOrigin: '0 0 0' }}
                  key={item.identifier}
                  unmountOnExit
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(showSelections ? { timeout: transitionDuration.enter * index } : {})}
                  direction='up'>
                  <Chip
                    icon={
                      applicationsLength > 2 ? (
                        <PictureAsPdfIcon sx={{ '&&': { color: item.color } }} />
                      ) : (
                        <FindInPageIcon sx={{ '&&': { color: item.color } }} />
                      )
                    }
                    label={showSelections ? item.title : item?.identifier}
                    sx={chipStyle}
                    onDelete={() => {
                      removeFromComparison(item.identifier);
                    }}
                  />
                </Slide>
              ))}
              {applicationsLength > 0 && (
                <Slide
                  in={showSelections}
                  style={{ transformOrigin: '0 0 0' }}
                  unmountOnExit
                  mountOnEnter
                  timeout={transitionDuration.enter}
                  direction='up'>
                  <Chip
                    label='Clear All'
                    onClick={clearComparison}
                    clickable={false}
                    sx={{
                      ...chipStyle,
                      cursor: 'pointer'
                    }}
                    onDelete={clearComparison}
                    deleteIcon={<DeleteRounded />}
                    variant='outlined'
                  />
                </Slide>
              )}
            </Box>
          </Box>
        </Box>
        {feature !== 'comparsion' && (
          <Button
            variant='outlined'
            type='submit'
            sx={{
              ...exitButton
            }}
            onClick={exitCompareMode}>
            Exit Mode
          </Button>
        )}
      </Box>
      {openDialog &&
      applicationData.length >= 2 &&
      resultsState?.labelComparisonSelectionMethod?.value === COMPARE_LABEL_TYPES.VIEW ? (
        <PDFComparisonDialog
          sources={sources}
          open={openDialog}
          keywords={searchTerm}
          pdfData={applicationData}
          onSwap={({ message, currentIndex, targetIndex }: any) => {
            dispatch({
              type: Actions.SET_ALERT,
              value: { status: true, message, color: 'success', duration: 4000 }
            });
            const comparisonApplicationsNewSeq = [...applicationData];
            const temp = comparisonApplicationsNewSeq[currentIndex];
            comparisonApplicationsNewSeq[currentIndex] = comparisonApplicationsNewSeq[targetIndex];
            comparisonApplicationsNewSeq[targetIndex] = temp;
            resultsDispatch({
              type: ResultsAction.SET_COMPARISON_APPLICATIONS,
              value: comparisonApplicationsNewSeq
            });
          }}
          onClose={openComparisonPopup}
        />
      ) : (
        openDialog &&
        applicationData.length === 2 && (
          <ComparisonDialogComp
            open={openDialog}
            onClose={openComparisonPopup}
            applications={applicationData.map(item => ({
              source: item?.source,
              application_type: item.applicationType ?? '',
              application_number: item.identifier,
              application_name: item?.title
            }))}
            mode={mode}
          />
        )
      )}
    </>
  );
};

export default React.memo(CompareView);
