import React from 'react';

import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Drawer,
  Container,
  Divider
} from '@mui/material';

import { CrossIcon } from '../../../assets/svgs/Icons';

import AdministrativeSummary from './AdministrativeSummary';
import SubmissionsSummary from './SubmissionSummary';
import ClinicalSummary from './ClinicalSummary';

import styles from './styles';

interface SnapshotLayoutProps {
  dataItem: any;
  onclose: () => void;
  modalOpen: boolean;
}

const SnapshotLayout: React.FC<SnapshotLayoutProps> = ({ dataItem, onclose, modalOpen }) => {
  return (
    <Drawer sx={styles.drawerContainer} anchor='bottom' onClose={onclose} open={!!modalOpen}>
      <Stack sx={{ bgcolor: 'white.main' }}>
        <Stack
          direction='row'
          spacing={2}
          p={3}
          justifyContent='space-between'
          useFlexGap
          sx={{ position: 'sticky' }}>
          <div />
          <Stack spacing={1} alignItems='center'>
            <Typography sx={styles.snapshotTitle}>Snapshot</Typography>
            <Divider sx={styles.divider} />
          </Stack>
          <Stack>
            <Tooltip title='Close'>
              <IconButton onClick={onclose}>
                <CrossIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Divider />
        <Box sx={{ bgcolor: 'gray.100' }}>
          <Container maxWidth='lg-xl'>
            <Grid container sx={styles.gridContainer}>
              <Grid item xs={5}>
                <Stack spacing={2} p={1} mb={4}>
                  {/* administrative summary */}
                  <AdministrativeSummary dataItem={dataItem} />
                  {/* submission summary */}
                  <SubmissionsSummary dataItem={dataItem} />
                </Stack>
              </Grid>
              <Grid item xs={7}>
                <Stack spacing={2} p={1} mb={4}>
                  <ClinicalSummary dataItem={dataItem} />
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Stack>
    </Drawer>
  );
};

export default SnapshotLayout;
