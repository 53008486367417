import { useCallback, useContext, useMemo, useState } from 'react';
import { debounce } from 'lodash';

// Imports of API.
import { getAutoComplete } from '../../../api/modules/SearchAutocomplete';

// Store for module
import Store from '../../../store';

const useSearchSuggestions = (selectedSource, selectedCategory) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchSuggestions, setSearchSuggesions] = useState([]);

  const { state } = useContext(Store);

  const isSuggestionsLoading = useMemo(
    () => isSearchOpen && searchSuggestions.length === 0,
    [isSearchOpen, searchSuggestions]
  );

  const clearSearchSuggestions = useCallback(() => {
    setIsSearchOpen(false);
    setSearchSuggesions([]);
  }, []);

  const handleGetSuggestions = useCallback(
    async event => {
      setSearchSuggesions([]);

      let searchResult = [];
      if (event.target.value && event.target.value.length >= 2) {
        try {
          const res = await getAutoComplete(
            event.target.value,
            'core',
            selectedSource?.autoCompleteAPIParam || 'fda',
            selectedCategory.autoCompleteAPIParam
          );
          const searchResultResponse = res.data.success;

          searchResult = searchResultResponse.map(element => element.title);

          setSearchSuggesions(searchResult);
        } catch (error) {
          /* empty */
        }
      } else {
        setSearchSuggesions(['Enter 2 or more letters']);
      }
    },
    [selectedSource, selectedCategory, state.module]
  );

  const handleKeyPress = useMemo(() => debounce(handleGetSuggestions, 175), [handleGetSuggestions]);

  return {
    searchSuggestions,
    isSearchOpen,
    isSuggestionsLoading,
    setIsSearchOpen,
    handleKeyPress,
    clearSearchSuggestions
  };
};

export default useSearchSuggestions;
