import React, { useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { getOptionLabel } from '../../../components/Header/utils/advanceSearchHelpers';
import styles from '../styles/SearchBar.styles';
import { CaretDownIcon } from '../../../assets/svgs/Icons';
import { NESTED_LABEL_MAPPING } from '../const';

const AdvanceSearchTitleText = ({ title }: { title: string }) => {
  const [list, setList] = useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [anchorSubsectionEl, setAnchorSubsectionEl] = React.useState<HTMLElement | null>(null);
  if (title) {
    let category = '';
    let searchTerm = '';
    const subCategory: Array<any> = [];
    let subCategoryValue = '';
    const queryList = title.split(':');
    if (queryList.length === 3) {
      [category, subCategoryValue, searchTerm] = queryList;
    } else {
      [category, searchTerm] = queryList;
    }
    if (searchTerm.startsWith('"') && searchTerm.endsWith('"')) {
      searchTerm = searchTerm.slice(1, -1);
    }

    if (category.startsWith('~')) {
      category = category.slice(1);
    }
    if (subCategoryValue) {
      const subCategoryList = subCategoryValue.slice(1, -1).split(',');

      // replace nested value with parent value like if limitation_of_use replace with indication_and_usage
      subCategoryList.forEach((item: string) => {
        if (item) {
          let allPresent = false;
          let parentId = '';
          NESTED_LABEL_MAPPING.forEach(itemValue => {
            const { parent, child } = itemValue;
            allPresent = child.some(c => c.toLowerCase().trim() === item.toLowerCase().trim());
            parentId = parent;
          });
          if (allPresent) {
            const indexOfItem = subCategory.findIndex((i: any) => i.id === parentId);
            if (indexOfItem !== -1) {
              subCategory[indexOfItem]?.section?.push({
                id: item.trim(),
                label: getOptionLabel(item.trim())
              });
            } else {
              subCategory.push({
                id: parentId.trim(),
                label: getOptionLabel(parentId.trim()),
                section: [
                  {
                    id: item,
                    label: getOptionLabel(item.trim())
                  }
                ]
              });
            }
          } else {
            subCategory.push({
              id: item.trim(),
              label: getOptionLabel(item.trim()),
              section: []
            });
          }
        }
      });
    }
    if (category === 'label_section_search' && !subCategoryValue) {
      subCategory.push({
        id: 'all_label_sections',
        label: 'All Label Sections'
      });
    }

    const handleHover = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setList(subCategory);
    };

    return (
      <Typography sx={styles.titleContainer}>
        <span>
          &nbsp;
          {subCategory.length !== 0 &&
          !subCategory.some((item: any) => item?.id?.toLowerCase() === 'all label sections')
            ? '(Label Sections'
            : '(All Label Sections'}
        </span>
        &nbsp;
        {subCategory.length !== 0 &&
          !subCategory.some((item: any) => item?.id?.toLowerCase() === 'all label sections') && (
            <Box
              onMouseEnter={(event: React.MouseEvent<HTMLElement>) => {
                handleHover(event);
              }}
              onMouseLeave={() => setList([])}>
              <Typography sx={styles.categorytext}>{`(${subCategory.length})`}</Typography>
              {list.length !== 0 && (
                <Menu anchorEl={anchorEl} open={list.length !== 0} onClose={() => setList([])}>
                  <MenuItem disabled>
                    <Typography sx={styles.textFont}>Selected Label Sections:</Typography>
                  </MenuItem>
                  {list?.map((section: any) => (
                    <MenuItem key={section.id} sx={styles.menuItem}>
                      <Typography sx={styles.textFont}>{section.label}</Typography>

                      {section?.section && section?.section.length !== 0 && (
                        <IconButton
                          sx={styles.icon}
                          onClick={event => {
                            event.stopPropagation();
                            setAnchorSubsectionEl(anchorSubsectionEl ? null : event.currentTarget);
                          }}>
                          <CaretDownIcon sx={styles.rightarrow} />
                        </IconButton>
                      )}
                      {section?.section && section?.section.length !== 0 && (
                        <Menu
                          anchorEl={anchorSubsectionEl}
                          open={Boolean(anchorSubsectionEl)}
                          onClose={() => setAnchorSubsectionEl(null)}>
                          {section?.section?.map((subsection: any) => (
                            <MenuItem key={subsection.id} sx={styles.sectionList}>
                              <Typography sx={styles.textFont}>{subsection.label}</Typography>
                            </MenuItem>
                          ))}
                        </Menu>
                      )}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </Box>
          )}
        <span>:&nbsp;{`${searchTerm}`}&nbsp; </span>
      </Typography>
    );
  }
  return null;
};

export default React.memo(AdvanceSearchTitleText);
