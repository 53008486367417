import { Typography, Box, IconButton, Badge, Tooltip } from '@mui/material';
import { badgeStyle } from '../../../SpecialityDesignation/styles/horizontalDesignationCardStyle';
import { SEARCH_TERM_COLOR } from './constant';
import { apiDataToCardProps } from '../../constants';
import styles, { plusStyle } from './styles';

const SearchTermBadge = ({
  application,
  year,
  searchTermList,
  activeBadge,
  isExpandedCardOpen,
  setIsExpandedCardOpen,
  setActiveBadge,
  setExpandedPayload
}: {
  application: any;
  year: string;
  searchTermList: any;
  activeBadge: string;
  isExpandedCardOpen: boolean;
  setIsExpandedCardOpen: any;
  setActiveBadge: any;
  setExpandedPayload: any;
}) => {
  return (
    <Box className='plus' sx={plusStyle}>
      <Box sx={styles.badgeContainer}>
        {searchTermList.map((term: string, index: number) => {
          const applicationByYear: any = application;
          const sourceApps = apiDataToCardProps[application?.source]?.(application);
          const isTypePresent = applicationByYear?.matched_submissions?.some(
            (e: any) => e.term === term
          );
          const submissionData = applicationByYear?.matched_submissions?.filter(
            (e: any) => e.term === term
          );
          const count = submissionData;
          const badgeKey = `${applicationByYear?.id}-${year}-${term}`;
          const isSelected = activeBadge === badgeKey && isExpandedCardOpen;
          if (isTypePresent) {
            return (
              <Badge badgeContent={count > 1 ? count : 0} sx={badgeStyle} key={badgeKey}>
                <Tooltip key={applicationByYear?.id} title={term}>
                  <IconButton
                    className='demo'
                    sx={{
                      height: 30,
                      backgroundColor: SEARCH_TERM_COLOR[index],
                      p: '0px 8px',
                      borderRadius: '33px',
                      boxShadow: isSelected ? 3 : 0,
                      transform: isSelected ? 'scale(1.3)' : 'none',
                      '&:hover': {
                        backgroundColor: SEARCH_TERM_COLOR[index],
                        cursor: 'pointer',
                        boxShadow: 1,
                        transform: 'scale(1.1)'
                      }
                    }}
                    onClick={() => {
                      setTimeout(() => {
                        setIsExpandedCardOpen(true);
                      }, 200);
                      setActiveBadge(badgeKey);
                      setExpandedPayload({
                        ...sourceApps,
                        submissionData
                      });
                    }}>
                    <Typography variant='body2' sx={styles.searchTerm}>
                      {term.toUpperCase().substring(0, 3)}
                    </Typography>
                  </IconButton>
                </Tooltip>
              </Badge>
            );
          }
          return null;
        })}
      </Box>
    </Box>
  );
};

export default SearchTermBadge;
