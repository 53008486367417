import Stack from '@mui/material/Stack';
import { reportQuestionDeleteButtons, reportQuestionDeleteContainer } from '../styles/styles';
import Typography from '@mui/material/Typography';
import { Box, Button, Grow } from '@mui/material';
import React from 'react';

interface CloseInputProps {
  closeSession: () => Promise<void>;
  handleMinimizeChat: () => void;
  closeAction: boolean;
  setConfirmDialog: (value: boolean) => void;
}

const CloseOptions = ({
  closeSession,
  handleMinimizeChat,
  closeAction,
  setConfirmDialog
}: CloseInputProps) => {
  return (
    <>
      <Grow in={closeAction} unmountOnExit>
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={reportQuestionDeleteContainer}
        >
          <Stack
            sx={{ background: 'white', p: 3, boxShadow: '-0.1em 0.1em 1em #33b1874a' }}
            justifyContent='center'
            alignItems='center'
          >
            <Typography variant='subtitle1' fontWeight='bold' component={Box}>
              Do you want to close the chat / minimize the chat window?
            </Typography>
            <Stack direction='row' mt={2}>
              <Button
                onClick={async () => {
                  await closeSession();
                  setConfirmDialog(false);
                }}
                sx={reportQuestionDeleteButtons}
                size='small'
              >
                Close
              </Button>
              <Button
                onClick={async () => {
                  await handleMinimizeChat();
                  setConfirmDialog(false);
                }}
                sx={reportQuestionDeleteButtons}
                size='small'
              >
                Minimize
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Grow>
    </>
  );
};
export default React.memo(CloseOptions);
