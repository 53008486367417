import React, { useCallback, useContext } from 'react';

// mui
import uniqueId from 'lodash/uniqueId';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

// component
import StatsCountTile from '../../../helpers/baseComponents/StatsCountTile';
import CustomBarGraph from '../../../components/CustomComponents/Graphs/CustomBarGraph';
import { VivproSelect } from '../../../components/CustomComponents/Selects';

// utils
import formatCount from '../../../utils/formatCount';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import { ADCOMM_REPORT_QUERIES, MEETING_TABS } from '../constant';
// styles
import styles from '../styles/homePage.styles';
import CustomPieChart from '../../../components/CustomComponents/Graphs/CustomPieChart';
import { PIECHARTCOLOR } from '../../../components/CustomComponents/Graphs/constant';

const Graphs = ({ graphData, meetingStatus, handleMeetingTabChange, applyFilter }: any) => {
  const { state, dispatch } = useContext<any>(GlobalStore);
  const DOCUMENTS_MINIMUM_TOOLTIP_LIMIT = 10000;
  const PAGES_MINIMUM_TOOLTIP_LIMIT = 10000;

  const addToReport = useCallback(
    async (id: string) => {
      const addedReport = ADCOMM_REPORT_QUERIES.find(report => report.id === id);
      const random = Math.floor(Math.random() * 1000);
      dispatch({
        type: GlobalActions.ADD_TEMPLATE_TO_REPORT,
        value: {
          templateType: 'custom',
          chartType: addedReport?.graph,
          sectionType: 'CHART',
          id: uniqueId(`adcomm_${random}_`),
          style: {
            placement: {
              h: 40,
              w: 12
            },
            graphStyle: addedReport?.graphStyle,
            title: addedReport?.title
          },
          data: {
            source: 'us-adcomm',
            search_type: 'adcomm',
            disableSelectionSource: true,
            filters: { ...applyFilter, meeting_status: meetingStatus },
            query: addedReport?.query
          }
        }
      });
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Successfully added to Report.', color: 'success' }
      });
    },
    [state.cartItems, dispatch, meetingStatus]
  );

  const getToolTipValue = (minimum: number, total: number) =>
    total < minimum ? '' : formatCount(total);

  return (
    <Box display='flex' flexDirection='row' justifyContent='space-around' maxWidth='100%'>
      <Box sx={{ height: '60vh' }} maxWidth='30%'>
        <Box id='adcomm-meeting-status' mt={2} ml={3} sx={styles.dropDownBox}>
          <VivproSelect
            // @ts-ignore
            value={meetingStatus}
            handleChange={handleMeetingTabChange}
            options={MEETING_TABS}
            icon={KeyboardArrowDown}
          />
        </Box>
        <Box id='info' sx={{ height: '60vh' }}>
          <StatsCountTile text='Meetings' count={graphData?.stats.meetings} toolTipValue='' />
          <StatsCountTile text='Advisors' count={graphData?.stats.advisors} toolTipValue='' />
          <StatsCountTile
            text='Total Documents'
            count={`${truncateCountAboveMillions(graphData?.stats.documents)}`}
            toolTipValue={getToolTipValue(
              DOCUMENTS_MINIMUM_TOOLTIP_LIMIT,
              graphData?.stats.documents
            )}
          />
          <StatsCountTile
            text='Pages'
            count={`${truncateCountAboveMillions(graphData?.stats.documents_page_count)}`}
            toolTipValue={getToolTipValue(
              PAGES_MINIMUM_TOOLTIP_LIMIT,
              graphData?.stats.documents_page_count
            )}
          />
        </Box>
      </Box>
      <Box id='graphs' display='flex' flexDirection='column' sx={styles.box} maxWidth='70%'>
        <Box display='flex' flexDirection='column' maxWidth='100%'>
          <Box display='flex' justifyContent='space-between' flexDirection='row'>
            {graphData && (
              <>
                <Typography sx={styles.graphHeading}>Total Number of Meeting by Year</Typography>
                <Button
                  onClick={() => addToReport('meeting_by_year')}
                  variant='text'
                  disabled={graphData.year_graph.length === 0}
                  fullWidth={false}
                  sx={styles.addToReport}>
                  <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                    + Add to Report
                  </Typography>
                </Button>
              </>
            )}
          </Box>
          <Box display='flex' justifyContent='center' alignItems='center' sx={styles.barGraph}>
            {graphData && graphData.year_graph.length !== 0 ? (
              <CustomBarGraph
                keys={['Year']}
                index='name'
                data={graphData.year_graph}
                paddings={0.8}
                colors={['#B1BBE4']}
                // @ts-ignore
                tickRotationValue={25}
                // @ts-ignore
                labelTextColor='#ffffff'
                // @ts-ignore
                axisText='#A3A3A3'
                enableLabel={undefined}
              />
            ) : (
              <Typography variant='subtitle2' sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                No data available
              </Typography>
            )}
          </Box>
        </Box>
        <Stack display='flex' flexDirection='row' sx={styles.pieCharts} maxWidth='100%'>
          <Box sx={styles.pieContainers} display='flex' flexDirection='column'>
            <Box display='flex' justifyContent='space-between'>
              {graphData && (
                <>
                  <Typography variant='subtitle2' sx={styles.graphHeading}>
                    NDAs vs BLAs vs Other
                  </Typography>
                  <Button
                    onClick={() => addToReport('appl_type_pie_chart')}
                    disabled={graphData.nda_bla.length === 0}
                    variant='text'
                    fullWidth={false}
                    sx={styles.addToReport}>
                    <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                      + Add to Report
                    </Typography>
                  </Button>
                </>
              )}
            </Box>
            {graphData && graphData.nda_bla.length !== 0 ? (
              <CustomPieChart
                data={graphData.nda_bla}
                innerRadius={0.7}
                colors={PIECHARTCOLOR}
                fillText='#707070'
                radialLabelsTextColor='#707070'
                sliceLabelsTextColor={{ from: 'color' }}
                margin={styles.pieChartMargin}
              />
            ) : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{ height: '-webkit-fill-available' }}>
                <Typography variant='subtitle2' sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                  No data available
                </Typography>
              </Box>
            )}
          </Box>
          <Box sx={styles.pieContainers} display='flex' flexDirection='column'>
            <Box display='flex' justifyContent='space-between'>
              {graphData && (
                <>
                  <Typography variant='subtitle2' sx={styles.graphHeading}>
                    Post Meeting Approvals
                  </Typography>
                  <Button
                    onClick={() => addToReport('appl_type_post_acm')}
                    disabled={graphData.post_meeting_approval.length === 0}
                    variant='text'
                    fullWidth={false}
                    sx={styles.addToReport}>
                    <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                      + Add to Report
                    </Typography>
                  </Button>
                </>
              )}
            </Box>
            {graphData && graphData.post_meeting_approval.length !== 0 ? (
              <CustomPieChart
                data={graphData.post_meeting_approval}
                innerRadius={0.7}
                colors={PIECHARTCOLOR}
                fillText='#707070'
                radialLabelsTextColor='#707070'
                sliceLabelsTextColor={{ from: 'color' }}
                margin={styles.pieChartMargin}
              />
            ) : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{ height: '-webkit-fill-available' }}>
                <Typography variant='subtitle2' sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                  No data available
                </Typography>
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default React.memo(Graphs);
