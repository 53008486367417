import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import { TransitionProps } from '@mui/material/transitions';
import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

/* Typescript throws error for loading images from tsx */
// @ts-ignore
import Template1Image from '../../../assets/images/Reports/CoverPageOption-1.png';
// @ts-ignore
import Template2Image from '../../../assets/images/Reports/CoverPageOption-2.png';
// @ts-ignore
import Template3Image from '../../../assets/images/Reports/CoverPageOption-3.png';
// @ts-ignore
import Template5Image from '../../../assets/images/Reports/CoverPageOption-5.png';

const TEMPLATES = [
  {
    id: 'template1',
    image: Template1Image
  },
  {
    id: 'template2',
    image: Template2Image
  },
  {
    id: 'template3',
    image: Template3Image
  },
  {
    id: 'template5',
    image: Template5Image
  }
];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction='down' ref={ref} {...props} />;
});

const CoverPageSelectionDialog = ({
  onClose,
  onSelectionChange,
  open = false,
  selectedTemplateId = 'template1'
}: any) => {
  const [selectedId, setSelectedId] = useState<string>(selectedTemplateId);
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby='alert-dialog-slide-description'>
      <DialogTitle sx={{ bgcolor: '#D9D9D9', fontWeight: 'bold', position: 'relative' }}>
        Select Cover Page Style
        <IconButton
          edge='end'
          color='inherit'
          onClick={onClose}
          aria-label='close'
          sx={{ position: 'absolute', right: 16, top: 12 }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' p={2}>
          {TEMPLATES.map((template: any) => (
            <Box
              width={106}
              key={template.id}
              m={2}
              border={selectedId === template.id ? '2px solid #2D95E0' : '1px solid #ddd'}
              sx={{ cursor: 'pointer' }}
              onClick={() => setSelectedId(template.id)}>
              <img
                src={template.image}
                alt={template.id}
                loading='lazy'
                width='100%'
                height='auto'
              />
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
        <Button onClick={() => onSelectionChange?.(selectedId)} variant='outlined'>
          Update Cover Page
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(CoverPageSelectionDialog);
