/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useCallback, useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { has, isEmpty } from 'lodash';

// mui
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Link,
  Button
} from '@mui/material';

// Icons
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { UrlParameters } from '../../../types/reactRouterDom';
import DocumentIcon from '../../../Images/documentsIcon.svg';

import styles from './TimelineDocuments.styles';
import PDFPreview from '../../PdfPreview/PDFPreview';

import CustomTimelinePropsInterface from '../../../interfaces/CustomTimelinePropsInterface';
import { mapGraphEntityData } from '../../../pages/RegulatoryInformation/ApprovalHistory/utils';
import {
  RESOURCE_MAPPPING_BAESD_ON_TABNAME,
  SOURCE_RESOURCE_MAPPPING
} from '../../../pages/RegulatoryInformation/ApprovalHistory/constants';
import useQuery from '../../../helpers/customHooks/queryParameter';
import customTitleCase from '../../../utils/titleCase';
import LanguageToggleButton from '../../LanguageToggleComponents/LanguageToggleButton';
import LanguageToggleText from '../../LanguageToggleComponents/LanguageToggleText';
import { SnapshotPlayBtn } from '../../../assets/svgs/Icons';

import SnapshotLayout from '../../../pages/RegulatoryInformation/ApprovalHistory/SnapshotLayout';

// store
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import getDocumentMetadata from './getDocumentMetadata';

// Need to change this completely
const TimelineDocuments: React.FC<CustomTimelinePropsInterface> = ({
  column,
  dataItem
}: CustomTimelinePropsInterface) => {
  const [PDFData, setPDFData] = useState('');
  const [title, setTitle] = useState('');
  const [openPdf, setOpenPdf] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<any>({});
  const [originalTextEnabled, setOriginalTextEnabled] = useState(false);
  const [currentFileOriginalLanguage, setCurrentFileOriginalLanguage] = useState<string>('');
  const { source } = useParams<UrlParameters>();
  const [category, setCategory] = useState<string>('');
  const query = useQuery();
  const { location } = window;
  // snapshot modal
  const [modalOpen, setModalOpen] = useState(false);

  const { dispatch } = useContext(GlobalStore) as any;
  const { regulatoryState } = useContext(RegulatoryInformationStore);

  useEffect(() => {
    if (dataItem) {
      const fileCategory: any = {};
      Object.keys(dataItem.file_links).forEach((row: any) => {
        fileCategory[row] = 3;
      });
      if (dataItem.trials_in_scope) {
        fileCategory.trialInScope = 3;
      }
      setShowAll(fileCategory);
    }
  }, [dataItem]);

  const handleOpenPopup = (e: any, toOpen: boolean) => {
    e.preventDefault();
    if (!toOpen) {
      setOpen(true);
    }
  };

  const handleClosePopup = () => {
    setOpen(false);
  };

  const handlePDFOpenClick = useCallback((pdfLink: string, name: string) => {
    setTitle(name);
    setOpenPdf(true);
    setPDFData(pdfLink);
  }, []);

  const saveCurrentFileOriginalLanguageOnClick = useCallback((fileLinkOriginalLanguage: string) => {
    setCurrentFileOriginalLanguage(fileLinkOriginalLanguage);
  }, []);

  const handleClosePDF = useCallback(() => {
    setOpenPdf(false);
    setPDFData('');
    setCurrentFileOriginalLanguage('');
  }, []);

  const handleShowMore = useCallback(
    (e: React.SyntheticEvent, key: string, count: number) => {
      const keyList = { ...showAll };
      if (keyList[key] === count) {
        keyList[key] = 3;
      } else {
        keyList[key] = count;
      }
      setShowAll(keyList);
    },
    [showAll]
  );

  const openPDFInNewTab = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, link: string) => {
    const loc = new URL(link);
    if (!loc?.hostname?.includes('s3')) window.open(link, '_blank');
    else {
      const domain = new URL(location?.href)?.origin;
      const bucket = loc?.hostname?.split('.')[0];
      window.open(
        `${domain}/resource?bucket=${bucket}&filename=${loc?.pathname?.replace(/\//, '')}`,
        '_blank'
      );
    }
    e.stopPropagation();
  };
  const fileUrl =
    originalTextEnabled && currentFileOriginalLanguage ? currentFileOriginalLanguage : PDFData;

  const isPdf = fileUrl ? new URL(fileUrl).pathname.endsWith('.pdf') : false;

  const handleChatRia = ({ pdfUrl = '' }: { pdfUrl?: string }) => {
    const metadata = getDocumentMetadata(
      source as string,
      regulatoryState?.applicationDetails ?? {},
      dataItem,
      category
    );
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: source
    });
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: metadata,
        source,
        triggerReopenChatRia: false
      }
    });
    const tradeName = regulatoryState?.applicationDetails?.trade_name || '';
    dispatch({ type: GlobalActions.SET_APPLICATION_NAME, value: tradeName });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    setPDFData('');
    setOpenPdf(false);
    handleClosePopup();
  };

  if (column === undefined) {
    return <Box />;
  }

  return (
    <Box sx={styles.textAlignment}>
      <Stack
        direction={{
          xs: 'column',
          sm: 'column',
          md: 'column',
          lg: 'row',
          'lg-xl': 'row',
          xl: 'row'
        }}
        justifyContent='center'
        spacing={2}>
        <Box
          onClick={(e: React.SyntheticEvent) => handleOpenPopup(e, dataItem.document_count === 0)}>
          <Tooltip title={dataItem.document_count === 0 ? 'No Resources available' : ''}>
            <Stack direction='row' pl={2} spacing={0.5} sx={styles.linkItem}>
              <img src={DocumentIcon} alt='documentLogo' />{' '}
              <Stack direction='column'>
                <Typography sx={{ color: '#7879f1' }}>
                  {`${dataItem.document_count} ${
                    RESOURCE_MAPPPING_BAESD_ON_TABNAME.includes(query.get('tabname') || '')
                      ? 'Documents'
                      : SOURCE_RESOURCE_MAPPPING[source as string]
                  }`}
                </Typography>
                {dataItem?.trials_in_scope && (
                  <Typography sx={{ color: '#27ae60' }}>
                    {dataItem?.trials_in_scope.length} Trials
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Tooltip>
        </Box>
        {dataItem && has(dataItem, 'snapshot') && dataItem?.is_snapshot && (
          <Stack alignItems='center' justifyContent='center'>
            <Stack
              onClick={() => setModalOpen(true)}
              direction='row'
              spacing='2px'
              alignItems='center'
              sx={styles.snapshotBtn}>
              <SnapshotPlayBtn sx={{ fontSize: 12 }} />
              <Typography variant='body1' sx={styles.snapshotBtnText}>
                Snapshot
              </Typography>
            </Stack>
          </Stack>
        )}
        {/* snapshot code starts here */}
        <SnapshotLayout
          onclose={() => setModalOpen(false)}
          dataItem={dataItem}
          modalOpen={modalOpen}
        />
        {/* snapshot code ends here */}
      </Stack>
      <Dialog
        fullWidth
        maxWidth='lg'
        aria-labelledby='customized-dialog-title'
        open={open}
        sx={styles.dialog}
        onClose={handleClosePopup}>
        <DialogTitle>
          <Grid display='flex' justifyContent='space-between' alignItems='flex-start'>
            <Grid sx={styles.heading} display='flex' justifyContent='center'>
              Resources
            </Grid>
            <Box>
              <IconButton aria-label='delete' onClick={handleClosePopup} sx={{ fontSize: '30px' }}>
                <CancelIcon fontSize='inherit' />
              </IconButton>
            </Box>
          </Grid>
          <Divider />
        </DialogTitle>
        <DialogContent sx={{ height: '94%' }}>
          <Stack spacing={1}>
            <Stack
              display='flex'
              flexDirection='column'
              spacing={2}
              sx={{
                height: '40px',
                padding: '1px 28px',
                borderRadius: '10px',
                bgcolor: 'white.main'
              }}>
              {dataItem.file_links &&
                Object.entries(dataItem.file_links).map(([key, value]: any) => {
                  if (dataItem.file_links[key].length !== 0) {
                    return (
                      <Fragment key={key}>
                        <Stack
                          spacing={1}
                          display='flex'
                          flexDirection='row'
                          sx={{ width: '100%' }}>
                          <Box sx={{ width: '15%' }}>
                            <Box
                              sx={{
                                padding: '13px 10px',
                                marginBottom: 'auto'
                              }}>
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  color: 'black.darkVariant',
                                  fontWeight: '600'
                                }}>
                                {`${
                                  key.includes(['IFU'])
                                    ? key
                                    : customTitleCase(key.replace('_', ' '))
                                }`}
                              </Typography>
                            </Box>
                          </Box>
                          <Stack
                            display='flex'
                            spacing={1}
                            flexDirection='column'
                            sx={{ width: '100%' }}>
                            {value?.map((data: any, index: number) => {
                              if (index < showAll[key]) {
                                return (
                                  <Stack spacing={1} key={data.link}>
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      sx={{
                                        marginTop: '5px',
                                        padding: '12px 10px',
                                        borderRadius: 8,
                                        backgroundColor: 'primary.background'
                                      }}>
                                      <Stack direction='row' spacing={1} alignItems='center'>
                                        <Box>
                                          <Box
                                            sx={{
                                              padding: '0.2rem',
                                              width: '40px',
                                              height: '40px'
                                            }}>
                                            <img
                                              src='https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/pdf_icon.png'
                                              alt='PDF download'
                                              height='100%'
                                              width='100%'
                                            />
                                          </Box>
                                        </Box>
                                        <Stack>
                                          {'rems_id' in dataItem ? (
                                            <Stack direction='row' alignItems='center' spacing={1}>
                                              <Link
                                                key={data.name}
                                                sx={styles.title}
                                                href={data.link}
                                                target='_blank'>
                                                <Typography
                                                  sx={styles.title}>{`${data.name}`}</Typography>
                                              </Link>
                                              <Tooltip title='Open PDF in new tab'>
                                                <IconButton
                                                  aria-label='Open PDF in new tab'
                                                  onClick={e => openPDFInNewTab(e, data?.link)}>
                                                  <OpenInNewIcon fontSize='small' />
                                                </IconButton>
                                              </Tooltip>
                                            </Stack>
                                          ) : (
                                            <Stack
                                              direction='row'
                                              alignItems='center'
                                              spacing={1}
                                              onClick={() => {
                                                setCategory(
                                                  `${
                                                    key.includes(['IFU'])
                                                      ? key
                                                      : customTitleCase(key.replace('_', ' '))
                                                  }`
                                                );
                                                handlePDFOpenClick(data.link, data.name);
                                                saveCurrentFileOriginalLanguageOnClick(
                                                  dataItem?.orig_file_links?.[key]?.[index]?.link
                                                );
                                              }}>
                                              <Tooltip
                                                title={
                                                  data?.document_title ? data.document_title : ''
                                                }>
                                                <Typography
                                                  sx={styles.title}>{`${data.name}`}</Typography>
                                              </Tooltip>
                                              <Tooltip title='Open PDF in new tab'>
                                                <IconButton
                                                  aria-label='Open PDF in new tab'
                                                  onClick={e => openPDFInNewTab(e, data?.link)}>
                                                  <OpenInNewIcon fontSize='small' />
                                                </IconButton>
                                              </Tooltip>
                                            </Stack>
                                          )}
                                        </Stack>
                                      </Stack>
                                    </Box>
                                  </Stack>
                                );
                              }
                            })}
                            {value.length > 3 && (
                              <Stack direction='row' spacing={2} justifyContent='center'>
                                <Button
                                  variant='outlined'
                                  sx={styles.button}
                                  onClick={(e: React.SyntheticEvent) =>
                                    handleShowMore(e, key, value.length)
                                  }
                                  endIcon={
                                    showAll[key] === 3 ? (
                                      <ArrowDownwardOutlinedIcon />
                                    ) : (
                                      <ArrowUpwardOutlinedIcon />
                                    )
                                  }>
                                  {showAll[key] === 3 ? 'Show More' : 'Show Less'}
                                </Button>
                              </Stack>
                            )}
                          </Stack>
                        </Stack>
                        <Divider />
                      </Fragment>
                    );
                  }
                })}
              {dataItem.trials_in_scope && dataItem.trials_in_scope.length > 0 && (
                <>
                  <Stack spacing={1} display='flex' flexDirection='row' sx={{ width: '100%' }}>
                    <Box sx={{ width: '15%' }}>
                      <Box
                        sx={{
                          padding: '13px 10px',
                          marginBottom: 'auto'
                        }}>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            color: 'black.darkVariant',
                            fontWeight: '600'
                          }}>
                          Trials In Scope
                        </Typography>
                      </Box>
                    </Box>
                    <Stack spacing={1} display='flex' flexDirection='column' sx={{ width: '100%' }}>
                      {dataItem.trials_in_scope.map((trials: any, index: number) => {
                        if (index < showAll.trialInScope) {
                          return (
                            <Box
                              key={trials.nct_id}
                              display='flex'
                              flexDirection='column'
                              sx={{
                                marginTop: '5px',
                                padding: '12px 10px',
                                borderRadius: 8,
                                backgroundColor: 'primary.background'
                              }}>
                              <Stack direction='row' spacing={1}>
                                <Box>
                                  <Box sx={{ padding: '0.2rem', width: '40px', height: '40px' }}>
                                    <img
                                      src='https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/pdf_icon.png'
                                      alt='PDF download'
                                      height='100%'
                                      width='100%'
                                    />
                                  </Box>
                                </Box>
                                <Stack display='flex' flexDirection='column'>
                                  <Box>
                                    <Typography
                                      sx={{
                                        paddingTop: '5px',
                                        fontSize: '14px',
                                        fontFamily: 'Nunito Sans',
                                        color: 'gray.main',
                                        fontWeight: '600'
                                      }}>{`${trials.brief_title}`}</Typography>
                                  </Box>
                                  <Stack
                                    display='flex'
                                    flexDirection='row'
                                    justifyContent='flex-start'>
                                    {trials.docs.map((docs: any) => {
                                      return (
                                        <Stack
                                          key={docs.s3_url}
                                          direction='row'
                                          alignItems='center'
                                          sx={{ marginRight: '5%' }}
                                          onClick={() => {
                                            setCategory('Trials In Scope');
                                            handlePDFOpenClick(docs.s3_url, docs.doc_type);
                                          }}>
                                          <Typography
                                            sx={styles.title}>{`${docs.doc_type}`}</Typography>
                                        </Stack>
                                      );
                                    })}
                                  </Stack>
                                  <Stack
                                    display='flex'
                                    flexDirection='row'
                                    sx={{ paddingTop: '10px' }}>
                                    {Object.entries(mapGraphEntityData(trials)).map(
                                      ([key, value]) => {
                                        if (value) {
                                          return (
                                            <Tooltip key={value} title={`${key}: ${value}`}>
                                              <Box display='flex' sx={styles.chips}>
                                                <Typography sx={{ fontSize: '12px' }}>
                                                  {value}
                                                </Typography>
                                              </Box>
                                            </Tooltip>
                                          );
                                        }
                                      }
                                    )}
                                  </Stack>
                                </Stack>
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  sx={{ marginTop: '5px' }}
                                />
                              </Stack>
                            </Box>
                          );
                        }
                      })}
                      {dataItem.trials_in_scope.length > 3 && (
                        <Stack direction='row' spacing={2} justifyContent='center'>
                          <Button
                            variant='outlined'
                            sx={styles.button}
                            onClick={(e: React.SyntheticEvent) =>
                              handleShowMore(e, 'trialInScope', dataItem.trials_in_scope.length)
                            }
                            endIcon={
                              showAll?.trialInScope === 3 ? (
                                <ArrowDownwardOutlinedIcon />
                              ) : (
                                <ArrowUpwardOutlinedIcon />
                              )
                            }>
                            {showAll?.trialInScope === 3 ? 'Show More' : 'Show Less'}
                          </Button>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                  <Divider />
                </>
              )}
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth='lg'
        onClose={() => {
          handleClosePDF();
        }}
        aria-labelledby='customized-dialog-title'
        open={openPdf}>
        <DialogTitle>
          <Grid container>
            <Grid item xs={11} sx={{ justify: 'center', alignItems: 'center' }}>
              {!isEmpty(currentFileOriginalLanguage) && (
                <LanguageToggleButton
                  source={source}
                  originalTextEnabled={originalTextEnabled}
                  toggleTextLanugage={() => {
                    setOriginalTextEnabled(!originalTextEnabled);
                  }}
                  color='green'
                />
              )}
            </Grid>
            <Grid item xs={1} sx={{ justify: 'flex-end', alignItems: 'center' }}>
              <IconButton
                aria-label='delete'
                onClick={() => {
                  handleClosePDF();
                }}>
                <CancelIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              {originalTextEnabled && <LanguageToggleText styles={{ textAlign: 'start' }} />}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {isPdf ? (
            <PDFPreview
              open={Boolean(fileUrl)}
              pdfUrl={fileUrl}
              onClose={handleClosePDF}
              handleChatRia={handleChatRia}
              title={title}
              setOriginalTextEnabled={setOriginalTextEnabled}
              originalTextEnabled={originalTextEnabled}
              currentFileOriginalLanguage={currentFileOriginalLanguage}
              source={source}
            />
          ) : (
            <iframe
              src={fileUrl}
              title='Document Viewer'
              style={{ width: '100%', height: '90vh' }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default React.memo(TimelineDocuments);
