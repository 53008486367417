import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  header: {
    width: '100%'
    //   padding:theme.spacing(2),
    // border: '2px solid green'
  },
  heading: {
    color: theme.palette.home.heading
  },
  btn: {
    margin: theme.spacing(1),
    fontSize: '3vh',
    // height : '100%',
    textTransform: 'capitalize',
    fontWeight: '500',
    borderRadius: '10px',
    background: theme.palette.navbar.button,
    color: theme.palette.white
  },
  logo: {
    height: theme.spacing(3)
  },
  preview: {
    padding: theme.spacing(5)
  },
  previewContainer: {
    width: '100%',
    border: '1px solid black'
  },
  graphs: {
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(10),
    width: '100%',
    height: '100%'
  },
  paper: {
    borderRadius: theme.spacing(3),
    padding: theme.spacing(3),
    width: '100%'
  },
  cardDetails: {
    marginTop: theme.spacing(15),
    paddingTop: theme.spacing(10),
    height: '100%',
    width: '100%'
  },
  highlightText: {
    // color:theme.palette.common.primaryBlack,
    opacity: 0.6,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  boxRoot: {
    marginTop: theme.spacing(15),
    paddingTop: theme.spacing(10),
    width: '100%'
  },
  progress: {
    color: theme.palette.navbar.button
  }
  // paper: {
  //   borderRadius: theme.spacing(3),
  //   padding: theme.spacing(3),
  // }
}));

export const styles = {
  close: {
    width: '100%',
    paddingTop: 2,
    paddingRight: 2,
    background: 'primary.background',
    display: 'flex',
    justifyContent: 'space-between'
  },
  iframeContainer: {
    width: '100%',
    height: '87vh',
    padding: 1.5
  },
  pdfIframe: {
    height: '100%',
    width: '100%',
    border: 'unset',
    borderRadius: '10px'
  },
  chatRiaButton: {
    display: 'flex',
    ml: '10px',
    width: 'fit-content',
    borderRadius: '16px',
    height: '32px',
    padding: '6px 16px',
    border: '1px solid',
    borderColor: 'blue.backgroundLight',
    backgroundColor: 'white.main',
    textTransform: 'capitalize',
    color: 'blue.backgroundLight',
    fontSize: '12px',
    fontWeigth: '400'
  }
};
