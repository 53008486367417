import palette from '../../../themev5/palette';

const styles: any = {
  dropDownBox: {
    display: 'inline-flex',
    borderRadius: '8px',
    boxShadow: '0px 0px 4px rgba(0,0,0,0.16)',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  askButton: {
    width: 'fit-content',
    borderRadius: '20px',
    height: '32px',
    padding: '10px 0px 11px 8px',
    borderColor: palette.white.smoke,
    backgroundColor: palette.blue.backgroundLight,
    textTransform: 'capitalize',
    color: palette.white.smoke,
    fontSize: '12px',
    fontWeigth: '400',
    '&:hover': {
      borderColor: palette.blue.backgroundLight,
      backgroundColor: palette.white.smoke,
      color: palette.blue.backgroundLight,
      border: '1px solid'
    }
  }
};

export default styles;
