/* eslint-disable react/prop-types */
import { useHistory } from 'react-router-dom';
import React from 'react';
// mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

// Styles
import styles from './VideoCard.styles';

const VideoCard = ({ video }) => {
  const history = useHistory();
  const tagString = `#${video.tags.join(' #')}`;
  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <Stack sx={styles.card} onClick={() => history.push(`/help/videos/${video.id}`)}>
        <img className={styles.image} src={video.thumbnail} alt={video.title} />
        <Box pl={2} pt={1} pr={1}>
          <Tooltip title={video.title}>
            <Typography variant='subtitle2' sx={styles.title}>
              {video.title}
            </Typography>
          </Tooltip>
          <Tooltip title={video.shortDescription}>
            <Typography variant='body1' sx={styles.shortDescription}>
              {video.shortDescription}
            </Typography>
          </Tooltip>
          <Tooltip title={tagString}>
            <Typography variant='body1' sx={styles.tags}>
              {tagString}
            </Typography>
          </Tooltip>
        </Box>
      </Stack>
    </Box>
  );
};

export default React.memo(VideoCard);
