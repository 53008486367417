import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Typography1 from '@mui/material/Typography';
import { Box, IconButton } from '@mui/material';
import { capitalize, isEmpty } from 'lodash';
import ExtendedCard, { status } from '../../CardsPage/components/ExtendedCard';
import Styles from '../../CardsPage/styles/ExtendedCard.styles';
import CardActions from '../../CardsPage/components/CardActions';
import { SHOW_REG_360_BTN } from '../../ResultsPage/utils/constants';
import Reg360Icon from '../../../assets/svgs/CardsPage/Reg360Icon';
import Loading from '../Loading';

// eslint-disable-next-line react/prop-types
const ApplicationButton = ({ application }) => {
  const classes = Styles();
  const actionRef = useRef(false);

  const [openExtendedCard, setOpenExtendedCard] = useState(false);
  const [showExtendedCard, setShowExtendedCard] = useState(false);
  const [showReg360, setShowReg360] = useState(false);
  const [applicationData, setApplicationData] = useState({});

  const getCardStatusString = useCallback(() => {
    // eslint-disable-next-line react/prop-types
    if (applicationData?.marketing_status === 'YELLOW') {
      // eslint-disable-next-line react/prop-types
      return status[applicationData?.marketing_status][1];
    }
    // eslint-disable-next-line react/prop-types
    if (applicationData?.marketing_status === 'RED') {
      // eslint-disable-next-line react/prop-types
      return status[applicationData?.marketing_status][0];
    }
    return '';
  }, [applicationData]);
  const getClassForApplication = (data, task = 'number') => {
    // eslint-disable-next-line react/prop-types
    if (
      data?.application_type === 'NDA' ||
      data?.application_type === 'CANADA' ||
      data?.application_type === 'HPRA'
    ) {
      return task === 'number' ? classes.numberNDA : classes.nameNDA;
    }
    // eslint-disable-next-line react/prop-types
    if (data?.approval_pathway === 'S-564') {
      return task === 'number' ? classes.numberEUA : classes.nameEUA;
    }
    // eslint-disable-next-line react/prop-types
    if (data?.approval_pathway === '510(k)') {
      // for devices same red color is used.
      return task === 'number' ? classes.numberNDA : classes.nameNDA;
    }
    if (data?.approval_pathway?.toLowerCase() === 'denovo') {
      return task === 'number' ? classes.numberDenovo : classes.nameDenovo;
    }
    return task === 'number' ? classes.numberBLA : classes.nameBLA;
  };
  const handleReg360 = () => {
    try {
      let url = '';
      const navigation = JSON.parse(localStorage.getItem('navigation'));
      let module_name = 'core';
      if (applicationData?.module_name) {
        module_name = applicationData.module_name;
      }
      const applicationNumber = encodeURIComponent(applicationData?.application_number);
      if (applicationData?.source?.toLowerCase() === 'us') {
        navigation.regulatory = {
          url: `/regulatory360/${module_name}/${applicationNumber}/${applicationData?.source}?type=${applicationData?.application_type}`,
          value: `Regulatory360:${applicationNumber}`
        };
      } else {
        navigation.regulatory = {
          url: `/regulatory360/${module_name}/${applicationNumber}/${applicationData?.source}`,
          value: `Regulatory360:${applicationNumber}`
        };
      }
      localStorage.setItem('navigation', JSON.stringify(navigation));
      if (applicationData?.source?.toLowerCase() === 'us') {
        url = `/regulatory360/${module_name}/${applicationNumber}/${applicationData?.source?.toLowerCase()}?type=${
          applicationData?.application_type
        }`;
      } else {
        url = `/regulatory360/${module_name}/${applicationNumber}/${applicationData?.source?.toLowerCase()}`;
      }
      window.open(url, '_blank');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };
  useEffect(() => {
    if (applicationData && !['ct'].includes(applicationData?.source?.toLowerCase())) {
      if (applicationData?.source?.toLowerCase() === 'us') {
        if (applicationData?.application_type?.toLowerCase() === 'eua') {
          setShowExtendedCard(false);
        } else {
          setShowExtendedCard(true);
        }
      } else {
        setShowExtendedCard(true);
      }
    } else {
      setShowExtendedCard(false);
    }

    if (applicationData?.source?.toLowerCase() === 'us') {
      if (applicationData?.application_type?.toLowerCase() !== 'eua') {
        setShowReg360(
          applicationData && SHOW_REG_360_BTN?.includes(applicationData?.source?.toLowerCase())
        );
      }
    } else {
      setShowReg360(
        applicationData && SHOW_REG_360_BTN?.includes(applicationData?.source?.toLowerCase())
      );
    }
  }, [applicationData]);
  useEffect(() => {
    if (application) {
      let localData = {};
      switch (application.source) {
        case 'eu':
          localData = {
            ...application,
            accelerated_approval: application.accelerated_assessment,
            accelerated_approval_text: application.accelerated_assessment_text,
            active_ingredients: application.generic_name,
            application_number: application.product_number,
            application_type: 'EMA',
            approval_pathway: 'null', // TODO: Need to check
            approval_year: application.approval_year,
            biosimilar: application.biosimilar,
            biosimilar_text: application.biosimilar_text,
            center: 'null', // TODO: Need to check
            marketing_status: application.marketing_status,
            module_name: application.module_name,
            source: application.source,
            trade_name: application.product_name
          };
          if (!Array.isArray(localData.generic_name)) {
            localData.active_ingredients = [localData.generic_name];
          }
          if (!Array.isArray(localData.product_name)) {
            localData.trade_name = [localData.product_name];
          }
          break;
        case 'ca':
          localData = {
            ...application,
            application_number: application.vin,
            trade_name: application.product_name,
            active_ingredients: application.generic_name
          };
          if (!Array.isArray(localData.product_name)) {
            localData.trade_name = [localData.product_name];
          }
          if (!Array.isArray(localData.generic_name)) {
            localData.active_ingredients = [localData.generic_name];
          }
          break;
        case 'ct':
          localData = {
            ...application,
            application_number: application.nct_id,
            trade_name: application.official_title
          };
          if (!Array.isArray(localData.trade_name)) {
            localData.trade_name = [localData.trade_name];
          }
          break;
        case 'us-devices':
          localData = { ...application };
          if (Array.isArray(localData.product_name)) {
            localData.trade_name = localData.product_name;
          } else {
            localData.trade_name = [localData.product_name];
          }
          break;
        case 'hpra':
          localData = {
            ...application,
            active_ingredients: application.generic_name,
            application_number: application.product_number,
            application_type: 'HPRA',
            approval_year: application.approval_year,
            biosimilar: application.biosimilar,
            biosimilar_text: application.biosimilar_text,
            center: 'null', // TODO: Need to check
            marketing_status: application.marketing_status,
            module_name: application.module_name,
            source: application.source,
            trade_name: application.product_name
          };
          if (!Array.isArray(localData.generic_name)) {
            localData.active_ingredients = [localData.generic_name];
          }
          if (!Array.isArray(localData.product_name)) {
            localData.trade_name = [localData.product_name];
          }
          break;
        case 'hma':
          localData = {
            ...application,
            active_ingredients: application.active_ingredients,
            application_number: application.product_number,
            approval_year: application.approval_year,
            biosimilar: application.biosimilar,
            biosimilar_text: application.biosimilar_text,
            center: 'null', // TODO: Need to check
            marketing_status: application.marketing_status,
            module_name: application.module_name,
            source: application.source,
            trade_name: application.trade_name
          };
          if (!Array.isArray(localData.active_ingredients)) {
            localData.active_ingredients = [localData.active_ingredients];
          }
          if (!Array.isArray(localData.trade_name)) {
            localData.trade_name = [localData.trade_name];
          }
          break;
        case 'jp':
          localData = {
            ...application,
            application_number: application.yj_code,
            application_type: 'jp',
            approval_year: application.sales_year,
            center: 'null' // TODO: Need to check
          };
          if (!Array.isArray(localData.active_ingredients)) {
            localData.active_ingredients = [localData.active_ingredients];
          }
          if (!Array.isArray(localData.trade_name)) {
            localData.trade_name = [localData.trade_name];
          }
          break;
        case 'hma':
          localData = {
            ...application,
            active_ingredients: application.active_ingredients,
            application_number: application.product_number,
            approval_year: application.approval_year,
            biosimilar: application.biosimilar,
            biosimilar_text: application.biosimilar_text,
            center: 'null', // TODO: Need to check
            marketing_status: application.marketing_status,
            module_name: application.module_name,
            source: application.source,
            trade_name: application.trade_name
          };
          if (!Array.isArray(localData.active_ingredients)) {
            localData.active_ingredients = [localData.active_ingredients];
          }
          if (!Array.isArray(localData.trade_name)) {
            localData.trade_name = [localData.trade_name];
          }
          break;
        case 'jp':
          localData = {
            ...application,
            application_number: application.yj_code,
            application_type: 'jp',
            approval_year: application.sales_year,
            center: 'null' // TODO: Need to check
          };
          if (!Array.isArray(localData.active_ingredients)) {
            localData.active_ingredients = [localData.active_ingredients];
          }
          if (!Array.isArray(localData.trade_name)) {
            localData.trade_name = [localData.trade_name];
          }
          break;
        default:
          localData = { ...application };
      }

      setApplicationData(localData);
    }
  }, [application]);
  if (!applicationData || isEmpty(applicationData)) {
    return <Loading type='card' />;
  }
  return (
    <Stack direction='column' width='31%' height={150} m={1}>
      {showExtendedCard && openExtendedCard && (
        <ExtendedCard
          open={openExtendedCard}
          setOpen={setOpenExtendedCard}
          source={applicationData?.source.toUpperCase()}
          cardNumber={applicationData?.application_number}
          module='core'
        />
      )}
      <Stack
        padding={1.5}
        sx={{
          border: '1px solid #C9D2F6',
          borderRadius: '8px',
          cursor: showExtendedCard ? 'pointer' : 'initial',
          height: '100%'
        }}>
        {applicationData && applicationData?.active_ingredients && (
          <Tooltip
            disableFocusListener
            placement='top-start'
            title={applicationData?.active_ingredients?.join(', ')}>
            <Typography1
              variant='subtitle2'
              component='div'
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                textTransform: 'capitalize'
              }}>
              {`${capitalize(applicationData?.active_ingredients?.join(', '))}`}
            </Typography1>
          </Tooltip>
        )}
        <Stack direction='row' alignItems='center' mt={1}>
          <Typography
            paddingLeft={1}
            variant='body2'
            component='div'
            className={getClassForApplication(applicationData, 'number')}>
            {applicationData?.application_number}
          </Typography>
          {Array.isArray(applicationData?.trade_name) && (
            <Tooltip
              title={`${applicationData.trade_name.join(', ')}${getCardStatusString()}`}
              aria-label='Button Link'>
              <Typography
                variant='subtitle1'
                component='div'
                className={getClassForApplication(applicationData, 'name')}
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 700,
                  fontSize: '16px',
                  textTransform: 'uppercase',
                  paddingLeft: '10px'
                }}>
                {applicationData.trade_name.join(', ').length >= 15
                  ? `${applicationData.trade_name
                      .join(', ')
                      .slice(0, 15)}...${getCardStatusString()}`
                  : `${applicationData.trade_name.join(', ')}${getCardStatusString()}`}
              </Typography>
            </Tooltip>
          )}
        </Stack>
        <Box ml='auto' mt='auto'>
          <Stack
            direction='row'
            onMouseEnter={() => {
              actionRef.current = true;
            }}
            onMouseLeave={() => {
              actionRef.current = false;
            }}>
            {showReg360 && (
              <Tooltip title='Regulatory 360'>
                <IconButton onClick={() => handleReg360()}>
                  <Reg360Icon />
                </IconButton>
              </Tooltip>
            )}
            <CardActions
              key={applicationData?.application_number}
              index={applicationData?.application_number}
              isFavorite={applicationData?.is_favorite}
              cardsPage
              typeData={{
                module: applicationData?.module_name,
                source: applicationData?.source,
                id: applicationData?.application_number,
                type: 'applications'
              }}
              inProject={applicationData?.inProjects}
              type='applications'
            />
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};

ApplicationButton.prototype = {
  application: PropTypes.object.isRequired
};

export default memo(ApplicationButton);
