/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/media-has-caption */
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Box, Divider, Drawer, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { CrossIcon } from '../../assets/svgs/Icons';
import { VIDEO_TUTORIALS } from '../../pages/Help/utils/constants';
import { getSignedVideoLink } from '../../api/pages/VideoList';
import VideoGuide from './VideoGuide';
import styles from './styles/TutorialDrawer.styles';
import Store from '../../store';
import GUIDES from './constants';
import Loading from '../../pages/SearchResults/components/Loading';
import { markdownToText } from '../ChatRIA/component/MarkdownBox';

type TutorialDrawerProps = {
  isDrawerOpen: boolean;
  closeDrawer: () => void;
};

const TutorialDrawer = ({ isDrawerOpen, closeDrawer }: TutorialDrawerProps) => {
  const { state } = useContext<any>(Store);
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedVideo, setExpandedVideo] = useState<any>();
  const [isExpandedDesc, setIsExpandedDesc] = useState(false);
  const [videoLink, setVideoLink] = useState('');
  const [guide, setGuide] = useState<any>();
  const [guideVideos, setGuideVideos] = useState<any>();
  const history = useHistory();

  const handleExpandTutorial = async (videoId: string) => {
    const selectedVideo = VIDEO_TUTORIALS.find(video => video.id === videoId);

    if (!selectedVideo) {
      return;
    }

    setIsExpanded(true);
    setIsExpandedDesc(false);
    setExpandedVideo(selectedVideo);
    const signedVideoLink = await getSignedVideoLink(selectedVideo.videoName);
    setVideoLink(signedVideoLink.data.Success.uri);
  };

  useEffect(() => {
    setIsExpanded(false);
    setIsExpandedDesc(false);
    const currentGuide = GUIDES[state?.module];

    if (!currentGuide) {
      return;
    }

    setGuide(currentGuide);
    const videos = VIDEO_TUTORIALS.filter(video => {
      if (!video.isNewTutorial) {
        return false;
      }

      const tags = video.tags.toString().toLowerCase();

      // eslint-disable-next-line no-restricted-syntax
      for (const tag of currentGuide.tags) {
        if (tags.includes(tag.toLowerCase())) {
          return true;
        }
      }

      return false;
    });

    setGuideVideos(videos);
  }, [isDrawerOpen]);

  const cleanText = (text: string) => {
    const div = document.createElement('div');
    div.innerHTML = text;
    return div.textContent || div.innerText || '';
  };

  const handleExpandDesc = (event: any) => {
    event.preventDefault();
    setIsExpandedDesc(!isExpandedDesc);
  };

  return (
    <Drawer
      sx={{
        '& > .MuiDrawer-paper': {
          height: '100vh',
          ...(isExpanded && { borderRadius: '8px 0 0 8px' })
        }
      }}
      anchor='right'
      open={isDrawerOpen}
      onClose={closeDrawer}>
      <Box sx={styles.container}>
        {isExpanded && expandedVideo && (
          <Box display='flex'>
            <Box overflow='auto'>
              <Box
                sx={{
                  ...styles.expandedContainer,
                  ...(!videoLink && styles.loadingExpandedContainer)
                }}>
                {videoLink ? (
                  <>
                    <video
                      controls
                      autoPlay
                      src={videoLink}
                      controlsList='nodownload'
                      style={styles.video}
                    />
                    <Typography sx={styles.expandedVideoTitle}>{expandedVideo.title}</Typography>
                    <Box sx={styles.expandedVideoDescContainer}>
                      <Typography sx={{ ...styles.expandedVideoDateTagText, ...{ mb: '6px' } }}>
                        About Video
                      </Typography>
                      {isExpandedDesc ? (
                        <>
                          <Typography
                            sx={styles.expandedVideoContent}
                            dangerouslySetInnerHTML={{ __html: expandedVideo.content }}
                          />
                          <Link onClick={handleExpandDesc} sx={styles.expandDescCtaText}>
                            {isExpandedDesc ? 'less' : 'more'}
                          </Link>
                        </>
                      ) : (
                        <Typography sx={styles.expandedVideoContent}>
                          {`${cleanText(markdownToText(expandedVideo.content.slice(0, 300)))}...`}
                          <Link onClick={handleExpandDesc} sx={styles.expandDescCtaText}>
                            {isExpandedDesc ? 'less' : 'more'}
                          </Link>
                        </Typography>
                      )}
                      <Typography
                        sx={{
                          ...styles.expandedVideoDateTagText,
                          ...{ flexShrink: 0, mt: '32px', fontSize: '12px' }
                        }}>
                        {format(expandedVideo.date, 'MMM dd, yyyy')}
                      </Typography>
                      <Typography
                        sx={{
                          ...styles.expandedVideoDateTagText,
                          ...{ fontSize: '12px' }
                        }}>{`#${expandedVideo.tags.join(' #')}`}</Typography>
                    </Box>
                  </>
                ) : (
                  <Box sx={styles.loadingContainer}>
                    <Loading showTips={false} />
                  </Box>
                )}
              </Box>
            </Box>
            <Divider orientation='vertical' sx={styles.verticalLine} />
          </Box>
        )}
        <Box sx={styles.guidesContainer}>
          <Typography sx={styles.guideTitle}>{guide?.title}</Typography>
          <Tooltip title='Close'>
            <IconButton onClick={closeDrawer} sx={styles.crossIcon}>
              <CrossIcon />
            </IconButton>
          </Tooltip>
          <Box sx={styles.listContainer}>
            {guideVideos?.map((video: any) => {
              return (
                <VideoGuide
                  key={video.id}
                  video={video}
                  handleExpandTutorial={handleExpandTutorial}
                />
              );
            })}
          </Box>
          <Box textAlign='center'>
            <button
              type='button'
              style={styles.allTutorialButton}
              onClick={() => history.push('/help?content=videos')}>
              See tutorials for all pages
            </button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default TutorialDrawer;
