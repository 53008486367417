import React, { memo, useContext, useRef, useState } from 'react';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Add, Remove, RestartAlt } from '@mui/icons-material';
import DesignationStore from '../../../store/Designation';
import { horizontalTimelineStyle } from '../styles/horizontalTimelineStyle';
import plusStyle from '../styles/twoDimensionalTimeLineStyle';

import SlimDesignationCard from './SlimDesignationCard';
import ExpandedCard from './ExpandedCard';
import TwoDimensionalBadge from './TwoDimensionalBadge';

type MouseEvent = React.MouseEvent<HTMLDivElement>;

const TwoDimensionalTimeLine = ({
  dataByYear,
  applicationNumbers,
  drugs
}: {
  dataByYear: any;
  applicationNumbers: string[];
  drugs: any;
}) => {
  const { designationState } = useContext(DesignationStore);
  const centerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [activeBadge, setActiveBadge] = useState('');
  const [zoom, setZoom] = useState(1);
  const [expandedPayload, setExpandedPayload] = useState({
    number: '',
    tradeName: '',
    activeIngredients: '',
    sponsorName: '',
    indications: '',
    route: '',
    formulation: '',
    specialityDesignation: [],
    selectedDesignationType: '',
    variant: 'red',
    strikeText: false
  });
  const [isExpandedCardOpen, setIsExpandedCardOpen] = useState(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });
  let maxKey = '';
  let maxSize = -Infinity;

  Object.keys(dataByYear).forEach(key => {
    if (Object.keys(key).length > maxSize) {
      maxSize = dataByYear[key].length;
      maxKey = key;
    }
  });

  const handleMouseDown = (event: MouseEvent) => {
    setIsDragging(true);
    setLastPosition({ x: event.clientX, y: event.clientY });
  };

  const handleClose = () => {
    setIsExpandedCardOpen(false);
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragging) return;

    const { scrollTop, scrollLeft } = scrollRef.current!;
    scrollRef.current!.scrollTop = scrollTop - (event.clientY - lastPosition.y);
    scrollRef.current!.scrollLeft = scrollLeft - (event.clientX - lastPosition.x);

    setLastPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleZoomIn = () => {
    setZoom(zoom + 0.1);
  };
  const handleZoomOut = () => {
    setZoom(zoom - 0.1);
  };
  const handleZoomReset = () => {
    setZoom(1);
  };

  return (
    <>
      <Stack
        direction='row'
        width='100%'
        overflow={designationState.openAllSeeMore ? 'visible' : 'auto'}
        maxWidth={designationState.openAllSeeMore ? '100%' : '93vw'}
        height='100%'
        maxHeight={designationState.openAllSeeMore ? '100%' : '70vh'}
        ref={scrollRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        sx={{
          ...horizontalTimelineStyle,
          '&>div': {
            transform: `scale(${zoom})`,
            transformOrigin: 'left top',
            transition: 'transform 0.3s ease-in-out',
            minWidth: 250 * zoom
          }
        }}>
        <Stack sx={{ marginTop: '2.5rem' }}>
          {drugs.map((drug: any) => {
            return (
              <SlimDesignationCard
                key={drug.application_number}
                tradeName={drug.product_name?.join(' : ') ?? ''}
                number={drug.application_number}
                variant={drug.application_type?.toLowerCase() === 'nda' ? 'red' : 'blue'}
              />
            );
          })}
        </Stack>
        {Object.entries(dataByYear)
          .sort(([yearA], [yearB]) => {
            if (designationState.sortBy === 'asc') {
              return parseInt(yearB, 10) - parseInt(yearA, 10);
            }
            return parseInt(yearA, 10) - parseInt(yearB, 10);
          })
          // eslint-disable-next-line no-unused-vars
          .map(([year, data]: any, _) => {
            return (
              <Stack
                direction='column'
                alignItems='center'
                key={year}
                width='9rem'
                minWidth='9rem !important'
                id={`year-${year}`}
                ref={year === maxKey ? centerRef : null}>
                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  width='100%'
                  height={24}
                  sx={{
                    position: 'relative',
                    borderBottom: '1px solid #adadad',
                    '&::before': {
                      width: `${Math.min(zoom * 10 * 100, 100)}%`
                    }
                  }}>
                  <Box>
                    <Typography sx={{ fontWeight: 800 }} variant='caption'>
                      {year === '1900' ? 'NA' : year}
                    </Typography>
                  </Box>
                </Stack>
                <Stack display='flex' justifyContent='center' flexDirection='column'>
                  {applicationNumbers.map(item => {
                    return item in data ? (
                      <TwoDimensionalBadge
                        application={data[item]}
                        year={year}
                        activeBadge={activeBadge}
                        isExpandedCardOpen={isExpandedCardOpen}
                        setIsExpandedCardOpen={setIsExpandedCardOpen}
                        setActiveBadge={setActiveBadge}
                        setExpandedPayload={setExpandedPayload}
                      />
                    ) : (
                      <Box className='eplus' sx={plusStyle}>
                        <Box
                          sx={{
                            zIndex: 1,
                            position: 'relative'
                          }}
                        />
                      </Box>
                    );
                  })}
                </Stack>
              </Stack>
            );
          })}
      </Stack>

      {!designationState.openAllSeeMore && (
        <Stack direction='column' justifyContent='center'>
          <Tooltip title='Zoom In'>
            <Box>
              <IconButton onClick={handleZoomIn} disabled={zoom >= 1.5}>
                <Add />
              </IconButton>
            </Box>
          </Tooltip>
          <Tooltip title='Zoom Out'>
            <Box>
              <IconButton onClick={handleZoomOut} disabled={zoom <= 0.5}>
                <Remove />
              </IconButton>
            </Box>
          </Tooltip>
          <Tooltip title='Reset Zoom'>
            <Box>
              <IconButton onClick={handleZoomReset} disabled={zoom === 1}>
                <RestartAlt />
              </IconButton>
            </Box>
          </Tooltip>
        </Stack>
      )}

      <ExpandedCard
        open={isExpandedCardOpen}
        payload={{
          ...expandedPayload
        }}
        onClose={handleClose}
      />
    </>
  );
};

export default memo(TwoDimensionalTimeLine);
