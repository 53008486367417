const styles = {
  button: {
    width: 30,
    height: 30,
    padding: 0,
    minWidth: 30,
    margin: 1,
    borderRadius: 3,
    color: '#C9D2F6 !important',
    '& > svg': {
      color: '#C9D2F6 !important'
    },
    '& > div': {
      borderRadius: 3
    }
  },
  buttonFilled: {
    width: 30,
    height: 30,
    padding: 0,
    minWidth: 30,
    margin: 1,
    borderRadius: 3,
    color: 'white !important',
    backgroundColor: '#C9D2F6 !important',
    '& > svg': {
      color: 'white !important'
    }
  },
  avatarHeader: {
    bgcolor: 'primary.main',
    width: 32,
    height: 32,
    fontSize: '14px !important',
    fontWeight: 'bold !important',
    borderRadius: '50%'
  }
};
export default styles;

export const featureButton = {
  color: 'primary.main',
  textTransform: 'none',
  animation: 'pulse 5s cubic-bezier(0.4, 0, 0.6, 1) 20',
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(46, 143, 110, 0.6)'
    },
    '70%': {
      transform: 'scale(1.2)',
      boxShadow: '0 0 0 5px rgba(46, 143, 110, 0)'
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(46, 143, 110, 0)'
    }
  }
};
