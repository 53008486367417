/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useContext, useRef, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip
} from '@mui/material';

import { useHistory, useLocation } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { Delete } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';

import { deleteProject, updateProject } from '../../../../api/pages/UserProfile';
import UserProfileActions from '../../../../store/UserProfile/actions';
import UserProfileStore from '../../../../store/UserProfile';
import Actions from '../../../../store/actions';
import GlobalStore from '../../../../store';
// eslint-disable-next-line import/no-cycle
import { StyledListItemButton } from '../../UserProfileNav';

const ProjectListItem = ({
  project,
  projectsList,
  handleClick,
  loading,
  setLoading,
  setProjectsList
}) => {
  const { id, name, active_items_count } = project;
  const inputRef = useRef('');
  const history = useHistory();
  const location = useLocation();
  const { dispatch } = useContext(GlobalStore);
  const { profileState, profileDispatch } = useContext(UserProfileStore);
  const [allowEditUpdate, setAllowEditUpdate] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [hoverMode, setHoverMode] = useState(false);
  const [openDialog, toggleopenDialog] = useState(false);

  const onChangeValidation = e => {
    let { value } = e.target;
    value = value.trim();
    if (value && value !== name) {
      setAllowEditUpdate(true);
    } else {
      setAllowEditUpdate(false);
    }
  };

  const onEditMode = () => {
    setEditMode(true);
    setErrorMsg(null);
    setAllowEditUpdate(false);
  };

  const removeEditMode = () => {
    setEditMode(false);
    setErrorMsg(null);
  };

  const onBlurItem = () => {
    if (!hoverMode) {
      removeEditMode();
    }
  };

  const onMouseActionChange = action => {
    if (action === 'enter') {
      setHoverMode(true);
    } else {
      setHoverMode(false);
    }
  };

  const handleDeleteUpdate = async (action, updatedTitle = null) => {
    toggleopenDialog(false);
    updatedTitle = updatedTitle && updatedTitle.trim();
    let msg = 'Something went wrong';
    try {
      setErrorMsg(null);
      setLoading(true);
      let res;
      if (action === 'delete') {
        res = await deleteProject(id);
        setProjectsList(list =>
          list.filter(currentProject => {
            let { id: localId, project_id = 0 } = currentProject;
            if (project_id !== 0) localId = project_id;
            return localId !== id;
          })
        );
        await profileDispatch({
          type: UserProfileActions.SET_PROJECTS,
          payload: projectsList.filter(currentProject => {
            // eslint-disable-next-line camelcase
            let { id: localId, project_id = 0 } = currentProject;
            // eslint-disable-next-line camelcase
            if (project_id !== 0) localId = project_id;
            return localId !== id;
          })
        });
        if (id.toString() === projectId) {
          history.push('/account/activity');
        }
      } else {
        const payload = {
          title: updatedTitle,
          // description for a project
          description: profileState.projectDescription[projectId]
        };
        // Check whether the new value is same as old or no value or changing to existing value.
        if (updatedTitle !== '') {
          let allowUpdate = true;
          for (let index = 0; index < projectsList.length; index += 1) {
            const ele = projectsList[index];
            if (ele.name === updatedTitle) {
              allowUpdate = false;
              msg = 'Project already exists';
              setErrorMsg('Project already exists');
              break;
            }
          }
          if (allowUpdate) {
            res = await updateProject(id, payload);
            setProjectsList(list =>
              list.map(currentProject => {
                let { id: localId, project_id = 0 } = currentProject;
                if (project_id !== 0) localId = project_id;
                if (localId === id) {
                  project.name = updatedTitle;
                }
                return project;
              })
            );
            const projectName = { ...profileState.projectName };
            projectName[id] = payload.title;
            profileDispatch({
              type: UserProfileActions.SET_PROJECT_NAME,
              value: projectName
            });
            await profileDispatch({
              type: UserProfileActions.SET_PROJECTS,
              payload: projectsList.filter(currentProject => {
                let { id: localId, project_id = 0 } = currentProject;
                if (project_id !== 0) localId = project_id;
                if (localId === id) {
                  project.name = updatedTitle;
                }
                return project;
              })
            });
            removeEditMode();
          }
        }
      }
      setLoading(false);

      if (res && res.status === 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: {
            message:
              action === 'delete' ? 'Project deleted successfully' : 'Project updated successfully',
            status: true,
            color: 'success'
          }
        });
        await profileDispatch({ type: UserProfileActions.SET_REFETCH, value: true });
      } else {
        throw new Error(msg);
      }
    } catch (e) {
      setLoading(false);
      await dispatch({
        type: Actions.SET_ALERT,
        value: { message: e.message, status: true }
      });
    }
  };
  const params = location.pathname.split('/');
  const projectId = params.length > 2 ? params[3] : '';
  return (
    <>
      <ListItem
        onBlur={onBlurItem}
        sx={{ minHeight: '60px', paddingRight: '10px' }}
        onMouseEnter={() => {
          onMouseActionChange('enter');
        }}
        onMouseLeave={() => {
          onMouseActionChange('leave');
        }}
        secondaryAction={
          <Box>
            {hoverMode ? (
              <Stack direction='row'>
                <Tooltip title='Delete Project'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => toggleopenDialog(true)}
                    edge='end'>
                    <Delete fontSize='small' />
                  </IconButton>
                </Tooltip>

                <Tooltip title='Edit Project Name'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => onEditMode()}
                    edge='end'>
                    <EditOutlinedIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
              </Stack>
            ) : (
              <Box
                sx={{
                  fontSize: '10px',
                  padding: '5px 5px',
                  backgroundColor: 'gray.border',
                  borderRadius: '50%',
                  aspectRatio: '1/1',
                  minWidth: '20px',
                  textAlign: 'center'
                }}>
                {active_items_count || '0'}
              </Box>
            )}
          </Box>
        }>
        {editMode ? (
          <TextField
            sx={{ marginLeft: '16px', marginRight: '50px' }}
            inputRef={inputRef}
            onChange={onChangeValidation}
            onBlur={onBlurItem}
            variant='outlined'
            defaultValue={name}
            autoFocus
            error={errorMsg}
            helperText={errorMsg}
            size='small'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip title='Cancel'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={removeEditMode}
                      edge='end'>
                      <CancelOutlinedIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Submit'>
                    <IconButton
                      disabled={!allowEditUpdate}
                      aria-label='toggle password visibility'
                      onClick={() => {
                        handleDeleteUpdate('update', inputRef.current.value);
                      }}
                      edge='end'>
                      <CheckCircleOutlineOutlinedIcon
                        color={allowEditUpdate ? 'primary' : 'default'}
                        fontSize='small'
                      />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
        ) : (
          <StyledListItemButton
            disabled={loading}
            selected={location.pathname.startsWith('/account/project') && projectId === `${id}`}
            sx={{ pl: 5 }}
            key={id}
            onClick={() => handleClick(`/project/${id}`)}>
            <ListItemIcon sx={{ mr: '8px !important' }}>
              <LabelOutlinedIcon sx={{ fontSize: 20, mr: 1, color: 'gray.light' }} />
            </ListItemIcon>
            <ListItemText primary={name} />
          </StyledListItemButton>
        )}
      </ListItem>
      <Dialog open={openDialog} onClose={() => toggleopenDialog(false)} maxWidth='xs' fullWidth>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this project: {name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => toggleopenDialog(false)}
            variant='contained'
            sx={{ textTransform: 'initial !important', color: 'white !important' }}>
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            onClick={() => handleDeleteUpdate('delete')}
            sx={{ textTransform: 'initial !important', color: 'white !important' }}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(ProjectListItem);
