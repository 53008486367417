import React, { useCallback, useContext, useEffect } from 'react';

// mui
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  Divider,
  Stack,
  ListItem,
  Chip
} from '@mui/material';
import { CrossIcon, DownloadIcon } from '../../../assets/svgs/Icons';

import useQuery from '../../../helpers/customHooks/queryParameter';

import { VISUALIZE_HEADER } from '../constants';
import AddToReportButton from '../Buttons/AddToReportButton';
import ShareButton from '../Buttons/ShareButton';
import BusinessIntelligence from '../../../components/Biologics/BusinessIntelligence/ResultsPageIndex';
import ResultsStore from '../../../store/SearchResults';

import CTTimelineGraph from '../../../components/Timeline/CTGraph/CTTimelineGraph';
import CTAdverseEventsListView from '../../../components/Timeline/CTGraph/CTAdverseEventsListView';
import CTGraphs from '../../../components/ClinicalTrials/Graphs';
import { analyticViewMapping } from '../../ResultsPage/utils/analyticViewMapping';
import AnalyticView from '../../ResultsPage/components/AnalyticView';
import styles from '../../../components/CustomComponents/BottomDrawer/BottomDrawer.styles';
import EUCTBusinessIntellegence from '../../../components/Timeline/CTGraph/EUCTBusinessIntellegence';
import EUCTAdverseEventsListView from '../../../components/Timeline/CTGraph/EUCTAdverseEventListView';
import IndicationApprovalTimline from './IndicationApprovalTimeline/indicationApprovalTimline';
import handleDownload from '../utils/downloadCSVData';
import RESULT_VIEW_TYPES from './constants';

const Visualize: React.FC<any> = ({ handleClose, applications, tab = '' }) => {
  const [selectedTab, setSelectedTab] = React.useState<string>('businessIntelligence');
  const { resultsState } = useContext(ResultsStore);

  const query = useQuery();
  const [source, setSource] = React.useState<string>('us');

  useEffect(() => {
    if (tab) {
      setSource(Object.keys(resultsState?.applicationResults)[0]);
      setSelectedTab('matchSubmissions');
    } else if (
      resultsState?.viewType === RESULT_VIEW_TYPES.CT &&
      Object.values(resultsState.decryptedSource).some(
        valueList => Array.isArray(valueList) && valueList.includes('usnlm')
      )
    ) {
      setSource(RESULT_VIEW_TYPES.CT);
      setSelectedTab('businessIntelligenceCT');
    } else if (
      resultsState?.viewType === RESULT_VIEW_TYPES.CT &&
      Object.values(resultsState.decryptedSource).some(
        valueList => Array.isArray(valueList) && valueList.includes('euctr')
      )
    ) {
      setSource('euct');
      setSelectedTab('businessIntelligenceEUCT');
    } else if (
      applications.some((obj: any) => {
        return obj.source === 'us';
      })
    ) {
      setSource('us');
    } else if (
      applications.some((obj: any) => {
        return obj.source === 'eu';
      })
    ) {
      setSource('eu');
      setSelectedTab('analyticsView');
    } else {
      setSource('default');
      setSelectedTab('analyticsView');
    }
  }, [query, resultsState.viewType]);

  const handleChange = (key: string) => {
    setSelectedTab(key);
  };

  const getSource = useCallback(() => {
    if (selectedTab === 'businessIntelligence') {
      return ['us'];
    }
    if (source === 'ct') {
      return ['ct'];
    }
    if (Object.keys(resultsState.applicationResults).length !== 0) {
      return Object.keys(resultsState.applicationResults).map((item: string) =>
        item === 'ie' ? 'hpra' : item
      );
    }
    return '';
  }, [resultsState.applicationResults, selectedTab]);

  const getSearchTerm = useCallback(() => {
    if (Object.keys(resultsState.decryptedPayload)) {
      if ('query' in resultsState.decryptedPayload) {
        return resultsState.decryptedPayload.query;
      }
    }
    return '';
  }, [resultsState.decryptedPayload]);

  const filterApplicationBySource = () => {
    if (
      Object.keys(resultsState.applicationResults).length !== 0 &&
      source === 'us' &&
      'us' in resultsState.applicationResults
    ) {
      return resultsState.applicationResults[source].results;
    }
    return [];
  };

  return (
    <Box>
      <Box sx={styles.headerWrapper}>
        <Box sx={styles.titleWrapper}>
          <Typography sx={styles.title}>Visualize</Typography>
          <Box sx={{ ...styles.actionsWrapper, ...{ left: '97%' } }}>
            <Tooltip title='Close'>
              <IconButton
                onClick={(e: any) => {
                  e.preventDefault();
                  handleClose();
                }}>
                <CrossIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Box sx={styles.dividerWrapper}>
          <Divider sx={{ ...styles.divider, ...{ marginBottom: '36px' } }} />
        </Box>

        <Stack
          direction='row'
          justifyContent='space-between'
          sx={{ width: '100%', backgroundColor: 'white.main', px: '40px', mb: '22px' }}>
          <Stack direction='row'>
            {VISUALIZE_HEADER[source].slice(0, 6).map((type: any) => {
              return (
                <ListItem
                  key={type.key}
                  sx={{
                    pl: 0,
                    pr: '8px',
                    py: 0
                  }}>
                  <Chip
                    onClick={() => handleChange(type.key)}
                    sx={{
                      fontSize: 13,
                      '&:hover': {
                        backgroundColor: (theme: any) =>
                          `${theme.palette.primary.backgroundLight} !important`,
                        borderColor: (theme: any) => `${theme.palette.primary.main} !important`
                      },
                      '&.MuiChip-filled': {
                        backgroundColor: (theme: any) =>
                          `${theme.palette.primary.lightVariant1} !important`,
                        color: (theme: any) => `${theme.palette.primary.darkVariant2} !important`,
                        fontWeight: 'bold'
                      },
                      '& > .MuiChip-label': {
                        padding: '8px 16px'
                      },
                      color: (theme: any) => theme.palette.primary.darkVariant1,
                      borderColor: (theme: any) => theme.palette.gray.lightVariant8
                    }}
                    variant={selectedTab === type.key ? 'filled' : 'outlined'}
                    label={type.label}
                  />
                </ListItem>
              );
            })}
          </Stack>
          <Stack direction='row' alignItems='center' justifyContent='center' spacing={2}>
            <AddToReportButton
              source={getSource()}
              search={getSearchTerm()}
              module='core'
              searchType={resultsState.decryptedPayload?.searchType ?? 'quicksearch'}
              sourceType='SEARCH_ID'
              disableSelectionSource={false}
            />
            <Box m='auto 0'>
              <Divider orientation='vertical' sx={styles.verticalDivider} />
            </Box>
            <ShareButton searchType='' search='' />
            {selectedTab === 'matchSubmissions' && (
              <DownloadIcon
                onClick={() => {
                  handleDownload(applications, resultsState?.decryptedPayload?.query ?? '');
                }}
                sx={styles.buttonIcon}
              />
            )}
          </Stack>
        </Stack>
      </Box>
      <Divider />
      <Box sx={{ ...styles.childrenWrapper, ...{ height: '66vh' } }}>
        {selectedTab === 'businessIntelligence' && (
          <BusinessIntelligence payload={filterApplicationBySource()} />
        )}
        {selectedTab === 'businessIntelligenceCT' && (
          <CTGraphs payload={resultsState?.ctBussinessIntelligence} />
        )}
        {['timelineGantt', 'timelineGanttEUCT'].includes(selectedTab) && (
          <CTTimelineGraph type='gantt' />
        )}
        {['timelineChanges', 'timelineChangesEUCT'].includes(selectedTab) && (
          <CTTimelineGraph type='changes' />
        )}
        {selectedTab === 'adverseEffect' && <CTAdverseEventsListView />}
        {selectedTab === 'analyticsView' && (
          <AnalyticView applicationData={analyticViewMapping(applications)} />
        )}
        {selectedTab === 'businessIntelligenceEUCT' && <EUCTBusinessIntellegence />}
        {selectedTab === 'adverseEffectEUCT' && <EUCTAdverseEventsListView />}
        {selectedTab === 'matchSubmissions' && <IndicationApprovalTimline />}
      </Box>
    </Box>
  );
};

export default React.memo(Visualize);
