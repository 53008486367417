import { Box } from '@mui/material';
import React from 'react';

import CoverPage from '../../../assets/svgs/coverPage';

const CoverPageTemplate3 = ({ children }: any) => {
  const [LogoComponent, TitleComponent, , PreparedByName, PreparedDate, PoweredByLogo]: any =
    children;

  return (
    <Box height='100%' width='100%' position='relative'>
      <CoverPage />
      <Box position='absolute' top='0' right='0' zIndex='999'>
        {LogoComponent}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          width: 360,
          transform: 'translate(-50%, -50%)',
          zIndex: '999',
          '& .se-wrapper-inner': {
            color: 'primary.main',
            bgcolor: 'transparent'
          },
          '& .sun-editor': {
            color: 'primary.main',
            bgcolor: 'transparent'
          }
        }}>
        {TitleComponent}
        <Box textAlign='center'>
          <Box mt={1}>{PreparedByName}</Box>
          <Box mt={1}>{PreparedDate}</Box>
        </Box>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 20,
          textAlign: 'center',
          width: '100%',
          zIndex: '999'
        }}>
        <Box display='inline-block' sx={{ scale: 1.3 }}>
          {PoweredByLogo}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(CoverPageTemplate3);
