import React, { Fragment, useContext, useEffect, useState } from 'react';
//material ui imports
import PdfStyles from './PdfPreview.styles';
import DialogStyles from '../../pages/CardsPage/styles/Dialog.styles';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

//context store
import Store from '../../store';
import Actions from '../../store/actions';
import AuthContext from '../../store/Auth/AuthProvider';

//other components
import HomeReport from './reports/HomeReport';
import AnalyticView from './reports/AnalyticViewReport';
import CardDetailsReport from './reports/CardDetailsReport';
import ApplicationContentReport from './reports/ApplicationContentReport';
import BarGraphReport from './reports/BarGraphReport';
import PieGraphReport from './reports/PieGraphReport';
import ImmunoPieCharts from './reports/ImmunoPieCharts';
import RldReport from './reports/RldReport';
import Percentages from '../Biologics/BusinessIntelligence/Percentages';
import PercentageApproval from '../ApprovalPathway/BusinessIntelligence/Percentages';
import ProgressGraph from '../Biologics/Immunogenicity/ProgressGraph';
import Graph from '../PMR/Graphs';
import Graphs from '../ClinicalTrials/Graphs';
import Logo from '../../Images/svgs/logo';
import ApprovalHistoryReport from './reports/ApprovalHistory';
import PMRImpactReport from './reports/PMRImpactReport';

// api
import { RESTRICT_DOWNLOADS_BY_ORGANIZATION } from '../../const';

const PdfPreview = () => {
  const { currentUserId, currentUser } = useContext(AuthContext);
  const classes = PdfStyles();
  const dialogClasses = DialogStyles();
  const { state, dispatch } = useContext(Store);
  const [date, setDate] = useState();
  const [customName, setCustomName] = useState();
  const [open, setOpen] = useState();

  useEffect(() => {
    async function getData() {
      try {
        const date = new Date();
        const formattedDate =
          '' + (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
        setDate(formattedDate);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
    getData();
  }, []);

  const handleKeyChange = event => {
    setCustomName(event.target.value);
  };

  const closeDialog = () => {
    setCustomName('');
    setOpen(false);
  };

  const generatePdf = async event => {
    event.preventDefault();

    setOpen(false);
    if (RESTRICT_DOWNLOADS_BY_ORGANIZATION.includes(currentUser['custom:organization'])) {
      dispatch({
        type: Actions.SET_SERVICE_RESTRICTION_DETAILS,
        value: { status: true, closeBtn: true, message: '' }
      });
      return;
    }
    const element = document.getElementById('print');
    const options = {
      filename: `Vivpro-RIA-${customName}-${date}`
    };
    await dispatch({ type: Actions.SET_LOADING, value: true });

    /**
     * User Profile Reports
     * DO NOT DELETE !!
     */
    // const payload = {
    //   user_id: currentUserId,
    //   pdf_data: state.report,
    //   filename: customName
    // };
    // await addReport(payload);

    const { default: domToPdf } = await import('dom-to-pdf');

    return domToPdf(element, options, async () => {
      // callback function
      await dispatch({ type: Actions.SET_LOADING, value: false });
      await dispatch({ type: Actions.SET_CART_ITEMS, value: 0 });
      await dispatch({ type: Actions.SET_REPORT, value: [] });
      await dispatch({
        type: Actions.SET_PDF_MESSAGE,
        value: 'Your report is ready and downloaded'
      });
    });
  };
  const reset = async event => {
    event.preventDefault();
    await dispatch({ type: Actions.SET_CART_ITEMS, value: 0 });
    await dispatch({ type: Actions.SET_REPORT, value: [] });
  };
  return (
    <Fragment className={classes.root}>
      <Box display='flex' flexDirection='row' justifyContent='center' className={classes.header}>
        <Typography variant='h1'></Typography>
        <Toolbar>
          {state.cartItems > 0 ? (
            <>
              {state.loading ? (
                <CircularProgress className={classes.progress} />
              ) : (
                <>
                  <Button variant='contained' className={classes.btn} onClick={() => setOpen(true)}>
                    <Typography variant='body1'>Download</Typography>
                    <PrintOutlinedIcon fontSize='small' />
                  </Button>
                  <Button variant='contained' className={classes.btn} onClick={reset}>
                    <Typography variant='body1'>Reset</Typography>
                    <CancelOutlinedIcon fontSize='small' />
                  </Button>
                </>
              )}
            </>
          ) : null}
        </Toolbar>
      </Box>
      {state.cartItems > 0 ? (
        <Box className={classes.previewContainer}>
          <Box
            display='flex'
            id='print'
            flexDirection='column'
            justifyContent='center'
            className={classes.preview}>
            <Box display='flex' justifyContent='space-between'>
              {/* <img src={RIALogo} alt="logo" className={classes.logo} /> */}
              <Logo />
              <Typography variant='body1'>{date}</Typography>
            </Box>
            <Box display='flex' flexDirection='column' justifyContent='center'>
              <Typography variant='h1' className={classes.heading}>
                Intelligence Report
              </Typography>
              <Typography variant='body1'>
                <b>Prepared by </b>: VivProAI
              </Typography>
              <Typography variant='body1' align='xw'>
                <b>Prepared for </b> : {currentUser?.['custom:user']} <br></br>
                <b>Subscription</b> : {currentUser?.['custom:license']}
              </Typography>
              <Typography variant='subtitle2' align='left'>
                Unlock Regulatory Intelligence with RIA to:<br></br>
                -Decrease Time to Insights.<br></br>
                -Receive Higher Quality Intelligence.<br></br>
                -Efficient use of resources enabled by Technology!<br></br>
              </Typography>
            </Box>
            {state.report &&
              state.report.map(item => {
                return (
                  <>
                    {item.id === 'Home Report' ? <HomeReport data={item.data} /> : null}
                    {item.id === 'Card Details' ? <CardDetailsReport data={item.data} /> : null}
                    {item.id === 'Analytic View' ? (
                      <AnalyticView
                        results={item.data.results}
                        heading={item.data.heading}
                        source={item.data.source}
                        category={item.data.category}
                        module={item.data.module}
                        search={item.data.search}
                      />
                    ) : null}
                    {item.id === 'Application Content' ? (
                      <ApplicationContentReport data={item.data} />
                    ) : null}
                    {item.id === 'RLD Information' ? <RldReport data={item.data} /> : null}
                    {item.id === 'Bar Graph' ? <BarGraphReport data={item.data} /> : null}
                    {item.id === 'Pie Graph' ? <PieGraphReport data={item.data} /> : null}
                    {item.id === 'Immunogenicity Pie Charts' ? (
                      <ImmunoPieCharts data={item.data} />
                    ) : null}
                    {item.id === 'Approval History' ? (
                      <ApprovalHistoryReport data={item.data} />
                    ) : null}
                    {item.id === 'Progress Bars' ? (
                      <>
                        <Box display='flex' flexDirection='column' className={classes.boxRoot}>
                          <Paper elevation={0} className={classes.paper}>
                            <Typography variant='h3'>
                              {item.title} - Business Intelligence
                            </Typography>
                            {item.title === 'Biologics' ? (
                              <Percentages data={item.data} />
                            ) : (
                              <PercentageApproval data={item.data} />
                            )}
                          </Paper>
                        </Box>
                      </>
                    ) : null}
                    {item.id === 'Progress Graph' ? (
                      <>
                        <Box display='flex' flexDirection='column' className={classes.boxRoot}>
                          <Paper elevation={0} className={classes.paper}>
                            <Typography variant='h3'>Biologics</Typography>
                            <Typography variant='h3'>Immunogenicity - Overview</Typography>
                            <ProgressGraph data={item.data} />
                          </Paper>
                        </Box>
                      </>
                    ) : null}
                    {item.id === 'PMR' ? (
                      <>
                        <Box display='flex' flexDirection='column' className={classes.boxRoot}>
                          <Paper elevation={0} className={classes.paper}>
                            <Typography variant='h3'>PMR - Insights</Typography>
                            {/* <Typography variant="h3"></Typography> */}
                            <Graph graphData={item.data.graphData} path={item.data.path} />
                          </Paper>
                        </Box>
                      </>
                    ) : null}
                    {item.id === 'pmrImpact' ? (
                      <>
                        <Box display='flex' flexDirection='column' className={classes.boxRoot}>
                          <Paper elevation={0} className={classes.paper}>
                            <Typography variant='h3'>PMR - Insights</Typography>
                            {/* <Typography variant="h3"></Typography> */}
                            <PMRImpactReport data={item.data} />
                          </Paper>
                        </Box>
                      </>
                    ) : null}
                    {item.id === 'Clinical Trials' ? (
                      <>
                        <Box display='flex' flexDirection='column' className={classes.boxRoot}>
                          <Paper elevation={0} className={classes.paper}>
                            <Typography variant='h3'>
                              Clinical Trials - {item.data.searchTerm}{' '}
                            </Typography>
                            <Typography variant='h3'>Insights</Typography>
                            <Graphs
                              phaseGraph={item.data.phaseGraph}
                              CTCount={item.data.CTCount}
                              CTMOcount={item.data.CTMOcount}
                              statusGraph={item.data.statusGraph}
                              yearGraph={item.data.yearGraph}
                            />
                          </Paper>
                        </Box>
                      </>
                    ) : null}
                  </>
                );
              })}
          </Box>

          <Dialog
            maxWidth='md'
            open={open}
            aria-labelledby='dialog-submit-custom-name-report'
            aria-describedby='dialog-custom-name-description'>
            <DialogTitle>
              Report Name
              <IconButton
                className={dialogClasses.closeButton}
                aria-label='close'
                onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography>Add a custom name to the Report.</Typography>
                <TextField
                  required
                  className={dialogClasses.textfield}
                  onChange={handleKeyChange}
                  fullWidth
                  id='standard-basic'
                  placeholder=''
                  InputProps={{
                    classes: { input: classes.input },
                    disableUnderline: true
                  }}
                />
              </DialogContentText>
              <Button className={dialogClasses.reportSubmitButton} onClick={generatePdf}>
                Submit
              </Button>
            </DialogContent>
          </Dialog>
        </Box>
      ) : null}

      {state.cartItems > 0 ? null : (
        <Typography style={{ marginLeft: '2vw' }} variant='h3'>
          {state.pdfMessage}
        </Typography>
      )}
    </Fragment>
  );
};

export default PdfPreview;
