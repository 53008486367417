import React from "react";

// material ui
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// context
// styles
import Styles from "../../../styles/ApplicationContent.styles";

// image

const SubmissionSum = props => {
  const classes = Styles();

  return (
    <Grid container className={classes.submission}>
      <Grid item xs={12} className={classes.subheading}>
        <Typography className={classes.Heading}>Types of studies</Typography>
        <Grid container>
          <Grid item xs={4}>
            <Typography className={classes.subheadingLabel}>Phase 1</Typography>
            <Typography className={classes.subdata}>{props.data.Phase1}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.subheadingLabel}>Phase 2</Typography>
            <Typography className={classes.subdata}>{props.data.Phase2}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.subheadingLabel}>Phase 3</Typography>
            <Typography className={classes.subdata}>{props.data.Phase3}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.subheading}>
        <Typography className={classes.subheadingLabel}> No. of Indication</Typography>
        <Typography className={classes.subdata}>{props.data.number_of_indication}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.subheading}>
        <Typography className={classes.subheadingLabel}> Pivotal Evidence</Typography>
        <Typography className={classes.subdata}>{props.data.pivotal_evidence}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.subheading}>
        <Typography className={classes.subheadingLabel}> New Efficacy Data</Typography>
        <Typography className={classes.subdata}>{props.data.new_efficacy_data}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.subheading}>
        <Typography className={classes.subheadingLabel}>New Safety Data</Typography>
        <Typography className={classes.subdata}>{props.data.new_safety_data}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.subheading}>
        <Typography className={classes.subheadingLabel}>Number of Literature Studies</Typography>
        <Typography className={classes.subdata}>
          {props.data.number_of_literature_studies}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.subheading}>
        <Typography className={classes.subheadingLabel}> Number of Resubmissions</Typography>
        <Typography className={classes.subdata}>
          {props.data.number_of_resubmissions_numerical}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.subheading}>
        <Typography className={classes.subheadingLabel}> Reason for CR letter</Typography>
        <Typography className={classes.subdata}>{props.data.reason_for_cr_letter}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.subheading}>
        <Typography className={classes.subheadingLabel}> Study Indications</Typography>
        <Typography className={classes.subdata}>{props.data.study_indications}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.subheading}>
        <Typography className={classes.subheadingLabel}>
          {' '}
          Number of Studies (Label Section 14)
        </Typography>
        <Typography className={classes.subdata}>{props.data.number_of_studies}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.subheading}>
        <Typography className={classes.subheadingLabel}>Safety Database</Typography>
        <Typography className={classes.subdata}>{props.data.safety_database}</Typography>
      </Grid>
    </Grid>
  );
};

export default SubmissionSum;
