import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import Stack from '@mui/material/Stack';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import UserProfileStore from '../../../store/UserProfileV2';
import styles from '../styles/ProjectDialog.styles';
import { TitleDescriptionDialogProps } from '../interface';

const TitleDescriptionDialog = ({
  dialogType,
  heading,
  title,
  description,
  handleSubmit,
  handleClose,
  action = 'edit',
  titleMaxlength,
  descriptionMaxLength
}: TitleDescriptionDialogProps) => {
  const { profileState } = useContext(UserProfileStore) as any;
  const [currentTitle, setCurrentTitle] = useState(title ?? '');
  const [currentDescription, setCurrentDescription] = useState(description ?? '');
  const [errorMsg, setErrorMsg] = useState('');

  const checkValidation = useCallback((value: string) => {
    if (value.length === 0) {
      setCurrentTitle('');
      setErrorMsg('Project name is empty');
      return;
    }
    setCurrentTitle(value);
    const projectExists = profileState.projectList.some(
      (project: any) => project?.name === value.trim()
    );
    if (projectExists) {
      setErrorMsg('Project with a similar name already exists');
      return;
    }
    // No errors so clear the error message
    setErrorMsg('');
  }, []);

  const disableSubmit = useMemo(() => {
    return action === 'edit'
      ? currentTitle.length === 0 ||
          !!errorMsg ||
          (currentTitle === title && currentDescription === description)
      : false;
  }, [currentTitle, errorMsg, currentDescription]);

  return (
    <Dialog
      open={!!action}
      onClose={(_, r) => {
        if (r && r === 'backdropClick') return;
        handleClose();
      }}
      PaperProps={{
        onClick: (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()
      }}
      sx={styles.dialog}>
      <Stack direction='column' spacing='24px' padding='24px 24px 40px 24px'>
        <Stack alignItems='center' direction='column' justifyContent='center' spacing='24px'>
          <DialogTitle sx={{ padding: '0px' }}>
            <Stack direction='column'>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignSelf: 'stretch',
                  alignItems: 'center'
                }}>
                <Typography sx={styles.formHeading}>{heading}</Typography>
                <IconButton size='small' title='Close' onClick={handleClose} sx={styles.closeIcon}>
                  <Close />
                </IconButton>
              </Box>
              <Divider sx={styles.divider} />
            </Stack>
          </DialogTitle>
          <DialogContent
            sx={{
              width: '100%',
              p: 0
            }}>
            <Stack display='flex' flexDirection='column' justifyContent='space-evenly'>
              {action === 'edit' ? (
                <>
                  <Stack direction='column' mb='10px'>
                    <Typography sx={styles.fieldTitle}>Name</Typography>
                    <TextField
                      key='title'
                      size='small'
                      fullWidth
                      required
                      id='title'
                      variant='outlined'
                      defaultValue={title}
                      value={currentTitle}
                      onChange={event => checkValidation(event.target.value)}
                      error={!!errorMsg}
                      helperText={
                        errorMsg.length === 0
                          ? `${currentTitle.length}/${titleMaxlength}`
                          : errorMsg
                      }
                      sx={styles.textField}
                      autoFocus
                      inputProps={{
                        maxLength: titleMaxlength
                      }}
                    />
                  </Stack>

                  <Stack mb='20px' direction='column'>
                    <Typography sx={styles.fieldTitle}>Description</Typography>
                    <TextField
                      fullWidth
                      size='small'
                      id='description'
                      name='description'
                      variant='outlined'
                      multiline
                      defaultValue={description}
                      value={currentDescription}
                      onChange={event => setCurrentDescription(event.target.value)}
                      inputProps={{ maxLength: descriptionMaxLength }}
                      minRows={4}
                      helperText={`${currentDescription.length}/${descriptionMaxLength}`}
                      sx={styles.textField}
                    />
                  </Stack>
                </>
              ) : (
                <Typography sx={{ ...styles.fieldTitle, textAlign: 'center', mb: '24px' }}>
                  Do you want to delete the {dialogType}{' '}
                  {title && <strong>&quot;{title}&quot;</strong>} ?
                </Typography>
              )}

              <Stack display='flex' flexDirection='row' justifyContent='center'>
                <Button sx={styles.cancelButton} onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  disabled={disableSubmit}
                  sx={styles.submitButton}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (action === 'edit') {
                      handleSubmit(currentTitle, currentDescription);
                    } else {
                      handleSubmit();
                    }
                  }}>
                  {action === 'edit' ? 'Apply' : 'Delete'}
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default React.memo(TitleDescriptionDialog);
