import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// material ui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stack from '@mui/material/Stack';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import { isArray, isEmpty } from 'lodash';

// material icons
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CloseIcon from '../../../assets/svgs/CardsPage/CloseIcon';
// assets
import Reg360Icon from '../../../Images/reg360.svg';

// context
import GlobalStore from '../../../store';
import Actions from '../../../store/actions';

// other components
import ExtendedTabs from './ExtendedTabs';
import customTitleCase from '../../../utils/titleCase';
// styles
import Styles from '../styles/ExtendedCard.styles';
// api
import { get505b2SearchList, getExtendedCard, getSearchList } from '../../../api/pages/CardsPage';
import SuspenseCircularLoader from '../../../helpers/SuspenseCircularLoader';

// constant
import { SOURCE_NAME_MAPPING } from '../const';
import { APPROVAL_STATUS_TAGS } from '../../ResultsPage/utils/constants';
import CircularAvatar from '../../ResultsPage/components/CircularAvatar';

export const status = { YELLOW: ['(Refused)', '(Tentative Approval)'], RED: [' (Discontinued)'] };
const GaugeChart = lazy(() => import('react-gauge-chart'));

// eslint-disable-next-line react/prop-types
const ExtendedCard = ({ open, setOpen, cardNumber, source, module, onReg360Click }) => {
  const classes = Styles();
  const history = useHistory();
  const { category = '', search = '' } = useParams();
  const [cardState, setCardState] = useState();
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useContext(GlobalStore);

  const tags = [
    { key: 'formulation', tooltipPrefix: 'Formulation: ', titleCase: true },
    {
      key: 'route',
      tooltipPrefix: 'Route: ',
      titleCase: true
    },
    { key: 'approval_pathway', tooltipPrefix: 'Approval Pathway: ', titleCase: false },
    {
      key: 'product_code',
      tooltipPrefix: 'Product Code: ',
      titleCase: false
    },
    { key: 'device_class', tooltipPrefix: 'Device Class: ', titleCase: true },
    {
      key: 'schedule',
      tooltipPrefix: 'Schedule: ',
      titleCase: true
    }
  ];

  useEffect(() => {
    const getData = async () => {
      let categoryName = '';
      const cardDetails = await getExtendedCard(
        // eslint-disable-next-line react/prop-types
        SOURCE_NAME_MAPPING[source?.toLowerCase()] + cardNumber
      );
      const extendedCardsData = cardDetails.data.success[0];
      if ('active_ingredients' in extendedCardsData) {
        if (isArray(extendedCardsData.active_ingredients)) {
          extendedCardsData.active_ingredients = extendedCardsData.active_ingredients.join(': ');
        }
      }
      if ('active_ingredient' in extendedCardsData) {
        if (isArray(extendedCardsData.active_ingredient)) {
          extendedCardsData.active_ingredient = extendedCardsData.active_ingredient.join(': ');
        }
      }
      if ('trade_name' in extendedCardsData) {
        if (isArray(extendedCardsData.trade_name)) {
          extendedCardsData.trade_name = extendedCardsData.trade_name.join(': ');
        }
      }
      if ('formulation' in extendedCardsData) {
        if (isArray(extendedCardsData.formulation)) {
          extendedCardsData.formulation = extendedCardsData.formulation.join(': ');
        }
      }
      if ('route' in extendedCardsData) {
        if (isArray(extendedCardsData.route)) {
          extendedCardsData.route = extendedCardsData.route.join(': ');
        }
      }
      setCardState({ extendedCard: cardDetails.data.success[0] });
      // eslint-disable-next-line react/prop-types
      if (
        source?.toLowerCase() !== 'us-devices' &&
        !isEmpty(cardDetails.data.success[0]?.active_ingredients)
      ) {
        if (module === '505b2') {
          // Since there is no category after advance search we add "active_ingredients" as category
          // eslint-disable-next-line react/prop-types
          if (source.toLowerCase() === 'eu') {
            categoryName = 'active_ingredient';
          } else {
            categoryName = 'active_ingredients';
          }

          const response = await get505b2SearchList(
            categoryName,
            cardDetails.data.success[0].active_ingredients,
            source,
            module
          );
          await dispatch({ type: Actions.SET_TREE_DATA, value: response.data.body.Success });
        } else {
          // eslint-disable-next-line react/prop-types
          if (['eu', 'hpra', 'hma'].includes(source.toLowerCase())) {
            categoryName = 'active_ingredient';
          } else {
            categoryName = 'active_ingredients';
          }
          const response = await getSearchList(
            categoryName,
            cardDetails.data.success[0].active_ingredients,
            source
          );
          await dispatch({ type: Actions.SET_TREE_DATA, value: response.data.body.Success });
        }
      }

      setLoading(false);
    };
    getData();
    return () => {
      dispatch({ type: Actions.SET_TREE_DATA, value: {} });
    };
  }, [open, source]);

  const handleCommon360Click = card => {
    if (!onReg360Click) {
      try {
        const num = encodeURIComponent(card.application_number);
        const type = card.application_type;
        const navigation = JSON.parse(localStorage.getItem('navigation'));
        // eslint-disable-next-line react/prop-types
        if (source?.toLowerCase() === 'us') {
          navigation.regulatory = {
            url: `/regulatory360/${module}/${num}/${source}?type=${type}&category=${category}`,
            value: `Regulatory360:${type}${num}`
          };
        } else {
          navigation.regulatory = {
            url: `/regulatory360/${module}/${num}/${source}?category=${category}`,
            value: `Regulatory360:${num}`
          };
        }
        localStorage.setItem('navigation', JSON.stringify(navigation));
        // eslint-disable-next-line react/prop-types
        if (source?.toLowerCase() === 'us') {
          history.push(
            // eslint-disable-next-line react/prop-types
            `/regulatory360/${module}/${num}/${source?.toLowerCase()}?type=${type}&category=${category}`
          );
        } else {
          history.push(
            // eslint-disable-next-line react/prop-types
            `/regulatory360/${module}/${num}/${source?.toLowerCase()}?category=${category}`
          );
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    } else {
      const num = card.application_number;
      const type = card.application_type;
      onReg360Click(num, type, source?.toLowerCase());
    }
  };

  function getPercentage(value, lowerBound, upperBound, segmentAdjustment) {
    return (value - lowerBound) / (upperBound - lowerBound) / 3 + segmentAdjustment;
  }

  const getDialValue = value => {
    let result = 0;
    if (value >= 0 && value <= 365) {
      result = getPercentage(value, 0, 365, 0);
    } else if (value > 366 && value < 720) {
      result = getPercentage(value, 366, 720, 0.33);
    } else if (value >= 721) {
      if (value <= 1095) {
        result = getPercentage(value, 721, 1095, 0.66);
      } else if (value >= 1095) {
        result = getPercentage(value, 721, value, 0.66);
      }
    }
    return result;
  };

  const getCardStatusString = () => {
    if (cardState?.extendedCard.marketing_status === 'YELLOW') {
      return status[cardState?.extendedCard.marketing_status][1];
    }
    if (cardState?.extendedCard.marketing_status === 'RED') {
      return status[cardState?.extendedCard.marketing_status][0];
    }
    return '';
  };

  const getClassFromApplicationType = (type, task = 'number') => {
    if (type === 'NDA' || type === 'CANADA' || type === 'PMN' || type === 'HPRA') {
      return task === 'number' ? classes.numberNDA : classes.nameNDA;
    }
    if (type === 'EUA') {
      return task === 'number' ? classes.numberEUA : classes.nameEUA;
    }
    if (type === 'DENOVO') {
      return task === 'number' ? classes.numberDenovo : classes.nameDenovo;
    }
    return task === 'number' ? classes.numberBLA : classes.nameBLA;
  };

  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby='customized-dialog-title'
      open={open}
      fullWidth
      maxWidth='lg'
      disableScrollLock
      disableRestoreFocus>
      <Box
        className={classes.close}
        display='flex'
        onClick={() => setOpen(false)}
        justifyContent='flex-end'>
        <CloseIcon />
      </Box>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <DialogContent className={classes.root}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={10}>
                  <Stack>
                    <Stack
                      direction='row'
                      alignItems='center'
                      paddingBottom={2}
                      justifyContent='space-between'>
                      <Stack direction='row' alignItems='center'>
                        <Tooltip
                          disableFocusListener
                          placement='right-start'
                          title={`${
                            // eslint-disable-next-line react/prop-types
                            ['us', 'eu', 'ca', 'us-devices', 'hpra', 'hma'].includes(
                              source.toLowerCase()
                            )
                              ? 'Open Regulatory 360 view'
                              : 'Capture in Progress'
                          }`}>
                          <Box onClick={() => handleCommon360Click(cardState?.extendedCard)}>
                            <img
                              alt='regulatoryLogo'
                              className={classes.regIcon}
                              src={Reg360Icon}
                            />
                          </Box>
                        </Tooltip>
                        <Stack marginLeft={2}>
                          <Box className={classes.paperHeader} square borderRadius={3}>
                            <Stack padding={1.5}>
                              <Tooltip
                                disableFocusListener
                                placement='top-start'
                                title={cardState?.extendedCard?.active_ingredients}>
                                <Typography
                                  id='active-ingredient'
                                  variant='subtitle1'
                                  className={classes.title}
                                  noWrap>
                                  {`${cardState?.extendedCard?.active_ingredients
                                    ?.substring(0, 24)
                                    ?.replace(/ ;/g, '; ')}${
                                    cardState?.extendedCard?.active_ingredients?.length > 24
                                      ? '...'
                                      : ''
                                  }`}
                                </Typography>
                              </Tooltip>
                              <Stack direction='row' alignItems='center'>
                                <Typography
                                  id='application-number'
                                  paddingLeft={1}
                                  variant='body2'
                                  className={getClassFromApplicationType(
                                    cardState?.extendedCard.application_type,
                                    'number'
                                  )}>
                                  {cardState?.extendedCard.application_number}
                                </Typography>
                                <Tooltip
                                  title={`${
                                    cardState?.extendedCard.trade_name?.toUpperCase() +
                                    getCardStatusString()
                                  }`}
                                  aria-label='Button Link'>
                                  <Typography
                                    id='trade-name'
                                    variant='subtitle1'
                                    className={getClassFromApplicationType(
                                      cardState?.extendedCard.application_type,
                                      'name'
                                    )}>
                                    {`${(cardState?.extendedCard.trade_name + getCardStatusString())
                                      .substring(0, 15)
                                      .toUpperCase()}${
                                      (cardState?.extendedCard.trade_name + getCardStatusString())
                                        .length > 15
                                        ? '...'
                                        : ''
                                    }`}
                                  </Typography>
                                </Tooltip>
                              </Stack>
                            </Stack>
                          </Box>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack direction='row' paddingBottom={2} alignItems='center'>
                      <Stack direction='row'>
                        {tags.map(tag =>
                          Object.keys(cardState?.extendedCard).includes(tag.key) ? (
                            <Box
                              id={tag.key}
                              display='flex'
                              key={tag.key}
                              alignItems='center'
                              className={classes.tagText}>
                              <FiberManualRecordIcon className={classes.icon} />
                              <Tooltip
                                title={
                                  tag.titleCase
                                    ? `${tag.tooltipPrefix} ${customTitleCase(
                                        cardState?.extendedCard[tag.key].replace(/ ;/g, '; ') || ''
                                      )}`
                                    : `${tag.tooltipPrefix} ${
                                        cardState?.extendedCard[tag.key].replace(/ ;/g, '; ') || ''
                                      }`
                                }
                                aria-label='Button Link'>
                                <Typography variant='body3' className={classes.tagContent}>
                                  {tag.titleCase
                                    ? `${
                                        customTitleCase(
                                          cardState?.extendedCard[tag.key]
                                            .substring(0, 10)
                                            .replace(/ ;/g, '; ')
                                        ) || '---'
                                      }`
                                    : cardState?.extendedCard[tag.key]
                                        .substring(0, 10)
                                        .replace(/ ;/g, '; ') || '---'}
                                  {`${cardState?.extendedCard[tag.key].length > 10 ? '...' : ''}`}
                                </Typography>
                              </Tooltip>
                            </Box>
                          ) : null
                        )}
                        {!['ca', 'us-devices', 'hpra', 'hma'].includes(source) && (
                          <Tooltip
                            title={`Generics: ${cardState?.extendedCard.generic_count || '0'}`}>
                            <Box display='flex' alignItems='center' className={classes.tagText}>
                              <FiberManualRecordIcon className={classes.icon} />
                              <Typography variant='body3' className={classes.tagContent}>
                                Generics ({cardState?.extendedCard.generic_count || '0'})
                              </Typography>
                            </Box>
                          </Tooltip>
                        )}
                        {source === 'jp' && (
                          <Tooltip title={`Price: ${cardState?.extendedCard.price || '0'} ¥`}>
                            <Box display='flex' alignItems='center' className={classes.tagText}>
                              <FiberManualRecordIcon className={classes.icon} />
                              <Typography variant='body3' className={classes.tagContent}>
                                Price ({cardState?.extendedCard.price || '0'} ¥)
                              </Typography>
                            </Box>
                          </Tooltip>
                        )}
                        <CircularAvatar
                          data={cardState?.extendedCard}
                          avatarList={APPROVAL_STATUS_TAGS[source?.toLowerCase()]}
                          tooltipWithoutPrefix={source?.toLowerCase() === 'ct'}
                        />
                        {/* </Stack> */}
                      </Stack>
                    </Stack>
                  </Stack>
                </Grid>
                {['us-devices', 'hpra', 'hma'].includes(source) ? null : (
                  <Grid item xs={2} alignItems='center' className={classes.guageBox}>
                    <Stack>
                      <Typography className={classes.timeForApproval}>Time for Approval</Typography>
                      <Box>
                        <Suspense fallback={<SuspenseCircularLoader />}>
                          <GaugeChart
                            id='gauge-chart5'
                            nrOfLevels={20}
                            needleColor='#00A178'
                            colors={['#46B163', '#F8B518', '#FF5A48']}
                            percent={getDialValue(cardState?.extendedCard?.time_to_approval)}
                            arcPadding={0.05}
                            arcWidth={0.45}
                            hideText
                            needleBaseColor='#06C66C'
                          />
                        </Suspense>
                      </Box>

                      <Typography id='approval-time' className={classes.noOfDaysBox} margin={2}>
                        {cardState?.extendedCard?.time_to_approval} Days
                      </Typography>
                    </Stack>
                  </Grid>
                )}
              </Grid>
              <Box className={classes.extendedCard}>
                <ExtendedTabs
                  generic={cardState?.extendedCard.generic_count}
                  cardState={cardState}
                  source={source}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ExtendedCard;
