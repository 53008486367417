import React, { useState, useEffect, useRef } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Box } from '@mui/material';
import DiagonalDividedCell from '../components/DiagonalDividedCell';
import snapshotStyles from './styles';

interface Study {
  [key: string]: any;
}

interface Data {
  studies: Study[];
  text: string;
}

interface FlatStudy {
  [key: string]: any;
}

interface TableStructure {
  indicationStudies: { label: string; studies: number }[];
  studies: FlatStudy[];
  rowHeaders: string[];
  title: string;
}

const flattenStudies = (studies: any[]): FlatStudy[] => {
  const flattenObject = (
    obj: any,
    parentKey: string = '',
    isFirstDegree: boolean = true
  ): FlatStudy => {
    const flatStudy: FlatStudy = {};

    Object.keys(obj).forEach(key => {
      let newKey: any;
      if (isFirstDegree) {
        newKey = '';
      } else if (parentKey) {
        newKey = `${parentKey} ${key}`;
      } else {
        newKey = `${key}`;
      }
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        Object.assign(flatStudy, flattenObject(obj[key], newKey, false));
      } else {
        flatStudy[newKey] = obj[key];
      }
    });

    return flatStudy;
  };

  return studies.map(study => flattenObject(study));
};

const generateTableStructure = (data: Data[], title: string): TableStructure[] => {
  const tables: TableStructure[] = [];
  let currentTable: TableStructure = {
    indicationStudies: [],
    studies: [],
    rowHeaders: [],
    title
  };

  data?.forEach(item => {
    const flatStudies = flattenStudies(item.studies);
    flatStudies.forEach((study, studyIndex) => {
      // this is to create a new table when the number of studies exceed 5
      if (currentTable.studies.length >= 5) {
        // this is to add indicationStudies data when study need to be separated into another table of same indication text
        if (studyIndex > 0) {
          currentTable.indicationStudies.push({
            label: item.text,
            studies: currentTable.studies.length
          });
        }
        tables.push(currentTable);
        currentTable = {
          indicationStudies: [],
          studies: [],
          rowHeaders: [],
          title: `${title} (continued...)`
        };
      }

      // this is to display the unique row header name
      Object.keys(study).forEach(subKey => {
        currentTable.rowHeaders.push(subKey);
      });
      // this is to display the study data respective to their property
      currentTable.studies.push(study);
    });
    // this is to display the indication name and under which how many study is present in header
    currentTable.indicationStudies.push({
      label: item.text,
      studies: item?.studies?.length || 0
    });
  });

  // Push the last table if it has any studies
  if (currentTable.studies.length > 0) {
    tables.push(currentTable);
  }

  // Ensure unique row headers
  tables.forEach(table => {
    // eslint-disable-next-line no-param-reassign
    table.rowHeaders = Array.from(new Set(table.rowHeaders));
  });

  return tables;
};

const ClinicalSummary = ({ data, title, onUpdatePlacement }: any) => {
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [utilityTextHeight, setUtilityTextHeight] = useState<Number>(0);

  const tables = generateTableStructure(data, title);

  const getHeight = () => {
    if (tableRef?.current?.clientHeight) {
      const totalHeight =
        tableRef.current.clientHeight * (tables.length > 1 ? tables.length + 1 : tables.length) +
        30;
      onUpdatePlacement?.(Math.ceil(totalHeight / 10) + 1);
      return totalHeight;
    }
    return 0;
  };

  useEffect(() => {
    const height = getHeight();
    setUtilityTextHeight(height);
  }, [tableRef?.current?.clientHeight]);

  return (
    <Box>
      {tables?.map((table: any, tableIndex: number) => (
        <Box
          ref={tableRef}
          // eslint-disable-next-line react/no-array-index-key
          key={tableIndex}
          height={`calc(100% - ${utilityTextHeight}px)`}
          my='20px'>
          <Typography variant='subtitle2' sx={snapshotStyles.clinicalSummary.tableTitle}>
            {table.title}
          </Typography>
          <Table sx={snapshotStyles.clinicalSummary.table}>
            <TableHead sx={snapshotStyles.clinicalSummary.tableHead}>
              <TableRow>
                <DiagonalDividedCell bottomLeft='Studies Properties' topRight='Indications' />
                {table?.indicationStudies.map((each: any) => (
                  <TableCell
                    key={each?.label}
                    colSpan={each.studies}
                    sx={snapshotStyles.clinicalSummary.tableCellIndicationText}>
                    {each?.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {table?.rowHeaders.map((header: any) => (
                <TableRow key={header}>
                  <TableCell sx={snapshotStyles.clinicalSummary.tableCellHeader}>
                    {header}
                  </TableCell>
                  {table?.studies.map((study: any) => {
                    const cellValue = study[header];
                    let displayValue: any;

                    if (cellValue) {
                      if (typeof cellValue === 'object') {
                        displayValue = cellValue.title;
                      } else {
                        displayValue = cellValue;
                      }
                    } else {
                      displayValue = 'N/A';
                    }

                    return (
                      <TableCell
                        key={study?.Identifier}
                        sx={snapshotStyles.clinicalSummary.tableCellValue}>
                        {displayValue}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      ))}
    </Box>
  );
};

export default React.memo(ClinicalSummary);
