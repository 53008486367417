import React from 'react';

// material ui
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

// styles
import styles from '../styles/Skeleton.styles';

const PmrImpactSkeleton: React.FC = () => {
  return (
    <Box>
      <Grid container display='flex' flexDirection='row' flexWrap='nowrap'>
        <Grid item xs={12} lg={3} mt={2}>
          <Box ml={5} display='flex' flexDirection='column' sx={styles.cards}>
            <Skeleton sx={styles.paper} />
            <Skeleton sx={styles.paper} />
            <Skeleton sx={styles.paper} />
          </Box>
        </Grid>
        <Grid item xs={12} container style={{ paddingLeft: '20px' }}>
          <Grid item xs={12} sx={styles.centerDivImpact}>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-end'
              alignItems='space-between'
              sx={styles.loaderBox}>
              <Box
                display='flex'
                justifyContent='space-between'
                flexWrap='wrap-reverse'
                sx={styles.barGraphRect}>
                <Skeleton variant='rectangular' height='90%' width='.2%' />
                <Skeleton variant='rectangular' height='60%' width='3%' />
                <Skeleton variant='rectangular' height='80%' width='3%' />
                <Skeleton variant='rectangular' height='30%' width='3%' />
                <Skeleton variant='rectangular' height='50%' width='3%' />
                <Skeleton variant='rectangular' height='50%' width='3%' />
                <Skeleton variant='rectangular' height='60%' width='3%' />
                <Skeleton variant='rectangular' height='80%' width='3%' />
                <Skeleton variant='rectangular' height='30%' width='3%' />
                <Skeleton variant='rectangular' height='50%' width='3%' />
                <Skeleton variant='rectangular' height='50%' width='3%' />
                <Skeleton variant='rectangular' height='90%' width='3%' />
                <Skeleton variant='rectangular' height='60%' width='3%' />
                <Skeleton variant='rectangular' height='80%' width='3%' />
              </Box>
              <Skeleton variant='rectangular' height='.2%' width='100%' />
            </Box>
          </Grid>
          <Grid item xs={12} container style={{ paddingTop: '20px' }}>
            <Grid item xs={6} sx={styles.centerDivImpact}>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-end'
                alignItems='space-between'
                sx={styles.loaderBox}>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  flexWrap='wrap-reverse'
                  sx={styles.barGraphRect}>
                  <Skeleton variant='rectangular' height='90%' width='.2%' />
                  <Skeleton variant='rectangular' height='60%' width='3%' />
                  <Skeleton variant='rectangular' height='80%' width='3%' />
                  <Skeleton variant='rectangular' height='30%' width='3%' />
                  <Skeleton variant='rectangular' height='50%' width='3%' />
                  <Skeleton variant='rectangular' height='50%' width='3%' />
                  <Skeleton variant='rectangular' height='60%' width='3%' />
                  <Skeleton variant='rectangular' height='80%' width='3%' />
                  <Skeleton variant='rectangular' height='30%' width='3%' />
                  <Skeleton variant='rectangular' height='50%' width='3%' />
                  <Skeleton variant='rectangular' height='50%' width='3%' />
                  <Skeleton variant='rectangular' height='90%' width='3%' />
                  <Skeleton variant='rectangular' height='60%' width='3%' />
                  <Skeleton variant='rectangular' height='80%' width='3%' />
                </Box>
                <Skeleton variant='rectangular' height='.2%' width='100%' />
              </Box>
            </Grid>
            <Grid item xs={6} sx={styles.centerDivImpact}>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='flex-end'
                alignItems='space-between'
                sx={styles.loaderBox}>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  flexWrap='wrap-reverse'
                  sx={styles.barGraphRect}>
                  <Skeleton variant='rectangular' height='90%' width='.2%' />
                  <Skeleton variant='rectangular' height='60%' width='3%' />
                  <Skeleton variant='rectangular' height='80%' width='3%' />
                  <Skeleton variant='rectangular' height='30%' width='3%' />
                  <Skeleton variant='rectangular' height='50%' width='3%' />
                  <Skeleton variant='rectangular' height='50%' width='3%' />
                  <Skeleton variant='rectangular' height='60%' width='3%' />
                  <Skeleton variant='rectangular' height='80%' width='3%' />
                  <Skeleton variant='rectangular' height='30%' width='3%' />
                  <Skeleton variant='rectangular' height='50%' width='3%' />
                  <Skeleton variant='rectangular' height='50%' width='3%' />
                  <Skeleton variant='rectangular' height='90%' width='3%' />
                  <Skeleton variant='rectangular' height='60%' width='3%' />
                  <Skeleton variant='rectangular' height='80%' width='3%' />
                </Box>
                <Skeleton variant='rectangular' height='.2%' width='100%' />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default React.memo(PmrImpactSkeleton);
