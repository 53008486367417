const SearchLogo = () => {
  // return (
  //   <svg
  //     width='100%'
  //     height='100%'
  //     viewBox='0 0 17 17'
  //     fill='#464255'
  //     xmlns='http://www.w3.org/2000/svg'>
  //     <path
  //       d='M7.42569e-08 7.23299C-0.000131257 8.11744 0.173945 8.99326 0.512289 9.81044C0.850633 10.6276 1.34662 11.3701 1.97193 11.9956C2.59724 12.6211 3.33963 13.1173 4.1567 13.4559C4.97378 13.7945 5.84955 13.9689 6.734 13.969C8.22969 13.9708 9.68255 13.4696 10.859 12.546L14.559 16.246C14.7181 16.4051 14.934 16.4945 15.159 16.4945C15.384 16.4945 15.5999 16.4051 15.759 16.246C15.9181 16.0869 16.0075 15.871 16.0075 15.646C16.0075 15.4209 15.9181 15.2051 15.759 15.046L12.059 11.346C12.9758 10.1712 13.4725 8.72315 13.47 7.23299C13.47 5.44675 12.7604 3.73368 11.4974 2.47062C10.2343 1.20756 8.52123 0.497986 6.735 0.497986C4.94877 0.497986 3.23569 1.20756 1.97264 2.47062C0.709578 3.73368 7.42569e-08 5.44675 7.42569e-08 7.23299ZM6.734 2.18299C7.90259 2.18271 9.03512 2.58764 9.93863 3.32878C10.8421 4.06992 11.4607 5.10141 11.6889 6.2475C11.9172 7.39359 11.7409 8.58335 11.1903 9.61407C10.6396 10.6448 9.74862 11.4527 8.66907 11.9001C7.58952 12.3475 6.38823 12.4068 5.2699 12.0678C4.15156 11.7288 3.18537 11.0125 2.53596 10.0409C1.88654 9.06942 1.59409 7.90277 1.70843 6.73978C1.82277 5.5768 2.33682 4.48944 3.163 3.66299C4.11 2.71567 5.39451 2.1833 6.734 2.18299Z'
  //       fill='inherit'
  //     />
  //   </svg>
  // );
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      viewBox='0 0 24 24'
      fill='#464255a3'
    >
      <path
        id='Path_249'
        data-name='Path 249'
        d='M4.166,14.269a10.1,10.1,0,0,0,10.1,10.1,10,10,0,0,0,6.188-2.134L26,27.793A1.268,1.268,0,1,0,27.793,26l-5.556-5.544a10,10,0,0,0,2.134-6.188,10.1,10.1,0,0,0-20.2,0Zm10.1-7.577A7.577,7.577,0,1,1,8.911,8.911,7.577,7.577,0,0,1,14.268,6.692Z'
        transform='translate(-4.166 -4.167)'
        fill='inherit'
      />
    </svg>
  );
};
export default SearchLogo;
