export const FABAnimation: any = {
  '&:not(:disabled)': {
    animation: 'pulse 1s cubic-bezier(0.4, 0, 0.6, 1) 4',
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 0 rgba(51, 177, 135, 0.6)'
      },
      '70%': {
        transform: 'scale(1.05)',
        boxShadow: '0 0 0 10px rgba(51, 177, 135, 0)'
      },
      '100%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 0 rgba(51, 177, 135, 0)'
      }
    }
  }
};

export const FAB: any = {
  backgroundColor: 'primary.700',
  '&:hover': {
    backgroundColor: 'primary.main'
  },
  color: 'white.main',
  transition: 'width 3s ease-in',
  textTransform: 'none',
  width: 'fit-content',
  padding: '0 12px',
  height: '28px',
  fontSize: '14px',
  ...FABAnimation
};

export const FABAnimationInfinite: any = {
  '&:not(:disabled)': {
    animation: 'pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite',
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 0 rgba(51, 177, 135, 0.6)'
      },
      '70%': {
        transform: 'scale(1.05)',
        boxShadow: '0 0 0 10px rgba(51, 177, 135, 0)'
      },
      '100%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 0 rgba(51, 177, 135, 0)'
      }
    }
  }
};

export const ContainerStyle: any = {
  position: 'relative',
  display: 'flex',
  mr: 3
};

export const optionsContainerStyle: any = {
  position: 'absolute',
  zIndex: 5,
  left: -10
};

export const chipStyle: any = {
  mt: 1,
  backgroundColor: 'rgba(211,221,221,1)',
  minWidth: 200,
  maxWidth: 200,
  justifyContent: 'space-between',
  p: 1,
  color: 'primary.700'
};

export const badgeStyle: any = {
  '& .MuiBadge-badge': {
    height: '14px',
    width: '14px',
    minWidth: '14px',
    fontSize: '10px'
  }
};

export const exitButton: any = {
  borderRadius: '16px',
  textTransform: 'capitalize',
  ml: 1,
  height: '28px',
  backgroundColor: 'red.main',
  color: 'white.main',
  fontSize: '14px',
  border: 'unset',
  '&:hover': {
    backgroundColor: 'red.main',
    color: 'white.main',
    border: '1px solid #B73123'
  }
};

export const buttonIcon: any = { fontSize: '14px', height: '14px', width: '14px' };
