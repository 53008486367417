import React, { useCallback, useEffect, useMemo, useRef, lazy, Suspense } from 'react';
import { Box } from '@mui/material';
// mui
// hooks
import useCDPData from '../../../pages/CDP/hooks/useCDPData';
// utils
import { getTrialsGraphData } from '../../../pages/CDP/utils';
import SuspenseCircularLoader from '../../../helpers/SuspenseCircularLoader';

const TimeSeriesGraph = lazy(() => import('../../CustomComponents/Graphs/CustomTimeSeries'));

const CTTimelineGanttReport = ({
  fdaFilters,
  trialsFiltersCNF,
  showTrialsInSBAS = false,
  ctFiltersDateRange = {},
  trialsSource = 'ct'
}: any) => {
  const graphRef = useRef();
  const { responseData, getCDPData } = useCDPData();
  const addRef = useCallback((ref: any) => {
    graphRef.current = ref;
  }, []);

  const { barGraphData, nctIds } = useMemo(() => {
    const { barGraphData: bData, nctIds: ids } = getTrialsGraphData(responseData, showTrialsInSBAS);
    bData
      .filter((bar: any) => bar.customType === 'fake_label')
      .forEach((bar: any) => {
        // eslint-disable-next-line no-shadow
        if (bar.trialInformation) {
          // eslint-disable-next-line no-param-reassign
          bar.text = bar.trialInformation;
        }
      });
    return { barGraphData: bData, nctIds: ids };
  }, [responseData.barGraphData, showTrialsInSBAS]);

  useEffect(() => {
    getCDPData(trialsFiltersCNF, fdaFilters, '', ctFiltersDateRange, false, trialsSource);
  }, [fdaFilters, trialsFiltersCNF, ctFiltersDateRange, trialsSource]);

  return (
    <Box height='100%' width='100%'>
      {barGraphData ? (
        <Suspense fallback={<SuspenseCircularLoader />}>
          <TimeSeriesGraph
            ref={addRef}
            barGraphData={barGraphData}
            shapes={responseData.shapes}
            annotations={responseData.annotations}
            yAxisIds={nctIds}
            onHorizontalBarClick={null}
            plotConfig={{
              toImageButtonOptions: {
                filename: `clinical_trials_timeline_plot`
              }
            }}
          />
        </Suspense>
      ) : null}
    </Box>
  );
};

export default React.memo(CTTimelineGanttReport);
