import { memo, useState, useContext, useEffect, useCallback } from 'react';

// material ui
import { Box, Popover } from '@mui/material';
import EUCTRPediatrics from '../../../store/EUCTRPediatrics';
import actions from '../../../store/EUCTRPediatrics/actions';

import NewFilters from '../../NewFilters';

// hooks
import useEUCTRPediatricsData from '../hook/useEUCTRPediatricsData';

// components
import EUCTRPediatricsDetails from '../components/Details';
import EUCTRPediatricsInsights from '../components/insights';
import ReportWidgets from '../../Report/Widgets';
import BottomDrawer from '../../CustomComponents/BottomDrawer';
import SubHeader from '../../SubHeader';

// styles
import { Stat } from '../../../pages/SearchResults/types';
import { EUCTR_PEDIATRICS_FILTERS } from '../const';

const EUCTRPediatricsTabs = () => {
  const { euctrPediatricsState, euctrPediatricsDispatch } = useContext(EUCTRPediatrics) as any;
  const { isLoading, euctrPediatricsFilters, sortFields, getEUCTRPedData } =
    useEUCTRPediatricsData();
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [euctrPediatricsStats, setEuctrPediatricsStats] = useState<Stat[]>([]);

  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] =
    useState<HTMLElement | null>(null);

  useEffect(() => {
    if (Object.keys(euctrPediatricsFilters).length === 0) {
      getEUCTRPedData([], 20, 0, []);
    }
    if (euctrPediatricsState?.euctrPediatricsStats) {
      const newStats: Stat[] = [];
      newStats.push({
        title: 'Therapeutic Areas',
        value: euctrPediatricsState?.euctrPediatricsStats?.total_unique_therapeutic_areas_count
      });
      newStats.push({
        title: 'Active Substances',
        value: euctrPediatricsState?.euctrPediatricsStats?.total_unique_active_substances_count
      });
      newStats.push({
        title: 'Total Trials',
        value: euctrPediatricsState?.euctrPediatricsStats?.total_count
      });
      setEuctrPediatricsStats(newStats);
    }
  }, [euctrPediatricsFilters]);

  const handleFilterApply = useCallback(
    (currentfilters: any) => {
      let limit = 20;
      let pageSize = 0;
      if (Object.keys(euctrPediatricsState.pagination).length !== 0) {
        const pageModel: any = euctrPediatricsState.pagination;
        limit = pageModel?.pageSize;
      }
      euctrPediatricsDispatch({
        type: actions.SET_PAGINATION,
        value: { pageSize: limit, page: pageSize }
      });
      getEUCTRPedData(currentfilters, limit, pageSize, sortFields);
    },
    [sortFields, euctrPediatricsState.pagination]
  );

  const getFiltersLabelList = () => {
    return EUCTR_PEDIATRICS_FILTERS.reduce((acc: any, elem: any) => {
      acc[elem.value] = elem.label;
      return acc;
    }, {});
  };

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };
  return (
    <>
      <SubHeader
        title='EU Clinical Trials Register'
        subTitle='Pediatric studies in scope of Art45 of the Pediatric Regulation'
        stats={euctrPediatricsStats}
        handleVisualizeOpen={handleVisualizeOpen}
      />

      <Box>
        {!isLoading && (
          <Popover
            open={!!reportChoicesOpenAnchorElement}
            anchorEl={reportChoicesOpenAnchorElement}
            onClose={() => setReportChoiceAnchorElement(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            sx={{
              '& .MuiPopover-paper': {
                borderRadius: '16px !important',
                backgroundColor: 'none'
              }
            }}>
            <Box>
              <ReportWidgets
                widgets={['pie-widget', 'bar-widget', 'table-widget']}
                title=''
                dataSource={{
                  source: 'euctr',
                  moduleName: 'paediatrics',
                  disableSelectionSource: true,
                  filters: euctrPediatricsState.availableFilters,
                  filterLabelList: getFiltersLabelList()
                }}
                pathMetaData={{
                  module: 'pediatrics',
                  href: window.location.href
                }}
                setReportWidgetClose={setReportChoiceAnchorElement}
              />
            </Box>
          </Popover>
        )}
        <EUCTRPediatricsDetails />
      </Box>

      <NewFilters
        data={euctrPediatricsFilters}
        applyFilters={handleFilterApply}
        isLoading={isLoading}
      />

      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}>
        <EUCTRPediatricsInsights />
      </BottomDrawer>
    </>
  );
};

export default memo(EUCTRPediatricsTabs);
