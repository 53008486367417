/* eslint-disable */
import React, { useContext } from "react";

// material ui
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// other components
import ProductsTab from "./ProductsTab";
import GenericsTab from "./GenericsTab";
import ExclusivityTab from "./ExclusivityTab";

// styles
import Styles from "../styles/ExtendedTabs.styles";

// context
import GlobalStore from "../../../store";
import SearchList from "../../../components/SearchList/SearchList";
import IndicationsTab from "./IndicationsTab";
import PosologyTab from "./PosologyTab";

// const
import { EXTENDED_CARD_POSOLOGY_SOURCE_SECTIONS, EXTENDED_CARD_SOURCE_SECTIONS, SOURCE_NAME_MAPPING } from "../const";
import Patent from "./Patent";

const TabPanel = props => {
  const { children, value, index } = props;

  return (
    <Box role='tabpanel' hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

const ExtendedTabs = props => {
  const classes = Styles();
  const [value, setValue] = React.useState('Indications and Usage');
  const { cardState, source } = props;
  const { state } = useContext(GlobalStore);

  const handleChange = (event, newValue) => {
    if (newValue === 'Patents') {
      if (cardState && cardState.extendedCard.patent && cardState.extendedCard.patent.length >= 1) {
        setValue(newValue);
      }
    } else if (newValue === 'Exclusivity') {
      if (
        cardState &&
        cardState.extendedCard.exclusivity &&
        cardState.extendedCard.exclusivity.length >= 1
      ) {
        setValue(newValue);
      }
    } else {
      setValue(newValue);
    }
  };

  return (
    <Box className={classes.root}>
      <AppBar position='static' color='transparent' elevation={0} className={classes.tabContainer}>
        <Tabs
          id={value}
          value={value}
          onChange={handleChange}
          classes={{ indicator: classes.indicator }}
          className={classes.tabs}
          aria-label='tabs'>
          <Tab
            id='Indications and Usage'
            value='Indications and Usage'
            label='Indications and Usage'
            className={value === 'Indications and Usage' ? classes.activeTab : classes.inactiveTab}
          />
          {source?.toLowerCase() !== 'us-devices' &&
          cardState.extendedCard.application_type !== 'EUA' &&
          cardState &&
          cardState.extendedCard.products &&
          cardState.extendedCard.products.length > 0 ? (
            <Tab
              id='Products'
              value='Products'
              label='Products'
              className={value === 'Products' ? classes.activeTab : classes.inactiveTab}
            />
          ) : null}

          {cardState &&
          cardState.extendedCard.patent &&
          cardState.extendedCard.patent.length > 0 ? (
            <Tab
              id='Patents'
              value='Patents'
              label='Patents'
              className={value === 'Patents' ? classes.activeTab : classes.inactiveTab}
            />
          ) : null}

          {cardState &&
          cardState.extendedCard.exclusivity &&
          cardState.extendedCard.exclusivity?.length > 0 ? (
            <Tab
              id='Exclusivity'
              value='Exclusivity'
              label='Exclusivity'
              className={value === 'Exclusivity' ? classes.activeTab : classes.inactiveTab}
            />
          ) : null}

          {props?.generic > 0 ? (
            <Tab
              id='Generics'
              value='Generics'
              label='Generics'
              className={value === 'Generics' ? classes.activeTab : classes.inactiveTab}
            />
          ) : null}
          {state.treeData && source?.toLowerCase() !== 'us-devices' ? (
            <Tab
              id='Synonyms'
              value='Synonyms'
              label='Synonyms'
              className={value === 'Synonyms' ? classes.activeTab : classes.inactiveTab}
            />
          ) : null}
          {['us', 'eu', 'hpra'].includes(source?.toLowerCase()) && (
            <Tab
              value='Posology'
              label='Posology'
              className={value === 'Posology' ? classes.activeTab : classes.inactiveTab}
            />
          )}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index='Indications and Usage' className={classes.tabPanel}>
        <IndicationsTab
          source={source?.toLowerCase()}
          applnum={`${SOURCE_NAME_MAPPING[source?.toLowerCase()].toLowerCase()}${
            cardState?.extendedCard.application_number
          }`}
          section={EXTENDED_CARD_SOURCE_SECTIONS[source?.toLowerCase()]}
          IndicationsField={cardState.extendedCard.indications_and_usage || ''}
        />
      </TabPanel>
      <TabPanel value={value} index='Products' className={classes.tabPanel}>
        <ProductsTab cardState={cardState} source={source} />
      </TabPanel>
      <TabPanel value={value} index='Patents' className={classes.tabPanel}>
        <Patent cardState={cardState} source={source} />
      </TabPanel>
      <TabPanel value={value} index='Exclusivity' className={classes.tabPanel}>
        <ExclusivityTab cardState={cardState} />
      </TabPanel>
      {props.generic > 0 ? (
        <TabPanel value={value} index='Generics' className={classes.tabPanel}>
          <GenericsTab cardState={cardState} />
        </TabPanel>
      ) : null}
      <TabPanel value={value} index='Synonyms' className={classes.tabPanel}>
        <Box padding={1}>
          <SearchList showCount={false} />
        </Box>
      </TabPanel>
      <TabPanel value={value} index='Posology' className={classes.tabPanel}>
        <PosologyTab
          applnum={`${source?.toLowerCase()}${cardState?.extendedCard.application_number}`}
          section={EXTENDED_CARD_POSOLOGY_SOURCE_SECTIONS[source?.toLowerCase()]}
          dosageAdministrationField={
            cardState?.extendedCard?.dosage_and_administration || 'Data capture in progress.'
          }
        />
      </TabPanel>
    </Box>
  );
};

export default ExtendedTabs;
