import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  avatar: {
    backgroundColor: `${theme.palette.white} !important`,
    width: '80px !important',
    height: '80px !important',
    color: `${theme.palette.black} !important`
  },
  avatarHeader: {
    backgroundColor: `#C9D2F6 !important`,
    width: '30px !important',
    height: '30px !important',
    color: `${theme.palette.white} !important`,
    fontSize: '1em !important',
    fontWeight: '900 !important',
    borderRadius: 3
  },
  textButton: {
    padding: `0 !important`,
    textTransform: `initial !important`,
    color: `${theme.palette.secondary.light} !important`,
    '&:disabled': {
      color: 'grey !important'
    }
  },
  list: {
    '& > div.Mui-selected': {
      backgroundColor: `initial !important`,
      '& > div ': {
        fontWeight: `900 !important`,
        color: `${theme.palette.secondary.dark} !important`
      }
    },
    '& > div >div.Mui-selected': {
      backgroundColor: `initial !important`,
      '& > div ': {
        fontWeight: `900 !important`,
        color: `${theme.palette.secondary.dark} !important`
      }
    }
  }
}));
