import React, { useEffect, useState } from 'react';

// material ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// styles
import Styles from '../../../styles/ApplicationContent.styles';

// child component
import AdministrativeSum from './AdministrativeSum';
import SubmissionSum from './SubmissionSum';
import StudyDetails from './StudyDetails';
import StudyGraph from './StudyGraph';
import Patient from './Patient';

const ApplicationContent = props => {
  const classes = Styles();

  const [adminData, setAdminData] = useState();
  const [submissionData, setSubmissionData] = useState();
  const [tableData, setTableData] = useState();
  const [patientData, setPatientData] = useState();
  const [studyData, setStudyData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      try {
        let data = props.data.success[0];

        setAdminData(data['administrative_Summary']);
        setSubmissionData(data['submission_summary']);
        setTableData(data['study_details']);
        setPatientData(data['no_of_patients']);
        setStudyData(data['study_duration']);
        setLoading(false);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
    getData();
  }, [props]);

  if (loading) {
    return null;
  } else {
    return (
      <Grid container spacing={4} className={classes.applicationRoot}>
        <Grid item xs={3}>
          <Typography className={classes.Heading}>Administrative Summury</Typography>
          {adminData && Object.keys(adminData).length !== 0 ? (
            <AdministrativeSum data={adminData} />
          ) : (
            <Grid container className={classes.capture}>
              Capture in Progress
            </Grid>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.Heading}>Submission Summury</Typography>
          {submissionData && Object.keys(submissionData).length !== 0 ? (
            <SubmissionSum data={submissionData} />
          ) : (
            <Grid container className={classes.capture}>
              Capture in Progress
            </Grid>
          )}
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.Heading}>Clinical Summury</Typography>

          <Grid container className={classes.clinical}>
            <Grid item xs={12}>
              {tableData && Object.keys(tableData).length !== 0 ? (
                <StudyDetails data={tableData} />
              ) : (
                <Grid container className={classes.capture2} spacing={3}>
                  Capture in Progress
                </Grid>
              )}
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                {patientData && Object.keys(patientData).length !== 0 ? (
                  <Patient data={patientData} />
                ) : (
                  <Grid container className={classes.capture2} spacing={3}>
                    Capture in Progress
                  </Grid>
                )}
              </Grid>
              <Grid item xs={6}>
                {studyData && Object.keys(studyData).length !== 0 ? (
                  <StudyGraph data={studyData} />
                ) : (
                  <Grid container className={classes.capture2} spacing={3}>
                    Capture in Progress
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

export default ApplicationContent;
