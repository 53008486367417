import { createSvgIcon } from '@mui/material';

const OpenLinkIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
    <path
      d='M8.25 2C7.9043 2 7.625 2.2793 7.625 2.625C7.625 2.9707 7.9043 3.25 8.25 3.25H9.86523L5.93359 7.18359C5.68945 7.42773 5.68945 7.82422 5.93359 8.06836C6.17773 8.3125 6.57422 8.3125 6.81836 8.06836L10.75 4.13477V5.75C10.75 6.0957 11.0293 6.375 11.375 6.375C11.7207 6.375 12 6.0957 12 5.75V2.625C12 2.2793 11.7207 2 11.375 2H8.25ZM3.5625 2.625C2.69922 2.625 2 3.32422 2 4.1875V10.4375C2 11.3008 2.69922 12 3.5625 12H9.8125C10.6758 12 11.375 11.3008 11.375 10.4375V8.25C11.375 7.9043 11.0957 7.625 10.75 7.625C10.4043 7.625 10.125 7.9043 10.125 8.25V10.4375C10.125 10.6094 9.98438 10.75 9.8125 10.75H3.5625C3.39062 10.75 3.25 10.6094 3.25 10.4375V4.1875C3.25 4.01562 3.39062 3.875 3.5625 3.875H5.75C6.0957 3.875 6.375 3.5957 6.375 3.25C6.375 2.9043 6.0957 2.625 5.75 2.625H3.5625Z'
      fill='#616161'
    />
  </svg>,
  'OpenLinkIcon'
);

export default OpenLinkIcon;
