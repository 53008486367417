const styles = {
  roundedMenu: {
    '& .MuiPaper-root': { padding: '8px 0px !important' },
    '& .MuiList-root': { padding: '0 !important' }
  },
  buttonIcon: { height: '16px', width: '16px', color: 'gray.700' },
  primaryButtonIcon: { height: '16px', width: '16px', color: 'primary.700' },
  primaryButtonIconLoading: { height: '16px', width: '16px', color: 'gray.400' },

  button: {
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '600',
    ml: '8px',
    color: 'gray.700'
  },
  primaryButton: {
    fontSize: 14,
    textTransform: 'none',
    fontWeight: '400',
    ml: '4px',
    color: 'primary.700'
  },
  arrowDownIcon: { fontSize: 16, ml: 0.75, mr: '2px' },
  compareLabels: {
    menuContainer: {
      '& .MuiPaper-root': { padding: '16px !important' },
      '& .MuiList-root': {
        padding: '0 !important',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
      }
    },
    methodContainer: {
      display: 'flex',
      padding: '12px',
      gap: '12px',
      alignItems: 'center',
      borderRadius: '8px',
      border: '1px solid #E0E0E0',
      background: '#FBFBFB',
      cursor: 'pointer'
    },
    methodIcon: {
      width: '20px',
      height: '20px',
      color: 'primary.700'
    },
    methodTitle: {
      color: 'black.main',
      fontFamily: 'Mulish',
      fontSize: '13px',
      fontWeight: 700
    },
    methodDescription: {
      color: 'gray.800',
      fontFamily: 'Mulish',
      fontSize: '12px',
      fontWeight: 400
    }
  },
  visualizeDrawer: {
    '& >.MuiDrawer-paper': {
      height: '96vh',
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
      position: 'absolute',
      backgroundColor: 'white.smokeDark'
    }
  }
};

export default styles;
