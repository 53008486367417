import React, { useState, useRef, useCallback } from 'react';

// mui
import {
  Box,
  Grid,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
  Stack,
  IconButton,
  Typography
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

// styles
import styles from '../styles/homePage.styles';
import CustomChip from '../CustomChip';

const CommitteeInfo = ({ data }: any) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [committeeInfo, setCommitteeInfo] = useState<{
    [key: string]: string;
  }>(data.committee[0] || {});

  const handleInfoClick = useCallback((info: { [key: string]: string }) => {
    setCommitteeInfo(info);
    if (boxRef.current) {
      boxRef.current.scrollTop = 0;
    }
  }, []);

  return (
    <Stack id='meeting-preview' m={2} spacing={2} display='flex' flexDirection='column'>
      <Box sx={styles.info}>
        <Tooltip title='Click here for more information'>
          <Stack
            display='flex'
            alignItems='center'
            flexDirection='row'
            justifyContent='space-evenly'
            sx={{ height: '100%', cursor: 'pointer' }}
            onClick={() => {
              setOpen(true);
            }}>
            <Typography sx={styles.heading}>Adcomm Basic Info</Typography>
            <IconButton aria-label='toggle password visibility' edge='end'>
              <InfoOutlinedIcon fontSize='small' />
            </IconButton>
          </Stack>
        </Tooltip>
      </Box>
      <Box sx={styles.meetingContainer}>
        <Stack display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography sx={styles.graphHeading}>Upcoming</Typography>
        </Stack>
        <Box
          sx={{
            width: '100%',
            textAlign: 'left',
            backgroundColor: 'primary.backgroundDark',
            borderRadius: '16px',
            padding: 2
          }}>
          {data.upcoming.meeting_title ? (
            <Box display='flex' justifyContent='space-between' flexDirection='column'>
              <Tooltip title={data.upcoming.meeting_title}>
                <Typography noWrap sx={styles.dialogText}>
                  {data.upcoming.meeting_title}
                </Typography>
              </Tooltip>
              <Box display='flex' justifyContent='flex-end'>
                <CustomChip label={data.upcoming.center} />
              </Box>
              <br />
              <Typography style={{ fontSize: '16px' }}>{data.upcoming.title}</Typography>
              <Box>
                <Typography style={{ fontSize: '14px' }}>
                  Start: {data.upcoming.adcom_meeting_start_date}
                </Typography>
                <Typography style={{ fontSize: '14px' }}>
                  End: {data.upcoming.adcom_meeting_end_date}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box>
              <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
                No upcoming meetings found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={styles.meetingContainer}>
        <Stack display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography sx={styles.graphHeading}>Completed</Typography>
        </Stack>
        <Box
          sx={{
            width: '100%',
            textAlign: 'left',
            backgroundColor: 'primary.backgroundDark',
            borderRadius: '16px',
            padding: 2,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column'
          }}>
          {data.recently_completed.meeting_title ? (
            <Box display='flex' justifyContent='space-between' flexDirection='column'>
              <Tooltip title={data.recently_completed.meeting_title}>
                <Typography noWrap sx={styles.dialogText}>
                  {data.recently_completed.meeting_title}
                </Typography>
              </Tooltip>
              <Box display='flex' justifyContent='flex-end'>
                <CustomChip label={data.recently_completed.center} />
              </Box>
              <br />
              <Typography style={{ fontSize: '16px' }}>{data.recently_completed.title}</Typography>
              <Box>
                <Typography style={{ fontSize: '14px' }}>
                  Start: {data.recently_completed.adcom_meeting_start_date}
                </Typography>
                <Typography style={{ fontSize: '14px' }}>
                  End: {data.recently_completed.adcom_meeting_end_date}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box>
              <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
                No Recently Completed meetings found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth='lg'
        fullWidth>
        <DialogTitle>
          <Grid display='flex' justifyContent='space-between' alignItems='flex-start'>
            <Grid
              sx={{ fontSize: '20px', color: 'primary.dark' }}
              display='flex'
              justifyContent='center'>
              Adcomm Basic Information
            </Grid>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => {
                setOpen(false);
              }}
              edge='end'>
              <CancelOutlinedIcon fontSize='small' />
            </IconButton>
          </Grid>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <Stack p={2} spacing={2} display='flex' flexDirection='row' sx={{ maxHeight: '70vh' }}>
            <Box
              sx={{
                ...styles.InfoBox,
                width: '40%'
              }}>
              {data.committee &&
                data.committee.map((item: any) => {
                  return (
                    <Stack spacing={1} key={item.title}>
                      <Box
                        display='flex'
                        flexDirection='column'
                        sx={{
                          marginTop: '5px',
                          padding: '12px 10px'
                        }}>
                        <Stack direction='row' spacing={1} alignItems='center'>
                          <Stack
                            direction='row'
                            alignItems='center'
                            onClick={() => {
                              handleInfoClick(item);
                            }}>
                            <Box display='flex' alignItems='center'>
                              <Typography
                                sx={
                                  item.title === committeeInfo?.title
                                    ? styles.selectedtitle
                                    : styles.unselectedtitle
                                }>{`${item.title}`}</Typography>
                              <CustomChip label={item.center} />
                            </Box>
                          </Stack>
                        </Stack>
                      </Box>
                    </Stack>
                  );
                })}
            </Box>
            {committeeInfo ? (
              <Box
                ref={boxRef}
                sx={{
                  width: '60%',
                  ...styles.InfoBox,
                  p: 2
                }}>
                {Object.keys(committeeInfo)
                  .filter((key: string) => !['acronym'].includes(key))
                  .map((item: any) => (
                    <Box
                      key={item.title}
                      sx={{
                        width: '100%',
                        textAlign: 'left',
                        backgroundColor: 'primary.backgroundDark',
                        marginBottom: '10px',
                        borderRadius: '16px',
                        padding: 2
                      }}>
                      <Typography sx={styles.dialogText}>{`${item}: `}</Typography>
                      <Typography style={{ fontSize: '14px' }}>{committeeInfo[item]}</Typography>
                    </Box>
                  ))}
              </Box>
            ) : null}
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default React.memo(CommitteeInfo);
