import React, { useCallback, useContext, useEffect, useState } from 'react';

// material ui
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

import store from '../../../store/HTADocuments';
// components
import HTADetails from '../components/Details';
import HTAInsights from '../components/Insights';
import SubHeader from '../../../components/SubHeader';
import BottomDrawer from '../../../components/CustomComponents/BottomDrawer';
import NewFilters from '../../../components/NewFilters';
import AriaDrawerCore from '../../SearchResults/components/AriaDrawerCore';

// utils
import useHTAData from '../hooks/useHTAData';
import ReportWidgets from '../../../components/Report/Widgets';
import { HTA_FILTERS, PAGINATION_DEFAULT_OPTIONS } from '../const';
import GlobalStore from '../../../store';
import AskRiaButton from '../../../components/Buttons/AskRiaButton';
import { Stat } from '../../SearchResults/types';
import AskRiaButtonStyles from '../../../components/Buttons/styles/AskRiaButton.styles';
import useAriaShareHandler from '../../../components/AriaShare/useAriaShareHandler';

// Not a True HOC.
const HTATabs = () => {
  const { HTAState } = useContext(store);
  const { dispatch } = useContext(GlobalStore) as any;
  const {
    htafilters,
    isLoading,
    getHTAData,
    sortFields,
    getDatawithDataGridFilter,
    dataGridFilter
  } = useHTAData();
  const [ariaModalOpen, setAriaModalOpen] = useState(false);
  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] =
    useState<HTMLElement | null>(null);
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [htaStats, setHtaStats] = useState<Stat[]>([]);
  const [sort, setSort] = useState([...sortFields]);
  const [pagination, setPagination] = useState({
    pageSize: PAGINATION_DEFAULT_OPTIONS.limit,
    page: PAGINATION_DEFAULT_OPTIONS.offset
  });
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);

  useEffect(() => {
    getHTAData([], PAGINATION_DEFAULT_OPTIONS.limit, PAGINATION_DEFAULT_OPTIONS.offset, []);
  }, []);

  useEffect(() => {
    if (HTAState?.HTAStats) {
      const newStats: Stat[] = [];
      newStats.push({
        title: 'Total',
        value: HTAState?.HTAStats?.totalGuidancesCount || 0
      });
      newStats.push({
        title: 'Reference Count',
        value: HTAState?.HTAStats?.totalNiceRefCount || 0
      });
      newStats.push({
        title: 'Recently Published',
        value: HTAState?.HTAStats?.totalLastThirtyDays || 0
      });
      newStats.push({
        title: 'Docs/Page',
        value: HTAState?.HTAStats?.pdfCount || 0,
        value2: HTAState?.HTAStats?.pageCount || 0
      });

      setHtaStats(newStats);
    }
  }, [htafilters]);

  useAriaShareHandler(isLoading, ariaModalOpen, setAriaModalOpen);

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  const handleFilterApply = useCallback(
    (currentfilters: any) => {
      setIsFilterUpdated(true);
      setPagination({ page: PAGINATION_DEFAULT_OPTIONS.offset, pageSize: pagination.pageSize });
      getHTAData(
        currentfilters,
        pagination.pageSize,
        PAGINATION_DEFAULT_OPTIONS.offset,
        sortFields
      );
    },
    [sortFields, pagination]
  );

  const getFiltersLabelList = () => {
    return HTA_FILTERS.reduce((acc: any, elem: any) => {
      acc[elem.value] = elem.label;
      return acc;
    }, {});
  };

  const getAriaDocuments = () => {
    const ariaDocuments = HTAState?.askRiaFilters?.map((item: any) => ({
      identifier: item
    }));

    return ariaDocuments || [];
  };

  return (
    <>
      <SubHeader
        title='UK NICE'
        subTitle='Showing list of Guidances published by UK NICE'
        stats={htaStats}
        handleVisualizeOpen={handleVisualizeOpen}
      />
      <Box>
        {!isLoading && (
          <Popover
            open={!!reportChoicesOpenAnchorElement}
            anchorEl={reportChoicesOpenAnchorElement}
            onClose={() => setReportChoiceAnchorElement(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            sx={{
              '& .MuiPopover-paper': {
                borderRadius: '16px !important',
                backgroundColor: 'none'
              }
            }}>
            <Box>
              <ReportWidgets
                widgets={['pie-widget', 'bar-widget', 'table-widget']}
                title=''
                dataSource={{
                  source: 'hta',
                  moduleName: 'guidance',
                  disableSelectionSource: true,
                  filters: HTAState.availableFilters,
                  filterLabelList: getFiltersLabelList()
                }}
                pathMetaData={{
                  module: 'hta',
                  href: window.location.href
                }}
                setReportWidgetClose={setReportChoiceAnchorElement}
              />
            </Box>
          </Popover>
        )}
        <HTADetails
          pagination={pagination}
          setPagination={setPagination}
          sort={sort}
          setSort={setSort}
          sortFields={sortFields}
          getHTAGuidanceData={getHTAData}
          isFilterUpdated={isFilterUpdated}
          setIsFilterUpdated={setIsFilterUpdated}
          getDatawithDataGridFilter={getDatawithDataGridFilter}
          dataGridFilter={dataGridFilter}
        />
      </Box>

      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}>
        <HTAInsights />
      </BottomDrawer>

      {ariaModalOpen && (
        <AriaDrawerCore
          applications={HTAState.isFiltersApplied ? getAriaDocuments() : []}
          sources={{ uk: ['nice'] }}
          groupId='entity_id'
          docCount={HTAState?.HTAStats?.pdfCount}
          docPageCount={HTAState?.HTAStats?.pageCount}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          dispatch={dispatch}
        />
      )}

      <NewFilters
        data={htafilters}
        applyFilters={handleFilterApply}
        isLoading={isLoading}
        selectedFilters={HTAState.availableFilters}
      />

      <Box sx={AskRiaButtonStyles.bottomFilterPosition}>
        <AskRiaButton
          iconId='aria'
          setOpenAriaSearch={setAriaModalOpen}
          text='Ask RIA'
          disabled={isLoading}
        />
      </Box>
    </>
  );
};

export default React.memo(HTATabs);
