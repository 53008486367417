import React from 'react';

const MoveIcon = () => {
  return (
    <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 0L7.11325 5L12.8868 5L10 0ZM9.5 8C9.5 8.27614 9.72386 8.5 10 8.5C10.2761 8.5 10.5 8.27614 10.5 8L9.5 8ZM9.5 4.5L9.5 8L10.5 8L10.5 4.5L9.5 4.5Z'
        fill='#2D95E0'
      />
      <path
        d='M10 20L12.8868 15L7.11325 15L10 20ZM10.5 12C10.5 11.7239 10.2761 11.5 10 11.5C9.72386 11.5 9.5 11.7239 9.5 12L10.5 12ZM10.5 15.5L10.5 12L9.5 12L9.5 15.5L10.5 15.5Z'
        fill='#2D95E0'
      />
      <path
        d='M20 10L15 7.11325V12.8868L20 10ZM12 9.5C11.7239 9.5 11.5 9.72386 11.5 10C11.5 10.2761 11.7239 10.5 12 10.5V9.5ZM15.5 9.5H12V10.5H15.5V9.5Z'
        fill='#2D95E0'
      />
      <path
        d='M0 10L5 12.8868L5 7.11325L0 10ZM8 10.5C8.27614 10.5 8.5 10.2761 8.5 10C8.5 9.72386 8.27614 9.5 8 9.5L8 10.5ZM4.5 10.5L8 10.5L8 9.5L4.5 9.5L4.5 10.5Z'
        fill='#2D95E0'
      />
    </svg>
  );
};

export default React.memo(MoveIcon);
