import React, { useRef, useState, useEffect } from 'react';
import { Stack, Typography, Divider } from '@mui/material';
import snapshotStyles from './styles';

const Section = ({ label, children }: any) => (
  <>
    <Stack direction='row' spacing={2} alignItems='center'>
      <Typography variant='subtitle2' sx={snapshotStyles.administrativeSummary.section}>
        {label}:
      </Typography>
      {children}
    </Stack>
    <Divider sx={{ mt: 2 }} />
  </>
);

const AdministrativeSummary = ({ data, title, onUpdatePlacement }: any) => {
  const administrativeSummaryRef = useRef<HTMLDivElement | null>(null);
  const [utilityTextHeight, setUtilityTextHeight] = useState<Number>(0);

  const getHeight = () => {
    const totalHeight = (administrativeSummaryRef?.current?.clientHeight || 0) + 10;
    onUpdatePlacement?.(Math.ceil(totalHeight / 10) + 1);
    return totalHeight;
  };

  useEffect(() => {
    const height = getHeight();
    setUtilityTextHeight(height);
  }, []);

  return (
    <Stack
      ref={administrativeSummaryRef}
      spacing={2}
      height={`calc(100% - ${utilityTextHeight}px)`}
      sx={snapshotStyles.administrativeSummary.main}>
      <Typography variant='subtitle1' sx={snapshotStyles.administrativeSummary.titleText}>
        {title}
      </Typography>

      {data?.length > 0 &&
        data?.map(({ label, value }: any) => (
          <Section key={label} label={label}>
            {typeof value === 'string' ? (
              <Typography variant='subtitle2' sx={snapshotStyles.administrativeSummary.valueText}>
                {value}
              </Typography>
            ) : (
              value
            )}
          </Section>
        ))}
    </Stack>
  );
};

export default React.memo(AdministrativeSummary);
