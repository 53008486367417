// react
import {
  memo,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';

// mui
import { IconButton, ListItemIcon, ListItemText, MenuItem, Tooltip } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { isArray } from 'lodash';
import {
  ChatBubbleIcon,
  DocumentPdfIcon,
  EyeDisabledIcon,
  OpenCdpIcon,
  Reg360Icon
} from '../../../assets/svgs/Icons';

import { CardActionMenuProps } from '../types';
import showReg360 from '../utils/showReg360';
import showChatRia from '../utils/showChatRia';
import ViewLatestLabel from './ViewLatestLabel';

// hooks
import { getSearchParams } from '../../../hooks/useBreadcrumbs';
import { encodeJSONtoString } from '../../../helpers/backToEncoding';
import FavoriteAndProjectActions from '../../../components/FavoritesAndProjectsAction/FavoriteAndProjectActions';
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import Actions from '../../../store/RegulatoryInformation/actions';
import { RoundedMenu } from '../../../components/ui/Menu';
import styles from '../styles/CardActionsMenu.styles';
import SubscriptionActions from '../../../components/Subscription/SubscriptionActions';
import showLatestLabel from '../utils/showLatestLabel';

const CardActionsMenu = ({
  data,
  hideApplication,
  handleReg360,
  triggerChatRIA,
  setFavoriteInHook,
  setInProjectInHook,
  setSubscriptionInHook
}: CardActionMenuProps) => {
  const {
    location: { pathname: locationPathName, search: locationSearch }
  } = window;
  const history = useHistory();
  const ref = useRef(null);

  const { source, isFavorite, isSubscribed, identifier } = data;
  const { regulatoryState, regulatoryDispatch } = useContext(RegulatoryInformationStore);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showPrecribingInfo, setShowPrecribingInfo] = useState<boolean>(false);

  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    if (!regulatoryState.db || !regulatoryState.applicationDetails?.application_number) {
      const applicationNumber = data.identifier;
      regulatoryDispatch({
        type: Actions.SET_APPLICATION_DETAILS,
        value: {
          ...regulatoryState.applicationDetails,
          application_number: applicationNumber
        }
      });
      regulatoryDispatch({ type: Actions.SET_DB, value: source });
    }
  }, []);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleViewLatestLabelClick = () => {
    setShowPrecribingInfo(!showPrecribingInfo);
  };

  const shouldShowReg360 = showReg360(data, source);
  const shouldShowChatRia = showChatRia(data, source);
  const shouldShowLatestLabel = showLatestLabel(data, source);

  const shouldShowCDP = useMemo(() => {
    return (
      ((data as any)?.center === 'CDER' || (data as any)?.center === 'CBER') &&
      (data as any)?.application_type !== 'EUA'
    );
  }, [source]);

  const handleCDPClick = useCallback(() => {
    if (!shouldShowCDP) return;
    const module = 'core';

    const genericData = data as any;
    const activeIngredients = isArray(genericData?.generic_name)
      ? genericData?.generic_name?.[0]
      : genericData?.generic_name;
    const backToObject = {
      module,
      path: locationPathName,
      search: getSearchParams(locationSearch || ''),
      name: 'Search Results'
    };
    const backToString = encodeJSONtoString(backToObject);
    const applicationNumber = encodeURIComponent(identifier);
    history.push(
      `/cdp/${module}/${applicationNumber}/${source}/${activeIngredients.toLowerCase()}?backTo=${backToString}`,
      { prevPath: locationPathName }
    );
  }, [data, source]);

  return (
    <>
      <Tooltip title='Options'>
        <IconButton
          id='long-button'
          ref={ref}
          aria-label='more'
          size='small'
          sx={{ color: 'black.main' }}
          onClick={handleOpenMenu}>
          <MoreVert sx={{ fontSize: 20 }} />
        </IconButton>
      </Tooltip>
      <RoundedMenu
        sx={styles.roundedMenu}
        id='card-actions-menu'
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        onClick={e => {
          // this stops the event from propagating to the parent and causing the card onClick to fire
          e.stopPropagation();
        }}>
        {shouldShowReg360 && (
          <MenuItem
            onClick={() => {
              handleReg360(true);
              handleCloseMenu();
            }}
            sx={styles.menuItem}>
            <ListItemIcon sx={styles.listItemIcon}>
              <Reg360Icon sx={styles.svgIcon} />
            </ListItemIcon>
            <ListItemText sx={styles.listItemText}>Open Reg 360°</ListItemText>
          </MenuItem>
        )}
        {shouldShowCDP && (
          <MenuItem onClick={handleCDPClick} sx={styles.menuItem}>
            <ListItemIcon sx={styles.listItemIcon}>
              <OpenCdpIcon sx={styles.svgIcon} />
            </ListItemIcon>
            <ListItemText sx={styles.listItemText}>Open CDP</ListItemText>
          </MenuItem>
        )}
        {shouldShowChatRia && (
          <MenuItem
            onClick={() => {
              triggerChatRIA(source, identifier);
              handleCloseMenu();
            }}
            sx={styles.menuItem}>
            <ListItemIcon sx={styles.listItemIcon}>
              <ChatBubbleIcon sx={styles.svgIcon} />
            </ListItemIcon>
            <ListItemText sx={styles.listItemText}>Chat RIA (Latest Label)</ListItemText>
          </MenuItem>
        )}
        {shouldShowLatestLabel && (
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              handleViewLatestLabelClick();
              handleCloseMenu();
            }}
            sx={styles.menuItem}>
            <ListItemIcon sx={styles.listItemIcon}>
              <DocumentPdfIcon sx={styles.svgIcon} />
            </ListItemIcon>
            <ListItemText sx={styles.listItemText}>View (Latest Label)</ListItemText>
          </MenuItem>
        )}

        {hideApplication && (
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              hideApplication({ source: data?.source, identifier: data?.identifier });
              handleCloseMenu();
            }}
            sx={styles.menuItem}>
            <ListItemIcon sx={styles.listItemIcon}>
              <EyeDisabledIcon sx={styles.svgIcon} />
            </ListItemIcon>
            <ListItemText sx={styles.listItemText}>Hide</ListItemText>
          </MenuItem>
        )}
        <SubscriptionActions
          invokedBy='application'
          isButton={false}
          isSubscribed={isSubscribed}
          source={source}
          id={identifier}
          setSubscriptionInHook={setSubscriptionInHook}
          handleMenuClose={handleCloseMenu}
        />
        <FavoriteAndProjectActions
          invokedBy='applications'
          isButton={false}
          isFavorite={isFavorite}
          inProjects={(data as any)?.in_projects ?? (data as any)?.inProjects}
          setFavoriteInHook={setFavoriteInHook}
          setInProjectInHook={setInProjectInHook}
          source={source}
          sourceIndex={(data as any)?.source_index}
          id={identifier}
        />
      </RoundedMenu>
      {showPrecribingInfo ? (
        <ViewLatestLabel open={showPrecribingInfo} setOpen={setShowPrecribingInfo} />
      ) : null}
    </>
  );
};

CardActionsMenu.defaultProps = {
  applicationIndex: -1,
  applicationGroup: null
};

export default memo(CardActionsMenu);
