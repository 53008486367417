import Box from '@mui/material/Box';
import React, { useCallback, useEffect, useState } from 'react';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import styles from './TextEditor.styles';

export const TextEditor = ({
  content = '',
  onChange,
  toolbarOpen = false,
  updateSectionPlacement = () => {},
  sectionId = null,
  disabled = false,
  // eslint-disable-next-line no-unused-vars
  isReferences = false
}: any) => {
  const [state, setState] = useState({ value: content });

  const getTotalHeight = () => {
    const seWrapper = document.querySelector(`#pdf-chart-section-${sectionId} .se-wrapper-wysiwyg`);
    const childElements = seWrapper ? seWrapper.children : [];
    // Calculate the sum of heights including margins
    const totalHeight = Array.from(childElements).reduce((sum, childElement: any) => {
      const computedStyle = window.getComputedStyle(childElement);
      // Add the total height including margins
      return (
        sum +
        childElement.offsetHeight +
        parseInt(computedStyle.marginTop, 10) +
        parseInt(computedStyle.marginBottom, 10)
      );
    }, 0);
    return totalHeight;
  };

  const autoAdjustHeight = useCallback(() => {
    const totalHeight = getTotalHeight();
    updateSectionPlacement(Number(totalHeight / 10));
  }, [updateSectionPlacement]);

  useEffect(() => {
    setTimeout(() => {
      const totalHeight = getTotalHeight();
      updateSectionPlacement(Number(totalHeight) / 10);
    }, 300);
  }, []);

  const handleChange = useCallback(
    (value: any) => {
      setState({ value });
      onChange?.(value);
      autoAdjustHeight();
    },
    [onChange, autoAdjustHeight]
  );

  return (
    <Box
      sx={{
        ...styles.main,
        '& .se-toolbar': {
          position: 'absolute',
          transform: 'translate(0, -102%)',
          top: '0',
          right: '0',
          display: !toolbarOpen ? 'none !important' : 'block'
        }
      }}
      className='ria-quill-editor-container'>
      <SunEditor
        defaultValue={state.value}
        disable={disabled}
        setOptions={{
          height: '100%',
          buttonList: [
            ['fontSize', 'formatBlock'],
            ['bold', 'underline', 'italic'],
            ['horizontalRule', 'lineHeight'],
            ['fontColor', 'hiliteColor'],
            ['align', 'list', 'lineHeight'],
            ['outdent', 'indent', 'table']
          ]
        }}
        onChange={handleChange}
      />
    </Box>
  );
};

export default React.memo(TextEditor);
