const css = `
.over_the_counter_icon{cursor:pointer}
`;

const OverTheCounterIcon = () => {
  return (
    <svg
      class='over_the_counter_icon'
      id='Component_10_1'
      data-name='Component 10 – 1'
      xmlns='http://www.w3.org/2000/svg'
      width='30.391'
      height='30.391'
      viewBox='0 0 30.391 30.391'
    >
      <g id='package' transform='translate(0 0)'>
        <g id='Group_822' data-name='Group 822' transform='translate(0 0)'>
          <path
            id='Path_475'
            data-name='Path 475'
            d='M12.03,30.391H3.166A3.169,3.169,0,0,1,0,27.225V3.166A3.169,3.169,0,0,1,3.166,0H19.627a3.169,3.169,0,0,1,3.166,3.166V12.03a.633.633,0,1,1-1.266,0V3.166a1.9,1.9,0,0,0-1.9-1.9H3.166a1.9,1.9,0,0,0-1.9,1.9V27.225a1.9,1.9,0,0,0,1.9,1.9H12.03a.633.633,0,0,1,0,1.266Z'
            transform='translate(0 0)'
            fill='#07c56f'
          />
        </g>
        <g id='Group_823' data-name='Group 823' transform='translate(3.799 3.799)'>
          <path
            id='Path_476'
            data-name='Path 476'
            d='M7.432,6.8H4.9A1.9,1.9,0,0,1,4.9,3H7.432a1.9,1.9,0,1,1,0,3.8ZM4.9,4.266a.633.633,0,0,0,0,1.266H7.432a.633.633,0,1,0,0-1.266Z'
            transform='translate(-3 -3)'
            fill='#07c56f'
          />
        </g>
        <g id='Group_824' data-name='Group 824' transform='translate(3.799 10.13)'>
          <path
            id='Path_477'
            data-name='Path 477'
            d='M7.432,11.8H4.9A1.9,1.9,0,0,1,4.9,8H7.432a1.9,1.9,0,1,1,0,3.8ZM4.9,9.266a.633.633,0,0,0,0,1.266H7.432a.633.633,0,1,0,0-1.266Z'
            transform='translate(-3 -8)'
            fill='#07c56f'
          />
        </g>
        <g id='Group_825' data-name='Group 825' transform='translate(3.799 16.462)'>
          <path
            id='Path_478'
            data-name='Path 478'
            d='M7.432,16.8H4.9a1.9,1.9,0,0,1,0-3.8H7.432a1.9,1.9,0,1,1,0,3.8ZM4.9,14.266a.633.633,0,0,0,0,1.266H7.432a.633.633,0,1,0,0-1.266Z'
            transform='translate(-3 -13)'
            fill='#07c56f'
          />
        </g>
        <g id='Group_826' data-name='Group 826' transform='translate(3.799 22.793)'>
          <path
            id='Path_479'
            data-name='Path 479'
            d='M7.432,21.8H4.9a1.9,1.9,0,1,1,0-3.8H7.432a1.9,1.9,0,1,1,0,3.8ZM4.9,19.266a.633.633,0,0,0,0,1.266H7.432a.633.633,0,1,0,0-1.266Z'
            transform='translate(-3 -18)'
            fill='#07c56f'
          />
        </g>
        <g id='Group_827' data-name='Group 827' transform='translate(12.663 3.799)'>
          <path
            id='Path_480'
            data-name='Path 480'
            d='M14.432,6.8H11.9a1.9,1.9,0,0,1,0-3.8h2.533a1.9,1.9,0,1,1,0,3.8ZM11.9,4.266a.633.633,0,1,0,0,1.266h2.533a.633.633,0,1,0,0-1.266Z'
            transform='translate(-10 -3)'
            fill='#07c56f'
          />
        </g>
        <g id='Group_828' data-name='Group 828' transform='translate(12.663 10.13)'>
          <path
            id='Path_481'
            data-name='Path 481'
            d='M14.432,11.8H11.9a1.9,1.9,0,0,1,0-3.8h2.533a1.9,1.9,0,0,1,0,3.8ZM11.9,9.266a.633.633,0,1,0,0,1.266h2.533a.633.633,0,1,0,0-1.266Z'
            transform='translate(-10 -8)'
            fill='#07c56f'
          />
        </g>
        <g id='Group_829' data-name='Group 829' transform='translate(12.663 16.462)'>
          <path
            id='Path_482'
            data-name='Path 482'
            d='M11.406,16.7a.719.719,0,0,1-.277-.057A1.928,1.928,0,0,1,10,14.9,1.9,1.9,0,0,1,11.9,13h1.925a.633.633,0,0,1,0,1.266H11.9a.634.634,0,0,0-.633.633.652.652,0,0,0,.372.58.636.636,0,0,1,.394.584A.628.628,0,0,1,11.406,16.7Z'
            transform='translate(-10 -13)'
            fill='#07c56f'
          />
        </g>
        <g id='Group_830' data-name='Group 830' transform='translate(13.929 13.929)'>
          <path
            id='Path_483'
            data-name='Path 483'
            d='M15.432,27.462A4.432,4.432,0,0,1,12.3,19.9l7.6-7.6a4.432,4.432,0,0,1,6.268,6.268l-7.6,7.6A4.405,4.405,0,0,1,15.432,27.462Zm7.6-15.2a3.15,3.15,0,0,0-2.239.927l-7.6,7.6a3.166,3.166,0,0,0,4.478,4.478l7.6-7.6a3.166,3.166,0,0,0-2.239-5.4Z'
            transform='translate(-11 -11)'
            fill='#07c56f'
          />
        </g>
        <g id='Group_831' data-name='Group 831' transform='translate(19.021 19.026)'>
          <path
            id='Path_484'
            data-name='Path 484'
            d='M0,0H1.266V7.6H0Z'
            transform='translate(0 0.895) rotate(-45)'
            fill='#07c56f'
          />
        </g>
      </g>
    </svg>
  );
};

export default OverTheCounterIcon;
