import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

const ClearReportDialog = ({
  onClose,
  onClear,
  handleDialogClose,
  reportId = undefined,
  open = false
}: any) => {
  return (
    <Dialog open={open} onClose={handleDialogClose}>
      {reportId ? (
        <>
          <DialogTitle sx={{ padding: '16px 40px' }}>Close Report</DialogTitle>
          <DialogContent>
            <Box display='flex' p={2}>
              Make Sure You have saved all the changes made in this report.
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'end', pb: 2, pr: 4 }}>
            <Button onClick={handleDialogClose} variant='outlined'>
              Cancel
            </Button>
            <Button onClick={() => window.close()} variant='contained' sx={{ color: 'white.main' }}>
              Exit
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle sx={{ padding: '16px 40px' }}>Clear report?</DialogTitle>
          <DialogContent>
            <Box display='flex' p={2}>
              Would you like to clear and start a fresh report? <br />
              Don’t worry about losing your work. Your saved reports will always be accessible
              inside your Profile.
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'end', pb: 2, pr: 4 }}>
            <Button onClick={onClose} variant='outlined'>
              Exit
            </Button>
            <Button onClick={onClear} variant='contained' sx={{ color: 'white.main' }}>
              Clear and Exit
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default React.memo(ClearReportDialog);
