import React from 'react';

const BlueWaves = () => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 700 325.3'
      // @ts-ignore
      style={{ enableBackground: 'new 0 0 700 325.3' }}
      xmlSpace='preserve'>
      <style type='text/css'>
        {`    .st0{clip-path:url(#SVGID_00000142164727188002290040000005591808591336247732_);fill:url(#SVGID_00000021812123573376131050000002126642904816396424_);}
    
      .st1{clip-path:url(#SVGID_00000142164727188002290040000005591808591336247732_);fill:url(#SVGID_00000091732588062063909240000005455499116683329925_);}
    .st2{clip-path:url(#SVGID_00000142164727188002290040000005591808591336247732_);fill:none;}`}
      </style>
      <g>
        <defs>
          <rect
            id='SVGID_1_'
            x='0'
            y='31.6'
            transform='matrix(-1 -1.224647e-16 1.224647e-16 -1 700 404.8374)'
            width='700'
            height='341.7'
          />
        </defs>
        <clipPath id='SVGID_00000101814141948693354700000002932564881757792184_'>
          <use xlinkHref='#SVGID_1_' style={{ overflow: 'visible' }} />
        </clipPath>

        <linearGradient
          id='SVGID_00000054981192255895710020000016298657255025756577_'
          gradientUnits='userSpaceOnUse'
          x1='-412.4926'
          y1='129.4291'
          x2='971.3057'
          y2='129.4291'
          gradientTransform='matrix(0.996 -8.911448e-02 8.911448e-02 0.996 -24.0213 30.5277)'>
          <stop offset='0.2706' style={{ stopColor: '#FFFFFF' }} />
          <stop offset='0.422' style={{ stopColor: '#90B9F4' }} />
          <stop offset='0.6824' style={{ stopColor: '#58BFE8' }} />
          <stop offset='0.8566' style={{ stopColor: '#FFFFFF' }} />
        </linearGradient>

        <path
          style={{
            clipPath: 'url(#SVGID_00000101814141948693354700000002932564881757792184_)',
            fill: 'url(#SVGID_00000054981192255895710020000016298657255025756577_)'
          }}
          d='
      M285.6,128.3c-27.6-10-61.1-14.1-96.7-10.7c-2.8,0.3-5.7,0.6-8.6,1c-55.9,7.3-102.4,23.2-143.3,41.1c-17.7,7.7-35,14.2-53.6,18.3
      l0-0.7c18.1-4.1,35.2-10.4,52.6-18.1c41-17.9,87.7-34,143.9-41.3c2.9-0.4,5.8-0.7,8.6-1c36-3.5,70,0.7,97.8,10.8
      c30.4,11,69.6,21.2,104.9,29.3c50,11.4,121.3,15,181.1,9.2c10.8-1,21.2-2.4,31.1-4.1c36.3-6.1,64.2-20.2,94-31
      c4-1.5,8.1-2.9,12.2-4.3l-0.1,0.8c-3.8,1.3-7.6,2.7-11.3,4c-29.7,10.7-57.7,24.8-94.4,31.1c-9.9,1.7-20.4,3-31.3,4.1
      c-60.1,5.8-131.6,2.2-182-9.2C355.3,149.6,316.1,139.4,285.6,128.3z M467,168.6c34.2,2.2,70.2,1.8,102.9-1.3
      c10.4-1,20.4-2.3,29.9-3.9c36.8-6.1,65-20.1,94.8-30.9c3.3-1.2,6.6-2.4,9.9-3.6c1.7-0.6,3.5-1.2,5.3-1.8l0.1-0.8
      c-2,0.7-4.1,1.4-6.1,2.1c-3.3,1.2-6.7,2.4-10,3.6c-29.9,10.8-58,24.8-94.4,30.8c-9.5,1.6-19.4,2.8-29.7,3.8
      c-32.5,3.1-68.4,3.5-102.4,1.3c-29-1.9-56.8-5.5-80-10.9c-10.9-2.5-22.2-5.2-33.5-8.1c-25.1-6.4-50.4-13.7-71.5-21.2
      c-27.6-9.9-61.3-14-97-10.6c-3,0.3-6.1,0.6-9.1,1c-37.9,5-71.3,13.9-101.6,24.7c-14.6,5.2-28.4,10.8-41.7,16.6
      c-16.4,7.2-32.4,13.2-49.3,17.3l0,0.7c17.4-4.1,33.7-10.3,50.3-17.6c13.2-5.8,27-11.4,41.6-16.6c30.2-10.7,63.5-19.6,101.2-24.6
      c3-0.4,6-0.7,9-1c35.3-3.4,68.5,0.6,95.9,10.5c21.2,7.6,46.5,14.8,71.7,21.3c11.3,2.9,22.6,5.6,33.6,8.2
      C410,163.1,437.8,166.8,467,168.6z M464.1,168.8c34.6,2.3,71.2,2,104.3-1.2c9.9-1,19.4-2.2,28.5-3.6c36.9-6,65.3-19.9,95.1-30.7
      c3.3-1.2,6.6-2.4,10-3.6c2.6-0.9,5.2-1.8,7.8-2.7l0.1-0.8c-2.9,1-5.8,2-8.6,3c-3.4,1.2-6.7,2.4-10,3.6
      c-30,10.8-58.2,24.7-94.7,30.6c-9,1.5-18.5,2.7-28.3,3.6c-32.9,3.2-69.4,3.5-103.8,1.2c-29-1.9-56.7-5.7-79.8-11.1
      c-10.9-2.5-22.2-5.3-33.5-8.2c-25.1-6.5-50.5-13.8-71.7-21.3c-27.5-9.8-60.9-13.8-96.2-10.4c-3.2,0.3-6.4,0.7-9.6,1.1
      c-37.7,5-71.1,14-101.2,24.8c-14.5,5.2-28.3,10.8-41.5,16.6c-15.7,6.9-31.2,12.8-47.4,16.9l0,0.7c16.7-4.2,32.4-10.2,48.4-17.2
      c13.2-5.8,26.9-11.4,41.4-16.6c30-10.7,63.2-19.7,100.7-24.7c3.2-0.4,6.4-0.8,9.5-1.1c35-3.4,67.9,0.6,95.1,10.3
      c21.2,7.5,46.7,14.8,71.8,21.3c11.3,2.9,22.6,5.7,33.5,8.2C407.2,163,435,166.8,464.1,168.8z M462.4,168.8c35,2.4,72.2,2.2,105.7-1
      c9.4-0.9,18.5-2,27.2-3.4c37-5.9,65.5-19.7,95.5-30.6c3.3-1.2,6.6-2.4,10-3.6c3-1.1,6.1-2.1,9.1-3.2l0.1-0.8
      c-3.3,1.1-6.7,2.3-9.9,3.5c-3.4,1.2-6.7,2.4-10,3.6c-30.1,10.9-58.5,24.6-95.1,30.5c-8.6,1.4-17.6,2.5-27,3.4
      c-33.3,3.2-70.3,3.5-105.2,1c-28.9-2-56.5-5.9-79.7-11.3c-10.9-2.6-22.2-5.4-33.4-8.3c-25-6.5-50.6-13.8-71.9-21.3
      c-27.3-9.6-60.4-13.6-95.4-10.2c-3.4,0.3-6.7,0.7-10.1,1.2c-37.6,5.1-70.8,14.1-100.7,24.8c-14.5,5.2-28.2,10.8-41.3,16.6
      c-15.5,6.8-30.7,12.7-46.7,16.8l0,0.7c16.4-4.2,31.9-10.1,47.6-17.1c13.1-5.8,26.8-11.4,41.2-16.6c29.8-10.7,62.9-19.7,100.2-24.7
      c3.3-0.5,6.7-0.8,10-1.2c34.6-3.3,67.2,0.5,94.3,10.1c21.3,7.5,46.9,14.8,72,21.3c11.3,2.9,22.5,5.7,33.5,8.3
      C405.6,162.9,433.3,166.7,462.4,168.8z M461.7,168.7c35.4,2.6,73.1,2.4,107.1-0.9c8.9-0.9,17.5-1.9,25.8-3.2
      c37.1-5.8,65.8-19.6,95.9-30.4c3.3-1.2,6.6-2.4,10-3.6c3.1-1.1,6.2-2.2,9.3-3.3l0.1-0.8c-3.4,1.2-6.8,2.4-10.2,3.6
      c-3.4,1.2-6.7,2.4-10,3.6c-30.2,10.9-58.8,24.5-95.5,30.3c-8.2,1.3-16.8,2.3-25.6,3.2c-33.8,3.3-71.3,3.4-106.6,0.9
      c-28.9-2.1-56.4-6-79.5-11.5c-10.9-2.6-22.1-5.4-33.4-8.4c-25-6.5-50.7-13.9-72-21.3c-27.2-9.5-59.9-13.4-94.6-10
      c-3.5,0.3-7.1,0.8-10.6,1.2c-37.4,5.2-70.5,14.2-100.3,24.9c-14.4,5.2-28,10.8-41.1,16.6c-15.6,6.9-30.9,12.8-47,16.9l0,0.7
      c16.5-4.2,32.1-10.1,48-17.2c13-5.8,26.7-11.4,41-16.6c29.7-10.7,62.6-19.7,99.8-24.8c3.5-0.5,7-0.9,10.5-1.2
      c34.3-3.3,66.6,0.5,93.5,9.9c21.3,7.4,47.1,14.8,72.1,21.4c11.3,3,22.5,5.8,33.4,8.4C405,162.6,432.7,166.5,461.7,168.7z
       M461.9,168.5c35.8,2.7,74.1,2.6,108.5-0.7c8.4-0.8,16.6-1.8,24.4-3c37.2-5.7,66.1-19.4,96.2-30.2c3.3-1.2,6.7-2.4,10-3.6
      c2.9-1,5.8-2,8.7-3l0.1-0.8c-3.2,1.1-6.4,2.2-9.6,3.3c-3.4,1.2-6.7,2.4-10,3.6c-30.3,10.9-59.1,24.5-95.8,30.1
      c-7.8,1.2-15.9,2.2-24.3,3c-34.2,3.3-72.3,3.4-107.9,0.7c-28.8-2.2-56.3-6.2-79.4-11.8c-10.9-2.6-22.1-5.5-33.3-8.4
      c-24.9-6.6-50.8-14-72.2-21.3c-27-9.3-59.5-13.1-93.8-9.8c-3.7,0.4-7.4,0.8-11.1,1.3c-37.3,5.2-70.3,14.3-99.8,25
      c-14.3,5.2-27.9,10.8-40.9,16.6c-16,7.1-31.6,13.1-48.1,17.2l0,0.7c17-4.2,32.9-10.3,49.1-17.5c13-5.8,26.5-11.4,40.9-16.6
      c29.4-10.7,62.3-19.7,99.3-24.9c3.7-0.5,7.3-0.9,11-1.3c34-3.3,66,0.4,92.7,9.7c21.4,7.4,47.3,14.8,72.3,21.4
      c11.2,3,22.5,5.8,33.4,8.4C405.3,162.3,432.9,166.3,461.9,168.5z M462.7,168.2c36.2,2.8,75,2.8,109.8-0.6
      c7.9-0.8,15.7-1.7,23.1-2.8c37.3-5.6,66.4-19.2,96.6-30.1c3.3-1.2,6.7-2.4,10-3.6c2.4-0.9,4.9-1.7,7.4-2.6l0.1-0.8
      c-2.7,1-5.5,1.9-8.2,2.9c-3.4,1.2-6.7,2.4-10,3.6c-30.3,10.9-59.3,24.4-96.2,30c-7.4,1.1-15.1,2-23,2.8
      c-34.6,3.3-73.2,3.4-109.3,0.6c-28.8-2.3-56.2-6.3-79.2-12c-10.9-2.7-22.1-5.5-33.3-8.5c-24.9-6.6-50.9-14.1-72.3-21.4
      c-26.9-9.2-59-12.9-93-9.6c-3.8,0.4-7.7,0.8-11.6,1.4c-37.1,5.3-70,14.4-99.4,25.1c-14.3,5.2-27.8,10.8-40.8,16.6
      c-16.5,7.4-32.8,13.6-50,17.7l0,0.7c17.7-4.2,34.2-10.5,51-17.9c12.9-5.8,26.4-11.4,40.7-16.6c29.3-10.7,62-19.7,98.9-24.9
      c3.8-0.5,7.7-1,11.5-1.4c33.6-3.2,65.3,0.4,92,9.5c21.5,7.3,47.5,14.8,72.4,21.4c11.2,3,22.4,5.9,33.3,8.5
      C406.3,161.8,433.8,165.9,462.7,168.2z M464.2,167.9c36.6,3,75.9,3,111.2-0.4c7.5-0.7,14.7-1.6,21.8-2.6c37.4-5.6,66.6-19,97-29.9
      c3.3-1.2,6.7-2.4,10.1-3.6c1.8-0.6,3.6-1.3,5.4-1.9l0.1-0.8c-2.1,0.7-4.2,1.5-6.3,2.2c-3.4,1.2-6.7,2.4-10.1,3.6
      c-30.4,11-59.6,24.3-96.5,29.8c-7,1-14.2,1.9-21.6,2.6c-35,3.4-74.2,3.3-110.7,0.4c-28.7-2.4-56-6.5-79-12.2
      c-10.8-2.7-22-5.6-33.2-8.6c-24.8-6.7-51-14.1-72.4-21.4c-26.7-9-58.5-12.7-92.2-9.4c-4,0.4-8,0.9-12.1,1.5
      c-37,5.3-69.7,14.5-98.9,25.1c-14.3,5.2-27.7,10.9-40.6,16.6c-17.3,7.8-34.3,14.2-52.5,18.3l0,0.7c18.7-4.1,35.9-10.7,53.5-18.5
      c12.9-5.8,26.3-11.4,40.5-16.6c29.1-10.6,61.7-19.7,98.4-25c4-0.6,8-1.1,12-1.4c33.3-3.2,64.7,0.4,91.1,9.3
      c21.5,7.3,47.7,14.8,72.6,21.4c11.2,3,22.4,5.9,33.3,8.6C407.9,161.3,435.4,165.5,464.2,167.9z M466.1,167.5
      c37,3.1,76.9,3.2,112.5-0.2c7-0.7,13.8-1.5,20.5-2.5c37.5-5.5,66.9-18.8,97.4-29.7c3.3-1.2,6.7-2.4,10.1-3.6c1-0.4,2-0.7,3-1.1
      l0.1-0.8c-1.3,0.5-2.6,0.9-3.9,1.4c-3.4,1.2-6.7,2.4-10.1,3.6c-30.5,11-59.9,24.2-96.9,29.6c-6.6,1-13.4,1.8-20.3,2.4
      c-35.4,3.4-75.2,3.3-112,0.2c-28.6-2.5-55.9-6.7-78.9-12.4c-10.8-2.7-22-5.6-33.2-8.7c-24.7-6.7-51.1-14.2-72.6-21.4
      c-26.5-8.9-58.1-12.5-91.4-9.2c-4.2,0.4-8.4,0.9-12.6,1.5c-36.8,5.4-69.5,14.5-98.5,25.2c-14.2,5.2-27.5,10.9-40.4,16.6
      c-18.2,8.2-36.1,14.9-55.4,19l0,0.6c19.8-4.1,37.9-11,56.4-19.2c12.8-5.8,26.2-11.4,40.3-16.6c28.9-10.6,61.4-19.7,98-25.1
      c4.2-0.6,8.3-1.1,12.5-1.5c33-3.2,64,0.3,90.3,9.1c21.6,7.2,48,14.8,72.7,21.5c11.2,3,22.4,6,33.2,8.7
      C409.9,160.8,437.3,165,466.1,167.5z M468.2,167c37.3,3.3,77.8,3.5,113.8,0c6.5-0.6,13-1.4,19.2-2.3c37.6-5.4,67.2-18.6,97.7-29.6
      c3.4-1.2,6.7-2.4,10.1-3.6l0.4-0.1l0.1-0.8c-0.4,0.2-0.9,0.3-1.3,0.4c-3.4,1.2-6.7,2.4-10.1,3.6c-30.6,11-60.1,24.2-97.3,29.5
      c-6.2,0.9-12.5,1.6-19,2.3c-35.9,3.5-76.1,3.3-113.3,0c-28.6-2.5-55.8-6.8-78.7-12.6c-10.8-2.7-22-5.7-33.1-8.7
      c-24.7-6.8-51.2-14.3-72.8-21.4c-26.4-8.8-57.6-12.2-90.6-9c-4.3,0.4-8.7,1-13.1,1.6c-36.7,5.4-69.2,14.6-98,25.3
      c-14.1,5.2-27.4,10.9-40.2,16.6c-19.2,8.7-38,15.7-58.6,19.6l0,0.6c21.1-4,40.2-11.1,59.6-19.9c12.8-5.7,26-11.4,40.1-16.6
      c28.7-10.6,61.1-19.8,97.5-25.2c4.3-0.6,8.7-1.2,12.9-1.6c32.6-3.2,63.4,0.3,89.5,8.9c21.7,7.2,48.2,14.7,72.9,21.5
      c11.2,3.1,22.3,6,33.2,8.7C412.2,160.2,439.5,164.5,468.2,167z M470.5,166.6c37.7,3.4,78.7,3.7,115.2,0.2
      c6.1-0.6,12.1-1.3,17.9-2.1c37.6-5.3,67.4-18.4,98.1-29.4c2.6-0.9,5.1-1.9,7.7-2.8l0.1-0.8c-2.9,1-5.8,2.1-8.6,3.1
      c-30.7,11-60.4,24.1-97.6,29.3c-5.8,0.8-11.7,1.5-17.8,2.1c-36.3,3.5-77,3.2-114.6-0.2c-28.5-2.6-55.7-7-78.6-12.9
      c-10.8-2.8-21.9-5.7-33.1-8.8c-24.6-6.8-51.3-14.3-72.9-21.5c-26.2-8.6-57.1-12-89.8-8.8c-4.5,0.4-9,1-13.6,1.7
      c-36.5,5.5-68.9,14.7-97.5,25.3c-14.1,5.2-27.3,10.9-40.1,16.6c-20.2,9.1-39.9,16.5-61.9,20.3l0,0.6c22.4-3.9,42.5-11.3,62.9-20.5
      c12.7-5.7,25.9-11.4,39.9-16.6c28.6-10.6,60.8-19.8,97.1-25.2c4.5-0.7,9-1.2,13.4-1.7c32.3-3.1,62.8,0.2,88.8,8.8
      c21.7,7.1,48.4,14.7,73,21.5c11.2,3.1,22.3,6,33.1,8.8C414.6,159.6,441.8,163.9,470.5,166.6z M472.7,166.1
      c38,3.6,79.5,3.9,116.4,0.4c5.7-0.5,11.3-1.2,16.7-1.9c37.7-5.2,67.7-18.2,98.5-29.3c1.7-0.6,3.4-1.2,5-1.8l0.1-0.8
      c-2,0.7-4,1.4-5.9,2.1c-30.8,11.1-60.7,24-98,29.1c-5.4,0.7-10.9,1.4-16.6,1.9c-36.6,3.5-78,3.2-115.8-0.4
      c-28.5-2.7-55.5-7.1-78.4-13.1c-10.8-2.8-21.9-5.8-33-8.9c-24.5-6.8-51.3-14.4-73.1-21.5c-26-8.5-56.6-11.8-89-8.7
      c-4.6,0.4-9.4,1-14.1,1.8c-36.4,5.5-68.6,14.8-97.1,25.4c-14,5.2-27.2,10.9-39.9,16.6c-21.2,9.6-41.8,17.2-65.1,20.9l0,0.6
      c23.8-3.7,44.7-11.4,66.1-21.1c12.6-5.7,25.8-11.4,39.8-16.6c28.4-10.6,60.5-19.8,96.6-25.3c4.7-0.7,9.3-1.3,13.9-1.7
      c32-3.1,62.2,0.2,87.9,8.6c21.8,7.1,48.6,14.7,73.1,21.5c11.2,3.1,22.3,6.1,33.1,8.9C416.9,158.9,444.1,163.4,472.7,166.1z
       M474.8,165.6c38.4,3.7,80.4,4.2,117.7,0.6c5.2-0.5,10.4-1.1,15.4-1.8c37.8-5.1,68-18,98.8-29.1c0.8-0.3,1.7-0.6,2.5-0.9l0.1-0.8
      c-1.1,0.4-2.3,0.8-3.4,1.2c-30.9,11.1-61,24-98.4,29c-5,0.7-10.1,1.3-15.3,1.8c-37.1,3.6-78.9,3.2-117.1-0.6
      c-28.4-2.8-55.4-7.3-78.3-13.3c-10.8-2.8-21.8-5.8-33-9c-24.5-6.9-51.4-14.5-73.2-21.5c-25.9-8.3-56.2-11.6-88.2-8.5
      c-4.8,0.5-9.7,1.1-14.6,1.8c-36.3,5.6-68.4,14.9-96.6,25.5c-14,5.2-27.1,10.9-39.7,16.6c-22.1,10.1-43.6,18-68.1,21.4l0,0.6
      c25.1-3.5,46.9-11.5,69.1-21.6c12.6-5.7,25.6-11.4,39.6-16.6c28.2-10.6,60.2-19.8,96.2-25.4c4.9-0.7,9.7-1.4,14.4-1.8
      c31.7-3.1,61.5,0.1,87.1,8.4c21.8,7,48.8,14.7,73.3,21.5c11.1,3.1,22.2,6.1,33,9C419.1,158.3,446.2,162.8,474.8,165.6z
       M476.5,165.1c38.7,3.9,81.3,4.4,119,0.8c4.8-0.5,9.5-1,14.2-1.6c37.9-5,68.3-17.8,99.2-28.9c0.1,0,0.2-0.1,0.4-0.1l0.1-0.8
      c-0.4,0.1-0.8,0.3-1.2,0.4c-31,11.1-61.3,23.9-98.7,28.8c-4.6,0.6-9.3,1.1-14.1,1.6c-37.4,3.6-79.8,3.1-118.4-0.8
      c-28.4-2.9-55.3-7.5-78.1-13.5c-10.8-2.9-21.8-5.9-32.9-9c-24.4-6.9-51.5-14.6-73.4-21.6c-25.7-8.2-55.7-11.4-87.4-8.3
      c-5,0.5-10,1.1-15.1,1.9c-36.1,5.6-68.1,15-96.2,25.5c-13.9,5.2-26.9,10.9-39.5,16.6c-22.9,10.5-45.1,18.7-70.9,21.9l0,0.6
      c26.3-3.2,48.8-11.6,71.8-22.1c12.5-5.7,25.5-11.4,39.4-16.6c28-10.5,59.9-19.8,95.7-25.4c5-0.8,10-1.4,14.9-1.9
      c31.3-3,60.9,0.1,86.3,8.2c21.9,7,49,14.7,73.4,21.6c11.1,3.1,22.2,6.2,33,9C420.9,157.7,448,162.3,476.5,165.1z M477.7,164.7
      c39,4,81.9,4.7,120,1c4.5-0.4,8.9-0.9,13.2-1.5c37.5-4.8,67.7-17.3,98.3-28.3l0.1-0.8c-31,11.1-61.3,23.7-98.7,28.5
      c-4.3,0.6-8.7,1-13.1,1.5c-37.8,3.7-80.6,3-119.4-1c-28.3-2.9-55.2-7.6-78-13.7c-10.8-2.9-21.8-5.9-32.9-9.1
      c-24.3-6.9-51.6-14.6-73.5-21.6c-25.5-8.1-55.2-11.1-86.6-8.1c-5.1,0.5-10.4,1.2-15.6,2c-35.9,5.7-67.8,15.1-95.7,25.6
      c-13.9,5.2-26.8,10.9-39.3,16.6c-23.5,10.8-46.3,19.2-73,22.2l0,0.6c27.4-3,50.4-11.6,74-22.4c12.5-5.7,25.4-11.4,39.2-16.6
      c27.8-10.5,59.5-19.8,95.3-25.5c5.2-0.8,10.3-1.5,15.4-2c31-3,60.3,0,85.5,8c22,6.9,49.3,14.6,73.6,21.6
      c11.1,3.2,22.2,6.2,32.9,9.1C422.3,157,449.2,161.8,477.7,164.7z M478.2,164.3c39.3,4.2,82.8,4.9,121.2,1.2c4.1-0.4,8.1-0.8,12-1.3
      c37.2-4.7,67.3-16.8,97.6-27.8l0.1-0.8c-30.7,11-60.9,23.3-98,28c-3.9,0.5-7.9,0.9-11.9,1.3c-38.2,3.7-81.5,3-120.6-1.2
      c-28.3-3-55-7.8-77.8-14c-10.7-2.9-21.8-6-32.8-9.2c-24.3-7-51.7-14.7-73.7-21.6C269,111,239.6,108,208.5,111
      c-5.3,0.5-10.7,1.2-16.1,2.1c-35.8,5.8-67.5,15.1-95.3,25.7c-13.8,5.3-26.7,10.9-39.1,16.6c-23.9,11-47.1,19.5-74.6,22.4l0,0.6
      c28.1-2.9,51.5-11.5,75.6-22.6c12.4-5.7,25.3-11.3,39-16.6c27.7-10.5,59.3-19.8,94.9-25.6c5.3-0.9,10.7-1.5,15.9-2.1
      c30.7-3,59.6,0,84.7,7.8c22,6.9,49.5,14.6,73.7,21.6c11.1,3.2,22.1,6.3,32.9,9.2C423,156.5,449.9,161.3,478.2,164.3z M478,163.9
      c39.6,4.4,83.6,5.2,122.4,1.4c3.6-0.4,7.3-0.7,10.8-1.2c37.2-4.6,67.4-16.6,97.8-27.5l0.1-0.8c-30.7,11-61.1,23.2-98.2,27.7
      c-3.5,0.4-7.1,0.8-10.7,1.2c-38.5,3.7-82.4,2.9-121.8-1.4c-28.2-3.1-54.9-7.9-77.6-14.2c-10.7-2.9-21.7-6.1-32.8-9.3
      c-24.2-7-51.8-14.8-73.9-21.6c-25.1-7.8-54.2-10.7-84.9-7.7c-5.5,0.5-11,1.2-16.6,2.2c-35.7,5.8-67.3,15.2-94.8,25.8
      c-13.8,5.3-26.6,10.9-39,16.6c-24.1,11.1-47.4,19.7-75.4,22.5l0,0.6c28.5-2.8,52.1-11.5,76.4-22.7c12.4-5.7,25.1-11.3,38.9-16.6
      c27.5-10.5,58.9-19.9,94.4-25.6c5.5-0.9,11-1.6,16.4-2.1c30.3-2.9,59,0,83.9,7.7c22.1,6.8,49.7,14.6,73.9,21.6
      c11.1,3.2,22.1,6.3,32.8,9.3C422.9,155.9,449.7,160.8,478,163.9z M476.8,163.6c39.9,4.5,84.5,5.4,123.6,1.6c3.2-0.3,6.5-0.7,9.6-1
      c37.6-4.5,68.2-16.6,99-27.7l0.1-0.8c-31.1,11.1-61.8,23.4-99.3,27.9c-3.1,0.4-6.3,0.7-9.6,1c-38.9,3.8-83.3,2.9-123-1.6
      c-28.1-3.2-54.8-8.1-77.5-14.4c-10.7-3-21.7-6.1-32.7-9.3c-24.1-7.1-51.9-14.9-74-21.7c-24.9-7.6-53.7-10.5-84.1-7.6
      c-5.6,0.5-11.4,1.3-17.1,2.2c-35.5,5.9-67,15.3-94.4,25.8c-13.7,5.3-26.5,10.9-38.8,16.6c-24,11.1-47.3,19.7-75.2,22.5l0,0.6
      c28.5-2.8,52-11.5,76.1-22.7c12.3-5.7,25-11.3,38.7-16.6c27.3-10.5,58.6-19.9,93.9-25.7c5.7-0.9,11.3-1.7,16.9-2.2
      c30-2.9,58.4-0.1,83.1,7.5c22.2,6.8,49.9,14.6,74,21.7c11.1,3.2,22.1,6.4,32.8,9.3C421.8,155.5,448.6,160.4,476.8,163.6z
       M474.6,163.3c40.2,4.7,85.2,5.7,124.8,1.9c2.8-0.3,5.7-0.6,8.5-0.9c38.4-4.5,69.6-16.9,101-28.1c0.1,0,0.1,0,0.2-0.1l0.1-0.8
      c-0.4,0.1-0.7,0.3-1.1,0.4c-31.5,11.2-62.6,23.6-100.5,28c-2.8,0.3-5.6,0.6-8.4,0.9c-39.3,3.8-84.2,2.8-124.2-1.9
      c-28.1-3.3-54.7-8.3-77.3-14.6c-10.7-3-21.7-6.2-32.7-9.4c-24.1-7.1-52-14.9-74.2-21.7c-24.8-7.5-53.2-10.3-83.3-7.4
      c-5.8,0.6-11.7,1.3-17.6,2.3c-35.4,5.9-66.7,15.4-93.9,25.9c-13.7,5.3-26.3,10.9-38.6,16.6c-23.6,11-46.5,19.5-73.9,22.4l0,0.6
      c27.9-2.9,51.1-11.6,74.9-22.6c12.2-5.7,24.9-11.3,38.5-16.6c27.1-10.4,58.3-19.9,93.5-25.8c5.9-1,11.7-1.8,17.4-2.3
      c29.7-2.9,57.8-0.1,82.3,7.3c22.3,6.7,50.1,14.6,74.2,21.7c11,3.3,22,6.4,32.7,9.4C419.7,155,446.4,160.1,474.6,163.3z
       M471.1,163.2c40.5,4.9,86.1,6,126,2.1c2.4-0.2,4.9-0.5,7.3-0.8c38.5-4.4,69.9-16.7,101.4-27.9c1.1-0.4,2.2-0.8,3.4-1.2l0.1-0.8
      c-1.4,0.5-2.8,1-4.2,1.5c-31.6,11.3-62.9,23.5-100.9,27.8c-2.4,0.3-4.8,0.5-7.3,0.8c-39.7,3.8-85.1,2.7-125.4-2.1
      c-28-3.4-54.5-8.4-77.2-14.8c-10.7-3-21.6-6.2-32.6-9.5c-24-7.1-52-15-74.3-21.7c-24.6-7.4-52.8-10.1-82.5-7.2
      c-6,0.6-12,1.4-18.1,2.4c-35.2,6-66.4,15.5-93.5,26C79.6,143,67,148.7,54.8,154.4c-22.9,10.7-45,19-71.3,22.2l0,0.6
      c26.8-3.2,49.3-11.7,72.3-22.4c12.2-5.7,24.8-11.3,38.3-16.6c26.9-10.4,58-19.9,93-25.8c6-1,12-1.8,17.9-2.4
      c29.4-2.8,57.2-0.2,81.5,7.1c22.3,6.7,50.3,14.6,74.3,21.7c11,3.3,22,6.5,32.7,9.5C416.3,154.7,442.9,159.8,471.1,163.2z
       M466.6,162.9c40.8,5,86.9,6.2,127.2,2.3c2.1-0.2,4.1-0.4,6.2-0.6c38.6-4.3,70.2-16.5,101.8-27.8c2.5-0.9,5-1.8,7.5-2.7l0.1-0.8
      c-2.8,1-5.6,2-8.4,3c-31.7,11.3-63.1,23.4-101.3,27.7c-2,0.2-4.1,0.4-6.1,0.6c-40.1,3.9-86,2.7-126.5-2.3c-28-3.4-54.4-8.6-77-15.1
      c-10.7-3-21.6-6.3-32.6-9.6c-24-7.2-52.1-15.1-74.5-21.7c-24.4-7.2-52.3-9.9-81.7-7c-6.2,0.6-12.4,1.4-18.6,2.5
      c-35.1,6-66.2,15.6-93,26c-13.6,5.3-26.1,10.9-38.3,16.6c-21.8,10.2-43,18.2-67.8,21.7l0,0.7c25.4-3.5,46.8-11.7,68.8-22
      c12.1-5.7,24.6-11.3,38.1-16.6c26.8-10.4,57.7-19.9,92.6-25.9c6.2-1.1,12.4-1.9,18.4-2.5c29.1-2.8,56.5-0.2,80.7,7
      c22.4,6.6,50.5,14.6,74.5,21.7c11,3.3,21.9,6.5,32.6,9.6C412,154.3,438.5,159.4,466.6,162.9z M462.4,162.5
      c41.1,5.2,87.6,6.5,128.4,2.6c1.7-0.2,3.4-0.3,5-0.5c38.7-4.2,70.4-16.3,102.2-27.6c3.4-1.2,6.9-2.5,10.3-3.7
      c0.3-0.1,0.7-0.3,1-0.4l0.1-0.8c-0.6,0.2-1.3,0.5-1.9,0.7c-3.4,1.3-6.9,2.5-10.3,3.7c-31.7,11.3-63.4,23.3-101.6,27.5
      c-1.7,0.2-3.3,0.3-5,0.5c-40.4,3.9-86.8,2.6-127.7-2.6c-27.9-3.5-54.3-8.8-76.9-15.3c-10.6-3.1-21.5-6.3-32.5-9.6
      c-23.9-7.2-52.2-15.2-74.6-21.8c-24.2-7.1-51.8-9.7-80.9-6.9c-6.3,0.6-12.7,1.5-19.1,2.6c-34.9,6.1-65.9,15.7-92.6,26.1
      c-13.5,5.3-26,10.9-38.1,16.6c-20.8,9.8-41.1,17.5-64.6,21.2l0,0.6c24-3.7,44.6-11.6,65.6-21.4c12.1-5.7,24.5-11.3,38-16.6
      c26.6-10.4,57.4-19.9,92.2-26c6.4-1.1,12.7-2,18.9-2.6c28.7-2.8,55.9-0.2,79.9,6.8c22.5,6.6,50.7,14.5,74.6,21.8
      c11,3.3,21.9,6.6,32.6,9.6C407.9,153.7,434.4,158.9,462.4,162.5z M458.6,162c41.4,5.4,88.4,6.8,129.5,2.8c1.3-0.1,2.6-0.3,3.9-0.4
      c38.7-4.1,70.7-16.1,102.5-27.5c3.5-1.2,6.9-2.5,10.3-3.7c1.5-0.6,3.1-1.1,4.6-1.7l0.1-0.8c-1.8,0.7-3.7,1.3-5.5,2
      c-3.4,1.3-6.9,2.5-10.3,3.7c-31.9,11.3-63.7,23.3-102,27.3c-1.3,0.1-2.6,0.3-3.9,0.4c-40.8,3.9-87.7,2.5-128.8-2.8
      c-27.9-3.6-54.2-8.9-76.7-15.5c-10.6-3.1-21.5-6.4-32.5-9.7c-23.9-7.3-52.3-15.2-74.8-21.8c-24-7-51.3-9.5-80.1-6.7
      c-6.5,0.6-13,1.5-19.7,2.7c-34.8,6.1-65.6,15.8-92.1,26.2c-13.5,5.3-25.9,10.9-37.9,16.6c-19.9,9.4-39.4,16.9-61.6,20.8l0,0.6
      c22.8-3.9,42.5-11.5,62.6-21c12-5.7,24.4-11.3,37.8-16.6c26.4-10.4,57.1-19.9,91.7-26.1c6.5-1.2,13-2,19.4-2.7
      c28.4-2.7,55.3-0.3,79.1,6.6c22.5,6.5,50.9,14.5,74.8,21.8c11,3.3,21.9,6.6,32.5,9.7C404.2,153,430.6,158.4,458.6,162z
       M454.9,161.4c41.6,5.5,89.2,7.1,130.7,3.1c0.9-0.1,1.9-0.2,2.8-0.3c38.8-4,71-15.9,102.9-27.3c3.5-1.2,6.9-2.5,10.4-3.7
      c2.6-1,5.3-1.9,7.9-2.9l0.1-0.8c-2.9,1.1-5.9,2.1-8.8,3.2c-3.5,1.3-6.9,2.5-10.3,3.7c-31.9,11.4-64,23.2-102.3,27.2
      c-0.9,0.1-1.8,0.2-2.8,0.3c-41.2,4-88.6,2.5-130-3.1c-27.8-3.7-54-9.1-76.6-15.7c-10.6-3.1-21.5-6.4-32.4-9.8
      c-23.8-7.3-52.4-15.3-74.9-21.8c-23.8-6.8-50.8-9.3-79.2-6.5c-6.6,0.6-13.4,1.6-20.2,2.8c-34.6,6.2-65.4,15.9-91.7,26.2
      c-13.4,5.3-25.7,11-37.7,16.6c-19.1,9-37.8,16.3-58.9,20.3l0,0.6c21.6-4,40.6-11.4,59.9-20.6c12-5.7,24.3-11.3,37.6-16.6
      c26.2-10.4,56.8-20,91.3-26.1c6.7-1.2,13.4-2.1,19.9-2.8c28.1-2.7,54.7-0.3,78.2,6.5c22.6,6.5,51.1,14.5,74.9,21.8
      c11,3.4,21.8,6.7,32.5,9.8C400.7,152.3,427,157.7,454.9,161.4z M451.5,160.7c41.9,5.7,90,7.4,131.8,3.3c0.6-0.1,1.1-0.1,1.7-0.2
      c38.9-3.9,71.2-15.7,103.3-27.1c3.5-1.2,6.9-2.5,10.4-3.7c3.7-1.3,7.3-2.7,11-4l0.1-0.8c-4,1.4-7.9,2.9-11.9,4.3
      c-3.5,1.3-6.9,2.5-10.4,3.7c-32,11.4-64.2,23.1-102.7,27c-0.6,0.1-1.1,0.1-1.7,0.2c-41.6,4-89.4,2.4-131.1-3.3
      c-27.7-3.8-53.9-9.2-76.4-15.9c-10.6-3.1-21.5-6.5-32.4-9.9c-23.7-7.3-52.4-15.4-75.1-21.8c-23.6-6.7-50.3-9.1-78.4-6.4
      c-6.8,0.7-13.7,1.6-20.7,2.9c-34.5,6.3-65.1,15.9-91.2,26.3c-13.3,5.3-25.6,11-37.5,16.6c-18.3,8.7-36.2,15.8-56.4,19.9l0,0.6
      c20.6-4.1,38.9-11.3,57.4-20.1c11.9-5.6,24.1-11.3,37.4-16.6c26-10.3,56.5-20,90.8-26.2c6.9-1.3,13.7-2.2,20.4-2.9
      c27.8-2.7,54.1-0.4,77.4,6.3c22.7,6.4,51.4,14.5,75,21.8c11,3.4,21.8,6.7,32.4,9.9C397.3,151.5,423.6,156.9,451.5,160.7z
       M448.2,160c42.3,5.9,91.1,7.5,133.5,3.5l0.4,0c38.9-3.8,71.3-15.6,103.3-26.9c3.5-1.2,6.9-2.5,10.4-3.8c4.7-1.7,9.3-3.4,14-5.1
      l0.1-0.8c-5,1.8-9.9,3.6-14.9,5.4c-3.4,1.3-6.9,2.5-10.4,3.8c-32,11.4-64.2,23.1-102.7,26.8l-0.4,0c-42.1,4-90.7,2.4-132.8-3.5
      c-27.7-3.9-53.8-9.4-76.3-16.1c-10.6-3.2-21.4-6.5-32.3-9.9c-23.7-7.4-52.5-15.5-75.2-21.9c-23.4-6.6-49.8-8.9-77.6-6.2
      c-7,0.7-14,1.7-21.2,3c-34.3,6.3-64.8,16-90.8,26.4c-13.3,5.3-25.5,11-37.3,16.6c-17.6,8.4-34.8,15.3-54,19.4l0,0.6
      c19.7-4.2,37.2-11.2,55-19.7c11.8-5.6,24-11.3,37.2-16.6c25.9-10.3,56.2-20,90.3-26.3c7.1-1.3,14.1-2.3,21-2.9
      c27.4-2.6,53.4-0.4,76.6,6.1c22.7,6.4,51.6,14.5,75.2,21.9c10.9,3.4,21.8,6.8,32.4,9.9C394.2,150.6,420.4,156.1,448.2,160z
       M445.1,159.3c42.2,6,91,7.8,133.6,3.9c0.4,0,0.7-0.1,1.1-0.1c38.7-3.7,71-15.4,102.9-26.7c3.5-1.2,7-2.5,10.4-3.8
      c5.6-2.1,11.2-4.1,16.8-6.1l0.1-0.8c-5.9,2.1-11.8,4.3-17.7,6.5c-3.5,1.3-6.9,2.5-10.4,3.8c-31.9,11.3-64.1,22.9-102.3,26.6
      c-0.4,0-0.7,0.1-1.1,0.1c-42.2,3.9-90.8,2.1-132.9-3.9c-27.6-4-53.6-9.6-76.1-16.4c-10.6-3.2-21.4-6.6-32.3-10
      c-23.6-7.4-52.6-15.5-75.4-21.9c-23.2-6.5-49.3-8.7-76.8-6c-7.2,0.7-14.4,1.7-21.7,3.1c-34.2,6.4-64.5,16.1-90.3,26.4
      c-13.2,5.3-25.4,11-37.2,16.6c-16.9,8.1-33.5,14.8-51.8,19l0,0.7c18.8-4.3,35.7-11.1,52.8-19.3c11.8-5.6,23.9-11.3,37.1-16.6
      c25.7-10.3,55.9-20,89.9-26.3c7.2-1.4,14.4-2.4,21.5-3c27.1-2.6,52.8-0.4,75.8,6c22.8,6.3,51.8,14.5,75.3,21.9
      c10.9,3.4,21.7,6.8,32.3,10C391.2,149.7,417.4,155.3,445.1,159.3z M442,158.5c42.1,6.2,91,8.2,133.6,4.2c0.3,0,0.7-0.1,1-0.1
      c38.7-3.7,71.3-15.2,103.3-26.5c3.5-1.2,7-2.5,10.4-3.8c6.5-2.4,13-4.8,19.5-7.1l0.1-0.8c-6.8,2.5-13.6,5-20.4,7.5
      c-3.5,1.3-6.9,2.5-10.4,3.8c-32,11.4-64.5,22.7-102.8,26.4l-1,0.1c-42.3,3.9-91,1.9-132.9-4.2c-27.6-4-53.5-9.7-75.9-16.6
      c-10.6-3.2-21.3-6.6-32.2-10.1c-23.6-7.4-52.7-15.6-75.6-21.9c-23-6.3-48.8-8.5-75.9-5.9c-7.3,0.7-14.7,1.8-22.2,3.2
      c-34,6.4-64.3,16.2-89.8,26.5c-13.2,5.3-25.2,11-37,16.6c-16.2,7.8-32.1,14.3-49.7,18.5l0,0.7c18-4.3,34.2-10.9,50.7-18.8
      c11.7-5.6,23.8-11.3,36.9-16.6c25.5-10.3,55.6-20,89.5-26.4c7.4-1.4,14.7-2.4,22-3.1c26.8-2.6,52.2-0.5,75,5.8
      c22.9,6.3,52,14.5,75.5,21.9c10.9,3.5,21.7,6.9,32.3,10.1C388.3,148.7,414.4,154.4,442,158.5z M439,157.7
      c42,6.3,90.9,8.4,133.6,4.6c0.6,0,1.2-0.1,1.7-0.2c38.5-3.7,71.1-15,103-26.3c3.5-1.2,7-2.5,10.5-3.8c7.4-2.7,14.8-5.4,22.1-8.1
      l0.1-0.8c-7.7,2.8-15.4,5.6-23,8.5c-3.5,1.3-7,2.5-10.5,3.8c-31.9,11.3-64.3,22.5-102.4,26.2c-0.6,0.1-1.2,0.1-1.7,0.2
      c-42.4,3.8-91.1,1.7-132.9-4.6c-27.5-4.1-53.4-9.9-75.8-16.8c-10.5-3.3-21.3-6.7-32.2-10.2c-23.5-7.5-52.7-15.7-75.7-21.9
      c-22.8-6.2-48.3-8.3-75.1-5.7c-7.5,0.7-15.1,1.8-22.7,3.3c-33.9,6.5-64,16.3-89.4,26.6c-13.1,5.3-25.1,11-36.8,16.6
      c-15.6,7.5-30.9,13.8-47.6,18l0,0.7c17.2-4.3,32.8-10.7,48.6-18.3c11.7-5.6,23.6-11.3,36.7-16.6c25.3-10.3,55.3-20,89-26.5
      c7.6-1.5,15.1-2.5,22.5-3.2c26.5-2.6,51.6-0.5,74.1,5.6c23,6.2,52.2,14.4,75.6,21.9c10.9,3.5,21.7,6.9,32.2,10.2
      C385.4,147.8,411.4,153.5,439,157.7z M436.1,156.9c41.9,6.4,90.8,8.7,133.6,5c0.8-0.1,1.7-0.1,2.5-0.2
      c38.3-3.7,70.8-14.8,102.6-26.1c3.5-1.2,7-2.5,10.5-3.8c8.3-3.1,16.5-6.1,24.8-9.1l0.1-0.8c-8.6,3.1-17.1,6.3-25.7,9.5
      c-3.5,1.3-7,2.5-10.5,3.8c-31.8,11.3-64.2,22.3-102.1,26c-0.8,0.1-1.6,0.2-2.5,0.2c-42.5,3.7-91.3,1.4-132.9-4.9
      c-27.4-4.2-53.3-10-75.6-17c-10.5-3.3-21.3-6.7-32.1-10.2c-23.4-7.5-52.8-15.8-75.9-22c-22.6-6.1-47.8-8.1-74.3-5.5
      c-7.6,0.7-15.4,1.9-23.3,3.4c-33.8,6.5-63.7,16.4-89,26.7c-13.1,5.3-25,11-36.6,16.6c-14.9,7.2-29.6,13.3-45.5,17.5l0,0.7
      c16.4-4.3,31.4-10.5,46.5-17.8c11.6-5.6,23.5-11.3,36.5-16.6c25.2-10.2,55-20.1,88.6-26.5c7.7-1.5,15.4-2.6,23-3.3
      c26.1-2.5,51-0.5,73.3,5.5c23,6.2,52.4,14.4,75.8,21.9c10.9,3.5,21.6,7,32.2,10.2C382.6,146.8,408.5,152.7,436.1,156.9z
       M433.1,156.1c41.7,6.5,90.7,9,133.7,5.3c1.1-0.1,2.2-0.2,3.2-0.3c38-3.7,70.5-14.6,102.3-25.8c3.5-1.2,7-2.5,10.5-3.8
      c9.2-3.4,18.3-6.8,27.5-10.1l0.1-0.8c-9.5,3.5-18.9,7-28.3,10.5c-3.5,1.3-7,2.6-10.5,3.8c-31.7,11.2-64,22.1-101.7,25.7
      c-1.1,0.1-2.1,0.2-3.2,0.3c-42.7,3.6-91.4,1.2-133-5.3c-27.4-4.3-53.1-10.2-75.5-17.2c-10.5-3.3-21.2-6.8-32.1-10.3
      c-23.3-7.6-52.9-15.8-76-22c-22.4-6-47.3-7.9-73.5-5.4c-7.8,0.8-15.8,1.9-23.8,3.5c-33.6,6.6-63.4,16.5-88.5,26.7
      c-13,5.3-24.9,11-36.4,16.6c-14.2,6.9-28.3,12.8-43.4,17l0,0.7c15.6-4.3,29.9-10.3,44.5-17.3c11.6-5.6,23.4-11.3,36.3-16.6
      c25-10.2,54.7-20.1,88.1-26.6c7.9-1.6,15.8-2.7,23.5-3.5c25.8-2.5,50.4-0.6,72.5,5.3c23.1,6.1,52.6,14.4,75.9,22
      c10.9,3.5,21.6,7,32.1,10.3C379.7,145.9,405.6,151.8,433.1,156.1z M430,155.3c41.6,6.7,90.6,9.2,133.7,5.7c1.3-0.1,2.7-0.2,4-0.4
      c37.8-3.7,70.3-14.4,101.9-25.6c3.5-1.2,7-2.5,10.5-3.8c10.1-3.7,20.1-7.5,30.2-11.1l0.1-0.8c-10.4,3.8-20.7,7.6-31.1,11.5
      c-3.5,1.3-7,2.6-10.5,3.8c-31.6,11.2-63.9,21.9-101.3,25.5c-1.3,0.1-2.6,0.2-3.9,0.4c-42.8,3.5-91.6,1-133-5.7
      c-27.3-4.4-53-10.4-75.3-17.5c-10.5-3.3-21.2-6.8-32-10.4c-23.3-7.6-53-15.9-76.2-22c-22.2-5.8-46.8-7.7-72.6-5.2
      c-8,0.8-16.1,2-24.3,3.6c-33.5,6.6-63.1,16.6-88,26.8c-13,5.3-24.8,11-36.3,16.6c-13.6,6.6-26.9,12.3-41.3,16.5l-0.1,0.7
      c14.8-4.2,28.5-10,42.3-16.8c11.5-5.6,23.3-11.3,36.2-16.6c24.8-10.2,54.4-20.1,87.7-26.7c8.1-1.6,16.1-2.8,24-3.6
      c25.5-2.5,49.8-0.6,71.7,5.2c23.2,6.1,52.8,14.4,76.1,22c10.9,3.5,21.6,7,32.1,10.4C376.8,144.9,402.6,150.9,430,155.3z
       M426.7,154.3c41.6,6.8,90.5,9.5,133.7,6.1c1.6-0.1,3.2-0.3,4.7-0.4c37.6-3.6,70-14.3,101.5-25.4c3.5-1.2,7-2.5,10.5-3.8
      c11.1-4.1,22.1-8.2,33.1-12.3l0.1-0.8c-11.4,4.1-22.7,8.4-34,12.6c-3.5,1.3-7,2.6-10.5,3.8c-31.5,11.1-63.7,21.7-100.9,25.3
      c-1.5,0.2-3.1,0.3-4.7,0.4c-42.9,3.4-91.7,0.7-133-6c-27.3-4.5-52.9-10.5-75.2-17.7c-10.5-3.4-21.2-6.9-32-10.5
      c-23.2-7.6-53-16-76.3-22c-22-5.7-46.3-7.5-71.8-5.1c-8.2,0.8-16.5,2-24.8,3.7c-33.3,6.7-62.9,16.7-87.6,26.9
      c-12.9,5.3-24.6,11-36.1,16.6c-12.8,6.3-25.4,11.7-38.9,15.8l-0.1,0.7c13.9-4.2,26.8-9.7,39.9-16.1c11.4-5.6,23.1-11.3,36-16.6
      c24.6-10.2,54.1-20.1,87.2-26.8c8.3-1.7,16.5-2.9,24.6-3.7c25.2-2.4,49.1-0.6,70.9,5c23.2,6,53,14.4,76.2,22
      c10.8,3.6,21.5,7.1,32,10.5C373.6,143.7,399.4,149.8,426.7,154.3z M423,152.5c41.4,6.9,90.4,9.8,133.8,6.4c1.8-0.1,3.7-0.3,5.5-0.5
      c37.4-3.6,69.7-14.1,101.1-25.2c3.5-1.2,7-2.5,10.5-3.8c12.2-4.5,24.4-9.1,36.6-13.6l0.1-0.8c-12.5,4.6-25,9.2-37.5,13.9
      c-3.5,1.3-7,2.6-10.5,3.8c-31.4,11.1-63.5,21.5-100.5,25.1c-1.8,0.2-3.6,0.3-5.4,0.5c-43,3.3-91.8,0.5-133.1-6.4
      c-27.2-4.5-52.8-10.7-75-17.9c-10.5-3.4-21.1-7-32-10.5c-23.2-7.7-53.1-16.1-76.5-22.1c-21.8-5.6-45.8-7.4-71-4.9
      c-8.3,0.8-16.8,2.1-25.4,3.8c-33.2,6.8-62.6,16.7-87.1,26.9c-12.9,5.3-24.5,11-35.9,16.6c-11.9,5.8-23.6,11-36,15l-0.1,0.7
      c12.8-4.1,24.8-9.3,37-15.3c11.4-5.6,23-11.3,35.8-16.6c24.4-10.2,53.8-20.1,86.8-26.8c8.5-1.7,16.8-3,25.1-3.8
      c24.8-2.4,48.5-0.7,70,4.9c23.3,6,53.2,14.4,76.4,22c10.8,3.6,21.5,7.1,32,10.5C370.1,141.8,395.7,148,423,152.5z M419.2,151
      c41.3,7,90.3,10,133.8,6.8c2.1-0.2,4.2-0.3,6.2-0.5c37.2-3.6,69.4-13.9,100.7-25c3.5-1.2,7.1-2.5,10.6-3.8
      c13.4-5,26.8-10,40.2-14.9l0.1-0.8c-13.7,5-27.3,10.1-41.1,15.2c-3.5,1.3-7,2.6-10.5,3.8c-31.2,11-63.4,21.3-100.1,24.8
      c-2,0.2-4.1,0.4-6.2,0.5c-43.1,3.2-92,0.3-133.1-6.7c-27.2-4.6-52.7-10.8-74.9-18.1c-10.5-3.4-21.1-7-31.9-10.6
      c-23.1-7.7-53.2-16.1-76.6-22.1c-21.5-5.5-45.3-7.2-70.1-4.8c-8.5,0.8-17.2,2.1-25.9,3.9c-33,6.8-62.3,16.8-86.7,27
      c-12.8,5.3-24.4,11-35.7,16.6c-10.9,5.4-21.7,10.1-33,14l-0.1,0.8c11.7-3.9,22.8-8.8,34-14.4c11.3-5.6,22.9-11.3,35.6-16.6
      c24.3-10.1,53.5-20.1,86.3-26.9c8.6-1.8,17.2-3.1,25.6-3.9c24.5-2.4,47.9-0.7,69.2,4.7c23.4,5.9,53.5,14.4,76.5,22.1
      c10.8,3.6,21.5,7.2,31.9,10.6C366.4,140.2,392,146.4,419.2,151z M415.3,149.7c41.2,7.2,90.3,10.3,133.8,7.1c2.4-0.2,4.7-0.4,7-0.6
      c36.9-3.6,69.2-13.7,100.3-24.7c3.5-1.3,7.1-2.5,10.6-3.8c14.7-5.5,29.3-11,43.9-16.3l0.1-0.8c-15,5.4-29.8,11.1-44.8,16.7
      c-3.5,1.3-7,2.6-10.6,3.8c-31.1,11-63.2,21.1-99.8,24.6c-2.3,0.2-4.6,0.4-6.9,0.6c-43.3,3.1-92.1,0-133.1-7.1
      c-27.1-4.7-52.5-11-74.7-18.3c-10.4-3.5-21.1-7.1-31.9-10.7c-23-7.7-53.3-16.2-76.8-22.1c-21.3-5.3-44.8-7-69.3-4.6
      c-8.7,0.8-17.5,2.2-26.4,4.1c-32.9,6.9-62.1,16.9-86.2,27.1c-12.7,5.4-24.3,11-35.5,16.6C5.1,146-4.7,150.3-14.9,154l-0.1,0.8
      c10.6-3.7,20.7-8.2,30.9-13.3c11.3-5.6,22.8-11.2,35.5-16.6c24.1-10.1,53.2-20.1,85.9-27c8.8-1.8,17.5-3.2,26.1-4
      c24.2-2.3,47.3-0.7,68.4,4.6c23.5,5.9,53.7,14.4,76.6,22.1c10.8,3.6,21.4,7.2,31.9,10.7C362.5,138.6,388,145,415.3,149.7z
       M411,148.4c41.1,7.3,90.2,10.6,133.9,7.5c2.6-0.2,5.2-0.4,7.8-0.7c36.7-3.5,68.9-13.6,99.9-24.5c3.5-1.3,7.1-2.5,10.6-3.9
      c16.1-6,31.9-12,47.9-17.8l0.1-0.8c-16.3,5.9-32.4,12.1-48.8,18.2c-3.5,1.3-7,2.6-10.6,3.8c-31,10.9-63,20.9-99.3,24.4
      c-2.5,0.2-5.1,0.5-7.7,0.6c-43.4,3-92.3-0.2-133.2-7.5c-27-4.8-52.4-11.2-74.6-18.6c-10.4-3.5-21-7.1-31.8-10.8
      c-23-7.8-53.3-16.3-77-22.1c-21.1-5.2-44.3-6.8-68.5-4.5c-8.9,0.9-17.9,2.2-27,4.2c-32.7,6.9-61.8,17-85.8,27.1
      c-12.7,5.3-24.1,11-35.3,16.6c-8.8,4.4-17.5,8.3-26.4,11.7l-0.1,0.8c9.4-3.5,18.4-7.5,27.5-12.1c11.2-5.6,22.6-11.2,35.3-16.6
      c23.9-10.1,52.9-20.2,85.4-27c9-1.9,17.9-3.3,26.6-4.1c23.9-2.3,46.7-0.7,67.5,4.4c23.6,5.8,53.9,14.3,76.8,22.1
      c10.8,3.6,21.4,7.3,31.8,10.8C358.4,137.2,383.9,143.6,411,148.4z M406.5,147.3c41,7.4,90.1,10.8,133.9,7.9
      c2.9-0.2,5.8-0.4,8.6-0.7c36.5-3.5,68.6-13.4,99.5-24.3c3.6-1.3,7.1-2.5,10.6-3.9c17.5-6.5,34.7-13.1,52.1-19.4l0.1-0.8
      c-17.7,6.4-35.2,13.1-53,19.8c-3.5,1.3-7.1,2.6-10.6,3.8c-30.8,10.9-62.8,20.7-98.9,24.2c-2.8,0.3-5.6,0.5-8.5,0.7
      c-43.5,2.9-92.4-0.5-133.2-7.8c-27-4.9-52.3-11.3-74.4-18.8c-10.4-3.5-21-7.2-31.8-10.8c-22.9-7.8-53.4-16.4-77.1-22.2
      c-20.9-5.1-43.8-6.6-67.6-4.3c-9,0.9-18.2,2.3-27.5,4.3c-32.6,7-61.5,17.1-85.3,27.2c-12.6,5.4-24,11-35.2,16.6
      C0.6,142.5-6.9,146-14.5,149l-0.1,0.8c8-3.2,15.9-6.8,23.8-10.7c11.2-5.6,22.5-11.2,35.1-16.6c23.7-10.1,52.6-20.2,85-27.1
      c9.2-2,18.2-3.4,27.2-4.2c23.6-2.3,46.1-0.8,66.7,4.3c23.6,5.8,54.1,14.3,77,22.1c10.8,3.7,21.3,7.3,31.8,10.8
      C354.1,135.9,379.4,142.4,406.5,147.3z M401.7,146.3c40.9,7.5,90,11.1,133.9,8.2c3.2-0.2,6.3-0.5,9.4-0.8
      c36.3-3.5,68.3-13.2,99.1-24.1c3.6-1.3,7.1-2.5,10.6-3.9c19.1-7.1,37.8-14.4,56.7-21.2l0.1-0.8c-19.3,6.9-38.2,14.3-57.6,21.5
      c-3.5,1.3-7.1,2.6-10.6,3.9c-30.7,10.8-62.6,20.5-98.5,24c-3.1,0.3-6.2,0.5-9.3,0.7c-43.6,2.8-92.6-0.7-133.2-8.2
      c-26.9-5-52.1-11.5-74.2-19c-10.4-3.5-21-7.2-31.7-10.9c-22.9-7.9-53.5-16.4-77.2-22.2c-20.6-5-43.3-6.5-66.8-4.2
      c-9.2,0.9-18.6,2.4-28,4.4c-32.4,7-61.2,17.2-84.9,27.3c-12.6,5.4-23.9,11-35,16.6c-6.2,3.1-12.4,6-18.7,8.7l-0.1,0.8
      c6.7-2.8,13.2-5.8,19.8-9.1c11.1-5.6,22.4-11.2,34.9-16.6c23.6-10.1,52.3-20.2,84.5-27.2c9.3-2,18.6-3.5,27.7-4.4
      c23.2-2.2,45.5-0.8,65.9,4.1c23.7,5.7,54.3,14.3,77.1,22.1c10.7,3.7,21.3,7.4,31.7,10.9C349.3,134.7,374.6,141.3,401.7,146.3z
       M396.5,145.4c40.8,7.7,89.9,11.3,134,8.6c3.4-0.2,6.8-0.5,10.2-0.8c36-3.5,68-13.1,98.6-23.9c3.6-1.3,7.1-2.6,10.7-3.9
      c19.6-7.3,38.9-14.8,58.4-21.9c1.1-0.4,2.2-0.7,3.3-1.1l0.1-0.8c-1.4,0.5-2.8,0.9-4.2,1.4c-19.5,7.1-38.8,14.5-58.4,21.9
      c-3.5,1.3-7.1,2.6-10.6,3.9c-30.6,10.8-62.4,20.3-98.1,23.7c-3.3,0.3-6.7,0.6-10.1,0.8c-43.7,2.7-92.7-0.9-133.3-8.6
      c-26.9-5.1-52-11.6-74.1-19.2c-10.4-3.6-20.9-7.3-31.7-11c-22.8-7.9-53.6-16.5-77.4-22.2c-20.4-4.9-42.7-6.3-65.9-4.1
      c-9.4,0.9-18.9,2.4-28.5,4.5c-32.3,7.1-60.9,17.3-84.4,27.4c-12.5,5.4-23.8,11-34.8,16.6c-4.8,2.4-9.5,4.6-14.3,6.7l-0.1,0.8
      c5.1-2.3,10.2-4.6,15.3-7.2c11-5.6,22.3-11.2,34.7-16.6c23.4-10,51.9-20.2,84.1-27.2c9.5-2.1,18.9-3.6,28.2-4.5
      c22.9-2.2,44.9-0.8,65.1,4c23.8,5.7,54.5,14.3,77.2,22.2c10.7,3.7,21.3,7.4,31.7,11C344.2,133.7,369.5,140.3,396.5,145.4z
       M389.7,144.2c40.6,7.8,89.8,11.6,134,9c3.7-0.2,7.4-0.5,11-0.9c35.8-3.5,67.7-12.9,98.2-23.6c3.6-1.3,7.1-2.6,10.7-3.9
      c19.7-7.4,38.9-14.9,58.4-22c3.3-1.2,6.5-2.3,9.8-3.3l0.1-0.7c-3.6,1.1-7.1,2.3-10.7,3.6c-19.5,7.1-38.7,14.6-58.4,22
      c-3.5,1.3-7.1,2.6-10.6,3.9c-30.5,10.7-62.2,20.1-97.6,23.5c-3.6,0.3-7.2,0.6-10.9,0.9c-43.9,2.6-92.8-1.2-133.3-8.9
      c-26.8-5.1-51.9-11.8-73.9-19.4c-10.4-3.6-20.9-7.3-31.6-11.1c-22.7-7.9-53.6-16.6-77.6-22.2C187,86.2,165,84.8,142.1,87
      c-9.6,0.9-19.3,2.5-29.1,4.7c-32.1,7.1-60.7,17.4-84,27.4c-12.5,5.4-23.6,11.1-34.6,16.6c-2.8,1.4-5.5,2.7-8.2,4l-0.1,0.9
      c3.1-1.4,6.2-2.9,9.3-4.5c11-5.5,22.2-11.2,34.6-16.6c23.2-10,51.6-20.2,83.6-27.3c9.7-2.2,19.3-3.7,28.7-4.6
      c22.6-2.2,44.3-0.8,64.2,3.9c23.9,5.6,54.7,14.3,77.4,22.2c10.7,3.7,21.2,7.5,31.6,11.1C337.6,132.4,362.8,139,389.7,144.2z
       M383,143.3c40.5,7.9,89.7,11.9,134,9.3c4-0.2,7.9-0.5,11.8-0.9c35.6-3.4,67.3-12.8,97.7-23.4c3.6-1.3,7.2-2.6,10.7-3.9
      c19.7-7.4,38.9-14.9,58.4-22.1c5.4-2,10.8-3.7,16.2-5.3l0.1-0.7c-5.7,1.7-11.4,3.5-17.1,5.6c-19.5,7.1-38.7,14.7-58.4,22.1
      c-3.5,1.3-7.1,2.6-10.7,3.9c-30.3,10.6-61.9,19.9-97.2,23.3c-3.8,0.4-7.7,0.7-11.7,0.9c-44,2.5-93-1.4-133.3-9.3
      c-26.8-5.2-51.8-12-73.8-19.7c-10.4-3.6-20.9-7.4-31.6-11.1c-22.7-8-53.7-16.7-77.7-22.3c-20-4.6-41.7-6-64.3-3.8
      c-9.7,0.9-19.6,2.5-29.6,4.8c-32,7.2-60.4,17.5-83.5,27.5c-12.4,5.4-23.5,11.1-34.5,16.6c-0.8,0.4-1.5,0.7-2.3,1.1l-0.1,0.9
      c1.1-0.5,2.2-1.1,3.4-1.6c10.9-5.5,22-11.2,34.4-16.6c23-10,51.3-20.2,83.2-27.4c9.9-2.2,19.7-3.8,29.3-4.7
      c22.3-2.2,43.7-0.9,63.4,3.7c23.9,5.6,54.9,14.3,77.5,22.2c10.7,3.8,21.2,7.5,31.6,11.1C331.1,131.3,356.2,138.1,383,143.3z
       M376.5,142.6c40.4,8,89.6,12.1,134,9.7c4.3-0.2,8.5-0.6,12.7-1c35.3-3.4,67-12.6,97.3-23.2c3.6-1.3,7.2-2.6,10.7-3.9
      c19.7-7.4,39-15,58.4-22.2c7.6-2.8,15-5.1,22.5-7.2l0-0.7c-7.8,2.2-15.6,4.5-23.4,7.4c-19.5,7.2-38.7,14.8-58.4,22.2
      c-3.5,1.3-7.1,2.6-10.7,3.9c-30.2,10.6-61.7,19.7-96.7,23.1c-4.1,0.4-8.3,0.7-12.5,1c-44.1,2.4-93.1-1.6-133.4-9.6
      c-26.7-5.3-51.6-12.1-73.6-19.9c-10.3-3.6-20.8-7.4-31.5-11.2c-22.6-8-53.8-16.8-77.9-22.3c-19.7-4.5-41.2-5.8-63.4-3.6
      c-9.9,1-20,2.6-30.1,4.9c-31.9,7.2-60.1,17.6-83.1,27.6c-11,4.8-21,9.8-30.7,14.8l-0.1,0.9c10.1-5.1,20.3-10.3,31.7-15.3
      c22.9-10,51-20.2,82.7-27.5c10.1-2.3,20-3.9,29.8-4.9c21.9-2.1,43.1-0.9,62.5,3.6c24,5.5,55.1,14.3,77.7,22.2
      c10.7,3.8,21.2,7.6,31.5,11.2C324.6,130.4,349.6,137.3,376.5,142.6z M369.9,142.1c40.3,8.2,89.6,12.4,134.1,10
      c4.6-0.2,9-0.6,13.5-1c35.1-3.4,66.7-12.4,96.8-23c3.6-1.3,7.2-2.6,10.7-3.9c19.7-7.4,39-15,58.4-22.3c9.6-3.6,19.1-6.4,28.7-9
      l0.1-0.7c-9.9,2.6-19.7,5.5-29.6,9.2c-19.4,7.2-38.7,14.8-58.4,22.3c-3.6,1.3-7.1,2.6-10.7,3.9c-30,10.5-61.5,19.5-96.2,22.9
      c-4.4,0.4-8.8,0.8-13.4,1c-44.2,2.3-93.3-1.9-133.4-10c-26.7-5.4-51.5-12.3-73.5-20.1c-10.3-3.7-20.8-7.5-31.5-11.3
      c-22.6-8-53.8-16.8-78-22.3c-19.5-4.4-40.7-5.6-62.6-3.5c-10.1,1-20.3,2.7-30.7,5c-31.7,7.3-59.8,17.6-82.6,27.6
      c-8.8,3.9-16.9,7.9-24.8,11.9l-0.1,0.9c8.2-4.2,16.6-8.3,25.8-12.4c22.7-10,50.7-20.3,82.3-27.5c10.2-2.4,20.4-4,30.3-5
      c21.6-2.1,42.4-0.9,61.7,3.5c24.1,5.5,55.3,14.2,77.8,22.3c10.7,3.8,21.1,7.6,31.5,11.3C318.2,129.8,343.2,136.7,369.9,142.1z
       M363.5,141.9c40.2,8.3,89.5,12.7,134.1,10.4c4.8-0.2,9.6-0.6,14.3-1.1c34.9-3.4,66.4-12.3,96.3-22.8c3.6-1.3,7.2-2.6,10.8-3.9
      c19.8-7.5,39-15.1,58.4-22.3c11.7-4.4,23.1-7.6,34.9-10.7l0-0.7c-12,3.1-23.7,6.4-35.8,10.9c-19.5,7.3-38.7,14.9-58.5,22.4
      c-3.6,1.3-7.1,2.6-10.7,3.9c-29.9,10.5-61.2,19.3-95.8,22.7c-4.7,0.5-9.4,0.8-14.2,1c-44.3,2.2-93.4-2.1-133.4-10.4
      c-26.6-5.5-51.4-12.5-73.3-20.3c-10.3-3.7-20.8-7.5-31.4-11.4c-22.5-8.1-53.9-16.9-78.2-22.3c-19.2-4.3-40.1-5.5-61.7-3.4
      c-10.3,1-20.7,2.7-31.2,5.2c-31.6,7.4-59.6,17.7-82.1,27.7c-6.7,2.9-12.9,6-18.9,9l-0.1,0.9c6.4-3.2,12.9-6.4,19.9-9.5
      c22.5-9.9,50.4-20.3,81.8-27.6c10.4-2.4,20.7-4.1,30.9-5.1c21.3-2.1,41.8-0.9,60.9,3.3c24.2,5.4,55.5,14.2,78,22.3
      c10.6,3.8,21.1,7.7,31.4,11.4C311.9,129.4,336.8,136.4,363.5,141.9z M357.1,141.8c40.1,8.4,89.4,12.9,134.1,10.8
      c5.1-0.2,10.2-0.6,15.2-1.1c34.7-3.4,66-12.1,95.9-22.6c3.6-1.3,7.2-2.6,10.8-3.9c19.8-7.5,39.1-15.1,58.4-22.4
      c13.7-5.2,27.1-8.8,40.9-12.4l0-0.7c-14.1,3.7-27.8,7.4-41.8,12.7c-19.4,7.3-38.7,15-58.5,22.4c-3.6,1.3-7.1,2.6-10.7,3.9
      c-29.7,10.4-60.9,19.1-95.3,22.5c-4.9,0.5-9.9,0.8-15,1.1c-44.5,2.1-93.6-2.3-133.4-10.7c-26.5-5.6-51.3-12.6-73.2-20.5
      c-10.3-3.7-20.7-7.6-31.4-11.4c-22.4-8.1-54-17-78.3-22.4c-19-4.2-39.6-5.3-60.9-3.2c-10.4,1-21.1,2.8-31.8,5.3
      c-31.4,7.4-59.3,17.8-81.7,27.8c-4.6,2-8.9,4.1-13.1,6.2l-0.1,0.9c4.6-2.2,9.2-4.5,14.2-6.7c22.3-9.9,50.1-20.3,81.4-27.7
      c10.6-2.5,21.1-4.3,31.4-5.3c21-2,41.2-0.9,60,3.2c24.3,5.4,55.7,14.2,78.1,22.3c10.6,3.8,21.1,7.7,31.4,11.4
      C305.7,129.1,330.5,136.2,357.1,141.8z M350.8,141.9c40,8.5,89.3,13.2,134.2,11.1c5.4-0.2,10.8-0.6,16-1.1
      c34.4-3.3,65.7-12,95.4-22.4c3.6-1.3,7.2-2.6,10.8-3.9c19.8-7.5,39.1-15.2,58.4-22.5c15.7-5.9,30.9-9.9,46.9-14.1l0.1-0.7
      c-16.3,4.3-31.8,8.3-47.8,14.4c-19.4,7.4-38.6,15-58.5,22.5c-3.6,1.3-7.1,2.7-10.8,3.9c-29.6,10.4-60.7,19-94.8,22.3
      c-5.2,0.5-10.5,0.9-15.9,1.1c-44.6,2-93.7-2.6-133.4-11.1c-26.5-5.7-51.1-12.8-73-20.8c-10.3-3.7-20.7-7.6-31.3-11.5
      c-22.4-8.2-54-17.1-78.5-22.4c-18.8-4.1-39.1-5.2-60-3.1c-10.6,1-21.4,2.8-32.3,5.5C45,96.5,17.3,106.9-5,116.8
      c-2.6,1.1-5,2.3-7.5,3.4l-0.1,0.9c2.8-1.3,5.6-2.6,8.5-3.9c22.2-9.9,49.8-20.3,80.9-27.7c10.8-2.6,21.5-4.4,31.9-5.4
      c20.7-2,40.6-1,59.2,3.1c24.4,5.3,56,14.2,78.2,22.3c10.6,3.9,21.1,7.8,31.3,11.5C299.5,129.1,324.2,136.2,350.8,141.9z
       M344.6,142.1c39.9,8.7,89.2,13.4,134.2,11.5c5.7-0.2,11.3-0.6,16.9-1.2c34.2-3.3,65.4-11.8,94.9-22.2c3.6-1.3,7.2-2.6,10.8-3.9
      c19.9-7.5,39.1-15.2,58.5-22.6c17.6-6.7,34.6-11,52.8-15.9l0-0.7c-18.4,5-35.8,9.3-53.7,16.1c-19.4,7.4-38.6,15.1-58.5,22.6
      c-3.6,1.4-7.2,2.7-10.8,3.9c-29.4,10.3-60.4,18.8-94.3,22.1c-5.5,0.5-11.1,0.9-16.7,1.2c-44.7,1.9-93.8-2.8-133.5-11.4
      c-26.4-5.7-51-12.9-72.8-21c-10.3-3.8-20.7-7.7-31.3-11.6c-22.3-8.2-54.1-17.1-78.6-22.4c-18.6-4-38.6-5-59.2-3
      c-10.8,1-21.8,2.9-32.8,5.6c-31.1,7.5-58.7,18-80.8,27.9c-0.6,0.3-1.2,0.6-1.9,0.9l-0.1,0.9c1-0.4,1.9-0.9,2.9-1.3
      c22-9.9,49.5-20.3,80.5-27.8c10.9-2.7,21.8-4.5,32.4-5.5c20.4-2,40-1,58.3,3c24.4,5.3,56.2,14.2,78.4,22.4
      c10.6,3.9,21,7.8,31.3,11.6C293.4,129.1,318,136.4,344.6,142.1z M338.4,142.5c39.7,8.8,89.1,13.7,134.2,11.9
      c6-0.2,11.9-0.7,17.7-1.2c34-3.3,65-11.7,94.4-22c3.6-1.3,7.2-2.6,10.8-4c19.9-7.5,39.1-15.3,58.5-22.7
      c18.6-7.1,36.6-11.6,55.9-16.9c0.9-0.2,1.8-0.5,2.7-0.7l0-0.7c-1.1,0.3-2.3,0.6-3.4,0.9c-19.3,5.3-37.4,9.8-56.1,17
      c-19.3,7.4-38.6,15.2-58.5,22.7c-3.6,1.4-7.2,2.7-10.8,3.9c-29.3,10.2-60.2,18.6-93.8,21.9c-5.8,0.6-11.6,1-17.6,1.2
      c-44.8,1.8-94-3.1-133.5-11.8c-26.4-5.8-50.9-13.1-72.7-21.2c-10.2-3.8-20.6-7.7-31.2-11.7c-22.3-8.2-54.2-17.2-78.8-22.4
      c-18.3-3.9-38-4.8-58.3-2.9c-11,1.1-22.2,3-33.4,5.7c-29.3,7.2-55.4,17-76.7,26.4l-0.1,0.9c21.4-9.5,47.8-19.5,77.4-26.7
      c11.1-2.7,22.2-4.6,33-5.7c20-1.9,39.4-1,57.5,2.8c24.5,5.2,56.4,14.2,78.6,22.4c10.6,3.9,21,7.9,31.2,11.7
      C287.3,129.4,311.9,136.7,338.4,142.5z M332.2,143.1c39.6,8.9,89,14,134.3,12.2c6.3-0.2,12.5-0.7,18.6-1.3
      c33.8-3.3,64.7-11.6,93.9-21.8c3.6-1.3,7.3-2.6,10.8-4c19.9-7.6,39.2-15.3,58.5-22.8c18.6-7.2,36.5-11.7,55.8-17.1
      c2.9-0.8,5.7-1.6,8.6-2.4l0.1-0.7c-3.1,0.9-6.2,1.7-9.3,2.6c-19.3,5.4-37.3,9.9-56,17.2c-19.3,7.5-38.6,15.3-58.5,22.8
      c-3.6,1.4-7.2,2.7-10.8,3.9c-29.1,10.2-59.9,18.4-93.3,21.7c-6.1,0.6-12.2,1-18.4,1.2c-44.9,1.7-94.1-3.3-133.6-12.2
      c-26.3-5.9-50.8-13.3-72.5-21.4c-10.2-3.8-20.6-7.8-31.2-11.7c-22.2-8.3-54.2-17.3-79-22.5c-18.1-3.8-37.5-4.7-57.5-2.8
      c-11.2,1.1-22.5,3-33.9,5.9C32.1,96.7,8.1,105.5-12,114.1l-0.1,0.8c20.2-8.8,44.5-17.7,71.5-24.4c11.3-2.8,22.5-4.8,33.5-5.8
      c19.7-1.9,38.8-1,56.6,2.7c24.6,5.1,56.6,14.2,78.7,22.4c10.6,3.9,20.9,7.9,31.2,11.7C281.3,129.8,305.8,137.2,332.2,143.1z
       M326.2,143.8c39.5,9,88.9,14.2,134.3,12.6c6.6-0.2,13.1-0.7,19.5-1.3c33.5-3.2,64.3-11.4,93.4-21.6c3.6-1.3,7.3-2.6,10.9-4
      c20-7.6,39.2-15.4,58.5-22.9c18.6-7.2,36.4-11.8,55.7-17.3c4.8-1.4,9.6-2.7,14.4-4.1l0.1-0.7c-5,1.4-10.1,2.9-15.1,4.3
      c-19.2,5.5-37.2,10.1-55.8,17.4c-19.3,7.5-38.6,15.3-58.5,22.9c-3.6,1.4-7.2,2.7-10.8,3.9c-29,10.1-59.6,18.2-92.8,21.5
      c-6.3,0.6-12.8,1-19.3,1.3c-45.1,1.6-94.3-3.5-133.6-12.5c-26.3-6-50.6-13.4-72.4-21.6c-10.2-3.9-20.6-7.9-31.1-11.8
      c-22.1-8.3-54.3-17.4-79.1-22.5c-17.8-3.7-37-4.5-56.7-2.6c-11.3,1.1-22.9,3.1-34.4,6c-24.2,6.1-46.2,13.9-65,21.8l-0.1,0.8
      c18.9-8,41.2-16,65.6-22.1c11.5-2.9,22.9-4.9,34.1-6c19.4-1.9,38.2-1,55.8,2.6c24.7,5.1,56.8,14.2,78.8,22.4
      c10.6,4,20.9,8,31.1,11.8C275.4,130.3,299.8,137.8,326.2,143.8z M320.2,144.6c39.4,9.2,88.8,14.5,134.3,13
      c6.9-0.2,13.7-0.7,20.4-1.3c33.3-3.2,64-11.3,92.9-21.4c3.7-1.3,7.3-2.6,10.9-4c20-7.6,39.2-15.4,58.5-23
      c18.5-7.3,36.4-11.9,55.5-17.5c6.8-2,13.5-3.9,20.2-5.8l0-0.7c-6.9,2-13.9,4-20.9,6.1c-19.1,5.5-37.1,10.2-55.7,17.5
      c-19.3,7.6-38.5,15.4-58.5,23c-3.6,1.4-7.2,2.7-10.9,4c-28.8,10.1-59.3,18.1-92.3,21.3c-6.6,0.6-13.3,1.1-20.2,1.3
      c-45.2,1.5-94.4-3.8-133.6-12.9c-26.2-6.1-50.5-13.6-72.2-21.9c-10.2-3.9-20.5-7.9-31.1-11.9c-22.1-8.3-54.4-17.5-79.3-22.5
      c-17.6-3.6-36.4-4.4-55.8-2.5c-11.5,1.1-23.2,3.2-35,6.2C25.6,97,5.7,104-11.8,111.1l-0.1,0.8c17.6-7.2,37.8-14.4,59.9-20
      c11.7-3,23.2-5,34.6-6.1c19.1-1.8,37.6-1,55,2.5c24.8,5,57,14.1,79,22.4c10.5,4,20.9,8,31.1,11.9
      C269.5,131,293.9,138.5,320.2,144.6z M314.2,145.5c39.3,9.3,88.8,14.7,134.4,13.3c7.2-0.2,14.3-0.7,21.3-1.4
      c33.1-3.2,63.6-11.2,92.4-21.2c3.7-1.3,7.3-2.6,10.9-4c20-7.6,39.3-15.5,58.5-23.1c18.5-7.3,36.3-12,55.4-17.7
      c8.7-2.6,17.3-5.1,25.9-7.6l0.1-0.7c-8.8,2.5-17.7,5.2-26.6,7.8c-19.1,5.6-37,10.4-55.6,17.7c-19.3,7.6-38.5,15.5-58.5,23.1
      c-3.6,1.4-7.2,2.7-10.9,4c-28.6,10-59,17.9-91.8,21.1c-6.9,0.7-13.9,1.1-21.1,1.3c-45.3,1.4-94.5-4-133.6-13.2
      c-26.1-6.2-50.4-13.7-72.1-22.1c-10.2-3.9-20.5-8-31-12c-22-8.4-54.4-17.5-79.4-22.6c-17.4-3.5-35.9-4.3-55-2.4
      c-11.7,1.1-23.6,3.3-35.6,6.3c-19.5,5-37.5,11.2-53.5,17.5l-0.1,0.8c16.2-6.5,34.5-12.8,54.2-17.8c11.8-3.1,23.6-5.1,35.1-6.3
      c18.8-1.8,37-1,54.1,2.4c24.8,5,57.2,14.1,79.1,22.5c10.5,4,20.8,8,31,12C263.7,131.7,288,139.3,314.2,145.5z M308.3,146.5
      c39.2,9.4,88.6,15,134.4,13.7c7.5-0.2,14.9-0.7,22.1-1.4c32.8-3.2,63.3-11,91.8-21c3.7-1.3,7.3-2.6,10.9-4
      c20-7.6,39.3-15.5,58.5-23.2c18.5-7.3,36.2-12.2,55.3-17.8c10.6-3.2,21.1-6.4,31.5-9.4l0.1-0.7c-10.7,3.1-21.4,6.3-32.3,9.6
      c-19,5.7-36.9,10.5-55.4,17.9c-19.3,7.6-38.5,15.5-58.5,23.2c-3.6,1.4-7.2,2.7-10.9,4c-28.5,9.9-58.7,17.7-91.3,20.9
      c-7.2,0.7-14.5,1.2-21.9,1.4c-45.4,1.3-94.7-4.3-133.7-13.6c-26.1-6.3-50.3-13.9-71.9-22.3c-10.2-3.9-20.5-8-31-12
      c-22-8.4-54.5-17.6-79.6-22.6c-17.1-3.4-35.4-4.1-54.1-2.3c-11.9,1.1-24,3.3-36.1,6.5C19,97.7,3,103.2-11.6,108.8l-0.1,0.8
      c14.8-5.8,31.1-11.3,48.6-15.9c12-3.1,24-5.3,35.7-6.4c18.5-1.8,36.4-1.1,53.3,2.3c24.9,4.9,57.4,14.1,79.3,22.5
      c10.5,4,20.8,8.1,31,12C257.9,132.6,282.1,140.2,308.3,146.5z M302.4,147.6c39.1,9.5,88.6,15.3,134.4,14c7.8-0.2,15.5-0.7,23-1.4
      c32.6-3.2,62.9-10.9,91.3-20.8c3.7-1.3,7.3-2.6,11-4c20.1-7.7,39.3-15.6,58.5-23.3c18.4-7.4,36.2-12.3,55.1-18
      c12.4-3.8,24.8-7.2,37-10.3l0-0.7c-12.5,3.2-25.2,6.6-37.8,10.5c-18.9,5.8-36.8,10.7-55.3,18.1c-19.2,7.7-38.5,15.6-58.5,23.3
      c-3.6,1.4-7.2,2.7-10.9,4c-28.3,9.9-58.4,17.5-90.7,20.7c-7.5,0.7-15.1,1.2-22.8,1.4c-45.5,1.2-94.8-4.5-133.7-14
      c-26-6.3-50.1-14.1-71.8-22.5c-10.2-4-20.4-8.1-30.9-12.1c-21.9-8.4-54.6-17.7-79.7-22.6c-16.9-3.3-34.8-4-53.3-2.2
      c-12.1,1.2-24.3,3.4-36.6,6.6c-15.1,4-29.3,8.7-42.3,13.6l-0.1,0.8c13.3-5,27.7-9.8,43.1-13.9c12.2-3.2,24.3-5.4,36.2-6.6
      c18.2-1.8,35.8-1.1,52.5,2.2c25,4.9,57.6,14.1,79.4,22.5c10.5,4,20.8,8.1,30.9,12.1C252.1,133.5,276.3,141.3,302.4,147.6z
       M296.6,148.8c39,9.7,88.5,15.5,134.4,14.4c8.1-0.2,16.1-0.7,23.9-1.5c32.4-3.1,62.5-10.8,90.8-20.6c3.7-1.3,7.3-2.6,11-4
      c20.1-7.7,39.4-15.6,58.6-23.4c18.4-7.4,36.1-12.4,55-18.2c14.2-4.4,28.5-8.4,42.6-11.9l0-0.7c-14.3,3.6-28.9,7.6-43.3,12.1
      c-18.9,5.8-36.6,10.8-55.2,18.3c-19.2,7.7-38.5,15.7-58.5,23.4c-3.6,1.4-7.2,2.7-10.9,4c-28.1,9.8-58.1,17.4-90.2,20.5
      c-7.8,0.8-15.7,1.2-23.7,1.4c-45.7,1.1-95-4.7-133.7-14.3c-26-6.4-50-14.2-71.6-22.7c-10.2-4-20.4-8.1-30.9-12.2
      c-21.9-8.5-54.6-17.8-79.9-22.6c-16.6-3.2-34.3-3.8-52.4-2.1c-12.3,1.2-24.7,3.5-37.2,6.8c-13,3.5-25.3,7.5-36.8,11.7l-0.1,0.8
      c11.7-4.3,24.3-8.4,37.6-12c12.4-3.3,24.7-5.6,36.7-6.7c17.9-1.7,35.2-1.1,51.6,2.1c25.1,4.8,57.8,14.1,79.6,22.5
      c10.5,4.1,20.7,8.2,30.9,12.2C246.5,134.5,270.6,142.4,296.6,148.8z M290.9,150c38.8,9.8,88.4,15.8,134.5,14.8
      c8.4-0.2,16.7-0.7,24.9-1.5c32.2-3.1,62.2-10.6,90.2-20.4c3.7-1.3,7.3-2.6,11-4c20.1-7.7,39.4-15.7,58.6-23.5
      c18.4-7.4,36.1-12.5,54.9-18.4c16.1-5,32.2-9.5,48-13.5l0-0.7c-16.1,4-32.5,8.6-48.8,13.7c-18.8,5.9-36.5,11-55,18.4
      c-19.2,7.8-38.5,15.8-58.5,23.4c-3.6,1.4-7.3,2.7-10.9,4c-28,9.7-57.8,17.2-89.7,20.3c-8.1,0.8-16.3,1.3-24.6,1.5
      c-45.8,1-95.1-5-133.8-14.7c-25.9-6.5-49.9-14.4-71.4-23c-10.1-4-20.4-8.2-30.8-12.3c-21.8-8.5-54.7-17.9-80.1-22.7
      c-16.4-3.1-33.7-3.7-51.6-2c-12.4,1.2-25,3.5-37.7,7c-11,3-21.5,6.3-31.4,9.8l-0.1,0.8c10.1-3.6,20.9-7.1,32.1-10.1
      c12.5-3.4,25-5.7,37.3-6.9c17.6-1.7,34.7-1.1,50.8,2c25.2,4.8,58.1,14.1,79.7,22.6c10.5,4.1,20.7,8.2,30.8,12.3
      C240.8,135.6,264.9,143.5,290.9,150z M285.1,151.3c38.7,9.9,88.3,16.1,134.5,15.1c8.7-0.2,17.3-0.7,25.8-1.5
      c32-3.1,61.8-10.5,89.7-20.2c3.7-1.3,7.4-2.6,11-4c20.1-7.7,39.4-15.7,58.6-23.5c18.3-7.5,36-12.6,54.8-18.6
      c17.9-5.7,35.9-10.7,53.5-15.1l0-0.7c-17.9,4.5-36.1,9.6-54.3,15.4c-18.7,6-36.4,11.1-54.9,18.6c-19.2,7.8-38.4,15.8-58.6,23.5
      c-3.6,1.4-7.3,2.7-11,4c-27.8,9.7-57.5,17-89.2,20.1c-8.4,0.8-16.9,1.3-25.5,1.5c-45.9,0.9-95.3-5.2-133.8-15.1
      c-25.9-6.6-49.8-14.5-71.3-23.2c-10.1-4.1-20.3-8.2-30.8-12.3c-21.7-8.6-54.7-17.9-80.2-22.7c-16.1-3-33.2-3.6-50.7-1.9
      c-12.6,1.2-25.4,3.6-38.2,7.1c-9,2.5-17.7,5.2-26,8l-0.1,0.8c8.5-3,17.4-5.8,26.7-8.3c12.7-3.5,25.4-5.8,37.8-7
      c17.3-1.7,34.1-1.1,49.9,1.9c25.3,4.7,58.3,14.1,79.9,22.6c10.5,4.1,20.7,8.3,30.8,12.3C235.2,136.8,259.2,144.7,285.1,151.3z
       M279.4,152.7c38.6,10,88.2,16.3,134.6,15.5c9-0.2,17.9-0.7,26.7-1.5c31.7-3.1,61.4-10.4,89.2-20c3.7-1.3,7.4-2.6,11-4
      c20.2-7.7,39.5-15.8,58.6-23.6c18.3-7.5,35.9-12.7,54.6-18.8c19.7-6.4,39.6-12,58.9-16.8l0-0.7c-19.6,4.9-39.7,10.5-59.7,17
      c-18.7,6-36.3,11.3-54.8,18.8c-19.1,7.8-38.4,15.9-58.6,23.6c-3.6,1.4-7.3,2.7-11,4c-27.6,9.6-57.1,16.9-88.6,19.9
      c-8.7,0.8-17.5,1.4-26.4,1.5c-46,0.8-95.4-5.4-133.8-15.4c-25.8-6.7-49.6-14.7-71.1-23.4c-10.1-4.1-20.3-8.3-30.7-12.4
      c-21.7-8.6-54.8-18-80.4-22.7c-15.9-2.9-32.7-3.5-49.9-1.8c-12.8,1.2-25.8,3.7-38.8,7.3c-7.1,2-14,4.1-20.6,6.3l-0.1,0.8
      c6.9-2.3,14-4.6,21.4-6.6c12.9-3.6,25.7-6,38.3-7.2c17-1.6,33.5-1.1,49.1,1.8c25.4,4.6,58.5,14.1,80,22.6
      c10.4,4.1,20.6,8.3,30.7,12.4C229.6,137.9,253.6,146,279.4,152.7z M273.8,154.1c38.5,10.1,88.1,16.6,134.6,15.9
      c9.4-0.1,18.6-0.7,27.6-1.6c31.5-3,61-10.3,88.6-19.8c3.7-1.3,7.4-2.6,11-4c20.2-7.8,39.5-15.8,58.6-23.7
      c18.3-7.5,35.9-12.8,54.5-18.9c21.6-7.1,43.2-13.2,64.2-18.5l0-0.7c-21.2,5.3-43.2,11.5-65,18.7c-18.6,6.1-36.2,11.4-54.6,19
      c-19.1,7.9-38.4,16-58.6,23.7c-3.6,1.4-7.3,2.7-11,4c-27.5,9.5-56.8,16.7-88.1,19.7c-9,0.9-18.1,1.4-27.3,1.5
      c-46.1,0.7-95.5-5.7-133.8-15.8c-25.7-6.8-49.5-14.9-71-23.6c-10.1-4.1-20.3-8.3-30.7-12.5c-21.6-8.6-54.9-18.1-80.5-22.7
      c-15.6-2.8-32.1-3.3-49-1.7c-13,1.3-26.1,3.8-39.3,7.5c-5.3,1.5-10.3,3.1-15.3,4.7l-0.1,0.7c5.2-1.7,10.5-3.4,16.1-4.9
      c13.1-3.7,26.1-6.1,38.9-7.4c16.7-1.6,32.9-1.1,48.3,1.7c25.4,4.6,58.7,14,80.1,22.6c10.4,4.2,20.6,8.4,30.7,12.5
      C224.1,139.2,248,147.3,273.8,154.1z M268.2,155.5c38.4,10.3,88,16.8,134.6,16.2c9.7-0.1,19.2-0.7,28.5-1.6
      c31.3-3,60.7-10.1,88.1-19.7c3.7-1.3,7.4-2.6,11.1-4.1c20.2-7.8,39.5-15.9,58.6-23.8c18.3-7.6,35.8-12.9,54.4-19.1
      c23.4-7.8,46.9-14.5,69.5-20.2l0-0.7c-22.9,5.7-46.7,12.5-70.4,20.4c-18.5,6.2-36.1,11.6-54.5,19.2c-19.1,7.9-38.4,16-58.6,23.8
      c-3.6,1.4-7.3,2.7-11,4c-27.3,9.5-56.5,16.5-87.5,19.5c-9.2,0.9-18.7,1.4-28.3,1.6c-46.3,0.6-95.7-5.9-133.9-16.1
      c-25.7-6.9-49.4-15-70.8-23.8c-10.1-4.1-20.2-8.4-30.6-12.6c-21.6-8.7-54.9-18.2-80.7-22.8c-15.4-2.7-31.6-3.2-48.2-1.6
      c-13.1,1.3-26.5,3.8-39.8,7.6c-3.4,1-6.8,2-10.1,3l-0.1,0.7c3.5-1.1,7.1-2.2,10.8-3.3c13.2-3.7,26.4-6.3,39.4-7.5
      c16.4-1.6,32.3-1.1,47.4,1.6c25.5,4.5,58.9,14,80.3,22.7c10.4,4.2,20.6,8.4,30.6,12.6C218.7,140.4,242.4,148.6,268.2,155.5z
       M262.6,156.9c38.3,10.4,87.9,17.1,134.6,16.6c10-0.1,19.8-0.7,29.4-1.6c31.1-3,60.3-10,87.5-19.5c3.7-1.3,7.4-2.7,11.1-4.1
      c20.3-7.8,39.5-15.9,58.6-23.9c18.2-7.6,35.7-13.1,54.2-19.3c25.2-8.5,50.6-15.8,74.8-21.9l0-0.7c-24.5,6.1-50.1,13.5-75.7,22.1
      c-18.4,6.2-36,11.7-54.3,19.4c-19.1,8-38.4,16.1-58.6,23.9c-3.7,1.4-7.3,2.7-11,4c-27.1,9.4-56.2,16.4-87,19.4
      c-9.6,0.9-19.3,1.5-29.2,1.6c-46.4,0.5-95.8-6.2-133.9-16.5c-25.6-6.9-49.3-15.2-70.7-24.1c-10-4.2-20.2-8.4-30.6-12.6
      c-21.5-8.7-55-18.3-80.8-22.8c-15.1-2.6-31.1-3.1-47.4-1.5C20.6,96.7,7,99.3-6.5,103.2c-1.6,0.5-3.2,1-4.8,1.5l-0.1,0.7
      c1.9-0.6,3.7-1.2,5.6-1.7C7.6,99.8,21,97.2,34.1,96c16.1-1.6,31.7-1.1,46.6,1.5c25.6,4.5,59.1,14,80.4,22.7
      c10.4,4.2,20.5,8.5,30.6,12.6C213.2,141.6,236.9,149.9,262.6,156.9z M257.1,158.3c38.2,10.5,87.8,17.4,134.7,16.9
      c10.3-0.1,20.4-0.6,30.4-1.6c30.8-3,59.9-9.9,87-19.3c3.7-1.3,7.4-2.7,11.1-4.1c20.3-7.8,39.5-16,58.6-24
      c18.2-7.6,35.7-13.2,54.1-19.5c27.1-9.3,54.3-17.2,80.1-23.6l0-0.7c-26.1,6.5-53.5,14.5-80.9,23.9c-18.4,6.3-35.9,11.9-54.2,19.5
      c-19.1,8-38.3,16.2-58.6,24c-3.7,1.4-7.3,2.8-11.1,4c-26.9,9.3-55.8,16.2-86.4,19.2c-9.9,1-19.9,1.5-30.1,1.6
      c-46.5,0.4-96-6.4-134-16.9c-25.6-7-49.1-15.4-70.5-24.3c-10-4.2-20.2-8.5-30.5-12.7c-21.4-8.7-55-18.4-81-22.8
      c-14.9-2.6-30.5-3-46.5-1.4c-13.4,1.3-27,4-40.5,7.9l-0.1,0.7c0.1,0,0.3-0.1,0.4-0.1c13.6-3.9,27.1-6.6,40.4-7.9
      c15.8-1.5,31.2-1.1,45.8,1.4c25.7,4.4,59.3,14,80.6,22.7c10.4,4.2,20.5,8.5,30.5,12.7C207.8,142.9,231.4,151.2,257.1,158.3z
       M251.6,159.7c38.1,10.6,87.8,17.6,134.7,17.3c10.6-0.1,21-0.6,31.3-1.6c30.6-3,59.5-9.8,86.4-19.1c3.7-1.3,7.5-2.7,11.1-4.1
      c20.3-7.8,39.6-16,58.6-24.1c18.2-7.7,35.6-13.3,54-19.7c28.9-10.1,58-18.6,85.3-25.5l0-0.7c-27.6,6.9-56.9,15.5-86.2,25.7
      c-18.3,6.4-35.8,12-54.1,19.7c-19.1,8.1-38.3,16.2-58.6,24.1c-3.6,1.4-7.3,2.8-11.1,4.1c-26.8,9.3-55.5,16.1-85.8,19
      c-10.1,1-20.5,1.5-31,1.6c-46.6,0.3-96.1-6.6-134-17.2c-25.5-7.1-49-15.5-70.4-24.5c-10-4.2-20.2-8.5-30.5-12.8
      c-21.4-8.8-55.1-18.4-81.1-22.8c-14.6-2.5-30-2.8-45.7-1.3c-11.9,1.1-23.9,3.4-35.9,6.6l-0.1,0.7c12.2-3.3,24.3-5.6,36.2-6.8
      c15.5-1.5,30.6-1.1,44.9,1.3c25.8,4.4,59.5,14,80.7,22.7c10.3,4.3,20.5,8.6,30.5,12.8C202.4,144.1,226,152.5,251.6,159.7z
       M246.1,161c38,10.8,87.7,17.9,134.7,17.7c10.9,0,21.7-0.6,32.2-1.6c30.4-2.9,59.1-9.7,85.9-18.9c3.8-1.3,7.5-2.7,11.1-4.1
      c20.3-7.9,39.6-16.1,58.7-24.2c18.1-7.7,35.5-13.4,53.8-19.9c30.8-10.9,61.7-20,90.5-27.3L713,82c-29.1,7.3-60.3,16.5-91.4,27.5
      c-18.3,6.4-35.7,12.1-53.9,19.9c-19,8.1-38.3,16.3-58.6,24.2c-3.7,1.4-7.3,2.8-11.1,4.1c-26.6,9.2-55.1,15.9-85.3,18.8
      c-10.5,1-21.1,1.6-31.9,1.6c-46.8,0.2-96.3-6.9-134-17.6c-25.4-7.2-48.9-15.7-70.2-24.7c-10-4.2-20.1-8.6-30.4-12.9
      c-21.3-8.8-55.1-18.5-81.3-22.9c-14.4-2.4-29.5-2.7-44.9-1.2c-10.4,1-20.8,2.9-31.3,5.5l0,0.7c10.6-2.7,21.2-4.6,31.6-5.6
      c15.2-1.5,30-1.1,44.1,1.2c25.9,4.3,59.7,14,80.9,22.7c10.3,4.3,20.4,8.6,30.4,12.9C197,145.3,220.6,153.8,246.1,161z M375.4,180.4
      c11.2,0,22.3-0.6,33.1-1.6c30.2-2.9,58.8-9.6,85.3-18.8c3.8-1.3,7.5-2.7,11.2-4.1c20.4-7.9,39.7-16.1,58.7-24.3
      c18.1-7.7,35.5-13.5,53.7-20c32.6-11.7,65.4-21.5,95.7-29.2l0-0.7c-30.5,7.7-63.6,17.6-96.5,29.4c-18.2,6.5-35.6,12.3-53.8,20.1
      c-19,8.1-38.3,16.4-58.6,24.3c-3.7,1.4-7.4,2.8-11.1,4.1c-26.4,9.1-54.8,15.8-84.7,18.7c-10.7,1-21.7,1.6-32.8,1.6
      c-46.9,0.1-96.4-7.1-134-17.9c-25.4-7.3-48.8-15.8-70.1-24.9c-10-4.3-20.1-8.6-30.4-12.9c-21.3-8.9-55.2-18.6-81.5-22.9
      c-14.1-2.3-28.9-2.6-44-1.2c-8.8,0.9-17.8,2.4-26.7,4.4l0,0.7c9.1-2.1,18.1-3.6,27-4.5c14.9-1.4,29.4-1.1,43.3,1.1
      c26,4.2,59.9,14,81,22.8c10.3,4.3,20.4,8.7,30.4,12.9c21.3,9.1,44.8,17.7,70.2,25C278.5,173.2,328.2,180.5,375.4,180.4z M370,182
      c11.5,0,22.9-0.6,34-1.6c30-2.9,58.4-9.5,84.8-18.6c3.7-1.3,7.5-2.7,11.2-4.1c20.4-7.9,39.7-16.2,58.7-24.4
      c18.1-7.8,35.4-13.6,53.6-20.2c34.5-12.5,69.2-23,100.8-31.1l0.1-0.7c-31.9,8.1-66.9,18.7-101.7,31.3
      c-18.1,6.6-35.5,12.4-53.7,20.3c-19,8.2-38.3,16.5-58.6,24.4c-3.7,1.4-7.4,2.8-11.1,4.1c-26.2,9.1-54.4,15.6-84.2,18.5
      c-11,1.1-22.3,1.6-33.8,1.6c-47,0-96.5-7.3-134.1-18.3c-25.3-7.4-48.6-16-69.9-25.2c-10-4.3-20.1-8.7-30.4-13
      c-21.2-8.9-55.3-18.7-81.6-22.9c-13.8-2.2-28.4-2.5-43.2-1.1c-7.3,0.7-14.7,1.9-22.2,3.4l0,0.7c7.5-1.6,15-2.8,22.5-3.5
      c14.6-1.4,28.9-1.1,42.5,1.1c26.1,4.2,60.1,14,81.2,22.8c10.3,4.3,20.3,8.7,30.3,13c21.3,9.2,44.7,17.8,70.1,25.2
      C272.9,174.7,322.7,182.1,370,182z M364.6,183.6c11.9,0,23.5-0.5,35-1.7c29.8-2.9,58-9.4,84.2-18.4c3.8-1.3,7.5-2.7,11.2-4.1
      c20.4-7.9,39.7-16.2,58.7-24.5c18.1-7.8,35.4-13.7,53.4-20.4c36.4-13.4,73-24.5,106-33l0-0.7c-33.3,8.5-70.2,19.8-106.9,33.3
      c-18,6.7-35.4,12.6-53.5,20.4c-19,8.2-38.2,16.5-58.6,24.4c-3.7,1.4-7.4,2.8-11.1,4.1c-26.1,9-54.1,15.5-83.6,18.3
      c-11.3,1.1-22.9,1.7-34.7,1.6c-47.1-0.1-96.7-7.6-134.1-18.7c-25.3-7.5-48.5-16.1-69.7-25.4c-10-4.3-20-8.7-30.3-13.1
      c-21.1-8.9-55.3-18.8-81.7-22.9c-13.6-2.1-27.9-2.4-42.4-1c-5.8,0.6-11.7,1.4-17.6,2.5l0,0.6c6-1.1,12-2,17.9-2.6
      c14.3-1.4,28.3-1.1,41.7,1c26.2,4.1,60.3,13.9,81.3,22.8c10.3,4.3,20.3,8.8,30.3,13.1c21.3,9.2,44.6,17.9,69.9,25.4
      C267.4,176,317.1,183.5,364.6,183.6z M359.2,185.2c12.2,0.1,24.2-0.5,35.9-1.7c29.5-2.9,57.6-9.3,83.6-18.3
      c3.8-1.3,7.5-2.7,11.2-4.1c20.5-7.9,39.7-16.3,58.7-24.6c18-7.9,35.3-13.9,53.3-20.6c38-14.2,76.2-26,110.3-34.9
      c0.3-0.1,0.5-0.1,0.8-0.2l0-0.7c-0.5,0.1-1,0.3-1.5,0.4c-34.2,8.9-72.5,20.7-110.6,34.9c-18,6.7-35.3,12.7-53.4,20.6
      c-19,8.3-38.2,16.6-58.6,24.5c-3.7,1.4-7.4,2.8-11.2,4.1c-25.9,8.9-53.8,15.3-83,18.1c-11.6,1.1-23.5,1.7-35.6,1.6
      c-47.2-0.2-96.8-7.8-134.1-19c-25.2-7.6-48.4-16.3-69.6-25.6c-10-4.3-20-8.8-30.3-13.2c-21.1-9-55.4-18.9-81.9-23
      c-13.4-2.1-27.3-2.3-41.6-0.9c-4.4,0.4-8.8,1.1-13.2,1.8l0,0.6c4.5-0.8,9-1.4,13.4-1.8c14-1.4,27.7-1.1,40.8,0.9
      c26.2,4.1,60.5,13.9,81.4,22.8c10.3,4.4,20.3,8.8,30.2,13.2c21.3,9.3,44.5,18.1,69.8,25.7C261.9,177.3,311.7,185,359.2,185.2z
       M353.9,186.6c12.5,0.1,24.8-0.5,36.9-1.7c29.3-2.8,57.2-9.2,83-18.1c3.8-1.3,7.5-2.7,11.2-4.1c20.5-8,39.8-16.3,58.7-24.6
      c18-7.9,35.2-14,53.2-20.8c37.9-14.3,76-26.4,110-35.5c2.1-0.6,4.2-1.1,6.3-1.7l0.1-0.7c-2.3,0.6-4.7,1.3-7,1.9
      c-34.1,9.1-72.3,21.2-110.2,35.5c-17.9,6.8-35.2,12.9-53.3,20.8c-18.9,8.3-38.2,16.7-58.7,24.6c-3.7,1.4-7.4,2.8-11.2,4.1
      c-25.7,8.9-53.4,15.2-82.5,18c-12,1.2-24.2,1.7-36.5,1.7c-47.4-0.3-97-8.1-134.2-19.4c-25.1-7.6-48.3-16.5-69.4-25.8
      c-9.9-4.4-20-8.8-30.2-13.2c-21-9-55.5-18.9-82.1-23c-13.1-2-26.8-2.2-40.7-0.9c-2.9,0.3-5.8,0.6-8.6,1.1l0,0.6
      c3-0.4,6-0.8,8.9-1.1c13.7-1.3,27.2-1.1,40,0.8c26.4,4,60.7,13.9,81.6,22.9c10.2,4.4,20.2,8.9,30.2,13.2
      c21.2,9.4,44.4,18.2,69.6,25.9C256.4,178.5,306.2,186.3,353.9,186.6z M348.5,188c12.8,0.1,25.4-0.5,37.8-1.7
      c29.1-2.8,56.8-9.1,82.5-17.9c3.8-1.3,7.5-2.7,11.2-4.1c20.5-8,39.8-16.4,58.7-24.7c18-7.9,35.2-14.1,53-20.9
      c37.7-14.5,75.7-26.7,109.6-36.1c4-1.1,7.9-2.2,11.8-3.3l0.1-0.7c-4.1,1.2-8.3,2.4-12.5,3.5c-34,9.4-72,21.6-109.8,36.1
      c-17.8,6.9-35.1,13-53.1,21c-18.9,8.3-38.2,16.7-58.7,24.7c-3.7,1.4-7.4,2.8-11.2,4.1c-25.5,8.8-53.1,15-81.9,17.8
      c-12.3,1.2-24.8,1.8-37.5,1.6c-47.5-0.4-97.1-8.3-134.2-19.7c-25.1-7.7-48.1-16.6-69.3-26c-9.9-4.4-19.9-8.9-30.1-13.3
      c-21-9-55.5-19-82.2-23c-12.8-1.9-26.3-2.1-39.9-0.8c-1.4,0.1-2.8,0.3-4.2,0.5l0,0.6c1.5-0.2,3-0.4,4.4-0.5
      c13.4-1.3,26.6-1.1,39.2,0.8c26.4,3.9,60.9,13.9,81.8,22.9c10.2,4.4,20.2,8.9,30.1,13.3c21.2,9.4,44.3,18.3,69.4,26.1
      C250.9,179.6,300.7,187.6,348.5,188z M343.2,189.2c13.1,0.1,26-0.4,38.7-1.7c28.9-2.8,56.4-9,81.9-17.8c3.8-1.3,7.6-2.7,11.3-4.1
      c20.5-8,39.8-16.4,58.7-24.8c17.9-8,35.1-14.2,52.9-21.1C624.4,105,662.2,92.6,696,83c5.8-1.6,11.6-3.3,17.3-5l0.1-0.7
      c-6,1.8-12,3.6-18,5.3c-33.9,9.6-71.8,22.1-109.5,36.7c-17.8,6.9-35,13.2-53,21.2c-18.9,8.4-38.2,16.8-58.7,24.8
      c-3.7,1.4-7.4,2.8-11.2,4.1c-25.3,8.8-52.7,14.9-81.4,17.6c-12.6,1.2-25.4,1.8-38.4,1.7c-47.6-0.5-97.2-8.5-134.2-20.1
      c-25-7.8-48-16.8-69.1-26.2c-9.9-4.4-19.9-9-30.1-13.4c-20.9-9.1-55.6-19.1-82.4-23c-12.5-1.8-25.6-2-38.8-0.7l0,0.6
      c13.1-1.3,26-1.1,38.4,0.7c26.5,3.9,61.1,13.9,81.9,22.9c10.2,4.4,20.2,8.9,30.1,13.4c21.2,9.5,44.2,18.5,69.3,26.3
      C245.5,180.6,295.3,188.7,343.2,189.2z M337.9,190.4c13.4,0.2,26.7-0.4,39.6-1.7c28.7-2.8,56.1-8.9,81.4-17.6
      c3.8-1.3,7.6-2.7,11.3-4.2c20.5-8,39.9-16.5,58.7-24.9c17.9-8,35-14.3,52.8-21.3c37.5-14.8,75.2-27.5,108.9-37.3
      c7.7-2.2,15.2-4.5,22.8-6.9l0.1-0.7c-7.8,2.4-15.6,4.8-23.5,7.1c-33.8,9.8-71.5,22.5-109.1,37.3c-17.7,7-34.9,13.3-52.8,21.3
      c-18.9,8.4-38.2,16.9-58.7,24.9c-3.7,1.4-7.5,2.8-11.2,4.1c-25.2,8.7-52.4,14.7-80.8,17.5c-12.9,1.2-26,1.8-39.3,1.6
      c-47.7-0.6-97.4-8.8-134.3-20.5c-25-7.9-47.9-17-69-26.5c-9.9-4.5-19.9-9-30.1-13.5c-20.9-9.1-55.6-19.2-82.5-23.1
      c-10.8-1.5-22.1-1.9-33.5-1.1l0,0.6c11.3-0.8,22.4-0.5,33.1,1c26.6,3.8,61.4,13.9,82,22.9c10.2,4.5,20.1,9,30,13.5
      c21.1,9.5,44.1,18.6,69.1,26.5C240,181.5,289.9,189.7,337.9,190.4z M332.6,191.4c13.7,0.2,27.3-0.4,40.5-1.7
      c28.5-2.8,55.7-8.8,80.8-17.4c3.8-1.3,7.6-2.7,11.3-4.2c20.6-8.1,39.9-16.6,58.8-25c17.9-8,35-14.4,52.7-21.5
      c37.4-15,74.9-27.9,108.6-37.9c9.5-2.8,18.9-5.8,28.3-8.8l0.1-0.7c-9.6,3.1-19.3,6.1-29,9c-33.8,10-71.3,23-108.7,38
      c-17.7,7.1-34.8,13.5-52.7,21.5c-18.9,8.5-38.1,17-58.7,25c-3.7,1.4-7.4,2.8-11.2,4.1c-25,8.6-52,14.6-80.3,17.3
      c-13.2,1.3-26.6,1.8-40.2,1.6c-47.8-0.7-97.5-9-134.3-20.8c-24.9-8-47.8-17.1-68.8-26.7c-9.9-4.5-19.8-9-30-13.5
      c-20.8-9.1-55.7-19.3-82.7-23.1c-9.2-1.3-18.7-1.6-28.3-1.2l0,0.6c9.5-0.4,18.8-0.1,27.9,1.1c26.7,3.7,61.6,13.9,82.2,23
      c10.2,4.5,20.1,9,30,13.5c21.1,9.6,44,18.7,69,26.8C234.6,182.3,284.4,190.7,332.6,191.4z M327.3,192.2c14.1,0.2,27.9-0.3,41.5-1.6
      c28.3-2.7,55.3-8.7,80.3-17.3c3.8-1.3,7.6-2.7,11.3-4.2c20.6-8.1,39.9-16.6,58.8-25.1c17.9-8.1,34.9-14.5,52.5-21.7
      c37.3-15.1,74.6-28.2,108.2-38.5c11.4-3.5,22.6-7.1,33.8-10.8l0.1-0.7c-11.5,3.7-22.9,7.5-34.6,11c-33.7,10.3-71.1,23.4-108.4,38.6
      c-17.6,7.1-34.7,13.6-52.6,21.7c-18.8,8.5-38.1,17-58.7,25.1c-3.7,1.5-7.5,2.8-11.2,4.1c-24.8,8.6-51.7,14.5-79.7,17.2
      c-13.5,1.3-27.2,1.9-41.1,1.6c-48-0.8-97.7-9.3-134.3-21.2c-24.9-8.1-47.6-17.3-68.6-26.9c-9.9-4.5-19.8-9.1-30-13.6
      c-20.7-9.2-55.7-19.4-82.8-23.1c-7.5-1-15.2-1.4-23-1.3l0,0.6c7.7-0.1,15.2,0.2,22.6,1.2c26.8,3.7,61.8,13.9,82.3,23
      c10.2,4.5,20.1,9.1,29.9,13.6c21.1,9.6,43.9,18.9,68.8,27C229.1,182.9,279,191.4,327.3,192.2z M322,193c14.4,0.3,28.5-0.3,42.4-1.6
      c28.1-2.7,54.9-8.6,79.7-17.1c3.8-1.3,7.6-2.7,11.3-4.2c20.6-8.1,40-16.6,58.8-25.2c17.8-8.1,34.8-14.6,52.4-21.9
      c37.1-15.3,74.3-28.6,107.9-39.1c13.3-4.2,26.3-8.5,39.3-12.8l0.1-0.7c-13.4,4.4-26.6,8.9-40.1,13.1c-33.6,10.5-70.8,23.9-108,39.2
      c-17.5,7.2-34.6,13.8-52.4,21.9c-18.8,8.5-38.1,17.1-58.7,25.2c-3.7,1.5-7.5,2.8-11.3,4.1c-24.7,8.5-51.3,14.3-79.1,17
      c-13.8,1.3-27.8,1.9-42.1,1.6c-48.1-0.9-97.8-9.5-134.4-21.6c-24.8-8.2-47.5-17.4-68.5-27.1c-9.8-4.5-19.8-9.2-29.9-13.7
      c-20.7-9.2-55.8-19.5-83-23.1c-5.8-0.8-11.7-1.2-17.8-1.2l0,0.6c5.9,0.1,11.7,0.4,17.4,1.2c26.9,3.6,62,13.9,82.5,23
      c10.1,4.5,20,9.1,29.9,13.7c21,9.7,43.8,19,68.7,27.2C223.7,183.4,273.6,192.1,322,193z M316.7,193.6c14.7,0.3,29.2-0.3,43.3-1.6
      c27.9-2.7,54.5-8.5,79.1-17c3.9-1.3,7.6-2.7,11.4-4.2c20.7-8.1,40-16.7,58.8-25.3c17.8-8.1,34.8-14.8,52.2-22
      c37-15.4,74.1-29,107.5-39.7c15.2-4.9,29.9-10,44.9-15L714,68c-15.3,5.1-30.3,10.3-45.7,15.3C634.8,94,597.8,107.6,560.7,123
      c-17.4,7.3-34.4,13.9-52.3,22.1c-18.8,8.6-38.1,17.2-58.7,25.3c-3.7,1.5-7.5,2.8-11.3,4.2c-24.5,8.4-51,14.2-78.6,16.9
      c-14.1,1.4-28.4,1.9-43,1.6c-48.2-1-97.9-9.7-134.4-21.9c-24.7-8.3-47.4-17.6-68.3-27.4c-9.8-4.6-19.7-9.2-29.9-13.8
      c-20.6-9.3-55.8-19.6-83.1-23.2c-4.1-0.5-8.3-0.9-12.5-1l0,0.6c4.1,0.2,8.1,0.5,12.1,1c27,3.6,62.2,13.8,82.6,23
      c10.1,4.6,20,9.2,29.8,13.8c21,9.8,43.7,19.1,68.5,27.4C218.3,183.8,268.2,192.5,316.7,193.6z M311.4,194c15,0.4,29.8-0.2,44.2-1.6
      c27.7-2.7,54.2-8.4,78.6-16.8c3.8-1.3,7.6-2.7,11.4-4.2c20.7-8.1,40-16.8,58.8-25.4c17.8-8.2,34.7-14.9,52.1-22.2
      c36.9-15.6,73.8-29.4,107.1-40.3c17-5.6,33.5-11.5,50.4-17.2l0.1-0.7c-17.2,5.8-34,11.8-51.3,17.5c-33.4,11-70.4,24.8-107.3,40.4
      c-17.4,7.3-34.3,14.1-52.2,22.2c-18.8,8.6-38.1,17.2-58.7,25.4c-3.7,1.5-7.5,2.8-11.3,4.2c-24.3,8.4-50.6,14.1-78.1,16.7
      c-14.4,1.4-29,1.9-43.9,1.6c-48.3-1.1-98.1-10-134.4-22.3c-24.7-8.3-47.2-17.8-68.2-27.6c-9.8-4.6-19.7-9.3-29.8-13.8
      c-20.6-9.3-55.9-19.6-83.3-23.2c-2.4-0.3-4.8-0.5-7.2-0.7l0,0.6c2.3,0.2,4.6,0.4,6.9,0.7c27.1,3.5,62.4,13.8,82.8,23
      c10.1,4.6,20,9.2,29.8,13.8c21,9.8,43.6,19.3,68.3,27.6C212.9,183.9,262.8,192.8,311.4,194z M306.2,194.2
      c15.3,0.4,30.4-0.2,45.2-1.6c27.4-2.7,53.8-8.3,78-16.7c3.8-1.3,7.6-2.7,11.4-4.2c20.7-8.2,40-16.8,58.8-25.5
      c17.8-8.2,34.7-15,52-22.4c36.8-15.7,73.5-29.7,106.8-40.9c18.9-6.3,37.2-13.1,56-19.5l0.1-0.7c-19.2,6.5-37.7,13.3-56.8,19.7
      c-33.4,11.2-70.1,25.2-106.9,41c-17.3,7.4-34.2,14.2-52,22.4c-18.8,8.7-38,17.3-58.7,25.4c-3.7,1.5-7.5,2.9-11.3,4.2
      c-24.1,8.3-50.3,13.9-77.5,16.6c-14.7,1.4-29.6,2-44.8,1.6c-48.4-1.2-98.2-10.2-134.5-22.6c-24.6-8.4-47.1-17.9-68-27.8
      c-9.8-4.6-19.7-9.3-29.8-13.9c-20.5-9.3-55.9-19.7-83.5-23.2c-0.7-0.1-1.3-0.1-2-0.2l0,0.6c0.5,0.1,1.1,0.1,1.6,0.2
      c27.2,3.4,62.6,13.8,82.9,23.1c10.1,4.6,19.9,9.3,29.7,13.9c20.9,9.9,43.5,19.4,68.2,27.9C207.4,183.9,257.4,193,306.2,194.2z
       M300.9,194.2c15.6,0.4,31-0.1,46.1-1.6c27.2-2.6,53.4-8.3,77.5-16.5c3.9-1.3,7.7-2.7,11.4-4.2c20.7-8.2,40.1-16.8,58.8-25.6
      c17.7-8.2,34.6-15.1,51.9-22.6c36.6-15.9,73.2-30.1,106.4-41.5c20.7-7.1,40.7-14.7,61.6-21.8l0.1-0.8c-21.2,7.1-41.5,14.8-62.5,22
      c-33.3,11.4-69.9,25.7-106.6,41.6c-17.2,7.5-34.1,14.3-51.9,22.6c-18.7,8.7-38,17.4-58.7,25.5c-3.7,1.5-7.5,2.9-11.3,4.2
      c-23.9,8.2-49.9,13.8-76.9,16.4c-15,1.4-30.3,2-45.7,1.6c-48.6-1.3-98.4-10.5-134.5-23c-24.5-8.5-47-18.1-67.9-28
      c-9.8-4.6-19.6-9.4-29.7-14c-19.7-9-53.2-19-80.3-22.8l0,0.6c26.7,3.8,60,13.7,79.4,22.6c10.1,4.6,19.9,9.3,29.7,14
      c20.9,9.9,43.4,19.5,68,28.1C202,183.7,252,192.9,300.9,194.2z M295.6,194.1c15.9,0.5,31.6-0.1,47-1.6c27-2.6,53-8.2,77-16.4
      c3.9-1.3,7.7-2.7,11.4-4.2c20.8-8.2,40.1-16.9,58.8-25.7c17.7-8.3,34.5-15.2,51.7-22.8c36.5-16,72.9-30.5,106.1-42.1
      c22.6-7.9,44.3-16.4,67.2-24.1l0.1-0.7c-23.3,7.8-45.3,16.4-68.1,24.4c-33.2,11.7-69.6,26.1-106.2,42.2
      c-17.2,7.5-34,14.5-51.7,22.8c-18.7,8.8-38,17.5-58.8,25.6c-3.7,1.5-7.5,2.9-11.4,4.2c-23.8,8.2-49.6,13.7-76.4,16.3
      c-15.2,1.5-30.9,2-46.6,1.5c-48.7-1.4-98.5-10.7-134.5-23.4c-24.5-8.6-46.9-18.2-67.7-28.2c-9.8-4.7-19.6-9.4-29.7-14.1
      c-18.3-8.5-49-17.8-75.2-22l0,0.6c25.8,4.2,56.2,13.5,74.3,21.8c10.1,4.6,19.9,9.4,29.7,14.1c20.9,10,43.3,19.7,67.9,28.3
      C196.6,183.3,246.6,192.6,295.6,194.1z M290.3,193.7c16.2,0.5,32.2,0,47.9-1.5c26.9-2.6,52.7-8.1,76.4-16.3
      c3.9-1.3,7.7-2.7,11.5-4.2c20.8-8.2,40.1-17,58.8-25.8c17.7-8.3,34.4-15.3,51.6-22.9c36.4-16.2,72.6-30.9,105.7-42.8
      c24.4-8.8,47.8-18.1,72.9-26.5l0.1-0.7c-25.4,8.5-49.1,17.9-73.7,26.8c-33.2,11.9-69.4,26.6-105.8,42.8
      c-17.1,7.6-33.9,14.6-51.6,23c-18.7,8.8-38,17.5-58.8,25.7c-3.7,1.5-7.5,2.9-11.4,4.2c-23.6,8.1-49.2,13.6-75.9,16.1
      c-15.5,1.5-31.4,2-47.5,1.5c-48.8-1.5-98.6-10.9-134.5-23.7c-24.4-8.7-46.7-18.4-67.6-28.4c-9.8-4.7-19.5-9.5-29.6-14.1
      c-17.1-7.9-45.1-16.6-70.1-21.2l0,0.6c24.7,4.5,52.3,13.1,69.1,21c10,4.7,19.8,9.4,29.6,14.1c20.8,10.1,43.2,19.8,67.7,28.5
      C191.1,182.7,241.1,192.2,290.3,193.7z M284.9,193.1c16.5,0.6,32.8,0,48.8-1.5c26.7-2.6,52.3-8,75.9-16.1c3.9-1.3,7.7-2.7,11.5-4.2
      c20.8-8.2,40.2-17,58.9-25.8c17.6-8.3,34.4-15.4,51.5-23.1c36.3-16.3,72.3-31.2,105.4-43.4C663,69.3,688.1,59,715.3,50l0.1-0.7
      c-27.6,9.1-53,19.5-79.4,29.2c-33.1,12.1-69.2,27-105.5,43.4c-17.1,7.7-33.8,14.8-51.5,23.2c-18.7,8.8-38,17.6-58.8,25.8
      c-3.7,1.5-7.6,2.9-11.4,4.2c-23.4,8-48.9,13.4-75.3,16c-15.8,1.5-32.1,2-48.4,1.5c-48.9-1.6-98.8-11.2-134.6-24.1
      c-24.4-8.8-46.6-18.6-67.4-28.7c-9.7-4.7-19.5-9.5-29.6-14.2c-15.9-7.4-41.2-15.5-64.9-20.2l0,0.6c23.3,4.7,48.4,12.7,64,20
      c10,4.7,19.8,9.5,29.6,14.2c20.8,10.1,43.1,19.9,67.5,28.7C185.7,181.8,235.7,191.4,284.9,193.1z M279.6,192.2
      c16.8,0.6,33.4,0.1,49.7-1.5c26.5-2.6,51.9-7.9,75.4-16c3.9-1.3,7.7-2.8,11.5-4.2c20.9-8.3,40.2-17.1,58.9-25.9
      c17.6-8.4,34.3-15.6,51.3-23.3c36.1-16.5,72-31.6,105-44c28-10.5,54.8-21.7,84.3-31.3l0.1-0.7c-29.9,9.7-56.9,21-85.2,31.6
      c-33,12.4-68.9,27.5-105.1,44c-17,7.8-33.7,14.9-51.4,23.3c-18.7,8.9-38,17.7-58.8,25.9c-3.7,1.5-7.6,2.9-11.4,4.2
      c-23.3,8-48.5,13.3-74.8,15.8c-16.1,1.6-32.7,2.1-49.3,1.5c-49-1.7-98.9-11.4-134.6-24.4c-24.3-8.9-46.5-18.7-67.2-28.9
      c-9.7-4.8-19.5-9.6-29.5-14.3c-14.7-6.9-37.6-14.3-59.7-19.1l0,0.7c21.8,4.8,44.4,12.1,58.8,18.9c10,4.7,19.8,9.5,29.5,14.3
      c20.8,10.2,43,20.1,67.4,28.9C180.2,180.8,230.2,190.5,279.6,192.2z M274.2,191.2c17.1,0.6,34.1,0.1,50.6-1.5
      c26.3-2.5,51.5-7.9,74.8-15.8c3.9-1.3,7.8-2.8,11.5-4.3c20.9-8.3,40.2-17.1,58.9-26c17.6-8.4,34.3-15.7,51.2-23.5
      c36-16.6,71.7-32,104.6-44.6c29.7-11.4,58.2-23.5,90-33.7l0.1-0.7c-32.2,10.3-60.9,22.6-90.9,34c-33,12.6-68.7,28-104.7,44.6
      c-16.9,7.8-33.6,15.1-51.2,23.5c-18.6,8.9-37.9,17.7-58.8,26c-3.8,1.5-7.6,2.9-11.4,4.2c-23.1,7.9-48.2,13.2-74.3,15.7
      c-16.4,1.6-33.2,2.1-50.2,1.4c-49.2-1.8-99.1-11.7-134.6-24.8c-24.2-8.9-46.4-18.9-67.1-29.1c-9.7-4.8-19.5-9.6-29.5-14.4
      c-13.4-6.4-34-13.2-54.5-18l0,0.7c20.2,4.8,40.4,11.5,53.6,17.8c10,4.7,19.7,9.6,29.5,14.4c20.7,10.2,42.9,20.2,67.2,29.2
      C174.7,179.4,224.8,189.3,274.2,191.2z M269.5,190.2c17.4,0.7,34.6,0.2,51.5-1.4c26.1-2.5,51.2-7.8,74.3-15.7
      c3.9-1.3,7.7-2.8,11.5-4.3c20.9-8.3,40.2-17.2,58.9-26.1c17.6-8.5,34.2-15.8,51.1-23.7c35.9-16.8,71.4-32.4,104.3-45.2
      c31.3-12.2,61.2-25.2,95.1-36l0.1-0.7c-34.4,10.8-64.5,24-96.1,36.3c-32.9,12.8-68.5,28.4-104.4,45.2
      c-16.8,7.9-33.5,15.2-51.1,23.7c-18.6,9-37.9,17.8-58.8,26.1c-3.8,1.5-7.6,2.9-11.5,4.2c-23,7.9-47.9,13.1-73.7,15.6
      c-16.7,1.6-33.8,2.1-51.1,1.4c-49.3-1.9-99.2-11.9-134.7-25.2c-24.2-9-46.2-19.1-66.9-29.3c-9.7-4.8-19.4-9.7-29.4-14.4
      c-12.4-5.9-30.9-12.2-49.9-17l0,0.7c18.6,4.7,36.8,10.9,48.9,16.7c10,4.8,19.7,9.6,29.4,14.4c20.7,10.3,42.8,20.3,67.1,29.4
      C169.8,178.2,219.9,188.2,269.5,190.2z M266,189.5c17.7,0.7,35.3,0.3,52.4-1.4c25.9-2.5,50.8-7.7,73.8-15.6
      c3.9-1.3,7.8-2.8,11.6-4.3c21-8.3,40.3-17.2,58.9-26.2c17.5-8.5,34.1-15.9,50.9-23.9c35.8-17,71.1-32.7,103.9-45.8
      c32.4-12.9,63.4-26.6,98.9-37.8l0.1-0.7c-36,11.3-67.2,25.1-99.9,38.1c-32.9,13.1-68.2,28.9-104,45.8c-16.8,8-33.4,15.4-51,23.9
      c-18.6,9-37.9,17.9-58.8,26.2c-3.8,1.5-7.6,2.9-11.5,4.2c-22.8,7.8-47.5,13-73.2,15.4c-17,1.6-34.4,2.1-52,1.4
      c-49.4-2-99.3-12.1-134.7-25.5c-24.1-9.1-46.1-19.2-66.8-29.5c-9.7-4.8-19.4-9.7-29.4-14.5c-11.6-5.6-28.7-11.5-46.6-16.2l0,0.7
      c17.5,4.6,34.2,10.4,45.6,15.9c10,4.8,19.7,9.7,29.4,14.5c20.7,10.3,42.7,20.5,66.9,29.6C166.2,177.3,216.3,187.4,266,189.5z
       M263.6,189.4c18,0.8,35.8,0.3,53.3-1.4c25.7-2.5,50.5-7.6,73.3-15.4c3.9-1.3,7.8-2.8,11.6-4.3c21-8.3,40.3-17.3,58.9-26.3
      c17.5-8.5,34-16,50.8-24c35.6-17.1,70.8-33.1,103.6-46.4c33.2-13.4,64.9-27.7,101.7-39.3l0.1-0.7c-37.2,11.6-69.2,26.1-102.6,39.6
      c-32.8,13.3-68,29.3-103.7,46.4c-16.7,8-33.3,15.5-50.8,24.1c-18.6,9-37.9,18-58.8,26.3c-3.8,1.5-7.6,2.9-11.5,4.2
      c-22.6,7.7-47.2,12.9-72.7,15.3c-17.3,1.7-35,2.1-52.9,1.4c-49.5-2.1-99.5-12.4-134.7-25.9c-24.1-9.2-46-19.4-66.6-29.8
      c-9.7-4.9-19.4-9.8-29.3-14.6C22,113.2,5.8,107.5-11.2,103l0,0.7C5.4,108.1,21.2,113.8,32,119c9.9,4.8,19.6,9.7,29.3,14.6
      C82,144,104,154.1,128.1,163.4C163.6,177,213.7,187.2,263.6,189.4z'
        />

        <linearGradient
          id='SVGID_00000044883822509356175140000008027168013162390181_'
          gradientUnits='userSpaceOnUse'
          x1='-159.7089'
          y1='-91.2872'
          x2='823.8315'
          y2='-91.2872'
          gradientTransform='matrix(0.9984 5.687980e-02 -5.687980e-02 0.9984 16.2004 291.9021)'>
          <stop offset='3.489820e-02' style={{ stopColor: '#FFFFFF' }} />
          <stop offset='7.217500e-02' style={{ stopColor: '#F0F6FD' }} />
          <stop offset='0.235' style={{ stopColor: '#B4D4F6' }} />
          <stop offset='0.3736' style={{ stopColor: '#89BAF1' }} />
          <stop offset='0.4811' style={{ stopColor: '#6EABEE' }} />
          <stop offset='0.5444' style={{ stopColor: '#64A5ED' }} />
          <stop offset='0.5883' style={{ stopColor: '#6DAAEE' }} />
          <stop offset='0.6605' style={{ stopColor: '#86B9F1' }} />
          <stop offset='0.7518' style={{ stopColor: '#AED0F6' }} />
          <stop offset='0.8578' style={{ stopColor: '#E5F0FC' }} />
          <stop offset='0.903' style={{ stopColor: '#FFFFFF' }} />
        </linearGradient>

        <path
          style={{
            clipPath: 'url(#SVGID_00000101814141948693354700000002932564881757792184_)',
            fill: 'url(#SVGID_00000044883822509356175140000008027168013162390181_)'
          }}
          d='
      M316.4,253.6c-53.3,11.9-128.6,16.1-191.9,10.7c-11.4-1-22.5-2.2-32.9-3.9C53,254.5,23.8,240.5-7.2,230c-3.9-1.3-7.9-2.6-11.8-3.9
      l0-0.8c4.3,1.4,8.6,2.8,12.8,4.3C24.8,240.1,54,254,92.1,259.9c10.4,1.6,21.3,2.9,32.7,3.8c62.9,5.3,138.1,1.2,191-10.7
      c37.4-8.4,78.8-18.9,111.1-30.2c29.5-10.3,65.4-14.8,103.3-11.6c3,0.3,6,0.6,9.1,0.9c59.1,6.9,108,22.6,150.8,40.3
      c18.2,7.5,36.1,13.8,55.1,17.7l0,0.7c-19.5-4-37.6-10.3-56.1-17.9c-42.8-17.6-91.4-33.3-150.2-40.2c-3-0.4-6-0.6-9-0.9
      c-37.4-3.2-72.8,1.2-102.1,11.5C395.4,234.6,353.9,245.2,316.4,253.6z M320.7,253.6c11.6-2.6,23.5-5.5,35.5-8.5
      c26.6-6.7,53.5-14.2,75.9-21.9c29-10.1,64.1-14.4,101.2-11.3c3.1,0.3,6.3,0.6,9.5,1c39.6,4.7,74.5,13.4,106.1,23.9
      c15.2,5.1,29.6,10.6,43.5,16.3c17.4,7.2,34.5,13.2,52.7,17.3l0-0.7c-17.8-4-34.5-9.9-51.7-17c-13.8-5.7-28.3-11.3-43.6-16.4
      c-31.7-10.6-66.8-19.3-106.6-24c-3.2-0.4-6.4-0.7-9.6-1c-37.5-3.2-73.1,1.2-102.4,11.4c-22.4,7.7-49.1,15.2-75.7,21.9
      c-11.9,3-23.9,5.8-35.5,8.4c-24.5,5.6-53.8,9.5-84.5,11.6c-35.9,2.4-73.7,2.4-107.8-0.5c-10.8-0.9-21.3-2.1-31.2-3.6
      c-38.2-5.8-67.6-19.6-98.8-30.2c-3.5-1.2-6.9-2.3-10.4-3.5c-2.1-0.7-4.3-1.4-6.4-2.1l0,0.8c1.8,0.6,3.7,1.2,5.5,1.8
      c3.5,1.2,7,2.3,10.4,3.5c31.2,10.6,60.6,24.4,99.3,30.3c10,1.5,20.5,2.7,31.4,3.6c34.3,2.9,72.3,3,108.3,0.5
      C266.6,263.1,296,259.1,320.7,253.6z M323.6,253.4c11.6-2.6,23.5-5.5,35.4-8.5c26.6-6.7,53.6-14.3,76.1-22
      c28.9-9.9,63.7-14.2,100.4-11.1c3.3,0.3,6.7,0.6,10,1c39.4,4.7,74.2,13.5,105.6,24c15.1,5.1,29.5,10.6,43.3,16.3
      c16.7,6.9,33.2,12.8,50.7,16.9l0-0.7c-17-4-33.2-9.8-49.6-16.6c-13.8-5.7-28.2-11.3-43.4-16.4c-31.5-10.6-66.5-19.3-106.1-24.1
      c-3.4-0.4-6.8-0.8-10.1-1c-37.2-3.2-72.4,1.2-101.6,11.2c-22.4,7.7-49.4,15.2-75.9,21.9c-11.9,3-23.9,5.9-35.4,8.5
      c-24.5,5.6-53.7,9.6-84.3,11.8c-36.3,2.6-74.7,2.6-109.3-0.4c-10.3-0.9-20.3-2-29.8-3.4c-38.3-5.7-67.8-19.4-99.2-30
      c-3.5-1.2-6.9-2.3-10.4-3.5c-3-1-6-2-9-2.9l0,0.8c2.7,0.9,5.4,1.8,8.1,2.6c3.5,1.2,7,2.3,10.4,3.5c31.3,10.6,60.9,24.4,99.6,30.1
      c9.6,1.4,19.6,2.6,30,3.4c34.8,3,73.3,3,109.8,0.4C269.6,263.1,299,259.1,323.6,253.4z M325.3,253.2c11.6-2.7,23.5-5.6,35.4-8.6
      c26.5-6.7,53.7-14.3,76.2-22c28.8-9.8,63.2-14,99.6-10.9c3.5,0.3,7,0.7,10.5,1.1c39.2,4.8,73.9,13.5,105.1,24
      c15.1,5.1,29.4,10.6,43.1,16.3c16.5,6.9,32.7,12.7,49.9,16.8l0-0.7c-16.7-4-32.6-9.7-48.8-16.5c-13.7-5.7-28.1-11.3-43.2-16.4
      c-31.3-10.5-66.1-19.3-105.6-24.2c-3.6-0.4-7.1-0.8-10.6-1.1c-36.8-3.1-71.7,1.1-100.7,11c-22.5,7.7-49.6,15.2-76.1,22
      c-11.9,3-23.8,5.9-35.3,8.6c-24.5,5.7-53.6,9.7-84.1,12c-36.8,2.7-75.7,2.8-110.8-0.2c-9.8-0.8-19.3-1.9-28.3-3.2
      c-38.4-5.6-68.1-19.2-99.6-29.8c-3.4-1.2-6.9-2.3-10.5-3.5c-3.4-1.1-6.9-2.3-10.4-3.4l0,0.8c3.2,1,6.4,2.1,9.5,3.1
      c3.5,1.2,7,2.3,10.4,3.5c31.4,10.6,61.1,24.3,100,29.9c9.1,1.3,18.7,2.4,28.5,3.2c35.2,3,74.4,2.9,111.3,0.2
      C271.4,263,300.7,258.9,325.3,253.2z M325.9,252.9c11.5-2.7,23.4-5.6,35.3-8.7c26.5-6.8,53.8-14.4,76.4-22
      c28.6-9.6,62.7-13.7,98.7-10.7c3.7,0.3,7.3,0.7,11,1.2c39.1,4.9,73.6,13.7,104.7,24.1c15,5.1,29.3,10.6,42.9,16.3
      c16.6,6.9,32.9,12.8,50.2,16.9l0-0.7c-16.9-4-32.9-9.8-49.2-16.6c-13.7-5.7-27.9-11.2-43-16.4c-31.2-10.5-65.8-19.4-105.2-24.2
      c-3.7-0.5-7.4-0.8-11.1-1.2c-36.5-3.1-71,1-99.9,10.8c-22.5,7.6-49.8,15.2-76.2,22c-11.9,3.1-23.8,6-35.3,8.7
      c-24.4,5.7-53.5,9.9-84,12.2c-37.2,2.8-76.7,3-112.3,0c-9.3-0.8-18.3-1.8-26.9-3c-38.5-5.5-68.4-19-100-29.7
      c-3.5-1.2-7-2.3-10.5-3.5c-3.5-1.2-7.1-2.3-10.7-3.5l0,0.8c3.3,1.1,6.5,2.1,9.8,3.2c3.5,1.2,7,2.3,10.5,3.5
      c31.5,10.6,61.4,24.2,100.4,29.8c8.7,1.2,17.7,2.2,27.1,3c35.7,3,75.4,2.9,112.8,0C272.1,262.8,301.3,258.6,325.9,252.9z
       M325.5,252.5c11.5-2.7,23.4-5.7,35.3-8.7c26.4-6.8,53.9-14.5,76.5-22c28.4-9.5,62.2-13.5,97.9-10.5c3.8,0.3,7.7,0.7,11.5,1.2
      c38.9,4.9,73.4,13.7,104.2,24.2c15,5.1,29.1,10.6,42.7,16.3c16.9,7.1,33.6,13.1,51.4,17.2l0-0.7c-17.3-4-33.7-9.9-50.4-16.9
      c-13.6-5.7-27.8-11.3-42.8-16.4c-30.9-10.5-65.5-19.4-104.7-24.3c-3.9-0.5-7.8-0.9-11.7-1.2c-36.1-3.1-70.4,1-99,10.6
      c-22.6,7.6-50,15.2-76.4,22c-11.9,3.1-23.7,6-35.3,8.7c-24.4,5.8-53.4,10-83.8,12.4c-37.6,3-77.8,3.2-113.7,0.1
      c-8.8-0.7-17.3-1.7-25.5-2.8c-38.6-5.4-68.7-18.8-100.4-29.5c-3.5-1.2-7-2.4-10.5-3.5c-3.3-1.1-6.7-2.2-10-3.3l0,0.8
      c3,1,6.1,2,9.1,3c3.5,1.2,7,2.4,10.5,3.5c31.6,10.7,61.7,24.1,100.8,29.6c8.3,1.2,16.9,2.1,25.7,2.8c36.2,3.1,76.5,2.9,114.2-0.1
      C271.8,262.5,301,258.3,325.5,252.5z M324.4,252c11.5-2.8,23.4-5.7,35.3-8.8c26.3-6.8,54-14.5,76.7-22.1
      c28.2-9.3,61.7-13.3,97-10.3c4,0.3,8,0.8,12,1.3c38.8,5,73.1,13.8,103.7,24.3c14.9,5.1,29,10.6,42.5,16.3
      c17.5,7.4,34.8,13.6,53.4,17.6l0-0.7c-18.1-4-35.1-10.1-52.4-17.4c-13.5-5.7-27.6-11.3-42.6-16.4c-30.7-10.5-65.2-19.4-104.2-24.4
      c-4.1-0.5-8.1-1-12.2-1.3c-35.8-3-69.7,0.9-98.2,10.4c-22.7,7.5-50.3,15.2-76.6,22c-11.8,3.1-23.7,6.1-35.2,8.8
      c-24.4,5.8-53.3,10.1-83.7,12.7c-38,3.1-78.7,3.4-115.1,0.3c-8.3-0.7-16.4-1.6-24.1-2.6c-38.7-5.3-69-18.6-100.8-29.3
      c-3.5-1.2-7-2.4-10.5-3.5c-2.8-1-5.7-1.9-8.6-2.8l0,0.8c2.6,0.8,5.1,1.7,7.7,2.5c3.5,1.2,7,2.4,10.5,3.5
      c31.7,10.7,62,24.1,101.2,29.4c7.8,1.1,15.9,2,24.3,2.7c36.6,3.1,77.5,2.8,115.7-0.3C270.8,262.2,299.9,257.9,324.4,252z
       M322.7,251.4c11.5-2.8,23.3-5.8,35.2-8.9c26.3-6.9,54-14.6,76.8-22.1c28.1-9.2,61.2-13,96.2-10.1c4.2,0.4,8.4,0.8,12.6,1.3
      c38.6,5,72.8,13.9,103.2,24.3c14.9,5.1,28.9,10.6,42.3,16.3c18.4,7.7,36.4,14.2,56,18.2l0-0.7c-19.1-4-36.9-10.3-55-18
      c-13.5-5.7-27.5-11.2-42.4-16.4c-30.5-10.5-64.9-19.4-103.7-24.5c-4.3-0.5-8.5-1-12.7-1.4c-35.4-3-69,0.9-97.3,10.2
      c-22.7,7.5-50.5,15.2-76.7,22.1c-11.8,3.1-23.7,6.1-35.2,8.9c-24.4,5.9-53.2,10.3-83.5,12.9c-38.5,3.3-79.7,3.6-116.6,0.5
      c-7.8-0.7-15.4-1.5-22.7-2.5c-38.8-5.2-69.3-18.4-101.1-29.2c-3.5-1.2-7-2.4-10.5-3.5c-2.2-0.7-4.4-1.4-6.6-2.2l0,0.8
      c1.9,0.6,3.8,1.2,5.7,1.9c3.5,1.2,7,2.4,10.5,3.5c31.8,10.7,62.3,24,101.6,29.3c7.4,1,15,1.8,22.9,2.5c37.1,3.1,78.5,2.8,117.1-0.5
      C269.2,261.8,298.2,257.4,322.7,251.4z M320.6,250.8c11.5-2.8,23.3-5.8,35.1-9c26.2-6.9,54.1-14.7,77-22.1
      c27.9-9.1,60.7-12.8,95.3-9.9c4.3,0.4,8.7,0.8,13.1,1.4c38.5,5.1,72.5,14,102.8,24.4c14.8,5.1,28.8,10.7,42.2,16.3
      c19.3,8.2,38.3,14.9,59.1,18.9l0-0.6c-20.3-3.9-39-10.6-58-18.6c-13.4-5.7-27.4-11.2-42.2-16.4c-30.4-10.5-64.6-19.4-103.3-24.5
      c-4.4-0.6-8.8-1.1-13.2-1.4c-35.1-3-68.4,0.8-96.5,10c-22.8,7.4-50.7,15.2-76.9,22.1c-11.8,3.1-23.6,6.1-35.1,9
      c-24.3,6-53.1,10.4-83.3,13.1c-38.8,3.4-80.7,3.8-118,0.7c-7.3-0.6-14.5-1.4-21.4-2.3c-38.9-5.1-69.6-18.2-101.5-29
      c-3.5-1.2-7-2.4-10.6-3.6c-1.4-0.5-2.7-0.9-4.1-1.3l0,0.8c1.1,0.4,2.1,0.7,3.2,1.1c3.5,1.2,7.1,2.4,10.5,3.6
      c31.9,10.8,62.6,23.9,102,29.1c7,0.9,14.2,1.7,21.5,2.3c37.5,3.2,79.5,2.7,118.5-0.7C267.2,261.3,296.1,256.8,320.6,250.8z
       M318.2,250.2c11.5-2.8,23.3-5.9,35.1-9.1c26.1-7,54.2-14.8,77.2-22.1c27.7-8.9,60.2-12.6,94.5-9.7c4.5,0.4,9,0.9,13.6,1.5
      c38.3,5.1,72.2,14.1,102.3,24.5c14.8,5.1,28.6,10.7,42,16.3c20.3,8.6,40.2,15.6,62.4,19.5l0-0.6c-21.6-3.8-41.3-10.7-61.3-19.3
      c-13.4-5.7-27.3-11.2-42.1-16.4c-30.2-10.5-64.2-19.4-102.8-24.6c-4.6-0.6-9.2-1.1-13.8-1.5c-34.7-2.9-67.7,0.8-95.6,9.8
      c-22.9,7.4-51,15.1-77.1,22.1c-11.8,3.2-23.6,6.2-35.1,9c-24.3,6-53,10.5-83.2,13.3c-39.2,3.6-81.7,4.1-119.4,0.9
      c-6.8-0.6-13.5-1.3-20-2.1c-39-5-69.9-18-101.9-28.8c-3.5-1.2-7.1-2.4-10.6-3.6c-0.4-0.1-0.9-0.3-1.3-0.4l0,0.8l0.4,0.1
      c3.5,1.2,7,2.4,10.6,3.6c32,10.8,62.9,23.8,102.4,28.9c6.5,0.8,13.3,1.5,20.2,2.1c37.9,3.2,80.5,2.7,119.9-0.9
      C264.9,260.8,293.7,256.3,318.2,250.2z M315.6,249.5c11.5-2.9,23.2-5.9,35.1-9.1c26-7,54.3-14.8,77.3-22.2
      c27.5-8.8,59.7-12.4,93.7-9.5c4.7,0.4,9.4,0.9,14.1,1.6c38.2,5.2,71.9,14.2,101.8,24.6c14.7,5.1,28.5,10.7,41.8,16.3
      c21.3,9.1,42.3,16.4,65.8,20.1l0-0.6c-23-3.7-43.7-10.9-64.8-19.9c-13.3-5.7-27.1-11.2-41.9-16.4c-30-10.4-63.9-19.5-102.3-24.7
      c-4.8-0.6-9.6-1.2-14.3-1.6c-34.4-2.9-67,0.7-94.8,9.6c-22.9,7.3-51.2,15.1-77.2,22.1c-11.8,3.2-23.6,6.3-35,9.1
      c-24.3,6.1-52.9,10.7-83,13.5c-39.6,3.7-82.6,4.3-120.7,1.1c-6.4-0.5-12.6-1.2-18.7-1.9C53.4,256.8,22.3,243.9-9.8,233
      c-3-1-6-2-9-3l0,0.8c2.7,0.9,5.4,1.8,8.1,2.7c32.1,10.8,63.2,23.8,102.8,28.8c6.1,0.8,12.4,1.4,18.8,2c38.4,3.2,81.5,2.6,121.3-1.1
      C262.4,260.3,291.2,255.7,315.6,249.5z M313.1,248.9c11.4-2.9,23.2-6,35-9.2c26-7.1,54.4-14.9,77.5-22.2
      c27.3-8.6,59.2-12.2,92.8-9.3c4.9,0.4,9.7,1,14.6,1.6c38,5.2,71.6,14.2,101.3,24.6c14.6,5.1,28.4,10.7,41.6,16.3
      c22.3,9.6,44.2,17.2,69.2,20.7l0-0.6c-24.4-3.5-46.1-11-68.2-20.5c-13.2-5.7-27-11.2-41.7-16.4c-29.8-10.4-63.6-19.5-101.8-24.7
      c-5-0.7-9.9-1.2-14.8-1.7c-34.1-2.9-66.3,0.7-93.9,9.4c-23,7.3-51.4,15.1-77.4,22.2c-11.8,3.2-23.5,6.3-35,9.2
      c-24.2,6.1-52.8,10.8-82.9,13.8c-39.9,3.9-83.5,4.6-122,1.3c-5.9-0.5-11.8-1.1-17.4-1.8c-39.2-4.8-70.4-17.6-102.7-28.5
      c-2.1-0.7-4.1-1.4-6.2-2.1l0,0.8c1.8,0.6,3.5,1.2,5.3,1.8c32.2,10.8,63.5,23.7,103.2,28.6c5.7,0.7,11.6,1.3,17.5,1.8
      c38.8,3.3,82.5,2.6,122.6-1.3C260.1,259.7,288.8,255,313.1,248.9z M310.8,248.2c11.4-2.9,23.1-6,34.9-9.3
      c25.9-7.1,54.5-15,77.6-22.2c27.1-8.5,58.7-11.9,91.9-9.1c5,0.4,10.1,1,15.2,1.7c37.9,5.3,71.4,14.3,100.9,24.7
      c14.6,5.1,28.2,10.7,41.4,16.3c23.3,10,46,17.9,72.4,21.2l0-0.6c-25.8-3.3-48.3-11-71.4-21c-13.2-5.7-26.8-11.2-41.5-16.4
      c-29.6-10.4-63.2-19.5-101.3-24.8c-5.2-0.7-10.3-1.3-15.3-1.7c-33.7-2.9-65.7,0.6-93.1,9.2c-23.1,7.2-51.6,15.1-77.5,22.2
      c-11.8,3.2-23.5,6.3-34.9,9.3c-24.2,6.2-52.7,11-82.7,14c-40.3,4-84.4,4.8-123.4,1.5c-5.5-0.5-10.9-1-16.1-1.6
      c-39.3-4.7-70.7-17.4-103.1-28.3c-1.2-0.4-2.4-0.8-3.6-1.2l0,0.8c0.9,0.3,1.8,0.6,2.7,0.9c32.3,10.9,63.8,23.6,103.6,28.4
      c5.3,0.6,10.7,1.2,16.2,1.6c39.2,3.3,83.5,2.6,124-1.5C257.8,259.2,286.5,254.4,310.8,248.2z M308.9,247.5
      c11.4-3,23.1-6.1,34.9-9.3c25.8-7.1,54.6-15,77.8-22.2c27-8.3,58.2-11.7,91.1-8.9c5.2,0.4,10.4,1,15.7,1.8
      c37.7,5.4,71.1,14.4,100.4,24.8c14.5,5.1,28.1,10.7,41.2,16.3c24.1,10.4,47.6,18.6,75.3,21.6l0-0.6c-27.1-3-50.3-11.1-74.2-21.4
      c-13.1-5.7-26.7-11.2-41.3-16.4c-29.4-10.4-62.9-19.5-100.8-24.9c-5.3-0.8-10.6-1.4-15.9-1.8c-33.4-2.8-65,0.6-92.2,9
      c-23.2,7.2-51.9,15.1-77.7,22.2c-11.8,3.2-23.5,6.4-34.9,9.3c-24.2,6.3-52.6,11.1-82.6,14.2c-40.7,4.2-85.3,5-124.7,1.7
      c-5-0.4-10-0.9-14.8-1.5c-39.4-4.7-71-17.2-103.4-28.2c-0.4-0.1-0.9-0.3-1.3-0.4l0,0.8c0.1,0,0.3,0.1,0.4,0.1
      c32.4,10.9,64.1,23.5,104,28.3c4.9,0.6,9.9,1.1,14.9,1.5c39.6,3.4,84.5,2.5,125.3-1.7C256,258.7,284.6,253.8,308.9,247.5z
       M307.5,246.8c11.4-3,23.1-6.2,34.8-9.4c25.8-7.2,54.6-15.1,77.9-22.3c26.8-8.2,57.6-11.5,90.3-8.7c5.4,0.5,10.8,1.1,16.2,1.9
      c37.6,5.4,70.8,14.5,99.9,24.9c14.5,5.1,28,10.7,41,16.3c24.7,10.7,48.8,19.1,77.6,21.9l0-0.6c-28.2-2.8-52-11.1-76.5-21.7
      c-13.1-5.7-26.6-11.2-41.1-16.4c-29.2-10.4-62.6-19.5-100.4-25c-5.5-0.8-11-1.4-16.4-1.9c-33-2.8-64.4,0.5-91.4,8.8
      c-23.2,7.1-52.1,15.1-77.9,22.3c-11.8,3.3-23.4,6.4-34.8,9.4c-24.1,6.3-52.5,11.2-82.4,14.4c-41,4.4-86.1,5.3-125.8,1.9
      c-4.7-0.4-9.3-0.9-13.8-1.4c-39.3-4.5-71-16.9-103.4-27.8l0,0.8c32,10.8,63.6,23.1,103,27.6c4.5,0.5,9.2,1,13.9,1.4
      c40,3.4,85.3,2.4,126.4-2C254.7,258.1,283.2,253.2,307.5,246.8z M306.7,246.2c11.4-3,23-6.2,34.8-9.5c25.7-7.2,54.7-15.2,78.1-22.3
      c26.6-8.1,57.1-11.3,89.4-8.5c5.5,0.5,11.1,1.1,16.7,1.9c37.4,5.5,70.5,14.6,99.4,24.9c14.4,5.1,27.8,10.7,40.8,16.3
      c25.1,10.9,49.7,19.4,79.2,22.1l0-0.6c-28.9-2.7-53.2-11-78.2-21.9c-13-5.6-26.4-11.2-40.9-16.4c-29-10.4-62.3-19.5-99.9-25
      c-5.7-0.8-11.3-1.5-16.9-2c-32.7-2.8-63.7,0.5-90.5,8.6c-23.3,7.1-52.4,15.1-78.1,22.3c-11.7,3.3-23.4,6.5-34.8,9.5
      c-24.1,6.4-52.4,11.4-82.2,14.6c-41.3,4.5-86.9,5.6-127.1,2.2c-4.3-0.4-8.4-0.8-12.5-1.2c-39-4.4-70.6-16.5-102.7-27.3l0,0.8
      c31.8,10.7,63.3,22.7,102.3,27.1c4.1,0.5,8.3,0.9,12.6,1.2c40.4,3.4,86.2,2.4,127.7-2.2C254,257.6,282.4,252.6,306.7,246.2z
       M306.8,245.6c11.4-3,23-6.3,34.7-9.6c25.6-7.2,54.8-15.3,78.2-22.3c26.4-7.9,56.6-11.1,88.5-8.4c5.7,0.5,11.5,1.1,17.3,2
      c37.2,5.5,70.2,14.7,98.9,25c14.4,5.1,27.7,10.7,40.6,16.3c25.4,11.1,50.1,19.6,80,22.2l0-0.6c-29.3-2.6-53.7-11-79-22
      c-12.9-5.6-26.3-11.2-40.7-16.4c-28.8-10.3-61.9-19.6-99.4-25.1c-5.9-0.9-11.7-1.6-17.5-2c-32.3-2.7-63,0.4-89.6,8.5
      c-23.4,7-52.6,15.1-78.2,22.3c-11.7,3.3-23.4,6.5-34.7,9.6c-24.1,6.4-52.3,11.5-82.1,14.9c-41.6,4.7-87.8,5.8-128.4,2.4
      c-3.8-0.3-7.6-0.7-11.3-1.1c-39-4.3-70.7-16.2-102.9-27.1l0,0.8c31.8,10.7,63.4,22.6,102.5,26.9c3.7,0.4,7.5,0.8,11.4,1.1
      c40.8,3.5,87.2,2.3,129-2.4C254.2,257.2,282.6,252.1,306.8,245.6z M307.9,245.1c11.3-3.1,23-6.3,34.7-9.6
      c25.6-7.3,54.9-15.3,78.4-22.3c26.2-7.8,56.1-10.8,87.7-8.2c5.9,0.5,11.8,1.2,17.8,2.1c37.1,5.6,69.9,14.8,98.5,25.1
      c14.3,5.2,27.6,10.7,40.5,16.3c25.2,11.1,49.8,19.6,79.8,22.2l0-0.6c-29.3-2.6-53.6-11-78.7-22c-12.9-5.6-26.2-11.2-40.6-16.4
      c-28.6-10.3-61.6-19.6-98.9-25.2c-6-0.9-12-1.6-18-2.1c-32-2.7-62.4,0.4-88.8,8.3c-23.5,7-52.8,15.1-78.4,22.3
      c-11.7,3.3-23.3,6.6-34.7,9.6c-24,6.5-52.2,11.6-81.9,15.1c-41.9,4.8-88.7,6.1-129.6,2.6c-3.4-0.3-6.7-0.6-10-1
      c-39.4-4.2-71.6-16.3-104.1-27.2l0,0.8c32.2,10.8,64.2,22.8,103.8,27c3.3,0.4,6.7,0.7,10.1,1c41.2,3.5,88.1,2.2,130.3-2.6
      C255.4,256.8,283.7,251.6,307.9,245.1z M310.1,244.6c11.3-3.1,23-6.4,34.6-9.7c25.5-7.3,55-15.4,78.5-22.4c26-7.7,55.6-10.6,86.8-8
      c6.1,0.5,12.1,1.2,18.3,2.2c36.9,5.6,69.6,14.9,98,25.1c14.2,5.2,27.5,10.7,40.3,16.3c24.9,10.9,49.1,19.4,78.4,22.1l0-0.6
      c-28.8-2.7-52.7-11.1-77.4-21.9c-12.8-5.6-26.1-11.2-40.4-16.4c-28.5-10.3-61.3-19.6-98.4-25.3c-6.2-0.9-12.4-1.7-18.5-2.2
      c-31.6-2.7-61.7,0.3-87.9,8.1c-23.5,6.9-53,15-78.5,22.4c-11.7,3.4-23.3,6.6-34.6,9.7c-24,6.6-52.1,11.8-81.7,15.3
      c-42.3,5-89.5,6.4-130.9,2.9c-3-0.3-5.9-0.5-8.8-0.8c-39.9-4.2-72.4-16.3-105.4-27.3c-0.4-0.1-0.7-0.3-1.1-0.4l0,0.8
      c0.1,0,0.1,0,0.2,0.1c32.9,11,65.6,23.2,105.9,27.4c2.9,0.3,5.9,0.6,8.9,0.8c41.6,3.5,89.1,2.2,131.5-2.9
      C257.7,256.4,285.9,251.2,310.1,244.6z M313.6,244.2c11.3-3.1,22.9-6.4,34.6-9.8c25.4-7.4,55.1-15.5,78.7-22.4
      c25.8-7.5,55.1-10.4,86-7.8c6.2,0.5,12.5,1.3,18.8,2.3c36.8,5.7,69.3,15,97.5,25.2c14.2,5.2,27.3,10.7,40.1,16.3
      c24.1,10.6,47.5,18.9,75.8,21.9l0-0.6c-27.6-3-50.8-11.2-74.7-21.7c-12.7-5.6-25.9-11.2-40.2-16.4c-28.3-10.3-61-19.6-98-25.3
      c-6.4-1-12.8-1.8-19-2.3c-31.3-2.7-61,0.3-87.1,7.9c-23.6,6.9-53.2,15-78.7,22.4c-11.7,3.4-23.3,6.7-34.6,9.8
      c-24,6.6-52,11.9-81.6,15.5c-42.6,5.2-90.4,6.6-132.1,3.1c-2.6-0.2-5.1-0.5-7.6-0.7c-40-4.1-72.7-16.1-105.8-27.1
      c-1.5-0.5-3-1-4.4-1.5l0,0.8c1.2,0.4,2.3,0.8,3.5,1.2c33,11,65.9,23.1,106.3,27.3c2.5,0.3,5.1,0.5,7.7,0.7c42,3.6,90,2.1,132.8-3.1
      C261.4,256.1,289.5,250.8,313.6,244.2z M318.2,243.6c11.3-3.1,22.9-6.5,34.5-9.9c25.4-7.4,55.2-15.6,78.9-22.4
      c25.6-7.4,54.6-10.2,85.1-7.6c6.4,0.5,12.8,1.3,19.4,2.3c36.6,5.8,69.1,15.1,97.1,25.3c14.1,5.2,27.2,10.7,39.9,16.3
      c23,10.2,45.4,18.2,72.1,21.5l0-0.7c-26-3.3-48.2-11.1-71-21.2c-12.7-5.6-25.8-11.2-40-16.4c-28.1-10.3-60.7-19.6-97.5-25.4
      c-6.6-1-13.1-1.8-19.6-2.4c-30.9-2.6-60.4,0.3-86.2,7.7c-23.7,6.8-53.5,15-78.9,22.4c-11.7,3.4-23.2,6.7-34.5,9.9
      c-24,6.7-51.9,12-81.4,15.7c-42.9,5.3-91.2,6.9-133.4,3.3c-2.2-0.2-4.3-0.4-6.4-0.6c-40.1-4-73-15.9-106.1-27c-2.9-1-5.8-2-8.8-3
      l0,0.8c2.6,0.9,5.2,1.8,7.9,2.7c33.1,11.1,66.2,23.1,106.7,27.1c2.1,0.2,4.3,0.4,6.5,0.6c42.4,3.6,91,2,134.1-3.4
      C266,255.7,294.1,250.3,318.2,243.6z M322.4,242.9c11.3-3.2,22.8-6.5,34.5-9.9c25.3-7.4,55.2-15.6,79-22.4c25.4-7.3,54-10,84.2-7.5
      c6.6,0.6,13.2,1.4,19.9,2.4c36.5,5.8,68.8,15.1,96.6,25.4c14.1,5.2,27.1,10.7,39.7,16.3c21.9,9.7,43.5,17.5,68.7,21l0-0.6
      c-24.6-3.5-45.9-11.1-67.6-20.8c-12.6-5.6-25.7-11.2-39.8-16.4c-27.9-10.3-60.4-19.6-97-25.5c-6.7-1.1-13.5-1.9-20.1-2.5
      c-30.6-2.6-59.7,0.2-85.3,7.5c-23.8,6.8-53.7,15-79,22.4c-11.7,3.4-23.2,6.8-34.5,9.9c-23.9,6.7-51.8,12.2-81.3,15.9
      c-43.2,5.5-92.1,7.2-134.6,3.6c-1.8-0.1-3.5-0.3-5.3-0.5C60.3,257.7,27.2,245.8-6,234.7c-3.6-1.2-7.2-2.4-10.8-3.7
      c-0.7-0.2-1.3-0.5-2-0.7l0,0.8c0.4,0.1,0.7,0.2,1.1,0.4c3.6,1.2,7.2,2.5,10.8,3.7c33.2,11.1,66.5,23,107.1,26.9
      c1.7,0.2,3.5,0.3,5.3,0.5c42.8,3.6,91.9,1.9,135.3-3.6C270.3,255.2,298.3,249.7,322.4,242.9z M326.3,242.2
      c11.3-3.2,22.8-6.6,34.4-10c25.2-7.5,55.3-15.7,79.2-22.5c25.2-7.1,53.5-9.8,83.4-7.3c6.7,0.6,13.5,1.4,20.4,2.5
      c36.3,5.9,68.5,15.2,96.1,25.4c14,5.2,27,10.8,39.5,16.3c21,9.4,41.7,16.9,65.6,20.6l0-0.6c-23.4-3.7-43.7-11.1-64.5-20.4
      c-12.6-5.6-25.5-11.2-39.6-16.4c-27.7-10.2-60-19.7-96.5-25.6c-7-1.1-13.8-2-20.7-2.5c-30.2-2.6-59,0.2-84.5,7.4
      c-23.8,6.7-53.9,15-79.2,22.5c-11.6,3.4-23.2,6.8-34.4,10c-23.9,6.8-51.7,12.3-81.1,16.2c-43.4,5.7-92.9,7.5-135.8,3.8
      c-1.4-0.1-2.7-0.2-4.1-0.4C64.3,257.6,31,245.8-2.4,234.7c-3.6-1.2-7.2-2.4-10.8-3.7c-1.9-0.7-3.8-1.3-5.7-2l0,0.8
      c1.6,0.5,3.2,1.1,4.8,1.6c3.6,1.2,7.2,2.5,10.8,3.7c33.3,11.1,66.8,22.9,107.5,26.8c1.4,0.1,2.7,0.2,4.1,0.4
      c43.2,3.7,92.8,1.9,136.5-3.9C274.4,254.6,302.3,249,326.3,242.2z M330,241.3c11.3-3.2,22.8-6.6,34.4-10.1
      c25.2-7.5,55.4-15.8,79.3-22.5c24.9-7,53-9.6,82.5-7.1c6.9,0.6,13.9,1.5,20.9,2.6c36.2,5.9,68.2,15.3,95.6,25.5
      c14,5.2,26.8,10.8,39.3,16.3c20.1,9,40,16.3,62.7,20.2l0-0.6c-22.2-3.8-41.7-11-61.7-19.9c-12.5-5.6-25.4-11.2-39.4-16.4
      c-27.5-10.2-59.7-19.7-96.1-25.6c-7.1-1.2-14.2-2-21.2-2.6c-29.9-2.5-58.4,0.1-83.6,7.2c-23.9,6.7-54.1,15-79.3,22.5
      c-11.6,3.5-23.1,6.9-34.4,10.1c-23.9,6.8-51.6,12.5-81,16.4c-43.7,5.9-93.7,7.8-137,4.1c-1-0.1-2-0.2-2.9-0.3
      C68,257.4,34.5,245.8,1,234.6c-3.6-1.2-7.2-2.4-10.8-3.7c-3.1-1.1-6.1-2.1-9.2-3.1l0,0.8c2.8,0.9,5.5,1.9,8.3,2.8
      c3.6,1.2,7.2,2.5,10.8,3.7c33.4,11.1,67.1,22.9,107.9,26.6c1,0.1,1.9,0.2,2.9,0.3c43.6,3.7,93.7,1.8,137.7-4.1
      C278.2,253.9,306,248.2,330,241.3z M333.5,240.4c11.2-3.3,22.7-6.7,34.3-10.2c25.1-7.5,55.5-15.9,79.5-22.5
      c24.7-6.9,52.5-9.4,81.7-6.9c7.1,0.6,14.3,1.5,21.5,2.7c36,6,67.9,15.4,95.2,25.6c13.9,5.2,26.7,10.8,39.1,16.3
      c19.4,8.7,38.4,15.8,60.1,19.7l0-0.6c-21.1-3.9-39.9-10.9-59-19.5c-12.4-5.6-25.3-11.2-39.2-16.4C619.2,218.5,587.2,209,551,203
      c-7.3-1.2-14.6-2.1-21.7-2.7c-29.5-2.5-57.7,0.1-82.7,7c-24,6.7-54.4,15-79.5,22.5c-11.6,3.5-23.1,6.9-34.3,10.2
      c-23.8,6.9-51.5,12.6-80.8,16.6c-44,6-94.5,8.1-138.2,4.4c-0.6,0-1.2-0.1-1.8-0.2c-40.5-3.6-74.1-15.1-107.7-26.3
      c-3.6-1.2-7.2-2.4-10.9-3.7c-4.2-1.4-8.3-2.8-12.5-4.3l0,0.8c3.8,1.3,7.7,2.6,11.5,4c3.6,1.2,7.2,2.5,10.9,3.7
      c33.5,11.2,67.4,22.8,108.3,26.4c0.6,0.1,1.2,0.1,1.8,0.2c44,3.7,94.7,1.7,138.9-4.4C281.8,253.1,309.5,247.4,333.5,240.4z
       M336.8,239.5c11.2-3.3,22.7-6.7,34.3-10.2c25-7.6,55.6-15.9,79.6-22.5c24.5-6.7,52-9.2,80.8-6.8c7.3,0.6,14.6,1.5,22,2.8
      c35.8,6,67.6,15.5,94.7,25.7c13.8,5.2,26.6,10.8,38.9,16.3c18.6,8.4,36.9,15.3,57.6,19.3l0-0.6c-20.2-4-38.2-10.8-56.6-19.1
      c-12.4-5.6-25.1-11.2-39-16.4c-27.1-10.2-59.1-19.7-95.1-25.8c-7.5-1.3-14.9-2.2-22.3-2.8c-29.2-2.5-57.1,0-81.9,6.8
      c-24.1,6.6-54.6,15-79.7,22.6c-11.6,3.5-23.1,7-34.3,10.2c-23.8,7-51.4,12.7-80.6,16.8c-44.5,6.3-95.7,8.3-140,4.6l-0.4,0
      c-40.4-3.4-74.2-14.9-107.7-26.1c-3.6-1.2-7.3-2.4-10.9-3.7c-5.2-1.8-10.4-3.6-15.5-5.3l0,0.8c4.9,1.7,9.7,3.3,14.6,5
      c3.6,1.3,7.2,2.5,10.9,3.7c33.5,11.2,67.4,22.8,108.3,26.2l0.4,0c44.6,3.7,96,1.7,140.7-4.6C285.2,252.3,312.9,246.5,336.8,239.5z
       M340,238.5c11.2-3.3,22.7-6.8,34.2-10.3c25-7.6,55.6-16,79.8-22.6c24.3-6.6,51.4-9,79.9-6.6c7.4,0.6,15,1.6,22.6,2.9
      c35.7,6.1,67.3,15.6,94.2,25.7c13.8,5.2,26.4,10.8,38.7,16.3c17.9,8.1,35.5,14.8,55.3,18.9l0-0.7c-19.3-4.1-36.6-10.7-54.2-18.7
      c-12.3-5.6-25-11.2-38.8-16.4c-27-10.2-58.7-19.7-94.6-25.8c-7.7-1.3-15.3-2.3-22.8-2.9c-28.9-2.5-56.4,0-81,6.7
      c-24.2,6.6-54.8,15-79.9,22.6c-11.6,3.5-23,7-34.2,10.3c-23.8,7-51.3,12.9-80.5,17c-44.4,6.4-95.6,8.5-140,4.9
      c-0.4,0-0.8-0.1-1.1-0.1c-40.2-3.4-73.9-14.7-107.3-25.9c-3.6-1.2-7.3-2.4-10.9-3.7c-6.2-2.1-12.3-4.2-18.5-6.3l0,0.8
      c5.9,2,11.7,4,17.6,6c3.6,1.3,7.3,2.5,10.9,3.7c33.4,11.1,67.3,22.5,107.9,26c0.4,0,0.8,0.1,1.1,0.1c44.7,3.6,96.2,1.4,140.8-4.9
      C288.4,251.4,316,245.5,340,238.5z M343,237.4c11.2-3.3,22.6-6.8,34.2-10.4c24.9-7.7,55.7-16.1,79.9-22.6
      c24.1-6.5,50.9-8.8,79.1-6.4c7.6,0.6,15.3,1.6,23.1,3c35.5,6.1,67.1,15.7,93.7,25.8c13.7,5.2,26.3,10.8,38.6,16.3
      c17.2,7.8,34.1,14.3,53,18.5l0-0.7c-18.4-4.1-35-10.5-52-18.2c-12.3-5.6-24.9-11.2-38.6-16.4c-26.8-10.2-58.4-19.7-94.1-25.9
      c-7.9-1.4-15.7-2.4-23.4-3c-28.5-2.4-55.8,0-80.1,6.5c-24.2,6.5-55.1,14.9-80,22.6c-11.6,3.6-23,7.1-34.2,10.4
      c-23.7,7.1-51.2,13-80.3,17.3c-44.3,6.5-95.6,8.9-140.1,5.3l-1.1-0.1C80.4,256,46.5,244.9,13,233.7c-3.6-1.2-7.3-2.4-10.9-3.7
      c-7.1-2.5-14.2-4.9-21.3-7.3l0,0.8c6.8,2.3,13.6,4.7,20.4,7c3.6,1.3,7.3,2.5,10.9,3.7c33.5,11.2,67.6,22.4,108.4,25.8
      c0.4,0,0.7,0.1,1.1,0.1c44.8,3.6,96.3,1.2,140.8-5.3C291.6,250.5,319.2,244.6,343,237.4z M346,236.4c11.2-3.4,22.6-6.9,34.1-10.5
      c24.8-7.7,55.8-16.2,80.1-22.6c23.9-6.3,50.4-8.6,78.2-6.3c7.8,0.7,15.7,1.7,23.6,3.1c35.4,6.2,66.8,15.8,93.3,25.9
      c13.7,5.2,26.2,10.8,38.4,16.3c16.5,7.5,32.8,13.8,50.9,18l0-0.7c-17.5-4.1-33.5-10.3-49.8-17.7c-12.2-5.6-24.7-11.2-38.4-16.4
      c-26.6-10.1-58.1-19.8-93.7-26c-8-1.4-16-2.4-23.9-3.1c-28.2-2.4-55.1-0.1-79.2,6.3c-24.3,6.5-55.3,14.9-80.2,22.6
      c-11.6,3.6-23,7.1-34.1,10.5c-23.7,7.1-51.1,13.1-80.2,17.5c-44.1,6.6-95.5,9.2-140.1,5.7c-0.6-0.1-1.2-0.1-1.8-0.1
      c-40-3.4-74-14.4-107.4-25.5c-3.7-1.2-7.3-2.4-10.9-3.7c-8-2.8-16.1-5.6-24.1-8.3l0,0.8c7.7,2.6,15.4,5.3,23.2,8
      c3.6,1.3,7.3,2.5,10.9,3.7c33.4,11.1,67.5,22.2,108,25.6c0.6,0.1,1.2,0.1,1.8,0.2c44.9,3.5,96.5,1,140.9-5.7
      C294.7,249.6,322.2,243.6,346,236.4z M349,235.3c11.2-3.4,22.5-6.9,34.1-10.5c24.8-7.7,55.9-16.2,80.2-22.6
      c23.6-6.2,49.9-8.4,77.4-6.1c8,0.7,16,1.7,24.2,3.2c35.2,6.3,66.5,15.9,92.8,26c13.6,5.2,26,10.8,38.2,16.4
      c15.8,7.2,31.5,13.3,48.7,17.5l0-0.7c-16.7-4.1-32-10.1-47.6-17.2c-12.1-5.6-24.6-11.2-38.3-16.4c-26.4-10.1-57.8-19.8-93.2-26.1
      c-8.2-1.5-16.4-2.5-24.4-3.2c-27.8-2.4-54.5-0.1-78.4,6.2c-24.4,6.4-55.5,14.9-80.3,22.7c-11.5,3.6-22.9,7.2-34.1,10.5
      c-23.7,7.2-51,13.3-80,17.7c-44,6.7-95.4,9.4-140.1,6c-0.9-0.1-1.7-0.1-2.6-0.2c-39.8-3.4-73.7-14.2-107-25.3
      c-3.7-1.2-7.3-2.5-10.9-3.7c-9-3.1-17.9-6.2-26.9-9.3l0,0.8c8.6,3,17.3,6,25.9,9c3.7,1.3,7.3,2.5,11,3.7
      c33.3,11.1,67.4,22,107.6,25.4c0.9,0.1,1.7,0.1,2.6,0.2c45.1,3.4,96.6,0.7,140.9-6C297.8,248.7,325.2,242.6,349,235.3z M352,234.3
      c11.1-3.4,22.5-7,34-10.6c24.7-7.8,55.9-16.3,80.4-22.7c23.4-6.1,49.3-8.2,76.5-5.9c8.1,0.7,16.4,1.8,24.7,3.3
      c35.1,6.3,66.2,16,92.3,26c13.5,5.2,25.9,10.8,38,16.4c15.1,7,30.1,12.9,46.5,17l0-0.7c-15.9-4.1-30.5-9.9-45.4-16.7
      c-12.1-5.5-24.5-11.2-38.1-16.4c-26.2-10.1-57.5-19.8-92.7-26.1c-8.4-1.5-16.7-2.6-25-3.3c-27.5-2.3-53.8-0.2-77.5,6
      c-24.5,6.4-55.8,14.9-80.5,22.7c-11.5,3.6-22.9,7.2-34,10.6c-23.7,7.3-50.9,13.4-79.8,17.9c-43.9,6.9-95.3,9.7-140.2,6.4
      c-1.1-0.1-2.2-0.2-3.4-0.3c-39.6-3.4-73.4-14-106.6-25c-3.7-1.2-7.3-2.5-11-3.7c-9.9-3.4-19.8-6.9-29.6-10.3l0,0.8
      c9.6,3.3,19.1,6.6,28.7,10c3.7,1.3,7.3,2.5,11,3.7c33.2,11,67.2,21.8,107.2,25.1c1.1,0.1,2.3,0.2,3.4,0.3
      c45.2,3.3,96.8,0.5,140.9-6.4C300.9,247.8,328.2,241.6,352,234.3z M355.1,233.3c11.1-3.4,22.5-7,34-10.7
      c24.6-7.8,56-16.4,80.5-22.7c23.2-6,48.8-8,75.6-5.8c8.3,0.7,16.8,1.8,25.3,3.4c34.9,6.4,65.9,16.1,91.8,26.1
      c13.5,5.2,25.8,10.8,37.8,16.3c14.4,6.7,28.7,12.4,44.3,16.5l0-0.7c-15-4.1-29-9.7-43.2-16.2c-12-5.5-24.3-11.1-37.9-16.4
      c-26-10.1-57.1-19.8-92.2-26.2c-8.6-1.6-17.1-2.7-25.5-3.4c-27.1-2.3-53.2-0.2-76.6,5.8c-24.6,6.3-56,14.9-80.7,22.7
      c-11.5,3.6-22.8,7.3-33.9,10.7c-23.6,7.3-50.8,13.5-79.7,18.1c-43.8,7-95.2,9.9-140.2,6.7c-1.4-0.1-2.8-0.2-4.1-0.3
      c-39.4-3.3-73.2-13.8-106.2-24.8c-3.7-1.2-7.3-2.5-11-3.7c-10.9-3.8-21.7-7.6-32.5-11.3l0,0.8c10.5,3.6,21,7.3,31.6,11
      c3.7,1.3,7.3,2.5,11,3.7c33.1,11,67.1,21.6,106.8,24.9c1.4,0.1,2.8,0.2,4.2,0.3c45.3,3.2,97,0.2,141-6.8
      C304,246.9,331.3,240.7,355.1,233.3z M358.4,232c11.1-3.5,22.4-7.1,33.9-10.8c24.6-7.8,56.1-16.5,80.7-22.7
      c23-5.8,48.3-7.8,74.7-5.6c8.5,0.7,17.1,1.9,25.8,3.5c34.8,6.4,65.6,16.1,91.3,26.2c13.4,5.2,25.7,10.8,37.6,16.4
      c13.7,6.3,27.2,11.8,41.7,15.9l0-0.7c-14.1-4-27.3-9.4-40.7-15.6c-11.9-5.5-24.2-11.1-37.7-16.4c-25.8-10.1-56.8-19.8-91.8-26.3
      c-8.8-1.6-17.5-2.8-26.1-3.5c-26.8-2.3-52.5-0.2-75.7,5.7c-24.6,6.3-56.2,14.9-80.8,22.7c-11.5,3.7-22.8,7.3-33.9,10.8
      c-23.6,7.4-50.7,13.7-79.5,18.4c-43.7,7.1-95.1,10.2-140.3,7.1c-1.7-0.1-3.3-0.2-4.9-0.4C93.7,253.3,59.9,243,27,232
      c-3.7-1.2-7.3-2.5-11-3.7c-11.9-4.1-23.7-8.3-35.6-12.4l0,0.8c11.6,4,23.1,8,34.7,12c3.7,1.3,7.3,2.5,11,3.8
      c33,10.9,66.9,21.4,106.5,24.7c1.6,0.1,3.3,0.3,5,0.4c45.5,3.1,97.1,0,141-7.1C307.5,245.7,334.7,239.4,358.4,232z M362.1,230
      c11.1-3.5,22.4-7.2,33.9-10.8c24.5-7.9,56.2-16.5,80.9-22.7c22.7-5.7,47.7-7.6,73.9-5.4c8.7,0.7,17.5,1.9,26.3,3.6
      c34.6,6.5,65.3,16.2,90.9,26.3c13.4,5.2,25.5,10.8,37.4,16.4c12.7,5.9,25.3,11.1,38.7,15.1l0-0.7c-13-3.9-25.2-9-37.6-14.8
      c-11.9-5.5-24.1-11.1-37.5-16.4c-25.6-10-56.5-19.8-91.3-26.4c-9-1.7-17.9-2.9-26.7-3.6c-26.4-2.2-51.8-0.3-74.9,5.5
      c-24.7,6.2-56.4,14.9-81,22.8c-11.5,3.7-22.8,7.3-33.9,10.8c-23.6,7.4-50.6,13.8-79.4,18.6c-43.6,7.2-95,10.5-140.3,7.5
      c-1.9-0.1-3.8-0.3-5.7-0.4c-38.9-3.3-72.6-13.5-105.4-24.4c-3.7-1.2-7.4-2.5-11-3.8c-13.1-4.6-26.1-9.2-39.2-13.7l0,0.8
      c12.8,4.4,25.5,8.9,38.3,13.3c3.7,1.3,7.3,2.5,11,3.8c32.9,10.9,66.8,21.2,106,24.5c1.9,0.2,3.8,0.3,5.8,0.4
      c45.6,3,97.3-0.2,141-7.5C311.3,243.9,338.5,237.5,362.1,230z M366,228.3c11.1-3.5,22.4-7.2,33.8-10.9c24.4-7.9,56.2-16.6,81-22.8
      c22.5-5.6,47.2-7.5,73-5.3c8.9,0.8,17.8,2,26.9,3.7c34.5,6.5,65,16.3,90.4,26.3c13.3,5.2,25.4,10.8,37.2,16.4
      c11.7,5.5,23.3,10.3,35.6,14.2l0-0.8c-11.8-3.8-23.1-8.4-34.5-13.8c-11.8-5.5-23.9-11.1-37.3-16.4c-25.5-10-56.1-19.9-90.8-26.4
      c-9.2-1.7-18.2-3-27.2-3.7c-26.1-2.2-51.2-0.3-74,5.4c-24.8,6.2-56.7,14.9-81.1,22.8c-11.5,3.7-22.7,7.4-33.8,10.9
      c-23.5,7.5-50.5,13.9-79.2,18.8c-43.4,7.4-94.9,10.7-140.3,7.8c-2.2-0.1-4.4-0.3-6.5-0.5c-38.7-3.3-72.3-13.3-105-24.1
      c-3.7-1.2-7.4-2.5-11-3.8c-14.4-5-28.6-10.1-43-15l0,0.8c14,4.8,28,9.7,42,14.7c3.7,1.3,7.4,2.5,11.1,3.8
      c32.7,10.8,66.6,21,105.6,24.3c2.2,0.2,4.4,0.4,6.6,0.5c45.7,2.9,97.4-0.5,141.1-7.9C315.3,242.2,342.4,235.8,366,228.3z
       M370.1,226.6c11.1-3.5,22.4-7.3,33.8-11c24.3-7.9,56.3-16.7,81.2-22.8c22.3-5.5,46.7-7.3,72.1-5.1c9,0.8,18.2,2,27.4,3.8
      c34.3,6.6,64.7,16.4,89.9,26.4c13.3,5.2,25.3,10.8,37,16.4c10.6,5,21.2,9.4,32.3,13.1l0-0.8c-10.6-3.6-20.9-7.9-31.2-12.7
      c-11.8-5.5-23.8-11.1-37.1-16.4c-25.3-10-55.8-19.9-90.3-26.5c-9.3-1.8-18.6-3.1-27.8-3.9c-25.8-2.2-50.5-0.3-73.1,5.2
      c-24.9,6.1-56.9,14.9-81.3,22.8c-11.4,3.7-22.7,7.4-33.8,11c-23.5,7.5-50.4,14.1-79.1,19c-43.3,7.5-94.9,11-140.4,8.2
      c-2.5-0.2-4.9-0.3-7.3-0.5c-38.4-3.3-72-13.1-104.6-23.9c-3.7-1.2-7.4-2.5-11-3.8c-15.7-5.5-31.2-11-46.9-16.4l0,0.8
      c15.3,5.2,30.5,10.7,45.9,16.1c3.7,1.3,7.4,2.5,11.1,3.8c32.6,10.8,66.4,20.7,105.2,24c2.4,0.2,4.9,0.4,7.4,0.5
      c45.8,2.8,97.6-0.7,141.1-8.2C319.5,240.8,346.5,234.2,370.1,226.6z M374.4,225.1c11.1-3.6,22.3-7.3,33.7-11.1
      c24.3-8,56.4-16.8,81.3-22.8c22-5.3,46.1-7.1,71.2-5c9.2,0.8,18.6,2.1,28,3.9c34.2,6.6,64.5,16.5,89.5,26.5
      c13.2,5.3,25.1,10.8,36.8,16.4c9.5,4.5,18.9,8.5,28.7,11.9l0-0.8c-9.4-3.3-18.5-7.2-27.6-11.5c-11.7-5.5-23.7-11.1-36.9-16.4
      c-25.1-10-55.5-19.9-89.8-26.6c-9.5-1.9-19-3.2-28.3-4c-25.4-2.2-49.9-0.4-72.2,5c-25,6.1-57.1,14.9-81.5,22.8
      c-11.4,3.8-22.7,7.5-33.7,11.1c-23.5,7.6-50.3,14.2-78.9,19.2c-43.2,7.6-94.8,11.3-140.4,8.6c-2.7-0.2-5.4-0.4-8.1-0.6
      c-38.2-3.2-71.7-13-104.2-23.7c-3.7-1.2-7.4-2.5-11.1-3.8c-17.1-6-34-12-51-17.8l0,0.8c16.7,5.7,33.3,11.6,50.1,17.5
      c3.7,1.3,7.4,2.6,11.1,3.8c32.5,10.7,66.2,20.6,104.8,23.8c2.7,0.2,5.4,0.4,8.2,0.6c46,2.7,97.7-1,141.2-8.6
      C323.9,239.4,350.8,232.8,374.4,225.1z M379,223.7c11.1-3.6,22.3-7.4,33.7-11.1c24.2-8,56.5-16.8,81.5-22.8
      c21.8-5.2,45.6-6.9,70.4-4.8c9.4,0.8,18.9,2.1,28.5,4c34,6.7,64.2,16.6,89,26.5c13.1,5.3,25,10.9,36.7,16.4
      c8.2,3.9,16.4,7.4,24.8,10.6l0-0.8c-8-3-15.9-6.5-23.8-10.2c-11.6-5.5-23.5-11.1-36.7-16.4c-24.9-10-55.2-19.9-89.4-26.7
      c-9.7-1.9-19.4-3.3-28.9-4.1c-25.1-2.1-49.3-0.4-71.3,4.9c-25.1,6-57.4,14.8-81.7,22.9c-11.4,3.8-22.6,7.5-33.7,11.1
      c-23.4,7.7-50.2,14.3-78.7,19.5c-43.1,7.7-94.7,11.5-140.5,8.9c-3-0.2-6-0.4-9-0.6c-38-3.2-71.4-12.8-103.7-23.5
      c-3.7-1.2-7.4-2.5-11.1-3.8c-18.6-6.5-36.9-13.1-55.5-19.4l0,0.8c18.2,6.2,36.2,12.7,54.5,19.1c3.7,1.3,7.4,2.6,11.1,3.8
      c32.4,10.7,66,20.4,104.3,23.6c3,0.3,6,0.5,9,0.6c46.1,2.6,97.9-1.2,141.2-9C328.6,238.1,355.4,231.4,379,223.7z M383.9,222.4
      c11-3.6,22.2-7.4,33.6-11.2c24.2-8.1,56.5-16.9,81.6-22.9c21.6-5.1,45-6.7,69.5-4.7c9.6,0.8,19.3,2.2,29.1,4.2
      c33.8,6.8,63.9,16.7,88.5,26.6c13.1,5.3,24.9,10.9,36.5,16.4c6.9,3.3,13.7,6.3,20.6,9l0-0.8c-6.6-2.6-13-5.5-19.5-8.6
      c-11.6-5.5-23.4-11.1-36.6-16.4c-24.7-9.9-54.9-19.9-88.9-26.7c-9.9-2-19.7-3.4-29.4-4.2c-24.8-2.1-48.6-0.4-70.5,4.7
      c-25.1,6-57.6,14.8-81.8,22.9c-11.4,3.8-22.6,7.6-33.6,11.2c-23.4,7.7-50.1,14.5-78.6,19.7c-43,7.9-94.6,11.8-140.5,9.3
      c-3.3-0.2-6.6-0.4-9.8-0.7C116.5,247,83.1,237.6,50.9,227c-3.7-1.2-7.4-2.5-11.1-3.8c-20.2-7.1-40.1-14.3-60.3-21.1l0,0.8
      c19.8,6.7,39.4,13.8,59.3,20.8c3.7,1.3,7.4,2.6,11.1,3.8c32.2,10.6,65.8,20.2,103.9,23.4c3.3,0.3,6.5,0.5,9.9,0.7
      c46.2,2.5,98-1.4,141.2-9.3C333.7,237,360.4,230.2,383.9,222.4z M389.3,221.3c11-3.7,22.2-7.5,33.6-11.3
      c24.1-8.1,56.6-17,81.8-22.9c21.4-5,44.5-6.6,68.6-4.5c9.7,0.8,19.7,2.2,29.6,4.3c33.7,6.8,63.6,16.8,88,26.7
      c13,5.3,24.8,10.9,36.3,16.4c5.3,2.5,10.6,4.9,16,7.1l0-0.8c-5-2.1-9.9-4.3-14.9-6.6c-11.5-5.5-23.3-11.1-36.4-16.4
      c-24.5-9.9-54.5-19.9-88.4-26.8c-10.1-2-20.1-3.5-30-4.3c-24.4-2.1-48-0.5-69.5,4.6c-25.2,5.9-57.8,14.8-82,22.9
      c-11.4,3.8-22.6,7.6-33.6,11.3c-23.4,7.8-50,14.6-78.4,19.9c-42.9,8-94.5,12-140.5,9.7c-3.6-0.2-7.1-0.4-10.6-0.7
      c-37.5-3.2-70.8-12.5-102.8-23.1c-3.7-1.2-7.4-2.5-11.1-3.8c-20.5-7.2-40.7-14.6-61.1-21.5c-1.5-0.5-2.9-0.9-4.4-1.4l0,0.8
      c1.1,0.4,2.3,0.7,3.4,1.1C3.3,208.6,23.4,216,44,223.2c3.7,1.3,7.4,2.6,11.2,3.8c32.1,10.6,65.6,20,103.4,23.2
      c3.5,0.3,7.1,0.5,10.7,0.7c46.4,2.4,98.2-1.7,141.3-9.7C339.1,235.9,365.8,229.1,389.3,221.3z M396.3,219.8
      c11-3.7,22.2-7.5,33.5-11.4c24-8.1,56.7-17.1,81.9-22.9c21.1-4.9,43.9-6.4,67.7-4.4c9.9,0.8,20,2.3,30.2,4.4
      c33.5,6.9,63.3,16.9,87.6,26.8c13,5.3,24.6,10.9,36.1,16.4c3.2,1.6,6.5,3,9.7,4.4l0-0.9c-2.9-1.3-5.7-2.6-8.6-3.9
      c-11.5-5.5-23.2-11.1-36.2-16.4c-24.4-9.9-54.2-20-87.9-26.9c-10.3-2.1-20.5-3.6-30.5-4.4c-24.1-2-47.3-0.5-68.7,4.4
      c-25.3,5.9-58,14.8-82.1,23c-11.4,3.8-22.5,7.7-33.5,11.4c-23.4,7.8-49.9,14.7-78.3,20.1c-42.7,8.1-94.4,12.3-140.6,10
      c-3.9-0.2-7.7-0.5-11.5-0.8c-37.3-3.2-70.5-12.3-102.4-22.9c-3.7-1.2-7.4-2.5-11.1-3.8c-20.6-7.2-40.7-14.6-61.1-21.6
      c-3.8-1.3-7.5-2.4-11.2-3.5l0,0.7c3.4,1,6.8,2.1,10.3,3.2c20.4,7,40.5,14.4,61.1,21.6c3.7,1.3,7.4,2.6,11.2,3.8
      c31.9,10.5,65.3,19.8,103,23c3.8,0.3,7.7,0.6,11.6,0.8c46.5,2.3,98.4-1.9,141.3-10.1C346.2,234.6,372.8,227.7,396.3,219.8z
       M403.1,218.7c11-3.7,22.1-7.6,33.5-11.4c24-8.2,56.8-17.1,82.1-22.9c20.9-4.8,43.4-6.2,66.8-4.2c10.1,0.9,20.4,2.4,30.7,4.5
      c33.4,6.9,63,17,87.1,26.8c12.9,5.3,24.5,10.9,35.9,16.4c1.2,0.6,2.3,1.1,3.5,1.6l-0.1-0.9c-0.8-0.4-1.6-0.7-2.4-1.1
      c-11.4-5.5-23-11.1-36-16.4c-24.2-9.9-53.9-20-87.5-26.9c-10.5-2.2-20.8-3.7-31.1-4.6c-23.7-2-46.7-0.5-67.8,4.3
      c-25.4,5.8-58.3,14.8-82.3,23c-11.4,3.9-22.5,7.7-33.5,11.4c-23.3,7.9-49.8,14.9-78.1,20.3c-42.6,8.2-94.3,12.6-140.6,10.4
      c-4.2-0.2-8.3-0.5-12.3-0.8c-37-3.1-70.2-12.2-101.9-22.6c-3.7-1.2-7.5-2.5-11.2-3.8C37.5,214.4,17.4,207-3,199.9
      c-6-2.1-12-3.8-17.9-5.5l0,0.7c5.6,1.6,11.3,3.2,17,5.2c20.4,7,40.5,14.4,61.1,21.7c3.7,1.3,7.5,2.6,11.2,3.8
      c31.8,10.5,65.1,19.6,102.5,22.8c4.1,0.3,8.2,0.6,12.4,0.8c46.6,2.2,98.5-2.2,141.3-10.4C353.2,233.6,379.7,226.6,403.1,218.7z
       M409.9,217.7c11-3.7,22.1-7.6,33.4-11.5c23.9-8.2,56.8-17.2,82.2-22.9c20.6-4.6,42.9-6.1,65.9-4.1c10.3,0.9,20.7,2.4,31.3,4.6
      c33.2,7,62.7,17.1,86.6,26.9c11.9,4.9,22.6,10,33.1,15.1l-0.1-0.9c-10.2-4.9-20.6-9.9-32.1-14.6c-24-9.9-53.6-20-87-27
      c-10.6-2.3-21.2-3.8-31.7-4.7c-23.4-2-46-0.5-66.9,4.2c-25.5,5.8-58.5,14.8-82.5,23c-11.3,3.9-22.5,7.8-33.4,11.5
      c-23.3,8-49.7,15-77.9,20.6c-42.5,8.3-94.2,12.8-140.7,10.7c-4.5-0.2-8.8-0.5-13.2-0.9c-36.8-3.1-69.8-12-101.4-22.4
      c-3.7-1.2-7.5-2.5-11.2-3.8c-20.6-7.3-40.8-14.7-61.1-21.8c-8.2-2.9-16.4-5.2-24.5-7.3l0,0.7c7.9,2,15.7,4.3,23.6,7
      c20.3,7.1,40.5,14.5,61.1,21.8c3.7,1.3,7.4,2.6,11.2,3.8c31.7,10.4,64.9,19.4,102,22.5c4.4,0.4,8.8,0.7,13.3,0.9
      c46.7,2.1,98.7-2.4,141.4-10.8C360.1,232.8,386.6,225.7,409.9,217.7z M416.6,217c10.9-3.8,22.1-7.7,33.4-11.6
      c23.8-8.2,56.9-17.3,82.4-23c20.4-4.5,42.3-5.9,65.1-4c10.5,0.9,21.1,2.5,31.9,4.8c33.1,7,62.4,17.2,86.1,27
      c9.6,4,18.4,8.1,26.9,12.2l-0.1-0.9c-8.2-3.9-16.7-7.9-25.9-11.7c-23.8-9.9-53.2-20-86.5-27.1c-10.9-2.3-21.6-3.9-32.2-4.8
      c-23.1-2-45.4-0.6-66,4c-25.6,5.7-58.7,14.8-82.6,23c-11.3,3.9-22.4,7.8-33.4,11.6c-23.3,8-49.6,15.1-77.8,20.8
      c-42.4,8.5-94.2,13.1-140.7,11.1c-4.7-0.2-9.4-0.5-14-0.9c-36.6-3.1-69.5-11.9-100.9-22.2c-3.8-1.2-7.5-2.5-11.2-3.8
      c-20.7-7.3-40.8-14.8-61.1-21.9c-10.4-3.6-20.7-6.5-31.1-9l0,0.7c10.1,2.5,20,5.3,30.1,8.8c20.3,7.1,40.5,14.6,61.1,21.9
      c3.7,1.3,7.5,2.6,11.2,3.8c31.5,10.4,64.6,19.2,101.5,22.3c4.7,0.4,9.4,0.7,14.2,0.9c46.9,2,98.8-2.6,141.4-11.2
      C366.9,232.1,393.3,225,416.6,217z M423.3,216.4c10.9-3.8,22-7.7,33.3-11.7c23.8-8.3,57-17.4,82.5-23c20.1-4.4,41.8-5.7,64.2-3.8
      c10.7,0.9,21.5,2.5,32.4,4.9c32.9,7.1,62.1,17.3,85.7,27.1c7.3,3,14.2,6.2,20.8,9.3l0-0.9c-6.3-3-12.8-6-19.8-8.9
      c-23.6-9.8-52.9-20-86-27.2c-11-2.4-22-4-32.8-5c-22.7-1.9-44.7-0.6-65.1,3.9c-25.7,5.7-58.9,14.8-82.8,23.1
      c-11.3,3.9-22.4,7.9-33.3,11.7c-23.2,8.1-49.5,15.3-77.6,21c-42.3,8.6-94.1,13.3-140.7,11.5c-5-0.2-10-0.5-14.9-0.9
      c-36.3-3.1-69.2-11.7-100.5-22c-3.8-1.2-7.5-2.5-11.2-3.8c-20.7-7.3-40.8-14.8-61.2-22c-12.6-4.4-24.9-7.7-37.5-10.7l0,0.7
      c12.3,3,24.3,6.2,36.5,10.5c20.3,7.1,40.4,14.7,61.1,22c3.7,1.3,7.5,2.6,11.3,3.9c31.4,10.3,64.4,19,101.1,22.1
      c4.9,0.4,10,0.7,15.1,0.9c47,1.9,99-2.9,141.5-11.5C373.6,231.7,399.9,224.5,423.3,216.4z M429.8,216c10.9-3.8,22-7.8,33.3-11.7
      c23.7-8.3,57-17.4,82.7-23c19.9-4.3,41.2-5.6,63.3-3.7c10.8,0.9,21.9,2.6,33,5c32.8,7.1,61.8,17.4,85.2,27.1
      c5.1,2.2,10,4.4,14.8,6.6l-0.1-0.9c-4.4-2.1-8.9-4.1-13.7-6.1c-23.4-9.8-52.6-20-85.6-27.2c-11.2-2.5-22.4-4.1-33.4-5.1
      c-22.4-1.9-44.1-0.6-64.2,3.7c-25.8,5.6-59.2,14.8-83,23.1c-11.3,3.9-22.4,7.9-33.3,11.7c-23.2,8.1-49.4,15.4-77.4,21.2
      c-42.2,8.7-94,13.6-140.8,11.8c-5.4-0.2-10.6-0.5-15.8-1c-36.1-3.1-68.8-11.6-100-21.8c-3.8-1.2-7.5-2.5-11.2-3.8
      c-20.7-7.3-40.8-14.9-61.2-22.1c-14.7-5.2-29-8.8-43.8-12.4l0,0.7c14.5,3.5,28.5,7.1,42.9,12.1c20.3,7.2,40.4,14.7,61.1,22.1
      c3.7,1.3,7.5,2.6,11.3,3.9c31.2,10.3,64.1,18.8,100.6,21.9c5.3,0.4,10.6,0.8,16,1c47.1,1.8,99.1-3.1,141.5-11.9
      C380.3,231.5,406.5,224.2,429.8,216z M436.3,215.9c10.9-3.8,22-7.8,33.2-11.8c23.6-8.3,57.1-17.5,82.9-23
      c19.6-4.2,40.7-5.4,62.4-3.6c11,0.9,22.2,2.7,33.5,5.2c32.6,7.2,61.5,17.4,84.7,27.2c3,1.3,6,2.6,8.8,3.9l-0.1-0.9
      c-2.5-1.1-5.1-2.3-7.8-3.4c-23.2-9.8-52.3-20.1-85.1-27.3c-11.4-2.5-22.7-4.3-33.9-5.2c-22.1-1.9-43.4-0.7-63.3,3.6
      c-25.9,5.5-59.4,14.8-83.1,23.1c-11.3,4-22.3,8-33.2,11.8c-23.2,8.2-49.3,15.5-77.3,21.4c-42.1,8.8-93.9,13.9-140.8,12.2
      c-5.7-0.2-11.2-0.5-16.7-1c-35.9-3-68.5-11.4-99.4-21.6c-3.8-1.2-7.5-2.5-11.3-3.8c-20.7-7.4-40.9-14.9-61.2-22.2
      c-16.7-6-33-9.9-50.1-14.1l0,0.7c16.8,4.1,32.7,8,49.1,13.8c20.3,7.2,40.4,14.8,61.1,22.2c3.8,1.3,7.5,2.6,11.3,3.9
      c31,10.2,63.8,18.7,100.1,21.7c5.5,0.5,11.1,0.8,16.8,1c47.3,1.7,99.3-3.4,141.6-12.2C386.9,231.5,413.1,224.1,436.3,215.9z
       M442.7,215.9c10.9-3.9,21.9-7.9,33.2-11.9c23.6-8.4,57.2-17.6,83-23.1c19.4-4.1,40.1-5.2,61.5-3.4c11.2,0.9,22.6,2.7,34.1,5.3
      c32.5,7.3,61.3,17.5,84.2,27.3c1,0.4,2,0.9,3,1.3l-0.1-0.9c-0.7-0.3-1.3-0.6-1.9-0.9c-23.1-9.8-52-20.1-84.6-27.4
      c-11.6-2.6-23.1-4.4-34.5-5.4c-21.7-1.8-42.8-0.7-62.4,3.5c-26,5.5-59.6,14.7-83.3,23.2c-11.2,4-22.3,8-33.2,11.9
      c-23.1,8.3-49.2,15.7-77.1,21.6c-41.9,9-93.8,14.1-140.8,12.6c-6-0.2-11.8-0.6-17.6-1c-35.6-3-68.1-11.3-98.9-21.4
      c-3.8-1.2-7.5-2.5-11.3-3.9c-20.8-7.4-40.9-15-61.2-22.3c-18.8-6.7-36.9-10.9-56.3-15.8l0,0.7c19.1,4.8,36.9,8.9,55.3,15.5
      c20.2,7.3,40.4,14.9,61.2,22.3c3.8,1.3,7.5,2.6,11.3,3.9c30.9,10.1,63.6,18.5,99.5,21.5c5.8,0.5,11.7,0.8,17.8,1.1
      c47.4,1.6,99.4-3.6,141.6-12.6C393.4,231.6,419.5,224.1,442.7,215.9z M449.1,216c10.9-3.9,21.9-7.9,33.1-12
      c23.5-8.4,57.3-17.7,83.2-23.1c19.1-4,39.5-5.1,60.6-3.3c11.4,1,23,2.8,34.7,5.4c31,7,58.6,16.8,81,26.2l0-0.9
      c-22.2-9.3-49.6-18.9-80.3-25.9c-11.8-2.7-23.5-4.5-35.1-5.5c-21.4-1.8-42.1-0.7-61.5,3.4c-26.1,5.4-59.8,14.7-83.4,23.2
      c-11.3,4-22.3,8.1-33.1,12c-23.1,8.3-49.1,15.8-77,21.9c-41.8,9.1-93.7,14.4-140.9,12.9c-6.3-0.2-12.4-0.6-18.5-1.1
      c-35.4-3-67.8-11.2-98.4-21.2c-3.8-1.2-7.5-2.5-11.3-3.9c-20.8-7.4-40.9-15-61.2-22.3c-19.6-7.1-38.5-11.4-58.8-16.6
      c-1.2-0.3-2.4-0.6-3.6-0.9l0,0.7c0.9,0.2,1.9,0.5,2.8,0.7c20.3,5.2,39.1,9.5,58.6,16.6c20.2,7.3,40.4,14.9,61.2,22.4
      c3.8,1.3,7.5,2.6,11.3,3.9c30.8,10.1,63.3,18.3,99.1,21.3c6.1,0.5,12.3,0.9,18.7,1.1c47.5,1.5,99.6-3.8,141.6-13
      C399.8,231.9,425.9,224.3,449.1,216z M455.4,216.3c10.9-3.9,21.8-8,33.1-12c23.4-8.4,57.3-17.8,83.3-23.1c18.8-3.9,39-4.9,59.7-3.2
      c11.6,1,23.4,2.8,35.2,5.6c28.3,6.5,53.7,15.3,74.8,23.9l0-0.8c-21-8.5-46.1-17.2-74.1-23.6c-12-2.8-23.9-4.6-35.6-5.6
      c-21-1.8-41.5-0.7-60.6,3.2c-26.2,5.4-60.1,14.7-83.6,23.2c-11.2,4-22.2,8.1-33.1,12c-23.1,8.4-49,15.9-76.8,22.1
      c-41.7,9.2-93.6,14.7-140.9,13.3c-6.6-0.2-13-0.6-19.4-1.1c-35.2-3-67.4-11-97.9-21c-3.8-1.2-7.6-2.5-11.3-3.9
      c-20.8-7.4-41-15.1-61.2-22.4c-19.6-7.1-38.5-11.5-58.7-16.8c-3.3-0.9-6.5-1.7-9.7-2.5l0,0.7c3,0.8,6,1.6,9,2.4
      c20.2,5.3,39,9.7,58.5,16.7c20.2,7.4,40.3,15,61.2,22.5c3.7,1.3,7.5,2.6,11.4,3.9c30.6,10,63,18.1,98.5,21.1
      c6.4,0.5,13,0.9,19.6,1.1c47.6,1.4,99.7-4.1,141.7-13.3C406.2,232.3,432.2,224.7,455.4,216.3z M461.6,216.7
      c10.8-4,21.8-8.1,33-12.1c23.4-8.5,57.4-17.8,83.5-23.1c18.6-3.8,38.4-4.8,58.8-3.1c11.8,1,23.7,2.9,35.8,5.7
      c25.6,6,48.9,13.8,68.7,21.7l0-0.8c-19.6-7.8-42.7-15.5-68-21.4c-12.1-2.8-24.3-4.8-36.2-5.8c-20.7-1.8-40.9-0.7-59.8,3.1
      c-26.2,5.3-60.3,14.7-83.8,23.2c-11.2,4.1-22.2,8.2-33,12.1c-23.1,8.4-48.9,16.1-76.7,22.3c-41.6,9.3-93.5,14.9-141,13.6
      c-6.9-0.2-13.7-0.6-20.3-1.1c-35-3-67.1-10.9-97.4-20.8c-3.8-1.2-7.6-2.5-11.3-3.9c-20.9-7.4-41-15.1-61.2-22.5
      c-19.5-7.2-38.4-11.6-58.5-17c-5.3-1.4-10.6-2.8-15.9-4.2l0,0.7c5,1.3,10,2.7,15.1,4c20.2,5.4,38.9,9.8,58.3,16.9
      c20.2,7.4,40.3,15.1,61.2,22.5c3.8,1.3,7.5,2.6,11.4,3.9c30.4,10,62.7,17.9,98,20.9c6.7,0.6,13.6,1,20.5,1.1
      c47.8,1.3,99.9-4.3,141.7-13.7C412.5,232.8,438.4,225.2,461.6,216.7z M467.7,217.3c10.8-4,21.8-8.1,33-12.2
      c23.3-8.5,57.4-17.9,83.6-23.2c18.4-3.7,37.9-4.6,58-2.9c11.9,1,24.1,3,36.3,5.9c23.1,5.4,44.3,12.4,62.7,19.6l0-0.8
      c-18.2-7-39.1-13.9-62-19.3c-12.3-2.9-24.6-4.9-36.8-5.9c-20.4-1.7-40.3-0.7-58.9,3c-26.3,5.3-60.5,14.7-83.9,23.3
      c-11.2,4.1-22.2,8.2-33,12.2c-23,8.5-48.8,16.2-76.5,22.5c-41.5,9.5-93.4,15.2-141,14c-7.2-0.2-14.3-0.6-21.2-1.2
      c-34.7-2.9-66.7-10.7-96.8-20.6c-3.8-1.2-7.6-2.5-11.4-3.9c-20.9-7.5-41-15.2-61.2-22.6c-19.5-7.2-38.3-11.8-58.3-17.2
      c-7.3-2-14.6-4-21.9-5.9l0,0.7c7,1.9,14,3.8,21.1,5.7c20.1,5.4,38.8,10,58.2,17.1c20.2,7.4,40.3,15.2,61.2,22.6
      c3.8,1.3,7.6,2.6,11.4,3.9c30.2,9.9,62.4,17.8,97.4,20.7c7,0.6,14.2,1,21.4,1.2c47.9,1.2,100.1-4.6,141.8-14.1
      C418.8,233.5,444.6,225.8,467.7,217.3z M473.8,217.9c10.8-4,21.8-8.2,32.9-12.3c23.2-8.6,57.5-18,83.8-23.2
      c18.1-3.6,37.3-4.5,57.1-2.8c12.1,1,24.5,3,36.9,6c20.7,4.9,39.8,11.1,56.8,17.5l0-0.8c-16.8-6.3-35.6-12.3-56-17.2
      c-12.5-3-25-5-37.3-6.1c-20.1-1.7-39.6-0.8-58,2.9c-26.4,5.2-60.7,14.7-84.1,23.3c-11.2,4.1-22.1,8.2-32.9,12.3
      c-23,8.6-48.7,16.3-76.3,22.7c-41.4,9.6-93.3,15.4-141,14.4c-7.5-0.2-14.9-0.6-22.2-1.2c-34.5-2.9-66.3-10.6-96.3-20.4
      c-3.8-1.3-7.6-2.6-11.4-3.9c-20.9-7.5-41-15.2-61.2-22.7C45,199.1,26.3,194.5,6.3,189c-9.4-2.6-18.7-5.2-27.9-7.6l0,0.7
      c9,2.4,18,4.9,27.1,7.4c20,5.5,38.7,10.1,58.1,17.3c20.1,7.5,40.3,15.2,61.2,22.7c3.8,1.4,7.6,2.7,11.4,3.9
      c30.1,9.8,62.1,17.6,96.9,20.5c7.3,0.6,14.8,1,22.4,1.2c48,1.1,100.2-4.8,141.8-14.4C425,234.3,450.8,226.5,473.8,217.9z
       M479.9,218.7c10.8-4,21.7-8.2,32.9-12.3c23.2-8.6,57.6-18.1,83.9-23.2c17.8-3.5,36.7-4.3,56.2-2.7c12.3,1,24.8,3.1,37.5,6.2
      c18.4,4.4,35.4,9.9,50.9,15.5l0-0.8c-15.3-5.6-32.1-10.9-50.2-15.3c-12.7-3.1-25.4-5.2-37.9-6.2c-19.7-1.7-39-0.8-57.1,2.7
      c-26.5,5.2-61,14.7-84.3,23.3c-11.1,4.1-22.1,8.3-32.9,12.3c-23,8.6-48.6,16.5-76.2,23c-41.3,9.7-93.3,15.7-141.1,14.7
      c-7.8-0.2-15.5-0.6-23.1-1.2c-34.2-2.9-65.9-10.5-95.8-20.2c-3.8-1.3-7.6-2.6-11.4-3.9c-20.9-7.5-41.1-15.3-61.2-22.8
      c-19.4-7.3-38.1-12-58.1-17.5c-11.4-3.2-22.6-6.4-33.8-9.4l0,0.7c10.9,2.9,21.9,6,33,9.1c20,5.6,38.6,10.3,57.9,17.5
      c20.1,7.5,40.3,15.3,61.2,22.8c3.8,1.4,7.6,2.7,11.4,3.9c29.9,9.8,61.8,17.4,96.4,20.3c7.6,0.6,15.4,1.1,23.3,1.2
      c48.2,1,100.4-5,141.8-14.8C431.1,235.2,456.8,227.3,479.9,218.7z M485.9,219.5c10.8-4.1,21.7-8.3,32.8-12.4
      c23.1-8.6,57.6-18.2,84.1-23.2c17.6-3.4,36.2-4.2,55.3-2.6c12.5,1.1,25.2,3.2,38,6.3c16.1,3.9,31.2,8.7,45.1,13.6l0-0.8
      c-13.7-4.8-28.5-9.5-44.3-13.3c-12.9-3.2-25.8-5.3-38.5-6.4c-19.4-1.6-38.4-0.8-56.2,2.6c-26.6,5.1-61.2,14.7-84.4,23.3
      c-11.1,4.2-22,8.3-32.8,12.4c-22.9,8.7-48.5,16.6-76,23.2c-41.1,9.8-93.2,16-141.1,15.1c-8.2-0.1-16.2-0.6-24-1.2
      c-34-2.9-65.6-10.4-95.2-20c-3.8-1.3-7.6-2.6-11.4-3.9c-21-7.5-41.1-15.3-61.2-22.9C56.5,202,37.8,197.2,18,191.5
      c-13.2-3.8-26.5-7.1-39.6-10.2l0,0.7c12.9,3,25.9,6.4,38.8,10c19.9,5.6,38.5,10.4,57.8,17.7c20.1,7.6,40.3,15.4,61.2,22.9
      c3.8,1.4,7.6,2.7,11.5,3.9c29.7,9.7,61.5,17.2,95.8,20.2c7.9,0.7,16,1.1,24.2,1.2c48.3,0.9,100.5-5.3,141.9-15.2
      C437.2,236.2,462.9,228.2,485.9,219.5z M491.8,220.4c10.8-4.1,21.7-8.3,32.8-12.5c23-8.7,57.7-18.2,84.2-23.3
      c17.3-3.3,35.6-4.1,54.4-2.5c12.7,1.1,25.6,3.2,38.6,6.5c13.9,3.4,27.1,7.5,39.3,11.7l0-0.8c-12.1-4.1-24.9-8.1-38.6-11.4
      c-13.1-3.2-26.1-5.4-39-6.5c-19.1-1.6-37.7-0.8-55.3,2.5c-26.7,5.1-61.4,14.7-84.6,23.4c-11.1,4.2-22,8.4-32.8,12.5
      c-22.9,8.7-48.4,16.7-75.9,23.4c-41,10-93.1,16.2-141.2,15.5c-8.5-0.1-16.8-0.6-25-1.3c-33.8-2.9-65.2-10.2-94.7-19.8
      c-3.9-1.3-7.7-2.6-11.4-3.9c-21-7.5-41.1-15.4-61.2-23c-19.4-7.3-38-12.2-57.8-17.9c-15.2-4.4-30.4-8.3-45.4-11.8l0,0.7
      c14.8,3.5,29.7,7.3,44.6,11.6c19.8,5.7,38.4,10.6,57.6,17.9c20.1,7.6,40.2,15.4,61.3,23c3.8,1.4,7.6,2.7,11.5,3.9
      c29.6,9.7,61.2,17.1,95.3,20c8.2,0.7,16.6,1.1,25.2,1.3c48.4,0.8,100.7-5.5,141.9-15.5C443.3,237.2,468.8,229.2,491.8,220.4z
       M497.7,221.4c10.8-4.1,21.6-8.4,32.7-12.6c23-8.7,57.8-18.3,84.4-23.3c17-3.2,35-3.9,53.5-2.4c12.9,1.1,26,3.3,39.1,6.6
      c11.8,3,23,6.3,33.6,9.9l0-0.8c-10.4-3.5-21.4-6.7-32.9-9.6c-13.3-3.3-26.5-5.6-39.6-6.7c-18.8-1.6-37.1-0.8-54.4,2.4
      c-26.8,5-61.7,14.6-84.8,23.4c-11.1,4.2-22,8.4-32.7,12.6c-22.9,8.8-48.3,16.9-75.7,23.6c-40.9,10.1-93,16.5-141.2,15.8
      c-8.8-0.1-17.4-0.6-25.9-1.3c-33.5-2.8-64.8-10.1-94.1-19.7c-3.8-1.3-7.7-2.6-11.4-3.9c-21-7.6-41.2-15.4-61.2-23.1
      c-19.3-7.4-37.9-12.3-57.6-18.1c-17.1-5-34.3-9.5-51.2-13.4l0,0.7c16.6,3.9,33.5,8.3,50.4,13.2c19.7,5.8,38.3,10.7,57.5,18
      c20.1,7.6,40.2,15.5,61.3,23.1c3.8,1.4,7.6,2.7,11.5,3.9c29.4,9.6,60.8,16.9,94.7,19.8c8.6,0.7,17.3,1.2,26.2,1.3
      c48.6,0.7,100.8-5.8,141.9-15.9C449.3,238.3,474.8,230.2,497.7,221.4z M503.5,222.5c10.7-4.2,21.6-8.4,32.7-12.6
      c22.9-8.7,57.8-18.4,84.5-23.3c16.8-3.1,34.5-3.8,52.6-2.3c13.1,1.1,26.3,3.4,39.7,6.8c9.7,2.5,19.1,5.2,28,8.1l0-0.8
      c-8.7-2.8-17.8-5.5-27.2-7.9c-13.4-3.4-26.9-5.7-40.1-6.8c-18.4-1.6-36.5-0.8-53.5,2.3c-26.9,5-61.9,14.6-84.9,23.4
      c-11.1,4.2-21.9,8.5-32.7,12.6c-22.8,8.8-48.2,17-75.5,23.8c-40.8,10.2-92.9,16.7-141.2,16.2c-9.1-0.1-18.1-0.5-26.9-1.3
      c-33.3-2.8-64.4-10-93.5-19.5c-3.9-1.3-7.7-2.6-11.5-3.9c-21-7.6-41.2-15.5-61.2-23.2c-19.3-7.4-37.9-12.4-57.5-18.3
      c-19-5.7-38.2-10.6-56.9-15l0,0.7c18.5,4.3,37.3,9.2,56,14.8c19.6,5.9,38.1,10.9,57.3,18.2c20,7.7,40.2,15.6,61.3,23.2
      c3.8,1.4,7.6,2.7,11.5,4c29.2,9.5,60.5,16.7,94.1,19.6c8.9,0.8,17.9,1.2,27.1,1.3c48.7,0.6,101-6,142-16.3
      C455.2,239.5,480.6,231.3,503.5,222.5z M509.3,223.5c10.7-4.2,21.5-8.5,32.6-12.7c22.8-8.8,57.9-18.5,84.7-23.3
      c16.5-3,33.9-3.7,51.8-2.2c13.2,1.1,26.7,3.4,40.2,6.9c7.7,2,15.2,4.2,22.4,6.5l0-0.8c-7-2.2-14.2-4.3-21.6-6.2
      c-13.6-3.5-27.3-5.9-40.7-7c-18.1-1.5-35.8-0.9-52.6,2.2c-27,4.9-62.1,14.6-85.1,23.4c-11.1,4.2-21.9,8.5-32.6,12.7
      c-22.8,8.9-48.1,17.1-75.4,24.1c-40.7,10.3-92.8,17-141.3,16.5c-9.4-0.1-18.7-0.5-27.8-1.3c-33.1-2.8-64-9.9-93-19.3
      c-3.9-1.3-7.7-2.6-11.5-3.9c-21.1-7.6-41.2-15.5-61.2-23.3c-19.3-7.4-37.8-12.5-57.3-18.5c-21-6.3-42-11.9-62.5-16.6l0,0.7
      c20.2,4.7,41,10.2,61.7,16.4c19.6,5.9,38,11,57.2,18.4c20,7.7,40.2,15.7,61.3,23.3c3.8,1.4,7.6,2.7,11.5,4
      c29,9.5,60.2,16.6,93.6,19.4c9.2,0.8,18.6,1.2,28.1,1.3c48.8,0.5,101.1-6.3,142-16.6C461.1,240.7,486.5,232.5,509.3,223.5z
       M515.1,224.7c10.7-4.2,21.5-8.5,32.6-12.8c22.8-8.8,58-18.5,84.9-23.4c16.2-2.9,33.4-3.5,50.9-2.1c13.4,1.1,27.1,3.5,40.8,7.1
      c5.8,1.5,11.3,3.1,16.8,4.8l0-0.7c-5.2-1.6-10.5-3.2-16.1-4.6c-13.8-3.6-27.7-6-41.3-7.2c-17.8-1.5-35.2-0.9-51.7,2.1
      c-27.1,4.8-62.3,14.6-85.3,23.5c-11.1,4.3-21.9,8.6-32.6,12.8c-22.8,9-48,17.3-75.2,24.3c-40.6,10.4-92.7,17.3-141.3,16.9
      c-9.8-0.1-19.4-0.5-28.8-1.3c-32.8-2.8-63.6-9.7-92.4-19.1c-3.9-1.3-7.7-2.6-11.5-4c-21.1-7.6-41.2-15.6-61.2-23.4
      C84.4,210.2,66,205,46.5,199c-22.9-7-45.9-13.1-68.2-18.2l0,0.7c22,5.1,44.7,11.1,67.3,18c19.5,6,37.9,11.2,57,18.6
      c20,7.8,40.2,15.7,61.3,23.4c3.8,1.4,7.7,2.7,11.6,4c28.9,9.4,59.8,16.4,93,19.2c9.5,0.8,19.2,1.3,29,1.3
      c48.9,0.3,101.3-6.5,142.1-17C467,242,492.2,233.6,515.1,224.7z M520.8,225.8c10.7-4.2,21.5-8.6,32.5-12.9
      c22.7-8.8,58-18.6,85-23.4c16-2.8,32.8-3.4,50-2c13.6,1.2,27.5,3.6,41.3,7.3c3.9,1,7.6,2.1,11.3,3.2l0-0.7c-3.5-1-7-2-10.5-3
      c-14-3.7-28-6.2-41.8-7.3c-17.5-1.5-34.6-0.9-50.8,2c-27.2,4.8-62.5,14.6-85.4,23.5c-11,4.3-21.8,8.6-32.5,12.9
      c-22.8,9-47.9,17.4-75.1,24.5c-40.4,10.6-92.6,17.5-141.4,17.3c-10.1,0-20-0.5-29.7-1.3c-32.6-2.8-63.2-9.6-91.8-18.9
      c-3.9-1.3-7.7-2.6-11.5-4c-21.1-7.6-41.3-15.6-61.3-23.4c-19.2-7.5-37.6-12.8-57-18.8c-24.8-7.7-49.7-14.4-73.7-19.9l0,0.7
      c23.7,5.5,48.4,12.1,72.9,19.7c19.4,6.1,37.8,11.3,56.9,18.8c20,7.8,40.1,15.8,61.3,23.5c3.8,1.4,7.7,2.7,11.6,4
      c28.7,9.3,59.5,16.3,92.4,19c9.8,0.8,19.8,1.3,30,1.3c49.1,0.2,101.4-6.7,142.1-17.4C472.8,243.2,498,234.8,520.8,225.8z
       M526.5,226.9c10.7-4.3,21.5-8.6,32.5-12.9c22.6-8.9,58.1-18.7,85.1-23.4c15.7-2.7,32.2-3.3,49.1-1.9c13.8,1.2,27.8,3.7,41.9,7.4
      c2,0.5,3.9,1.1,5.8,1.7l0-0.7c-1.7-0.5-3.3-1-5.1-1.4c-14.2-3.8-28.4-6.3-42.4-7.5c-17.2-1.5-34-0.9-49.9,1.9
      c-27.3,4.7-62.8,14.6-85.6,23.5c-11,4.3-21.8,8.7-32.5,12.9c-22.7,9.1-47.8,17.5-74.9,24.7c-40.3,10.7-92.5,17.8-141.4,17.6
      c-10.4,0-20.6-0.5-30.7-1.3c-32.4-2.7-62.9-9.5-91.2-18.8c-3.9-1.3-7.7-2.6-11.5-4c-21.1-7.7-41.3-15.7-61.3-23.5
      c-19.1-7.5-37.6-12.9-56.9-19c-26.7-8.5-53.6-15.7-79.3-21.6l0,0.7c25.4,5.9,52,13,78.4,21.4c19.4,6.1,37.7,11.5,56.8,19
      c20,7.9,40.1,15.9,61.3,23.6c3.8,1.4,7.7,2.7,11.6,4c28.5,9.3,59.2,16.1,91.8,18.9c10.1,0.9,20.5,1.3,31,1.4
      c49.2,0.1,101.6-7,142.2-17.7C478.6,244.5,503.7,236,526.5,226.9z M532.1,228.1c10.7-4.3,21.4-8.7,32.4-13
      c22.6-8.9,58.1-18.8,85.3-23.4c15.4-2.6,31.7-3.2,48.2-1.8c14,1.2,28.2,3.7,42.5,7.6c0.1,0,0.3,0.1,0.4,0.1l0-0.7
      c-14.2-3.8-28.5-6.4-42.5-7.6c-16.8-1.4-33.4-0.9-49.1,1.8c-27.4,4.7-63,14.6-85.7,23.5c-11,4.3-21.8,8.7-32.4,13
      c-22.7,9.1-47.7,17.7-74.8,24.9c-40.2,10.8-92.4,18-141.4,18c-10.7,0-21.3-0.5-31.7-1.4c-32.2-2.7-62.4-9.4-90.7-18.6
      c-3.9-1.3-7.7-2.6-11.6-4c-21.2-7.7-41.3-15.7-61.3-23.6c-19.1-7.6-37.5-13-56.7-19.2c-28.7-9.2-57.5-17-84.8-23.3l0,0.7
      c27.1,6.3,55.6,14,83.9,23.1c19.3,6.2,37.6,11.6,56.6,19.1c19.9,7.9,40.1,16,61.3,23.6c3.8,1.4,7.7,2.7,11.6,4
      c28.3,9.2,58.8,15.9,91.3,18.7c10.5,0.9,21.1,1.4,31.9,1.4c49.3,0,101.8-7.2,142.2-18.1C484.3,245.8,509.4,237.3,532.1,228.1z
       M537.8,229.2c10.6-4.3,21.4-8.7,32.4-13.1c22.5-8.9,58.2-18.9,85.5-23.5c15.2-2.5,31.1-3,47.3-1.7c12.5,1.1,25.3,3.3,38,6.5l0-0.8
      c-12.6-3.1-25.2-5.3-37.7-6.3c-16.5-1.4-32.7-0.9-48.2,1.7c-27.5,4.6-63.2,14.6-85.9,23.6c-11,4.4-21.7,8.8-32.4,13.1
      c-22.7,9.2-47.6,17.8-74.6,25.2c-40.1,10.9-92.4,18.3-141.5,18.4c-11.1,0-22-0.5-32.6-1.4c-31.9-2.7-62.1-9.3-90.1-18.4
      c-3.9-1.3-7.8-2.6-11.6-4c-21.2-7.7-41.4-15.8-61.3-23.7c-19.1-7.6-37.4-13.1-56.6-19.4c-30.6-10-61.3-18.4-90.3-25.1l0,0.7
      c28.7,6.7,59.1,15,89.4,24.9c19.2,6.3,37.5,11.8,56.5,19.3c19.9,7.9,40.1,16,61.3,23.7c3.8,1.4,7.7,2.7,11.6,4
      c28.1,9.1,58.5,15.8,90.6,18.5c10.8,0.9,21.7,1.4,32.9,1.4c49.4-0.1,101.9-7.5,142.2-18.4C490,247.1,515,238.4,537.8,229.2z
       M543.4,230.3c10.6-4.3,21.4-8.8,32.3-13.2c22.5-9,58.3-19,85.6-23.5c14.9-2.5,30.5-2.9,46.5-1.6c10.9,0.9,22,2.8,33.2,5.4l0-0.7
      c-11-2.5-22-4.4-32.9-5.3c-16.2-1.4-32.1-0.9-47.3,1.6c-27.6,4.6-63.4,14.6-86.1,23.6c-11,4.4-21.7,8.8-32.3,13.2
      c-22.6,9.3-47.5,17.9-74.4,25.4c-40,11.1-92.3,18.6-141.5,18.7c-11.4,0-22.6-0.4-33.6-1.4c-31.7-2.7-61.6-9.2-89.5-18.2
      c-3.9-1.3-7.8-2.6-11.6-4c-21.2-7.7-41.4-15.8-61.3-23.8c-19.1-7.6-37.3-13.2-56.4-19.5c-32.5-10.8-65.2-19.8-95.7-26.9l0,0.7
      c30.2,7.1,62.6,16,94.8,26.7c19.1,6.3,37.4,11.9,56.3,19.5c19.9,8,40.1,16.1,61.3,23.8c3.8,1.4,7.7,2.7,11.7,4
      c28,9.1,58.1,15.6,90.1,18.3c11.1,0.9,22.4,1.4,33.9,1.4c49.6-0.2,102.1-7.7,142.3-18.8C495.7,248.3,520.7,239.6,543.4,230.3z
       M474.5,257.1c26.9-7.6,51.8-16.3,74.4-25.7c10.6-4.4,21.3-8.8,32.3-13.2c22.4-9,58.3-19,85.8-23.5c14.7-2.4,30-2.8,45.6-1.5
      c9.4,0.8,18.9,2.3,28.4,4.3l0-0.7c-9.4-2-18.8-3.4-28.1-4.2c-15.9-1.3-31.5-0.9-46.4,1.5c-27.7,4.5-63.7,14.6-86.2,23.6
      c-11,4.4-21.7,8.9-32.3,13.2c-22.6,9.3-47.4,18.1-74.3,25.6c-39.9,11.2-92.2,18.8-141.5,19.1c-11.7,0.1-23.3-0.4-34.6-1.4
      c-31.5-2.7-61.3-9.1-88.9-18.1c-3.9-1.3-7.8-2.6-11.6-4c-21.3-7.7-41.4-15.9-61.3-23.9c-19-7.7-37.3-13.3-56.3-19.7
      C45,197,10.3,187.4-21.7,179.8l0,0.7c31.7,7.5,66.1,17.1,100.3,28.5c19.1,6.4,37.3,12.1,56.2,19.7c19.9,8,40,16.2,61.4,23.9
      c3.9,1.4,7.7,2.7,11.7,4c27.8,9,57.7,15.5,89.5,18.2c11.4,1,23,1.4,34.9,1.4C381.9,276,434.4,268.3,474.5,257.1z M480.2,258.3
      c26.9-7.6,51.7-16.5,74.3-25.9c10.6-4.4,21.3-8.9,32.2-13.3c22.3-9,58.4-19.1,85.9-23.5c14.4-2.3,29.4-2.7,44.8-1.4
      c7.8,0.7,15.7,1.8,23.6,3.3l0-0.7c-7.8-1.5-15.6-2.6-23.3-3.2c-15.6-1.3-30.9-0.9-45.6,1.4c-27.8,4.4-63.9,14.6-86.4,23.7
      c-10.9,4.4-21.6,8.9-32.2,13.3c-22.6,9.4-47.3,18.2-74.1,25.8c-39.8,11.3-92.1,19.1-141.6,19.4c-12.1,0.1-23.9-0.4-35.5-1.4
      c-31.3-2.6-60.8-9-88.3-17.9c-3.9-1.3-7.8-2.6-11.6-4c-21.3-7.8-41.4-15.9-61.3-24c-19-7.7-37.2-13.4-56.1-19.9
      c-36.4-12.4-73.1-22.7-106.6-30.6l0,0.7c33.2,7.9,69.5,18.1,105.6,30.4c19,6.5,37.1,12.2,56.1,19.9c19.9,8.1,40,16.2,61.4,24
      c3.9,1.4,7.8,2.8,11.7,4c27.6,9,57.4,15.3,88.9,18c11.7,1,23.7,1.5,35.8,1.4C387.6,277.5,440.2,269.7,480.2,258.3z M485.8,259.5
      c26.8-7.7,51.5-16.7,74.1-26.1c10.6-4.4,21.2-8.9,32.2-13.4c22.3-9.1,58.4-19.2,86.1-23.6c14.1-2.2,28.9-2.6,43.9-1.3
      c6.2,0.5,12.5,1.4,18.8,2.4l0-0.6c-6.2-1.1-12.4-1.9-18.5-2.4c-15.3-1.3-30.3-0.9-44.7,1.3c-27.9,4.4-64.1,14.5-86.5,23.7
      c-10.9,4.4-21.6,9-32.2,13.4c-22.6,9.4-47.2,18.3-73.9,26c-39.6,11.4-92,19.3-141.6,19.8c-12.4,0.1-24.6-0.4-36.5-1.4
      c-31-2.6-60.5-8.9-87.7-17.7c-3.9-1.3-7.8-2.6-11.7-4c-21.3-7.8-41.4-16-61.3-24.1c-19-7.7-37.1-13.6-56-20.1
      c-38.4-13.3-77.1-24.3-112-32.6l0,0.7c34.6,8.3,73,19.2,111,32.3c18.9,6.5,37,12.4,55.9,20.1c19.8,8.1,40,16.3,61.4,24.1
      c3.9,1.4,7.8,2.8,11.7,4c27.4,8.9,57,15.2,88.3,17.8c12,1,24.3,1.5,36.8,1.4C393.4,279,446,271,485.8,259.5z M491.5,260.6
      c26.8-7.8,51.4-16.8,74-26.3c10.6-4.5,21.2-9,32.1-13.5c22.2-9.1,58.5-19.3,86.2-23.6c13.9-2.1,28.3-2.5,43-1.2
      c4.7,0.4,9.4,1,14.1,1.7l0-0.6c-4.6-0.7-9.2-1.3-13.8-1.7c-15-1.3-29.7-0.9-43.8,1.3c-28,4.3-64.3,14.5-86.7,23.7
      c-10.9,4.5-21.6,9-32.1,13.5c-22.5,9.5-47.1,18.5-73.8,26.3c-39.5,11.5-91.9,19.6-141.7,20.2c-12.7,0.1-25.2-0.3-37.5-1.4
      c-30.8-2.6-60-8.8-87.1-17.6c-3.9-1.3-7.8-2.6-11.7-4c-21.3-7.8-41.5-16-61.3-24.2c-18.9-7.8-37-13.7-55.8-20.3
      c-39.8-14-79.9-25.5-115.8-34.2c-0.5-0.1-1-0.3-1.6-0.4l0,0.7c0.3,0.1,0.6,0.1,0.8,0.2c35.8,8.6,75.8,20.2,115.6,34.1
      c18.9,6.6,36.9,12.5,55.8,20.2c19.8,8.1,40,16.4,61.4,24.2c3.9,1.4,7.8,2.8,11.7,4c27.2,8.8,56.7,15,87.7,17.7
      c12.4,1.1,25,1.5,37.8,1.4C399.2,280.3,451.8,272.2,491.5,260.6z M497.2,261.7c26.7-7.9,51.3-17,73.8-26.5c10.5-4.5,21.2-9,32-13.5
      c22.2-9.1,58.6-19.4,86.4-23.6c13.6-2.1,27.7-2.4,42.2-1.2c3.1,0.3,6.2,0.6,9.4,1l0-0.6c-3-0.4-6.1-0.7-9.1-1
      c-14.7-1.2-29.1-0.9-42.9,1.2c-28.1,4.3-64.6,14.5-86.9,23.7c-10.9,4.5-21.5,9.1-32.1,13.5c-22.5,9.5-47,18.6-73.6,26.5
      c-39.4,11.7-91.8,19.9-141.7,20.5c-13,0.2-25.9-0.3-38.5-1.4c-30.6-2.6-59.6-8.7-86.6-17.4c-3.9-1.3-7.8-2.6-11.7-4
      c-21.4-7.8-41.5-16.1-61.3-24.3c-18.9-7.8-36.9-13.8-55.7-20.5c-39.7-14.1-79.6-25.9-115.4-34.8c-2.5-0.6-4.9-1.2-7.3-1.9l0,0.7
      c2.2,0.6,4.4,1.1,6.6,1.7c35.7,8.9,75.6,20.6,115.2,34.8c18.8,6.7,36.8,12.6,55.6,20.4c19.8,8.2,40,16.5,61.4,24.3
      c3.9,1.4,7.8,2.8,11.8,4.1c27.1,8.8,56.3,14.9,87.1,17.5c12.7,1.1,25.6,1.6,38.8,1.4C404.9,281.6,457.5,273.4,497.2,261.7z
       M502.8,262.6c26.6-8,51.1-17.1,73.6-26.8c10.6-4.5,21.1-9.1,32-13.6c22.1-9.2,58.6-19.5,86.6-23.6c13.3-2,27.2-2.3,41.3-1.1
      c1.6,0.1,3.1,0.3,4.7,0.5l0-0.6c-1.5-0.2-2.9-0.3-4.4-0.4c-14.3-1.2-28.5-0.9-42.1,1.1c-28.2,4.2-64.8,14.5-87.1,23.8
      c-10.9,4.5-21.5,9.1-32,13.6c-22.5,9.6-46.9,18.7-73.5,26.7c-39.3,11.8-91.7,20.1-141.7,20.9c-13.4,0.2-26.5-0.3-39.4-1.4
      c-30.4-2.6-59.2-8.6-86-17.2c-4-1.3-7.9-2.6-11.7-4c-21.4-7.8-41.6-16.1-61.3-24.4c-18.9-7.8-36.9-13.9-55.6-20.6
      c-39.5-14.3-79.3-26.3-115-35.4c-4.4-1.1-8.8-2.3-13.1-3.4l0,0.7c4.1,1.1,8.2,2.2,12.3,3.2c35.6,9.1,75.3,21.1,114.8,35.4
      c18.7,6.8,36.7,12.8,55.5,20.6c19.8,8.2,40,16.5,61.4,24.4c3.9,1.4,7.8,2.8,11.8,4.1c26.9,8.7,56,14.8,86.6,17.3
      c13,1.1,26.3,1.6,39.8,1.4C410.6,282.9,463.3,274.5,502.8,262.6z M508.4,263.5c26.6-8.1,51-17.3,73.5-27c10.5-4.5,21.1-9.1,32-13.7
      c22-9.2,58.7-19.6,86.7-23.7c13-1.9,26.6-2.2,40.4-1l0-0.6c-13.9-1.2-27.7-0.9-40.9,1.1c-28.3,4.2-65,14.5-87.2,23.8
      c-10.9,4.5-21.4,9.2-32,13.7c-22.4,9.7-46.8,18.9-73.3,26.9c-39.2,11.9-91.6,20.4-141.8,21.3c-13.7,0.2-27.2-0.2-40.4-1.4
      c-30.1-2.5-58.8-8.5-85.4-17.1c-4-1.3-7.9-2.6-11.7-4c-21.4-7.9-41.6-16.2-61.3-24.4c-18.8-7.9-36.8-14-55.4-20.8
      c-39.4-14.4-79.1-26.7-114.6-36c-6.3-1.7-12.6-3.4-18.9-5.1l0,0.7c6,1.7,12,3.3,18.1,4.9c35.5,9.3,75.1,21.6,114.4,36
      c18.6,6.8,36.6,12.9,55.3,20.8c19.8,8.3,39.9,16.6,61.4,24.5c3.9,1.4,7.8,2.8,11.8,4.1c26.7,8.6,55.6,14.6,86,17.2
      c13.3,1.1,26.9,1.6,40.7,1.4C416.3,284,469,275.5,508.4,263.5z M514,264.2c26.5-8.2,50.9-17.5,73.3-27.2
      c10.5-4.6,21.1-9.2,31.9-13.7c22-9.2,58.8-19.6,86.9-23.7c11.3-1.6,23-2,34.9-1.3l0-0.6c-12-0.7-23.9-0.3-35.3,1.3
      c-28.4,4.1-65.2,14.5-87.4,23.8c-10.8,4.6-21.4,9.2-31.9,13.8c-22.4,9.7-46.7,19-73.2,27.1c-39.1,12-91.6,20.6-141.8,21.6
      c-14,0.3-27.8-0.2-41.4-1.3c-29.9-2.5-58.4-8.4-84.8-16.9c-4-1.3-7.9-2.6-11.8-4.1c-21.5-7.9-41.6-16.2-61.3-24.5
      c-18.8-7.9-36.7-14.1-55.3-21c-39.3-14.6-78.8-27.1-114.2-36.6c-8.3-2.2-16.5-4.6-24.6-6.9l0,0.7c7.9,2.3,15.8,4.5,23.9,6.7
      c35.4,9.6,74.8,22,114.1,36.6c18.5,6.9,36.5,13.1,55.2,21c19.7,8.3,39.9,16.7,61.4,24.6c3.9,1.4,7.8,2.8,11.8,4.1
      c26.5,8.6,55.2,14.5,85.4,17c13.6,1.2,27.6,1.6,41.7,1.3C422,285,474.7,276.3,514,264.2z M519.6,264.8
      c26.5-8.3,50.8-17.6,73.2-27.4c10.5-4.6,21.1-9.2,31.9-13.8c21.9-9.3,58.8-19.7,87-23.7c9.5-1.3,19.4-1.7,29.4-1.3l0-0.6
      c-10.1-0.4-20.1,0-29.8,1.4c-28.5,4-65.5,14.5-87.5,23.8c-10.8,4.6-21.4,9.2-31.9,13.8c-22.4,9.8-46.6,19.1-73,27.4
      c-39,12.1-91.5,20.9-141.9,22c-14.3,0.3-28.5-0.2-42.3-1.3c-29.7-2.5-58-8.3-84.2-16.8c-4-1.3-7.9-2.6-11.8-4.1
      c-21.5-7.9-41.6-16.3-61.4-24.6c-18.7-7.9-36.6-14.2-55.1-21.2C83,203.6,43.6,190.9,8.3,181.1c-10.3-2.8-20.3-5.8-30.4-8.8l0,0.7
      c9.8,2.9,19.6,5.8,29.6,8.6c35.3,9.8,74.6,22.5,113.7,37.2c18.5,7,36.4,13.2,55.1,21.2c19.7,8.4,39.9,16.7,61.4,24.7
      c3.9,1.4,7.8,2.8,11.8,4.1c26.3,8.5,54.9,14.3,84.8,16.9c13.9,1.2,28.2,1.6,42.7,1.3C427.7,285.8,480.5,277.1,519.6,264.8z
       M525.2,265.3c26.4-8.3,50.6-17.8,73-27.6c10.5-4.6,21-9.3,31.8-13.9c21.8-9.3,58.9-19.8,87.2-23.7c7.8-1.1,15.7-1.5,23.8-1.4
      l0-0.6c-8.2-0.1-16.3,0.4-24.3,1.5c-28.6,4-65.7,14.5-87.7,23.9c-10.8,4.6-21.3,9.3-31.8,13.9c-22.3,9.8-46.5,19.3-72.8,27.6
      c-38.9,12.3-91.4,21.2-141.9,22.3c-14.7,0.3-29.2-0.1-43.3-1.3c-29.5-2.5-57.6-8.2-83.6-16.6c-4-1.3-7.9-2.6-11.8-4.1
      c-21.5-7.9-41.7-16.3-61.4-24.7c-18.7-8-36.6-14.4-55-21.4c-39-14.9-78.2-27.8-113.4-37.9c-12.2-3.5-24.2-7.1-36.2-10.8l0,0.7
      c11.7,3.6,23.4,7.1,35.4,10.6c35.2,10,74.3,22.9,113.3,37.8c18.4,7,36.2,13.4,54.9,21.4c19.7,8.4,39.9,16.8,61.4,24.8
      c3.9,1.4,7.9,2.8,11.8,4.1c26.1,8.4,54.5,14.2,84.2,16.7c14.3,1.2,28.9,1.7,43.7,1.3C433.4,286.6,486.1,277.7,525.2,265.3z
       M530.8,265.6c26.3-8.4,50.5-17.9,72.8-27.9c10.5-4.6,21-9.4,31.7-14c21.8-9.3,58.9-19.9,87.3-23.8c6-0.8,12.1-1.2,18.3-1.3l0-0.6
      c-6.3,0.1-12.6,0.5-18.7,1.4c-28.7,3.9-65.9,14.5-87.9,23.9c-10.8,4.6-21.3,9.3-31.8,14c-22.3,9.9-46.4,19.4-72.7,27.8
      c-38.7,12.4-91.3,21.4-141.9,22.7c-15,0.4-29.8-0.1-44.3-1.3c-29.3-2.5-57.2-8.1-83.1-16.5c-4-1.3-7.9-2.6-11.8-4.1
      c-21.6-7.9-41.7-16.4-61.4-24.8c-18.7-8-36.5-14.5-54.8-21.6c-38.9-15.1-77.9-28.2-113-38.5c-14.2-4.1-28-8.5-42-12.8l0,0.7
      c13.7,4.2,27.3,8.5,41.2,12.6C54,192,92.9,205.1,131.7,220.1c18.3,7.1,36.1,13.5,54.8,21.5c19.7,8.4,39.9,16.9,61.4,24.8
      c3.9,1.4,7.9,2.8,11.9,4.1c26,8.4,54.1,14.1,83.6,16.6c14.6,1.2,29.5,1.7,44.6,1.3C439.1,287.2,491.9,278.1,530.8,265.6z
       M536.4,265.8c26.3-8.5,50.4-18.1,72.7-28.1c10.4-4.7,20.9-9.4,31.7-14c21.7-9.4,59-20,87.5-23.8c4.2-0.6,8.5-0.9,12.8-1.1l0-0.6
      c-4.4,0.2-8.9,0.6-13.2,1.1c-28.8,3.8-66.1,14.5-88,23.9c-10.8,4.7-21.3,9.4-31.7,14.1c-22.3,10-46.3,19.5-72.5,28
      c-38.6,12.5-91.2,21.7-142,23.1c-15.3,0.4-30.4,0-45.2-1.3c-29.1-2.5-56.8-8-82.5-16.3c-4-1.3-7.9-2.7-11.8-4.1
      c-21.6-8-41.7-16.4-61.4-24.9c-18.7-8.1-36.4-14.6-54.7-21.7C99.2,204.9,60.4,191.5,25.3,181c-16.1-4.8-31.9-10-47.8-15l0,0.7
      c15.7,4.9,31.1,9.9,47,14.7c35,10.5,73.8,23.8,112.5,39c18.3,7.2,36,13.7,54.6,21.7c19.7,8.5,39.8,17,61.5,24.9
      c3.9,1.4,7.9,2.8,11.9,4.1c25.8,8.3,53.8,13.9,83.1,16.4c14.9,1.3,30.2,1.7,45.6,1.3C444.8,287.6,497.6,278.4,536.4,265.8z
       M542,265.8c26.2-8.6,50.2-18.3,72.5-28.3c10.4-4.7,20.9-9.4,31.6-14.1c21.7-9.4,59-20.1,87.6-23.8c2.4-0.3,4.8-0.5,7.2-0.7l0-0.6
      c-2.6,0.2-5.1,0.4-7.6,0.8c-28.9,3.8-66.4,14.5-88.2,24c-10.8,4.7-21.2,9.4-31.7,14.1c-22.2,10-46.2,19.7-72.3,28.2
      c-38.5,12.6-91.1,21.9-142,23.4c-15.6,0.5-31.1,0-46.2-1.3c-28.9-2.4-56.5-8-81.9-16.2c-4-1.3-7.9-2.7-11.8-4.1
      c-21.6-8-41.8-16.5-61.4-25c-18.6-8.1-36.4-14.7-54.5-21.9C104.7,205,66,191.4,31,180.7c-18.1-5.6-35.7-11.5-53.7-17.1l0,0.7
      c17.7,5.6,35,11.4,52.8,16.9c35,10.7,73.5,24.3,112.1,39.6c18.2,7.2,35.9,13.8,54.5,21.9c19.6,8.5,39.8,17,61.5,25
      c3.9,1.4,7.9,2.8,11.9,4.1c25.6,8.3,53.4,13.8,82.5,16.3c15.2,1.3,30.8,1.7,46.6,1.3C450.5,287.9,503.3,278.5,542,265.8z
       M547.6,265.6c26.2-8.7,50.1-18.4,72.4-28.5c10.4-4.7,20.9-9.5,31.6-14.2c21.6-9.5,59.1-20.2,87.8-23.8c0.6-0.1,1.1-0.1,1.7-0.2
      l0-0.6c-0.7,0.1-1.4,0.1-2.1,0.2c-29.1,3.7-66.6,14.5-88.4,24c-10.7,4.7-21.2,9.5-31.6,14.2c-22.2,10.1-46.1,19.8-72.2,28.5
      c-38.4,12.7-91,22.2-142.1,23.8c-16,0.5-31.8,0.1-47.2-1.2c-28.6-2.4-56.1-7.9-81.3-16c-4-1.3-7.9-2.7-11.9-4.1
      c-21.6-8-41.8-16.5-61.4-25.1c-18.6-8.1-36.3-14.8-54.4-22.1c-38.5-15.5-76.9-29.3-111.9-40.3c-20-6.3-39.4-13-59.5-19.4l0,0.7
      c19.7,6.3,38.9,12.9,58.6,19.1c34.9,11,73.3,24.8,111.7,40.3c18.1,7.3,35.8,14,54.4,22.1c19.6,8.6,39.8,17.1,61.5,25.1
      c3.9,1.5,7.9,2.8,11.9,4.1c25.4,8.2,53.1,13.7,81.9,16.1c15.6,1.3,31.5,1.7,47.6,1.2C456.2,287.9,509,278.5,547.6,265.6z
       M553.2,265.3c26.1-8.8,50-18.6,72.2-28.7c10.4-4.7,20.9-9.6,31.6-14.3c20.6-9.1,55.8-19.3,84.1-23.3l0-0.6
      c-28.6,4-64.2,14.3-85.1,23.5c-10.7,4.7-21.2,9.5-31.6,14.3c-22.2,10.1-46,19.9-72,28.7c-38.3,12.9-90.9,22.5-142.1,24.2
      c-16.3,0.5-32.4,0.1-48.1-1.2c-28.4-2.4-55.7-7.8-80.7-15.9c-4-1.3-8-2.7-11.9-4.1c-21.7-8-41.8-16.6-61.4-25.2
      c-18.6-8.2-36.2-14.9-54.2-22.3c-38.3-15.7-76.6-29.7-111.5-40.9c-22-7.1-43.2-14.6-65.4-21.6l0,0.8c21.9,6.9,42.8,14.4,64.5,21.4
      c34.8,11.2,73,25.2,111.3,40.9c18.1,7.4,35.7,14.1,54.2,22.3c19.6,8.6,39.8,17.2,61.5,25.2c3.9,1.5,7.9,2.8,12,4.1
      c25.2,8.1,52.7,13.6,81.3,16c15.9,1.3,32.1,1.8,48.5,1.2C461.9,287.8,514.7,278.2,553.2,265.3z M558.9,264.7
      c26-8.9,49.8-18.7,72-28.9c10.4-4.8,20.8-9.6,31.5-14.3c19.2-8.5,51.4-18.1,78.6-22.5l0-0.6c-27.6,4.5-60.2,14.1-79.6,22.7
      c-10.7,4.7-21.1,9.6-31.5,14.4c-22.1,10.2-45.9,20.1-71.9,28.9c-38.2,13-90.9,22.7-142.2,24.5c-16.6,0.6-33,0.2-49.1-1.2
      c-28.2-2.4-55.3-7.7-80.2-15.7c-4-1.3-8-2.7-11.9-4.1c-21.7-8-41.8-16.6-61.4-25.3c-18.5-8.2-36.1-15-54.1-22.5
      c-38.2-15.8-76.3-30.1-111.1-41.5c-23.9-7.9-46.9-16.3-71.3-23.9l0,0.7c24,7.6,46.7,15.9,70.4,23.7c34.7,11.4,72.8,25.7,110.9,41.5
      c18,7.4,35.6,14.3,54.1,22.5c19.6,8.7,39.8,17.2,61.5,25.3c3.9,1.5,7.9,2.8,12,4.1c25.1,8.1,52.3,13.4,80.8,15.9
      c16.2,1.4,32.7,1.8,49.5,1.2C467.6,287.5,520.5,277.8,558.9,264.7z M564.5,263.9c26-8.9,49.7-18.9,71.9-29.2
      c10.4-4.8,20.8-9.7,31.5-14.4c17.8-8,47.1-16.9,73.2-21.6l0-0.6c-26.4,4.8-56.1,13.7-74.2,21.8c-10.7,4.8-21.1,9.6-31.5,14.4
      c-22.1,10.2-45.8,20.2-71.7,29.1c-38.1,13.1-90.7,23-142.2,24.9c-16.9,0.6-33.7,0.2-50-1.2c-28-2.4-54.9-7.6-79.6-15.6
      c-4-1.3-8-2.7-11.9-4.1c-21.7-8.1-41.9-16.7-61.4-25.4c-18.5-8.2-36.1-15.1-53.9-22.7c-38.1-16-76-30.5-110.7-42.1
      c-25.8-8.7-50.6-18-77.2-26.3l0,0.7c26.2,8.2,50.7,17.4,76.3,26c34.7,11.7,72.5,26.1,110.6,42.1c17.9,7.5,35.5,14.4,53.9,22.6
      c19.5,8.7,39.7,17.3,61.5,25.4c3.9,1.5,7.9,2.8,12,4.1c24.9,8,52,13.3,80.2,15.7c16.5,1.4,33.4,1.8,50.4,1.2
      C473.3,287,526.2,277.1,564.5,263.9z M570.2,262.9c25.9-9,49.6-19.1,71.7-29.4c10.4-4.8,20.8-9.7,31.4-14.5
      c16.6-7.4,43.1-15.6,67.8-20.5l0-0.6c-25,4.9-51.9,13.2-68.8,20.8c-10.7,4.8-21.1,9.7-31.4,14.5c-22.1,10.3-45.7,20.3-71.6,29.3
      c-37.9,13.2-90.7,23.2-142.2,25.2c-17.2,0.7-34.3,0.3-51-1.1c-27.8-2.4-54.5-7.6-79-15.5c-4-1.3-8-2.7-11.9-4.1
      c-21.7-8.1-41.9-16.7-61.4-25.4c-18.4-8.3-36-15.3-53.8-22.8c-37.9-16.1-75.6-30.8-110.3-42.7c-27.7-9.5-54.2-19.7-83.1-28.7l0,0.7
      C5,156.8,31.3,167,58.7,176.4c34.6,11.9,72.3,26.6,110.2,42.7c17.8,7.6,35.4,14.6,53.8,22.8c19.5,8.7,39.7,17.4,61.5,25.5
      c3.9,1.5,7.9,2.9,12,4.2c24.7,7.9,51.6,13.2,79.6,15.6c16.8,1.4,34,1.8,51.4,1.1C479.1,286.2,532,276.2,570.2,262.9z M575.8,261.7
      c25.8-9.1,49.4-19.2,71.5-29.6c10.3-4.9,20.7-9.8,31.4-14.6C694,210.6,717.9,203,741,198l0-0.7c-23.4,5-47.7,12.7-63.3,19.7
      c-10.6,4.8-21,9.7-31.4,14.6c-22.1,10.4-45.6,20.5-71.4,29.5c-37.8,13.4-90.6,23.5-142.3,25.6c-17.6,0.7-34.9,0.3-51.9-1.1
      c-27.6-2.3-54.1-7.5-78.5-15.3c-4-1.3-8-2.7-11.9-4.1c-21.8-8.1-41.9-16.8-61.4-25.5c-18.4-8.3-35.9-15.4-53.7-23
      c-37.8-16.3-75.3-31.2-109.9-43.4c-29.5-10.4-57.8-21.5-89.1-31l0,0.7c30.9,9.4,58.9,20.5,88.2,30.8c34.5,12.1,72,27,109.8,43.3
      c17.8,7.7,35.3,14.7,53.6,23c19.5,8.8,39.7,17.5,61.5,25.6c3.9,1.5,8,2.9,12,4.2c24.5,7.9,51.3,13.1,79.1,15.4
      c17.1,1.5,34.6,1.8,52.3,1.1C484.8,285.3,537.8,275.1,575.8,261.7z M581.5,260.2c25.8-9.2,49.3-19.4,71.4-29.8
      c10.3-4.9,20.7-9.8,31.3-14.6c14-6.4,35.4-13.3,56.8-18.3l0-0.7c-21.7,5-43.5,12-57.8,18.5c-10.6,4.8-21,9.8-31.3,14.7
      c-22,10.4-45.5,20.6-71.2,29.8c-37.7,13.5-90.5,23.7-142.3,26c-17.9,0.8-35.6,0.4-52.9-1.1c-27.4-2.3-53.7-7.4-77.9-15.2
      c-4-1.3-8-2.7-12-4.2c-21.8-8.1-42-16.8-61.4-25.6c-18.4-8.3-35.8-15.5-53.5-23.2c-37.6-16.4-75-31.6-109.5-44
      C39.7,161.2,9.7,149.1-24,139l0,0.7c33.3,10,63.1,22,94.2,33.1c34.5,12.4,71.8,27.5,109.4,43.9c17.7,7.7,35.1,14.9,53.5,23.2
      c19.5,8.8,39.7,17.5,61.5,25.7c3.9,1.5,8,2.9,12.1,4.2c24.4,7.8,50.9,13,78.5,15.3c17.4,1.5,35.3,1.8,53.3,1.1
      C490.6,284,543.6,273.7,581.5,260.2z M586.6,258.8c25.7-9.3,49.2-19.5,71.2-30c10.3-4.9,20.6-9.9,31.3-14.7
      c12.9-5.9,32.1-12.3,51.9-17.2l0-0.7c-20.1,4.9-39.7,11.4-52.9,17.4c-10.6,4.9-21,9.8-31.3,14.7c-22,10.5-45.4,20.7-71.1,30
      c-37.6,13.6-90.4,24-142.3,26.3c-18.2,0.8-36.2,0.5-53.8-1c-27.2-2.3-53.3-7.3-77.4-15.1c-4.1-1.3-8.1-2.7-12-4.2
      c-21.8-8.1-42-16.9-61.4-25.7c-18.4-8.4-35.8-15.6-53.4-23.4c-37.5-16.6-74.7-32-109.1-44.6c-33-12.1-64.5-25.1-100.5-35.7l0,0.7
      c35.5,10.5,66.8,23.4,99.5,35.4c34.4,12.6,71.5,28,109,44.5c17.6,7.8,35,15,53.3,23.4c19.5,8.9,39.7,17.6,61.5,25.8
      c4,1.5,8,2.9,12.1,4.2c24.2,7.8,50.5,12.9,78,15.2c17.7,1.5,35.9,1.9,54.2,1C495.8,282.9,548.7,272.4,586.6,258.8z M590.3,257.7
      c25.7-9.4,49.1-19.7,71.1-30.3c10.3-4.9,20.6-9.9,31.2-14.8c12.1-5.6,29.8-11.6,48.4-16.3l0-0.7c-18.9,4.8-37,10.9-49.4,16.6
      c-10.6,4.9-20.9,9.9-31.2,14.8c-22,10.5-45.3,20.9-70.9,30.2c-37.5,13.7-90.3,24.3-142.4,26.7c-18.5,0.9-36.9,0.5-54.7-1
      c-27-2.3-53-7.3-76.8-14.9c-4.1-1.3-8.1-2.7-12-4.2c-21.9-8.2-42-16.9-61.4-25.8c-18.3-8.4-35.7-15.7-53.2-23.6
      c-37.4-16.8-74.3-32.3-108.7-45.2c-34.1-12.7-66.8-26.4-104.5-37.4l0,0.7c37.2,10.9,69.6,24.5,103.5,37.2
      c34.3,12.8,71.3,28.4,108.6,45.2c17.6,7.9,34.9,15.2,53.2,23.6c19.4,8.9,39.6,17.7,61.6,25.9c4,1.5,8,2.9,12.1,4.2
      c24,7.7,50.2,12.7,77.4,15c18,1.5,36.5,1.9,55.2,1C499.6,282,552.6,271.5,590.3,257.7z M593,257.2c25.6-9.5,48.9-19.9,70.9-30.5
      c10.3-5,20.6-10,31.2-14.9c11.5-5.3,28.2-11.1,45.9-15.7l0-0.7c-18,4.7-35.1,10.6-46.9,16c-10.6,4.9-20.9,9.9-31.2,14.9
      c-21.9,10.6-45.2,21-70.7,30.4c-37.4,13.8-90.2,24.5-142.4,27.1c-18.8,0.9-37.5,0.6-55.7-0.9c-26.8-2.3-52.6-7.2-76.3-14.8
      c-4.1-1.3-8.1-2.7-12-4.2c-21.9-8.2-42-17-61.5-25.9c-18.3-8.4-35.6-15.8-53.1-23.8c-37.2-16.9-74-32.7-108.3-45.8
      c-34.9-13.3-68.4-27.6-107.3-38.9l0,0.7c38.5,11.3,71.7,25.4,106.4,38.7c34.3,13.1,71,28.9,108.2,45.8
      c17.5,7.9,34.8,15.3,53.1,23.7c19.4,8.9,39.6,17.8,61.6,26c4,1.5,8,2.9,12.1,4.2c23.8,7.6,49.8,12.6,76.9,14.9
      c18.3,1.6,37.2,1.9,56.1,0.9C502.3,281.9,555.3,271.1,593,257.2z'
        />

        <rect
          x='4.1'
          y='52.3'
          style={{
            clipPath: 'url(#SVGID_00000101814141948693354700000002932564881757792184_)',
            fill: 'none'
          }}
          width='700'
          height='323.7'
        />
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

export default React.memo(BlueWaves);
