/* eslint-disable react/prop-types */
import { Box, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import useStyles from '../styles/UserList.styles';
import UserProfileListIcons from '../UserProfileListIcons';
import FilterToolTip from '../Components/FilterToolTip';
import { ELASTIC_SEARCH_ATTRIBUTES_LABELS } from '../../ResultsPage/utils/constants';
import { encodeObjectToBase64 } from '../../../utils/encodeDecodeObject';
import RESULT_VIEW_TYPES from '../../SearchResults/components/constants';
import {
  cnfToAdvancedSearch,
  generateCNFQuery
} from '../../../components/Header/utils/advanceSearchHelpers';

import { SOURCE_MAPPING_USER_PROFILE_ACTIVITY } from '../util';

const ariaSourceMapping = {
  us: 'us',
  eu: 'eu',
  ca: 'ca',
  'us-devices': 'us-devices',
  ct: 'ct',
  adcomm: 'adcomm',
  guidance: 'guidance',
  'ema-pips': 'ema-pips',
  uk: 'uk',
  devices: 'us-devices',
  bla: 'bla',
  'ema-guidance': 'ema-guidance',
  'canada-guidance': 'canada-guidance',
  'fda-letters': 'fda-letters',
  'fda-written-request': 'fda-written-request',
  chmp: 'chmp',
  hpra: 'hpra',
  hma: 'hma',
  jp: 'jp'
};
const ariaModuleMapping = {
  biologics: 'bla',
  '505b2': '505b2',
  core: 'core',
  guidance: 'guidanceDocuments',
  advisorycommittee: 'advisoryCommittee',
  ct: 'ct',
  'ema-pips': 'pediatricsEma',
  uk: 'htaDocuments',
  bla: 'bla',
  ema_guidance: 'ema_guidance',
  canada_guidance: 'canada_guidance',
  pediatrics: 'pediatrics',
  chmp: 'chmp'
};
// eslint-disable-next-line react/prop-types
const AriaSearchButton = ({
  search,
  smallIcon,
  listIndex = null,
  handleAriaResultExpandCollapse = null
}) => {
  // eslint-disable-next-line react/prop-types
  const {
    module,
    searchOperationData,
    search_from_favorites,
    search_project,
    term,
    source,
    id,
    bucket = 'all',
    filters,
    use_synonyms,
    dateFilter
  } = search;
  const [path, setPath] = React.useState('');

  const classes = useStyles();
  const isAriaSearchWithResults =
    listIndex !== null && listIndex !== undefined && handleAriaResultExpandCollapse;

  const handleRedirection = async e => {
    e.stopPropagation();
    const payload = {
      search_term: term,
      source,
      document_search_id: id,
      view_type: RESULT_VIEW_TYPES.DOCUMENT
    };
    const encodedPayload = encodeObjectToBase64(payload) ?? '';
    const url = `/search/${encodedPayload}`;
    window.open(url, '_blank');
  };
  useEffect(() => {
    let localPath = '';
    if (Object.keys(source).length > 1) {
      localPath = 'Multiple Sources';
    } else {
      const currSource = Object.keys(source)[0];
      if (currSource) {
        const subcategory = source[Object.keys(source)[0]];
        localPath = `${
          SOURCE_MAPPING_USER_PROFILE_ACTIVITY[currSource][subcategory[0]?.toLowerCase()] ?? ''
        }`;
      }
    }
    if (searchOperationData && 'type' in searchOperationData) {
      if (searchOperationData.type === 'quickSearch') {
        if ('term' in searchOperationData) {
          localPath = `${localPath} | ${searchOperationData.term}`;
        } else {
          const moduleCategories =
            ELASTIC_SEARCH_ATTRIBUTES_LABELS[searchOperationData?.source[0]?.source][
              searchOperationData?.category
            ];
          localPath = `${localPath} | ${
            moduleCategories ?? searchOperationData.category.replace(/_/g, ' ')
          }`;
          if (searchOperationData.searchData) {
            const categoryValueArray = [];
            Object.entries(searchOperationData.searchData).forEach(([key, value]) => {
              if (Array.isArray(value)) {
                categoryValueArray.push(value.join(' / '));
              } else {
                categoryValueArray.push(value);
              }
            });
            if (categoryValueArray) {
              localPath = `${localPath} | ${categoryValueArray.join(' / ')}`;
            }
          }
        }
      } else {
        const payload = cnfToAdvancedSearch(searchOperationData.cnf_query);
        localPath += generateCNFQuery(
          payload,
          ELASTIC_SEARCH_ATTRIBUTES_LABELS[searchOperationData?.source[0]?.source]
        );
      }
    }
    if (filters && Object.keys(filters).length !== 0) {
      if (source === 'adcomm') {
        localPath = `${localPath} | With filters`;
      }
    }
    if (search_from_favorites) {
      localPath = `${localPath} | Favorite Applications`;
    }
    if (search_project && Object.keys(search_project).length !== 0) {
      localPath = `${localPath} | Applications in Project - ${search_project?.name}`;
    }
    setPath(localPath);
  }, [module, searchOperationData, term, source, id]);

  return (
    <ListItem
      className={classes.list}
      onClick={
        isAriaSearchWithResults
          ? () => {
              handleAriaResultExpandCollapse(listIndex);
            }
          : null
      }>
      <ListItemAvatar
        sx={{
          maxWidth: smallIcon ? 45 : 56,
          minWidth: smallIcon ? 45 : 56,
          mr: smallIcon ? 1 : 0
        }}>
        <Box
          sx={{
            p: '.6em',
            width: '100%'
          }}>
          <UserProfileListIcons type='ariaSearch' smallIcon={smallIcon} />
        </Box>
      </ListItemAvatar>
      {/* eslint-disable-next-line react/prop-types */}
      <Box>
        <FilterToolTip filters={filters} source={source}>
          <ListItemText
            onClick={handleRedirection}
            primary={path}
            secondary={term}
            className={classes.listItem}
            primaryTypographyProps={{ variant: 'activityTitle' }}
            secondaryTypographyProps={{ variant: 'activityDescription' }}
          />
        </FilterToolTip>
      </Box>
    </ListItem>
  );
};
AriaSearchButton.prototype = {
  search: PropTypes.object.isRequired
};
export default memo(AriaSearchButton);
