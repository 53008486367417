const css = `
.discontinued_icon{cursor:pointer}
`;

const DiscontinuedIcon = () => {
  return (
    <svg
      class='discontinued_icon'
      xmlns='http://www.w3.org/2000/svg'
      width='26.683'
      height='26.683'
      viewBox='0 0 26.683 26.683'
    >
      <defs>
        <style>{css}</style>
      </defs>
      <g id='Component_9_1' data-name='Component 9 - 1' transform='translate(1.02 1.02)'>
        <g id='Group_833' data-name='Group 833' transform='translate(-28.679 0)'>
          <path
            id='Icon_metro-cross'
            data-name='Icon metro-cross'
            d='M26.988,21.725h0l-7.475-7.476,7.476-7.476h0a.772.772,0,0,0,0-1.089L23.456,2.153a.772.772,0,0,0-1.089,0h0L14.892,9.629,7.416,2.153h0a.772.772,0,0,0-1.089,0L2.8,5.684a.772.772,0,0,0,0,1.089h0l7.476,7.476L2.8,21.725h0a.772.772,0,0,0,0,1.089l3.531,3.531a.772.772,0,0,0,1.089,0h0l7.476-7.476,7.476,7.476h0a.772.772,0,0,0,1.089,0l3.531-3.531a.772.772,0,0,0,0-1.089Z'
            transform='translate(26.108 -1.928)'
            fill='none'
            stroke='red'
            strokeWidth='2'
          />
        </g>
      </g>
    </svg>
  );
};

export default DiscontinuedIcon;
