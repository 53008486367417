import {
  Avatar,
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Tooltip,
  Typography
} from '@mui/material';
import { useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AuthContext from '../../store/Auth/AuthProvider';
import useStyles from './styles/UserNav.styles';
// eslint-disable-next-line import/no-cycle
import UserProjectList from './Actions/UserProjectList';
import ActivityIcon from '../../assets/svgs/UserMenu/ActivityIcon';
import MyReportsIcon from '../../assets/svgs/UserMenu/MyReportsIcon';
import { StarOutlineIcon } from '../../assets/svgs/Icons';

// eslint-disable-next-line no-empty-pattern
export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  marginBottom: '5px !important',
  '& > div > span': {
    color: `${theme.palette.black.gravel} !important`,
    fontSize: `16px !important`
  },
  '&.Mui-selected': {
    borderRadius: '16px',
    backgroundColor: `${theme.palette.secondary[75]} !important`,
    fontSize: `16px !important`,
    color: `${theme.palette.primary.darkVariant2} !important`,
    '& > div ': {
      fontWeight: `700 !important`,

      '& > span': {
        fontWeight: `700 !important`,
        color: `${theme.palette.primary.darkVariant2} !important`
      },

      '& > svg': {
        fill: `${theme.palette.primary.darkVariant2} !important`,
        color: `${theme.palette.primary.darkVariant2} !important`
      }
    }
  },
  '&:hover': {
    borderRadius: '16px',
    backgroundColor: `${theme.palette.secondary[75]} !important`
  },
  '& .MuiSvgIcon-root': { fill: `${theme.palette.black.gravel} !important` },
  '& > .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: '12px'
  }
}));
export const stringAvatar = name => {
  let returnName = '';
  if (name) {
    const initials = name?.split(' ');
    if (initials) {
      returnName = `${initials[0][0]}`;
    }
    if (initials.length > 1) {
      returnName = `${initials[0][0]}${initials[1][0]}`;
    }
  }
  return {
    children: returnName
  };
};
const UserProfileNav = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);

  const handleClick = page => {
    if (page) {
      history.push(`/account${page}`);
    }
  };
  const disablePasswordChange = useMemo(() => {
    return currentUser && 'identities' in currentUser;
  }, [currentUser]);
  return (
    <Box display='flex' flexDirection='column' container>
      <Box display='flex' width='100%'>
        <Box sx={{ marginRight: '1em' }}>
          <Avatar
            className={classes.avatar}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...stringAvatar(currentUser && currentUser['custom:user'])}
          />
        </Box>
        <Box display='flex' flexDirection='column' justifyContent='center'>
          <Typography component='div' variant='subtitle1' data-dd-privacy='mask'>
            <b>{currentUser?.['custom:user']}</b>
          </Typography>
          <Typography component='div' variant='subtitle1' data-dd-privacy='mask' noWrap>
            {currentUser?.email}
          </Typography>
          <Tooltip title={disablePasswordChange && 'SSO users cannot change passwords'}>
            <Box>
              <Button
                disabled={disablePasswordChange}
                variant='text'
                className={classes.textButton}
                onClick={() => history.push('/reset')}>
                <u>Change password</u>
              </Button>
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <Box>
        <List
          sx={{
            width: '98%%',
            bgcolor: 'background.paper',
            borderRadius: '16px',
            marginTop: '3em',
            padding: '2%',
            color: '#4F4F4F !important'
          }}
          component='nav'
          aria-labelledby='nested-list-subheader'>
          <StyledListItemButton
            selected={location.pathname.match('activity') || location.pathname === '/account'}
            onClick={() => handleClick('/activity')}>
            <ListItemIcon>
              <ActivityIcon sx={{ fontSize: 16 }} />
            </ListItemIcon>
            <ListItemText primary='Activity' />
          </StyledListItemButton>
          <StyledListItemButton
            selected={!!location.pathname.match('favorites')}
            onClick={() => handleClick('/favorites')}>
            <ListItemIcon>
              <StarOutlineIcon sx={{ fontSize: 16 }} />
            </ListItemIcon>
            <ListItemText primary='Saved items' />
          </StyledListItemButton>
          <UserProjectList type='nav' handleClick={handleClick} />
          <StyledListItemButton
            selected={!!location.pathname.match('reports')}
            onClick={() => handleClick('/reports')}>
            <ListItemIcon>
              <MyReportsIcon sx={{ fontSize: 16 }} />
            </ListItemIcon>
            <ListItemText primary='My reports' />
          </StyledListItemButton>
        </List>
      </Box>
    </Box>
  );
};
export default UserProfileNav;
