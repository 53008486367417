const css = `
.prescription_icon{cursor:pointer}
`;

const PrescriptionIcon = () => {
  return (
    <svg
      class='prescription_icon'
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 25 25'
    >
      <g id='Component_8_1' data-name='Component 8 – 1' transform='translate(0.5 0.5)'>
        <g id='stamped'>
          <path
            id='Path_467'
            data-name='Path 467'
            d='M9.221,21.176H2.426A2.479,2.479,0,0,1,0,18.652V5.525A2.479,2.479,0,0,1,2.426,3H4.368a.505.505,0,0,1,0,1.01H2.426A1.488,1.488,0,0,0,.971,5.525V18.652a1.488,1.488,0,0,0,1.456,1.515H9.221a.505.505,0,0,1,0,1.01Z'
            transform='translate(0 -0.088)'
            fill='none'
            stroke='#20b2c2'
            strokeWidth='1'
          />
          <path
            id='Path_468'
            data-name='Path 468'
            d='M11.279,5.824H5.456A1.458,1.458,0,0,1,4,4.368V2.426a.485.485,0,0,1,.485-.485h1.5a2.427,2.427,0,0,1,4.756,0h1.5a.485.485,0,0,1,.485.485V4.368A1.458,1.458,0,0,1,11.279,5.824ZM4.971,2.912V4.368a.487.487,0,0,0,.485.485h5.824a.487.487,0,0,0,.485-.485V2.912H10.309a.485.485,0,0,1-.485-.485,1.456,1.456,0,1,0-2.912,0,.485.485,0,0,1-.485.485Z'
            transform='translate(0.071)'
            fill='none'
            stroke='#20b2c2'
            strokeWidth='1'
          />
          <path
            id='Path_469'
            data-name='Path 469'
            d='M13.191,8.971H3.485a.485.485,0,1,1,0-.971h9.706a.485.485,0,1,1,0,.971Z'
            transform='translate(-0.088 0.01)'
            fill='none'
            stroke='#20b2c2'
            strokeWidth='1'
          />
          <path
            id='Path_470'
            data-name='Path 470'
            d='M12.706,11.971H3.485a.485.485,0,1,1,0-.971h9.221a.485.485,0,0,1,0,.971Z'
            transform='translate(-0.088 0.014)'
            fill='none'
            stroke='#20b2c2'
            strokeWidth='1'
          />
          <path
            id='Path_471'
            data-name='Path 471'
            d='M11.735,14.971H3.485a.485.485,0,1,1,0-.971h8.25a.485.485,0,1,1,0,.971Z'
            transform='translate(-0.088 0.018)'
            fill='none'
            stroke='#20b2c2'
            strokeWidth='1'
          />
          <path
            id='Path_472'
            data-name='Path 472'
            d='M19.353,20.221H16.441a.485.485,0,0,1-.485-.485v-1.9a1.606,1.606,0,0,0-.5-1.092,3.363,3.363,0,0,1-.9-2.971,3.4,3.4,0,0,1,6.739.623,3.348,3.348,0,0,1-1.007,2.4,1.378,1.378,0,0,0-.449.957v1.982A.485.485,0,0,1,19.353,20.221Zm-2.426-.971h1.941v-1.5a2.328,2.328,0,0,1,.738-1.647,2.386,2.386,0,0,0,.718-1.709,2.427,2.427,0,0,0-4.812-.45,2.4,2.4,0,0,0,.64,2.122,2.529,2.529,0,0,1,.776,1.768Z'
            transform='translate(0.279 0.382)'
            fill='none'
            stroke='#20b2c2'
            strokeWidth='1'
          />
          <path
            id='Path_473'
            data-name='Path 473'
            d='M23.162,23.868H12.485A.485.485,0,0,1,12,23.382V21.328a1.457,1.457,0,0,1,1.274-1.444l3.032-.38,2.973,0,3.093.383a1.457,1.457,0,0,1,1.274,1.444v2.055A.485.485,0,0,1,23.162,23.868ZM12.971,22.9h9.706V21.328a.485.485,0,0,0-.425-.481l-3.032-.38-2.852,0-2.972.376a.485.485,0,0,0-.425.481Z'
            transform='translate(0.353 0.132)'
            fill='none'
            stroke='#20b2c2'
            strokeWidth='1'
          />
          <path
            id='Path_474'
            data-name='Path 474'
            d='M16.368,8.824a.485.485,0,0,1-.485-.485V5.426a1.458,1.458,0,0,0-1.456-1.456H12.485a.485.485,0,1,1,0-.971h1.941a2.429,2.429,0,0,1,2.426,2.426V8.338A.485.485,0,0,1,16.368,8.824Z'
            transform='translate(0.093 -0.088)'
            fill='none'
            stroke='#20b2c2'
            strokeWidth='1'
          />
        </g>
      </g>
    </svg>
  );
};

export default PrescriptionIcon;
