const styles = {
  indications_container: {
    maxHeight: '53vh',
    overflow: 'auto',
    paddingLeft: '30px',
    paddingRight: '30px'
  },
  indications_field_container: {
    paddingTop: '30px',
    textAlign: 'justify',
    fontSize: 'larger'
  },
  activeTab: {
    background: 'linear-gradient(102deg, #29ABE2 0%, #06C66C 100%)',
    border: `0.5px solid gray.lightBorder`,
    borderColor: 'gray.lightBorder',
    borderRadius: '10px',
    marginTop: '20px',
    '&.Mui-selected': {
      color: 'white.main',
      border: '0.5px solid',
      fontSize: 'large'
    },
    textTransform: 'capitalize'
  },
  inactiveTab: {
    background: 'primary.background',
    marginTop: '20px',
    textTransform: 'capitalize',
    border: `0.5px solid`,
    borderColor: 'gray.lightBorder',
    borderRadius: '10px'
  },
  tabContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    height: '53vh',
    paddingTop: '5px',
    margin: '5px 5px 5px 15px'
  },
  tabs: {
    paddingRight: '10px',
    width: '200px',
    '& div.MuiTabs-scroller': {
      '& .MuiTabs-flexContainer': {
        padding: '5px'
      }
    }
  },
  indicationText: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '35px',
    textAlign: 'justify',
    fontSize: 'larger',
    padding: '15px 20px 10px 30px'
  },
  textAlignment: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '45vh',
    width: '100%',
    alignItems: 'center',
    fontWeight: 900
  }
};

export default styles;
