// @ts-nocheck
import React, { useCallback, useContext } from 'react';
import uniqueId from 'lodash/uniqueId';
// mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
// store
import PMRStore from '../../../store/PMRImpact';
import Actions from '../../../store/actions';
import Store from '../../../store';

// component
import Styles from '../styles/Insights.styles';
import CustomBarGraph from '../../CustomComponents/Graphs/CustomBarGraph';
import PmrImpactSkeleton from './PmrImpactSkeleton';
import StatsCountTile from '../../../helpers/baseComponents/StatsCountTile';

// utils
import formatCount from '../../../utils/formatCount';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';
import { PMR_IMPACT_QUERIES } from '../const';

const PMRImpactInsights: React.FC = () => {
  const { pmrState } = useContext(PMRStore) as any;
  const { state, dispatch } = useContext(Store) as any;

  const addToReport = useCallback(
    async (id: string) => {
      const addedReport = PMR_IMPACT_QUERIES.find(report => report.id === id);
      const random = Math.floor(Math.random() * 1000);
      dispatch({
        type: Actions.ADD_TEMPLATE_TO_REPORT,
        value: {
          templateType: 'custom',
          chartType: addedReport?.graph,
          sectionType: 'CHART',
          id: uniqueId(`pmr_impact_${random}`),
          style: {
            placement: {
              h: 40,
              w: 12
            },
            graphStyle: addedReport?.graphStyle,
            title: addedReport?.title
          },
          data: {
            source: 'pmr',
            search_type: 'impact',
            disableSelectionSource: true,
            filters: pmrState.availableFilters,
            query: addedReport?.query
          }
        }
      });
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Successfully added to Report.', color: 'success' }
      });
    },
    [state.cartItems, dispatch, pmrState.availableFilters]
  );

  if (pmrState.loading) {
    return <PmrImpactSkeleton />;
  }
  return (
    <Stack display='flex' flexDirection='row'>
      <Grid item xs={12} lg={3} mt={2}>
        {pmrState.pmrStats && (
          <>
            <StatsCountTile text='Total PMR/PMC' count={pmrState?.pmrStats?.total} />
            <StatsCountTile
              text='Active Ingredients'
              count={pmrState.pmrStats.total_active_ingredients}
            />
            <StatsCountTile
              text='Total Documents | Pages'
              count={`${truncateCountAboveMillions(
                pmrState?.pmrStats?.pdf_stats?.total_document_count
              )} | ${truncateCountAboveMillions(pmrState?.pmrStats?.pdf_stats?.total_page_count)}`}
              toolTipValue={`${formatCount(
                pmrState?.pmrStats?.pdf_stats?.total_document_count
              )} | ${formatCount(pmrState?.pmrStats?.pdf_stats?.total_page_count)}`}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} container style={{ padding: '20px 20px 0 20px' }}>
        <Grid item xs={12} display='flex' flexDirection='column' alignItems='flex-end'>
          <Grid item container sx={Styles.graphContainer}>
            <Typography sx={Styles.graphTitle}>
              Number of PMR/PMC and Number of days from issue to fulfillment or release
            </Typography>
            {pmrState.categoryGraph?.length > 0 ? (
              <CustomBarGraph
                keys={['days', 'number']}
                index='x'
                data={pmrState.categoryGraph}
                borderRadius={2}
                colors='#B1BBE4'
                groupMode='grouped'
                paddings={0.2}
                gridXValues={5}
                defs={[
                  {
                    id: 'dots',
                    type: 'linearGradient',
                    colors: [{ color: '#B1BBE4' }]
                  },
                  {
                    id: 'lines',
                    type: 'linearGradient',
                    colors: [{ color: '#838691' }],
                    spacing: 10
                  }
                ]}
                fill={[
                  {
                    match: {
                      id: 'days'
                    },
                    id: 'dots'
                  },
                  {
                    match: {
                      id: 'number'
                    },
                    id: 'lines'
                  }
                ]}
                tickRotationValue={25}
                enableLabel
                labelTextColor='#ffffff'
                axisText='#A3A3A3'
              />
            ) : (
              <Box display='flex' alignItems='center' ml='50%'>
                <Typography> No Data </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} container style={{ paddingTop: '20px', paddingLeft: '20px' }}>
          <Grid item xs={6} container>
            <Box paddingRight={2}>
              <Button
                onClick={() => addToReport('pmrs_issued_by_year')}
                variant='text'
                fullWidth={false}
                sx={Styles.addToReport}>
                <Typography align='right' variant='subtitle2' sx={Styles.reportText}>
                  + Add to Report
                </Typography>
              </Button>
            </Box>
            <Grid item container sx={Styles.graphContainer}>
              <Typography sx={Styles.graphTitle}>
                Number of PMR/PMC issued by year (and fulfilled or released)
              </Typography>
              {pmrState.issueYearGraph?.length > 0 ? (
                <CustomBarGraph
                  data={pmrState.issueYearGraph}
                  keys={['y']}
                  index='x'
                  indexBy='x'
                  borderRadius={2}
                  paddings={0.4}
                  innerPadding={0.1}
                  gridXValues={5}
                  colors='#B1BBE4'
                  tickRotationValue={90}
                  enableLabel
                  labelTextColor='#ffffff'
                  axisText='#A3A3A3'
                />
              ) : (
                <Box display='flex' alignItems='center' ml='50%'>
                  <Typography>No Data</Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid item xs={6} container>
            <Box paddingRight={2}>
              <Button
                onClick={() => addToReport('fulfilled_year')}
                variant='text'
                fullWidth={false}
                sx={Styles.addToReport}>
                <Typography align='right' variant='subtitle2' sx={Styles.reportText}>
                  + Add to Report
                </Typography>
              </Button>
            </Box>
            <Grid item container sx={Styles.graphContainer}>
              <Typography sx={Styles.graphTitle}>
                Number of PMR/PMC fulfilled or released by year
              </Typography>
              {pmrState.fulfillmentGraph?.length > 0 ? (
                <CustomBarGraph
                  data={pmrState.fulfillmentGraph}
                  keys={['y']}
                  index='x'
                  indexBy='x'
                  colors='#B1BBE4'
                  borderRadius={2}
                  paddings={0.4}
                  innerPadding={0.1}
                  gridXValues={5}
                  tickRotationValue={90}
                  enableLabel
                  labelTextColor='#ffffff'
                  axisText='#A3A3A3'
                />
              ) : (
                <Box display='flex' alignItems='center' ml='50%'>
                  <Typography>No Data</Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default React.memo(PMRImpactInsights);
