import React, { createContext, useReducer } from 'react';
import reducers from './reducers';

type Props = {
  children: React.ReactNode;
};

export interface designationContextInterface {
  openAllSeeMore: boolean;
  view: 'horizontal' | 'vertical' | string;
  expandCard: boolean;
  expandedCardPayload: ExpandedCardPayloadInterface;
  sortBy: 'asc' | 'desc' | string;
  loading: boolean;
  filters: any;
  isFiltersApplied: boolean;
}

const initialState = {
  openAllSeeMore: false,
  view: 'vertical',
  expandCard: false,
  expandedCardPayload: {},
  sortBy: 'asc',
  loading: false,
  filters: [],
  isFiltersApplied: false
};

const DesignationStore = createContext<{
  designationState: designationContextInterface;
  designationDispatch: React.Dispatch<any>;
}>({ designationState: initialState, designationDispatch: () => null });
const { Provider } = DesignationStore;

export const DesignationStateProvider = ({ children }: Props) => {
  const [designationState, designationDispatch] = useReducer(reducers, initialState);
  return <Provider value={{ designationState, designationDispatch }}>{children}</Provider>;
};

export default DesignationStore;
