import React, { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, FormControlLabel, ListItem, Popover, Radio, TextField, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { RemoveCircleOutline } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import { isEmpty } from 'lodash';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import NotEqualsToIcon from '../../../assets/svgs/Icons/NotEqualsToIcon';
import styles from '../styles/SearchBar.styles';
import EqualsToIconSelected from '../../../assets/svgs/Icons/EqualsToSelectedIcon';
import EqualsToIcon from '../../../assets/svgs/Icons/EqualsToIcon';
import NotEqualsToSelectedIcon from '../../../assets/svgs/Icons/NotEqualsToSelectedIcon';
import ApprovalHistoryHeaderStyles from '../../../components/ApprovalPathway/styles/Header.styles';

import useSearchSuggestions from '../../../components/Header/hooks/useSearchSuggestions';
import { VivproAutocomplete } from '../../../components/CustomComponents/Autocomplete';
import { filterOptions } from '../../../components/Header/utils/searchUtils';
import CustomDatePicker from '../../../components/CustomComponents/DatePicker';
import { SOURCE_MENU_ITEMS } from '../const';
import KeyDownIcon from '../../../assets/svgs/Icons/KeyDownIcon';
import CaretDownIcon from '../../../assets/svgs/Icons/CaretDown';
import CustomCheckbox from '../../../components/CustomComponents/Checkbox';

const AdvancedSearchItems = ({
  id,
  category,
  subCategoryList,
  categoryOptions,
  searchTerm,
  setItem,
  condition,
  disabled,
  isLastItem,
  exactMatch,
  advancedSearch,
  setAdvancedSearch,
  compareCondition,
  rowIndex,
  rowsLength,
  rows,
  isHighlighted,
  setHighlightRowIds,
  source
}: any) => {
  const [localExactMatch, setLocalExactMatch] = useState(exactMatch);
  const [datePickerOpen, setDatePickerOpen] = useState({
    startDate: false,
    endDate: false
  });
  const getSelectedSource = () => {
    let modules: any;
    SOURCE_MENU_ITEMS.forEach((item: any) => {
      if (item.module) {
        item.module.forEach((data: any) => {
          if (data.value === source) {
            modules = data;
          }
        });
      }
    });
    return modules;
  };
  const getCategory = useMemo(() => {
    if (category) {
      return getSelectedSource()?.categories?.find((c: any) => c?.urlRouteParam === category);
    }
    return undefined;
  }, [category]);
  // Search Autocomplete.
  const {
    searchSuggestions,
    isSuggestionsLoading,
    setIsSearchOpen,
    handleKeyPress,
    clearSearchSuggestions,
    isSearchOpen
  } = useSearchSuggestions(getSelectedSource(), getCategory);
  const handleAutoCompleteOpen = useCallback(() => setIsSearchOpen(true), [setIsSearchOpen]);
  const handleAutoCompleteClose = useCallback(() => setIsSearchOpen(false), [setIsSearchOpen]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElCategory, setAnchorElCategory] = React.useState<null | HTMLElement>(null);
  const [openCategory, setOpenCategory] = React.useState<boolean>(false);
  const [subCategory, setSubCategory] = React.useState<any>([]);
  const [categoryLabel, setCategoryLabel] = React.useState<string>('');
  const openMenu = Boolean(anchorEl);
  const isApprovalDate = category === 'approval_date';

  const changeCategory = (option: any) => {
    clearSearchSuggestions();
    setItem((prev: any) => {
      const previousValue = prev;
      const indexOfItem = previousValue.findIndex(
        (i: any) => i.id.toLowerCase().trim() === id.toLowerCase().trim()
      );
      previousValue[indexOfItem].category = option.id;
      previousValue[indexOfItem].categoryKey = option.id;
      if (option.id !== 'label_section_search') {
        previousValue[indexOfItem].subCategory = [];
      } else {
        previousValue[indexOfItem].subCategory = [
          {
            id: 'all_label_sections',
            label: 'All Label Sections'
          }
        ];
      }
      if (indexOfItem > 0) {
        if (previousValue[indexOfItem - 1].category !== option.id) {
          previousValue[indexOfItem - 1].compareCondition = 'AND';
        }
      }

      return [...previousValue];
    });
    setSubCategory(option.id === 'label_section_search' ? option?.subcategories : []);
  };

  const changeSubCategory = (
    section: {
      id: string;
      label: string;
      subsection?: Array<{ id: string; label: string }>;
    },
    checked: boolean
  ) => {
    clearSearchSuggestions();
    setItem((prev: any) => {
      const previousValue = prev;
      const indexOfItem = previousValue.findIndex(
        (i: any) => i.id.toLowerCase().trim() === id.toLowerCase().trim()
      );
      // check if selected section already being selected or not
      if (checked) {
        // Mark the category as label_section_search
        previousValue[indexOfItem].category = 'label_section_search';
        previousValue[indexOfItem].categoryKey = 'label_section_search';
        if (section.id === 'all_label_sections') {
          previousValue[indexOfItem].subCategory = [section];
        } else if (section?.subsection && section?.subsection.length !== 0) {
          section?.subsection.forEach((sect: { id: string; label: string }) => {
            if (
              !previousValue[indexOfItem]?.subCategory.some(
                (item: any) => item.id.toLowerCase().trim() === sect.id.toLowerCase().trim()
              )
            ) {
              previousValue[indexOfItem]?.subCategory.push(sect);
            }
          });
        } else {
          previousValue[indexOfItem]?.subCategory.push(section);
        }
      } else if (section.id === 'all_label_sections') {
        previousValue[indexOfItem].subCategory = [];
      } else {
        previousValue[indexOfItem].subCategory = previousValue[indexOfItem]?.subCategory.filter(
          (obj: { id: string; label: string }) =>
            obj.id.toLowerCase().trim() !== section.id.toLowerCase().trim()
        );
        if (section?.subsection && section?.subsection.length !== 0) {
          section?.subsection.forEach((sect: { id: string; label: string }) => {
            previousValue[indexOfItem].subCategory = previousValue[indexOfItem]?.subCategory.filter(
              (obj: { id: string; label: string }) =>
                obj.id.toLowerCase().trim() !== sect.id.toLowerCase().trim()
            );
          });
        }
      }
      return [...previousValue];
    });
  };

  const removeItem = () => {
    setItem((prev: any) => prev.filter((i: any) => i.id !== id));
  };
  const changeSearchTerm = (value: string) => {
    setItem((prev: any) => {
      const previousValue = prev;
      const indexOfItem = previousValue.findIndex((i: any) => i.id === id);
      previousValue[indexOfItem].searchTerm = value;
      return [...previousValue];
    });
  };
  const changeExactMatch = (event: any) => {
    setItem((prev: any) => {
      const previousValue = prev;
      const indexOfItem = previousValue.findIndex((i: any) => i.id === id);
      previousValue[indexOfItem].exactMatch = event.target.checked;
      return [...previousValue];
    });
  };
  const setCondition = (cond: string) => () => {
    setItem((prev: any) => {
      const previousValue = prev;
      const indexOfItem = previousValue.findIndex((i: any) => i.id === id);
      previousValue[indexOfItem].categoryKey = `${cond === 'doesNotContain' ? '~' : ''}${
        previousValue[indexOfItem].category
      }`;
      previousValue[indexOfItem].condition = cond;
      return [...previousValue];
    });
  };
  const openCompareCondition = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setCompareCondition = (cond: string) => () => {
    setItem((prev: any) => {
      const previousValue = prev;
      const indexOfItem = previousValue.findIndex((i: any) => i.id === id);
      previousValue[indexOfItem].compareCondition = cond;
      return [...previousValue];
    });
    setAnchorEl(null);
    handleClose();
  };

  const nextSearchConditionDisabled = useMemo(() => {
    let returnValue = true;
    if (!isLastItem) {
      const nextValue = rows[rowIndex + 1];
      returnValue = nextValue?.category !== category;
    }
    setCompareCondition(returnValue ? 'AND' : compareCondition);
    return returnValue;
  }, [isLastItem, rowIndex, rows, rowsLength, category, compareCondition]);

  useEffect(() => {
    setLocalExactMatch(category.startsWith('atc') ? true : exactMatch);
  }, [exactMatch, category]);
  useEffect(() => {
    setSubCategory([]);
  }, [source]);
  useEffect(() => {
    if (openMenu) {
      setHighlightRowIds([rowIndex + 1, rowIndex]);
    } else {
      setHighlightRowIds([]);
    }
  }, [openMenu, setHighlightRowIds, rowIndex]);

  const isSourceIntermediate = (option: {
    id: string;
    label: string;
    subsection: Array<{ id: string; label: string }>;
  }) => {
    // Check if the option id is in subCategoryList
    const isDirectMatch = subCategoryList.some(
      (item: { id: string }) => item.id.toLowerCase().trim() === option.id.toLowerCase().trim()
    );
    if (isDirectMatch) {
      return false;
    }

    // If no subsection or subsection is empty, return false
    if (!option.subsection || option.subsection.length === 0) {
      return false;
    }

    // Check if every subsection item is in subCategoryList
    const allSubsectionsMatch = option.subsection.every(mainItem =>
      subCategoryList.some(
        (subItem: { id: string }) =>
          subItem.id.toLowerCase().trim() === mainItem.id.toLowerCase().trim()
      )
    );
    if (allSubsectionsMatch) {
      return false;
    }

    // Check if any subsection item is in subCategoryList
    return option.subsection.some(subItem =>
      subCategoryList.some(
        (item: { id: string }) => item.id.toLowerCase().trim() === subItem.id.toLowerCase().trim()
      )
    );
  };

  const checkLabelSection = (section: {
    id: string;
    label: string;
    subsection?: Array<{ id: string; label: string }>;
  }) => {
    if (subCategoryList && subCategoryList.some((item: any) => item.id === 'all_label_sections')) {
      return true;
    }
    if (section?.subsection && section?.subsection.length !== 0) {
      const isAllSubsectionsMatch = section?.subsection.every(mainItem =>
        subCategoryList.some(
          (subItem: { id: string }) =>
            subItem.id.toLowerCase().trim() === mainItem.id.toLowerCase().trim()
        )
      );
      if (isAllSubsectionsMatch) {
        return true;
      }
      return false;
    }
    if (
      subCategoryList &&
      subCategoryList.some(
        (item: any) => item.id.toLowerCase().trim() === section.id.toLowerCase().trim()
      )
    ) {
      return true;
    }
    return false;
  };

  const checkDisablity = () => {
    if (subCategoryList.some((item: any) => item.id === 'all_label_sections')) {
      return true;
    }
    return false;
  };

  const getLabelSectionCategoryList = () => {
    if (subCategory && subCategory.length !== 0) {
      return subCategory;
    }
    return [];
  };

  useEffect(() => {
    if (categoryOptions && categoryOptions.length !== 0) {
      let categoryExists = false;
      categoryOptions.forEach((value: { id: string; label: string }) => {
        if (value.id === category) {
          categoryExists = true;
          setCategoryLabel(value.label);
        }
      });
      if (!categoryExists) {
        setCategoryLabel('');
      }
    } else {
      setCategoryLabel('');
    }
  }, [categoryOptions, category]);

  return (
    <>
      <ListItem
        key={`${id}-advanced-search-item`}
        sx={{
          p: 0,
          width: '599px',
          height: '40px'
        }}>
        <Stack
          direction='row'
          id={isHighlighted ? 'highlighted' : 'not-highlighted'}
          sx={{
            ...styles.advanceSearchListItem
          }}>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            width='128px'
            onClick={(e: any) => {
              setAnchorElCategory(e.currentTarget);
              setOpenCategory(true);
            }}
            sx={{ color: !categoryLabel ? 'red.900' : 'inherit' }}>
            {categoryLabel !== 'Label Sections'
              ? categoryLabel || 'Select category'
              : `${categoryLabel} ${
                  subCategoryList && subCategoryList.length !== 0
                    ? `(${subCategoryList.length})`
                    : ''
                }`}

            <KeyDownIcon sx={styles.keyDownIconStyle} />
          </Box>
          {openCategory && (
            <Popover
              id={id}
              open={openCategory}
              anchorEl={anchorElCategory}
              onClose={() => {
                setOpenCategory(false);
              }}
              sx={{
                '& .MuiPopover-paper': {
                  ...styles.popOverContainer
                }
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}>
              <Box width='160px'>
                <Box>
                  <Box display='flex' flexDirection='row' alignItems='center' width='160px'>
                    <MenuItem value='' disabled sx={{ pl: 0 }}>
                      Select category
                    </MenuItem>
                  </Box>
                  {categoryOptions &&
                    categoryOptions.map((option: any) => (
                      <Box
                        key={option.id}
                        display='flex'
                        flexDirection='row'
                        alignItems='center'
                        sx={styles.categoryBox}
                        onClick={() => {
                          changeCategory(option);
                        }}>
                        <Radio
                          key={option.id}
                          checked={option.id === category}
                          value='a'
                          name='radio-buttons'
                          sx={styles.radioButton}
                        />
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          itemID={option.label}
                          aria-label={option.label}
                          sx={styles.menuList}>
                          {option.label}
                        </MenuItem>
                        {option?.subcategories && option?.subcategories.length !== 0 && (
                          <IconButton
                            sx={{ width: '16px', height: '16px' }}
                            onClick={event => {
                              event.stopPropagation();
                              setSubCategory(
                                subCategory?.length === 0 ? option?.subcategories : []
                              );
                            }}>
                            <CaretDownIcon sx={styles.rightarrow} />
                          </IconButton>
                        )}
                      </Box>
                    ))}
                </Box>
              </Box>

              {getLabelSectionCategoryList() && getLabelSectionCategoryList().length !== 0 && (
                <Box display='flex' flexDirection='row'>
                  <Divider orientation='vertical' sx={styles.labeldivider} />
                  <Box sx={styles.labelSectionBox}>
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='flex-start'
                      sx={{ width: '208px', ml: '12px' }}>
                      <MenuItem value='' disabled sx={{ pl: '0px' }}>
                        Select Section(s)
                      </MenuItem>
                      <Box display='flex' flexDirection='row' alignItems='center' width='208px'>
                        <FormControlLabel
                          sx={{ ...styles.checkBox, '& .MuiTypography-root': { fontWeight: 700 } }}
                          control={
                            <CustomCheckbox
                              checked={checkLabelSection({
                                id: 'all_label_sections',
                                label: 'All Label Sections'
                              })}
                              value='all_label_sections'
                              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                changeSubCategory(
                                  {
                                    id: 'all_label_sections',
                                    label: 'All Label Sections'
                                  },
                                  event.target.checked
                                );
                              }}
                              size='small'
                              name='All Label Sections'
                              sx={styles.checkBox}
                            />
                          }
                          label='All Label Sections'
                        />
                      </Box>
                    </Box>
                    <Divider sx={styles.dividerStyle} />
                    {getLabelSectionCategoryList().map((option: any) => (
                      <Box
                        key={option.id}
                        display='flex'
                        flexDirection='column'
                        alignItems='center'
                        width='208px'
                        ml='12px'>
                        <Box display='flex' flexDirection='row' alignItems='center' width='208px'>
                          <FormControlLabel
                            sx={styles.checkBox}
                            control={
                              <CustomCheckbox
                                disabled={checkDisablity()}
                                checked={checkLabelSection(option)}
                                indeterminate={isSourceIntermediate(option)}
                                value={option.id}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                  changeSubCategory(option, event.target.checked);
                                }}
                                size='small'
                                name={option.label}
                                sx={styles.checkBox}
                              />
                            }
                            label={option.label}
                          />
                        </Box>
                        {option?.subsection && option?.subsection?.length !== 0 && (
                          <Box display='flex' flexDirection='row' ml='12px'>
                            <Divider orientation='vertical' sx={styles.dividerSubSection} />
                            <Box display='flex' flexDirection='column' alignItems='center'>
                              {option?.subsection.map(
                                (subOption: { id: string; label: string }) => (
                                  <Box
                                    key={subOption.id}
                                    display='flex'
                                    flexDirection='row'
                                    alignItems='center'
                                    width='208px'>
                                    <FormControlLabel
                                      sx={styles.checkBox}
                                      control={
                                        <CustomCheckbox
                                          disabled={checkDisablity()}
                                          checked={checkLabelSection(subOption)}
                                          value={subOption.id}
                                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            changeSubCategory(subOption, event.target.checked);
                                          }}
                                          size='small'
                                          name={subOption.label}
                                          sx={styles.checkBox}
                                        />
                                      }
                                      label={subOption.label}
                                    />
                                  </Box>
                                )
                              )}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Popover>
          )}

          <Divider orientation='vertical' flexItem variant='middle' sx={styles.horizontalDivider} />
          <Stack direction='row' alignItems='center'>
            <Tooltip title='Contains'>
              <IconButton
                sx={{ ...styles.conditionButtons, mr: '12px' }}
                disabled={disabled || isApprovalDate}
                onClick={setCondition('contains')}>
                {condition === 'contains' ? (
                  <EqualsToIconSelected sx={{ color: 'primary.500' }} />
                ) : (
                  <EqualsToIcon sx={{ color: 'gray.800' }} />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title='Does not contain'>
              <IconButton
                sx={styles.conditionButtons}
                disabled={disabled || isApprovalDate}
                onClick={setCondition('doesNotContain')}>
                {condition === 'doesNotContain' ? (
                  <NotEqualsToSelectedIcon sx={{ color: 'primary.500' }} />
                ) : (
                  <NotEqualsToIcon sx={{ color: 'gray.800' }} />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider orientation='vertical' flexItem variant='middle' sx={styles.horizontalDivider} />
          <Box sx={{ ...styles.advanceSearchInput, width: isApprovalDate ? '220px' : '214px' }}>
            {category && category === 'approval_date' ? (
              <Box sx={ApprovalHistoryHeaderStyles.advanceSearchDateBox}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Tooltip title='Start date'>
                    <CustomDatePicker
                      views={['year', 'month', 'day']}
                      closeOnSelect={false}
                      maxDate={
                        advancedSearch.endDate ? new Date(advancedSearch.endDate) : new Date()
                      }
                      disableFuture
                      value={advancedSearch.startDate}
                      inputFormat='MMM dd, yyyy'
                      onChange={newVal => {
                        setAdvancedSearch({ ...advancedSearch, startDate: newVal });
                      }}
                      open={datePickerOpen.startDate}
                      onClose={() => {
                        setDatePickerOpen({ ...datePickerOpen, startDate: false });
                      }}
                      InputProps={{
                        onMouseDown: () => {
                          setDatePickerOpen({ ...datePickerOpen, startDate: true, endDate: false });
                        },
                        onFocus: () => {
                          setDatePickerOpen({ ...datePickerOpen, startDate: true, endDate: false });
                        },
                        onBlur: () => {
                          setDatePickerOpen({ ...datePickerOpen, startDate: false });
                        }
                      }}
                      disabled={disabled}
                      renderInput={params => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          id='start_date'
                          helperText={null}
                          placeholder='Start date'
                          sx={styles.advanceSearchDateInput}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                            onBlur: () => {
                              setDatePickerOpen({ ...datePickerOpen, startDate: false });
                            },
                            onClick: () => {
                              if (!datePickerOpen.startDate) {
                                setDatePickerOpen({ ...datePickerOpen, startDate: true });
                              }
                            }
                          }}
                        />
                      )}
                      components={{
                        OpenPickerIcon: ArrowDropDownIcon
                      }}
                      PopperProps={{
                        placement: 'bottom',
                        modifiers: [
                          {
                            name: 'preventOverflow',
                            enabled: true,
                            options: {
                              altAxis: true,
                              altBoundary: true,
                              tether: true,
                              rootBoundary: 'document',
                              padding: 8
                            }
                          }
                        ]
                      }}
                    />
                  </Tooltip>
                  <Divider
                    orientation='vertical'
                    flexItem
                    variant='middle'
                    sx={styles.horizontalDivider}
                  />

                  <Tooltip title='End date'>
                    <CustomDatePicker
                      views={['year', 'month', 'day']}
                      closeOnSelect={false}
                      minDate={
                        advancedSearch.startDate ? new Date(advancedSearch.startDate) : new Date()
                      }
                      disableFuture
                      inputFormat='MMM dd, yyyy'
                      value={advancedSearch.endDate}
                      onChange={newVal => {
                        setAdvancedSearch({ ...advancedSearch, endDate: newVal });
                      }}
                      open={datePickerOpen.endDate}
                      onClose={() => {
                        setDatePickerOpen({ ...datePickerOpen, endDate: false });
                      }}
                      InputProps={{
                        onMouseDown: () => {
                          setDatePickerOpen({ ...datePickerOpen, startDate: false, endDate: true });
                        },
                        onFocus: () => {
                          setDatePickerOpen({ ...datePickerOpen, startDate: false, endDate: true });
                        },
                        onBlur: () => {
                          setDatePickerOpen({ ...datePickerOpen, endDate: false });
                        }
                      }}
                      disabled={disabled}
                      renderInput={params => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          id='end_date'
                          helperText={null}
                          placeholder='End date'
                          sx={{ ...styles.advanceSearchDateInput }}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                            onBlur: () => {
                              setDatePickerOpen({ ...datePickerOpen, endDate: false });
                            },
                            onClick: () => {
                              if (!datePickerOpen.endDate) {
                                setDatePickerOpen({ ...datePickerOpen, endDate: true });
                              }
                            }
                          }}
                        />
                      )}
                      PopperProps={{
                        placement: 'bottom',
                        modifiers: [
                          {
                            name: 'preventOverflow',
                            enabled: true,
                            options: {
                              altAxis: true,
                              altBoundary: true,
                              tether: true,
                              rootBoundary: 'document',
                              padding: 8
                            }
                          }
                        ]
                      }}
                    />
                  </Tooltip>
                </LocalizationProvider>
              </Box>
            ) : (
              <VivproAutocomplete
                // @ts-ignore
                disableSearch={isEmpty(category) || disabled}
                disableSearchIcon
                value={searchTerm}
                label='Search term'
                options={filterOptions(searchSuggestions)}
                loading={isSuggestionsLoading}
                onOpen={handleAutoCompleteOpen}
                open={isSearchOpen}
                onClose={handleAutoCompleteClose}
                filterOptions={filterOptions}
                onFocus={() => {}}
                onFocusOut={() => {}}
                onInputChange={handleKeyPress}
                setSearch={changeSearchTerm}
                clearSearchSuggestions={clearSearchSuggestions}
              />
            )}
          </Box>
          {!isApprovalDate && (
            <Tooltip
              title={
                (category.startsWith('atc') && 'ATC classification can only have exact match') ||
                (isApprovalDate && 'Date can only have exact match')
              }>
              <FormControlLabel
                sx={styles.advanceSearchExactMatch}
                disabled={disabled || category.startsWith('atc') || isApprovalDate}
                value={localExactMatch}
                defaultValue={localExactMatch}
                defaultChecked={localExactMatch}
                checked={localExactMatch}
                control={<Switch />}
                onChange={changeExactMatch}
                label='Exact Match'
                labelPlacement='start'
              />
            </Tooltip>
          )}
        </Stack>
        <Tooltip title='Remove item'>
          <IconButton
            sx={styles.advanceSearchRemoveListItem}
            disabled={disabled || rowsLength === 1}
            color='error'
            onClick={removeItem}>
            <RemoveCircleOutline />
          </IconButton>
        </Tooltip>
      </ListItem>
      <Stack
        width='100%'
        direction='row'
        justifyContent='center'
        display={rowIndex === rowsLength - 1 ? 'none' : 'flex'}>
        <Button
          sx={styles.nextSearchCondition}
          disabled={nextSearchConditionDisabled}
          id='conditionalButton'
          aria-controls={openMenu ? 'conditions-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={openMenu ? 'true' : undefined}
          variant='contained'
          disableElevation
          onClick={openCompareCondition}
          endIcon={<KeyboardArrowDownIcon />}
          onMouseEnter={() => setHighlightRowIds([rowIndex + 1, rowIndex])}
          onMouseLeave={() => setHighlightRowIds([])}>
          {nextSearchConditionDisabled ? 'AND' : compareCondition}
        </Button>
        <Menu
          sx={styles.conditionsMenu}
          id='demo-customized-menu'
          onMouseEnter={() => setHighlightRowIds([rowIndex + 1, rowIndex])}
          onMouseLeave={() => setHighlightRowIds([])}
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button'
          }}
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}>
          <MenuItem
            selected={compareCondition === 'AND'}
            onClick={setCompareCondition('AND')}
            disableRipple>
            AND
          </MenuItem>
          <MenuItem
            selected={compareCondition === 'OR'}
            onClick={setCompareCondition('OR')}
            disableRipple>
            OR
          </MenuItem>
        </Menu>
      </Stack>
    </>
  );
};
export default memo(AdvancedSearchItems);
