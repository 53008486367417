const accordionTitle = {
  color: '#1C170D',
  fontFamily: 'Mulish',
  fontSize: '14px',
  fontWeight: '600',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  ml: '8px'
};
const styles = {
  root: { backgroundColor: 'gray.50' },
  rootGrid: {
    paddingY: '24px',
    paddingLeft: { xs: '32px', lg: '80px' },
    paddingRight: { xs: '32px', lg: '160px' },
    maxHeight: 'calc(100vh - 76px)',
    my: 'auto',
    mx: 'auto',
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '1440px',
    columnSpacing: '16px',
    justifyContent: 'space-between'
  },
  navigationBox: {
    width: '187px',
    pr: '12px'
  },
  container: {
    bgcolor: 'background.paper',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'gray.300',
    maxHeight: '100%',
    height: '88vh',
    width: '100%',
    overflow: 'hidden'
  },
  containerWithoutBorder: {
    maxHeight: '100%',
    height: '88vh',
    width: '100%',
    overflow: 'hidden'
  },
  avatar: {
    selfAlign: 'center',
    backgroundColor: `white.main !important`,
    width: '40px !important',
    height: '40px !important',
    fontSize: '18px !important',
    color: `black.main !important`
  },
  userName: {
    fontSize: '14px',
    fontWeight: 700
  },
  userEmail: {
    fontSize: '14px',
    fontWeight: 400,
    color: 'gray.600',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  changePasswordButton: {
    padding: 0,
    textTransform: 'none',
    textDecoration: 'underline',
    fontSize: '14px',
    color: `primary.main !important`,
    '&:disabled': {
      color: 'grey !important'
    }
  },
  containerGrid: {
    pl: 2,
    flexGrow: 1
  },
  dateChip: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'gray.100',
    borderRadius: '16px',
    my: '8px',
    mx: 'auto',
    px: '12px ',
    py: '1px',
    alignItems: 'center',
    color: '#1C170D',
    fontFeatureSettings: "'dlig' on",
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '150%',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.200',
    cursor: 'default'
  },
  accordionTitle,
  accordionTitleWithUnderline: {
    ...accordionTitle,
    cursor: 'pointer',
    '&:hover': {
      color: 'primary.650',
      textDecorationLine: 'underline'
    }
  },
  documentLoadingStack: {
    width: '100%',
    height: '90vh'
  }
};

export default styles;
