import { useHistory, useParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import _ from 'lodash';
import { decodeBase64ToObject, encodeObjectToBase64 } from '../../../utils/encodeDecodeObject';
import { SourceDropdown } from '../../Home/types';
import {
  createSourceModuleDropdownMapping,
  createSourceModulePayloadMapping,
  extractSourceDropdown,
  getApplicationsSourceDropdown,
  getCTSourceDropdown,
  getDefaultApplicationsSourceDropDown,
  getDefaultCTSourceDropDown,
  getDefaultHomePageSourceDropDown
} from '../../Home/utils';
import getCategoryCount from './getCategoryCount';
import ResultsStore from '../../../store/SearchResults';
import RESULT_VIEW_TYPES from '../components/constants';
import { SOURCE_MENU_ITEMS } from '../../Home/const';
import Actions from '../../../store/SearchResults/actions';

const useEntities = () => {
  const history = useHistory();

  const [selectedSources, setSelectedSources] = useState<SourceDropdown[]>([]);

  const [category, setCategory] = useState<string>('');
  const [entityValue, setEntityValue] = useState<string>('');
  const [entityCategory, setEntityCategory] = useState<string>('');
  const [entityCategoryOptions, setEntityCategoryOptions] = useState<any>([]);
  const [entityTextOptions, setEntityTextOptions] = useState<any>([]);
  const [query, setQuery] = useState<string>('');
  const [entityLoading, setEntityLoading] = useState<boolean>(false);
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { payload }: any = useParams();
  const { search_type: searchType }: any = useMemo(() => {
    if (!payload) {
      return {
        search_term: '',
        source: '',
        search_type: ''
      };
    }
    return decodeBase64ToObject(payload);
  }, [payload]);

  const localResultsEntities = useRef<any>({});

  useEffect(() => {
    let selectedSourcesFromParams: SourceDropdown[] = [];
    if (payload) {
      const searchPayload: any = decodeBase64ToObject(payload);
      if (searchPayload && 'source' in searchPayload) {
        selectedSourcesFromParams = createSourceModuleDropdownMapping(searchPayload.source);
        let dropdownList = SOURCE_MENU_ITEMS;
        if (resultsState.viewType === RESULT_VIEW_TYPES.APPLICATION)
          dropdownList = getApplicationsSourceDropdown();
        else if (resultsState.viewType === RESULT_VIEW_TYPES.CT)
          dropdownList = getCTSourceDropdown();
        const newSelectedSources = extractSourceDropdown(dropdownList, selectedSourcesFromParams);
        setSelectedSources(newSelectedSources);
      }
    } else if (resultsState.viewType === RESULT_VIEW_TYPES.DOCUMENT)
      setSelectedSources(getDefaultHomePageSourceDropDown());
    else if (resultsState.viewType === RESULT_VIEW_TYPES.CT)
      setSelectedSources(getDefaultCTSourceDropDown());
    else setSelectedSources(getDefaultApplicationsSourceDropDown());
  }, [payload, resultsState.viewType]);

  const mapCatergoryName = (categoryName: string, count: number) => {
    return `${categoryName} (${count})`;
  };
  const getEntityOptions = async (results: any) => {
    // we need to reset the entityCategory and entityCategoryOptions because when filter is applied older count data shouldn't get displayed
    setEntityCategory('');
    setEntityCategoryOptions([]);
    setEntityLoading(true);
    const options: any = [];
    const entityTextList: any = [];

    if (results && 'entities' in results) {
      let categoryCount: any = [];
      if (results.search_term && resultsState.decryptedSource) {
        categoryCount = await getCategoryCount(
          resultsState.decryptedSource,
          results.search_term,
          resultsState.viewType as string,
          resultsState.filters
        );
      }
      setQuery(results?.query);
      const categoryValue = results.category_name ?? '';
      if (!entityValue) {
        setEntityValue(results.search_term ?? '');
      }
      const categoryList: any = [];
      const entityText: any = [];
      categoryCount.forEach((item: any) => {
        if (!categoryList.includes(item.category)) {
          options.push({
            label: mapCatergoryName(item.label, item.count),
            value: item.category,
            selected: categoryValue === item.category,
            count: item.count
          });
        }
        if (categoryValue === item.category) {
          setEntityCategory(mapCatergoryName(item.label, item.count));
          setCategory(categoryValue);
        }
        categoryList.push(item.entity_category);
      });
      results?.entities.forEach((item: any) => {
        if (!entityText.includes(item.entity_text)) {
          entityTextList.push({
            label: _.startCase(item.entity_text).replace(/_/g, ''),
            value: item.entity_text,
            selected: results.search_term === item.entity_text
          });
        }
        entityText.push(item.entity_text);
      });
    }
    setEntityCategoryOptions(options);
    setEntityTextOptions(entityTextList);
    setEntityLoading(false);
  };

  /**
   * @description This function is used to handle the source change
   * @param values - selected sources
   */
  const handleSourceChange = (values: SourceDropdown[]) => setSelectedSources(values);

  /**
   * @description This function is used to make the search
   * @param values - selected sources
   */
  const makeSearch = useCallback(
    (sources?: SourceDropdown[]) => {
      setEntityCategory('');
      setEntityCategoryOptions([]);
      // this check is necessary as if no filter is present and we are setting isSourceChangedWithFilters true by changing the source
      // then useEffect in useFetchResults custom hook won't be executed
      if (Object.keys(resultsState?.filters)?.length > 0) {
        resultsDispatch({ type: Actions.SET_FILTERS, value: {} });
        resultsDispatch({ type: Actions.SET_IS_SOURCE_CHANGED_WITH_FILTERS, value: true });
        resultsDispatch({ type: Actions.SET_IS_NEW_SEARCH_TERM, value: true });
      }

      // need to reset datagrid filters and sort by for CT when search is made since there might be change of source or category or entity
      resultsDispatch({ type: Actions.SET_CT_DATA_GRID_FILTERS, value: {} });
      resultsDispatch({ type: Actions.SET_CT_SORT_BY, value: {} });

      const finalSelectedSources = sources ?? selectedSources;
      const convertedSelectedSources = createSourceModulePayloadMapping(finalSelectedSources);
      const searchPayload: any = decodeBase64ToObject(payload);
      const newPayload = {
        ...searchPayload,
        // need to reset the search ids as when source is changed the search should be happened again
        source: convertedSelectedSources,
        document_search_id: ''
      };
      if (newPayload.entity_category) {
        delete newPayload.entity_category;
      }
      const encodedPayload = encodeObjectToBase64(newPayload) ?? '';
      history.push(`/search/${encodedPayload}`);
    },
    [selectedSources, category, entityValue, payload, resultsState?.filters]
  );

  const applyCategoryEntity = (option: any) => {
    const categoryList: any = [];
    entityCategoryOptions.forEach((item: any) => {
      if (item.value === option.value) {
        categoryList.push({
          label: option.label,
          value: option.value,
          selected: true
        });

        setEntityCategory(item.label);
        setCategory(option.value);
      } else {
        categoryList.push({
          label: item.label,
          value: item.value,
          selected: false
        });
      }
    });
    setEntityCategoryOptions(categoryList);
    const searchPayload: any = decodeBase64ToObject(payload);
    const newPayload = {
      ...searchPayload,
      query,
      entity_text: entityValue,
      entity_category: option.value
    };
    const encodedPayload = encodeObjectToBase64(newPayload);
    history.push(`/search/${encodedPayload}`);
  };
  const applyEntityText = (option: any) => {
    setEntityCategory('');
    setEntityCategoryOptions([]);
    const entityList: any = [];
    entityTextOptions.forEach((item: any) => {
      if (item.value === option.value) {
        entityList.push({
          label: _.startCase(option.value).replace(/_/g, ''),
          value: option.value,
          selected: true
        });
        setEntityValue(option.value);
      } else {
        entityList.push({
          label: _.startCase(item.value).replace(/_/g, ''),
          value: item.value,
          selected: false
        });
      }
    });
    setEntityTextOptions(entityList);
    const searchPayload: any = decodeBase64ToObject(payload);
    const newPayload = {
      ...searchPayload,
      query,
      entity_text: option.value
    };
    if (newPayload.entity_category) {
      delete newPayload.entity_category;
    }
    const encodedPayload = encodeObjectToBase64(newPayload);
    history.push(`/search/${encodedPayload}`);
  };

  useEffect(() => {
    const isPreviousEntitiesSame = _.isEqual(localResultsEntities.current, resultsState.entities);
    localResultsEntities.current = resultsState.entities;

    if (searchType !== 'advanced' && !isPreviousEntitiesSame) {
      getEntityOptions(resultsState.entities);
    }
  }, [resultsState.entities]);

  return {
    searchType,
    selectedSources,
    handleSourceChange,
    makeSearch,
    entityCategory,
    entityCategoryOptions,
    applyCategoryEntity,
    entityValue,
    entityTextOptions,
    applyEntityText,
    entityLoading
  };
};

export default useEntities;
