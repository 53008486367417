export default {
  SET_LOADING: 'SET_LOADING',
  SET_LIST_DATA: 'SET_LIST_DATA',
  SET_PROJECTS: 'SET_PROJECTS',
  SET_REFETCH: 'SET_REFETCH',
  SET_PROJECT_NAME: 'SET_PROJECT_NAME',
  TRIGGER_PROJECT_UPDATED: 'TRIGGER_PROJECT_UPDATED',
  SET_PROJECT_DESCRIPTION: 'SET_PROJECT_DESCRIPTION',
  APPEND_LIST_DATA: 'APPEND_LIST_DATA',
  SET_PROFILE_TIMESTAMP: 'SET_PROFILE_TIMESTAMP',
  SET_PAGINATION_ON_SCROLL: 'SET_PAGINATION_ON_SCROLL',
  CLEAR_USER_ACTIVITY_DATA: 'CLEAR_USER_ACTIVITY_DATA',
  SET_ACTIVITY_DATA: 'SET_ACTIVITY_DATA'
};
