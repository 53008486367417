/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { has } from 'lodash';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Divider, Skeleton } from '@mui/material';
import Button from '@mui/material/Button';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import comparisonClasses from './styles/LabelSelection.style';
import useLabels from './hooks/useLabels';
import { getComparisonSummary, getLabelComparision } from '../../api/pages/Reg360';
import DraftableComparisonView from './DraftableComparisonView';
import getComparisonFile from '../../helpers/draftableUtils';

import LabelList from './LabelList';

import { ReadOnlyMarkdown } from '../ChatRIA/component/MarkdownBox';
import GlobalStore from '../../store';
import GlobalActions from '../../store/actions';

export interface ApplicationProps {
  source: string;
  application_type?: string;
  application_number?: string;
  product_number?: string;
  vin?: string;
  application_name?: string;
  yj_code?: string;
}

export interface ModeOfComparisonDialog {
  child_labelList_dependent_on_parent: boolean;
  section_binding: boolean;
  allsections: boolean;
  default_section_all_section: boolean;
  parentRld?: boolean;
  childRld?: boolean;
  sortOnDate?: boolean;
}

interface ComparisonDialogProps {
  open: boolean;
  onClose: () => void;
  applications: Array<ApplicationProps>;
  mode: ModeOfComparisonDialog;
}

interface SelectedSectionProps extends ApplicationProps {
  section: string;
  pdfLink: string;
  submissionType: string;
  submissionNum: string;
  approvalDate: string;
  description: string;
}

const ComparisonDialog: React.FC<ComparisonDialogProps> = ({
  open,
  onClose,
  applications = [],
  mode
}) => {
  const [selectedApplications, setSelectedApplications]: [
    Array<ApplicationProps>,
    // eslint-disable-next-line no-unused-vars
    (appls: Array<ApplicationProps>) => void
  ] = useState([] as Array<ApplicationProps>);
  const [labelOrder, setLabelOrder] = useState(['parent', 'child']);
  const [parentUrl, setParentUrl] = useState('');
  const [childUrl, setChildUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showSummary, setShowSummary] = useState(false);
  const [comparisonSummary, setComparisonSummary] = useState('');
  const [switchTriggered, setSwitchTriggered] = useState(false);
  const [parentLabelSection, setParentLabelSection]: [
    SelectedSectionProps | undefined,
    // eslint-disable-next-line no-unused-vars
    (section: SelectedSectionProps | undefined) => void
  ] = useState();
  const anchorRef = useRef<HTMLButtonElement>(null);

  const { dispatch } = useContext(GlobalStore) as any;

  const [childLabelSection, setChildLabelSection]: [
    SelectedSectionProps | undefined,
    // eslint-disable-next-line no-unused-vars
    (section: SelectedSectionProps | undefined) => void
  ] = useState();

  // eslint-disable-next-line no-unused-vars
  const [viewURL, setViewerURL]: [string | undefined, (url: string | undefined) => void] =
    useState();

  const [oldLabelSection, setOldLabelSection]: [
    SelectedSectionProps | undefined,
    // eslint-disable-next-line no-unused-vars
    (section: SelectedSectionProps | undefined) => void
  ] = useState();

  const [newLabelSection, setNewLabelSection]: [
    SelectedSectionProps | undefined,
    // eslint-disable-next-line no-unused-vars
    (section: SelectedSectionProps | undefined) => void
  ] = useState();

  const [childLabels, setChildLabels] = useState();

  const {
    isLoading: parentLabelLoading,
    error: parentLabelLoadingError,
    labels: parentLabelList,
    getLabels: getParentLabels
  } = useLabels(mode.allsections);

  let {
    isLoading: childLabelLoading,
    error: childLabelLoadingError,
    labels: childLabelList,
    getLabels: getChildLabels
  } = useLabels(mode.allsections);

  if (mode.child_labelList_dependent_on_parent) {
    // if the child label list dependent on parent ;at first it should be the same,
    // so no need to call the api for getting the labellist
    childLabelLoading = parentLabelLoading;
    childLabelLoadingError = parentLabelLoadingError;
    childLabelList = parentLabelList;
    getChildLabels = getParentLabels;
  }

  useEffect(() => {
    const rldCompare = async () => {
      const oldLabelSection: SelectedSectionProps = {
        ...applications[0],
        section: '',
        pdfLink: '',
        submissionNum: '',
        submissionType: '',
        approvalDate: '',
        description: ''
      };
      const newLabelSection: SelectedSectionProps = {
        ...applications[1],
        section: '',
        pdfLink: '',
        submissionNum: '',
        submissionType: '',
        approvalDate: '',
        description: ''
      };
      const res = await getLabelComparision({
        parent_object: ((oldLabelSection?.application_type?.toUpperCase() as string) +
          oldLabelSection?.application_number) as string,
        child_object: ((newLabelSection?.application_type?.toUpperCase() as string) +
          newLabelSection?.application_number) as string
      });
      await setOldLabelSection({ ...oldLabelSection });
      await setNewLabelSection({ ...newLabelSection });
      if (res.data.success !== null) {
        setViewerURL(res.data.success);
      }
    };

    setSelectedApplications(applications);
    if (mode.parentRld && mode.childRld) {
      rldCompare();
      return;
    }
    if (applications.length === 2) {
      getParentLabels(
        applications[0].source,
        (applications?.[0]?.application_number ||
          applications?.[0]?.product_number ||
          applications[0]?.vin ||
          applications?.[0]?.yj_code) as string
      );
      if (!mode.child_labelList_dependent_on_parent) {
        getChildLabels(
          applications[1].source,
          (applications?.[1]?.application_number ||
            applications?.[1]?.product_number ||
            applications[1]?.vin ||
            applications?.[1]?.yj_code) as string
        );
      }
    }
  }, []);

  const handleLabelComparison = async () => {
    if (parentLabelSection && childLabelSection) {
      let oldLabelSection = null;
      let newLabelSection = null;
      // sort the data; older at parent .newer at child
      // only do this if mode.sortOnDate = true
      const sort = mode.sortOnDate !== false && !switchTriggered;
      if (sort) {
        oldLabelSection =
          parentLabelSection.approvalDate < childLabelSection.approvalDate
            ? JSON.parse(JSON.stringify(parentLabelSection))
            : JSON.parse(JSON.stringify(childLabelSection));

        newLabelSection =
          parentLabelSection.approvalDate < childLabelSection.approvalDate
            ? JSON.parse(JSON.stringify(childLabelSection))
            : JSON.parse(JSON.stringify(parentLabelSection));
      } else {
        oldLabelSection = JSON.parse(JSON.stringify(parentLabelSection));
        newLabelSection = JSON.parse(JSON.stringify(childLabelSection));
      }
      const pdfData = {
        parent_object: oldLabelSection?.pdfLink,
        child_object: newLabelSection?.pdfLink
      };
      setParentUrl(oldLabelSection?.pdfLink);
      setChildUrl(newLabelSection?.pdfLink);
      await setOldLabelSection(oldLabelSection);
      await setNewLabelSection(newLabelSection);

      const res = await getLabelComparision(pdfData);
      if (res.data.success !== null) {
        setViewerURL(res.data.success);
      }
    }
  };

  useEffect(() => {
    handleLabelComparison();
  }, [labelOrder]);

  const handleParentLabelSelect = (item: any, section: string) => {
    // this logic is for Child_label _dependent_on_parent

    if (mode.child_labelList_dependent_on_parent) {
      const copyChildLabelList = JSON.parse(JSON.stringify(childLabelList));
      const removedChildLabelList = copyChildLabelList.filter((ele: any) => {
        return !(
          ele.submission_num === item.submission_num &&
          ele.approval_date === item.approval_date &&
          ele.description === item.description
        );
      });
      setChildLabels(removedChildLabelList);
    }

    // this logic is for child_label _dependent_on_parent   ENDS

    // this logic is for section binding
    if (mode.section_binding) {
      if (section && childLabelSection) {
        // As the parent section has changed, so update the childLabelsection
        // we have to update the sectionPdfLink of the childLabelSection
        const childLabelApprovalDate = childLabelSection?.approvalDate;
        const childLabelSubNum = childLabelSection?.submissionNum;
        const childLabelSubType = childLabelSection?.submissionType;
        const childLabelDescription = childLabelSection?.description;
        const selectedLabel = childLabelList.find(
          (ele: any) =>
            ele.approval_date === childLabelApprovalDate &&
            ele.submission_num === childLabelSubNum &&
            ele.submission_type === childLabelSubType &&
            ele.description === childLabelDescription
        );
        // @ts-ignore
        setChildLabelSection(prev => ({
          // @ts-ignore
          ...prev,
          // @ts-ignore
          pdfLink: selectedLabel.label.sections.find(
            (sectionItem: any) => sectionItem.section === section
          )?.s3_link,
          section
        }));
      }
    }
    // this logic is for section binding           ENDS

    //  if we want default section to be all_sections
    const section_to_add = !section && mode.default_section_all_section ? 'all_sections' : section;
    const labelData: SelectedSectionProps = {
      section: section_to_add,
      pdfLink: item.label.sections.find(
        (sectionItem: any) => sectionItem.section === section_to_add
      )?.s3_link,
      submissionType: item.submission_type,
      submissionNum: item.submission_num,
      approvalDate: item.approval_date,
      description: item.description,
      source: selectedApplications[0]?.source ? selectedApplications[0]?.source : '',
      application_type:
        selectedApplications[0]?.source.toLocaleLowerCase() === 'us'
          ? selectedApplications[0]?.application_type
          : '',
      application_number: selectedApplications[0]?.application_number
        ? selectedApplications[0]?.application_number
        : selectedApplications[0]?.product_number,
      application_name: selectedApplications[0]?.application_name
        ? selectedApplications[0]?.application_name
        : ''
    };
    setParentLabelSection(labelData);
  };

  const handleChildLabelSelect = async (item: any, section: string) => {
    // this logic binds section from parent when we select a new label
    let sectionFromPrent =
      !section && parentLabelSection?.section ? parentLabelSection?.section : section;
    if (!sectionFromPrent) {
      sectionFromPrent = 'all_sections';
    }
    const sectionNotFromParent =
      !section && mode.default_section_all_section ? 'all_sections' : section;
    // this logic binds section from parent when we select a new label END
    const labelData: SelectedSectionProps = {
      section: mode.section_binding ? sectionFromPrent : sectionNotFromParent,
      pdfLink: item.label.sections.find(
        (sectionItem: any) => sectionItem.section === sectionFromPrent
      )?.s3_link,
      submissionType: item.submission_type,
      submissionNum: item.submission_num,
      approvalDate: item.approval_date,
      description: item.description,
      source: selectedApplications[1]?.source ? selectedApplications[1]?.source : '',
      application_type:
        selectedApplications[1]?.source.toLocaleLowerCase() === 'us'
          ? selectedApplications[1]?.application_type
          : '',
      application_number: selectedApplications[1]?.application_number
        ? selectedApplications[1]?.application_number
        : selectedApplications[1]?.product_number,
      application_name: selectedApplications[1]?.application_name
        ? selectedApplications[1]?.application_name
        : ''
    };

    // this logic is for section binding
    if (mode.section_binding) {
      if (section) {
        // section is present in child ---> update parentChildSection
        // we have to update the sectionPdfLink of the parentLabelSection
        const parentLabelApprovalDate = parentLabelSection?.approvalDate;
        const parentLabelSubNum = parentLabelSection?.submissionNum;
        const parentLabelSubType = parentLabelSection?.submissionType;
        const parentLabelDescription = parentLabelSection?.description;
        const selectedLabel = parentLabelList.find(
          (ele: any) =>
            ele.approval_date === parentLabelApprovalDate &&
            ele.submission_num === parentLabelSubNum &&
            ele.submission_type === parentLabelSubType &&
            ele.description === parentLabelDescription
        );
        // @ts-ignore
        setParentLabelSection(prev => ({
          // @ts-ignore
          ...prev,
          // @ts-ignore
          pdfLink: selectedLabel.label.sections.find(
            (sectionItem: any) => sectionItem.section === section
          )?.s3_link,
          // @ts-ignore
          section
        }));
      }
    }
    // this logic is for section binding END
    setChildLabelSection(labelData);
  };

  const downloadFile = () => {
    try {
      const identifierInURL = viewURL?.split('/')[7];
      const identifier = identifierInURL?.split('?')[0];
      getComparisonFile(identifier);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
  const labelMappings = {
    us: (selectedApplication: any) => selectedApplication?.application_type as string,
    ca: () => 'CA ',
    hpra: () => 'HPRA ',
    jp: () => 'JP ',
    hma: () => 'HMA ',
    default: (selectedApplication: any) =>
      (selectedApplication?.center === 'ema-who' && 'EMEA-H-W-') || 'EMEA-H-C-'
  };

  const getApplicationString = (application: any) => {
    let applicationString = '';
    const source = application.source.toLowerCase() as keyof typeof labelMappings;
    const labelFunction = labelMappings[source] || labelMappings.default;
    // for us we need to show the trade name as header of pdf
    if (
      source === ('us' as keyof typeof labelMappings) ||
      source === ('eu' as keyof typeof labelMappings)
    ) {
      if (!(mode.parentRld && mode.childRld)) {
        return `${application?.application_name || ''}  ${application?.approvalDate || ''}`;
      }
    }
    applicationString = `${labelFunction(application)}${
      application.application_number || application.product_number
    } ${application?.approvalDate}`;
    return applicationString;
  };

  const fetchComparisonSummary = async (
    oldLabelDetails: { pdfLink?: string } | null = null,
    newLabelDetails: { pdfLink?: string } | null = null
  ) => {
    setIsLoading(true);
    const res = await getComparisonSummary({
      child_pdf_details: {
        url: oldLabelDetails?.pdfLink ?? childUrl,
        ...(oldLabelDetails ?? oldLabelSection)
      },
      parent_pdf_details: {
        url: newLabelDetails?.pdfLink ?? parentUrl,
        ...(newLabelDetails ?? newLabelSection)
      }
    });

    if (res?.status === 200) {
      setComparisonSummary(res?.data?.body?.result?.content || '');
      if (!has(res?.data?.body?.result, 'content')) {
        setComparisonSummary('Comparison summary not available at this time');
      }
    }
    if (res?.status !== 200) {
      setComparisonSummary('Comparison summary not available at this time');
    }
    setIsLoading(false);
  };

  const handleComparionsSummary = async () => {
    if (comparisonSummary) {
      setShowSummary(true);
      return;
    }

    // check if all sections summary is requested
    if (
      oldLabelSection?.section === 'all_sections' ||
      newLabelSection?.section === 'all_sections'
    ) {
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: {
          status: true,
          message: 'Please select a specific section for both documents',
          color: 'info'
        }
      });
      return;
    }
    setShowSummary(true);

    setIsLoading(true);
    await fetchComparisonSummary();
    setIsLoading(false);
  };
  const handleSwapLabels = () => {
    // Swap the order of labels in the state
    const currentOrder = [...labelOrder]; // Create a copy of the array to avoid mutating state directly
    [currentOrder[0], currentOrder[1]] = [currentOrder[1], currentOrder[0]];
    setChildLabelSection(parentLabelSection);
    setParentLabelSection(childLabelSection);
    setOldLabelSection(newLabelSection);
    setNewLabelSection(oldLabelSection);
    setLabelOrder(currentOrder);
    setSwitchTriggered(true);
  };
  return (
    <Dialog
      fullScreen
      fullWidth
      maxWidth='lg'
      aria-labelledby='customized-dialog-title'
      open={open}
      sx={{ width: '100%' }}
      onClose={onClose}>
      {/* Heading section for comparison dialog box */}
      <Stack
        direction='row'
        justifyContent='space-between'
        p={1}
        alignItems='center'
        sx={{ bgcolor: 'primary.main' }}>
        <Box />
        <Stack direction='row' spacing={1}>
          <Typography variant='h3' fontWeight='bold' sx={{ color: 'white.main' }}>
            Label Comparison
          </Typography>
        </Stack>
        <IconButton aria-label='delete' onClick={onClose} size='small'>
          <HighlightOffOutlinedIcon
            fontSize='inherit'
            sx={{ height: '24px', width: '24px', color: 'white.main' }}
          />
        </IconButton>
      </Stack>
      <DialogContent>
        {viewURL ? (
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            position='absolute'
            width={showSummary ? '62%' : '100%'}>
            <Stack direction='row' spacing={1}>
              <Tooltip title='Click to Switch windows' arrow>
                <IconButton
                  ref={anchorRef} // Add the ref to IconButton
                  aria-label='menu'
                  aria-controls='menu-list'
                  aria-haspopup='true'
                  onClick={handleSwapLabels}
                  style={{ color: '#90EE90' }}>
                  <SwapHorizontalCircleIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        ) : null}
        <Grid container spacing={2}>
          {/* comparison pdf viewer grid */}
          <Grid item xs={showSummary ? 8 : 12}>
            <Box>
              {viewURL ? (
                <DraftableComparisonView
                  viewerURL={viewURL}
                  onNewCompare={() => {
                    setViewerURL(undefined);
                    setParentLabelSection(undefined);
                    setChildLabelSection(undefined);
                  }}
                  downloadFile={downloadFile}
                  parentApplicationNumber={getApplicationString(oldLabelSection)}
                  parentApplicationName={
                    oldLabelSection?.application_name ? oldLabelSection?.application_name : ''
                  }
                  // @ts-ignore
                  childApplicationNumber={getApplicationString(newLabelSection)}
                  childApplicationName={
                    newLabelSection?.application_name ? newLabelSection.application_name : ''
                  }
                  showFooter={false}
                  showNewCompare={!(mode.childRld && mode.parentRld)}
                />
              ) : !(mode?.parentRld && mode?.childRld) ? (
                <>
                  <Box display='flex' height='80%' sx={{ maxHeight: '75vh' }}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} height='100%'>
                        <LabelList
                          isLoading={
                            labelOrder[0] === 'parent' ? parentLabelLoading : childLabelLoading
                          }
                          selectedApplication={
                            labelOrder[0] === 'parent'
                              ? selectedApplications?.[0]
                              : selectedApplications?.[1]
                          }
                          onLabelSelect={
                            labelOrder[0] === 'parent'
                              ? handleParentLabelSelect
                              : handleChildLabelSelect
                          }
                          labelList={
                            labelOrder[0] === 'parent'
                              ? parentLabelList
                              : mode.child_labelList_dependent_on_parent
                              ? childLabels
                              : childLabelList
                          }
                          selectedLabel={
                            labelOrder[0] === 'parent' ? parentLabelSection : childLabelSection
                          }
                          errorMessage={
                            labelOrder[0] === 'parent'
                              ? parentLabelLoadingError
                              : childLabelLoadingError
                          }
                        />
                      </Grid>
                      <Grid item xs={6} alignItems='flex-start' height='100%'>
                        <LabelList
                          isLoading={
                            labelOrder[1] === 'child' ? childLabelLoading : parentLabelLoading
                          }
                          selectedApplication={
                            labelOrder[1] === 'child'
                              ? selectedApplications?.[1]
                              : selectedApplications?.[0]
                          }
                          onLabelSelect={
                            labelOrder[1] === 'child'
                              ? handleChildLabelSelect
                              : handleParentLabelSelect
                          }
                          labelList={
                            labelOrder[1] === 'child'
                              ? mode.child_labelList_dependent_on_parent
                                ? childLabels
                                : childLabelList
                              : parentLabelList
                          }
                          selectedLabel={
                            labelOrder[1] === 'child' ? childLabelSection : parentLabelSection
                          }
                          errorMessage={
                            labelOrder[1] === 'child'
                              ? childLabelLoadingError
                              : parentLabelLoadingError
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Stack mt={2} direction='row' justifyContent='center' height='10%'>
                    <Button
                      color='primary'
                      aria-label='add'
                      variant='contained'
                      onClick={handleLabelComparison}
                      disabled={!(parentLabelSection?.pdfLink && childLabelSection?.pdfLink)}
                      sx={{
                        width: '400px'
                      }}>
                      <Typography variant='h4' sx={comparisonClasses.labelTitle2}>
                        Compare
                      </Typography>
                      {/* </Button> */}
                    </Button>
                  </Stack>
                </>
              ) : null}
            </Box>
          </Grid>

          {/* summary paragraph grid */}
          {showSummary && (
            <Grid item xs={4}>
              <Stack p={2} spacing={2} sx={comparisonClasses.container}>
                <Stack spacing={0} alignItems='flex-start' justifyContent='flex-start'>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    spacing={1}
                    sx={{ width: '100%' }}>
                    <Stack direction='row' spacing={1}>
                      <Typography variant='h4' sx={comparisonClasses.title}>
                        Comparison Summary
                      </Typography>
                      <AutoAwesomeIcon fontSize='small' sx={{ color: 'primary.avatar' }} />
                    </Stack>
                    <IconButton onClick={() => setShowSummary(false)}>
                      <CloseOutlinedIcon sx={{ fontSize: 24 }} color='primary' />
                    </IconButton>
                  </Stack>

                  <Typography variant='body1' sx={{ color: 'gray.dark' }}>
                    (Generative AI is experimental. Curated response quality may vary.)
                  </Typography>

                  {isLoading ? (
                    <Stack sx={{ width: '100%' }}>
                      <Skeleton
                        animation='wave'
                        sx={{ bgcolor: 'primary.backgroundLight', height: 30 }}
                      />
                      <Skeleton
                        animation='wave'
                        width='60%'
                        sx={{ bgcolor: 'primary.backgroundLight', height: 30 }}
                      />
                      <Skeleton
                        animation='wave'
                        width='30%'
                        sx={{ bgcolor: 'primary.backgroundLight', height: 30 }}
                      />
                    </Stack>
                  ) : (
                    <Divider />
                  )}
                  <Stack
                    sx={{
                      width: '100%',
                      maxHeight: '65vh',
                      overflowY: 'auto'
                    }}>
                    <ReadOnlyMarkdown markdown={comparisonSummary} />
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>

        {/* footer */}
        {viewURL && (
          <Stack direction='row' justifyContent='space-between' mt={3} spacing={2}>
            <Stack direction='row' spacing={2} alignItems='center'>
              {/* # show new Comapre button in all cases except the RLD mode */}
              {!(mode?.parentRld && mode?.childRld) && (
                <Button
                  onClick={() => {
                    setViewerURL(undefined);
                    setParentLabelSection(undefined);
                    setChildLabelSection(undefined);
                    setShowSummary(false);
                    setComparisonSummary('');
                  }}
                  variant='outlined'
                  size='small'
                  sx={{ width: '400px' }}>
                  <Typography
                    variant='subtitle1'
                    sx={{ textTransform: 'capitalize', color: 'primary.main', fontWeight: 'bold' }}>
                    New Compare
                  </Typography>
                </Button>
              )}
              <Button
                variant='contained'
                size='small'
                onClick={downloadFile}
                sx={{ width: '400px' }}>
                <Typography
                  variant='subtitle1'
                  sx={{
                    textTransform: 'capitalize',
                    color: 'white.main',
                    fontWeight: 'bold'
                  }}>
                  Download comparison
                </Typography>
              </Button>
            </Stack>

            {/* generate comparison summary button */}
            {!showSummary && parentUrl && childUrl && (
              <Button
                disabled={!viewURL}
                variant='outlined'
                onClick={handleComparionsSummary}
                sx={{
                  textTransform: 'capitalize'
                }}>
                <AutoAwesomeIcon fontSize='small' /> Generate summary
              </Button>
            )}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(ComparisonDialog);
