import { Box } from '@mui/material';
import React from 'react';

const PreparedByName = ({ name }: any) => {
  return (
    <Box>
      <Box>Prepared by</Box>
      <Box sx={{ fontWeight: 'bold' }}>{name}</Box>
    </Box>
  );
};

export default React.memo(PreparedByName);
