const AdvanceSearchLogo = () => {
  // return (
  //   <svg
  //     width='100%'
  //     height='100%'
  //     viewBox='0 0 21 18'
  //     fill='#DFDFDF'
  //     stroke='#DFDFDF'
  //     xmlns='http://www.w3.org/2000/svg'>
  //     <path
  //       d='M13.3998 13.3961C12.5296 13.3961 11.679 13.138 10.9554 12.6546C10.2319 12.1711 9.66793 11.484 9.33492 10.68C9.00191 9.87603 8.91478 8.99137 9.08454 8.13789C9.25431 7.28441 9.67335 6.50044 10.2887 5.88511C10.904 5.26979 11.688 4.85075 12.5415 4.68098C13.3949 4.51121 14.2796 4.59834 15.0836 4.93135C15.8875 5.26437 16.5747 5.8283 17.0581 6.55185C17.5416 7.27539 17.7996 8.12605 17.7996 8.99625C17.7996 10.1632 17.3361 11.2823 16.511 12.1074C15.6858 12.9325 14.5667 13.3961 13.3998 13.3961ZM13.3998 5.33574C12.6747 5.33574 11.9658 5.55078 11.3628 5.95366C10.7599 6.35654 10.2899 6.92917 10.0124 7.59914C9.73489 8.26911 9.66228 9.00632 9.80376 9.71756C9.94523 10.4288 10.2944 11.0821 10.8072 11.5949C11.32 12.1076 11.9733 12.4568 12.6845 12.5983C13.3958 12.7398 14.133 12.6672 14.8029 12.3897C15.4729 12.1122 16.0455 11.6422 16.4484 11.0393C16.8513 10.4363 17.0663 9.72742 17.0663 9.00225C17.0663 8.52076 16.9715 8.04398 16.7872 7.59914C16.603 7.1543 16.3329 6.7501 15.9924 6.40964C15.652 6.06917 15.2478 5.7991 14.8029 5.61484C14.3581 5.43058 13.8813 5.33574 13.3998 5.33574Z'
  //       fill='inherit'
  //       stroke='inherit'
  //     />
  //     <path
  //       d='M19.1905 15.6042L19.1897 15.6035L15.8072 12.221L15.7991 12.2128L15.7993 12.2127C15.6923 12.0955 15.6347 11.9415 15.6383 11.7829C15.642 11.6243 15.7066 11.4732 15.8188 11.361C15.931 11.2488 16.0821 11.1842 16.2407 11.1806C16.3993 11.1769 16.5532 11.2346 16.6704 11.3415L16.6788 11.3492L16.6786 11.3494L20.0635 14.7316C20.0636 14.7316 20.0637 14.7317 20.0637 14.7317L20.0638 14.7319L19.8871 14.9087L19.1905 15.6042ZM19.1905 15.6042C19.298 15.7109 19.4407 15.774 19.5912 15.7824L19.1905 15.6042Z'
  //       fill='inherit'
  //       stroke='inherit'
  //       strokeWidth='0.5'
  //     />
  //     <path
  //       d='M13.8241 1.7333H1.36665C1.26941 1.7333 1.17615 1.69467 1.10739 1.62591C1.03863 1.55715 1 1.46389 1 1.36665C1 1.26941 1.03863 1.17615 1.10739 1.10739C1.17615 1.03863 1.26941 1 1.36665 1H13.8241C13.9214 1 14.0146 1.03863 14.0834 1.10739C14.1522 1.17615 14.1908 1.26941 14.1908 1.36665C14.1908 1.46389 14.1522 1.55715 14.0834 1.62591C14.0146 1.69467 13.9214 1.7333 13.8241 1.7333Z'
  //       fill='inherit'
  //       stroke='inherit'
  //     />
  //     <path
  //       d='M13.8241 16.9999H1.36665C1.26941 16.9999 1.17615 16.9613 1.10739 16.8925C1.03863 16.8238 1 16.7305 1 16.6333C1 16.536 1.03863 16.4428 1.10739 16.374C1.17615 16.3052 1.26941 16.2666 1.36665 16.2666H13.8241C13.9214 16.2666 14.0146 16.3052 14.0834 16.374C14.1522 16.4428 14.1908 16.536 14.1908 16.6333C14.1908 16.7305 14.1522 16.8238 14.0834 16.8925C14.0146 16.9613 13.9214 16.9999 13.8241 16.9999Z'
  //       fill='inherit'
  //       stroke='inherit'
  //     />
  //     <path
  //       d='M7.22907 5.36843H1.36665C1.26941 5.36843 1.17615 5.32981 1.10739 5.26104C1.03863 5.19228 1 5.09903 1 5.00178C1 4.90454 1.03863 4.81128 1.10739 4.74252C1.17615 4.67376 1.26941 4.63513 1.36665 4.63513H7.22907C7.32632 4.63513 7.41958 4.67376 7.48834 4.74252C7.5571 4.81128 7.59573 4.90454 7.59573 5.00178C7.59573 5.09903 7.5571 5.19228 7.48834 5.26104C7.41958 5.32981 7.32632 5.36843 7.22907 5.36843Z'
  //       fill='inherit'
  //       stroke='inherit'
  //     />
  //     <path
  //       d='M7.22907 13.3648H1.36665C1.26941 13.3648 1.17615 13.3262 1.10739 13.2574C1.03863 13.1887 1 13.0954 1 12.9982C1 12.9009 1.03863 12.8077 1.10739 12.7389C1.17615 12.6702 1.26941 12.6315 1.36665 12.6315H7.22907C7.32632 12.6315 7.41958 12.6702 7.48834 12.7389C7.5571 12.8077 7.59573 12.9009 7.59573 12.9982C7.59573 13.0954 7.5571 13.1887 7.48834 13.2574C7.41958 13.3262 7.32632 13.3648 7.22907 13.3648Z'
  //       fill='inherit'
  //       stroke='inherit'
  //     />
  //     <path
  //       d='M5.76313 9.3663H1.36665C1.3185 9.3663 1.27082 9.35681 1.22634 9.33839C1.18186 9.31996 1.14144 9.29296 1.10739 9.25891C1.07334 9.22486 1.04634 9.18444 1.02791 9.13996C1.00948 9.09547 1 9.0478 1 8.99965C1 8.9515 1.00948 8.90382 1.02791 8.85934C1.04634 8.81485 1.07334 8.77443 1.10739 8.74039C1.14144 8.70634 1.18186 8.67933 1.22634 8.66091C1.27082 8.64248 1.3185 8.633 1.36665 8.633H5.76647C5.81462 8.633 5.8623 8.64248 5.90678 8.66091C5.95126 8.67933 5.99168 8.70634 6.02573 8.74039C6.05978 8.77443 6.08678 8.81485 6.10521 8.85934C6.12364 8.90382 6.13312 8.9515 6.13312 8.99965C6.13312 9.0478 6.12364 9.09547 6.10521 9.13996C6.08678 9.18444 6.05978 9.22486 6.02573 9.25891C5.99168 9.29296 5.95126 9.31996 5.90678 9.33839C5.8623 9.35681 5.81462 9.3663 5.76647 9.3663H5.76313Z'
  //       fill='inherit'
  //       stroke='inherit'
  //     />
  //   </svg>
  // );
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      viewBox='0 0 26.181 24'
      fill='#202020'
    >
      <g id='Group_316' data-name='Group 316' transform='translate(9.693 5.404)'>
        <path
          id='Path_190'
          data-name='Path 190'
          d='M15.6,19.191a6.6,6.6,0,1,1,6.6-6.6A6.6,6.6,0,0,1,15.6,19.191ZM15.6,7.1a5.5,5.5,0,1,0,5.5,5.5A5.5,5.5,0,0,0,15.6,7.1Z'
          transform='translate(-9 -6)'
          fill='inherit'
        />
      </g>
      <g id='Group_317' data-name='Group 317' transform='translate(20.008 15.629)'>
        <path
          id='Path_191'
          data-name='Path 191'
          d='M24.008,21.557a.552.552,0,0,1-.389-.16l-5.074-5.074a.55.55,0,0,1,.777-.777L24.4,20.62a.549.549,0,0,1-.388.938Z'
          transform='translate(-18.384 -15.385)'
          fill='inherit'
        />
      </g>
      <g id='Group_318' data-name='Group 318' transform='translate(0 0)'>
        <path
          id='Path_192'
          data-name='Path 192'
          d='M19.237,2.1H.55A.55.55,0,0,1,.55,1H19.237a.55.55,0,0,1,0,1.1Z'
          transform='translate(0 -1)'
          fill='inherit'
        />
      </g>
      <g id='Group_319' data-name='Group 319' transform='translate(0 22.901)'>
        <path
          id='Path_193'
          data-name='Path 193'
          d='M19.237,23.1H.55a.55.55,0,0,1,0-1.1H19.237a.55.55,0,0,1,0,1.1Z'
          transform='translate(0 -22)'
          fill='inherit'
        />
      </g>
      <g id='Group_320' data-name='Group 320' transform='translate(0 5.453)'>
        <path
          id='Path_194'
          data-name='Path 194'
          d='M9.344,7.1H.55A.55.55,0,0,1,.55,6H9.344a.55.55,0,1,1,0,1.1Z'
          transform='translate(0 -6)'
          fill='inherit'
        />
      </g>
      <g id='Group_321' data-name='Group 321' transform='translate(0 17.448)'>
        <path
          id='Path_195'
          data-name='Path 195'
          d='M9.344,18.1H.55a.55.55,0,1,1,0-1.1H9.344a.55.55,0,1,1,0,1.1Z'
          transform='translate(0 -17)'
          fill='inherit'
        />
      </g>
      <g id='Group_322' data-name='Group 322' transform='translate(0 11.45)'>
        <path
          id='Path_196'
          data-name='Path 196'
          d='M7.145,12.6H.55a.55.55,0,1,1,0-1.1h6.6a.55.55,0,1,1,0,1.1Z'
          transform='translate(0 -11.5)'
          fill='inherit'
        />
      </g>
    </svg>
  );
};
export default AdvanceSearchLogo;
