import { Box, Divider } from '@mui/material';
import React from 'react';

const CoverPageTemplate2 = ({ children }: any) => {
  const [
    LogoComponent,
    TitleComponent,
    DescriptionComponent,
    PreparedByName,
    PreparedDate,
    PoweredByLogo
  ]: any = children;

  return (
    <Box height='100%' width='100%' position='relative'>
      <Box
        bgcolor='#30B09C'
        height={16}
        sx={{ backgroundImage: 'linear-gradient(to right, #34B27E, #30B09C)' }}
      />
      <Box p={4}>
        <Box display='flex' pt={4} pl={6}>
          {LogoComponent}
        </Box>
        <Box p={6} pt={14}>
          <Box p={5} pt={2} pb={2}>
            {TitleComponent}
          </Box>
          <Divider sx={{ borderColor: 'black.main' }} />
          <Box p={3} pl={4} pr={4}>
            {DescriptionComponent}
          </Box>
        </Box>
        <Box pt={8} textAlign='center'>
          {PreparedDate}
        </Box>
        <Box pt={16} textAlign='center'>
          {PreparedByName}
        </Box>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          textAlign: 'center',
          width: '100%'
        }}>
        <Box display='inline-block' sx={{ scale: 1.3, pb: 2 }}>
          {PoweredByLogo}
        </Box>
        <Box height={112} sx={{ backgroundImage: 'linear-gradient(to right, #34B27E, #30B09C)' }} />
      </Box>
    </Box>
  );
};

export default React.memo(CoverPageTemplate2);
