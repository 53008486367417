// material ui
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import ProductRow from './productRow';
// context
// styles
import Styles from '../styles/ProductsTab.styles';
import { useEffect, useState } from 'react';
import { SOURCE_PRODUCT_TABS } from '../const';
import { isEmpty } from 'lodash';

// eslint-disable-next-line react/prop-types
const ProductsTab = ({ cardState, source }) => {
  const classes = Styles();
  const [tabHeading, setTabHeading] = useState([]);
  useEffect(() => {
    if (source in SOURCE_PRODUCT_TABS) {
      setTabHeading(SOURCE_PRODUCT_TABS[source]);
    } else {
      setTabHeading(SOURCE_PRODUCT_TABS['default']);
    }
  }, [source]);

  return (
    cardState && (
      <Box className={classes.root}>
        <Grid container>
          {!isEmpty(cardState?.extendedCard?.products) &&
            tabHeading?.map(item => (
              <Grid item key={item.key} xs={item.size}>
                <Typography align='center' className={classes.heading}>
                  {item.value}
                </Typography>
              </Grid>
            ))}
        </Grid>
        <Stack spacing={2} marginTop={2} className={classes.content}>
          {
            // eslint-disable-next-line react/prop-types
            Object.keys(cardState.extendedCard.products).map(item => {
              return (
                <ProductRow
                  tabHeading={tabHeading}
                  item={
                    // eslint-disable-next-line react/prop-types
                    cardState.extendedCard.products[item]
                  }
                  name={item}
                  key={item}
                  source={source}
                />
              );
            })
          }
        </Stack>
      </Box>
    )
  );
};

export default ProductsTab;
