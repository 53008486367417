import React, { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Box, Chip, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { identifierKey, LABEL_COLOR, sourceFlagMapping } from '../../SearchResults/constants';
import styles from '../styles/cardsStyles.styles';
import FavoriteAndProjectActions from '../../../components/FavoritesAndProjectsAction/FavoriteAndProjectActions';

import UserProfileStore from '../../../store/UserProfile/index';
import UserProfileActions from '../../../store/UserProfile/actions';
import SubscriptionActions from '../../../components/Subscription/SubscriptionActions';
import NotificationsStore from '../../../store/Notifications';
import NotificationsActions from '../../../store/Notifications/actions';

interface UserProfileCardsProps {
  data: any;
  cardType?: 'FavoritesAndProjects' | 'Subscriptions';
}

const UserProfileCards = ({ data, cardType }: UserProfileCardsProps) => {
  const { title, identifier, source, labels, groupTitle, application_type: type } = data;
  const location = useLocation();
  const { projectId }: any = useParams();
  const { profileState, profileDispatch } = useContext(UserProfileStore) as any;
  const { notificationsState, notificationsDispatch } = useContext(NotificationsStore);

  const FlagComponent = sourceFlagMapping[source];

  const handleReg360 = () => {
    const module = 'core';
    const applicationNumber = encodeURIComponent(identifier);
    try {
      let url = '';
      const navigation = JSON.parse((localStorage.getItem('navigation') as any) ?? '{}');
      if (source?.toLowerCase() === 'us') {
        navigation.regulatory = {
          url: `/regulatory360/${module}/${applicationNumber}/${source}?type=${type}`,
          value: `Regulatory360:${type}${applicationNumber}`
        };
      } else {
        navigation.regulatory = {
          url: `/regulatory360/${module}/${applicationNumber}/${source}`,
          value: `Regulatory360:${applicationNumber}`
        };
      }
      localStorage.setItem('navigation', JSON.stringify(navigation));
      if (source?.toLowerCase() === 'us') {
        url = `/regulatory360/${module}/${applicationNumber}/${source?.toLowerCase()}?type=${type}&tabname=approvalHistory`;
      } else {
        url = `/regulatory360/${module}/${applicationNumber}/${source}?tabname=approvalHistory`;
      }
      window.open(url, '_blank');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const handleFavorite = ({ isFavorite }: { isFavorite: boolean }) => {
    const applications = profileState?.listData ?? [];
    const selectedApplicationIndex = applications?.findIndex((x: any) => {
      const appIdentifierKey = identifierKey[source];
      const appIdentifier = x?.[appIdentifierKey] ?? x?.application_number;
      return x.source === source && appIdentifier === identifier;
    });
    if (selectedApplicationIndex === -1) return;

    const newApplications = [...applications];
    if (location.pathname.startsWith('/account/favorites')) {
      // isFavorite = true cannot be case here
      if (isFavorite) return;
      newApplications.splice(selectedApplicationIndex, 1);
    } else {
      newApplications[selectedApplicationIndex].isFavorite = isFavorite;
    }
    profileDispatch({
      type: UserProfileActions.SET_LIST_DATA,
      value: newApplications
    });
  };

  const handleProject = ({ project }: { project: any }) => {
    const applications = profileState?.listData ?? [];
    const selectedApplicationIndex = applications?.findIndex((x: any) => {
      const appIdentifierKey = identifierKey[source];
      const appIdentifier = x?.[appIdentifierKey] ?? x?.application_number;
      return x.source === source && appIdentifier === identifier;
    });
    if (selectedApplicationIndex === -1) return;
    const newApplications = [...applications];
    if (
      location.pathname.startsWith('/account/project') &&
      projectId === project?.project_id.toString()
    ) {
      if (!project?.inProject) return;
      newApplications.splice(selectedApplicationIndex, 1);
    } else if (project?.inProject) {
      newApplications[selectedApplicationIndex].inProjects = newApplications[
        selectedApplicationIndex
      ].inProjects.filter(
        (p: any) => (p?.project_id ?? p?.id) !== (project?.project_id ?? project?.id)
      );
    } else {
      newApplications[selectedApplicationIndex].inProjects = [
        ...newApplications[selectedApplicationIndex].inProjects,
        { id: project?.project_id ?? project?.id, name: project?.name }
      ];
    }
    profileDispatch({
      type: UserProfileActions.SET_LIST_DATA,
      value: newApplications
    });
  };

  const handleSubscription = ({ isSubscribed }: { isSubscribed: boolean }) => {
    const applications = notificationsState?.subscribedApplications ?? [];
    const selectedApplicationIndex = applications?.findIndex((x: any) => {
      const appIdentifierKey = identifierKey[source];
      const appIdentifier = x?.[appIdentifierKey] ?? x?.application_number;
      return x.source === source && appIdentifier === identifier;
    });
    if (selectedApplicationIndex === -1) return;

    const newApplications = [...applications];
    if (location.pathname.startsWith('/notifications/settings')) {
      // isSubscribed = true cannot be case here
      if (isSubscribed) return;
      newApplications.splice(selectedApplicationIndex, 1);
    } else {
      newApplications[selectedApplicationIndex].isSubscribed = isSubscribed;
    }
    notificationsDispatch({
      type: NotificationsActions.SET_SUBSCRIBED_APPLICATIONS,
      value: newApplications
    });
  };

  return (
    <Box>
      <Grid item xs={12} md={6} lg={3} xl={3} key={data.identifier}>
        <Box sx={styles.root} onClick={() => handleReg360()}>
          <Box display='flex' flexDirection='column' width='100%'>
            <Tooltip title={groupTitle}>
              <Typography color='gray' fontSize={12} maxHeight={24} sx={styles.heading}>
                {groupTitle}
              </Typography>
            </Tooltip>
            <Box display='flex' flexDirection='row' width='100%' alignItems='center'>
              <Tooltip title={title}>
                <Typography
                  color='black'
                  fontWeight='bold'
                  fontSize={16}
                  maxHeight={24}
                  sx={styles.heading}>
                  {title}
                </Typography>
              </Tooltip>
            </Box>
            <Stack direction='row' justifyContent='space-between'>
              <Box display='flex' mt='4px' flexDirection='row' width='100%' alignItems='center'>
                <FlagComponent sx={{ fontSize: 20 }} />
                {labels?.map((label: string) => {
                  const labelKey = label.toLowerCase();
                  const labelColor = LABEL_COLOR[labelKey];
                  return labelColor ? (
                    <Chip
                      label={label}
                      key={label}
                      sx={{
                        ...styles.chips,
                        bgcolor: labelColor
                      }}
                    />
                  ) : null;
                })}
                <Typography pl={1} pr={1} fontWeight={500} sx={styles.heading}>
                  {identifier}
                </Typography>
              </Box>
              {cardType === 'Subscriptions' && (
                <SubscriptionActions
                  invokedBy='application'
                  isButton
                  source={data.source}
                  id={data.identifier}
                  isSubscribed={data.isSubscribed}
                  setSubscriptionInHook={handleSubscription}
                />
              )}
              {cardType === 'FavoritesAndProjects' && (
                <FavoriteAndProjectActions
                  invokedBy='applications'
                  isButton
                  setFavoriteInHook={handleFavorite}
                  source={data.source}
                  sourceIndex={data?.source_index}
                  id={data.identifier}
                  isFavorite={data.isFavorite}
                  inProjects={data.inProjects}
                  setInProjectInHook={handleProject}
                />
              )}
            </Stack>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

UserProfileCards.defaultProps = {
  cardType: 'FavoritesAndProjects'
};

export default React.memo(UserProfileCards);
