import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  tabs: {
    '& > div': {
      '& > div > button': {
        color: `grey`,
        textTransform: 'initial !important',
        paddingBottom: 1,
        paddingTop: 0,
        '&.Mui-selected': {
          color: `black`,
          fontWeight: `${theme.typography.fontWeightBold} !important`
        }
      },
      '& > span.MuiTabs-indicator': {
        backgroundColor: `black`
      }
    }
  },
  textField: {
    width: '100%',
    '& .MuiInputBase-input': {
      padding: 4
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#00000029',
      borderWidth: 0.25
    }
  },
  dialogContent: {
    scrollbarColor: `${theme.palette.common.green} #C2D2E4`,
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px'
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.common.green,
      borderRadius: '10px'
    },

    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.common.green
    }
  },
  inputText: {
    padding: 4,
    fontFamily: 'Mulish',
    whiteSpace: 'pre-line',
    '&:hover': {
      border: '0.25px dotted',
      borderColor: '#00000029',
      padding: 4
    },
    wordWrap: 'break-word'
  }
}));
