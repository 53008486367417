import React, { Fragment } from 'react';
//material ui imports
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
//styles
import ApplicationContentStyles from '../styles/ApplicationContentReport.styles';
//other components
import ApplicationContent from '../../../pages/Reg360/components/Timeline/ApplicationContent';

const ApplicationContentReport = ({ data }) => {
  const classes = ApplicationContentStyles();

  return (
    <Fragment>
      <Box display='flex' flexDirection='column' className={classes.cardDetails}>
        <Paper elevation={0} className={classes.paper}>
          <Typography variant='h3'>
            Application content
            {/*   <span className={classes.highlightText}>
              {data.source}/{data.category}/{data.searchTerm} - {data.tradeName}
  </span> */}
          </Typography>
          <ApplicationContent data={data} />
        </Paper>
      </Box>
    </Fragment>
  );
};

export default ApplicationContentReport;
