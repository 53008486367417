import React, { useCallback, useContext } from 'react';

// mui v5
import uniqueId from 'lodash/uniqueId';
import { Box, Button, Grid, Stack, Tooltip, Typography } from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';

// components
import CustomBarGraph from '../../../components/CustomComponents/Graphs/CustomBarGraph';
import CustomPieChart from '../../../components/CustomComponents/Graphs/CustomPieChart';
import { InsightsLoader } from './Loaders';

// utils
import { CHMP_REPORT_QUERIES, statsId } from '../utils/constants';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';
import formatCount from '../../../utils/formatCount';

// styles
import styles from '../styles/HomePage.styles';

// store
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

const HomePage = ({
  stats,
  loading,
  appliedFilters
}: {
  stats: any;
  loading: boolean;
  appliedFilters: { [key: string]: any };
}) => {
  /**
   * function to render stats tile with count data
   * @param title
   * @param value
   * @returns
   */
  const { state, dispatch } = useContext(GlobalStore) as any;

  const addToReport = useCallback(
    async (id: string) => {
      const addedReport = CHMP_REPORT_QUERIES.find(report => report.id === id);
      const random = Math.floor(Math.random() * 1000);
      dispatch({
        type: GlobalActions.ADD_TEMPLATE_TO_REPORT,
        value: {
          templateType: 'custom',
          chartType: addedReport?.graph,
          sectionType: 'CHART',
          id: uniqueId(`chmp_${random}_`),
          style: {
            placement: {
              h: 40,
              w: 12
            },
            graphStyle: addedReport?.graphStyle,
            title: addedReport?.title
          },
          data: {
            source: 'chmp',
            search_type: 'chmp',
            disableSelectionSource: true,
            filters: appliedFilters,
            query: addedReport?.query
          }
        }
      });
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Successfully added to Report.', color: 'success' }
      });
    },
    [state.cartItems, dispatch, appliedFilters]
  );

  const renderStatTile = (title: string, value: number) => (
    <Stack sx={styles.statsTile} justifyContent='center'>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
        <Stack spacing={1} direction='column'>
          <Typography variant='h4' textAlign='left' color='black.secondary'>
            {title}
          </Typography>
          <Tooltip title={value >= 10000 ? formatCount(value) : ''} placement='top'>
            <Typography textAlign='left' variant='h2' fontWeight='bold'>
              {truncateCountAboveMillions(value)}
            </Typography>
          </Tooltip>
        </Stack>
        <Stack justifyContent='flex-end' alignItems='flex-end' sx={{ width: '100%' }}>
          <ShowChartIcon fontSize='large' sx={styles.iconColor} />
        </Stack>
      </Stack>
    </Stack>
  );

  if (loading) {
    return <InsightsLoader />;
  }
  return (
    <Stack>
      <Grid container spacing={2}>
        {/* grid for stats counts */}
        <Grid item xs={2}>
          <Stack spacing={4} direction='column'>
            {Object.keys(stats)?.length > 0 &&
              Array.from({ length: 3 }, (_, index) =>
                renderStatTile(statsId[index].title, stats?.[statsId[index].value])
              )}
          </Stack>
        </Grid>
        <Grid item xs={10}>
          <Stack mt={0} spacing={4}>
            <Stack spacing={2} direction='row'>
              {/* basic info */}
              <Stack
                justifyContent='flex-start'
                alignItems='flex-start'
                spacing={2}
                sx={styles.basicInfoContainer}>
                <Typography variant='subtitle1' align='left' fontWeight='bold'>
                  Committee for Medicinal Products for Human Use
                </Typography>
                <Stack spacing={1}>
                  <Typography variant='subtitle1' color='gray.darkText' fontWeight='bold'>
                    Assessment:
                  </Typography>
                  <Typography variant='subtitle2' color='gray.darkText'>
                    {stats?.basic_info?.assessment}
                  </Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant='subtitle1' color='gray.darkText' fontWeight='bold'>
                    Role:
                  </Typography>
                  <Typography variant='subtitle2' color='gray.darkText'>
                    {stats?.basic_info?.role}
                  </Typography>
                </Stack>
              </Stack>

              {/* pie chart */}
              <Stack sx={styles.pieCharContainer}>
                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='subtitle1' fontWeight={600}>
                    Meeting Insights
                  </Typography>
                  <Button
                    onClick={() => addToReport('meeting_insights')}
                    variant='text'
                    fullWidth={false}
                    sx={styles.addToReport}>
                    <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                      + Add to Report
                    </Typography>
                  </Button>
                </Box>
                {stats?.meeting_insights &&
                stats?.meeting_insights?.some((item: any) => item.value !== 0) ? (
                  <CustomPieChart
                    data={stats?.meeting_insights?.filter(
                      (obj: any) => parseInt(obj.value, 10) !== 0
                    )}
                    colors={['#079992', '#38ada9', '#78e08f', '#05c46b', '#0be881']}
                    innerRadius={0.5}
                    margin={{ top: 20, right: 0, bottom: 50, left: -200 }}
                    fillText='#707070'
                    radialLabelsTextColor='#7b808c'
                    sliceLabelsTextColor='#7b808c'
                    // @ts-ignore
                    enableArcLinkLabels={false}
                    // @ts-ignore
                    legends={[
                      {
                        anchor: 'right',
                        direction: 'column',
                        justify: false,
                        translateX: -100,
                        translateY: 0,
                        itemsSpacing: -20,
                        itemWidth: 70,
                        itemHeight: 40,
                        itemTextColor: '#000',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemTextColor: '#000'
                            }
                          }
                        ]
                      }
                    ]}
                  />
                ) : (
                  <Stack
                    sx={{ width: '100%', height: '100%' }}
                    alignItems='center'
                    justifyContent='center'>
                    <Typography variant='subtitle2' color='gray.darkText'>
                      No Data Available
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>

            {/* bar graph */}
            <Stack spacing={2} direction='row'>
              <Stack sx={styles.pieCharContainer}>
                <Box display='flex' justifyContent='space-between'>
                  <Typography variant='subtitle1' fontWeight={600}>
                    Total Number of Unique Products by Years
                  </Typography>
                  <Button
                    onClick={() => addToReport('meetings_by_year')}
                    variant='text'
                    fullWidth={false}
                    sx={styles.addToReport}>
                    <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                      + Add to Report
                    </Typography>
                  </Button>
                </Box>
                {stats?.unique_products_by_year &&
                stats?.unique_products_by_year?.some((item: any) => item.year !== 0) ? (
                  <CustomBarGraph
                    keys={['year']}
                    index='key'
                    colors='#6868FA'
                    data={stats?.unique_products_by_year}
                    paddings={0.5}
                    // @ts-ignore
                    tickRotationValue={0}
                    // @ts-ignore
                    borderWidth='1px'
                    enableLabel
                    // @ts-ignore
                    borderRadius={8}
                    // @ts-ignore
                    axisText='#7b808c'
                  />
                ) : (
                  <Stack
                    sx={{ width: '100%', height: '100%' }}
                    alignItems='center'
                    justifyContent='center'>
                    <Typography variant='subtitle2' color='gray.darkText'>
                      No Data Available
                    </Typography>
                  </Stack>
                )}
              </Stack>

              <Stack
                justifyContent='flex-start'
                alignItems='flex-start'
                spacing={2}
                sx={styles.basicInfoContainer}>
                <Typography variant='subtitle1' align='left' fontWeight='bold'>
                  Upcoming Meetings
                </Typography>
                {Object.keys(stats?.upcoming_events).length !== 0 ? (
                  <Stack>
                    {Object.entries(stats?.upcoming_events).map(([key, value]: any) => (
                      <Box
                        key={key}
                        sx={{
                          width: '100%',
                          textAlign: 'left',
                          backgroundColor: 'primary.backgroundDark',
                          borderRadius: '16px',
                          padding: 2,
                          m: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column'
                        }}>
                        <Box display='flex' justifyContent='space-between' flexDirection='column'>
                          <Tooltip title={value?.chmp_meeting_title}>
                            <Typography noWrap sx={styles.dialogText}>
                              {value?.chmp_meeting_title}
                            </Typography>
                          </Tooltip>
                          <Box>
                            <Typography style={{ fontSize: '14px' }}>
                              Location: {value?.chmp_meeting_location}
                            </Typography>
                            <Typography style={{ fontSize: '14px' }}>
                              Start: {value?.chmp_meeting_start_date}
                            </Typography>
                            <Typography style={{ fontSize: '14px' }}>
                              End: {value?.chmp_meeting_end_date}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Stack>
                ) : (
                  <Box>
                    <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
                      No Recently Completed meetings found
                    </Typography>
                  </Box>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default React.memo(HomePage);
