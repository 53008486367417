import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  Fab,
  Badge,
  Tooltip
} from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { Auth } from 'aws-amplify';
import { v4 } from 'uuid';
import styles from '../styles/LabelSectionSelection.styles';

import ResultsActions from '../../../store/SearchResults/actions';
import ResultsStore from '../../../store/SearchResults';
import GlobalStore from '../../../store';
import Actions from '../../../store/actions';
import { downloadCollatedSummary } from '../data/collatedSummary';
import CloseIcon from '../../../assets/svgs/Icons/CloseIcon';
import { DownloadIcon, LeftArrowIcon } from '../../../assets/svgs/Icons';
import downloadLabelFile from '../utils/downloadLabelFile';

const DownloadLabelSection = () => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { dispatch } = useContext<any>(GlobalStore);
  const [cancel, setCancel] = React.useState<CancelTokenSource | null>(null);
  const [progress, setProgress] = useState(0);
  const [docLoader, setDocLoader] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  const exitLabelSection = () => {
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_APPLICATIONS, value: [] });
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_SELECTED_APPLICATIONS, value: [] });
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_SELECTION, value: false });
    resultsDispatch({
      type: ResultsActions.SET_COLLATED_SUMMARY_SELECTED_CATEGORIES,
      value: [
        {
          id: v4(),
          source: '',
          sections: ''
        }
      ]
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 50 && count !== 0) {
        setProgress(progress + 100 / count);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [progress, count]);

  const handleCancelCall = useCallback(async () => {
    if (cancel) {
      cancel.cancel('Operation canceled by the user.');
      setCancel(null);
    }
  }, [cancel]);

  const handleDownloadCollatedSummary = async () => {
    const user = await Auth.currentUserInfo();
    const username = user.attributes['custom:user'] ?? '';
    const cancelToken = axios.CancelToken.source();
    const downloadType = resultsState?.collatedSummaryDownloadType ?? 'html';
    setCancel(cancelToken);

    try {
      if (resultsState?.collatedSummarySelectedApplications?.length < 1) {
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Please select at least 1 label', duration: 3000 }
        });
        return;
      }

      setErrorMsg(false);
      setDocLoader(true);
      setProgress(0);
      setCount(resultsState?.collatedSummarySelectedApplications.length);

      let collatedApplication: any[] = [];

      const applicationNumbers = resultsState?.collatedSummarySelectedApplications?.map(
        selectedApp => selectedApp.labelSelectionIdentifier
      );

      collatedApplication = resultsState?.collatedSummaryApplications?.filter((appl: any) => {
        return applicationNumbers.includes(`${appl.source}-${appl.number}-${appl.section}`);
      });

      const payload = {
        username,
        download_type: downloadType,
        applications: collatedApplication
      };

      const res = await downloadCollatedSummary(payload, cancelToken.token);
      if (res?.status === 200) {
        downloadLabelFile(res.data, downloadType);
        setProgress(100);
      } else {
        setErrorMsg(true);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setErrorMsg(true);
      if (!cancel) {
        setDocLoader(false);
      }
    }
    setCancel(null);
  };

  const handleSectionSelection = () => {
    resultsDispatch({ type: ResultsActions.SET_COLLATED_SUMMARY_SELECTION, value: true });
  };

  return (
    <Box sx={styles.labelSectionDownloadContainer}>
      <Button type='button' sx={styles.sectionSelectionButton} onClick={handleSectionSelection}>
        <LeftArrowIcon sx={styles.leftArrowIcon} />
        Section Selection
      </Button>

      <Fab
        sx={styles.downloadButton}
        aria-label='download-label'
        onClick={handleDownloadCollatedSummary}
        variant='extended'>
        <Badge
          badgeContent={resultsState?.collatedSummarySelectedApplications?.length}
          color='error'
          showZero
          sx={styles.badge}>
          <DownloadIcon sx={styles.downloadIcon} />
        </Badge>
        <Box sx={styles.buttonText}>Download</Box>
      </Fab>

      <Button variant='outlined' type='submit' sx={styles.exitButton} onClick={exitLabelSection}>
        <Tooltip title='Exit mode'>
          <CloseIcon sx={styles.closeIcon} />
        </Tooltip>
      </Button>

      <Dialog
        open={docLoader}
        keepMounted={false}
        maxWidth='xs'
        onClose={() => {
          if (progress === 100) {
            setDocLoader(false);
          }
        }}>
        <DialogTitle id='alert-dialog-title'>
          <Stack sx={styles.loaderWrapper}>
            <Box>
              {(progress === 100 || errorMsg) && (
                <IconButton
                  aria-label='close'
                  onClick={() => {
                    setDocLoader(false);
                  }}
                  sx={styles.loaderCloseIcon}>
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
            <Box
              sx={{
                position: 'relative',
                display: 'inline-flex',
                justifyContent: 'center',
                width: 200,
                height: 200
              }}>
              <CircularProgress
                variant='determinate'
                value={100}
                size={200}
                thickness={3}
                sx={{
                  ...styles.circularProgress,
                  ...{ color: 'gray.background' }
                }}
              />
              {!errorMsg && (
                <CircularProgress
                  variant={progress === 100 ? 'determinate' : 'indeterminate'}
                  value={100}
                  color={!errorMsg ? 'primary' : 'error'}
                  size={200}
                  thickness={3}
                  sx={styles.circularProgress}
                />
              )}

              <Box sx={styles.loaderPercentWrapper}>
                {errorMsg ? (
                  <ErrorOutlineIcon />
                ) : (
                  <Typography
                    display='flex'
                    variant='caption'
                    component='div'
                    sx={styles.percentTypography}>
                    <span>{`${Math.round(progress)}`}</span>
                    <span style={styles.percentText}>%</span>
                  </Typography>
                )}
              </Box>
            </Box>
            <Typography ml={1} sx={styles.loaderStatusText}>
              {!errorMsg
                ? `${progress !== 100 ? 'Downloading...' : 'Download Completed !'}`
                : `Download Failed`}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box sx={styles.dialogContentWrapper}>
            <Typography sx={{ fontSize: '16px', color: errorMsg ? 'red' : 'black' }}>
              {!errorMsg
                ? `${
                    progress !== 100
                      ? `It may take a few seconds to compile section summary.`
                      : 'Label Section Summary file has been downloaded successfully'
                  }`
                : `Apologies! An error has occurred in downloading.Please try again`}
            </Typography>

            <Button
              variant='contained'
              color='secondary'
              sx={styles.loaderButton}
              onClick={() => {
                if (errorMsg) {
                  setErrorMsg(false);
                  handleDownloadCollatedSummary();
                } else if (progress !== 100) {
                  handleCancelCall();
                } else {
                  setDocLoader(false);
                }
              }}>
              {
                // eslint-disable-next-line no-nested-ternary
                `${errorMsg ? 'Try Again' : progress !== 100 ? 'Cancel' : 'Done'}`
              }
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DownloadLabelSection;
