import React, { useCallback, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

// mui5
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/lab/Alert';
import { Skeleton, Stack } from '@mui/material';

// CDP Reused Components
import uniqueId from 'lodash/uniqueId';
import CTDocuments from '../../../pages/CDP/components/CTDocuments';
import ResultsStore from '../../../store/SearchResults';
import GlobalStore from '../../../store';
import styles from '../../../pages/CDP/styles/CDPMain';

import { decodeBase64ToObject } from '../../../utils/encodeDecodeObject';

import GlobalActions from '../../../store/actions';
// Timeline Components
import SelectedFiltersBar from '../../../pages/ResultsPage/components/SelectedFiltersBar';
import DelayTimeline from './DelayTimeline';
import Timeline from './Timeline';

// custom hooks
import { handleCTGraphFetch } from '../../../pages/ResultsPage/hoc/withFetchingSearchResults';
import getSearchPathParams from '../../Header/utils/getSearchPathParams';

const CTTimelineGraph: React.FC<any> = ({ type: timelineGraphTab }) => {
  const location = useLocation();
  const [warningMessage, setWarningMessage] = useState<string>('');
  const [showDocs, setShowDocs] = useState<boolean>(false);
  const [trialsCount, setTrialsCount] = useState<number>(0);
  const [ctId, setCTId] = useState<string>('');
  const [selectedEntity, setSelectedEntity] = useState<any>({});
  const { dispatch } = useContext<any>(GlobalStore);
  const { resultsState } = useContext(ResultsStore);
  const { pendingFilters } = resultsState as any;

  const source = resultsState.decryptedPayload.source?.eu?.includes('euctr') ? 'euctr' : 'ct';

  const handlePDFViewClose = useCallback(() => {
    setShowDocs(false);
  }, []);

  const handleOnAlertClose = useCallback(() => setWarningMessage(''), []);

  const handleAddToReport = () => {
    const { searchId }: any = getSearchPathParams(location.pathname);
    const {
      search_term: searchTerm,
      entity_text: entityText,
      entity_category: entityCategory,
      search_type: searchType
    }: any = decodeBase64ToObject(searchId ?? '');
    const { cnfOfSelectedQuery, dateRange, useSynonyms } = handleCTGraphFetch(
      searchType,
      resultsState.decryptedPayload,
      resultsState.filters,
      entityCategory || 'indications_and_usage',
      entityText || searchTerm
    );
    const random = Math.floor(Math.random() * 1000);
    const reportStateValue = {
      templateType: 'custom',
      chartType: timelineGraphTab === 'gantt' ? 'CT_TIMELINE_GANTT' : 'CT_TIMELINE_CHANGE',
      sectionType: 'CHART',
      id: uniqueId(`cttimeline_${random}_`),
      style: {
        placement: {
          h: 28,
          w: 12
        },
        title: {},
        graphStyle: {}
      },
      data: {
        source,
        resultId: resultsState?.searchIds?.ctSearchId,
        filters: {
          queryCNF: cnfOfSelectedQuery,
          fda: resultsState?.filters,
          dateFilters: dateRange,
          defaultFilters: false,
          trialsSource: source,
          useSynonyms
        }
      }
    };

    dispatch({
      type: GlobalActions.ADD_TEMPLATE_TO_REPORT,
      value: reportStateValue
    });
    dispatch({
      type: GlobalActions.SET_ALERT,
      value: { status: true, message: 'Successfully added to Report.', color: 'success' }
    });
  };

  return (
    <Box>
      {!pendingFilters && <SelectedFiltersBar />}
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4'>
          {timelineGraphTab === 'gantt'
            ? 'Clinical Trials Timeline Gantt'
            : 'Clinical Trials Timeline Changes'}
        </Typography>
        <Stack direction='row' alignItems='center' spacing={1}>
          <Box onClick={handleAddToReport} display='inline-block' pr={1}>
            <Typography sx={{ ...styles.addToReport, marginRight: '10px' }}>
              + Add to Report
            </Typography>
          </Box>
        </Stack>
      </Stack>
      <Box display='flex'>
        <Box alignSelf='end'>
          {resultsState.trialLoading || resultsState.loading ? (
            <Skeleton
              variant='rectangular'
              height={16}
              width={120}
              sx={{ mr: 1, borderRadius: 1 }}
            />
          ) : (
            <Typography sx={styles.label}>Total {trialsCount} trials</Typography>
          )}
        </Box>
      </Box>
      <Box width='90vw' display='flex' height='60vh' pb={2} overflow='hidden' position='relative'>
        <Box width={showDocs ? '50%' : '100%'} height='100%' display='flex' flexDirection='column'>
          {timelineGraphTab === 'gantt' ? (
            <Timeline
              ctId={ctId}
              setCtId={setCTId}
              showDocs={showDocs}
              setShowDocs={setShowDocs}
              setTrialsCount={setTrialsCount}
              setWarningMessage={setWarningMessage}
              onEntitySelectionUpdate={setSelectedEntity}
            />
          ) : (
            <DelayTimeline
              nctId={ctId}
              setNctId={setCTId}
              showDocs={showDocs}
              setShowDocs={setShowDocs}
              setTrialsCount={setTrialsCount}
              setWarningMessage={setWarningMessage}
              onEntitySelectionUpdate={setSelectedEntity}
            />
          )}
        </Box>
        {showDocs && (
          <Box width='50%' height='100%'>
            {ctId ? (
              <CTDocuments
                id={ctId}
                onClose={handlePDFViewClose}
                source={source}
                selectedGraphEntityData={selectedEntity}
              />
            ) : null}
          </Box>
        )}
        <Snackbar
          open={!!warningMessage}
          autoHideDuration={6000}
          onClose={handleOnAlertClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}>
          <Alert onClose={handleOnAlertClose} severity='warning' sx={{ width: '100%' }}>
            <Typography>{warningMessage}</Typography>
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default CTTimelineGraph;
