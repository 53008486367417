import { Avatar, Box, Button, Tooltip, Typography } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router';
import AuthContext from '../../../../store/Auth/AuthProvider';
import styles from '../../styles/LandingPage.styles';

const UserAvatar = () => {
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);

  const disablePasswordChange = useMemo(() => {
    return currentUser && 'identities' in currentUser;
  }, [currentUser]);

  const stringAvatar = (name: string) => {
    let returnName = '';
    if (name) {
      const initials = name?.split(' ');
      if (initials) {
        returnName = `${initials[0][0]}`;
      }
      if (initials.length > 1) {
        returnName = `${initials[0][0]}${initials[1][0]}`;
      }
    }
    return {
      children: returnName
    };
  };
  return (
    <Box display='flex' sx={{ alignItems: 'center' }}>
      <Box sx={{ marginRight: '1em' }}>
        <Avatar
          sx={styles.avatar}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...stringAvatar(currentUser?.['custom:user'])}
        />
      </Box>
      <Box display='flex' flexDirection='column' justifyContent='center' overflow='hidden'>
        <Typography data-dd-privacy='mask' sx={styles.userName}>
          {currentUser?.['custom:user']}
        </Typography>
        <Typography data-dd-privacy='mask' sx={styles.userEmail}>
          {currentUser?.email}
        </Typography>
        <Tooltip title={disablePasswordChange && 'SSO users cannot change passwords'}>
          <Box>
            <Button
              disabled={disablePasswordChange}
              variant='text'
              sx={styles.changePasswordButton}
              onClick={() => history.push('/reset')}>
              Change password
            </Button>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default React.memo(UserAvatar);
