import { memo, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ErrorText from './ErrorText';

// hooks
import useMoxoSdkServices from '../../../hooks/useMoxoSdkServices';
import useUpdateModule from '../../../hooks/useUpdateModule';

const ServiceRequests = () => {
  useUpdateModule('serviceRequests');
  const { initializeServiceRequests, error } = useMoxoSdkServices();

  useEffect(() => {
    initializeServiceRequests('#serviceRequests');
  }, []);

  if (error) {
    return <ErrorText />;
  }
  return (
    <Box sx={{ width: '100%', maxHeight: '100%', height: '100%', overflow: 'hidden' }}>
      <Typography variant='h2' fontWeight='bold'>
        Service Requests
      </Typography>
      <Box
        data-dd-privacy='mask'
        id='serviceRequests'
        sx={{ mt: 2, width: '100%', maxHeight: '100%', height: '70vh', border: 'none' }}
      />
    </Box>
  );
};

export default memo(ServiceRequests);
