// eslint-disable-next-line import/prefer-default-export
export const projectPayloadGeneration = (data: any) => {
  let entityType = '';

  switch (data.type) {
    case 'quickSearch':
    case 'advancedSearch':
      entityType = 'quick_advanced_search';
      break;
    case 'ariaSearch':
      entityType = 'aria_search';
      break;
    case 'ariaResult':
      entityType = 'aria_result';
      break;
    default:
      entityType = data.type;
  }
  const payload = {
    entity_type: entityType
  };
  if (data.type === 'applications') {
    // @ts-ignore
    payload.additional_fields = {
      source: data.source,
      entity_id: data.id
    };
  } else if (data.type === 'ariaResult') {
    // @ts-ignore
    payload.additional_fields = {
      aria_search_id: data.id,
      source: data.source,
      ...data.ariaResultData
    };
  }
  return payload;
};

export const SOURCE_MAPPING_FOR_FAVOURITES: any = {
  us: 'US FDA - Drugs & Biologics',
  eu: 'EU EMA - Drugs & Biologics',
  ct: 'CT GOV - Clinical Trials',
  ca: 'CA HPFB - Drugs & Biologics',
  'us-devices': 'US FDA - Devices',
  hpra: 'IE HPRA - Drugs & Biologics',
  hma: 'EU HMA - Drugs & Biologics',
  jp: 'JP PMDA - Drugs & Biologics'
};

export const ARIA_SOURCE_MAPPING_FOR_FAVOURITES: any = {
  us: 'us',
  eu: 'eu',
  ca: 'ca',
  'us-devices': 'us-devices',
  ct: 'ct',
  devices: 'us-devices',
  hpra: 'hpra',
  hma: 'hma',
  jp: 'jp'
};
export const SOURCE_MAPPING_USER_PROFILE_ACTIVITY: any = {
  us: {
    sba: 'US FDA - Drugs & Biologics',
    wr: 'FDA Written Requests',
    adcomm: 'Adcomm',
    guidance: 'FDA Guidance',
    devices: 'US FDA - Devices',
    'dd-tools': 'Drug Development Tools',
    'fda-letters': 'FDA Warnings and Untitled Letters'
  },
  eu: {
    epar: 'EU EMA - Drugs & Biologics',
    pip: 'EMA-PIPs',
    chmp: 'CHMP',
    guidance: 'EMA Guidance',
    hma: 'EU HMA - Drugs & Biologics',
    euctr: 'EU Clinical Trials Register',
    ema_orphan: 'EMA Orphan Designations',
    pdco: 'EU PDCO'
  },
  ie: {
    hpra: 'IE HPRA - Drugs & Biologics'
  },
  ca: {
    hpfb: 'CA HPFB - Drugs & Biologics',
    guidance: 'CA Guidance',
    consultation: 'CA Consultation'
  },
  jp: {
    pmda: 'JP PMDA - Drugs & Biologics'
  },
  ct: {
    usnlm: 'CT GOV - Clinical Trials'
  },
  uk: {
    nice: 'UK NICE Guidance'
  }
};
