import { Box } from '@mui/material';
import React from 'react';

const PreparedDate = ({ createdAt = undefined }: any) => {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  let dateValue;
  if (createdAt) {
    dateValue = new Date(createdAt).toLocaleDateString(undefined, options);
  } else {
    dateValue = new Date().toLocaleDateString(undefined, options);
  }
  return <Box sx={{ fontWeight: 'bold' }}>{dateValue}</Box>;
};

export default React.memo(PreparedDate);
