const overflowMixin = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  whiteSpace: 'normal'
};

const styles = {
  card: {
    width: '250px',
    cursor: 'pointer'
  },
  image: {
    width: '100%',
    height: '180px'
  },
  title: {
    ...overflowMixin,
    fontWeight: 'bold',
    height: '50px',
    paddingTop: '8px'
  },
  shortDescription: {
    ...overflowMixin,
    color: 'primary.gray',
    height: '40px',
    paddingTop: '4px'
  },
  tags: {
    ...overflowMixin,
    color: 'blue.light',
    height: '40px',
    paddingBottom: '8px'
  }
};

export default styles;
