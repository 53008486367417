const styles = {
  drawer: {
    '& >.MuiDrawer-paper': {
      height: '96vh',
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      position: 'absolute',
      backgroundColor: 'white.smokeDark'
    }
  },
  container: { display: 'flex', flexDirection: 'column', height: '100%' },
  headerWrapper: { backgroundColor: 'white.main', display: 'flex', flexDirection: 'column' },
  titleWrapper: {
    backgroundColor: 'white.main',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    px: 5,
    pt: 3,
    pb: 1.25
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    alignSelf: 'center',
    fontWeight: '700',
    fontSize: '20px',
    color: 'primary.darkVariant1'
  },
  actionsWrapper: {
    position: 'absolute',
    display: 'flex',
    left: '87%'
  },
  separator: {
    borderLeft: (theme: any) => `1px solid ${theme.palette.gray.lightVariant3}`,
    height: '18px',
    margin: 'auto 8px'
  },
  dividerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  divider: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: '2px',
    mb: '16px',
    width: '64px'
  },
  verticalDivider: { height: '20px', borderColor: 'gray.400' },
  childrenWrapper: { px: 5, pt: 2, pb: 6, height: '100%', overflowY: 'hidden' },
  buttonIcon: { height: '16px', width: '16px', color: 'gray.700', cursor: 'pointer' }
};

export default styles;
