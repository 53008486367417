import React, { useRef, useState, useEffect } from 'react';
import { Stack, Typography, Divider } from '@mui/material';
import snapshotStyles from './styles';

const LabelValueRow = ({ label, value }: any) => (
  <Stack direction='row' spacing={2} alignItems='center'>
    <Typography variant='subtitle2' sx={snapshotStyles.SubmissionSummary.labelText}>
      {label}:
    </Typography>
    <Typography variant='subtitle2' sx={snapshotStyles.SubmissionSummary.valueText}>
      {value}
    </Typography>
  </Stack>
);

const SafetyDataRow = ({ data, metadata, lastItem }: any) => (
  <>
    <LabelValueRow label={metadata.sample_size} value={data.sample_size} />
    <LabelValueRow label={metadata.description} value={data.description} />
    {!lastItem && <Divider />}
  </>
);

const SubmissionSummary = ({ data, title, onUpdatePlacement }: any) => {
  const submissionSummaryRef = useRef<HTMLDivElement | null>(null);
  const [utilityTextHeight, setUtilityTextHeight] = useState<Number>(0);
  const { total_indication: totalIndication, metadata, data: summary } = data;

  const getHeight = () => {
    const totalHeight = (submissionSummaryRef?.current?.clientHeight || 0) + 10;
    onUpdatePlacement?.(Math.ceil(totalHeight / 10) + 1);
    return totalHeight;
  };

  useEffect(() => {
    const height = getHeight();
    setUtilityTextHeight(height);
  }, []);

  return (
    <Stack
      ref={submissionSummaryRef}
      spacing={2}
      height={`calc(100% - ${utilityTextHeight}px)`}
      sx={snapshotStyles.SubmissionSummary.main}>
      <Typography variant='subtitle2' sx={snapshotStyles.SubmissionSummary.titleText}>
        {title}
      </Typography>
      <LabelValueRow label='No. of indications' value={totalIndication} />
      <Divider />

      {summary?.length > 0 && (
        <Typography variant='subtitle2' sx={snapshotStyles.SubmissionSummary.textDescription}>
          Clinical trial safety database summary used to support product safety
        </Typography>
      )}

      {summary?.map((item: any, index: any, array: any) => (
        <SafetyDataRow
          key={item.id}
          data={{ ...item, index }}
          metadata={metadata}
          lastItem={index === array.length - 1}
        />
      ))}
    </Stack>
  );
};

export default React.memo(SubmissionSummary);
