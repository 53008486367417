import { memo, useCallback, useContext, useEffect, useState } from 'react';

// material ui
import { Box, Popover, Stack, Switch, Tooltip, Typography } from '@mui/material';

// hooks
import useCanadaGuidanceData from '../hooks/useCanadaGuidanceData';

// Store
import GlobalStore from '../../../store';
import store from '../../../store/CanadaGuidance';

// components
import CanadaGuidanceInsights from '../components/Insights';
import CanadaGuidanceDetails from '../components/Details';
import ReportWidgets from '../../../components/Report/Widgets';
import ComparisonButtons from '../components/ComparisonButtons';
import CompareDialog from '../components/CompareDialog';
import SubHeader from '../../../components/SubHeader';
import BottomDrawer from '../../../components/CustomComponents/BottomDrawer';
import NewFilters from '../../../components/NewFilters';
import AskRiaButton from '../../../components/Buttons/AskRiaButton';
import AriaDrawerCore from '../../SearchResults/components/AriaDrawerCore';

// utils
import CANADA_GUIDANCE_FILTERS, { PAGINATION_DEFAULT_OPTIONS } from '../const';
import { Stat } from '../../SearchResults/types';
import AskRiaButtonStyles from '../../../components/Buttons/styles/AskRiaButton.styles';
import useAriaShareHandler from '../../../components/AriaShare/useAriaShareHandler';
import { getCanadaGuidanceDocumentsSignedURL } from '../../../api/pages/CanadaGuidance';

const CanadaGuidanceTabs = () => {
  const {
    canadaGuidancefilters,
    isLoading,
    compareEnabled,
    documentsToCompare,
    comparePopupOpen,
    getCanadaGuidanceData,
    openComparisonPopup,
    removeFromComparison,
    openGuidanceComparison,
    clearComparison,
    clearSearch,
    sortFields,
    getDatawithDataGridFilter,
    dataGridFilter
  } = useCanadaGuidanceData();
  const { CanadaGuidanceState } = useContext(store);
  const [ariaModalOpen, setAriaModalOpen] = useState(false);
  const { dispatch } = useContext(GlobalStore) as any;
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] =
    useState<HTMLElement | null>(null);
  const [guidanceStats, setGuidanceStats] = useState<Stat[]>([]);
  const [compareItems, setCompareItems] = useState<Array<any>>([]);
  const [sort, setSort] = useState([...sortFields]);
  const [pagination, setPagination] = useState({
    pageSize: PAGINATION_DEFAULT_OPTIONS.limit,
    page: PAGINATION_DEFAULT_OPTIONS.offset
  });
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);

  useEffect(() => {
    getCanadaGuidanceData([], pagination.pageSize, pagination.page, []);
  }, []);

  useEffect(() => {
    if (CanadaGuidanceState?.canadaGuidanceStats) {
      const newStats: Stat[] = [];
      newStats.push({
        title: 'Total',
        value: CanadaGuidanceState?.canadaGuidanceStats?.totalGuidance || 0
      });
      newStats.push({
        title: 'Recently Published',
        value: CanadaGuidanceState?.canadaGuidanceStats?.totalLastThirtyDays || 0
      });
      newStats.push({
        title: 'Docs/Page',
        value: CanadaGuidanceState?.canadaGuidanceStats?.pdfCount,
        value2: CanadaGuidanceState?.canadaGuidanceStats?.pageCount
      });

      setGuidanceStats(newStats);
    }
  }, [canadaGuidancefilters]);

  useEffect(() => {
    const fetchData = async () => {
      const formattedDocumentsToCompare = await Promise.all(
        documentsToCompare.map(async doc => {
          const pdfSignedUrl: any = await getCanadaGuidanceDocumentsSignedURL({
            source: 'ca_guidance',
            s3_path: doc.s3_path
          });
          return {
            title: `${doc?.title?.replace(/ \([\s\S]*?\)/g, '')}`,
            pdfLink: pdfSignedUrl.data.body.s3_url
          };
        })
      );
      setCompareItems(formattedDocumentsToCompare);
    };

    // check is added so that when user selects both the pdf only pdf url is fetched
    if (documentsToCompare.length === 2) {
      fetchData();
    }
  }, [documentsToCompare]);

  useAriaShareHandler(isLoading, ariaModalOpen, setAriaModalOpen);

  const exitFromComparisonMode = () => {
    clearComparison();
    openGuidanceComparison(false);
    openComparisonPopup(false);
    clearSearch();
    if ('filter_keywords' in CanadaGuidanceState.availableFilters) {
      // eslint-disable-next-line camelcase
      const { filter_keywords, ...updatedGuidanceState } = CanadaGuidanceState.availableFilters;
      getCanadaGuidanceData(
        updatedGuidanceState,
        pagination.pageSize,
        pagination.page * pagination.pageSize,
        sortFields,
        false,
        dataGridFilter
      );
    }
  };

  const handleFilterApply = useCallback(
    (currentfilters: any) => {
      setIsFilterUpdated(true);
      setPagination({ page: PAGINATION_DEFAULT_OPTIONS.offset, pageSize: pagination.pageSize });
      clearComparison();
      clearSearch();
      getCanadaGuidanceData(
        currentfilters,
        pagination.pageSize,
        // offset is set to default because whenever filter is applied user should be directed to first page
        PAGINATION_DEFAULT_OPTIONS.offset,
        sortFields
      );
    },
    [sortFields, pagination]
  );

  const handleCompareClick = () => {
    if (compareEnabled) {
      exitFromComparisonMode();
    } else {
      openGuidanceComparison(!compareEnabled);
    }
  };

  const getFiltersLabelList = () => {
    return CANADA_GUIDANCE_FILTERS.reduce((acc: any, elem) => {
      acc[elem.value] = elem.label;
      return acc;
    }, {});
  };

  const getAriaDocuments = () => {
    const ariaDocuments = CanadaGuidanceState?.askRiaFilters?.map((item: any) => ({
      identifier: item
    }));

    return ariaDocuments || [];
  };

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  return (
    <>
      <SubHeader
        title='CA Guidances'
        subTitle='Showing list of Guidances published by CA'
        stats={guidanceStats}
        handleVisualizeOpen={handleVisualizeOpen}>
        <Stack
          alignItems='center'
          direction='row'
          sx={{ cursor: 'pointer', paddingRight: '16px' }}
          onClick={handleCompareClick}>
          <Typography
            id='filters'
            sx={{ justifySelf: 'flex-end', color: 'gray.contrastText', fontSize: '12px' }}
            variant='subtitle1'>
            Comparison Mode
          </Typography>
          <Switch
            sx={{
              '.Mui-checked': {
                color: (theme: any) => `${theme.palette.primary[650]} !important`
              }
            }}
            checked={compareEnabled}
          />
        </Stack>
      </SubHeader>

      {!isLoading && (
        <Popover
          open={!!reportChoicesOpenAnchorElement}
          anchorEl={reportChoicesOpenAnchorElement}
          onClose={() => setReportChoiceAnchorElement(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          sx={{
            '& .MuiPopover-paper': {
              borderRadius: '16px !important',
              backgroundColor: 'none'
            }
          }}>
          <Box>
            <ReportWidgets
              widgets={['pie-widget', 'bar-widget', 'table-widget']}
              title=''
              dataSource={{
                source: 'canada-guidance',
                moduleName: 'guidance',
                disableSelectionSource: true,
                filters: CanadaGuidanceState.availableFilters,
                filterLabelList: getFiltersLabelList()
              }}
              pathMetaData={{
                module: 'canada_guidance',
                href: window.location.href
              }}
              setReportWidgetClose={setReportChoiceAnchorElement}
            />
          </Box>
        </Popover>
      )}

      {comparePopupOpen && (
        <CompareDialog
          open={comparePopupOpen}
          onClose={() => openComparisonPopup(false)}
          compareItems={compareItems}
          onNewCompare={clearComparison}
        />
      )}

      {compareEnabled && (
        <Box marginTop='10px'>
          <ComparisonButtons
            documentsToCompare={documentsToCompare}
            removeFromComparison={removeFromComparison}
            openComparisonPopup={openComparisonPopup}
            clearComparison={clearComparison}
          />
        </Box>
      )}

      <CanadaGuidanceDetails
        pagination={pagination}
        setPagination={setPagination}
        sort={sort}
        setSort={setSort}
        sortFields={sortFields}
        getCanadaGuidanceData={getCanadaGuidanceData}
        isFilterUpdated={isFilterUpdated}
        setIsFilterUpdated={setIsFilterUpdated}
        getDatawithDataGridFilter={getDatawithDataGridFilter}
        dataGridFilter={dataGridFilter}
      />

      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}>
        <CanadaGuidanceInsights />
      </BottomDrawer>

      {ariaModalOpen && (
        <AriaDrawerCore
          applications={CanadaGuidanceState.isFiltersApplied ? getAriaDocuments() : []}
          sources={{ ca: ['guidance'] }}
          groupId='entity_id'
          docCount={CanadaGuidanceState?.canadaGuidanceStats?.pdfCount}
          docPageCount={CanadaGuidanceState?.canadaGuidanceStats?.pageCount}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          dispatch={dispatch}
        />
      )}

      <NewFilters
        data={canadaGuidancefilters}
        applyFilters={handleFilterApply}
        isLoading={isLoading}
        selectedFilters={CanadaGuidanceState.availableFilters}
      />

      <Tooltip title={compareEnabled ? 'Exit compare mode to search ARIA' : ''}>
        <Box sx={AskRiaButtonStyles.bottomFilterPosition}>
          <AskRiaButton
            iconId='aria'
            setOpenAriaSearch={setAriaModalOpen}
            text='Ask RIA'
            disabled={compareEnabled || isLoading}
          />
        </Box>
      </Tooltip>
    </>
  );
};

export default memo(CanadaGuidanceTabs);
