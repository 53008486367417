import { ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import useStyles from '../styles/UserList.styles';
import UserProfileListIcons from '../UserProfileListIcons';

// eslint-disable-next-line react/prop-types
const ReportsButton = ({ report }) => {
  const classes = useStyles();
  return (
    <ListItemButton disableRipple disablePadding className={classes.list}>
      <ListItemAvatar>
        <UserProfileListIcons type='reports' />
      </ListItemAvatar>
      {/* eslint-disable-next-line react/prop-types */}
      <ListItemText primary={report.title} />
    </ListItemButton>
  );
};
ReportsButton.prototype = {
  report: PropTypes.object.isRequired
};

export default ReportsButton;
