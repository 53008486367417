import { GridColDef } from '@mui/x-data-grid-pro';

import React from 'react';
import Box from '@mui/material/Box';

// components
import VivproDatagrid from '../../../components/Datagrid';
import DatagridTooltip from '../../../components/Datagrid/DatagridTooltip';

// style
import Styles from '../styles/DataGrid.styles';

const ExclusivityTab = ({ cardState }: any) => {
  const classes = Styles() as any;
  const COLUMN_LIST: GridColDef[] = [
    {
      field: 'exclusivity_code',
      headerName: 'Code',
      width: 180,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'exclusivity_definition',
      headerName: 'Definition',
      width: 500,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      valueGetter: ({ row }) => `${row.exclusivity_definition}`,
      renderCell: DatagridTooltip
    },
    {
      field: 'exclusivity_date',
      headerName: 'Expiration Date',
      width: 280,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'product_no',
      headerName: 'Product Number',
      width: 180,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      valueGetter: ({ row }) => `${row.product_no.join(', ')}`,
      renderCell: DatagridTooltip
    }
  ];

  return (
    <Box className={classes.datagrid} display='flex' alignItems='center' height='35vh'>
      <VivproDatagrid
        rowId='exclusivity_code'
        rows={cardState.extendedCard.exclusivity}
        columnsMapping={COLUMN_LIST}
      />
    </Box>
  );
};

export default React.memo(ExclusivityTab);
