import React, { useContext, memo, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Box, Grid, Tooltip } from '@mui/material';

import ResultsStore from '../../../store/SearchResults';
import AuthContext from '../../../store/Auth/AuthProvider';
import getUserDetails from '../utils/getUserDetails';
import { encodeObjectToBase64 } from '../../../utils/encodeDecodeObject';
import { SOURCE_MAPPING_USER_PROFILE_ACTIVITY } from '../../UserProfile/util';
import { IGNORE_REGION_IN_SEARCH_SUGGESTIONS } from '../constants';
import searchSuggestionStyles from '../styles/SearchSuggestion.styles';
import { MagnifyingGlassIcon, InfoIconSummary, ArrowRightIcon } from '../../../assets/svgs/Icons';

interface Source {
  [key: string]: string[];
}

interface Suggestion {
  key: string;
  category: string | null;
  view_type: string;
  label: string;
  count: number;
  search_term: string;
  query: string | null;
  source: Source;
  tooltip: string;
}

const getSourceBasedTooltip = (regions: Source): string => {
  const tooltip: string[] = [];

  Object.keys(regions).forEach(region => {
    const regionWiseSources = SOURCE_MAPPING_USER_PROFILE_ACTIVITY[region];

    if (regionWiseSources) {
      regions[region].forEach(source => {
        tooltip.push(regionWiseSources[source]);
      });
    }
  });

  return tooltip.join('\n');
};

const combineData = (data: Suggestion[]): Suggestion[] => {
  const combined: Record<string, Suggestion> = {};

  data.forEach(item => {
    const key = `${item.category}|${item.view_type}|${item.label}`;

    if (!combined[key]) {
      combined[key] = { ...item, count: 0, source: {}, tooltip: '', key };
    }

    combined[key].count += item.count;

    Object.keys(item.source)
      ?.filter(region => !IGNORE_REGION_IN_SEARCH_SUGGESTIONS.includes(region))
      ?.forEach(region => {
        if (!combined[key].source[region]) {
          combined[key].source[region] = [];
        }
        item.source[region].forEach(source => {
          if (!combined[key].source[region].includes(source)) {
            combined[key].source[region].push(source);
          }
        });
      });
  });

  Object.keys(combined).forEach(key => {
    combined[key].tooltip = getSourceBasedTooltip(combined[key].source);
  });

  return Object.values(combined);
};

const SearchSuggestions: React.FC = () => {
  const { resultsState } = useContext(ResultsStore);
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();
  const userDetails = useMemo(() => getUserDetails(currentUser), [currentUser]);

  const combinedData = useMemo(
    () => combineData(resultsState?.searchSuggestions || []),
    [resultsState?.searchSuggestions]
  );

  const handleSearch = useCallback(
    (index: number) => {
      const suggestion = combinedData[index];

      if (!suggestion) return;

      const newPayload = {
        entity_category: suggestion.category,
        query: suggestion.query,
        search_term: suggestion.search_term ?? suggestion.query,
        source: suggestion.source ?? {},
        view_type: suggestion.view_type,
        userDetails
      };

      const encodedPayload = encodeObjectToBase64(newPayload) ?? '';
      history.push(`/search/${encodedPayload}`);
    },
    [combinedData, history, userDetails]
  );

  return (
    <Box sx={searchSuggestionStyles.mainContainer}>
      <Box sx={searchSuggestionStyles.searchIconWrapper}>
        <MagnifyingGlassIcon sx={searchSuggestionStyles.searchIcon} />
      </Box>
      <Typography sx={searchSuggestionStyles.noMatchedText}>
        No {resultsState?.viewType}s matched for your selected source(s)
      </Typography>

      <Box sx={searchSuggestionStyles.suggestionsMainContainer}>
        <Typography sx={searchSuggestionStyles.suggestionText}>
          But we found results in other source(s). <br />
          Please select from the options below, or try executing a new search.
        </Typography>
        <Typography sx={searchSuggestionStyles.searchQueryText}>
          &ldquo;
          {resultsState?.decryptedPayload?.search_term ?? resultsState?.decryptedPayload?.query}
          &rdquo;
        </Typography>

        <Grid container sx={searchSuggestionStyles.gridContainer}>
          {combinedData?.map((suggestion, index) => (
            <Grid
              item
              key={suggestion.key}
              onClick={() => handleSearch(index)}
              sx={searchSuggestionStyles.gridItem}>
              <Box sx={searchSuggestionStyles.suggestionContainer}>
                <Box>
                  <Typography sx={searchSuggestionStyles.titleContainer}>
                    {suggestion.label}{' '}
                    <Typography sx={searchSuggestionStyles.count}>({suggestion.count})</Typography>
                  </Typography>
                  <Box sx={searchSuggestionStyles.multipleSourceTextContainer}>
                    <Typography sx={searchSuggestionStyles.multipleSourceText}>
                      Other Source(s)
                    </Typography>
                    <Tooltip
                      title={<span style={{ whiteSpace: 'pre-line' }}>{suggestion.tooltip}</span>}>
                      <InfoIconSummary sx={searchSuggestionStyles.tooltipInfoIcon} />
                    </Tooltip>
                  </Box>
                </Box>

                <ArrowRightIcon sx={searchSuggestionStyles.rightArrowIcon} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default memo(SearchSuggestions);
