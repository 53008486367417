import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(5),
    width: '100%'
  },
  tabs: {
    paddingLeft: theme.spacing(2),
    minHeight: 'unset',
    marginBottom: '-1px',
    '& Tabs-flexContainer': {
      height: '30px'
    }
  },
  activeTab: {
    border: `1px solid ${theme.palette.tabs.grayLight}`,
    borderRadius: '10px 10px 0px 0px',
    minWidth: '130px',
    color: theme.palette.tabs.grayText,
    textTransform: 'initial',
    fontSize: '14px',
    fontWeight: 600
  },
  inactiveTab: {
    border: `1px solid ${theme.palette.tabs.grayLight}`,
    borderRadius: '10px 10px 0px 0px',
    textTransform: 'initial',
    color: theme.palette.white,
    minWidth: '130px',
    background: theme.palette.gradient.main,
    fontSize: '14px',
    fontWeight: 600
  },
  indicator: {
    background: theme.palette.backgrounds.main
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.tabs.grayLight}`
  },
  tabPanel: {
    height: '60vh',
    overflowY: 'auto'
  }
}));
