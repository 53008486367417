import React, { lazy, Suspense } from 'react';

// material ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// styles
import Styles from '../../../styles/ApplicationContent.styles';
import SuspenseCircularLoader from '../../../../../helpers/SuspenseCircularLoader';

const ResponsiveBar = lazy(() =>
  import('@nivo/bar').then(module => ({ default: module.ResponsiveBar }))
);

// image

const StudyGraph = props => {
  const classes = Styles();

  return (
    <Grid container className={classes.graph}>
      <Grid item xs={12}>
        <Typography className={classes.Heading}>Study Duration</Typography>
      </Grid>
      <Grid item xs={12} container style={{ height: '30vh' }}>
        {props.data !== {} ? (
          <Suspense fallback={<SuspenseCircularLoader />}>
            <ResponsiveBar
              data={props.data}
              keys={['y']}
              indexBy={'x'}
              margin={{
                top: 5,
                right: 70,
                bottom: 50,
                left: 80
              }}
              layout='horizontal'
              borderRadius={2}
              padding={0.4}
              defs={[
                {
                  id: 'gradientD',
                  type: 'linearGradient',
                  colors: [
                    { offset: 40, color: '#4524CB' },
                    { offset: 100, color: '#6868FA' }
                  ]
                }
              ]}
              fill={[{ match: '*', id: 'gradientD' }]}
              colorBy='index'
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                tickSize: 1,
                tickPadding: 5,
                tickRotation: 0
              }}
              theme={{
                axis: {
                  ticks: {
                    text: {
                      fill: '#6D7D8A',
                      fontWeight: 'bold',
                      fontSize: '11px'
                    }
                  }
                },
                legends: {
                  text: {
                    fontSize: '16px'
                  }
                }
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={'#ffffff'}
              enableGridY={false}
            />
          </Suspense>
        ) : (
          <Typography> Capture in Progress</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default StudyGraph;
