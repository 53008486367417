import { Box, Tooltip, tooltipClasses } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { uniq, uniqueId } from 'lodash';

import { ELASTIC_SEARCH_ATTRIBUTES_LABELS } from '../../ResultsPage/utils/constants';

const FilterToolTip = ({ filters, source, children }) => {
  const [labels, setLabels] = useState([]);
  useEffect(() => {
    const labelsArray = [];
    if (filters && Object.keys(filters).length !== 0 && source) {
      let currSource = '';
      // eslint-disable-next-line prefer-destructuring
      currSource = Object.keys(source)[0];
      if (currSource && ['ie'].includes(currSource.toLowerCase())) {
        currSource = source[Object.keys(source)[0]];
      }
      Object.entries(filters).forEach(([key, value]) => {
        if (key !== 'committee_id') {
          let label = key;
          const labelValue =
            key === 'start_date' || key === 'end_date'
              ? value
              : uniq(value.map(val => val.label || val)).join(', ');
          if (key in ELASTIC_SEARCH_ATTRIBUTES_LABELS[currSource]) {
            label = ELASTIC_SEARCH_ATTRIBUTES_LABELS[currSource][key];
          }
          labelsArray.push(
            <Box key={uniqueId()} sx={{ width: '100%' }}>
              {label} : {labelValue}
            </Box>
          );
        }
      });
    }

    setLabels(labelsArray);
  }, [filters, source]);
  return (
    <Tooltip
      sx={{
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: '400px'
        }
      }}
      placement='bottom-start'
      title={labels.length > 0 ? labels.map(label => label) : ''}>
      {children}
    </Tooltip>
  );
};
FilterToolTip.prototype = {
  filters: PropTypes.object,
  source: PropTypes.string,
  children: PropTypes.node
};

export default memo(FilterToolTip);
