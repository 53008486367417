import React, { lazy, Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import SuspenseCircularLoader from '../../helpers/SuspenseCircularLoader';

// Lazy loading pdf module
const PDFViewerInternal = lazy(() => import('./pdfViewer'));

// Base exported for PDF viewer
// eslint-disable-next-line react/prop-types
const PDFViewer = ({ fileUrl, initialPage, defaultScale, onPageChange, plugins }) => {
  const [cleanedFileUrl, setCleanedFileUrl] = React.useState(null);
  useEffect(() => {
    if (fileUrl) {
      // remove #page=1 from the url
      const cleanedUrl = fileUrl.replace(/#page=\d+/, '');
      setCleanedFileUrl(cleanedUrl);
    }
  }, [fileUrl]);
  if (!cleanedFileUrl) {
    return <CircularProgress />;
  }
  return (
    <Suspense fallback={<SuspenseCircularLoader />}>
      <PDFViewerInternal
        fileUrl={cleanedFileUrl}
        initialPage={initialPage}
        defaultScale={defaultScale}
        onPageChange={onPageChange || (() => {})}
        plugins={plugins}
      />
    </Suspense>
  );
};

PDFViewer.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  initialPage: PropTypes.number,
  defaultScale: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onPageChange: PropTypes.func
};
// eslint-disable-next-line react/default-props-match-prop-types
PDFViewer.defaultProps = {
  initialPage: 0,
  defaultScale: 1.35,
  onPageChange: () => {},
  // eslint-disable-next-line react/default-props-match-prop-types
  plugins: []
};

export default PDFViewer;
