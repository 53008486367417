import { Box, Divider } from '@mui/material';
import React from 'react';
import BlueWaves from '../../../assets/svgs/Report/BlueWaves';

const CoverPageTemplate5 = ({ children }: any) => {
  const [
    LogoComponent,
    TitleComponent,
    DescriptionComponent,
    PreparedByName,
    PreparedDate,
    PoweredByLogo
  ]: any = children;

  return (
    <Box height='100%' width='100%' position='relative'>
      <Box p={4}>
        <Box display='flex' pt={4} justifyContent='center'>
          {LogoComponent}
        </Box>
        <Box p={6} pt={14}>
          <Box p={5} pt={2} pb={2}>
            {TitleComponent}
          </Box>
          <Divider sx={{ borderColor: '#007EC4', borderWidth: 2 }} />
          <Box p={3} pl={4} pr={4}>
            {DescriptionComponent}
          </Box>
        </Box>
        <Box pt={8} textAlign='center'>
          {PreparedDate}
        </Box>
        <Box pt={16} textAlign='center'>
          {PreparedByName}
        </Box>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          textAlign: 'center',
          width: '100%'
        }}>
        <Box>
          <BlueWaves />
        </Box>
        <Box display='inline-block' sx={{ scale: '1.3', pb: 2 }}>
          {PoweredByLogo}
        </Box>
        <Box bgcolor='#007EC4' height={34} />
      </Box>
    </Box>
  );
};

export default React.memo(CoverPageTemplate5);
