import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { TimelineDot } from '@mui/lab';
import { isEmpty } from 'lodash';
import HorizontalDesignationCard from './HorizontalDesignationCard';
import { Add, Remove, RestartAlt } from '@mui/icons-material';
import DesignationStore from '../../../store/Designation';
import {
  horizontalTimelineContainerBeforeStyle,
  horizontalTimelineStyle
} from '../styles/horizontalTimelineStyle';

type MouseEvent = React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>;

const HorizontalTimeline = ({
  dataByYear,
  applicationNumbers
}: {
  dataByYear: any;
  applicationNumbers: string[];
}) => {
  const { designationState } = React.useContext(DesignationStore);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [realign, setRealign] = useState(false);

  const centerRef = useRef<HTMLDivElement>(null);
  let maxKey = '';
  let maxSize = -Infinity;
  Object.keys(dataByYear).forEach(key => {
    if (Object.keys(key).length > maxSize) {
      maxSize = dataByYear[key].length;
      maxKey = key;
    }
  });
  const handleMouseDown = (event: MouseEvent) => {
    setIsDragging(true);
    setLastPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragging) return;

    const { scrollTop, scrollLeft } = scrollRef.current!;
    scrollRef.current!.scrollTop = scrollTop - (event.clientY - lastPosition.y);
    scrollRef.current!.scrollLeft = scrollLeft - (event.clientX - lastPosition.x);

    setLastPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleZoomIn = () => {
    setZoom(zoom + 0.1);
  };
  const handleZoomOut = () => {
    setZoom(zoom - 0.1);
  };
  const handleZoomReset = () => {
    setZoom(1);
  };
  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      const scrollLeft = (scrollRef.current?.offsetWidth - centerRef.current?.offsetWidth) / 2;
      // @ts-ignore
      scrollRef.current?.scrollTo({ left: scrollLeft, behavior: 'smooth' });
    }, 500);
  }, []);

  return (
    <React.Fragment>
      <Stack
        direction={'row'}
        width={'100%'}
        overflow={designationState.openAllSeeMore ? 'visible' : 'auto'}
        maxWidth={designationState.openAllSeeMore ? '100%' : '93vw'}
        height={'100%'}
        maxHeight={designationState.openAllSeeMore ? '100%' : '70vh'}
        ref={scrollRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        sx={{
          ...horizontalTimelineStyle,
          '&>div': {
            transform: designationState.openAllSeeMore ? 'none' : `scale(${zoom})`,
            transformOrigin: 'left top',
            transition: 'transform 0.3s ease-in-out',
            minWidth: designationState.openAllSeeMore ? 250 : 250 * zoom
          }
        }}>
        {Object.entries(dataByYear)
          .sort(([yearA], [yearB]) => {
            if (designationState.sortBy === 'asc') {
              return parseInt(yearB) - parseInt(yearA);
            }
            return parseInt(yearA) - parseInt(yearB);
          })
          .map(([year, data]: any, index) => {
            // if (Object.entries(dataByYear).length === index + 1) {
            //  setRealign(true);
            // }
            return (
              <Stack
                direction={'column'}
                alignItems={'center'}
                key={year}
                width={'100%'}
                id={`year-${year}`}
                ref={year === maxKey ? centerRef : null}>
                <Stack
                  direction={'row'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  width={'100%'}
                  height={80}
                  sx={{
                    position: 'relative',
                    '&::before': {
                      width: designationState.openAllSeeMore
                        ? '100%'
                        : `${Math.min(zoom * 10 * 100, 100)}%`,
                      ...horizontalTimelineContainerBeforeStyle
                    }
                  }}>
                  <TimelineDot
                    sx={{
                      bgcolor: isEmpty(data) ? 'rgba(46,204,113,0.3)' : `rgb(46, 204, 113)`,
                      zIndex: 10
                    }}>
                    <Avatar sx={{ width: 46, height: 46, p: 2, bgcolor: 'transparent' }}>
                      <Typography variant={'caption'}>{year === '1900' ? 'NA' : year}</Typography>
                    </Avatar>
                  </TimelineDot>
                </Stack>
                <Stack display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                  {applicationNumbers.map((item, index) => {
                    if (item in data) {
                      return <HorizontalDesignationCard key={index} {...data[item]} />;
                    } else {
                      return <HorizontalDesignationCard key={index} blankCard={true} />;
                    }
                  })}
                </Stack>
              </Stack>
            );
          })}
      </Stack>

      {!designationState.openAllSeeMore && (
        <Stack direction={'column'} justifyContent={'center'}>
          <Tooltip title={'Zoom In'}>
            <Box>
              <IconButton onClick={handleZoomIn} disabled={zoom >= 1.5}>
                <Add />
              </IconButton>
            </Box>
          </Tooltip>
          <Tooltip title={'Zoom Out'}>
            <Box>
              <IconButton onClick={handleZoomOut} disabled={zoom <= 0.5}>
                <Remove />
              </IconButton>
            </Box>
          </Tooltip>
          <Tooltip title={'Reset Zoom'}>
            <Box>
              <IconButton onClick={handleZoomReset} disabled={zoom === 1}>
                <RestartAlt />
              </IconButton>
            </Box>
          </Tooltip>
        </Stack>
      )}
    </React.Fragment>
  );
};
export default React.memo(HorizontalTimeline);
