import { memo, useContext, useMemo } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// Import MUI.
import React, {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';

import AuthContext from '../../../store/Auth/AuthProvider';

// context store
import { logoutUserFromQueue } from '../../../api/pages/UserProfile';
import topNavigationStyle from '../styles/TopNavigation.styles';
import { RoundedMenu } from '../../ui/Menu';
import { StarOutlineIcon, SubscribeIcon } from '../../../assets/svgs/Icons';
import HelpCenterIcon from '../../../assets/svgs/UserMenu/HelpCenterIcon';
import MyProjectsIcon from '../../../assets/svgs/UserMenu/MyProjectsIcon';
import MyReportsIcon from '../../../assets/svgs/UserMenu/MyReportsIcon';
import SignOutIcon from '../../../assets/svgs/UserMenu/SignOutIcon';
import UserIcon from '../../../assets/svgs/UserMenu/UserIcon';
import ActivityIcon from '../../../assets/svgs/UserMenu/ActivityIcon';

const UserInfoPopover = ({ menuAnchorEl, menuOpen, menuOnClose }: any) => {
  const history = useHistory();
  const location = useLocation();
  const { currentUser, setCurrentUser } = useContext(AuthContext);

  const performLogout = async () => {
    localStorage.removeItem('layout_json');
    await logoutUserFromQueue();
    await Auth.signOut({ global: true });
    setCurrentUser(false);
  };
  const disablePasswordChange = useMemo(() => {
    return currentUser && 'identities' in currentUser;
  }, [currentUser]);

  if (!currentUser) {
    return <Redirect to='/' />;
  }

  return (
    <RoundedMenu
      id='profile-menu'
      anchorEl={menuAnchorEl}
      open={menuOpen}
      onClose={menuOnClose}
      sx={topNavigationStyle.menu}
      MenuListProps={topNavigationStyle.menuList}
      PaperProps={{
        style: {
          width: '188px'
        }
      }}>
      <Tooltip title={disablePasswordChange && 'SSO users cannot change passwords'}>
        <MenuItem
          sx={{ mt: '0 !important' }}
          onClick={() => {
            if (disablePasswordChange) return;
            history.push('/reset');
            menuOnClose();
          }}>
          <ListItemIcon>
            <UserIcon sx={{ color: 'gray.700' }} />
          </ListItemIcon>
          <ListItemText>
            <Stack>
              <Typography
                sx={{
                  color: 'gray.800',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: 12,
                  fontWeight: 400
                }}>
                {currentUser?.email}
              </Typography>
              <Typography
                sx={{
                  color: disablePasswordChange ? 'grey' : 'primary.600',
                  fontSize: 12,
                  fontWeight: 400
                }}>
                Change password
              </Typography>
            </Stack>
          </ListItemText>
        </MenuItem>
      </Tooltip>
      <Divider />
      <MenuItem
        selected={!!(location.pathname.match('activity') || location.pathname === '/account')}
        onClick={() => {
          history.push('/account/activity');
          menuOnClose();
        }}>
        <ListItemIcon>
          <ActivityIcon sx={{ ml: '7px', color: 'gray.700', fontSize: '14px' }} />
        </ListItemIcon>
        <ListItemText primary='Activity' />
      </MenuItem>
      <MenuItem
        selected={!!location.pathname.match('saved')}
        onClick={() => {
          history.push('/account/saved');
          menuOnClose();
        }}>
        <ListItemIcon>
          <StarOutlineIcon sx={{ ml: '4px', color: 'gray.700', fontSize: '18px' }} />
        </ListItemIcon>
        <ListItemText
          primary='Saved items'
          sx={{
            fontSize: 12,
            color: 'gray.800',
            fontWeight: 400
          }}
        />
      </MenuItem>
      <MenuItem
        selected={!!location.pathname.match('reports')}
        onClick={() => {
          history.push('/account/reports');
          menuOnClose();
        }}>
        <ListItemIcon>
          <MyReportsIcon sx={{ ml: '7px', color: 'gray.700', fontSize: '14px' }} />
        </ListItemIcon>
        <ListItemText primary='My reports' />
      </MenuItem>
      <MenuItem
        selected={!!location.pathname.match('subscriptions')}
        onClick={() => {
          history.push('/account/subscriptions');
          menuOnClose();
        }}>
        <ListItemIcon>
          <SubscribeIcon sx={{ ml: '4px', color: 'gray.700', fontSize: '18px' }} />
        </ListItemIcon>
        <ListItemText
          primary='My Subscriptions'
          sx={{
            fontSize: 12,
            color: 'gray.800',
            fontWeight: 400
          }}
        />
      </MenuItem>
      <MenuItem
        selected={!!location.pathname.match('project')}
        onClick={() => {
          history.push('/account/project');
          menuOnClose();
        }}>
        <ListItemIcon>
          <MyProjectsIcon sx={{ ml: '7px', color: 'gray.700', fontSize: '14px' }} />
        </ListItemIcon>
        <ListItemText primary='My projects' />
      </MenuItem>
      <MenuItem
        selected={!!location.pathname.match('help')}
        onClick={() => {
          history.push('/help');
          menuOnClose();
        }}>
        <ListItemIcon>
          <HelpCenterIcon sx={{ ml: '7px', color: 'gray.700', fontSize: '14px' }} />
        </ListItemIcon>
        <ListItemText primary='Help center' />
      </MenuItem>
      <Divider />
      <MenuItem sx={{ mb: '0 !important' }} onClick={async () => performLogout()}>
        <ListItemIcon>
          <SignOutIcon sx={{ ml: '7px', color: 'gray.700', fontSize: '14px' }} />
        </ListItemIcon>
        <ListItemText>Sign out</ListItemText>
      </MenuItem>
    </RoundedMenu>
  );
};

export default memo(UserInfoPopover);
