import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import React, { useEffect, useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Typography from '@mui/material/Typography';

import { getLogoS3Lnk, uploadReporFile } from '../../api/modules/Reports';

const styles = {
  headerLogo: {
    cursor: 'pointer',
    '&:hover': {
      border: '2px dashed #ddd'
    }
  }
};

const PDFLogo = ({
  assetKey,
  onLogoKeyChange
}: {
  assetKey: string | null;
  // eslint-disable-next-line no-unused-vars
  onLogoKeyChange: (key: string) => void;
}) => {
  const [coverPageAnchorEl, setCoverPageAnchorEl] = useState<any>(null);
  const [coverPageLogo, setCoverpageLogo] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const getLogoURL = async () => {
    let logoKey = assetKey;
    if (!logoKey) {
      logoKey = 'vivpro.png';
    }
    const response = await getLogoS3Lnk('logo', logoKey);
    if (response && response.data.status === 200) {
      setCoverpageLogo(response.data.Success.s3_url);
    }
  };

  const handleFileInputChange = async (event: any) => {
    const files = event.target.files;
    // Process the selected files here
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('category', 'logo');

    const response = await uploadReporFile(formData);
    if (response && response.data.status === 200) {
      onLogoKeyChange?.(response.data.Success.file_key);
      setCoverPageAnchorEl(null);
    }
  };

  useEffect(() => {
    getLogoURL();
  }, [assetKey]);

  return (
    <Box>
      <Box
        height='64px'
        sx={styles.headerLogo}
        onClick={(event: any) => setCoverPageAnchorEl(event.currentTarget)}>
        {coverPageLogo && <img src={coverPageLogo} alt='Logo' height='100%' width='auto' />}
      </Box>
      <Popover
        open={!!coverPageAnchorEl}
        anchorEl={coverPageAnchorEl}
        onClose={() => setCoverPageAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <input
          type='file'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileInputChange}
        />
        <IconButton
          aria-label='upload'
          onClick={() => fileInputRef.current?.click()}
          color='primary'>
          <CloudUploadIcon />
          <Typography pl={1}>Upload your logo</Typography>
        </IconButton>
      </Popover>
    </Box>
  );
};

export default React.memo(PDFLogo);
