import { useCallback, useContext, memo } from 'react';

// mui
import { Box, Grid, Stack, Typography, Button, CircularProgress, Skeleton } from '@mui/material';

import uniqueId from 'lodash/uniqueId';

// store
import OrphanDesignationsStore from '../../../store/OrphanDesignations';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

// utils
import formatCount from '../../../utils/formatCount';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';

// components
import PieChart from './PieChart';

import StatsCountTile from '../../../helpers/baseComponents/StatsCountTile';
import CustomBarGraph from '../../../components/CustomComponents/Graphs/CustomBarGraph';

// styles
import styles from '../styles/Insights.styles';

import { REPORT_QUERIES } from '../constants';
import palette from '../../../themev5/palette';

const Insights = () => {
  const { OrphanDesignationsState } = useContext(OrphanDesignationsStore);
  const { dispatch }: any = useContext(GlobalStore);

  const addToReport = useCallback(
    async (key: any) => {
      const addedReport = REPORT_QUERIES.find((report: any) => report.id === key);
      const random = Math.floor(Math.random() * 1000);
      dispatch({
        type: GlobalActions.ADD_TEMPLATE_TO_REPORT,
        value: {
          templateType: 'custom',
          chartType: addedReport?.graph,
          sectionType: 'CHART',
          id: uniqueId(`ema_orphan_${random}_`),
          style: {
            placement: {
              h: 40,
              w: 12
            },
            graphStyle: addedReport?.graphStyle,
            title: addedReport?.title
          },
          data: {
            source: 'ema_orphan',
            search_type: 'ema_orphan',
            disableSelectionSource: true,
            filters: OrphanDesignationsState.availableFilters,
            query: addedReport?.query
          }
        }
      });
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Successfully added to Report.', color: 'success' }
      });
    },
    [dispatch, OrphanDesignationsState.barGraph, OrphanDesignationsState.availableFilters]
  );

  return (
    <Stack>
      <Grid container>
        {/* TILES */}
        <Grid item xs={12} lg={3}>
          {OrphanDesignationsState.stats && !OrphanDesignationsState.isLoading ? (
            <>
              <StatsCountTile
                text='Orphan Designations'
                count={OrphanDesignationsState?.stats?.total ?? 0}
              />
              <StatsCountTile
                text='Active Substances'
                count={OrphanDesignationsState?.stats?.totalUniqueActiveSubstances ?? 0}
              />
              <StatsCountTile
                text='Total Documents | Pages'
                count={`${truncateCountAboveMillions(
                  OrphanDesignationsState?.stats?.totalDocuments ?? 0
                )} | ${truncateCountAboveMillions(
                  OrphanDesignationsState?.stats?.totalPages ?? 0
                )}`}
                toolTipValue={`${formatCount(
                  OrphanDesignationsState?.stats?.totalDocuments ?? 0
                )} | ${formatCount(OrphanDesignationsState?.stats?.totalPages ?? 0)}`}
              />
            </>
          ) : (
            <Box ml={5} display='flex' flexDirection='column' sx={styles.cards}>
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
            </Box>
          )}
        </Grid>
        {/* PIES */}
        <Grid item xs={12} lg={8}>
          <Box display='flex' flexDirection='column' sx={styles.box}>
            <Box display='flex' flexDirection='column'>
              <Box display='flex' justifyContent='space-between'>
                {!OrphanDesignationsState.isLoading ? (
                  <>
                    <Typography sx={styles.graphHeading}>
                      Opinions on Orphan Medicinal Product Designation by Year
                    </Typography>
                    <Button
                      onClick={() => addToReport('ema_orphan_designations_by_year')}
                      variant='text'
                      fullWidth={false}
                      sx={styles.addToReport}>
                      <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                        + Add to Report
                      </Typography>
                    </Button>
                  </>
                ) : (
                  <>
                    <Skeleton height={32} width={200} sx={styles.graphHeading} />
                    <Skeleton height={28} width={150} sx={styles.reportText} />
                  </>
                )}
              </Box>
              {/* Bar Graph */}
              <Box display='flex' justifyContent='center' alignItems='center' sx={styles.barGraph}>
                {OrphanDesignationsState.isLoading ? (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-end'
                    alignItems='space-between'
                    sx={styles.loaderBox}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      flexWrap='wrap-reverse'
                      sx={styles.barGraphRect}>
                      <Skeleton variant='rectangular' height='90%' width='.2%' />
                      <Skeleton variant='rectangular' height='60%' width='3%' />
                      <Skeleton variant='rectangular' height='80%' width='3%' />
                      <Skeleton variant='rectangular' height='30%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='60%' width='3%' />
                      <Skeleton variant='rectangular' height='80%' width='3%' />
                      <Skeleton variant='rectangular' height='30%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                    </Box>
                    <Skeleton variant='rectangular' height='.2%' width='100%' />
                  </Box>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {OrphanDesignationsState?.barGraph &&
                    OrphanDesignationsState.barGraph.length > 0 ? (
                      <CustomBarGraph
                        keys={['count']}
                        index='year'
                        colors={['#B1BBE4']}
                        data={OrphanDesignationsState.barGraph}
                        paddings={0.8}
                        // @ts-ignore
                        labelTextColor={palette.gray[0]}
                        // @ts-ignore
                        axisText='#A3A3A3'
                      />
                    ) : (
                      <Typography variant='subtitle2' sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                        No data
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' sx={styles.pieCharts}>
              {/* [{graph: []}, {graph: []}, {graph: []}] */}
              {OrphanDesignationsState.isLoading ? (
                <>
                  {Array.from(Array(3)).map((_, index) => (
                    <Box
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      display='flex'
                      flexDirection='column'
                      sx={styles.pieContainers}>
                      <Box display='flex' justifyContent='space-between'>
                        <Skeleton height={32} width={100} sx={styles.graphHeading} />
                        <Skeleton height={28} width={50} sx={styles.reportText} />
                      </Box>
                      <Box display='flex' alignSelf='center' pt={4} sx={styles.graphContainer}>
                        <CircularProgress
                          value={90}
                          thickness={5}
                          size='5rem'
                          variant='indeterminate'
                          sx={{
                            color: '#adadac',
                            opacity: 0.5
                          }}
                        />
                      </Box>
                    </Box>
                  ))}
                </>
              ) : (
                OrphanDesignationsState?.charts?.map((chart: any) => (
                  <PieChart
                    key={`ema_orphan_${chart.apiKey}`}
                    data={chart.children}
                    label={chart.label}
                    id={chart.apiKey}
                  />
                ))
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default memo(Insights);
