import React from 'react';
import PropTypes from 'prop-types';

// material ui
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@mui/material/Tooltip';

// icons
import ShowChartIcon from '@material-ui/icons/ShowChart';

// styles
import Styles from '../styles/StatsCountTile';

const StatsCountTile = ({ text, count, toolTipValue }) => {
  const classes = Styles();

  return (
    <Paper elevation={2} className={classes.paper}>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        className={classes.content}>
        <Box display='flex' flexDirection='column'>
          <Typography className={classes.title}>{text}</Typography>
          <Tooltip title={toolTipValue}>
            <Typography className={classes.count}>{count}</Typography>
          </Tooltip>
        </Box>
        <ShowChartIcon fontSize='large' className={classes.icon} />
      </Box>
    </Paper>
  );
};

StatsCountTile.propTypes = {
  text: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  // eslint-disable-next-line react/require-default-props
  toolTipValue: PropTypes.string
};

export default StatsCountTile;
