import React, { useCallback, useContext } from 'react';

// mui
import uniqueId from 'lodash/uniqueId';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// store
import store from '../../../store/EMAGuidance';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

// utils
import formatCount from '../../../utils/formatCount';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';
import { EMA_GUIDANCE_REPORT_QUERIES } from '../const';

// components
import StatsCountTile from '../../../helpers/baseComponents/StatsCountTile';
import CustomBarGraph from '../../../components/CustomComponents/Graphs/CustomBarGraph';
import PieChart from './PieChart';

// styles
import styles from '../styles/Insights.styles';

const EMAGuidanceInsights = () => {
  const { EMAGuidanceState } = useContext(store);
  const { state, dispatch } = useContext(GlobalStore) as any;

  const addToReport = useCallback(
    async (id: string) => {
      const addedReport = EMA_GUIDANCE_REPORT_QUERIES.find(report => report.id === id);
      const random = Math.floor(Math.random() * 1000);
      dispatch({
        type: GlobalActions.ADD_TEMPLATE_TO_REPORT,
        value: {
          templateType: 'custom',
          chartType: addedReport?.graph,
          sectionType: 'CHART',
          id: uniqueId(`ema-gudiance_${random}_`),
          style: {
            placement: {
              h: 40,
              w: 12
            },
            graphStyle: addedReport?.graphStyle,
            title: addedReport?.title
          },
          data: {
            source: 'ema-guidance',
            search_type: 'guidance',
            disableSelectionSource: true,
            filters: EMAGuidanceState.availableFilters,
            query: addedReport?.query
          }
        }
      });
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Successfully added to Report.', color: 'success' }
      });
    },
    [state.cartItems, dispatch, EMAGuidanceState.filters]
  );

  return (
    <Stack>
      <Grid container>
        <Grid item xs={12} lg={3}>
          {Object.keys(EMAGuidanceState.emaGuidanceStats).length > 0 &&
          !EMAGuidanceState.loading ? (
            <>
              <StatsCountTile
                text='Total Guidances'
                count={EMAGuidanceState.emaGuidanceStats.totalGuidance}
                toolTipValue=''
              />
              <StatsCountTile
                text='Status Adopted'
                count={EMAGuidanceState.emaGuidanceStats.totalAdoptedCount}
                toolTipValue=''
              />
              <StatsCountTile
                text='Published in Last 30 Days'
                count={EMAGuidanceState.emaGuidanceStats.totalLastThirtyDays}
                toolTipValue=''
              />
              <StatsCountTile
                text='Total Documents | Pages'
                count={`${truncateCountAboveMillions(
                  EMAGuidanceState.emaGuidanceStats.pdfCount
                )} | ${truncateCountAboveMillions(EMAGuidanceState.emaGuidanceStats.pageCount)}`}
                toolTipValue={`${formatCount(
                  EMAGuidanceState.emaGuidanceStats.pdfCount
                )} | ${formatCount(EMAGuidanceState.emaGuidanceStats.pageCount)}`}
              />
            </>
          ) : (
            <Box ml={5} display='flex' flexDirection='column' sx={styles.cards}>
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box display='flex' flexDirection='column' sx={styles.box}>
            <Box display='flex' flexDirection='column'>
              <Box display='flex' justifyContent='space-between'>
                {Object.keys(EMAGuidanceState.publishedDate).length > 0 &&
                !EMAGuidanceState.loading ? (
                  <>
                    <Typography sx={styles.graphHeading}>
                      Guidance Documents by Published Year
                    </Typography>
                    <Button
                      onClick={() => addToReport('guidance_documents_by_year')}
                      variant='text'
                      fullWidth={false}
                      sx={styles.addToReport}>
                      <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                        + Add to Report
                      </Typography>
                    </Button>
                  </>
                ) : (
                  <>
                    <Skeleton height={32} width={200} sx={styles.graphHeading} />
                    <Skeleton height={28} width={150} sx={styles.reportText} />
                  </>
                )}
              </Box>
              <Box display='flex' justifyContent='center' alignItems='center' sx={styles.barGraph}>
                {!EMAGuidanceState.loading ? (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {Object.keys(EMAGuidanceState?.publishedDate).length > 0 ? (
                      <CustomBarGraph
                        keys={['value']}
                        index='id'
                        data={EMAGuidanceState?.publishedDate}
                        paddings={0.8}
                        colors={['#B1BBE4']}
                        // @ts-ignore
                        tickRotationValue={25}
                        // @ts-ignore
                        labelTextColor='#ffffff'
                        // @ts-ignore
                        axisText='#A3A3A3'
                        enableLabel={undefined}
                      />
                    ) : (
                      <Typography variant='subtitle2' sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                        No data
                      </Typography>
                    )}
                  </>
                ) : (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-end'
                    alignItems='space-between'
                    sx={styles.loaderBox}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      flexWrap='wrap-reverse'
                      sx={styles.barGraphRect}>
                      <Skeleton variant='rectangular' height='90%' width='.2%' />
                      <Skeleton variant='rectangular' height='60%' width='3%' />
                      <Skeleton variant='rectangular' height='80%' width='3%' />
                      <Skeleton variant='rectangular' height='30%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='60%' width='3%' />
                      <Skeleton variant='rectangular' height='80%' width='3%' />
                      <Skeleton variant='rectangular' height='30%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='90%' width='3%' />
                      <Skeleton variant='rectangular' height='60%' width='3%' />
                      <Skeleton variant='rectangular' height='80%' width='3%' />
                    </Box>
                    <Skeleton variant='rectangular' height='.2%' width='100%' />
                  </Box>
                )}
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' sx={styles.pieCharts}>
              {EMAGuidanceState.loading ? (
                <>
                  {Array.from(Array(2)).map((_, index) => (
                    <Box
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      display='flex'
                      flexDirection='column'
                      sx={styles.pieContainers}>
                      <Box display='flex' justifyContent='space-between'>
                        <Skeleton height={32} width={100} sx={styles.graphHeading} />
                        <Skeleton height={28} width={50} sx={styles.reportText} />
                      </Box>
                      <Box display='flex' alignSelf='center' pt={4} sx={styles.graphContainer}>
                        <CircularProgress
                          value={90}
                          thickness={5}
                          size='5rem'
                          variant='indeterminate'
                          sx={{
                            color: '#adadac',
                            opacity: 0.5
                          }}
                          // @ts-ignore
                          margin={{ top: 20, right: 45, bottom: 50, left: 30 }}
                        />
                      </Box>
                    </Box>
                  ))}
                </>
              ) : (
                EMAGuidanceState.donutCharts.map((donutChart, i) => (
                  <PieChart
                    // eslint-disable-next-line react/no-array-index-key
                    key={`guidance_treemap__${i}`}
                    data={donutChart.children}
                    label={donutChart.label}
                    id={donutChart.graphItem}
                  />
                ))
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default React.memo(EMAGuidanceInsights);
