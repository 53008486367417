import theme from '../../../themev5';

const styles = {
  box: {
    borderRadius: '3px',
    width: '69.5vw'
  },
  barGraph: {
    backgroundColor: 'white.main',
    height: '40vh',
    borderRadius: '20px',
    [theme.breakpoints.up('xl')]: {
      height: '35vh'
    }
  },
  //
  pieCharts: {
    paddingTop: 1,
    height: '30vh'
  },
  graphHeading: {
    fontSize: '20px',
    color: 'gray.lightVariant',
    fontWeight: 'bold'
  },
  reportText: {
    color: 'primary',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'none'
    }
  },
  pieContainers: {
    heigth: '100%',
    marginTop: 1,
    marginLeft: 1,
    padding: 1,
    width: '50%',
    backgroundColor: 'white.main',
    borderRadius: '10px'
  },
  graphContainer: {
    width: '100%',
    height: '25vh',
    paddingLeft: '42%'
  },

  // stats
  cards: {
    height: '80vh'
  },
  paper: {
    margin: 3,
    width: '50%',
    height: '50%',
    padding: 3,
    borderRadius: 3
  },
  loaderBox: {
    width: '90%',
    height: '90%'
  },
  barGraphRect: {
    width: '100%',
    height: '100%'
  },
  addToReport: {
    height: '35px',
    cursor: 'pointer',
    background: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      border: 1,
      borderColor: 'primary.main',
      borderStyle: 'solid'
    }
  },
  container: {
    heigth: '100%',
    marginTop: 1,
    marginLeft: 1,
    padding: 1,
    width: '50%',
    backgroundColor: 'white.main',
    borderRadius: '10px'
  },
  text: {
    color: 'gray.lightVariant',
    fontSize: '16px',
    paddingTop: '0.5rem',
    fontWeight: 700
  },
  pieChartMargin: { top: 20, right: 45, bottom: 50, left: 30 }
};

export default styles;
