import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const ReportExistDialog = () => {
  return (
    <Dialog open maxWidth='xs' fullWidth>
      <DialogTitle sx={{ padding: '16px 40px', display: 'flex', alignItems: 'center' }}>
        <ErrorOutlineOutlinedIcon />
        <Box display='flex' pl={2}>
          Not Found
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' p={2}>
          Report not available
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'end', pb: 2, pr: 4 }}>
        <Button
          onClick={() => {
            window.close();
          }}
          variant='contained'
          sx={{ color: 'white.main' }}>
          Exit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ReportExistDialog);
