import { memo, useCallback, useState } from 'react';

import { Grid, Stack, Box, Typography, TextField, Button, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import VideoCard from '../VideoCard/VideoCard';
import VideoTutorialCard from '../VideoCard/VideoTutorialCard';
import { GradientArrowDownIcon } from '../../../../assets/svgs/Icons';
import { VIDEO_TUTORIALS } from '../../utils/constants';
import styles from './VideoList.styles';

const VideoList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredVideos, setFilteredVideos] = useState(VIDEO_TUTORIALS);
  const [isSearched, setIsSearched] = useState(false);

  const handleResetFilters = useCallback(() => {
    setFilteredVideos(VIDEO_TUTORIALS);
    setSearchTerm('');
    setIsSearched(false);
  }, []);

  function filterByValues(array, strings) {
    return array.filter(o =>
      Object.keys(o).some(k => {
        // eslint-disable-next-line no-restricted-syntax
        for (const string of strings) {
          const p = o[k].toString().toLowerCase();
          const q = string.toLowerCase();
          if (p.includes(q)) {
            return true;
          }
        }

        return false;
      })
    );
  }

  const handleApplyFilters = useCallback(() => {
    const filterArr = searchTerm
      .split(',')
      .map(element => element.trim())
      .filter(Boolean);
    if (filterArr.length === 0) {
      handleResetFilters();
      setIsSearched(false);
    } else {
      setFilteredVideos(filterByValues(VIDEO_TUTORIALS, filterArr));
      setIsSearched(true);
    }
  }, [searchTerm, handleResetFilters]);

  const keyPress = useCallback(
    e => {
      if (e.keyCode === 13) {
        handleApplyFilters();
        if (searchTerm) {
          setIsSearched(true);
        } else {
          setIsSearched(false);
        }
      }
    },
    [handleApplyFilters]
  );
  return (
    <>
      <Box display='flex' flexDirection='column' justifyContent='center'>
        <Box display='flex' justifyContent='center' mb={4} mx={6.8}>
          <TextField
            id='videos-search'
            onChange={event => {
              event.preventDefault();
              setSearchTerm(event.target.value);
            }}
            onKeyDown={keyPress}
            value={searchTerm}
            padding={0}
            placeholder='Search Tutorials Videos'
            variant='outlined'
            sx={styles.searchInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon sx={{ color: '#BDBDBD', cursor: 'pointer' }} />
                </InputAdornment>
              ),
              onClick: handleApplyFilters,
              sx: { borderRadius: 2, fontSize: 14, width: 288, height: 40 },
              inputProps: {
                maxLength: 100
              }
            }}
          />
          <Button onClick={handleResetFilters} sx={styles.resetButton}>
            Reset
          </Button>
        </Box>
        <Stack>
          <Box display='flex' flexDirection='column'>
            {!isSearched && (
              <Box display='flex' alignItems='center' justifyContent='center'>
                <Typography sx={styles.newTutorialText}>Video Tutorials (All)</Typography>
              </Box>
            )}
            <Grid container sx={styles.newVideosContainer}>
              {filteredVideos.map(videoTutorial => (
                <Grid item xs='auto' md='auto' lg='auto' key={videoTutorial.id}>
                  <VideoTutorialCard video={videoTutorial} />
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box sx={styles.comingSoonContainer}>
            <Typography sx={styles.moreVideosTitle}>More Videos</Typography>
            <Typography sx={styles.comingSoonTitle}>Coming Soon</Typography>
            <Typography sx={styles.comingSoonDescription}>
              Our latest application version maintains the intuitive workflow you&apos;re familiar
              with. While we&apos;re excited to bring you updated tutorial videos, you can
              confidently use our existing tutorials to navigate the new features seamlessly!
            </Typography>
            <GradientArrowDownIcon sx={styles.gradientDownArrowIcon} />
          </Box>
        </Stack>
      </Box>
      <Grid container spacing={2} justifyContent='flex-start' paddingBottom={10} mt='0 !important'>
        {VIDEO_TUTORIALS?.map(
          video =>
            !video.isNewTutorial && (
              <Grid item xs={12} sm={6} md={4} lg={3} key={video.id}>
                <VideoCard video={video} />
              </Grid>
            )
        )}
      </Grid>
    </>
  );
};

export default memo(VideoList);
