import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    minHeight: '50vh'
  },
  heading: {
    color: theme.palette.cards.green,
    fontSize: theme.spacing(2),
    fontWeight: '600'
  },
  content: {
    maxHeight: '55vh',
    overflow: 'auto'
  }
}));
