import React from 'react';
import { TableCell, Typography, Box } from '@mui/material';
import snapshotStyles from '../snapshot/styles';

const DiagonalDividedCell = ({ topRight, bottomLeft }: any) => (
  <TableCell sx={snapshotStyles.clinicalSummary.diagonalDividedCell.tableCell}>
    <svg
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1
      }}>
      <line x1='0' y1='0' x2='100%' y2='100%' stroke='black' strokeWidth='0.75' />
    </svg>
    <Box sx={snapshotStyles.clinicalSummary.diagonalDividedCell.diagonalCellTextContainer}>
      <Typography
        variant='body2'
        sx={snapshotStyles.clinicalSummary.diagonalDividedCell.bottomLeftText}>
        {bottomLeft}
      </Typography>
      <Typography
        variant='body2'
        sx={snapshotStyles.clinicalSummary.diagonalDividedCell.topRightText}>
        {topRight}
      </Typography>
    </Box>
  </TableCell>
);

export default React.memo(DiagonalDividedCell);
