import React from 'react';
import { Avatar, Box, Divider, Grid, Typography } from '@mui/material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator
} from '@mui/lab';
import DesignationCardBox from './DesignationCardBox';
import { designationName, designationTypes, designationTypesColor } from './SpecialityTimeLine';
import DesignationStore from '../../../store/Designation';
import compactModeStyle from '../styles/compactModeStyle';

const VerticalTimeline = ({
  dataByYear,
  viewMode = false
}: {
  dataByYear: any;
  // eslint-disable-next-line react/require-default-props
  viewMode?: boolean;
}) => {
  const { designationState } = React.useContext(DesignationStore);

  return (
    <>
      {designationTypes.map(type => (
        <Grid
          key={type}
          item
          xs={3}
          sx={{
            textTransform: 'uppercase',
            textAlign: 'center'
          }}>
          <Box
            sx={{
              border: `2px solid ${designationTypesColor[type].shadow}`,
              borderRadius: 5,
              backgroundColor: 'white',
              boxShadow: `1px 1px 10px 5px ${designationTypesColor[type].shadow} `,
              m: 2,
              mt: 0,
              p: 2,
              pr: 1,
              height: '100%',
              ...(viewMode ? compactModeStyle.designationContainer : {})
            }}>
            <Typography variant='body1' gutterBottom>
              {designationName[type]}
            </Typography>
            <Divider />
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0
                }
              }}>
              {dataByYear &&
                Object.keys(dataByYear)
                  .sort((a, b) => {
                    if (designationState.sortBy === 'asc') {
                      return parseInt(b) - parseInt(a);
                    }
                    return parseInt(a) - parseInt(b);
                  })
                  .map((key, index, row) => {
                    const year = key;
                    const keyData = dataByYear[year][type];
                    const data = keyData ? (Object.values(keyData) as DesignationCardBoxProps) : [];
                    return (
                      <React.Fragment key={`${key}-${type}`}>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{
                                bgcolor:
                                  data.length == 0 ? 'rgba(46,204,113,0.3)' : `rgb(46, 204, 113)`
                              }}>
                              <Avatar
                                sx={{
                                  width: 46,
                                  height: 46,
                                  p: 2,
                                  bgcolor: 'transparent',
                                  ...(viewMode ? compactModeStyle.timeLineDot : {})
                                }}>
                                <Typography variant={'caption'}>
                                  {year === '1900' ? 'NA' : year}
                                </Typography>
                              </Avatar>
                            </TimelineDot>
                            <TimelineConnector
                              sx={{
                                minHeight: 80,
                                mr: 'auto',
                                ml: 3.5,
                                ...(viewMode ? compactModeStyle.timelineConnector : {})
                              }}
                            />
                          </TimelineSeparator>
                          <TimelineContent
                            display={'flex'}
                            justifyContent={'flex-start'}
                            flexDirection={'column'}
                            sx={{ width: '80%', position: 'relative', minHeight: 175 }}>
                            <DesignationCardBox
                              designations={data}
                              type={type}
                              viewMode={viewMode}
                            />
                          </TimelineContent>
                        </TimelineItem>
                      </React.Fragment>
                    );
                  })}
            </Timeline>
          </Box>
        </Grid>
      ))}
    </>
  );
};
export default React.memo(VerticalTimeline);
