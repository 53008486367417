import React, { useContext } from 'react';

import Box from '@mui/material/Box';

import CoverPageTemplate1 from './CoverPageTemplate1';
import CoverPageTemplate2 from './CoverPageTemplate2';
import CoverPageTemplate3 from './CoverPageTemplate3';
import CoverPageTemplate5 from './CoverPageTemplate5';

import AppStore from '../../../store';
import actions from '../../../store/actions';
import PDFLogo from '../PDFLogo';
import CoverPageTitle from './CoverPageTitle';
import PreparedByName from './PreparedByName';
import PreparedDate from './PreparedDate';
import PoweredByLogo from './PoweredByLogo';
import CoverPageDescription from './CoverPageDescription';

const COVER_PAGE_TEMPLATE: any = {
  template1: CoverPageTemplate1,
  template2: CoverPageTemplate2,
  template3: CoverPageTemplate3,
  template5: CoverPageTemplate5
};

const CoverPage = ({
  title,
  description,
  ownerName,
  logo,
  templateType = 'template1',
  onTitleChange,
  onDescriptionChange,
  createdAt
}: any) => {
  const { dispatch } = useContext<{ dispatch: any }>(AppStore as any);

  const handleLogoKeyChange = async (fileKey: any) => {
    dispatch?.({
      type: actions.UPDATE_REPORT_HEADER_LOGO,
      value: fileKey
    });
  };
  const TemplateComponent: any = COVER_PAGE_TEMPLATE[templateType];
  return (
    <Box
      sx={{
        pageBreakAfter: 'always' /* Ensure each page starts on a new page when printing */,
        // padding: '20px' /* Add padding around the content within each page */,
        height: '297mm',
        '-webkit-print-color-adjust': 'exact !important' /* Chrome, Safari 6 – 15.3, Edge */,
        colorAdjust: 'exact !important' /* Firefox 48 – 96 */,
        printColorAdjust: 'exact !important' /* Firefox 97+, Safari 15.4+ */,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: '#fff',
        fontFamily: 'Mulish',
        position: 'relative'
      }}>
      <TemplateComponent>
        <PDFLogo assetKey={logo?.assetKey} onLogoKeyChange={handleLogoKeyChange} />
        <CoverPageTitle onChange={onTitleChange} value={title} />
        <CoverPageDescription onChange={onDescriptionChange} value={description} />
        <PreparedByName name={ownerName} />
        <PreparedDate createdAt={createdAt} />
        <PoweredByLogo />
      </TemplateComponent>
    </Box>
  );
};

export default React.memo(CoverPage);
