import React, { useCallback, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import uniqueId from 'lodash/uniqueId';

import CustomPieChart from '../../../components/CustomComponents/Graphs/CustomPieChart';
import styles from '../styles/PieChart.styles';

// store
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import { PIECHARTCOLOR } from '../../../components/CustomComponents/Graphs/constant';

import store from '../../../store/HTADocuments';

import { HTA_REPORT_QUERIES } from '../const';

// eslint-disable-next-line react/prop-types
const PieChart = ({ label, data, id }) => {
  const { dispatch } = useContext(GlobalStore);
  const { HTAState } = useContext(store);

  const addToReport = useCallback(async () => {
    const addedReport = HTA_REPORT_QUERIES.find(report => report.id === id);
    const random = Math.floor(Math.random() * 1000);
    dispatch({
      type: GlobalActions.ADD_TEMPLATE_TO_REPORT,
      value: {
        templateType: 'custom',
        chartType: addedReport.graph,
        sectionType: 'CHART',
        id: uniqueId(`hta_${random}_`),
        style: {
          placement: {
            h: 40,
            w: 12
          },
          graphStyle: addedReport.graphStyle,
          title: addedReport.title
        },
        data: {
          source: 'hta',
          search_type: 'guidance',
          disableSelectionSource: true,
          filters: HTAState.availableFilters,
          query: addedReport.query
        }
      }
    });
    await dispatch({
      type: GlobalActions.SET_ALERT,
      value: { status: true, message: 'Successfully added to Report.', color: 'success' }
    });
  }, [dispatch, HTAState.issueDateBar, HTAState.availableFilters]);

  return (
    <Box sx={styles.container} display='flex' flexDirection='column'>
      {Object.keys(data).length !== 0 ? (
        <>
          <Box display='flex' justifyContent='space-between'>
            <Typography sx={styles.text}>{label}</Typography>
            <Button
              onClick={() => addToReport()}
              variant='text'
              fullWidth={false}
              sx={styles.addToReport}>
              <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                + Add to Report
              </Typography>
            </Button>
          </Box>
          <Box display='flex' flexDirection='column' justifyContent='' sx={styles.graphContainer}>
            <CustomPieChart
              data={data}
              innerRadius={0.7}
              colors={PIECHARTCOLOR}
              fillText='#707070'
              radialLabelsTextColor='#707070'
              sliceLabelsTextColor={{ from: 'color' }}
              margin={styles.pieChartMargin}
            />
          </Box>
        </>
      ) : (
        <Typography
          variant='subtitle2'
          alignItems='center'
          sx={{ fontSize: '20px', fontWeight: 'bold' }}>
          No data
        </Typography>
      )}
    </Box>
  );
};

export default PieChart;
