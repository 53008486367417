const styles = {
  description: {
    fontSize: 16,
    color: 'gray.main',
    fontWeight: 'bold'
  },
  avatarActive: {
    marginLeft: '8px',
    marginRight: '8px',
    height: '3.4vh',
    width: '3.4vh',
    fontSize: '1.5vh',
    backgroundColor: 'primary.avatar'
  },
  avatarInActive: {
    marginLeft: '8px',
    marginRight: '8px',
    height: '3.4vh',
    width: '3.4vh',
    fontSize: '1.5vh',
    backgroundColor: 'grey.lightText'
  }
};

export default styles;
