import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import React from 'react';

import styles from './styles';

declare type DatePickerComponent = (<TInputDate, TDate = TInputDate>(
  // eslint-disable-next-line no-unused-vars
  props: DatePickerProps<TInputDate, TDate> & React.RefAttributes<HTMLDivElement>
) => React.JSX.Element) & {
  propTypes?: any;
};

const CustomDatePicker: DatePickerComponent = (props: any) => {
  return (
    <DatePicker
      views={['year', 'month', 'day']}
      PaperProps={{
        sx: styles.paperProps
      }}
      DialogProps={{
        sx: styles.dialogProps
      }}
      componentsProps={{
        actionBar: {
          // The actions will be the same between desktop and mobile
          actions: ['cancel', 'accept']
        }
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default CustomDatePicker;
