// eslint-disable-next-line import/prefer-default-export
import DatagridTooltip from '../../components/Datagrid/DatagridTooltip';

export const EXTENDED_CARD_SOURCE_SECTIONS = {
  us: 'indications_and_usage',
  eu: 'therapeutic_indications',
  ca: 'indications_and_usage',
  'us-devices': 'Indications_and_usage',
  hma: 'Indications_and_usage',
  hpra: 'therapeutic_indications',
  jp: 'indications_and_usage'
};

export const SOURCE_NAME_MAPPING = {
  us: 'US',
  eu: 'EU',
  ca: 'CA',
  'us-devices': 'CDRH',
  hpra: 'HPRA',
  hma: 'HMA',
  jp: 'JP'
};

export const EXTENDED_CARD_POSOLOGY_SOURCE_SECTIONS = {
  us: 'dosage_and_administration',
  eu: 'posology_and_method_of_administration',
  hpra: 'posology_and_method_of_administration'
};

export const SOURCE_PRODUCT_TABS = {
  default: [
    {
      key: 'products',
      value: 'Products',
      size: 2
    },
    {
      key: 'reference_status',
      value: 'Reference Status',
      size: 3
    },
    {
      key: 'strength',
      value: 'Strength',
      size: 4
    },
    {
      key: 'marketing_status',
      value: 'Marketing Status',
      size: 3
    }
  ],
  ca: [
    {
      key: 'products',
      value: 'Products',
      size: 2
    },
    {
      key: 'strength',
      value: 'Strength',
      size: 4
    },
    {
      key: 'marketing_status',
      value: 'Marketing Status',
      size: 3
    },
    {
      key: 'first_market_date',
      value: 'First Market Date',
      size: 3
    }
  ],
  jp: [
    {
      key: 'yj_code',
      value: 'Products',
      size: 2
    },
    {
      key: 'trade_name',
      value: 'Trade Name',
      size: 5
    },
    // {
    //   key: 'price',
    //   value: 'Price (in ¥)',
    //   size: 4,
    //   default: 'Unknown'
    // },
    {
      key: 'sales_date',
      value: 'First Market Date',
      size: 3,
      default: 'Unknown'
    }
  ],
  hma: [
    {
      key: 'products',
      value: 'Products',
      size: 2
    },
    {
      key: 'formulation',
      value: 'Formulation',
      size: 4
    },
    {
      key: 'marketing_status',
      value: 'Marketing Status',
      size: 3
    },
    {
      key: 'first_market_date',
      value: 'First Market Date',
      size: 3
    }
  ],
  hpra: [
    {
      key: 'products',
      value: 'Products',
      size: 2
    },
    {
      key: 'formulation',
      value: 'Formulation',
      size: 4
    },
    {
      key: 'marketing_status',
      value: 'Marketing Status',
      size: 3
    },
    {
      key: 'first_market_date',
      value: 'First Market Date',
      size: 3
    }
  ]
};

export const GENERICS_COLUMN_LIST: any = {
  default: [
    {
      field: 'activeIngredients',
      headerName: 'Active ingredients',
      width: 310,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'tradeName',
      headerName: 'Trade name',
      width: 270,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'anda',
      headerName: 'Application number',
      width: 320,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'approvalPathway',
      headerName: 'Approval pathway',
      width: 310,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'firstApprovalDate',
      headerName: 'First approval Date',
      width: 320,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'formulation',
      headerName: 'Formulation',
      width: 250,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'route',
      headerName: 'Route',
      width: 180,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'Strength',
      headerName: 'Strength',
      width: 350,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'marketingStatus',
      headerName: 'Marketing status',
      width: 290,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'sponsorName',
      headerName: 'Sponsor name',
      width: 270,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'rld',
      headerName: 'RLD',
      width: 170,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'rs',
      headerName: 'RS',
      width: 150,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'te_code',
      headerName: 'TE code',
      width: 200,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    }
  ],
  jp: [
    {
      field: 'activeIngredients',
      headerName: 'Active ingredients',
      width: 310,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'tradeName',
      headerName: 'Trade name',
      width: 270,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'anda',
      headerName: 'Application number',
      width: 320,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'firstApprovalDate',
      headerName: 'First approval Date',
      width: 320,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'formulation',
      headerName: 'Formulation',
      width: 250,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'route',
      headerName: 'Route',
      width: 180,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'sponsorName',
      headerName: 'Sponsor name',
      width: 270,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    },
    {
      field: 'price',
      headerName: 'Price (in ¥)',
      width: 150,
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: DatagridTooltip
    }
  ]
};
