import React, { useCallback, useContext, useEffect, useState } from 'react';

// mui v5
import { Box, Popover } from '@mui/material';

// componentns
import HomePage from './HomePage';
import Meetings from './Meetings';
import AskRIAButton from '../../../components/Buttons/AskRiaButtonAdcomm';
import AriaDrawerCore from '../../SearchResults/components/AriaDrawerCore';
import { saveAriaCache } from '../../Aria/utils/ariaCache';
import ReportWidgets from '../../../components/Report/Widgets';
import BottomDrawer from '../../../components/CustomComponents/BottomDrawer';
import SubHeader from '../../../components/SubHeader';
import NewFilters from '../../../components/NewFilters';

// hooks
import useChmpData from '../hooks/useChmpData';

// Store
import GlobalStore from '../../../store';
import { chmpFiltersMapping } from '../utils/constants';
import { Stat } from '../../SearchResults/types';
import AskRiaButtonStyles from '../../../components/Buttons/styles/AskRiaButton.styles';
import useAriaShareHandler from '../../../components/AriaShare/useAriaShareHandler';

const ChmpTabs = () => {
  const { dispatch } = useContext(GlobalStore) as any;
  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] =
    React.useState<HTMLElement | null>(null);
  const [ariaModalOpen, setAriaModalOpen] = React.useState(false);
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [chmpStat, setChmpStat] = useState<Stat[]>([]);
  const {
    getChmpMeetings,
    meetings,
    chmpStats,
    isFilterApplied,
    allMeetingsByYear,
    selectedMeeting,
    chmpFilters,
    handleSelectMeeting,
    loading,
    selectedResources,
    meetingStats
  } = useChmpData();

  const [ariaOption, setAriaOption] = useState<string>(
    selectedMeeting?.resources?.length > 0 ? 'current' : 'all'
  );
  const handleAriaNavigate = (ariaModalOpenValue = true) => {
    const meetingIds: string[] = Object.values(allMeetingsByYear)
      .flatMap(values => values)
      .filter((obj: any) => 'meeting_id' in obj)
      .map((obj: any) => obj.meeting_id);

    saveAriaCache(
      [],
      `/chmp/insights`,
      'CHMP',
      isFilterApplied
        ? {
            'group-id': meetingIds
          }
        : {}
    );
    setAriaModalOpen(ariaModalOpenValue);
  };

  useEffect(() => {
    getChmpMeetings();
  }, []);
  useEffect(() => {
    setAriaOption(selectedMeeting?.resources?.length > 0 ? 'current' : 'all');
  }, [selectedMeeting?.meeting_id]);

  useAriaShareHandler(loading, ariaModalOpen, setAriaModalOpen);

  const handleFilterApply = useCallback((currentfilters: any) => {
    getChmpMeetings(currentfilters);
    setAppliedFilters(currentfilters);
  }, []);

  const getFiltersLabelList = () => {
    return chmpFiltersMapping.reduce((acc: any, elem: any) => {
      acc[elem.id] = elem.label;
      return acc;
    }, {});
  };

  const getDocumentStats = (choice: string): any => {
    if (choice === 'document_count') {
      if (ariaOption === 'current') {
        return selectedMeeting?.resources?.length || 0;
      }
      return chmpStats?.total_documents;
    }
    if (choice === 'page_count') {
      if (ariaOption === 'current') {
        return selectedMeeting?.document_total_page_count || 0;
      }
      return chmpStats?.total_pages;
    }
    return '';
  };

  const getAriaDocuments = () => {
    if (ariaOption === 'current') {
      return [{ identifier: selectedMeeting?.meeting_id }];
    }
    if (!isFilterApplied) {
      return [];
    }
    const ariaDocuments = meetings?.map((item: any) => ({
      identifier: item.meeting_id
    }));
    return ariaDocuments || [];
  };
  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  useEffect(() => {
    if (chmpStats) {
      const newStats: Stat[] = [];
      newStats.push({
        title: 'Total Meetings',
        value: chmpStats?.total_meetings || 0
      });
      newStats.push({
        title: 'Documents',
        value: chmpStats?.total_documents || 0
      });
      newStats.push({
        title: 'Pages',
        value: chmpStats?.total_pages || 0
      });

      setChmpStat(newStats);
    }
  }, [chmpFilters]);

  return (
    <>
      <SubHeader
        title='CHMP'
        subTitle='Showing Meeting Results For Committee For Medicinal Products For Human Use'
        stats={chmpStat}
        handleVisualizeOpen={handleVisualizeOpen}
      />

      {!loading && (
        <Popover
          open={!!reportChoicesOpenAnchorElement}
          anchorEl={reportChoicesOpenAnchorElement}
          onClose={() => setReportChoiceAnchorElement(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          sx={{
            '& .MuiPopover-paper': {
              borderRadius: '16px !important',
              backgroundColor: 'none'
            }
          }}>
          <Box>
            <ReportWidgets
              widgets={['pie-widget', 'bar-widget', 'table-widget']}
              title=''
              dataSource={{
                source: 'chmp',
                moduleName: 'chmp',
                disableSelectionSource: true,
                filters: appliedFilters,
                filterLabelList: getFiltersLabelList()
              }}
              pathMetaData={{
                module: 'chmp',
                href: window.location.href
              }}
              setReportWidgetClose={setReportChoiceAnchorElement}
            />
          </Box>
        </Popover>
      )}

      <Meetings
        allMeetingsByYear={allMeetingsByYear && allMeetingsByYear}
        // allMeetingsByYear={allMeetingsByYear}
        selectedMeeting={selectedMeeting}
        handleSelectMeeting={handleSelectMeeting}
        resources={selectedResources}
        meetingStats={meetingStats}
        loading={loading}
      />

      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}>
        <HomePage stats={chmpStats} loading={loading} appliedFilters={appliedFilters} />
      </BottomDrawer>

      {ariaModalOpen && (
        <AriaDrawerCore
          applications={getAriaDocuments()}
          sources={{ eu: ['chmp'] }}
          groupId='entity_id'
          docCount={getDocumentStats('document_count')}
          docPageCount={getDocumentStats('page_count')}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          dispatch={dispatch}
        />
      )}
      <NewFilters data={chmpFilters} applyFilters={handleFilterApply} isLoading={loading} />

      <Box sx={AskRiaButtonStyles.bottomFilterPosition}>
        <AskRIAButton
          setOpenAriaSearch={handleAriaNavigate}
          ariaOption={ariaOption}
          setAriaOption={setAriaOption}
          disabled={meetings.length === 0}
          showCurrentMeeting={selectedMeeting?.resources?.length > 0}
          currentMeeting={getDocumentStats('document_count')}
        />
      </Box>
    </>
  );
};

export default ChmpTabs;
