const styles = {
  container: {
    heigth: '100%',
    marginTop: 1,
    marginLeft: 1,
    padding: 1,
    width: '33%',
    backgroundColor: 'white.main',
    borderRadius: '10px'
  },
  graphContainer: {
    width: '100%',
    height: '25vh'
  },
  text: {
    color: 'gray.lightVariant',
    fontSize: '16px',
    paddingTop: '0.5rem',
    fontWeight: 700
  },
  reportText: {
    color: 'primary',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'none'
    }
  },
  addToReport: {
    height: '35px',
    cursor: 'pointer',
    background: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      border: 1,
      borderColor: 'primary.main',
      borderStyle: 'solid'
    }
  },
  pieChartMargin: { top: 20, right: 100, bottom: 50, left: 127 }
};

export default styles;
