import React, { createContext, useReducer } from 'react';
import reducers from './reducers';

type Props = {
  children: React.ReactNode;
};

export interface userProfileInterface {
  loading: boolean;
  listData: any[];
  dateGroupedUserActivity: {};
  projects: any[];
  projectName: any;
  refetch: boolean;
  projectUpdatedTrigger: boolean;
  projectDescription: any;
  timestampUserActivity: any;
  paginateOnScroll: boolean;
  activityData: any[];
}
const initialState = {
  loading: false,
  listData: [],
  dateGroupedUserActivity: {},
  projects: [],
  projectName: {},
  refetch: false,
  projectUpdatedTrigger: false,
  projectDescription: {},
  timestampUserActivity: new Date().toISOString(),
  paginateOnScroll: true,
  activityData: []
};

const Store = createContext<{
  profileState: userProfileInterface;
  profileDispatch: React.Dispatch<any>;
}>({
  profileState: initialState,
  profileDispatch: () => null
});
const { Provider } = Store;

export const ProfileStateProvider = ({ children }: Props) => {
  const [profileState, profileDispatch] = useReducer(reducers, initialState);

  return <Provider value={{ profileState, profileDispatch }}>{children}</Provider>;
};

export default Store;
