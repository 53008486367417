import React, { useContext, useEffect, useRef } from 'react';
import uniqueId from 'lodash/uniqueId';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@mui/material/Button';
import Plot from 'react-plotly.js';

// store
import Skeleton from '@material-ui/lab/Skeleton';
import ResultsStore from '../../../store/SearchResults';
import CustomBarGraph from '../../CustomComponents/Graphs/CustomBarGraph';
import useBusinessIntelligenceAdverseEventsData from '../../ClinicalTrials/TimelineCT/hooks/useBusinessIntelligenceAdverseEventsData';
import ClinicalTrialsStyles from '../../ClinicalTrials/Graphs/Graphs.styles';

// context
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

import { EUCT_REPORT_QUERIES } from './utils';

const EUCTBusinessIntellegence = () => {
  const classes = ClinicalTrialsStyles();
  const { dispatch }: any = useContext(GlobalStore);
  const { resultsState } = useContext(ResultsStore);
  const { fetchEUCTBusinessSearchResultsFromId } = useBusinessIntelligenceAdverseEventsData();

  const isSearchIdChangedRef = useRef(true);

  useEffect(() => {
    isSearchIdChangedRef.current = true;
  }, [resultsState?.searchIds?.ctSearchId]);

  useEffect(() => {
    if (isSearchIdChangedRef.current) {
      fetchEUCTBusinessSearchResultsFromId(resultsState?.searchIds?.ctSearchId);
      isSearchIdChangedRef.current = false;
    }
  }, []);

  const addToReport = React.useCallback(
    async (key: string) => {
      const addedReport: any = EUCT_REPORT_QUERIES.find(report => report.id === key);
      const random = Math.floor(Math.random() * 1000);
      dispatch({
        type: GlobalActions.ADD_TEMPLATE_TO_REPORT,
        value: {
          templateType: 'custom',
          chartType: addedReport.graph,
          sectionType: 'CHART',
          id: uniqueId(`euctr_ria_graphs_${random}_`),
          style: {
            placement: {
              h: 40,
              w: 12
            },
            graphStyle: addedReport.graphStyle,
            title: addedReport.title
          },
          data: {
            source: 'euctr',
            search_type: 'quicksearch',
            search_id: resultsState.searchIds.ctSearchId,
            query: addedReport.query
          }
        }
      });
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Successfully added to Report.', color: 'success' }
      });
    },
    [dispatch, resultsState.searchIds.ctSearchId]
  );

  const formatdata = (data: any[]) => {
    const categories = data.map(item => item.nctId || item.euct_id);
    const keys = data.reduce((ele, item) => {
      Object.keys(item).forEach(key => {
        if (key !== 'euct_id' && !ele.includes(key) && item[key] !== 0) {
          ele.push(key);
        }
      });
      return ele;
    }, []);

    const traces = keys.map((key: string | number) => ({
      x: categories,
      y: data.map(item => item[key] || 0),
      name: key,
      type: 'bar'
    }));
    return traces;
  };
  return (
    <Grid container justifyContent='space-between' className={classes.root}>
      <Grid
        item
        container
        spacing={5}
        style={{ marginLeft: 0, marginRight: 0, marginTop: 'unset' }}>
        <Grid item xs={6} lg={6} style={{ padding: 18 }}>
          {resultsState.ctBusinessIntelligenceLoading || resultsState.loading ? (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-end'
              alignItems='space-between'
              className={classes.loaderBox}>
              <Box
                display='flex'
                justifyContent='space-between'
                flexWrap='wrap-reverse'
                className={classes.barGraphRect}>
                <Skeleton variant='rect' height='90%' width='.2%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
                <Skeleton variant='rect' height='30%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
                <Skeleton variant='rect' height='30%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='90%' width='3%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
              </Box>
              <Skeleton variant='rect' height='.2%' width='100%' />
            </Box>
          ) : (
            <Box className={classes.container}>
              <Box display='flex' justifyContent='space-between'>
                <Typography className={classes.heading}>
                  Number of Trials by Year of Completion
                </Typography>
                <Button
                  onClick={() => {
                    addToReport('study_completion_year');
                  }}
                  variant='text'
                  fullWidth={false}
                  className={classes.add}>
                  <Typography align='right' className={classes.addText}>
                    + Add to Report
                  </Typography>
                </Button>
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                className={classes.graph}>
                {resultsState.ctBussinessIntelligence &&
                resultsState.ctBussinessIntelligence.year_graph?.length > 0 ? (
                  <CustomBarGraph
                    keys={['data']}
                    index='name'
                    colors='#B1BBE4'
                    data={resultsState.ctBussinessIntelligence.year_graph}
                    paddings={0.7}
                    // @ts-ignore
                    margins={{
                      top: 20,
                      right: 20,
                      bottom: 40,
                      left: 30
                    }}
                    // @ts-ignore
                    tickRotationValue={90}
                    // @ts-ignore
                    borderRadius={4}
                    // @ts-ignore
                    labelTextColor='#ffffff'
                    // @ts-ignore
                    axisText='#A3A3A3'
                  />
                ) : (
                  'No Data'
                )}
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={6} lg={6} style={{ padding: 18 }}>
          {resultsState.ctBusinessIntelligenceLoading || resultsState.loading ? (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-end'
              alignItems='space-between'
              className={classes.loaderBox}>
              <Box
                display='flex'
                justifyContent='space-between'
                flexWrap='wrap-reverse'
                className={classes.barGraphRect}>
                <Skeleton variant='rect' height='90%' width='.2%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
                <Skeleton variant='rect' height='30%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
              </Box>
              <Skeleton variant='rect' height='.2%' width='100%' />
            </Box>
          ) : (
            <Box className={classes.container}>
              <Box display='flex' justifyContent='space-between'>
                <Typography className={classes.heading}>Number of Trials by Phase</Typography>
                <Button
                  onClick={() => {
                    addToReport('phase');
                  }}
                  variant='text'
                  fullWidth={false}
                  className={classes.add}>
                  <Typography align='right' className={classes.addText}>
                    + Add to Report
                  </Typography>
                </Button>
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                className={classes.graph}>
                {resultsState.ctBussinessIntelligence &&
                resultsState.ctBussinessIntelligence.phase_graph?.length > 0 ? (
                  <CustomBarGraph
                    keys={['data']}
                    index='name'
                    colors='#B1BBE4'
                    data={resultsState.ctBussinessIntelligence.phase_graph}
                    paddings={0.7}
                    // @ts-ignore
                    margins={{
                      top: 20,
                      right: 20,
                      bottom: 40,
                      left: 100
                    }}
                    // @ts-ignore
                    tickRotationValue={90}
                    // @ts-ignore
                    borderRadius={4}
                    // @ts-ignore
                    labelTextColor='#ffffff'
                    // @ts-ignore
                    axisText='#A3A3A3'
                    // @ts-ignore
                    layout='horizontal'
                  />
                ) : (
                  'No Data'
                )}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid item container spacing={5} style={{ marginLeft: 0, marginRight: 0, marginBottom: 0 }}>
        <Grid item xs={6} lg={6} style={{ padding: 18 }}>
          {resultsState.ctBusinessIntelligenceLoading || resultsState.loading ? (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-end'
              alignItems='space-between'
              className={classes.loaderBox}>
              <Box
                display='flex'
                justifyContent='space-between'
                flexWrap='wrap-reverse'
                className={classes.barGraphRect}>
                <Skeleton variant='rect' height='90%' width='.2%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
                <Skeleton variant='rect' height='30%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
              </Box>
              <Skeleton variant='rect' height='.2%' width='100%' />
            </Box>
          ) : (
            <Box className={classes.container}>
              <Box display='flex' justifyContent='space-between'>
                <Typography className={classes.heading}>Number of Patients by Trial</Typography>
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                mb={1}
                className={classes.graph}>
                {resultsState.ctBussinessIntelligence &&
                resultsState.ctBussinessIntelligence.enrollment_by_trial?.length > 0 ? (
                  <Plot
                    data={formatdata(resultsState.ctBussinessIntelligence.enrollment_by_trial)}
                    layout={{
                      barmode: 'stack',
                      autosize: true,
                      margin: { t: 32, r: 140 },
                      showlegend: false,
                      xaxis: {
                        tickangle: 90,
                        showticklabels: false
                      },
                      orientation: 90,
                      dragmode: 'pan'
                    }}
                    style={{ width: '100%', height: '100%' }}
                    useResizeHandler
                    config={{
                      toImageButtonOptions: {
                        filename: `number_of_patients_by_trial`
                      },
                      scrollZoom: true
                    }}
                  />
                ) : (
                  'No Data'
                )}
              </Box>
            </Box>
          )}
        </Grid>
        {/* <Grid item xs={6} lg={6} style={{ padding: 18 }}>
          {resultsState.ctBusinessIntelligenceLoading || resultsState.loading ? (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-end'
              alignItems='space-between'
              className={classes.loaderBox}>
              <Box
                display='flex'
                justifyContent='space-between'
                flexWrap='wrap-reverse'
                className={classes.barGraphRect}>
                <Skeleton variant='rect' height='90%' width='.2%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
                <Skeleton variant='rect' height='30%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
                <Skeleton variant='rect' height='30%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='90%' width='3%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
              </Box>
              <Skeleton variant='rect' height='.2%' width='100%' />
            </Box>
          ) : (
            <Box className={classes.container}>
              <Box display='flex' justifyContent='space-between'>
                <Typography className={classes.heading}>
                  Number of Trials by Gender
                </Typography>
                <Button
                  onClick={() => {
                    addToReport('gender');
                  }}
                  variant='text'
                  fullWidth={false}
                  className={classes.add}>
                  <Typography align='right' fontSize='16' className={classes.addText}>
                    + Add to Report
                  </Typography>
                </Button>
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                className={classes.graph}>
                {resultsState.ctBussinessIntelligence.euct.genderGraph?.length > 0 ? (
                  <CustomBarGraph
                    keys={['data']}
                    index='name'
                    colors='#B1BBE4'
                    data={statusGraph}
                    paddings={0.7}
                    className={classes.bar}
                    margins={{
                      top: 20,
                      right: 20,
                      bottom: 40,
                      left: 120
                    }}
                    tickRotationValue={90}
                    borderRadius={4}
                    labelTextColor='#ffffff'
                    axisText='#A3A3A3'
                    layout='horizontal'
                  />
                ) : (
                  'No Data'
                )}
              </Box>
            </Box>
          )}
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default React.memo(EUCTBusinessIntellegence);
