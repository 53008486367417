import Box from '@mui/material/Box';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Responsive, WidthProvider } from 'react-grid-layout';
import debounce from 'lodash/debounce';
import { isEqual } from 'lodash';
import { Auth } from 'aws-amplify';

import AppStore from '../../store';
import { getGraphData, getGraphDataByConfig } from '../../api/modules/Reports';
import { getReport } from '../../api/pages/UserProfile';
import RadarGraph from '../CustomComponents/Graphs/RadarGraph';
import LineChart from '../CustomComponents/Graphs/LineChart';
import BarChart from '../CustomComponents/Graphs/BarChart';
import PieChart from '../CustomComponents/Graphs/PieChart';
import TableView from './TableView';
import actions from '../../store/actions';
import TextEditor from './TextEditor';
import AdministrativeSummary from './snapshot/AdministrativeSummary';
import SubmissionSummary from './snapshot/SubmissionSummary';
import ClinicalSummary from './snapshot/ClinicalSummary';

import themev5 from '../../themev5';

import 'react-grid-layout/css/styles.css';
import CDPReport from '../../pages/CDP/components/CDPReport';

import SpecialityDesignationReport from '../../pages/SpecialityDesignation/components/SpecialityDesignationReport';

import CTTimelineGanttReport from '../ClinicalTrials/TimelineCT/CTTimelineGanttReport';
import CTTimelineChangeReport from '../ClinicalTrials/TimelineCT/CTTimelineChangeReport';
import useOutsideHandler from './hooks/useOutsideClickHandler';
import MoveIcon from '../../assets/svgs/Report/MoveIcon';
import Tools from './components/Tools';
import useUndoRedo from './hooks/useUndoRedo';
import CoverPage from './components/CoverPage';
import CoverPageSelectionDialog from './components/CoverPageSelectionDialog';

import StyleDialogBox from './StyleDialogBox';
import ClearDialog from './components/ClearDialog';
import ReferenceText from './components/ReferenceText';
import ReportExistDialog from './components/ReportExistDialog';
import TimelineCircularAvatar from '../Timeline/TimelineAvatars/TimelineCircularAvatar';
import APPROVAL_STATUS_TAGS from '../Timeline/TimelineAvatars/constants';
import snapshotStyles from './snapshot/styles';
import ReportLoader from './components/ReportLoader';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const styles = {
  reportContainer: {
    backgroundColor: '#F7F7F7 !important',
    '@media print': {
      '.no-print, .no-print *': {
        display: 'none !important'
      },
      '&': {
        height: 'auto'
      },
      '.react-grid-item': {
        position: 'unset !important',
        transform: 'unset !important',
        margin: '0px',
        display: 'block',
        pageBreakInside: 'avoid',
        pageBreakBefore: 'avoid',
        pageBreakAfter: 'avoid',
        '* div': {
          'page-break-inside': 'avoid',
          'break-inside': 'avoid'
        },
        p: {
          pageBreakInside: 'auto',
          breakInside: 'auto'
        }
      },
      '.react-grid-layout': {
        height: 'unset !important',
        display: 'table'
      }
    }
  },
  main: {
    // margin: 'auto',
    position: 'relative',
    '@media print': {
      '.no-print, .no-print *': {
        display: 'none !important'
      }
    },
    '& .layout': {
      pageBreakInside: 'avoid',
      '& .react-resizable-handle': {
        opacity: 0,
        zIndex: 1
      },
      '& .react-resizable-handle-s': {
        transform: 'rotate(0deg) !important',
        '&::after': {
          borderRight: '0',
          width: '9px !important'
        }
      },
      '& .react-resizable-handle-w': {
        transform: 'rotate(0deg) !important',
        '&::after': {
          borderBottom: '0 !important',
          borderRight: '0 !important',
          borderLeft: '3px solid #2D95E0',
          height: '9px !important',
          right: '12px !important'
        }
      },
      '& .react-resizable-handle-e': {
        transform: 'rotate(0deg) !important',
        '&::after': {
          borderBottom: '0 !important',
          height: '9px !important'
        }
      },
      '& .react-resizable-handle-n': {
        transform: 'rotate(0deg) !important',
        '&::after': {
          borderRight: '0',
          width: '9px !important',
          borderBottom: '0',
          borderTop: '3px solid #2D95E0',
          top: '-2px'
        }
      },
      '& .react-grid-item:hover': {
        border: '2px dotted #2D95E0',
        '& .react-resizable-handle': {
          opacity: 1,
          '&::after': {
            width: '7px',
            height: '7px',
            borderRightWidth: '3px',
            borderBottomWidth: '3px',
            borderColor: '#2D95E0',
            right: '-2px',
            bottom: '-2px'
          }
        }
      }
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '-ms-overflow-style': 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */
  },
  pdfLayout: {
    // margin: 'auto',
    // display: 'inline'
    mb: 5,
    width: '210mm',
    position: 'relative',
    margin: 'auto',
    pageBreakAfter: 'always' /* Ensure each page starts on a new page when printing */,
    backgroundColor: '#ffffff'
  },
  pdfPage: {
    pageBreakAfter: 'always' /* Ensure each page starts on a new page when printing */,
    // padding: '20px' /* Add padding around the content within each page */,
    backgroundColor: '#ffffff' /* Set a background color for the page */,
    // boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)',
    minHeight: '297mm',
    '-webkit-print-color-adjust': 'exact !important' /* Chrome, Safari 6 – 15.3, Edge */,
    colorAdjust: 'exact !important' /* Firefox 48 – 96 */,
    printColorAdjust: 'exact !important' /* Firefox 97+, Safari 15.4+ */
  },
  dragActiveElement: {
    border: '2px dotted #2D95E0',
    cursor: 'move',
    '& .moveIcon': {
      display: 'inline-block !important'
    },
    '& .moreButton': {
      display: 'inline-block !important'
    }
  }
};

const GRAPH_COMPONENTS: any = {
  RADAR: RadarGraph,
  LINE: LineChart,
  BAR: BarChart,
  PIE: PieChart,
  TABLE: TableView,
  CDP_TIMELINE: CDPReport,
  EXPEDITED_TIMELINE: SpecialityDesignationReport,
  CT_TIMELINE_GANTT: CTTimelineGanttReport,
  CT_TIMELINE_CHANGE: CTTimelineChangeReport
};
const TEXT_COMPONENTS: any = {
  HTML_TEXT: TextEditor
};

const SNAPSHOT_COMPONENTS: any = {
  SUBMISSION_SUMMARY: SubmissionSummary,
  ADMINISTRATIVE_SUMMARY: AdministrativeSummary,
  CLINICAL_SUMMARY: ClinicalSummary
};

const cols: any = { lg: 12 };
const margin: [number, number] = [0, 0];
const resizeHandles: any = ['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne'];

const PDFTextSection = React.memo(
  ({
    style,
    sectionId,
    textType,
    onUpdatePlacement,
    onTextChange,
    textContent,
    onEditStateChange,
    onDelete,
    onDuplicate
  }: any) => {
    const [editOpen, setEditOpen] = useState(false);
    const wrapperRef = useRef(null);
    const editStateRef = useRef(false);
    const [sectionMenuEl, setSectionMenuEl] = useState(null);

    const handleChangeText = useCallback(
      (newContent: any) => {
        onTextChange(sectionId, newContent);
      },
      [onUpdatePlacement, onTextChange, sectionId]
    );

    const handleOutSideElementClick = useCallback(() => {
      if (editStateRef.current) {
        // Why ref? because this code run in use effect of `useOutsideHandler` hook.
        setEditOpen(false);
        onEditStateChange(false);
        editStateRef.current = false;
      }
    }, [onEditStateChange]);

    useOutsideHandler(wrapperRef, handleOutSideElementClick);

    const TextEditorComponent = TEXT_COMPONENTS[textType];

    const updateSectionPlacement = (height: number) => {
      if (style?.references?.show) {
        // eslint-disable-next-line no-param-reassign
        height += 1;
      }
      onUpdatePlacement(sectionId, { h: height + 2 });
    };

    const handleTextEdit = useCallback(() => {
      setEditOpen(true);
      onEditStateChange(true);
      editStateRef.current = true;
    }, [onEditStateChange]);

    const handleDelete = useCallback(() => {
      setSectionMenuEl(null);
      onDelete();
    }, [onDelete]);

    const handleDuplicate = useCallback(() => {
      setSectionMenuEl(null);
      onDuplicate();
    }, [onDuplicate]);

    return (
      <Box
        width='100%'
        height='100%'
        ref={wrapperRef}
        className='section-container'
        position='relative'
        style={{ boxSizing: 'border-box', cursor: editOpen ? 'text' : 'move' }}
        id={`pdf-chart-section-${sectionId}`}
        onDoubleClick={() => handleTextEdit()}>
        <Box
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: 100,
            display: 'none'
          }}
          className='moreButton'>
          <Box
            display='inline-block'
            sx={{ cursor: 'pointer' }}
            onClick={(event: any) => setSectionMenuEl(event.currentTarget)}>
            <MoreVertIcon sx={{ color: '#2D95E0' }} />
          </Box>
          <Menu
            anchorEl={sectionMenuEl}
            onClose={() => setSectionMenuEl(null)}
            open={!!sectionMenuEl}>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
            <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
          </Menu>
        </Box>
        {TextEditorComponent && (
          <Box position='relative' height='100%'>
            <TextEditorComponent
              content={textContent}
              onChange={handleChangeText}
              toolbarOpen={editOpen}
              sectionId={sectionId}
              disabled={!editOpen}
              updateSectionPlacement={updateSectionPlacement}
              isReferences={style.references?.show}
            />
          </Box>
        )}
        {style.references?.show && <ReferenceText referenceText={style.references} />}
      </Box>
    );
  }
);

const Spacer = ({ id, onDelete }: any) => {
  const [sectionMenuEl, setSectionMenuEl] = useState(null);

  const handleDelete = useCallback(() => {
    setSectionMenuEl(null);
    onDelete();
  }, [onDelete]);

  return (
    <Box
      width='100%'
      height='100%'
      style={{ boxSizing: 'border-box' }}
      className='section-container'
      id={`pdf-spacer-section-${id}`}>
      <Box
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: 100,
          display: 'none'
        }}
        className='moreButton'>
        <Box
          display='inline-block'
          sx={{ cursor: 'pointer' }}
          onClick={(event: any) => setSectionMenuEl(event.currentTarget)}>
          <MoreVertIcon sx={{ color: '#2D95E0' }} />
        </Box>
        <Menu
          anchorEl={sectionMenuEl}
          onClose={() => setSectionMenuEl(null)}
          open={!!sectionMenuEl}>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

const Snapshot = ({ id, title, detail, onDelete, onDuplicate, onUpdatePlacement }: any) => {
  const [data, setData] = useState([]);
  const [sectionMenuEl, setSectionMenuEl] = useState(null);

  const fetchAdministrativeSummary = async (payload: any) => {
    try {
      const res = await getGraphDataByConfig('snapshot', {
        application_number: payload?.number,
        source: payload?.source,
        filter: {},
        query: {
          data_type: 'administrative_summary',
          submission_type: payload?.submission_type,
          submission_num: payload?.submission_num
        }
      });

      if (res?.data?.Success?.data) {
        const formattedData = res.data.Success.data.map((each: any) => {
          if (each.label === 'Approval Tracks') {
            return {
              label: each.label,
              value: (
                <TimelineCircularAvatar
                  data={each.value}
                  avatarList={APPROVAL_STATUS_TAGS.approval_history}
                />
              )
            };
          }
          if (each.label === 'Approval Time') {
            return {
              label: each.label,
              value: (
                <Stack direction='row' spacing={1} alignItems='center'>
                  <AccessTimeIcon sx={{ fontSize: 14 }} />
                  <Typography variant='subtitle2' sx={snapshotStyles.layout.approvalTimeText}>
                    {each.value}
                  </Typography>
                </Stack>
              )
            };
          }
          return each;
        });
        setData(formattedData);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error while fetching administrative summary data', error);
    }
  };

  const fetchSubmissionSummary = async (payload: any) => {
    try {
      const res = await getGraphDataByConfig('snapshot', {
        application_number: payload?.number,
        source: payload?.source,
        filter: {},
        query: {
          data_type: 'submission_summary',
          submission_type: payload?.submission_type,
          submission_num: payload?.submission_num
        }
      });
      setData(res?.data?.Success?.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error while fetching submission summary data', error);
    }
  };

  const fetchClinicalSummary = async (payload: any) => {
    try {
      const res = await getGraphDataByConfig('snapshot', {
        application_number: payload?.number,
        source: payload?.source,
        filter: {},
        query: {
          data_type: 'clinical_summary',
          submission_type: payload?.submission_type,
          submission_num: payload?.submission_num,
          indication_studies: payload?.indicationStudies
        }
      });
      setData(res?.data?.Success?.data);
    } catch (error) {
      console.error('Error while fetching clinical summary data', error);
    }
  };

  useEffect(() => {
    if (detail?.summaryType === 'SUBMISSION_SUMMARY') {
      fetchSubmissionSummary(detail);
    } else if (detail?.summaryType === 'ADMINISTRATIVE_SUMMARY') {
      fetchAdministrativeSummary(detail);
    } else if (detail?.summaryType === 'CLINICAL_SUMMARY') {
      fetchClinicalSummary(detail);
    }
  }, [detail?.summaryType]);

  const handleDelete = useCallback(() => {
    setSectionMenuEl(null);
    onDelete();
  }, [onDelete]);

  const handleDuplicate = useCallback(() => {
    setSectionMenuEl(null);
    onDuplicate();
  }, [onDuplicate]);

  const updateSectionPlacement = (height: number) => {
    onUpdatePlacement?.(id, { h: height });
  };

  const SnapshotSummary = SNAPSHOT_COMPONENTS[detail?.summaryType];

  return (
    <Box
      width='100%'
      height='100%'
      style={{ boxSizing: 'border-box' }}
      className='section-container'
      id={`pdf-card-section-${id}`}>
      <Box sx={snapshotStyles.layout.main} className='moreButton'>
        <Box
          display='inline-block'
          sx={{ cursor: 'pointer' }}
          onClick={(event: any) => setSectionMenuEl(event.currentTarget)}>
          <MoreVertIcon sx={{ color: '#2D95E0' }} />
        </Box>
      </Box>
      <Menu anchorEl={sectionMenuEl} onClose={() => setSectionMenuEl(null)} open={!!sectionMenuEl}>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
        <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
      </Menu>
      {(Array.isArray(data) ? data?.length > 0 : Object.keys(data)?.length > 0) &&
        SnapshotSummary && (
          <SnapshotSummary data={data} title={title} onUpdatePlacement={updateSectionPlacement} />
        )}
    </Box>
  );
};

const PDFChartSection = ({
  id,
  templateType,
  chartType,
  templateId,
  style,
  data,
  onDuplicate,
  onDelete,
  onUpdatePlacement,
  onStyleUpdate
}: any) => {
  const [graphsData, setGraphsData] = useState<any>();
  const [sectionMenuEl, setSectionMenuEl] = useState(null);
  const [styleFilter, setStyleFilters] = useState<any>({});
  const [appliedstyleFilter, setAppliedStyleFilters] = useState<any>({});
  const [openStyle, setOpenStyle] = useState<boolean>(false);

  const callLandingApi = useCallback(async () => {
    try {
      const response = await getGraphData(templateId);
      if (
        response &&
        response.data.Success !== '' &&
        Object.keys(response.data.Success.Data).length !== 0
      ) {
        setGraphsData(response.data.Success);
        (window as any).reportLoaded = true;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [templateId]);

  const getGraphDataBySourceConfig = async (config: any, graphType: string) => {
    try {
      if (graphType.includes('ct_timeline')) {
        return;
      }
      const payload = {
        ...config
      };
      const response = await getGraphDataByConfig(graphType, payload);
      if (
        response &&
        response?.data?.Success !== '' &&
        Object.keys(response.data.Success.data).length !== 0
      ) {
        setGraphsData(response.data.Success.data);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    // if id in url then call /user_profile/reports/id
    if (templateType === 'custom') {
      getGraphDataBySourceConfig(data, chartType?.toLowerCase());
    } else {
      callLandingApi();
    }
  }, []);

  useEffect(() => {
    setStyleFilters(style.graphStyle);
    setAppliedStyleFilters(style.graphStyle);
  }, [style.graphStyle, style]);

  const handleGraphStyleChange = () => {
    setSectionMenuEl(null);
    if (
      !['CDP_TIMELINE', 'CT_TIMELINE_GANTT', 'EXPEDITED_TIMELINE', 'CT_TIMELINE_CHANGE'].includes(
        chartType
      ) &&
      templateType === 'custom'
    ) {
      setOpenStyle(true);
    }
  };

  const handleClose = () => {
    setOpenStyle(false);
  };

  const handleStyleChange = useCallback(
    (styleList: any) => {
      setStyleFilters(styleList);
      onStyleUpdate?.(styleList);
    },
    [onStyleUpdate]
  );

  const handleAddToReport = useCallback(() => {
    setAppliedStyleFilters(styleFilter);
    handleClose();
  }, [styleFilter]);

  const updateSectionPlacement = (height: number) => {
    onUpdatePlacement?.(id, { h: height });
  };

  const handleDelete = useCallback(() => {
    setSectionMenuEl(null);
    onDelete();
  }, [onDelete]);

  const handleDuplicate = useCallback(() => {
    setSectionMenuEl(null);
    onDuplicate();
  }, [onDuplicate]);

  const stylesIncluded =
    ![
      'CDP_TIMELINE',
      'CT_TIMELINE_GANTT',
      'EXPEDITED_TIMELINE',
      'CT_TIMELINE_CHANGE',
      'TABLE'
    ].includes(chartType) && templateType === 'custom';

  const GraphComponent = GRAPH_COMPONENTS[chartType];
  if (!GraphComponent) {
    return <>Graph type not found :: {chartType}</>;
  }
  return (
    <>
      <Box
        height='100%'
        width='100%'
        style={{ boxSizing: 'border-box' }}
        className='section-container'
        id={`pdf-chart-section-${templateId}`}
        onDoubleClick={chartType !== 'TABLE' ? handleGraphStyleChange : undefined}>
        <Box
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: 100,
            display: 'none'
          }}
          className='moreButton'>
          <Box
            display='inline-block'
            sx={{ cursor: 'pointer' }}
            onClick={(event: any) => setSectionMenuEl(event.currentTarget)}>
            <MoreVertIcon sx={{ color: '#2D95E0' }} />
          </Box>
          <Menu
            anchorEl={sectionMenuEl}
            onClose={() => setSectionMenuEl(null)}
            open={!!sectionMenuEl}>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
            <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
            {stylesIncluded && <MenuItem onClick={handleGraphStyleChange}>Styles</MenuItem>}
          </Menu>
        </Box>
        {(graphsData ||
          chartType === 'CDP_TIMELINE' ||
          chartType === 'CT_TIMELINE_GANTT' ||
          chartType === 'EXPEDITED_TIMELINE' ||
          chartType === 'CT_TIMELINE_CHANGE') && (
          <GraphComponent
            data={graphsData?.Data || graphsData?.data}
            key='Title'
            indexKey='Title'
            keys={graphsData?.keyList || []}
            yAxis={graphsData?.yAxis || []}
            title={style.title}
            columnNameMapping={graphsData?.attribute_name}
            order={data?.query?.columns}
            style={appliedstyleFilter}
            referenceText={style.references}
            onUpdatePlacement={updateSectionPlacement}
            // eslint-disable-next-line react/jsx-props-no-spreading, no-nested-ternary
            {...(chartType === 'CDP_TIMELINE'
              ? {
                  fdaFilters: data.filters.fda,
                  trialsFiltersCNF: data.filters.trialsCNF,
                  applicationNumber: data.applicationNumber,
                  activeIngredient: data.activeIngredient,
                  ctFiltersDateRange: data.filters.trialsDateRange,
                  showTrialsInSBAS: data.filters.showTrialsInSBAS,
                  labels: data.labels,
                  approvals: data.approvals
                }
              : // eslint-disable-next-line no-nested-ternary
              chartType === 'CT_TIMELINE_GANTT'
              ? {
                  trialsFiltersCNF: data.filters.queryCNF,
                  fdaFilters: data.filters.fda,
                  ctFiltersDateRange: data.filters.dateFilters,
                  showTrialsInSBAS: !!data.filters.showTrialsInSBAS,
                  trialsSource: data.filters.trialsSource
                }
              : chartType === 'CT_TIMELINE_CHANGE'
              ? {
                  searchId: data.resultId
                }
              : {})}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(chartType === 'EXPEDITED_TIMELINE'
              ? {
                  filters: data.filters,
                  view: data.view,
                  search: data.search
                }
              : {})}
          />
        )}
      </Box>
      {openStyle && (
        <StyleDialogBox
          graphsData={graphsData}
          style={style}
          styleFilter={styleFilter}
          openStyle={openStyle}
          data={data}
          handleClose={handleClose}
          chartType={chartType}
          handleAddToReport={handleAddToReport}
          handleStyleChange={handleStyleChange}
        />
      )}
    </>
  );
};

const hasSame = (objectA: any, objectB: any) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in objectA) {
    if (objectA[key] !== objectB[key]) {
      return false;
    }
  }
  return true;
};

const textSectionStyles = {
  active: {
    border: '2px dotted #2D95E0',
    '&:hover': {
      border: '2px dotted #2D95E0',
      '& .react-resizable-handle': {
        opacity: 0
      },
      '& .moveIcon': {
        display: 'none'
      },
      '& .moreButton': {
        display: 'none'
      }
    }
  },
  inactive: {
    border: '2px dotted transparent',
    '&:hover': {
      border: '2px dotted #2D95E0',
      '& .react-resizable-handle': {
        opacity: 1
      },
      '& .moveIcon': {
        display: 'inline-block !important'
      },
      '& .moreButton': {
        display: 'inline-block !important'
      }
    }
  }
};
const PdfLayout = ({ reportLayout = null }: any) => {
  const { state, dispatch } = useContext<{ state: any; dispatch: any }>(AppStore as any);
  const containerRef = useRef<HTMLDivElement>(null);
  const layoutContainerRef = useRef<HTMLDivElement>(null);
  const layoutStateRef = useRef<any>(null);
  const datagridLayoutRef = useRef<any>(null);
  const [currentlyEditingTextSectionId, setEditingTextSectionId] = useState<string>('');

  const [newlyAddedSectionId, setNewAddedSectionId] = useState<string | null>(null);

  const [reportLayoutState, setReportLayoutState] = useState<any>({ sections: [] });
  const [isCoverPageSelectionOpen, setCoverPageSelection] = useState<boolean>(false);

  const [isClearOpen, setClearOpen] = useState<boolean>(false);
  const [isReportNotExist, setReportNotExist] = useState<boolean>(false);

  const queryParams = new URLSearchParams(window?.location?.search);
  const reportId = queryParams.get('id') || state?.reportLayout?.id;
  const reportIdFromUrl = queryParams.get('id');
  const redoUndoUpdateBlockedRef = useRef<boolean>(false);
  const { undo, redo, updatePresent } = useUndoRedo();

  useEffect(() => {
    (async () => {
      const user = await Auth.currentUserInfo();
      dispatch?.({
        type: actions.UPDATE_REPORT_OWNER,
        value: user.attributes['custom:user']
      });
    })();

    // when user refresh inside report, it should get data from localStorage and add it to the global store
    if (!reportIdFromUrl) {
      const reportData = localStorage.getItem('layout_json');
      if (reportData) {
        dispatch?.({
          type: actions.SET_REPORT_LAYOUT,
          value: JSON.parse(reportData)
        });
      }
    }
  }, []);

  const handleReportUpdate = () => {
    // prevent updating the global state if report is opened from profile page
    if (!reportIdFromUrl) {
      const reportData = localStorage.getItem('layout_json');
      if (reportData) {
        dispatch?.({
          type: actions.SET_REPORT_LAYOUT,
          value: JSON.parse(reportData)
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener('focus', handleReportUpdate);
    return () => {
      window.removeEventListener('focus', handleReportUpdate);
    };
  }, []);

  useEffect(() => {
    let timeout: any = null;
    function handleKeyDown(event: any) {
      let newState = null;
      if (event.key === 'z' && event.ctrlKey) {
        newState = undo();
      }
      if (event.key === 'y' && event.ctrlKey) {
        newState = redo();
      }
      if (newState) {
        redoUndoUpdateBlockedRef.current = true;
        dispatch?.({
          type: actions.REPLACE_REPORT_LAYOUT,
          value: newState
        });
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          redoUndoUpdateBlockedRef.current = false;
        }, 2000);
      }
    }
    // Bind the event listener
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [undo, redo, dispatch]);

  const handleUpdatePlacement = (sectionId: string, updatedPlacement: any) => {
    const modifiedSection = layoutStateRef.current?.sections.find(
      (section: any) => section.id === sectionId
    );
    if (
      modifiedSection?.style?.placement &&
      hasSame(updatedPlacement, modifiedSection?.style?.placement)
    ) {
      return;
    }

    dispatch?.({
      type: actions.UPDATE_REPORT_SECTION_POSITION,
      value: {
        sectionId,
        updatedPlacement
      }
    });
  };

  const handleTextUpdate = (sectionId: string, updatedContent: any) => {
    const modifiedSection = layoutStateRef.current?.sections.find(
      (section: any) => section.id === sectionId
    );
    if (modifiedSection && modifiedSection.content === updatedContent) {
      return;
    }
    dispatch?.({
      type: actions.UPDATE_REPORT_SECTION_TEXT,
      value: {
        sectionId,
        updatedContent
      }
    });
  };

  const handleLayoutChange = useCallback((changedLayout: any) => {
    const comparables = changedLayout.map((section: any) => ({ ...section, static: false }));
    if (isEqual(datagridLayoutRef.current, comparables)) {
      return;
    }
    datagridLayoutRef.current = changedLayout;
    dispatch?.({
      type: actions.UPDATE_REPORT_SECTIONS_POSITION,
      value: changedLayout
    });
  }, []);

  const debounceHandleLayoutChange = useCallback(debounce(handleLayoutChange, 1000), []);

  useEffect(() => {
    if (!redoUndoUpdateBlockedRef.current) {
      updatePresent(reportLayoutState);
    }
  }, [reportLayoutState]);

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line consistent-return
      const fetchReportData = async (id: string) => {
        const res = await getReport(id);
        if (res && res.status === 200) {
          const { body, status, error, message } = await res.data;
          if (status === 200 && !error && body) {
            const report = await body;
            return report;
          }
          throw new Error(message);
        }
      };

      const reportData = localStorage.getItem('layout_json');
      if (reportIdFromUrl || (state?.reportLayout?.id && !reportData)) {
        try {
          const report = await fetchReportData(reportId);
          // eslint-disable-next-line camelcase
          const { layout, created_at } = report;
          // eslint-disable-next-line camelcase
          layout.createdAt = created_at;
          layout.sections = state?.reportLayout?.sections?.length
            ? state?.reportLayout?.sections
            : layout.sections;
          dispatch?.({
            type: actions.SET_REPORT_LAYOUT,
            value: {
              ...layout,
              reportFileName: report.report_name,
              reportFileDescription: report.report_description,
              id: report.id
            }
          });
        } catch (error) {
          setReportNotExist(true);
          // eslint-disable-next-line no-console
          console.error('Error fetching data:', error);
        }
      }
    })();
  }, [reportId, state?.reportLayout?.sections?.length]);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    if (isEqual(reportLayoutState, state?.reportLayout)) {
      return;
    }
    const fetchData = async () => {
      if (reportLayout == null) {
        layoutStateRef.current = state?.reportLayout;
        setReportLayoutState(state?.reportLayout);
      } else {
        setReportLayoutState(reportLayout);
      }
    };

    fetchData();
  }, [reportLayout, state?.reportLayout?.owner, state?.reportLayout?.sections, reportLayoutState]);

  const handleTextSectionEditStatusChange = useCallback((isEditing: boolean, sectionId: string) => {
    if (isEditing) {
      setEditingTextSectionId(sectionId);
    } else {
      setEditingTextSectionId('');
    }
  }, []);

  const handleDeleteSection = useCallback((sectionId: string) => {
    dispatch?.({
      type: actions.REMOVE_REPORT_SECTION,
      value: sectionId
    });
  }, []);

  const handleDuplicateSection = useCallback((sectionId: string) => {
    dispatch?.({
      type: actions.DUPLICATE_REPORT_SECTION,
      value: sectionId
    });
  }, []);

  const handleCoverPageTitleChange = useCallback((text: string) => {
    dispatch?.({
      type: actions.UPDATE_COVERPAGE_TITLE,
      value: text
    });
  }, []);

  const handleCoverPageDescriptionChange = useCallback((text: string) => {
    dispatch?.({
      type: actions.UPDATE_COVERPAGE_DESCRIPTION,
      value: text
    });
  }, []);

  const handleCoverPageTemplateChange = useCallback((templateId: string) => {
    setCoverPageSelection(false);
    dispatch?.({
      type: actions.UPDATE_COVERPAGE_TEMPLATE,
      value: templateId
    });
  }, []);

  const handleNewSectionAdd = useCallback((sectionId: string) => {
    setNewAddedSectionId(sectionId);

    // Show the borders for the newly added section and hide the borders after sometime.
    setTimeout(() => {
      setNewAddedSectionId(null);
    }, 2500);
  }, []);

  const handleStyleUpdate = useCallback((sectionId: string | number, updatedStyle: any) => {
    dispatch?.({
      type: actions.UPDATE_GRAPH_STYLE,
      value: { sectionId, updatedStyle }
    });
  }, []);

  const handleClearReportExit = () => {
    setClearOpen(false);
    dispatch?.({
      type: actions.CLEAR_REPORT
    });
    window.close();
  };

  const handleDialogClose = useCallback(() => {
    setClearOpen(false);
  }, [isClearOpen]);

  const handleReportExit = () => {
    setClearOpen(false);
    window.close();
  };

  const getBackdropMessage = () => {
    if (state?.reportDownloading) return 'Downloading your report. Please wait...';
    if (state?.reportSaving) return 'Saving your report. Please wait...';
    if (state?.reportDownloadingForSharing) return 'Preparing your report. Please wait...';
    return '';
  };

  const showLoadingMessage =
    state?.reportDownloading || state?.reportSaving || state?.reportDownloadingForSharing;

  const sections = useMemo(
    () =>
      reportLayoutState?.sections?.sort(
        (a: any, b: any) => a.style.placement.y - b.style.placement.y
      ),
    [reportLayoutState?.sections]
  );

  return (
    <ThemeProvider theme={themev5}>
      <Box
        display='flex'
        flexDirection='row'
        overflow='hidden'
        width='100%'
        height='100vh'
        sx={styles.reportContainer}>
        <Box overflow='hidden' className='no-print'>
          <Tools
            layout={reportLayoutState}
            reportId={reportId}
            onCoverPageSelectionClick={() => setCoverPageSelection(true)}
            onTextWidgetAdded={handleNewSectionAdd}
            onSpacerAdded={handleNewSectionAdd}
            defaultFileName={reportLayoutState.reportFileName}
            defaultFileDescription={reportLayoutState.reportFileDescription}
          />
        </Box>
        <IconButton
          edge='end'
          color='inherit'
          className='no-print'
          onClick={() => setClearOpen(true)}
          aria-label='close'
          sx={{ position: 'absolute', right: 24, top: 10, zIndex: 1001 }}>
          <CloseIcon />
        </IconButton>
        <Box sx={styles.main} margin='auto' overflow='auto' className='print-main' height='100%'>
          {showLoadingMessage && (
            <ReportLoader open={showLoadingMessage} message={getBackdropMessage()} />
          )}
          <Box className='no-print' height={48} />
          <Box sx={styles.pdfLayout}>
            <CoverPage
              title={reportLayoutState.coverPage?.title}
              description={reportLayoutState.coverPage?.description}
              ownerName={reportLayoutState.owner}
              logo={reportLayoutState.logo}
              templateType={reportLayoutState.coverPage?.template}
              onTitleChange={handleCoverPageTitleChange}
              onDescriptionChange={handleCoverPageDescriptionChange}
              createdAt={reportLayoutState?.createdAt || reportLayout?.createdAt}
            />
          </Box>
          <Box className='no-print' height={48} />
          <Box sx={styles.pdfLayout} ref={layoutContainerRef} pl='25.4mm' pr='25.4mm'>
            <Box className='no-print' height='25.4mm' />
            <Box width='100%' height='100%' sx={styles.pdfPage}>
              <ResponsiveReactGridLayout
                className='layout'
                cols={cols}
                rowHeight={10}
                onLayoutChange={debounceHandleLayoutChange}
                margin={margin}
                resizeHandles={resizeHandles}
                breakpoint='lg'>
                {sections?.map((section: any) => {
                  if (section.sectionType === 'CHART') {
                    return (
                      <Box
                        id={`${section.id}`}
                        key={`${section.id}`}
                        data-grid={{ ...section.style.placement, i: section.id, minW: 8 }}
                        sx={{
                          border: '2px dotted transparent',
                          '&:hover': styles.dragActiveElement
                        }}>
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                            zIndex: 100,
                            display: 'none'
                          }}
                          className='moveIcon'>
                          <MoveIcon />
                        </Box>
                        <PDFChartSection
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...section}
                          sectionId={section.id}
                          mainPageHeight={containerRef.current?.clientHeight ?? 0}
                          boundedParent={layoutContainerRef.current}
                          onUpdatePlacement={handleUpdatePlacement}
                          onDelete={() => handleDeleteSection(section.id)}
                          onDuplicate={() => handleDuplicateSection(section.id)}
                          onStyleUpdate={(updatedStyle: any) =>
                            handleStyleUpdate(section.id, updatedStyle)
                          }
                        />
                      </Box>
                    );
                  }
                  if (section.sectionType === 'TEXT') {
                    return (
                      <Box
                        id={`${section.id}`}
                        key={`${section.id}`}
                        data-grid={{
                          ...section.style.placement,
                          static: currentlyEditingTextSectionId === section.id,
                          i: section.id,
                          minW: 8,
                          h:
                            (window as any).xClientId === 'REPORT_SERVER'
                              ? section.style.placement.h +
                                Math.ceil(section.style.placement.h / 54) +
                                2
                              : section.style.placement.h
                        }}
                        sx={
                          currentlyEditingTextSectionId === section.id ||
                          newlyAddedSectionId === section.id
                            ? textSectionStyles.active
                            : textSectionStyles.inactive
                        }>
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                            zIndex: 100,
                            display: 'none'
                          }}
                          className='moveIcon'>
                          <MoveIcon />
                        </Box>
                        <PDFTextSection
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...section}
                          textContent={section.content}
                          sectionId={section.id}
                          mainPageHeight={containerRef.current?.clientHeight ?? 0}
                          boundedParent={layoutContainerRef.current}
                          onUpdatePlacement={handleUpdatePlacement}
                          onTextChange={handleTextUpdate}
                          onEditStateChange={(isEditing: boolean) =>
                            handleTextSectionEditStatusChange(isEditing, section.id)
                          }
                          onDelete={() => handleDeleteSection(section.id)}
                          onDuplicate={() => handleDuplicateSection(section.id)}
                        />
                      </Box>
                    );
                  }
                  if (section.sectionType === 'SPACER') {
                    return (
                      <Box
                        id={`${section.id}`}
                        key={`${section.id}`}
                        data-grid={{ ...section.style.placement, i: section.id, minW: 8 }}
                        sx={{
                          border: '2px dotted transparent',
                          ...(newlyAddedSectionId === section.id ? styles.dragActiveElement : {}),
                          '&:hover': styles.dragActiveElement
                        }}>
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                            zIndex: 100,
                            display: 'none'
                          }}
                          className='moveIcon'>
                          <MoveIcon />
                        </Box>
                        <Spacer id={section.id} onDelete={() => handleDeleteSection(section.id)} />
                      </Box>
                    );
                  }
                  if (section.sectionType === 'SNAPSHOT') {
                    return (
                      <Box
                        id={`${section.id}`}
                        key={`${section.id}`}
                        data-grid={{ ...section.style.placement, i: section.id, minW: 8 }}
                        sx={{
                          border: '2px dotted transparent',
                          ...(newlyAddedSectionId === section.id ? styles.dragActiveElement : {}),
                          height: 'auto !important',
                          '&:hover': styles.dragActiveElement
                        }}>
                        <Box sx={snapshotStyles.layout.moveIconContainer} className='moveIcon'>
                          <MoveIcon />
                        </Box>
                        <Snapshot
                          id={section?.id}
                          title={section?.style?.title}
                          detail={section?.data}
                          onDelete={() => handleDeleteSection(section.id)}
                          onDuplicate={() => handleDuplicateSection(section.id)}
                          onUpdatePlacement={handleUpdatePlacement}
                        />
                      </Box>
                    );
                  }
                  return null;
                })}
              </ResponsiveReactGridLayout>
            </Box>
          </Box>
          {isCoverPageSelectionOpen && (
            <CoverPageSelectionDialog
              open={isCoverPageSelectionOpen}
              selectedTemplateId={reportLayoutState.coverPage?.template}
              onSelectionChange={handleCoverPageTemplateChange}
              onClose={() => setCoverPageSelection(false)}
            />
          )}
          {isClearOpen && (
            <ClearDialog
              onClose={handleReportExit}
              onClear={handleClearReportExit}
              reportId={reportIdFromUrl}
              handleDialogClose={handleDialogClose}
              open
            />
          )}
          {isReportNotExist && <ReportExistDialog />}

          <Box className='no-print' height={48} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default React.memo(PdfLayout);
