import React, { useCallback, useEffect, useMemo, useRef, useState, lazy, Suspense } from 'react';

// mui
import { Box } from '@mui/material';

// custom componenet , utils
import { prepareDelayGraphData } from './utils';
import CTIndicator from '../../../assets/svgs/CardsPage/CTIndicator';
import { getSearchFromId } from '../../../api/pages/UserProfile';
import SuspenseCircularLoader from '../../../helpers/SuspenseCircularLoader';

const DelayTimelineReport = lazy(() => import('./DelayTimelineReport'));

const CTTimelineChangeReport = ({ searchId }: any) => {
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getSearchFromId(searchId);
      if (res?.status === 200) {
        const { results } = res.data.body.result.ct;
        setApiData(results);
      } else {
        console.error('result not found');
      }
    };
    fetchData();
  }, []);

  const graphRef = useRef();
  const addRef = useCallback((ref: any) => {
    graphRef.current = ref;
  }, []);

  const { barGraphData, nctIds, minDelayDays, maxDelayDays } = useMemo(() => {
    const result = prepareDelayGraphData(apiData, 'default');
    return result;
  }, [apiData]);

  return (
    <Box height='100%' width='100%'>
      {barGraphData ? (
        <>
          <CTIndicator />
          <Suspense fallback={<SuspenseCircularLoader />}>
            <DelayTimelineReport
              barGraphData={barGraphData}
              nctIds={nctIds}
              addRef={addRef}
              minDelayDays={minDelayDays}
              maxDelayDays={maxDelayDays}
            />
          </Suspense>
        </>
      ) : null}
    </Box>
  );
};

export default React.memo(CTTimelineChangeReport);
