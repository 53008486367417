import React from 'react';
import { Badge, Box, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { designationName, designationTypes, designationTypesColor } from './SpecialityTimeLine';
import ExpandedCard from './ExpandedCard';
import { badgeStyle, blankCardStyle, cardStyle } from '../styles/horizontalDesignationCardStyle';
import { styles } from '../styles/verticalDesignationCard';

export const variantStyles = {
  red: {
    appNum: styles.appNumRed,
    name: styles.nameRed
  },
  blue: {
    appNum: styles.appNumBlue,
    name: styles.nameBlue
  }
};

const HorizontalDesignationCard = ({
  blankCard,
  number,
  tradeName,
  activeIngredients,
  sponsorName,
  indications,
  route,
  formulation,
  specialityDesignation,
  marketingStatus = 'GREEN',
  applicationType
}: HorizontalDesignationCardProps) => {
  const [selectedDesignationType, setSelectedDesignationType] = React.useState('');
  const strikeText = marketingStatus?.toLowerCase().trim() !== 'green';
  const variant = applicationType?.toLowerCase() === 'nda' ? 'red' : 'blue';

  const handleClose = (event: any, reason: any) => {
    setSelectedDesignationType('');
  };
  if (blankCard) {
    return (
      <Stack direction='row' sx={blankCardStyle}>
        <Divider orientation={'vertical'} />
      </Stack>
    );
  }
  const expandCard = !isEmpty(selectedDesignationType);
  return (
    <>
      <ExpandedCard
        open={expandCard}
        onClose={handleClose}
        payload={{
          number,
          tradeName,
          activeIngredients,
          sponsorName,
          indications,
          route,
          formulation,
          specialityDesignation,
          selectedDesignationType,
          variant,
          strikeText
        }}
      />

      <Stack
        direction='column'
        justifyContent='flex-start'
        alignItems='left'
        sx={{
          ...cardStyle,
          borderColor: expandCard ? 'primary.main' : 'gray.light',
          boxShadow: expandCard ? 1 : 0
        }}>
        <Box display='flex' justifyContent='flex-start' alignItems='center'>
          <Typography
            sx={{
              // @ts-ignore
              ...variantStyles[variant].appNum,
              textDecoration: strikeText ? 'line-through' : 'none'
            }}>
            {number}
          </Typography>
          <Tooltip title={tradeName as string}>
            <Typography
              sx={{
                // @ts-ignore
                ...variantStyles[variant].name,
                overflow: expandCard ? 'visible' : 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                textDecoration: strikeText ? 'line-through' : 'none'
              }}>
              {tradeName}
            </Typography>
          </Tooltip>
        </Box>
        {specialityDesignation && (
          <Stack direction={'row'} spacing={1} mt={1} justifyContent={'flex-start'}>
            {designationTypes.map((item, index) => {
              const isActive = specialityDesignation.some(e => e.type === item);
              const isSelected = selectedDesignationType === item && expandCard;
              const supplementCount = specialityDesignation?.filter(e => e.type === item)?.length;
              return (
                <Badge
                  key={`${item}`}
                  sx={badgeStyle}
                  badgeContent={supplementCount > 1 ? supplementCount : 0}>
                  <Tooltip key={`${number}${item}`} title={designationName[item]}>
                    <span>
                      <IconButton
                        disabled={!isActive}
                        onClick={() => {
                          setTimeout(() => {
                            setSelectedDesignationType(prev => (prev === item ? '' : item));
                          }, 200);
                        }}
                        sx={{
                          width: 30,
                          height: 30,
                          backgroundColor: designationTypesColor[item].background,
                          boxShadow: isSelected ? 3 : 0,
                          transform: isSelected ? 'scale(1.3)' : 'none',
                          '&:hover': isActive
                            ? {
                                backgroundColor: designationTypesColor[item].background,
                                cursor: 'pointer',
                                boxShadow: 1,
                                transform: 'scale(1.1)'
                              }
                            : {},
                          '&:disabled': {
                            backgroundColor: 'gray.light'
                          }
                        }}>
                        <Typography
                          variant={'body2'}
                          sx={{
                            color: 'white.main',
                            fontWeight: 'bold'
                          }}>
                          {item.toUpperCase()}
                        </Typography>
                      </IconButton>
                    </span>
                  </Tooltip>
                </Badge>
              );
            })}
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default React.memo(HorizontalDesignationCard);
