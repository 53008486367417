import PropTypes from 'prop-types';

import React, { Box, Button, Dialog, Divider, Stack, Tooltip, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { isEmpty } from 'lodash';
import { CloseIcon } from '../../assets/svgs/Icons';

import PDFViewer from '../PDFViewer';

import { styles } from './PdfPreview.styles';
import trimText from '../../utils/trimText';
import LanguageToggleButton from '../LanguageToggleComponents/LanguageToggleButton';
import LanguageToggleText from '../LanguageToggleComponents/LanguageToggleText';

const PDFPreview = ({
  open,
  onClose,
  pdfUrl,
  handleChatRia,
  title = '',
  setOriginalTextEnabled,
  originalTextEnabled,
  currentFileOriginalLanguage,
  source
}: any) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby='customized-dialog-title'
      open={open && Boolean(pdfUrl)}
      fullWidth
      maxWidth='lg'>
      <Box sx={styles.close} display='flex' alignItems='center'>
        <Stack flex='display' alignItems='center' sx={{ pl: '15px', flexDirection: 'row' }}>
          {title && (
            <Tooltip title={title}>
              <Typography
                sx={{
                  fontFamily: 'Mulish',
                  fontSize: 14,
                  fontWeight: 700,
                  textOverflow: 'ellipsis'
                }}>
                {trimText(title ?? '', 40)}
              </Typography>
            </Tooltip>
          )}
          <Divider
            orientation='vertical'
            variant='middle'
            flexItem
            sx={{ p: '5px', height: originalTextEnabled ? 40 : 16 }}
          />{' '}
          <Tooltip title='Use Chat RIA to interact with the document'>
            <Button
              variant='text'
              disabled={!pdfUrl}
              onClick={() =>
                handleChatRia({
                  pdfUrl
                })
              }
              sx={styles.chatRiaButton}>
              Chat RIA
              <SendIcon
                sx={{ width: '16px', height: '16px', p: '2.667px 2.666px 2.667px 2.667px' }}
              />
            </Button>
          </Tooltip>
          {source === 'jp' && (
            <>
              <Divider
                orientation='vertical'
                variant='middle'
                flexItem
                sx={{ p: '5px', height: originalTextEnabled ? 40 : 16 }}
              />{' '}
              <Box display='flex' flexDirection='column' ml='10px'>
                <Box sx={{ justify: 'center', alignItems: 'center' }}>
                  {!isEmpty(currentFileOriginalLanguage) && (
                    <LanguageToggleButton
                      source={source}
                      originalTextEnabled={originalTextEnabled}
                      toggleTextLanugage={() => {
                        setOriginalTextEnabled(!originalTextEnabled);
                      }}
                      color='green'
                    />
                  )}
                </Box>
                <Box>
                  {originalTextEnabled && <LanguageToggleText styles={{ textAlign: 'start' }} />}
                </Box>
              </Box>
            </>
          )}
        </Stack>

        <Box onClick={onClose}>
          <CloseIcon sx={{ width: '18px', height: '18px', cursor: 'pointer', color: 'grey.600' }} />
        </Box>
      </Box>
      {pdfUrl ? (
        <Box sx={styles.iframeContainer}>
          <PDFViewer fileUrl={pdfUrl} defaultScale={1.35} />
        </Box>
      ) : null}
    </Dialog>
  );
};

PDFPreview.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  pdfUrl: PropTypes.string.isRequired,
  handleChatRia: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  title: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  setOriginalTextEnabled: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  originalTextEnabled: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  currentFileOriginalLanguage: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  source: PropTypes.string
};

export default PDFPreview;
