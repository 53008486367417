/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ThemeProvider } from "@mui/material/styles";
import PDFViewer from "../../../components/PDFViewer/index";
import { ReadOnlyMarkdown } from "../../../components/ChatRIA/component/MarkdownBox";

import { getLatestSubmissonSection } from "../../../api/pages/CardsPage";
import themev5 from "../../../themev5";
import styles from "../styles/IndicationsTab.styles";
// eslint-disable-next-line react/prop-types
const IndicationsTab = ({ applnum, section, IndicationsField }) => {
  const [indicationData, setIndicationData] = useState();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getData = async () => {
    try {
      const response = await getLatestSubmissonSection(applnum, section);
      if (response && response.status === 200) {
        setIndicationData(response);
      } else {
        // eslint-disable-next-line no-console
        console.error(response.Error);
        setIndicationData({});
      }
    } catch (error) {
      setIndicationData({});
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <ThemeProvider theme={themev5}>
      {indicationData && indicationData.data ? (
        <Box sx={styles.indications_container}>
          {Array.isArray(indicationData?.data.Success) ? (
            <Box display='flex'>
              {indicationData.data.Success.length !== 1 ? (
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant='scrollable'
                  scrollButtons={false}
                  orientation='vertical'
                  centered
                  aria-label='scrollable prevent tabs example'
                  sx={styles.tabs}>
                  {indicationData.data.Success.map((row, index) => {
                    return (
                      <Tab
                        key={row.key || row.din || row.yj_code}
                        label={row.key || row.din || row.yj_code}
                        sx={index === value ? styles.activeTab : styles.inactiveTab}
                      />
                    );
                  })}
                </Tabs>
              ) : (
                <Box display='flex' flexDirection='column' sx={styles.indications_container}>
                  {indicationData.data.Success[0].s3_url ? (
                    <PDFViewer fileUrl={indicationData.data.Success[0].s3_url.toString()} />
                  ) : (
                    <Box sx={[styles.indications_container, styles.indications_field_container]}>
                      {indicationData.data.Success[0].indications_and_usage}
                    </Box>
                  )}
                </Box>
              )}
              {Array.isArray(indicationData?.data.Success) &&
                indicationData.data.Success.length !== 1 &&
                indicationData.data.Success.map((row, index) => {
                  if (index === value) {
                    return (
                      <Stack
                        key={row.key || row.din}
                        mt={1}
                        flexDirection='row'
                        sx={{ overflow: 'auto', bgcolor: 'background.paper' }}
                        width='100%'>
                        {row.uri ? (
                          <PDFViewer fileUrl={row.uri.toString()} />
                        ) : (
                          <Box
                            sx={[styles.indications_container, styles.indications_field_container]}>
                            <ReadOnlyMarkdown markdown={row.indications_and_usage} />
                          </Box>
                        )}
                      </Stack>
                    );
                  }
                })}
            </Box>
          ) : (
            <Box sx={styles.indications_container}>
              {indicationData.data.Success.uri ? (
                <PDFViewer fileUrl={indicationData.data.Success.uri.toString()} />
              ) : (
                <Box sx={[styles.indications_container, styles.indications_field_container]}>
                  {IndicationsField ? (
                    <ReadOnlyMarkdown markdown={IndicationsField} />
                  ) : (
                    <Box>
                      {indicationData.data.Success.indications_text ? (
                        <ReadOnlyMarkdown markdown={indicationData.data.Success.indications_text} />
                      ) : (
                        <Box sx={styles.textAlignment}>
                          <Typography variant='h2'>No Indication Text available</Typography>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={[styles.indications_container, styles.indications_field_container]}>Loading</Box>
      )}
    </ThemeProvider>
  );
};

export default IndicationsTab;
