import { CircularProgress, MenuItem } from '@mui/material';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { deleteSearch } from '../../../api/pages/UserProfile';
import Actions from '../../../store/actions';
import GlobalStore from '../../../store';
import DeleteConfirmation from '../Components/DeleteConfirmation';

const DeleteSearchAction = ({ data, onClose }) => {
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(GlobalStore);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      if ('activity_id' in data) {
        let payload = {
          activityId: data.activity_id
        };
        switch (data.type) {
          case 'advancedSearch':
          case 'quickSearch':
            payload = {
              ...payload,
              type: 'quick-advanced-search'
            };
            break;
          case 'ariaSearch':
            payload = {
              ...payload,
              type: 'aria-search'
            };
            break;
          default:
            payload = {
              ...payload,
              type: data.type
            };
        }
        const res = await deleteSearch(payload);
        setLoading(false);

        if (res && res.status === 200) {
          if (onClose) {
            onClose(data);
          }
          await dispatch({
            type: Actions.SET_ALERT,
            value: {
              message: 'Search removed from activity',
              status: true,
              color: 'success'
            }
          });
        } else {
          throw new Error('Something went wrong');
        }
      } else {
        throw new Error('Cannot delete this search');
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      await dispatch({
        type: Actions.SET_ALERT,
        value: { message: e.message, status: true }
      });
      if (onClose) {
        onClose();
      }
    }
  };
  return (
    <>
      <MenuItem onClick={() => setOpenDeleteConfirmation(true)} disabled={loading}>
        {loading ? 'Removing ....' : 'Remove this activity?'}
        {loading && <CircularProgress size={25} sx={{ ml: 1 }} />}
      </MenuItem>
      <DeleteConfirmation
        open={openDeleteConfirmation}
        onReject={() => setOpenDeleteConfirmation(false)}
        onAccept={() => {
          setOpenDeleteConfirmation(false);
          handleDelete();
        }}
      />
    </>
  );
};
DeleteSearchAction.prototype = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func
};
export default DeleteSearchAction;
