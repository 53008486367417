/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';
import AuthContext from './store/Auth/AuthProvider';
import UserProfile from './pages/UserProfilev2';

/**
 * function to create authenticated routes for the application
 * @param {string} path url path
 * @param {node} component component that should be rendered
 * @param {boolean} exact to check exact match for url path is required
 * @returns route from react-router-dom which renders the given component for url path
 */
const PrivateRoute = ({ path, component, exact }) => {
  const { currentUser } = useContext(AuthContext);
  const { pathname, search } = useLocation();
  const getRedirectPath = () => {
    let redirectPath = pathname;
    if (search) {
      redirectPath += search;
    }
    return encodeURIComponent(btoa(redirectPath));
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {/* eslint-disable-next-line */}
      {currentUser ? (
        path.startsWith('/account') ? (
          <Route path={path} render={() => <UserProfile childrenRoutes={component} />} />
        ) : (
          <Route exact={exact} path={path} component={component} />
        )
      ) : (
        <Redirect to={`/?redirectTo=${getRedirectPath()}`} />
      )}
    </>
  );
};

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.any.isRequired,
  // eslint-disable-next-line react/require-default-props
  exact: PropTypes.bool
};

export default PrivateRoute;
