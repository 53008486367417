import { useCallback, useState } from 'react';

const useUndoRedo = () => {
  const [past, setPast] = useState<any>([]);
  const [present, setPresent] = useState<any>(null);
  const [future, setFuture] = useState<any>([]);

  const undo = useCallback(() => {
    if (past.length === 0) return;

    const newPast = [...past];
    const newPresent = newPast.pop();

    setPast(newPast);
    setFuture([present, ...future]);
    setPresent(newPresent);

    // eslint-disable-next-line consistent-return
    return newPresent;
  }, [past, present, future]);

  const redo = useCallback(() => {
    if (future.length === 0) return;

    const newFuture = [...future];
    const newPresent = newFuture.shift();

    setPast([...past, present]);
    setFuture(newFuture);
    setPresent(newPresent);

    // eslint-disable-next-line consistent-return
    return newPresent;
  }, [past, present, future]);

  const updatePresent = useCallback(
    (newState: any) => {
      setPast([...past, present]);
      setPresent(newState);
      setFuture([]);
    },
    [past, present]
  );

  return {
    undo,
    redo,
    updatePresent,
    present
  };
};

export default useUndoRedo;
