import { makeStyles } from '@material-ui/styles';
import palette from '../../../themev5/palette';

export default makeStyles(theme => ({
  moreText: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: theme.palette.backgrounds.main,
    fontWeight: 'bold'
  },
  datagrid: {
    '& .MuiDataGrid-root': {
      width: '100%',
      background: theme.palette.white,
      '& .MuiDataGrid-columnHeader': {
        color: palette.primary.main,
        fontWeight: 'bold',
        background: palette.primary.backgroundDark
      },
      '& .MuiDataGrid-columnsContainer': {
        color: palette.primary.lightBluish
      }
    }
  },
  root: {
    // margin: theme.spacing(15),
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    display: 'flex',
    '& .cellValue': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& .MuiDataGrid-root': {
      fontSize: '1.8vh',
      fontWeight: 'normal',
      height: 'inherit',
      color: theme.palette.datagrid.dataText,
      backgroundColor: theme.palette.white,
      border: 'unset',
      '& .MuiDataGrid-columnsContainer': {
        color: theme.palette.primary.lighter,
        backgroundColor: theme.palette.regulatory.lighterGreen
      },
      '& .MuiDataGrid-columnHeader': {
        fontSize: '2.2vh',
        padding: '0 30px'
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold'
      },
      '& .MuiDataGrid-cell': {
        padding: '0 30px'
      },
      '& .MuiDataGrid-renderingZone': {
        backgroundColor: theme.palette.white
      },
      '& .MuiDataGrid-footer': {
        backgroundColor: theme.palette.regulatory.lighterGreen
      }
    }
  },
  loadingContainer: {
    width: '100vw',
    height: '100vh'
  },
  cellDiv: {
    height: 1,
    display: 'block',
    position: 'absolute',
    top: 0
  },
  popper: {
    height: 2,
    zIndex: 1400,
    marginLeft: '-60px'
  },
  typography: {
    padding: 8,
    fontSize: '1.8vh',
    fontWeight: 'normal'
  },
  avatar: {
    float: 'left',
    marginLeft: theme.spacing(1),
    height: '2.2vh',
    width: '2.2vh',
    fontSize: '1.2vh',
    background: theme.palette.cards.avatar
  },
  avatarLight: {
    float: 'left',
    marginLeft: theme.spacing(1),
    height: '2.2vh',
    width: '2.2vh',
    fontSize: '1.2vh',
    background: theme.palette.biologics.textSecondary
  },
  nda: {
    fontSize: '1.8vh',
    height: 'auto',
    width: 'auto',
    float: 'left',
    borderRadius: '5px',
    color: 'white',
    padding: '2px',
    marginTop: '16px',
    marginRight: '45px'
    // marginLeft: '45px',
  },
  labelTitle: {
    color: '#1C7D5D',
    fontWeight: '800',
    fontSize: '3.5vh',
    fontFamily: 'Constantia'
  }
}));
