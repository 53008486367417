import { useState } from 'react';
import { getLabelsForComparison } from '../../../api/pages/Comparison';

const useLabels = (allsections: boolean) => {
  const [isLoading, setLoading] = useState(false);
  const [labels, setLabels] = useState([]);
  const [error, setErrorMessage] = useState('');

  const getLabels = async (source: string, applicationNumber: string) => {
    try {
      setErrorMessage('');
      setLabels([]);
      setLoading(true);
      const response = await getLabelsForComparison(source, applicationNumber);
      if (
        response?.data?.body &&
        response?.data?.body.length > 0 &&
        response.data.body.filter((lbl: any) => !!lbl.label?.sections).length > 0
      ) {
        // if allsections flag is true then add completelabel pdf in sections
        if (allsections) {
          response.data.body.forEach((ele: any) => {
            if (!ele.label?.sections) {
              // eslint-disable-next-line no-param-reassign
              ele.label.sections = [];
            }
            ele.label?.sections?.push({
              s3_link: ele.label.s3_link,
              page_number: 0,
              section: 'all_sections'
            });
          });
        }
        setLabels(response.data.body);
      } else {
        setErrorMessage(`Sorry, we couldn’t find any results.`);
        setLabels([]);
      }
    } catch (err) {
      setErrorMessage(`Sorry, we couldn’t find any results.`);
      setLabels([]);
    } finally {
      setLoading(false);
    }
  };
  return {
    isLoading,
    labels,
    error,
    getLabels
  };
};

export default useLabels;
