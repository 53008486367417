import React from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

interface TabButtonProps {
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  size: 'medium' | 'small' | 'large';
  title: string;
  // eslint-disable-next-line no-unused-vars
  onClick: (e: any) => void;
  selected: boolean;
  // eslint-disable-next-line react/require-default-props
  tooltipText?: string;
}

const selectedStyle = {
  color: 'primary.700',
  fontWeight: 700,
  borderColor: 'primary.50',
  backgroundColor: 'primary.50',
  '&:hover': {
    borderColor: 'primary.50',
    backgroundColor: 'primary.50'
  }
};

const TabButton = ({
  disabled = false,
  size = 'medium',
  title,
  onClick,
  selected = false,
  tooltipText = ''
}: TabButtonProps) => {
  return (
    <Tooltip title={tooltipText}>
      <Button
        disabled={disabled}
        onClick={onClick}
        variant='outlined'
        size={size}
        sx={{
          py: 1,
          px: 3,
          borderRadius: 2,
          border: '1px solid gray',
          borderColor: 'gray.lightVariant8',
          color: 'gray.800',
          fontSize: 14,
          fontWeight: 500,
          lineHeight: 1,
          letterSpacing: '0.2px',
          textTransform: 'capitalize',
          '&:hover': {
            borderColor: 'gray.lightVariant8',
            backgroundColor: 'gray.100'
          },
          ...(selected ? selectedStyle : {})
        }}>
        {title}
      </Button>
    </Tooltip>
  );
};

export default React.memo(TabButton);
