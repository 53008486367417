// mui
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

// styles
import { ThemeProvider } from '@mui/material/styles';
import themev5 from '../../../themev5';
import styles from '../styles/ProductRow.styles';
// Components
import MarketingStatus from './MarketingStatus';

const references = [
  { key: 'rld', tooltipPrefix: 'Reference Listed Drug: ', value: 'RLD' },
  {
    key: 'rs',
    tooltipPrefix: 'Reference Standard: ',
    value: 'RS'
  },
  { key: 'te_code', tooltipPrefix: 'Therapeutic Equivalence: ', value: 'TE' }
];

const PMDASource = ({ item, tabHeading }) => {
  return (
    <ThemeProvider theme={themev5}>
      <Paper>
        <Grid container paddingTop={2} paddingBottom={2} alignItems='center'>
          {tabHeading?.map(header => (
            <Grid item xs={header.size} key={header.key}>
              <Typography align='center' sx={styles.description}>
                {(header?.key in item && item[header.key]) || header.default}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </ThemeProvider>
  );
};
const ProductRow = ({ item, name, source, tabHeading }) => {
  if (source?.toLowerCase() === 'jp') {
    return <PMDASource item={item} tabHeading={tabHeading} />;
  }
  return (
    <ThemeProvider theme={themev5}>
      <Paper>
        <Grid container paddingTop={2} paddingBottom={2} alignItems='center'>
          <Grid item xs={2}>
            <Typography align='center' sx={styles.description}>
              {source?.toLowerCase() === 'ca'
                ? `${item.din}`
                : ['hpra', 'hma'].includes(source?.toLowerCase())
                ? `${item.product_number}`
                : name}
            </Typography>
          </Grid>
          {!['ca', 'hpra', 'hma'].includes(source?.toLowerCase()) && (
            <Grid item xs={3}>
              <Stack direction='row' alignItems='center' justifyContent='center'>
                {references.map(ref => {
                  return (
                    <Tooltip title={ref.tooltipPrefix + item[ref.key]} aria-label='Button Link'>
                      <Avatar
                        key={ref.key}
                        sx={item[ref.key] === 'Yes' ? styles.avatarActive : styles.avatarInActive}>
                        {ref.value}
                      </Avatar>
                    </Tooltip>
                  );
                })}
              </Stack>
            </Grid>
          )}
          {!['hpra', 'hma'].includes(source) && (
            <Grid item xs={4}>
              <Typography align='center' sx={styles.description}>
                {source?.toLowerCase() === 'ca'
                  ? `${item.active_ingredients[0].strength}${item.active_ingredients[0].strength_unit} / ${item.active_ingredients[0].dosage_unit}`
                  : item.strength}
              </Typography>
            </Grid>
          )}
          {['hpra', 'hma'].includes(source) && (
            <Grid item xs={4}>
              <Typography align='center' sx={styles.description}>
                {item.formulation}
              </Typography>
            </Grid>
          )}
          <Grid item xs={3} align='center'>
            {source?.toLowerCase() === 'ca' ? (
              <MarketingStatus marketingstatus={item.current_status} />
            ) : (
              <MarketingStatus marketingstatus={item.marketingstatus} />
            )}
          </Grid>
          <Grid item xs={3} align='center'>
            {['ca', 'hpra', 'hma'].includes(source?.toLowerCase()) && (
              <Typography align='center' sx={styles.description}>
                {item.first_market_date}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  );
};

export default ProductRow;
