import { memo } from 'react';
import { Stack, Typography, Box, Tooltip, Chip } from '@mui/material';
import styles, { CardContainer } from './styles';
import { LABEL_COLOR, LABEL_TOOLTIP_TITLE } from '../../constants';

const Cards = ({ tradeName, applicationType }: { tradeName: any; applicationType: any }) => {
  const labelKey = applicationType?.toLowerCase();
  const labelColor = LABEL_COLOR[labelKey];
  const labelTooltipTitle = LABEL_TOOLTIP_TITLE[labelKey];
  return (
    <Stack sx={CardContainer}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Tooltip title={tradeName as string}>
          <Typography
            sx={{
              color: labelColor,
              ...styles.tradeName
            }}>
            {tradeName}
          </Typography>
        </Tooltip>
        <Tooltip title={labelTooltipTitle}>
          <Chip
            label={applicationType}
            key={applicationType}
            sx={{
              ...styles.chipMetadata,
              bgcolor: labelColor
            }}
          />
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default memo(Cards);
