const RESULT_VIEW_TYPES = {
  CT: 'ct',
  APPLICATION: 'application',
  DOCUMENT: 'document'
};

export const CT_ROW_ID_MAPPING: any = {
  usnlm: 'nct_id',
  euctr: 'euct_id'
};

export default RESULT_VIEW_TYPES;
