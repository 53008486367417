import React from 'react';

const Spacer = () => {
  return (
    <svg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 1H11H21M1 20H11H21'
        stroke='#777777'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.4419 2.55806C11.1979 2.31398 10.8021 2.31398 10.5581 2.55806L6.58058 6.53553C6.33651 6.77961 6.33651 7.17534 6.58058 7.41942C6.82466 7.6635 7.22039 7.6635 7.46447 7.41942L11 3.88388L14.5355 7.41942C14.7796 7.66349 15.1753 7.66349 15.4194 7.41942C15.6635 7.17534 15.6635 6.77961 15.4194 6.53553L11.4419 2.55806ZM10.5581 18.4419C10.8021 18.686 11.1979 18.686 11.4419 18.4419L15.4194 14.4645C15.6635 14.2204 15.6635 13.8247 15.4194 13.5806C15.1753 13.3365 14.7796 13.3365 14.5355 13.5806L11 17.1161L7.46447 13.5806C7.22039 13.3365 6.82466 13.3365 6.58058 13.5806C6.33651 13.8247 6.33651 14.2204 6.58058 14.4645L10.5581 18.4419ZM10.375 3L10.375 18L11.625 18L11.625 3L10.375 3Z'
        fill='#777777'
      />
    </svg>
  );
};

export default React.memo(Spacer);
