// @ts-nocheck
import { useContext, useEffect } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import themev5 from '../../themev5';
import Actions from '../../store/actions';
import Store from '../../store';
import { ProfileStateProvider } from '../../store/UserProfileV2';
import LandingPage from './components/LandingPage';

const UserProfile = ({ childrenRoutes }: { childrenRoutes: any[] }) => {
  const { dispatch } = useContext(Store);

  useEffect(() => {
    dispatch({ type: Actions.SET_MODULE, value: 'core' });
  }, []);

  useEffect(() => {
    return () => {
      dispatch({
        type: Actions.SET_ARIA_DOCUMENT,
        value: {}
      });
      dispatch({
        type: Actions.SET_APPLICATION_NUMBER,
        value: ''
      });
    };
  }, []);

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <ProfileStateProvider>
        <LandingPage childrenRoutes={childrenRoutes} />
      </ProfileStateProvider>
    </ThemeProvider>
  );
};

export default UserProfile;
