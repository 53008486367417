import { memo, useCallback, useContext, useEffect, useState } from 'react';

// material ui
import { Box, Popover } from '@mui/material';

import PMRSurveillance from '../../../store/PMRSurveillance';

import NewFilters from '../../NewFilters';

// hooks
import usePMRSurveillanceData from '../hook/usePMRSurveillanceData';

// components
import PMRSurveillanceDetails from '../components/Details';
import PMRSurveillanceInsights from '../components/Insights';
import ReportWidgets from '../../Report/Widgets';
import BottomDrawer from '../../CustomComponents/BottomDrawer';
import SubHeader from '../../SubHeader';

// styles
import { PMR_SURVEILLANCE_FILTERS } from '../const';
import { Stat } from '../../../pages/SearchResults/types';
import { PAGINATION_DEFAULT_OPTIONS } from '../../PMRImpact/const';

const PMRSurveillanceTabs = () => {
  const { pmrState } = useContext(PMRSurveillance) as any;
  const {
    isLoading,
    pmrSurFilters,
    sortFields,
    getPMRSurData,
    getDatawithDataGridFilter,
    dataGridFilter
  } = usePMRSurveillanceData();
  const [sort, setSort] = useState([...sortFields]);
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [pmrStats, setPmrStats] = useState<Stat[]>([]);
  const [pagination, setPagination] = useState({
    pageSize: PAGINATION_DEFAULT_OPTIONS.limit,
    page: PAGINATION_DEFAULT_OPTIONS.offset
  });
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);

  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] =
    useState<HTMLElement | null>(null);

  useEffect(() => {
    getPMRSurData([], pagination.pageSize, pagination.page, []);
  }, []);

  useEffect(() => {
    if (pmrState?.pmrStats) {
      const newStats: Stat[] = [];
      newStats.push({ title: 'Total', value: pmrState?.pmrStats?.total_count });
      newStats.push({
        title: 'Issued in Last 12 Months',
        value: pmrState?.pmrStats?.issued_last_twelve_month
      });
      newStats.push({
        title: 'Expected Completion in 12 Months',
        value: pmrState?.pmrStats?.expected_in_twelve_months
      });

      setPmrStats(newStats);
    }
  }, [pmrSurFilters]);

  const handleFilterApply = useCallback((currentfilters: any) => {
    setIsFilterUpdated(true);
    setPagination({ page: PAGINATION_DEFAULT_OPTIONS.offset, pageSize: pagination.pageSize });
    getPMRSurData(
      currentfilters,
      pagination.pageSize,
      PAGINATION_DEFAULT_OPTIONS.offset,
      sortFields
    );
  }, []);

  const getFiltersLabelList = () => {
    return PMR_SURVEILLANCE_FILTERS.reduce((acc: any, elem: any) => {
      acc[elem.value] = elem.label;
      return acc;
    }, {});
  };

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  return (
    <>
      <SubHeader
        title='FDA PMR/C Surveillance'
        subTitle='Surveillance for FDA Postmarketing Requirements and Commitments'
        stats={pmrStats}
        handleVisualizeOpen={handleVisualizeOpen}
      />

      <Box>
        {!isLoading && (
          <Popover
            open={!!reportChoicesOpenAnchorElement}
            anchorEl={reportChoicesOpenAnchorElement}
            onClose={() => setReportChoiceAnchorElement(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            sx={{
              '& .MuiPopover-paper': {
                borderRadius: '16px !important',
                backgroundColor: 'none'
              }
            }}>
            <Box>
              <ReportWidgets
                widgets={['pie-widget', 'bar-widget', 'table-widget']}
                title=''
                dataSource={{
                  source: 'pmr',
                  moduleName: 'surveillance',
                  disableSelectionSource: true,
                  filters: pmrState.availableFilters,
                  filterLabelList: getFiltersLabelList()
                }}
                pathMetaData={{
                  module: 'surveillance',
                  href: window.location.href
                }}
                setReportWidgetClose={setReportChoiceAnchorElement}
              />
            </Box>
          </Popover>
        )}
        <PMRSurveillanceDetails
          pagination={pagination}
          setPagination={setPagination}
          sort={sort}
          setSort={setSort}
          sortFields={sortFields}
          getPMRSurData={getPMRSurData}
          isFilterUpdated={isFilterUpdated}
          setIsFilterUpdated={setIsFilterUpdated}
          getDatawithDataGridFilter={getDatawithDataGridFilter}
          dataGridFilter={dataGridFilter}
        />
      </Box>

      <NewFilters
        data={pmrSurFilters}
        applyFilters={handleFilterApply}
        isLoading={isLoading}
        selectedFilters={pmrState.availableFilters}
      />

      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}>
        <PMRSurveillanceInsights />
      </BottomDrawer>
    </>
  );
};

export default memo(PMRSurveillanceTabs);
