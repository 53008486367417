import React from 'react';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// styles
import ApplicationContentStyles from '../../../styles/StudyDetails.styles';

const StudyDetails = props => {
  const classes = ApplicationContentStyles();

  let study2 = [];
  const studyDetailsData = Object.keys((study2 = props.data));

  let study1 = [];
  for (let j = 0; j < studyDetailsData.length; j++) {
    study1[j] = Object.keys(props.data[studyDetailsData[j]]);
  }

  let study1Res = [];
  let study2Res = [];
  let study3Res = [];

  for (let j = 0; j < studyDetailsData.length; j++) {
    if (study1[j].length >= 1) {
      study1Res[j] = props.data[studyDetailsData[j]][0].Study_1;

      if (study1[j].includes('1')) {
        study2Res[j] = props.data[studyDetailsData[j]][1].Study_2;
      }
      if (study1[j].includes('2')) {
        study3Res[j] = props.data[studyDetailsData[j]][2].Study_3;
      }
    }
  }

  return (
    <Grid container justify='center' alignItems='center' item xs={12}>
      {props.data ? (
        <div className={classes.StudyDetailsroot}>
          <TableContainer style={{ width: '100%' }}>
            <Table className={classes.table} aria-label='simple table'>
              <TableHead>
                <TableRow className={classes.StudyDetailsheadingTR}>
                  <TableCell className={classes.StudyDetailsheadingTH}> </TableCell>
                  {study1Res && study1Res.length >= 1 && (
                    <TableCell className={classes.StudyDetailsheadingTH}>Study 1</TableCell>
                  )}

                  {study2Res && study2Res.length >= 1 && (
                    <TableCell className={classes.StudyDetailsheadingTH}>Study 2</TableCell>
                  )}
                  {study3Res && study3Res.length >= 1 && (
                    <TableCell className={classes.StudyDetailsheadingTH}>Study 3</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody className={classes.SDBody}>
                {props.data.primary_endpoint.length !== 0 ? (
                  <TableRow className={classes.StudyDetailsRow}>
                    <TableCell className={classes.StudyDetailsheading1} component='th' scope='row'>
                      Primary Endpoint
                    </TableCell>
                    {props.data.primary_endpoint.map(item => {
                      return Object.keys(item).map((key, index) => {
                        if (key === 'Study_1') {
                          return (
                            <TableCell className={classes.StudyDetailsheading}>
                              {props.data.primary_endpoint['0'].Study_1}
                            </TableCell>
                          );
                        }
                        if (key === 'Study_2') {
                          return (
                            <TableCell className={classes.StudyDetailsheading}>
                              {props.data.primary_endpoint['1'].Study_2}
                            </TableCell>
                          );
                        }
                        if (key === 'Study_3') {
                          return (
                            <TableCell className={classes.StudyDetailsheading}>
                              {props.data.primary_endpoint['2'].Study_3}
                            </TableCell>
                          );
                        }
                      });
                    })}
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell className={classes.StudyDetailsheading1} component='th' scope='row'>
                      Primary Endpoint
                    </TableCell>
                    {study1Res.length >= 1 && (
                      <TableCell className={classes.StudyDetailsheading}></TableCell>
                    )}
                    {study2Res.length >= 1 && (
                      <TableCell className={classes.StudyDetailsheading}></TableCell>
                    )}
                    {study3Res.length >= 1 && (
                      <TableCell className={classes.StudyDetailsheading}></TableCell>
                    )}
                  </TableRow>
                )}

                {props.data.secondary_endpoint_1.length !== 0 ? (
                  <TableRow className={classes.StudyDetailsRow}>
                    <TableCell className={classes.StudyDetailsheading1} component='th' scope='row'>
                      Secondary Endpoint 1
                    </TableCell>
                    {props.data.secondary_endpoint_1.map(item => {
                      return Object.keys(item).map((key, index) => {
                        if (key === 'Study_1') {
                          return (
                            <TableCell className={classes.StudyDetailsheading}>
                              {props.data.secondary_endpoint_1['0'].Study_1}
                            </TableCell>
                          );
                        }
                        if (key === 'Study_2') {
                          return (
                            <TableCell className={classes.StudyDetailsheading}>
                              {props.data.secondary_endpoint_1['1'].Study_2}
                            </TableCell>
                          );
                        }
                        if (key === 'Study_3') {
                          return (
                            <TableCell className={classes.StudyDetailsheading}>
                              {props.data.secondary_endpoint_1['2'].Study_3}
                            </TableCell>
                          );
                        }
                      });
                    })}
                  </TableRow>
                ) : (
                  <TableRow className={classes.StudyDetailsRow}>
                    <TableCell className={classes.StudyDetailsheading1} component='th' scope='row'>
                      Secondary Endpoint 1
                    </TableCell>
                    {study1Res.length >= 1 && (
                      <TableCell className={classes.StudyDetailsheading}></TableCell>
                    )}
                    {study2Res.length >= 1 && (
                      <TableCell className={classes.StudyDetailsheading}></TableCell>
                    )}
                    {study3Res.length >= 1 && (
                      <TableCell className={classes.StudyDetailsheading}></TableCell>
                    )}
                  </TableRow>
                )}

                {props.data.secondary_endpoint_2.length !== 0 ? (
                  <TableRow className={classes.StudyDetailsRow}>
                    <TableCell className={classes.StudyDetailsheading1} component='th' scope='row'>
                      Secondary Endpoint 2
                    </TableCell>
                    {props.data.secondary_endpoint_2.map(item => {
                      return Object.keys(item).map((key, index) => {
                        if (key === 'Study_1') {
                          return (
                            <TableCell className={classes.StudyDetailsheading}>
                              {props.data.secondary_endpoint_2['0'].Study_1}
                            </TableCell>
                          );
                        }
                        if (key === 'Study_2') {
                          return (
                            <TableCell className={classes.StudyDetailsheading}>
                              {props.data.secondary_endpoint_2['1'].Study_2}
                            </TableCell>
                          );
                        }
                        if (key === 'Study_3') {
                          return (
                            <TableCell className={classes.StudyDetailsheading}>
                              {props.data.secondary_endpoint_2['2'].Study_3}
                            </TableCell>
                          );
                        }
                      });
                    })}
                  </TableRow>
                ) : (
                  <TableRow className={classes.StudyDetailsRow}>
                    <TableCell className={classes.StudyDetailsheading1} component='th' scope='row'>
                      Secondary Endpoint 2
                    </TableCell>
                    {study1Res.length >= 1 && (
                      <TableCell className={classes.StudyDetailsheading}></TableCell>
                    )}
                    {study2Res.length >= 1 && (
                      <TableCell className={classes.StudyDetailsheading}></TableCell>
                    )}
                    {study3Res.length >= 1 && (
                      <TableCell className={classes.StudyDetailsheading}></TableCell>
                    )}
                  </TableRow>
                )}

                {props.data.study_type.length !== 0 ? (
                  <TableRow className={classes.StudyDetailsRow}>
                    <TableCell className={classes.StudyDetailsheading1} component='th' scope='row'>
                      Study Type
                    </TableCell>
                    {props.data.study_type.map(item => {
                      return Object.keys(item).map((key, index) => {
                        if (key === 'Study_1') {
                          return (
                            <TableCell className={classes.StudyDetailsheading}>
                              {props.data.study_type['0'].Study_1}
                            </TableCell>
                          );
                        }
                        if (key === 'Study_2') {
                          return (
                            <TableCell className={classes.StudyDetailsheading}>
                              {props.data.study_type['1'].Study_2}
                            </TableCell>
                          );
                        }
                        if (key === 'Study_3') {
                          return (
                            <TableCell className={classes.StudyDetailsheading}>
                              {props.data.study_type['2'].Study_3}
                            </TableCell>
                          );
                        }
                      });
                    })}
                  </TableRow>
                ) : (
                  <TableRow className={classes.StudyDetailsRow}>
                    <TableCell className={classes.StudyDetailsheading1} component='th' scope='row'>
                      Study Type
                    </TableCell>
                    {study1Res.length >= 1 && (
                      <TableCell className={classes.StudyDetailsheading}></TableCell>
                    )}
                    {study2Res.length >= 1 && (
                      <TableCell className={classes.StudyDetailsheading}></TableCell>
                    )}
                    {study3Res.length >= 1 && (
                      <TableCell className={classes.StudyDetailsheading}></TableCell>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div></div>
      )}
    </Grid>
  );
};

export default StudyDetails;
