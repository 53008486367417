import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

import LabelCompareIcon from '../../../Images/labelCompareBW.svg';

import DraftableComparisonView from '../../../components/Comparison/DraftableComparisonView';

import comparisonClasses from '../../../components/Comparison/styles/LabelSelection.style';

import { getLabelComparision } from '../../../api/pages/Reg360';
import getComparisonFile from '../../../helpers/draftableUtils';
import trimText from '../../../utils/trimText';

const ComparisonDialog: React.FC<any> = ({ open, compareItems, onNewCompare, onClose }) => {
  const [viewURL, setViewerURL] = useState('');
  useEffect(() => {
    (async () => {
      if (compareItems.length === 2 && open) {
        const res = await getLabelComparision({
          parent_object: compareItems[0]?.pdfLink,
          child_object: compareItems[1]?.pdfLink
        });
        if (res.data.success !== null) {
          setViewerURL(res.data.success);
        }
      }
    })();
  }, [compareItems, open]);

  const downloadComparison = () => {
    try {
      const identifierInURL = viewURL?.split('/')[7];
      const identifier = identifierInURL?.split('?')[0];
      getComparisonFile(identifier);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      aria-labelledby='customized-dialog-title'
      open={open}
      onClose={onClose}>
      <Box display='flex' justifyContent='center' alignItems='flex-start'>
        <Box
          sx={{ ...comparisonClasses.LabelTitle, width: '100%' }}
          display='flex'
          justifyContent='center'>
          <img style={comparisonClasses.compareIcon} alt='compareLogo' src={LabelCompareIcon} />
          CANADA CONSULTATION COMPARISON
        </Box>
        <Box>
          <IconButton aria-label='delete' onClick={onClose} sx={{ fontSize: '40px' }}>
            <CancelIcon fontSize='inherit' />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        <DraftableComparisonView
          viewerURL={viewURL}
          showNewCompare
          onNewCompare={onNewCompare}
          downloadFile={downloadComparison}
          parentApplicationNumber={trimText(compareItems[0]?.title, 35)}
          childApplicationNumber={trimText(compareItems[1]?.title, 35)}
          parentApplicationName={compareItems[0]?.title}
          childApplicationName={compareItems[1]?.title}
        />
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(ComparisonDialog);
