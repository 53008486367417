import React from 'react';
import { MenuItem, ListItemIcon, ListItemText, Typography, Stack } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import { FilledRadioIcon, EmptyRadioIcon } from '../../../assets/svgs/Icons';

import { RoundedMenu } from '../../../components/ui/Menu';

import { NOTIFICATIONS_PREFERENCES } from './constants';

import styles from '../styles/Moxo.styles';

interface NotificationMenuItemProps {
  value: string;
  label: string;
  currentPreference: string;
  handleClick: any;
}

const NotificationMenuItem: React.FC<NotificationMenuItemProps> = ({
  value,
  label,
  currentPreference,
  handleClick
}) => (
  <MenuItem onClick={() => handleClick(value)}>
    <ListItemIcon>
      {currentPreference === value ? (
        <FilledRadioIcon sx={styles.radioIcon} />
      ) : (
        <EmptyRadioIcon sx={styles.radioIcon} />
      )}
    </ListItemIcon>
    <ListItemText
      primary={
        <Typography variant='body1' sx={styles.labelText}>
          {label}
        </Typography>
      }
    />
  </MenuItem>
);

interface NotificationMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  currentPreference: string;
  onMenuItemClick: any;
}

const NotificationMenu: React.FC<NotificationMenuProps> = ({
  anchorEl,
  open,
  onClose,
  currentPreference,
  onMenuItemClick
}) => (
  <RoundedMenu
    id='notification-preferences-menu'
    MenuListProps={{ 'aria-labelledby': 'notification-preferences-menu' }}
    sx={{ maxWidth: 260 }}
    anchorEl={anchorEl}
    open={open}
    onClose={onClose}>
    {NOTIFICATIONS_PREFERENCES.map(item => (
      <NotificationMenuItem
        key={item.value}
        value={item.value}
        label={item.label}
        currentPreference={currentPreference}
        handleClick={onMenuItemClick}
      />
    ))}

    <Stack mt={1} px={2} direction='row' spacing={1} alignItems='flex-start'>
      <CircleIcon sx={styles.circleIcon} />
      <Typography variant='body1' sx={styles.infoText}>
        Notification settings apply to all new conversations.
      </Typography>
    </Stack>
    <Stack px={2} direction='row' spacing={1} alignItems='flex-start'>
      <CircleIcon sx={styles.circleIcon} />
      <Typography variant='body1' sx={styles.infoText}>
        To customize notifications for specific conversations, modify settings in each
        conversation&apos;s menu
      </Typography>
    </Stack>
  </RoundedMenu>
);

export default NotificationMenu;
