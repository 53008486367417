import { Box, Paper, Stack, Typography } from '@mui/material';
import { memo, useContext, useEffect, useState } from 'react';
import { formatDistance } from 'date-fns';
import UserProfileStore from '../../../store/UserProfileV2';
import ListView from '../Components/ListView';

const JourneyView = ({ date }: any) => {
  const [listData, setListData] = useState({});
  const [loading, setLoading] = useState(true);
  // @ts-ignore
  const { profileState } = useContext(UserProfileStore) as any;

  // eslint-disable-next-line no-shadow
  const handleQuickSearch = (item: any, data: any, searchTermList: any, ariaSearch: any) => {
    let search = item;
    if (ariaSearch) {
      search = ariaSearch;
    }
    if (item && item.term) {
      const term = item.term.toLowerCase().trim();
      if (term in data) {
        data[term].push(search);
      } else {
        // eslint-disable-next-line no-param-reassign
        data[term] = [search];
        searchTermList.push(term);
      }
    }
  };
  // eslint-disable-next-line no-shadow
  const handleAdvancedSearch = (item: any, data: any, searchTermList: any, ariaSearch: any) => {
    let search = item;

    if (ariaSearch) {
      search = ariaSearch;
    }
    // eslint-disable-next-line no-unused-vars
    Object.entries(item.searchData).forEach(([key, value]: any) => {
      if (Array.isArray(value)) {
        value.forEach((val: any) => {
          const term = val.toLowerCase().trim();
          if (term in data) {
            data[term].push(search);
          } else {
            // eslint-disable-next-line no-param-reassign
            data[term] = [search];
            searchTermList.push(term);
          }
        });
      }
    });
  };
  const handleAriaSearch = (item: any, data: any, searchTermList: any) => {
    if ('searchOperationData' in item) {
      if (item.searchOperationData.type === 'quickSearch') {
        handleQuickSearch(item.searchOperationData, data, searchTermList, item);
      }
      if (item.searchOperationData.type === 'advancedSearch') {
        handleAdvancedSearch(item.searchOperationData, data, searchTermList, item);
      }
    } else {
      const term = item.term.toLowerCase().trim();
      if (Object.keys(data).length === 0) {
        data[term] = [item];
        searchTermList.push(term);
      } else {
        Object.keys(data).forEach(key => {
          if (key === term) {
            data[term].push(item);
          } else {
            // eslint-disable-next-line no-param-reassign
            data[term] = [item];
            searchTermList.push(term);
          }
        });
      }
    }
  };
  useEffect(() => {
    if (profileState.dateGroupedUserActivity && profileState.dateGroupedUserActivity[date]) {
      setLoading(true);
      const localData: any = {};
      const searchTermList: any = [];
      profileState.dateGroupedUserActivity[date].forEach((item: any) => {
        switch (item.type) {
          case 'quickSearch':
            // @ts-ignore
            handleQuickSearch(item, localData, searchTermList);
            break;
          case 'advancedSearch':
            // @ts-ignore
            handleAdvancedSearch(item, localData, searchTermList);
            break;
          case 'ariaSearch':
            // @ts-ignore
            handleAriaSearch(item, localData, searchTermList);
            break;
          default:
            break;
        }
      });

      setListData(localData);
      setLoading(false);
    }
  }, [profileState.dateGroupedUserActivity, date]);
  if (loading) {
    return <Box>Loading...</Box>;
  }
  return (
    <>
      {Object.entries(listData).map(([key, value]) => (
        <Paper elevation={3} sx={{ width: '99%', m: ' 2em auto', p: 2 }} key={key}>
          <Stack justifyContent='space-between' direction='row' alignItems='flex-end'>
            <Typography variant='h3' component='div'>
              " {key} "
            </Typography>
            <Typography component='div'>
              {
                // @ts-ignore
                formatDistance(new Date(value[0].date), new Date(), {
                  includeSeconds: true,
                  addSuffix: true
                })
              }
            </Typography>
          </Stack>
          <ListView
            data={
              // @ts-ignore
              value.reverse()
            }
            hideTimeline
            showTime
            smallIcon
          />
        </Paper>
      ))}
    </>
  );
};
export default memo(JourneyView);
