import { Box, Button, Collapse, Divider, Stack, Typography } from '@mui/material';

import React, { useCallback, useContext } from 'react';
import { Refresh, Remove } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { isEmpty } from 'lodash';
import { SOURCE_WITH_REG_360 } from '../constants';
import notificationsDetailStyles from './NotificationsDetail.styles';
import { SubNotification } from './NotificationInterface';
import NotificationDetailsHeader from './NotificationDetailsHeader';

// store
import NotificationsStore from '../../../store/Notifications';

const NotificationDetails = () => {
  const { notificationsState } = useContext(NotificationsStore);
  const { selectedNotification } = notificationsState;

  const [openedDocument, setOpenedDocument] = React.useState<string>('');

  const handleNotificationsReg360 = (applNo: string, subSource: string) => {
    const num = encodeURIComponent(applNo);
    window.open(`/regulatory360/core/${num}/${subSource?.toLowerCase()}`);
  };

  const isSubNotificationClickable = (entityId: string, subSource: string) => {
    return (
      (!isEmpty(entityId) && SOURCE_WITH_REG_360.includes(subSource)) ||
      subSource === 'adcomm' ||
      subSource === 'chmp'
    );
  };

  const handleSubNotificationClick = (entityId: string, subSource: string) => {
    if (isEmpty(entityId)) return;
    if (SOURCE_WITH_REG_360.includes(subSource)) {
      handleNotificationsReg360(entityId, subSource);
    } else if (subSource === 'adcomm') {
      window.open(`/adcomm?committee=ALL&selectedtab=meetings&meetingId=${entityId}`);
    } else if (subSource === 'chmp') {
      window.open(`/chmp/meetings?meetingId=${entityId}`);
    }
  };

  const getChangeTypeIcon = (changeType: string) => {
    switch (changeType) {
      case 'add':
        return <AddIcon />;
      case 'update':
        return <Refresh />;
      case 'delete':
        return <Remove />;
      default:
        return <AddIcon />;
    }
  };

  const SubNotificationUI = useCallback(
    (data: SubNotification) => (
      <Stack
        direction='row'
        key={`${data.id}-${data.source}-${data.entity_id}-${data.change_type}`}
        onClick={() => handleSubNotificationClick(data?.entity_id ?? '', data?.source ?? '')}
        sx={
          isSubNotificationClickable(data?.entity_id ?? '', data?.source ?? '')
            ? notificationsDetailStyles.detailsWithHover
            : notificationsDetailStyles.details
        }>
        <Box sx={notificationsDetailStyles.changeTypeIcon}>
          {getChangeTypeIcon(data?.change_type)}
        </Box>
        <Typography sx={notificationsDetailStyles.message}>{data?.message}</Typography>
      </Stack>
    ),
    []
  );

  const NotificationsDetailsList = useCallback(
    (notificationTitle: string, notifications: SubNotification[], optionalCount?: number) => (
      <Stack
        key={`${notificationTitle}-${notifications.length}`}
        sx={notificationsDetailStyles.detailContainer}>
        <Typography sx={notificationsDetailStyles.detailHeader}>
          {notificationTitle} ({optionalCount ?? notifications.length})
        </Typography>
        <Stack>
          {notifications.slice(0, 2).map(data => SubNotificationUI(data))}
          {notifications.length > 2 && (
            <Collapse in={openedDocument === notificationTitle} timeout='auto' unmountOnExit>
              {notifications.slice(2).map(data => SubNotificationUI(data))}
            </Collapse>
          )}
        </Stack>
        {notifications.length > 2 && (
          <Button
            sx={notificationsDetailStyles.showMoreButton}
            onClick={() =>
              setOpenedDocument(openedDocument === notificationTitle ? '' : notificationTitle)
            }>
            {openedDocument === notificationTitle
              ? 'Show Less'
              : `Show More (${notifications.length - 2})`}
          </Button>
        )}
      </Stack>
    ),
    [openedDocument]
  );

  return (
    <>
      <NotificationDetailsHeader notification={selectedNotification} />
      <Box height='100%' overflow='auto'>
        {Object.entries(selectedNotification?.notifications ?? {})
          .filter(
            ([key, value]) => !key.toLowerCase().startsWith('documents') && Array.isArray(value)
          )
          .map(([key, value]) => {
            let count;
            if (selectedNotification?.source === 'ct' && key === 'Trials')
              count = selectedNotification?.count_info?.[key];
            return NotificationsDetailsList(key, value as SubNotification[], count);
          })}
        {selectedNotification?.notifications?.Documents && (
          <Stack sx={notificationsDetailStyles.detailContainer}>
            <Typography sx={notificationsDetailStyles.detailHeader}>
              Documents ({selectedNotification?.count_info?.Documents})
            </Typography>
            <Divider
              sx={{
                mt: '3px'
              }}
            />
            <Stack>
              {selectedNotification?.notifications?.Documents?.map(documentData => {
                return Object.entries(documentData).map(([key, value]) =>
                  NotificationsDetailsList(key, value)
                );
              })}
            </Stack>
          </Stack>
        )}
      </Box>
    </>
  );
};
export default NotificationDetails;
