// @ts-nocheck
import React, { useCallback, useContext } from 'react';
import uniqueId from 'lodash/uniqueId';

// mui
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

// child components
import StatsCountTile from '../../../helpers/baseComponents/StatsCountTile';

// other components
import CustomBarGraph from '../../CustomComponents/Graphs/CustomBarGraph';
import CustomPieChart from '../../CustomComponents/Graphs/CustomPieChart';

// styles
import styles from '../styles/Insights.styles';

// context
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import PMRSurveillance from '../../../store/PMRSurveillance';
import { PIECHARTCOLOR } from '../../CustomComponents/Graphs/constant';

import { PMR_SURVEILLANCE_QUERIES } from '../const';

const Insights = () => {
  const { state, dispatch } = useContext(GlobalStore) as any;
  const { pmrState } = useContext(PMRSurveillance) as any;

  const addToReport = useCallback(
    async (id: string) => {
      const addedReport = PMR_SURVEILLANCE_QUERIES.find(report => report.id === id);
      const random = Math.floor(Math.random() * 1000);
      dispatch({
        type: GlobalActions.ADD_TEMPLATE_TO_REPORT,
        value: {
          templateType: 'custom',
          chartType: addedReport?.graph,
          sectionType: 'CHART',
          id: uniqueId(`pmr_sur_${random}_`),
          style: {
            placement: {
              h: 40,
              w: 12
            },
            graphStyle: addedReport?.graphStyle,
            title: addedReport?.title
          },
          data: {
            source: 'pmr',
            search_type: 'surveillance',
            disableSelectionSource: true,
            filters: pmrState.availableFilters,
            query: addedReport?.query
          }
        }
      });
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Successfully added to Report.', color: 'success' }
      });
    },
    [state.cartItems, dispatch, pmrState.availableFilters]
  );

  return (
    <Stack>
      <Grid container>
        <Grid item xs={12} lg={3}>
          {Object.keys(pmrState.pmrStats).length > 0 && !pmrState.Loading ? (
            <>
              <StatsCountTile text='Total' count={pmrState.pmrStats.total_count} />
              <StatsCountTile
                text='Issued in Last 12 Months'
                count={pmrState.pmrStats.issued_last_twelve_month}
              />
              <StatsCountTile
                text='Expected Completion in 12 Months'
                count={pmrState.pmrStats.expected_in_twelve_months}
              />
            </>
          ) : (
            <Box ml={5} display='flex' flexDirection='column' sx={styles.cards}>
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box display='flex' flexDirection='column' sx={styles.box}>
            <Box display='flex' flexDirection='column'>
              <Box display='flex' justifyContent='space-between'>
                {Object.keys(pmrState.pmrStats).length > 0 && !pmrState.loading ? (
                  <>
                    <Typography sx={styles.graphHeading}>
                      Number of PMRs/PMCs by Categories
                    </Typography>
                    <Button
                      onClick={() => addToReport('pmrs_by_category')}
                      variant='text'
                      fullWidth={false}
                      sx={styles.addToReport}>
                      <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                        + Add to Report
                      </Typography>
                    </Button>
                  </>
                ) : (
                  <>
                    <Skeleton height={32} width={200} sx={styles.graphHeading} />
                    <Skeleton height={28} width={150} sx={styles.reportText} />
                  </>
                )}
              </Box>
              <Box
                p={2}
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={styles.barGraph}>
                {!pmrState.loading ? (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {Object.keys(pmrState.pmrStats?.category_graph).length > 0 ? (
                      <CustomBarGraph
                        keys={['number']}
                        index='x'
                        data={pmrState.pmrStats.category_graph}
                        paddings={0.8}
                        colors={['#B1BBE4']}
                        tickRotationValue={25}
                        labelTextColor='#ffffff'
                        axisText='#A3A3A3'
                      />
                    ) : (
                      <Typography variant='subtitle2' sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                        No data
                      </Typography>
                    )}
                  </>
                ) : (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-end'
                    alignItems='space-between'
                    sx={styles.loaderBox}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      flexWrap='wrap-reverse'
                      sx={styles.barGraphRect}>
                      <Skeleton variant='rectangular' height='90%' width='.2%' />
                      <Skeleton variant='rectangular' height='60%' width='3%' />
                      <Skeleton variant='rectangular' height='80%' width='3%' />
                      <Skeleton variant='rectangular' height='30%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='60%' width='3%' />
                      <Skeleton variant='rectangular' height='80%' width='3%' />
                      <Skeleton variant='rectangular' height='30%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='90%' width='3%' />
                      <Skeleton variant='rectangular' height='60%' width='3%' />
                      <Skeleton variant='rectangular' height='80%' width='3%' />
                    </Box>
                    <Skeleton variant='rectangular' height='.2%' width='100%' />
                  </Box>
                )}
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' sx={styles.pieCharts}>
              <Box sx={styles.pieContainers} display='flex' flexDirection='column'>
                <Box display='flex' justifyContent='space-between'>
                  {Object.keys(pmrState.pmrStats).length > 0 && !pmrState.loading ? (
                    <>
                      <Typography sx={styles.graphHeading}>PMR / PMC Status</Typography>
                      <Button
                        onClick={() => addToReport('status')}
                        variant='text'
                        fullWidth={false}
                        sx={styles.addToReport}>
                        <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                          + Add to Report
                        </Typography>
                      </Button>
                    </>
                  ) : (
                    <>
                      <Skeleton height={32} width={200} sx={styles.graphHeading} />
                      <Skeleton height={24} width={150} sx={styles.reportText} />
                    </>
                  )}
                </Box>
                {!pmrState.loading ? (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {Object.keys(pmrState.pmrStats).length > 0 ? (
                      <CustomPieChart
                        data={pmrState.pmrStats.cmt_status_graph}
                        innerRadius={0.6}
                        colors={PIECHARTCOLOR}
                        margin={styles.pieChartMargin}
                      />
                    ) : (
                      'No data'
                    )}
                  </>
                ) : (
                  <Box display='flex' alignSelf='center' pt={4}>
                    <CircularProgress
                      value={90}
                      thickness={5}
                      size='12rem'
                      variant='indeterminate'
                      sx={{
                        color: '#adadac',
                        opacity: 0.5
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Box sx={styles.pieContainers} display='flex' flexDirection='column'>
                <Box display='flex' justifyContent='space-between'>
                  {Object.keys(pmrState.pmrStats).length > 0 && !pmrState.loading ? (
                    <>
                      <Typography sx={styles.graphHeading}>Subpart</Typography>
                      <Button
                        onClick={() => addToReport('subpart_desc')}
                        variant='text'
                        fullWidth={false}
                        sx={styles.addToReport}>
                        <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                          + Add to Report
                        </Typography>
                      </Button>
                    </>
                  ) : (
                    <>
                      <Skeleton height={32} width={200} sx={styles.graphHeading} />
                      <Skeleton height={24} width={150} sx={styles.reportText} />
                    </>
                  )}
                </Box>
                {!pmrState.loading ? (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {Object.keys(pmrState.pmrStats).length > 0 ? (
                      <CustomPieChart
                        data={pmrState.pmrStats.subpart_desc_graph}
                        innerRadius={0.6}
                        colors={PIECHARTCOLOR}
                        margin={styles.pieChartMargin}
                      />
                    ) : (
                      'No Data'
                    )}
                  </>
                ) : (
                  <Box display='flex' alignSelf='center' pt={4}>
                    <CircularProgress
                      value={90}
                      thickness={5}
                      size='12rem'
                      variant='indeterminate'
                      sx={{
                        color: '#adadac',
                        opacity: 0.5
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default React.memo(Insights);
