import {
  Stack,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Typography,
  Dialog,
  Button,
  CircularProgress
} from '@mui/material';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CloseIcon } from '../../assets/svgs/Icons';
import styles from './styles/PDFComparisonDialog.style';

const DownloadLoadingModal = ({
  docLoader,
  setDocLoader,
  progress,
  errorMsg,
  setErrorMsg,
  downloadType,
  handleDownloadComparison,
  handleCancelCall
}: any) => {
  return (
    <Dialog
      open={docLoader}
      keepMounted={false}
      maxWidth='xs'
      onClose={() => {
        if (progress === 100) {
          setDocLoader(false);
        }
      }}>
      <DialogTitle id='alert-dialog-title'>
        <Stack sx={styles.loaderWrapper}>
          <Box>
            {(progress === 100 || errorMsg) && (
              <IconButton
                aria-label='close'
                onClick={() => {
                  setDocLoader(false);
                }}
                sx={styles.loaderCloseIcon}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          <Box
            sx={{
              position: 'relative',
              display: 'inline-flex',
              justifyContent: 'center',
              width: 200,
              height: 200
            }}>
            <CircularProgress
              variant='determinate'
              value={100}
              size={200}
              thickness={3}
              sx={{
                ...styles.circularProgress,
                ...{ color: 'gray.background' }
              }}
            />
            {!errorMsg && (
              <CircularProgress
                variant={progress === 100 ? 'determinate' : 'indeterminate'}
                value={100}
                color={!errorMsg ? 'primary' : 'error'}
                size={200}
                thickness={3}
                sx={styles.circularProgress}
              />
            )}

            <Box sx={styles.loaderPercentWrapper}>
              {errorMsg ? (
                <ErrorOutlineIcon />
              ) : (
                <Typography
                  display='flex'
                  variant='caption'
                  component='div'
                  sx={styles.percentTypography}>
                  <span>{`${Math.round(progress)}`}</span>
                  <span style={styles.percentText}>%</span>
                </Typography>
              )}
            </Box>
          </Box>
          <Typography ml={1} sx={styles.loaderStatusText}>
            {!errorMsg
              ? `${progress !== 100 ? 'Downloading...' : 'Download Completed !'}`
              : `Download Failed`}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box sx={styles.dialogContentWrapper}>
          <Typography sx={{ fontSize: '16px', color: errorMsg ? 'red' : 'black' }}>
            {!errorMsg
              ? `${
                  progress !== 100
                    ? `It may take a few seconds to compile section summary.`
                    : 'Label Section Summary file has been downloaded successfully'
                }`
              : `Apologies! An error has occurred in downloading.Please try again`}
          </Typography>

          <Button
            variant='contained'
            color='secondary'
            sx={styles.loaderButton}
            onClick={() => {
              if (errorMsg) {
                setErrorMsg(false);
                handleDownloadComparison(downloadType);
              } else if (progress !== 100) {
                handleCancelCall();
              } else {
                setDocLoader(false);
              }
            }}>
            {
              // eslint-disable-next-line no-nested-ternary
              `${errorMsg ? 'Try Again' : progress !== 100 ? 'Cancel' : 'Done'}`
            }
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadLoadingModal;
