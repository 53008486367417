import React from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import ExpandedCard from './ExpandedCard';
import {
  verticalDesignationCard,
  verticalDesignationCardContainer,
  styles
} from '../styles/verticalDesignationCard';

const variantStyles = {
  red: {
    appNum: styles.appNumRed,
    name: styles.nameRed
  },
  blue: {
    appNum: styles.appNumBlue,
    name: styles.nameBlue
  }
};

const VerticalDesignationCard = ({
  number,
  tradeName,
  activeIngredients,
  sponsorName,
  indications,
  route,
  formulation,
  specialityDesignation,
  marketingStatus = 'GREEN',
  variant,
  type,
  compactMode = false
}: VerticalDesignationCardProps) => {
  const [expandCard, setExpandCard] = React.useState(false);
  const handleClose = () => {
    setExpandCard(false);
  };
  const strikeText = marketingStatus?.toLowerCase().trim() !== 'green';
  // @ts-ignore
  return (
    <>
      <ExpandedCard
        open={expandCard}
        onClose={handleClose}
        payload={{
          number,
          tradeName,
          activeIngredients,
          sponsorName,
          indications,
          route,
          formulation,
          specialityDesignation,
          selectedDesignationType: type,
          variant,
          strikeText
        }}
      />

      <Stack
        direction='column'
        sx={verticalDesignationCardContainer}
        onClick={() => {
          setExpandCard(!expandCard);
        }}>
        {specialityDesignation && specialityDesignation.length > 1 && (
          <Tooltip title='Supplements'>
            <Box sx={verticalDesignationCard}>{specialityDesignation.length}</Box>
          </Tooltip>
        )}
        <Box
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          flexWrap={compactMode ? 'wrap' : 'nowrap'}>
          <Typography
            sx={{
              // @ts-ignore
              ...variantStyles[variant].appNum,
              textDecoration: strikeText ? 'line-through' : 'none'
            }}>
            {number}
          </Typography>
          <Tooltip title={tradeName as string}>
            <Typography
              sx={{
                // @ts-ignore
                ...variantStyles[variant].name,
                overflow: expandCard ? 'visible' : 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                textDecoration: strikeText ? 'line-through' : 'none'
              }}>
              {tradeName}
            </Typography>
          </Tooltip>
        </Box>
      </Stack>
    </>
  );
};

export default React.memo(VerticalDesignationCard);
