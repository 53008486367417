/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useContext, useState, useCallback, useEffect, useMemo } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Stack,
  Box,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Divider,
  IconButton,
  Switch,
  Radio
} from '@mui/material';
import DocumentCountIcon from '../../../assets/svgs/CardsPage/DocumentCountIcon';
import PageCountIcon from '../../../assets/svgs/CardsPage/PageCountIcon';

// api
import {
  getDocumentsSignedURL,
  getDrugDevelopmentToolsProject
} from '../../../api/pages/DrugDevelopmentTools';

// utils

import formatCount from '../../../utils/formatCount';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';

// Icons
import PDFPreview from '../../../components/PdfPreview/PDFPreview';
import { FolderIcon } from '../../../assets/svgs/Icons';

// styles
import styles from '../styles/DocumentHistory.styles';
import mapEntityData from '../utils/mapEntityData';

// store
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

import ComparisonButtons from './ComparisonButtons';
import CompareDialog from './CompareDialog';
import trimText from '../../../utils/trimText';

const GridCellDocuments: React.FC<any> = ({ data }) => {
  const [linkData, setLinkData] = useState<Array<any>>([]);
  const [pdfUrl, setPdfUrl] = useState('');
  const [selectedRowData, setSelectdRowData] = useState({}) as any;
  const [compareEnabled, setCompareEnabled] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [documentsToCompare, setDocumentsToCompare] = useState<Array<any>>([]);
  const [documents, setDocuments] = useState<Array<any>>([]);
  const [openComparisonPopup, setOpenComparisonPopup] = useState<boolean>(false);

  const [title, setTitle] = useState<string>('');
  const { dispatch } = useContext(GlobalStore) as any;

  const getData = async () => {
    const res = await getDrugDevelopmentToolsProject(data.project_number);
    setLinkData(res?.data?.body ?? []);
  };

  const handleOpenPopup = (e: React.SyntheticEvent) => {
    e.preventDefault();
    getData();
    setOpen(true);
  };

  const handleClosePopup = () => {
    setOpen(false);
  };

  const handleSignedURL = useCallback(async (pdfLink: string) => {
    const res: any = await getDocumentsSignedURL({
      source: 'dd-tools',
      s3_path: pdfLink
    });
    return res?.data?.body?.s3_url ?? '';
  }, []);

  // eslint-disable-next-line no-shadow
  const handleChatRia = ({ pdfUrl = '' }) => {
    const mappedSourceName = 'dd-tools';
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: mappedSourceName
    });

    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: {
          stage_date: selectedRowData?.stage_date ?? '',
          project_number: data?.project_number ?? '',
          _category: selectedRowData?.submission_stage_type ?? '',
          title: title ?? ''
        },
        source: mappedSourceName,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    setPdfUrl('');
    handleClosePopup();
  };

  const handleDocumentClick = async (row: any) => {
    if (row?.doc_id && row?.doc_id !== 0) {
      setSelectdRowData(row);
      setPdfUrl(await handleSignedURL(row?.s3_path));
      setTitle(row?.title);
    }
  };

  const handleCompareClick = useCallback(() => {
    if (compareEnabled) {
      setCompareEnabled(false);
      setDocumentsToCompare([]);
    } else {
      setCompareEnabled(true);
    }
  }, [compareEnabled]);

  useEffect(() => {
    if (!linkData) {
      return;
    }

    const finalDocuments = linkData.filter((doc: any) => doc?.doc_id !== 0);

    setDocuments(finalDocuments);
  }, [linkData]);

  const handleComparisonPopup = useCallback((value: boolean) => {
    setOpenComparisonPopup(value);
  }, []);

  const compareItems: Array<any> = useMemo(
    () =>
      documentsToCompare.map((doc: any) => ({
        title: trimText(`${doc.title.replace(/ \([\s\S]*?\)/g, '')}`, 35),
        pdfLink: doc.s3_path
      })),
    [documentsToCompare]
  );

  const clearComparison = useCallback(() => {
    setDocumentsToCompare([]);
    setOpenComparisonPopup(false);
  }, []);

  const handleComparisonCheckbox = useCallback(
    (id: number) => {
      if (documentsToCompare.find((doc: any) => doc.doc_id === id)) {
        const removeDocument = documentsToCompare.filter((doc: any) => doc.doc_id !== id);
        setDocumentsToCompare([...removeDocument]);
      } else {
        if (
          documentsToCompare.length === 2 &&
          !documentsToCompare.find((doc: any) => doc.doc_id === id)
        ) {
          documentsToCompare.pop();
        }

        const addDocument = [...documentsToCompare, linkData.find((doc: any) => doc.doc_id === id)];
        setDocumentsToCompare(addDocument);
      }
    },
    [documentsToCompare, linkData]
  );

  return (
    <Box sx={styles.textAlignment}>
      <Box alignItems='center' sx={{ cursor: 'pointer' }}>
        {data && (
          <Stack
            direction='row'
            spacing={0.5}
            sx={styles.linkItem}
            alignItems='center'
            onClick={e => handleOpenPopup(e)}>
            <FolderIcon sx={{ fontSize: 12 }} />
            <Typography variant='subtitle2' sx={styles.pdfCellText}>
              {data?.document_count}
            </Typography>
          </Stack>
        )}
      </Box>
      <Dialog
        fullWidth
        maxWidth='lg'
        aria-labelledby='customized-dialog-title'
        open={open}
        sx={styles.dialog}
        onClose={handleClosePopup}>
        <DialogTitle>
          <Grid display='flex' justifyContent='space-between' alignItems='flex-start'>
            <Grid sx={styles.heading} display='flex' justifyContent='center'>
              Documents
            </Grid>
            <Stack
              display='flex'
              flexDirection='row'
              sx={{
                height: '40px',
                padding: '1px 28px',
                borderRadius: '10px',
                bgcolor: 'white.main'
              }}>
              <Stack direction='row' spacing={2}>
                <DocumentCountIcon />
                <Stack>
                  <Typography variant='subtitle2'>Documents</Typography>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Tooltip title={formatCount(data?.document_count)}>
                      <Typography variant='subtitle1' fontWeight='bold'>
                        {truncateCountAboveMillions(data?.document_count)}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Stack>
                <PageCountIcon />
                <Stack>
                  <Typography variant='subtitle2'>Pages</Typography>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Tooltip title={formatCount(data?.document_total_page_count)}>
                      <Typography variant='subtitle1' fontWeight='bold'>
                        {truncateCountAboveMillions(data?.document_total_page_count)}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Box display='flex' flexDirection='row'>
              {documents.length > 1 && (
                <Stack
                  spacing={1}
                  alignItems='center'
                  direction='row'
                  sx={{ cursor: 'pointer', paddingRight: '50px' }}
                  onClick={handleCompareClick}>
                  <Switch checked={compareEnabled} />
                  <Typography
                    id='filters'
                    sx={{ justifySelf: 'flex-end', color: 'gray.lightVariant' }}
                    variant='subtitle1'>
                    Comparison Mode {compareEnabled}
                  </Typography>
                </Stack>
              )}
              <IconButton aria-label='delete' onClick={handleClosePopup} sx={{ fontSize: '30px' }}>
                <CancelIcon fontSize='inherit' />
              </IconButton>
            </Box>
          </Grid>
          <Divider />
        </DialogTitle>
        <DialogContent sx={{ height: '94%' }}>
          <Stack spacing={1}>
            {openComparisonPopup && (
              <CompareDialog
                open={openComparisonPopup}
                onClose={() => handleComparisonPopup(false)}
                compareItems={compareItems}
                onNewCompare={clearComparison}
              />
            )}
            {compareEnabled && (
              <ComparisonButtons
                documentsToCompare={documentsToCompare}
                removeFromComparison={handleComparisonCheckbox}
                openComparisonPopup={handleComparisonPopup}
                clearComparison={clearComparison}
              />
            )}
            <Stack spacing={1}>
              {linkData && (
                <Stack spacing={1} display='flex' flexDirection='row' sx={{ width: '100%' }}>
                  <Box sx={{ width: '15%' }}>
                    <Box
                      sx={{
                        color: 'primary.dark',
                        padding: '13px 10px',
                        marginBottom: 'auto'
                      }}>
                      <Typography
                        sx={{ fontSize: '16px', color: 'black.darkVariant', fontWeight: '600' }}>
                        Documents
                      </Typography>
                    </Box>
                  </Box>
                  <Stack sx={{ width: '100%' }}>
                    <>
                      {linkData.map((row: any) => {
                        return (
                          <Stack key={row.doc_id} spacing={1}>
                            <Box
                              display='flex'
                              flexDirection='column'
                              sx={{
                                padding: '12px 10px',
                                borderRadius: 8,
                                backgroundColor: 'primary.background'
                              }}>
                              <Stack direction='row' spacing={1}>
                                <Box>
                                  {compareEnabled && row?.doc_id !== 0 && (
                                    <Tooltip
                                      title={
                                        documentsToCompare.length === 2 &&
                                        !documentsToCompare.find(
                                          (doc: any) => doc.doc_id === row.doc_id
                                        )
                                          ? 'Selection limit reached'
                                          : ''
                                      }>
                                      <Box>
                                        <Radio
                                          disabled={
                                            documentsToCompare.length === 2 &&
                                            !documentsToCompare.find(
                                              (doc: any) => doc.doc_id === row.doc_id
                                            )
                                          }
                                          checked={
                                            !!documentsToCompare.find(
                                              (doc: any) => doc.doc_id === row.doc_id
                                            )
                                          }
                                          onClick={() => handleComparisonCheckbox(row.doc_id)}
                                        />
                                      </Box>
                                    </Tooltip>
                                  )}
                                </Box>
                                <Box
                                  sx={{
                                    ...styles.pdfIconWrapper,
                                    visibility: row?.doc_id !== 0 ? 'visible' : 'hidden'
                                  }}>
                                  <img
                                    src='https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/pdf_icon.png'
                                    alt='PDF download'
                                    height='100%'
                                    width='100%'
                                  />
                                </Box>
                                <Stack>
                                  <Stack
                                    direction='row'
                                    alignItems='center'
                                    onClick={() => handleDocumentClick(row)}>
                                    <Typography
                                      sx={
                                        row?.doc_id && row?.doc_id !== 0
                                          ? styles.title
                                          : styles.titleWithoutLink
                                      }>
                                      {row?.title}
                                    </Typography>
                                  </Stack>
                                  <Stack
                                    display='flex'
                                    flexDirection='row'
                                    sx={{ paddingTop: '10px' }}>
                                    {Object.entries(mapEntityData(row))
                                      ?.filter(([, value]) => !!value)
                                      ?.map(([key, value]) => {
                                        return (
                                          <Tooltip key={key} title={`${key}: ${value}`}>
                                            <Box display='flex' sx={styles.chips}>
                                              <Typography sx={{ fontSize: '12px' }}>
                                                {value}
                                              </Typography>
                                            </Box>
                                          </Tooltip>
                                        );
                                      })}
                                  </Stack>
                                </Stack>
                              </Stack>
                            </Box>
                            <Box display='flex' flexDirection='column' sx={{ marginTop: '5px' }} />
                          </Stack>
                        );
                      })}
                    </>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      {pdfUrl && (
        <Box>
          <PDFPreview
            open={!!pdfUrl}
            pdfUrl={pdfUrl}
            onClose={() => setPdfUrl('')}
            handleChatRia={handleChatRia}
            title={title}
          />
        </Box>
      )}
    </Box>
  );
};
const DocumentView = (params: any) => {
  // eslint-disable-next-line react/destructuring-assignment
  return <GridCellDocuments data={params.row} />;
};

export default DocumentView;
