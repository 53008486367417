import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  paper: {
    margin: theme.spacing(3),
    width: theme.spacing(30),
    height: '20%',
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(3)
  },
  content: {
    height: '100%'
  },
  title: {
    fontSize: 15,
    color: theme.palette.cards.grayDark,
    fontFamily: 'Mulish'
  },
  count: {
    fontSize: 22,
    fontWeight: 'bold',
    color: theme.palette.cards.grayDark,
    fontFamily: 'Mulish'
  },
  icon: {
    fontWeight: 'bold',
    color: theme.palette.adcomm.efficacy
  }
}));
