import themev5 from '../../../themev5';

const styles: any = {
  root: {
    background: '#F7F7F7',
    marginTop: themev5.spacing(2),
    marginLeft: themev5.spacing(13) + 1,
    width: `calc(100% - ${themev5.spacing(14) + 1}px)`,
    height: '75vh'
  },
  headerText: {
    marginLeft: themev5.spacing(12)
  },
  cards: {
    height: '70vh'
  },
  paper: {
    margin: 3,
    width: '50%',
    height: '50%',
    padding: 3,
    borderRadius: 3
  },

  stats: {
    borderRadius: themev5.spacing(2)
  },
  outerDiv: {
    marginTop: themev5.spacing(2),
    height: '70vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  centerDiv: {
    marginTop: themev5.spacing(2),
    height: '70vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  centerDivImpact: {
    marginTop: themev5.spacing(2),
    height: '40vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  loaderBox: {
    width: '80%',
    height: '30vh'
  },
  circleBox: {
    width: '100%'
  },
  skeltonCircle: {
    width: '100px',
    height: '100px'
  },
  barGraphRect: {
    width: '100%',
    height: '100%'
  },
  circleLoading: {
    color: '#CDC9C9',
    opacity: 0.5
  }
};

export default styles;
