// @ts-nocheck
import React, { useCallback, useContext } from 'react';
import uniqueId from 'lodash/uniqueId';

// mui
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

// child components
import StatsCountTile from '../../../helpers/baseComponents/StatsCountTile';

// other components
import CustomBarGraph from '../../CustomComponents/Graphs/CustomBarGraph';
import CustomPieChart from '../../CustomComponents/Graphs/CustomPieChart';
// styles
import styles from '../styles/Insights.styles';

// context
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import EUCTRPediatrics from '../../../store/EUCTRPediatrics';
import { EUCTR_PEDIATRICS_QUERIES } from '../const';
import { PIECHARTCOLOR } from '../../CustomComponents/Graphs/constant';

const Insights = () => {
  const { state, dispatch } = useContext(GlobalStore) as any;
  const { euctrPediatricsState } = useContext(EUCTRPediatrics) as any;

  const addToReport = useCallback(
    async (id: string) => {
      const addedReport = EUCTR_PEDIATRICS_QUERIES.find(report => report.id === id);
      const random = Math.floor(Math.random() * 1000);
      dispatch({
        type: GlobalActions.ADD_TEMPLATE_TO_REPORT,
        value: {
          templateType: 'custom',
          chartType: addedReport?.graph,
          sectionType: 'CHART',
          id: uniqueId(`euctr_pediatrics_${random}_`),
          style: {
            placement: {
              h: 40,
              w: 12
            },
            graphStyle: addedReport?.graphStyle,
            title: addedReport?.title
          },
          data: {
            source: 'euctr',
            search_type: 'paediatrics',
            disableSelectionSource: true,
            filters: euctrPediatricsState.availableFilters,
            query: addedReport?.query
          }
        }
      });
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Successfully added to Report.', color: 'success' }
      });
    },
    [state.cartItems, dispatch, euctrPediatricsState.availableFilters]
  );

  return (
    <Stack>
      <Grid container>
        {/* Stats Grid  */}
        <Grid item xs={12} lg={3}>
          {Object.keys(euctrPediatricsState.euctrPediatricsStats).length > 0 &&
          !euctrPediatricsState.Loading ? (
            <>
              <StatsCountTile
                text='Therapeutic Areas'
                count={
                  euctrPediatricsState.euctrPediatricsStats.total_unique_therapeutic_areas_count
                }
              />
              <StatsCountTile
                text='Active Substances'
                count={
                  euctrPediatricsState.euctrPediatricsStats.total_unique_active_substances_count
                }
              />
              <StatsCountTile
                text='Total'
                count={euctrPediatricsState.euctrPediatricsStats.total_count}
              />
            </>
          ) : (
            <Box ml={5} display='flex' flexDirection='column' sx={styles.cards}>
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box display='flex' flexDirection='row' sx={styles.pieCharts}>
            {/* Graph 1  */}
            <Box sx={styles.pieContainers} display='flex' flexDirection='column'>
              <Box display='flex' justifyContent='space-between'>
                {Object.keys(euctrPediatricsState.euctrPediatricsStats).length > 0 &&
                !euctrPediatricsState.loading ? (
                  <>
                    <Typography sx={styles.graphHeading}>Trials by Age Categories</Typography>
                    <Button
                      onClick={() => addToReport('age_category_graph')}
                      variant='text'
                      fullWidth={false}
                      sx={styles.addToReport}>
                      <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                        + Add to Report
                      </Typography>
                    </Button>
                  </>
                ) : (
                  <>
                    <Skeleton height={32} width={200} sx={styles.graphHeading} />
                    <Skeleton height={24} width={150} sx={styles.reportText} />
                  </>
                )}
              </Box>
              {!euctrPediatricsState.loading ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {Object.keys(euctrPediatricsState.euctrPediatricsStats).length > 0 ? (
                    <CustomBarGraph
                      keys={['number']}
                      index='x'
                      data={euctrPediatricsState.euctrPediatricsStats.age_category_graph}
                      paddings={0.8}
                      colors={['#B1BBE4']}
                      tickRotationValue={15}
                      labelTextColor='#ffffff'
                      axisText='#A3A3A3'
                    />
                  ) : (
                    'No data'
                  )}
                </>
              ) : (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-end'
                  alignItems='space-between'
                  sx={styles.loaderBox}>
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    flexWrap='wrap-reverse'
                    sx={styles.barGraphRect}>
                    <Skeleton variant='rectangular' height='90%' width='.2%' />
                    <Skeleton variant='rectangular' height='60%' width='3%' />
                    <Skeleton variant='rectangular' height='80%' width='3%' />
                    <Skeleton variant='rectangular' height='30%' width='3%' />
                    <Skeleton variant='rectangular' height='50%' width='3%' />
                    <Skeleton variant='rectangular' height='50%' width='3%' />
                    <Skeleton variant='rectangular' height='60%' width='3%' />
                    <Skeleton variant='rectangular' height='80%' width='3%' />
                    <Skeleton variant='rectangular' height='30%' width='3%' />
                    <Skeleton variant='rectangular' height='50%' width='3%' />
                    <Skeleton variant='rectangular' height='50%' width='3%' />
                    <Skeleton variant='rectangular' height='90%' width='3%' />
                    <Skeleton variant='rectangular' height='60%' width='3%' />
                    <Skeleton variant='rectangular' height='80%' width='3%' />
                  </Box>
                  <Skeleton variant='rectangular' height='.2%' width='100%' />
                </Box>
              )}
            </Box>
            {/* Graph 2  */}
            <Box sx={styles.pieContainers} display='flex' flexDirection='column'>
              <Box display='flex' justifyContent='space-between'>
                {Object.keys(euctrPediatricsState.euctrPediatricsStats).length > 0 &&
                !euctrPediatricsState.loading ? (
                  <>
                    <Typography sx={styles.graphHeading}>Trial by Year</Typography>
                    <Button
                      onClick={() => addToReport('year_trials_graphs')}
                      variant='text'
                      fullWidth={false}
                      sx={styles.addToReport}>
                      <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                        + Add to Report
                      </Typography>
                    </Button>
                  </>
                ) : (
                  <>
                    <Skeleton height={32} width={200} sx={styles.graphHeading} />
                    <Skeleton height={24} width={150} sx={styles.reportText} />
                  </>
                )}
              </Box>
              {!euctrPediatricsState.loading ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {Object.keys(euctrPediatricsState.euctrPediatricsStats).length > 0 ? (
                    <CustomBarGraph
                      keys={['number']}
                      index='x'
                      data={euctrPediatricsState.euctrPediatricsStats.year_trials_graphs}
                      paddings={0.8}
                      colors={['#B1BBE4']}
                      tickRotationValue={25}
                      labelTextColor='#ffffff'
                      axisText='#A3A3A3'
                      layout='horizontal'
                    />
                  ) : (
                    'No Data'
                  )}
                </>
              ) : (
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-end'
                  alignItems='space-between'
                  sx={styles.loaderBox}>
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    flexWrap='wrap-reverse'
                    sx={styles.barGraphRect}>
                    <Skeleton variant='rectangular' height='90%' width='.2%' />
                    <Skeleton variant='rectangular' height='60%' width='3%' />
                    <Skeleton variant='rectangular' height='80%' width='3%' />
                    <Skeleton variant='rectangular' height='30%' width='3%' />
                    <Skeleton variant='rectangular' height='50%' width='3%' />
                    <Skeleton variant='rectangular' height='50%' width='3%' />
                    <Skeleton variant='rectangular' height='60%' width='3%' />
                    <Skeleton variant='rectangular' height='80%' width='3%' />
                    <Skeleton variant='rectangular' height='30%' width='3%' />
                    <Skeleton variant='rectangular' height='50%' width='3%' />
                    <Skeleton variant='rectangular' height='50%' width='3%' />
                    <Skeleton variant='rectangular' height='90%' width='3%' />
                    <Skeleton variant='rectangular' height='60%' width='3%' />
                    <Skeleton variant='rectangular' height='80%' width='3%' />
                  </Box>
                  <Skeleton variant='rectangular' height='.2%' width='100%' />
                </Box>
              )}
            </Box>
          </Box>
          <Box display='flex' flexDirection='row' sx={styles.pieCharts}>
            {/* Graph 3  */}
            <Box sx={styles.pieContainers} display='flex' flexDirection='column'>
              <Box display='flex' justifyContent='space-between'>
                {Object.keys(euctrPediatricsState.euctrPediatricsStats).length > 0 &&
                !euctrPediatricsState.loading ? (
                  <>
                    <Typography sx={styles.graphHeading}>Type of Medicine</Typography>
                    <Button
                      onClick={() => addToReport('type_of_medicine_graph')}
                      variant='text'
                      fullWidth={false}
                      sx={styles.addToReport}>
                      <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                        + Add to Report
                      </Typography>
                    </Button>
                  </>
                ) : (
                  <>
                    <Skeleton height={32} width={200} sx={styles.graphHeading} />
                    <Skeleton height={24} width={150} sx={styles.reportText} />
                  </>
                )}
              </Box>
              {!euctrPediatricsState.loading ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {Object.keys(euctrPediatricsState.euctrPediatricsStats).length > 0 ? (
                    <CustomPieChart
                      data={euctrPediatricsState.euctrPediatricsStats.type_of_medicine_graph}
                      innerRadius={0.6}
                      arcLinkLabelsSkipAngle={1}
                      colors={PIECHARTCOLOR}
                      margin={styles.pieChartMargin}
                    />
                  ) : (
                    'No data'
                  )}
                </>
              ) : (
                <Box display='flex' alignSelf='center' pt={4}>
                  <CircularProgress
                    value={90}
                    thickness={5}
                    size='12rem'
                    variant='indeterminate'
                    sx={{
                      color: '#adadac',
                      opacity: 0.5
                    }}
                  />
                </Box>
              )}
            </Box>
            {/* Graph 4  */}
            <Box sx={styles.pieContainers} display='flex' flexDirection='column'>
              <Box display='flex' justifyContent='space-between'>
                {Object.keys(euctrPediatricsState.euctrPediatricsStats).length > 0 &&
                !euctrPediatricsState.loading ? (
                  <>
                    <Typography sx={styles.graphHeading}>Scope of Study</Typography>
                    <Button
                      onClick={() => addToReport('scope_of_study_graph')}
                      variant='text'
                      fullWidth={false}
                      sx={styles.addToReport}>
                      <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                        + Add to Report
                      </Typography>
                    </Button>
                  </>
                ) : (
                  <>
                    <Skeleton height={32} width={200} sx={styles.graphHeading} />
                    <Skeleton height={24} width={150} sx={styles.reportText} />
                  </>
                )}
              </Box>
              {!euctrPediatricsState.loading ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {Object.keys(euctrPediatricsState.euctrPediatricsStats).length > 0 ? (
                    <CustomPieChart
                      data={euctrPediatricsState.euctrPediatricsStats.scope_of_study_graph}
                      innerRadius={0.6}
                      colors={PIECHARTCOLOR}
                      arcLinkLabelsSkipAngle={1}
                      margin={styles.pieChartMargin}
                    />
                  ) : (
                    'No Data'
                  )}
                </>
              ) : (
                <Box display='flex' alignSelf='center' pt={4}>
                  <CircularProgress
                    value={90}
                    thickness={5}
                    size='12rem'
                    variant='indeterminate'
                    sx={{
                      color: '#adadac',
                      opacity: 0.5
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default React.memo(Insights);
