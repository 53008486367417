import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  StudyDetailsroot: {
    width: '100%',
    marginBottom: theme.spacing(6)
  },
  table: {
    width: '100%',
    border: 'unset !important',
    borderCollapse: 'separate',
    borderSpacing: '0px 7px',
    padding: '0px 24px',
    marginBottom: '24px'
  },
  StudyDetailsheadingTR: {
    padding: '7px',
    fontSize: '1.4vh',
    fontWeight: 'bold'
  },
  StudyDetailsheadingTH: {
    padding: '7px',
    fontSize: '1.4vh',
    fontWeight: 'bold',
    color: theme.palette.black,
    border: 'unset'
  },
  StudyDetailsRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.white
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.white
    },
    '-moz-box-shadow': '0 0 5px rgba(0,0,0,0.1)',
    '-webkit-box-shadow': '0 0 5px rgba(0,0,0,0.1)',
    'box-shadow': '0 0 5px rgba(0,0,0,0.1)'
  },
  StudyDetailsheading: {
    fontSize: '1.3vh',
    fontWeight: '700',
    padding: '7px',
    color: '#0352CC'
  },
  StudyDetailsheading1: {
    fontSize: '1.3vh',
    fontWeight: '800',
    padding: '7px',
    color: '#848383'
  }
}));
