import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';

// other components
import Styles from '../styles/DataGrid.styles';

// The data creator.
import VivproDatagrid from '../../../components/Datagrid';
import DatagridTooltip from '../../../components/Datagrid/DatagridTooltip';

const COLUMN_CONST_VALUES: any = {
  us: 'patent_no',
  ca: 'patent_num'
};

const COLUMN_LIST: any = {
  us: [
    {
      field: 'patent_no',
      headerName: 'Patent number',
      width: 270,
      valueGetter: ({ row }: any) => `${row.patent_no}`,
      renderCell: DatagridTooltip
    },
    {
      field: 'patent_use_code',
      headerName: 'Code',
      width: 180,
      valueGetter: ({ row }: any) => `${row.patent_use_code}`,
      renderCell: DatagridTooltip
    },
    {
      field: 'patent_definition',
      headerName: 'Definition',
      width: 500,
      valueGetter: ({ row }: any) => `${row.patent_definition}`,
      renderCell: DatagridTooltip
    },
    {
      field: 'patent_expire_date',
      headerName: 'Expiration date',
      width: 280
    }
  ],
  ca: [
    {
      field: 'patent_num',
      headerName: 'Patent Number',
      width: 270
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 180
    },
    {
      field: 'submission_date',
      headerName: 'Submission Date',
      width: 270
    },
    {
      field: 'grant_date',
      headerName: 'Grant Date',
      width: 270
    },
    {
      field: 'patent_expire_date',
      headerName: 'Expiration date',
      width: 270
    }
  ]
};

const Patent = ({ cardState, source }: any) => {
  const classes = Styles() as any;
  return (
    <Box className={classes.datagrid} display='flex' alignItems='center' height='35vh'>
      <VivproDatagrid
        rows={cardState.extendedCard.patent}
        columnsMapping={COLUMN_LIST[source.toLowerCase()]}
        rowId={COLUMN_CONST_VALUES[source.toLowerCase()]}
      />
    </Box>
  );
};

export default React.memo(Patent);
