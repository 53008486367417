import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  Heading: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    fontSize: '1.6vh'
  },
  adminsum: {
    height: '100%',
    background: theme.palette.white,
    borderRadius: theme.spacing(1),
    padding: '20px 40px'
  },
  submission: {
    background: theme.palette.white,
    borderRadius: theme.spacing(1),
    height: '100%'
  },
  clinical: {
    height: '100%',
    background: theme.palette.white,
    borderRadius: theme.spacing(1)
  },
  subheading: {
    background: '#f7f7f7',
    margin: '4px 8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  graph: {
    background: 'white'
  },
  icons: {
    display: 'flex',
    justifyContent: 'center'
  },
  applicationRoot: {
    marginBottom: theme.spacing(4)
  },
  subdata: {
    color: theme.palette.regulatory.blue,
    fontWeight: 700,
    fontSize: '1.4vh'
  },
  adminDate: {
    color: theme.palette.regulatory.lightGreen,
    background: theme.palette.regulatory.lighterGreen,
    borderRadius: theme.spacing(0.5),
    margin: '0px 20px'
  },
  capture: {
    minHeight: '60vh',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    margin: '13px 0px'
  },
  capture2: {
    minHeight: '30vh',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    margin: '13px 0px'
  },
  ApprovalIcons: {
    width: '2.5vh',
    height: '2.5vh',
    margin: 'auto'
  },
  avatar: {
    marginLeft: theme.spacing(1),
    height: '2.2vh',
    width: '2.2vh',
    fontSize: '1.2vh',
    background: theme.palette.cards.avatar
  },
  avatarLight: {
    marginLeft: theme.spacing(1),
    height: '2.2vh',
    width: '2.2vh',
    fontSize: '1.2vh',
    background: theme.palette.biologics.textSecondary
  },
  subheadingLabel: {
    fontSize: '1.6vh'
  }
}));
