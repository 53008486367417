import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ResponsiveBar } from '@nivo/bar';
import React, { useEffect, useState } from 'react';
import ReferenceText from '../../Report/components/ReferenceText';

const BarChart = ({ data, yAxis, title = {}, style = {}, referenceText = {} }: any) => {
  const boxRef = React.useRef<HTMLDivElement | null>(null);
  const refBoxRef = React.useRef<HTMLDivElement | null>(null);

  const [utilityTextHeight, setUtilityTextHeight] = useState<Number>(0);

  const getHeight = () => {
    let boxHeight = 0;
    let refBoxHeight = 0;
    if (boxRef.current) {
      boxHeight = boxRef.current.clientHeight;
    }
    if (refBoxRef.current) {
      refBoxHeight = refBoxRef.current.clientHeight;
    }
    return boxHeight + refBoxHeight + 10;
  };

  useEffect(() => {
    const height = getHeight();
    setUtilityTextHeight(height);
  }, []);

  return (
    <Box
      position='relative'
      width='100%'
      height='100%'
      sx={{ 'page-break-inside': 'avoid', display: 'inline-block' } as any}>
      {title.show && (
        <Box ref={boxRef} position='absolute' width='100%' top='0px' textAlign='center'>
          <Typography fontSize={`${title?.fontSize || 20}px`} textAlign='center'>
            {title.title}
          </Typography>
        </Box>
      )}
      <Box width='100%' height={`calc(100% - ${utilityTextHeight}px)`}>
        {data.length !== 0 ? (
          <ResponsiveBar
            data={data}
            keys={['y']}
            // keys={["one", "two","three","four","five"]}
            indexBy='x'
            margin={{ top: 100, right: 20, bottom: 50, left: 60 }}
            padding={0.3}
            enableLabel={style?.enableLabel || false}
            borderRadius={2}
            colors={['#48E0A6', '#6868FA', '#12815E']}
            colorBy='indexValue'
            axisTop={null}
            enableGridX={false}
            enableGridY={false}
            axisRight={null}
            axisBottom={{
              tickSize: 1,
              tickPadding: 5,
              tickRotation: 'tickRotationX' in style ? style?.tickRotationX : 90
            }}
            axisLeft={{
              tickSize: 3,
              tickPadding: 5,
              tickRotation: 'tickRotationY' in style ? style?.tickRotationY : 90,
              legend: yAxis,
              legendOffset: -40,
              legendPosition: 'middle'
            }}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 10
                  }
                }
              },
              legends: {
                text: {
                  fontSize: 16
                }
              }
            }}
            layout='vertical'
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            legends={[]}
            animate
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...style}
          />
        ) : (
          <Box position='relative' width='100%' top='50%' textAlign='center'>
            <Typography fontSize='20px' textAlign='center'>
              No Graph Available
            </Typography>
          </Box>
        )}
      </Box>
      {referenceText.show && <ReferenceText ref={refBoxRef} referenceText={referenceText} />}
    </Box>
  );
};

export default React.memo(BarChart);
