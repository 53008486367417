/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useCallback, useContext, useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import CircularProgress from '@mui/material/CircularProgress';
import DocumentCountIcon from '../../../assets/svgs/CardsPage/DocumentCountIcon';
import PageCountIcon from '../../../assets/svgs/CardsPage/PageCountIcon';

// store
import canadaGuidanceStore from '../../../store/CanadaGuidance';
import actions from '../../../store/CanadaConsultation/actions';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

// api
import { getCanadaConsultationDocDetails } from '../../../api/pages/CanadaConsultation';
// component
import ComparisonButtons from './ComparisonButtons';
import CompareDialog from './CompareDialog';

// utils
import mapEntityData from '../utils/mapEntityData';
import trimText from '../../../utils/trimText';
import formatCount from '../../../utils/formatCount';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';

// styles
import styles from '../../EMAGuidance/styles/DocumentHistory.styles';

// Icons
import { getDocumentSourceName } from '../../SearchResults/utils/documentResultsUtils';
import PDFPreview from '../../../components/PdfPreview/PDFPreview';
import { FolderIcon } from '../../../assets/svgs/Icons';

const GridCellDouments: React.FC<any> = ({ data }) => {
  const { CanadaGuidanceDispatch } = useContext(canadaGuidanceStore);
  const [pdfUrl, setPdfUrl] = useState('');
  const { dispatch } = useContext(GlobalStore) as any;
  const [selectedRowData, setSelectdRowData] = useState({}) as any;
  const [documentsToCompare, setDocumentsToCompare] = useState<Array<any>>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [compareEnabled, setCompareEnabled] = useState<boolean>(false);
  const [openComparisonPopup, setOpenComparisonPopup] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [documentsDetails, setDocumentsDetails] = useState<{ [key: string]: any }>({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const consultationId = data?.consultation_id;
        if (consultationId) {
          const errors = [];
          const res = await getCanadaConsultationDocDetails(consultationId);

          if (res.data.status === 200) {
            setDocumentsDetails(res.data.body);
            setIsLoading(false);
          } else {
            errors.push('No data was found');

            CanadaGuidanceDispatch({
              type: actions.SET_CANADA_CONSULTATION_ERRORS,
              action: errors
            });
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    if (open) {
      fetchData();
    }
  }, [open]);

  const handleOpenPopup = async (e: any) => {
    if (data?.document_count > 0) {
      e.preventDefault();
      setOpen(true);
    }
  };

  const handleClosePopup = () => {
    setOpen(false);
    setDocumentsToCompare([]);
    setCompareEnabled(false);
  };

  const handlePDFOpenClick = useCallback((pdfLink: any) => {
    setPdfUrl(pdfLink);
  }, []);

  const handleCompareClick = useCallback(() => {
    if (compareEnabled) {
      setCompareEnabled(false);
      setDocumentsToCompare([]);
    } else {
      setCompareEnabled(true);
      CanadaGuidanceDispatch({
        type: actions.CLEAR_DOCUMENT_TO_COMPARE
      });
      CanadaGuidanceDispatch({
        type: actions.SET_COMPARE_ENABLED,
        value: false
      });
      CanadaGuidanceDispatch({
        type: actions.SET_SEARCH,
        value: {
          term: ''
        }
      });
    }
  }, [compareEnabled]);

  const handleComparisonCheckbox = useCallback(
    (row: any) => {
      if (documentsToCompare.find((doc: any) => doc.doc_title === row.doc_title)) {
        const removeDocument = documentsToCompare.filter(
          (doc: any) => doc.doc_title !== row.doc_title
        );
        setDocumentsToCompare(removeDocument);
      } else if (
        documentsToCompare.length === 2 &&
        !documentsToCompare.find((doc: any) => doc.doc_title === row.doc_title)
      ) {
        documentsToCompare.pop();
        const addDocument = [...documentsToCompare, row];
        setDocumentsToCompare(addDocument);
        // }
      } else {
        const addDocument = [...documentsToCompare, row];
        setDocumentsToCompare(addDocument);
      }
    },
    [documentsToCompare, data]
  );

  const clearComparison = useCallback(() => {
    setDocumentsToCompare([]);
    setOpenComparisonPopup(false);
  }, []);

  const handleComparisonPopup = useCallback((value: boolean) => {
    setOpenComparisonPopup(value);
  }, []);

  const compareItems: Array<any> = useMemo(
    () =>
      (documentsToCompare ?? []).map(doc => ({
        title: trimText(`${doc.doc_title.replace(/ \([\s\S]*?\)/g, '')}`, 35),
        doc_title: doc.doc_title,
        pdfLink: doc.s3_url
      })),
    [documentsToCompare]
  );

  const handleChatRia = ({ pdfUrl = '' }: { pdfUrl?: string }) => {
    const mappedSourceName = getDocumentSourceName('CA Consultations');
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: mappedSourceName
    });
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: {
          issued_date: selectedRowData?.issued_date ?? '',
          last_modified_date: selectedRowData?.last_modified_date ?? '',
          _category: selectedRowData?.document_type ?? '',
          title: selectedRowData?.doc_title ?? ''
        },
        source: mappedSourceName,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    setPdfUrl('');
    handleClosePopup();
  };

  return (
    <Box sx={styles.textAlignment}>
      <Box alignItems='center' sx={{ cursor: 'pointer' }}>
        {data && (
          <Stack
            direction='row'
            spacing={0.5}
            alignItems='center'
            sx={styles.linkItem}
            onClick={e => handleOpenPopup(e)}>
            <FolderIcon sx={{ fontSize: 12 }} />
            <Typography variant='subtitle2' sx={styles.pdfCellText}>
              {data?.document_count}
            </Typography>
          </Stack>
        )}
      </Box>
      <Dialog
        fullWidth
        maxWidth='lg'
        aria-labelledby='customized-dialog-title'
        open={open}
        sx={styles.dialog}
        onClose={handleClosePopup}>
        <DialogTitle>
          <Grid display='flex' justifyContent='space-between' alignItems='flex-start'>
            <Grid sx={styles.heading} display='flex' justifyContent='center'>
              Documents
            </Grid>
            <Stack
              display='flex'
              flexDirection='row'
              sx={{
                height: '40px',
                padding: '1px 28px',
                borderRadius: '10px',
                bgcolor: 'white.main'
              }}>
              <Stack direction='row' spacing={2}>
                <DocumentCountIcon />
                <Stack>
                  <Typography variant='subtitle2'>Documents</Typography>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Tooltip title={formatCount(data?.document_count || 0)}>
                      <Typography variant='subtitle1' fontWeight='bold'>
                        {truncateCountAboveMillions(data?.document_count || 0)}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Stack>
                <PageCountIcon />
                <Stack>
                  <Typography variant='subtitle2'>Pages</Typography>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Tooltip title={formatCount(data?.document_total_page_count || 0)}>
                      <Typography variant='subtitle1' fontWeight='bold'>
                        {truncateCountAboveMillions(data?.document_total_page_count || 0)}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Box display='flex' flexDirection='row'>
              {data?.document_count > 1 && (
                <Stack
                  spacing={1}
                  alignItems='center'
                  direction='row'
                  sx={{ cursor: 'pointer', paddingRight: '50px' }}
                  onClick={handleCompareClick}>
                  <Switch checked={compareEnabled} />
                  <Typography
                    id='filters'
                    sx={{ justifySelf: 'flex-end', color: 'gray.lightVariant' }}
                    variant='subtitle1'>
                    Comparison Mode
                  </Typography>
                </Stack>
              )}
              <IconButton aria-label='delete' onClick={handleClosePopup} sx={{ fontSize: '30px' }}>
                <CancelIcon fontSize='inherit' />
              </IconButton>
            </Box>
          </Grid>
          <Divider />
        </DialogTitle>
        <DialogContent sx={{ height: '94%' }}>
          <Stack spacing={1}>
            {openComparisonPopup && (
              <CompareDialog
                open={openComparisonPopup}
                onClose={() => handleComparisonPopup(false)}
                compareItems={compareItems}
                onNewCompare={clearComparison}
              />
            )}
            {compareEnabled && (
              <ComparisonButtons
                documentsToCompare={documentsToCompare}
                removeFromComparison={handleComparisonCheckbox}
                openComparisonPopup={handleComparisonPopup}
                clearComparison={clearComparison}
                fromDocumentHistory
              />
            )}
            {!isLoading ? (
              <Stack spacing={1}>
                {documentsDetails &&
                  Object.keys(documentsDetails).map(
                    key =>
                      documentsDetails[key].length > 0 && (
                        <Stack
                          key={key}
                          spacing={1}
                          display='flex'
                          flexDirection='row'
                          sx={{ width: '100%' }}>
                          <Box sx={{ width: '15%' }}>
                            <Box
                              sx={{
                                color: 'primary.dark',
                                padding: '13px 10px',
                                marginBottom: 'auto'
                              }}>
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  color: 'black.darkVariant',
                                  fontWeight: '600'
                                }}>
                                {key}
                              </Typography>
                            </Box>
                          </Box>
                          <Stack sx={{ width: '100%' }}>
                            <>
                              {documentsDetails[key].map((row: any) => {
                                return (
                                  <Stack key={row.doc_title} spacing={1}>
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      sx={{
                                        padding: '12px 10px',
                                        borderRadius: 8,
                                        backgroundColor: 'primary.background'
                                      }}>
                                      <Stack direction='row' spacing={1}>
                                        {compareEnabled && (
                                          <Tooltip
                                            title={
                                              documentsToCompare.length === 2 &&
                                              !documentsToCompare.find(
                                                (doc: any) => doc.doc_title === row.doc_title
                                              )
                                                ? 'Selection limit reached'
                                                : ''
                                            }>
                                            <Box>
                                              <Radio
                                                disabled={
                                                  documentsToCompare.length === 2 &&
                                                  !documentsToCompare.find(
                                                    (doc: any) => doc.doc_title === row.doc_title
                                                  )
                                                }
                                                checked={
                                                  !!documentsToCompare.find(
                                                    (doc: any) => doc.doc_title === row.doc_title
                                                  )
                                                }
                                                onClick={() => handleComparisonCheckbox(row)}
                                              />
                                            </Box>
                                          </Tooltip>
                                        )}
                                        <Box>
                                          <Box
                                            sx={{
                                              padding: '0.2rem',
                                              width: '40px',
                                              height: '40px'
                                            }}>
                                            <img
                                              src='https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/pdf_icon.png'
                                              alt='PDF download'
                                              height='100%'
                                              width='100%'
                                            />
                                          </Box>
                                        </Box>
                                        <Stack>
                                          <Stack
                                            direction='row'
                                            alignItems='center'
                                            onClick={() => {
                                              setSelectdRowData(row);
                                              handlePDFOpenClick(row.s3_url);
                                              setTitle(row.doc_title.replace(/ \([\s\S]*?\)/g, ''));
                                            }}>
                                            <Typography sx={styles.title}>{`${row.doc_title.replace(
                                              / \([\s\S]*?\)/g,
                                              ''
                                            )}`}</Typography>
                                          </Stack>
                                          <Stack
                                            display='flex'
                                            flexDirection='row'
                                            sx={{ paddingTop: '10px' }}>
                                            {Object.entries(mapEntityData(row)).map(
                                              ([key, value]) => {
                                                if (value) {
                                                  return (
                                                    <Tooltip key={key} title={`${key}: ${value}`}>
                                                      <Box display='flex' sx={styles.chips}>
                                                        <Typography sx={{ fontSize: '12px' }}>
                                                          {value}
                                                        </Typography>
                                                      </Box>
                                                    </Tooltip>
                                                  );
                                                }
                                              }
                                            )}
                                          </Stack>
                                        </Stack>
                                      </Stack>
                                    </Box>
                                    <Box
                                      display='flex'
                                      flexDirection='column'
                                      sx={{ marginTop: '5px' }}
                                    />
                                  </Stack>
                                );
                              })}
                            </>
                          </Stack>
                        </Stack>
                      )
                  )}
              </Stack>
            ) : (
              <Box display='flex' justifyContent='center' flexDirection='row'>
                <CircularProgress
                  value={70}
                  thickness={3}
                  size='8rem'
                  variant='indeterminate'
                  sx={{ color: '#adadac' }}
                />
              </Box>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
      {pdfUrl && (
        <Box>
          <PDFPreview
            open={!!pdfUrl}
            pdfUrl={pdfUrl}
            onClose={() => setPdfUrl('')}
            handleChatRia={handleChatRia}
            title={title}
          />
        </Box>
      )}
    </Box>
  );
};
const DocumentHistoryPDF = (params: any) => {
  return <GridCellDouments data={params.row} />;
};

export default DocumentHistoryPDF;
