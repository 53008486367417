import React, { useCallback, useContext } from 'react';

// mui
import uniqueId from 'lodash/uniqueId';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// store
import store from '../../../store/GuidanceDocuments';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

// utils
import formatCount from '../../../utils/formatCount';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';
import { GUIDANCE_REPORT_QUERIES } from '../const';

// components
import StatsCountTile from '../../../helpers/baseComponents/StatsCountTile';
import CustomBarGraph from '../../../components/CustomComponents/Graphs/CustomBarGraph';
import PieChart from './PieChart';

// styles
import styles from '../styles/Insights.styles';

const GuidanceInsights = () => {
  const { guidanceState } = useContext(store);
  // eslint-disable-next-line no-unused-vars
  const { state, dispatch } = useContext(GlobalStore);

  const addToReport = useCallback(
    async id => {
      const addedReport = GUIDANCE_REPORT_QUERIES.find(report => report.id === id);
      const random = Math.floor(Math.random() * 1000);
      dispatch({
        type: GlobalActions.ADD_TEMPLATE_TO_REPORT,
        value: {
          templateType: 'custom',
          chartType: addedReport.graph,
          sectionType: 'CHART',
          id: uniqueId(`guidance_${random}_`),
          style: {
            placement: {
              h: 40,
              w: 12
            },
            graphStyle: addedReport.graphStyle,
            title: addedReport.title
          },
          data: {
            source: 'fda-guidance',
            search_type: 'guidance',
            disableSelectionSource: true,
            filters: guidanceState.availableFilters,
            query: addedReport.query
          }
        }
      });
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Successfully added to Report.', color: 'success' }
      });
    },
    [dispatch, guidanceState.issueDateBar, guidanceState.filters]
  );
  return (
    <Stack>
      <Grid container>
        {/* TILES */}
        <Grid item xs={12} lg={3}>
          {guidanceState.guidanceStats && !guidanceState.loading ? (
            <>
              <StatsCountTile text='Total' count={guidanceState.guidanceStats.totalGuidanceCount} />
              <StatsCountTile
                text='Status Final'
                count={guidanceState.guidanceStats.totalCountStatusFinal}
              />
              <StatsCountTile
                text='Approval in Last 30 Days'
                count={guidanceState.guidanceStats.totalLastThirtyDays}
              />
              <StatsCountTile
                text='Total Documents | Pages'
                count={`${truncateCountAboveMillions(
                  guidanceState.guidanceStats.pdfCount
                )} | ${truncateCountAboveMillions(guidanceState.guidanceStats.pageCount)}`}
                toolTipValue={`${formatCount(guidanceState.guidanceStats.pdfCount)} | ${formatCount(
                  guidanceState.guidanceStats.pageCount
                )}`}
              />
            </>
          ) : (
            <Box ml={5} display='flex' flexDirection='column' sx={styles.cards}>
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
            </Box>
          )}
        </Grid>
        {/* PIES */}
        <Grid item xs={12} lg={8}>
          <Box display='flex' flexDirection='column' sx={styles.box}>
            <Box display='flex' flexDirection='column'>
              <Box display='flex' justifyContent='space-between'>
                {!guidanceState.loading ? (
                  <>
                    <Typography sx={styles.graphHeading}>Guidance Documents by Year</Typography>
                    <Button
                      onClick={() => addToReport('guidance_documents_by_year')}
                      variant='text'
                      fullWidth={false}
                      sx={styles.addToReport}>
                      <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                        + Add to Report
                      </Typography>
                    </Button>
                  </>
                ) : (
                  <>
                    <Skeleton height={32} width={200} sx={styles.graphHeading} />
                    <Skeleton height={28} width={150} sx={styles.reportText} />
                  </>
                )}
              </Box>
              {/* Bar Graph */}
              <Box display='flex' justifyContent='center' alignItems='center' sx={styles.barGraph}>
                {guidanceState.loading ? (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-end'
                    alignItems='space-between'
                    sx={styles.loaderBox}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      flexWrap='wrap-reverse'
                      sx={styles.barGraphRect}>
                      <Skeleton variant='rect' height='90%' width='.2%' />
                      <Skeleton variant='rect' height='60%' width='3%' />
                      <Skeleton variant='rect' height='80%' width='3%' />
                      <Skeleton variant='rect' height='30%' width='3%' />
                      <Skeleton variant='rect' height='50%' width='3%' />
                      <Skeleton variant='rect' height='50%' width='3%' />
                      <Skeleton variant='rect' height='60%' width='3%' />
                      <Skeleton variant='rect' height='80%' width='3%' />
                      <Skeleton variant='rect' height='30%' width='3%' />
                      <Skeleton variant='rect' height='50%' width='3%' />
                      <Skeleton variant='rect' height='50%' width='3%' />
                    </Box>
                    <Skeleton variant='rect' height='.2%' width='100%' />
                  </Box>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {guidanceState.issueDateBar && guidanceState.issueDateBar.length > 0 ? (
                      <CustomBarGraph
                        keys={['count']}
                        index='year'
                        colors={['#B1BBE4']}
                        data={guidanceState.issueDateBar}
                        paddings={0.8}
                        labelTextColor='#ffffff'
                        axisText='#A3A3A3'
                      />
                    ) : (
                      <Typography variant='subtitle2' sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                        No data
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' sx={styles.pieCharts}>
              {/* [{graph: []}, {graph: []}, {graph: []}] */}
              {guidanceState.loading ? (
                <>
                  {Array.from(Array(3)).map((_, index) => (
                    <Box
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      display='flex'
                      flexDirection='column'
                      sx={styles.pieContainers}>
                      <Box display='flex' justifyContent='space-between'>
                        <Skeleton height={32} width={100} sx={styles.graphHeading} />
                        <Skeleton height={28} width={50} sx={styles.reportText} />
                      </Box>
                      <Box display='flex' alignSelf='center' pt={4} sx={styles.graphContainer}>
                        <CircularProgress
                          value={90}
                          thickness={5}
                          size='5rem'
                          variant='indeterminate'
                          sx={{
                            color: '#adadac',
                            opacity: 0.5
                          }}
                          margin={{ top: 20, right: 45, bottom: 50, left: 30 }}
                        />
                      </Box>
                    </Box>
                  ))}
                </>
              ) : (
                guidanceState.donutCharts.map((donutChart, i) => (
                  <PieChart
                    // eslint-disable-next-line react/no-array-index-key
                    key={`guidance_treemap__${i}`}
                    data={donutChart.children}
                    label={donutChart.label}
                    id={donutChart.apikey}
                  />
                ))
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default React.memo(GuidanceInsights);
