import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

const buttonStyle = {
  p: 3.5,
  pl: 2,
  pr: 2,
  ml: 0.5,
  mr: 0.5,
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 92,
  height: 98,
  cursor: 'pointer',
  border: '2px dotted #ddd',
  '&:hover': {
    cursor: 'pointer',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
  }
};

const BorderedButton = ({ name, tooltipText, iconComponent, onClick }: any) => {
  return (
    <Tooltip title={tooltipText}>
      <Box sx={buttonStyle} onClick={onClick}>
        {iconComponent}
        <Typography fontSize={14} color='gray.sonicSilver' fontWeight={600}>
          {name}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default React.memo(BorderedButton);
