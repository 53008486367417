import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
// material ui
import { Button, Stack } from '@mui/material';
import { ComparisonIcon } from '../../../../assets/svgs/Icons';

// styles
import styles from '../../../SearchResults/styles/Buttons.styles';
// custom hooks
import useQuery from '../../../../helpers/customHooks/queryParameter';
// custom components
import ComparisonDialog, {
  ApplicationProps,
  ModeOfComparisonDialog
} from '../../../../components/Comparison/ComparisonDialog';

const CompareLabelButton = ({ applicationName }: any) => {
  const query = useQuery();
  const applicationtype = query.get('type');

  const { source, number }: { source: string; number: string } = useParams();

  const [openCompare, setOpenCompare] = useState(false);
  const applications = [
    {
      source: source.toUpperCase(),
      application_type: applicationtype,
      application_number: number,
      product_number: number,
      application_name: applicationName ? `${number}-${applicationName}` : ''
    },
    {
      source: source.toUpperCase(),
      application_type: applicationtype,
      application_number: number,
      product_number: number,
      application_name: applicationName ? `${number}-${applicationName}` : ''
    }
  ];
  const mode: ModeOfComparisonDialog = {
    child_labelList_dependent_on_parent: true,
    section_binding: true,
    allsections: true,
    default_section_all_section: true
  };

  const handleLabelComparison = () => {
    setOpenCompare(true);
  };
  const handleLabelSelectionClose = () => {
    setOpenCompare(false);
  };

  return (
    <Stack>
      {openCompare ? (
        <ComparisonDialog
          open={openCompare}
          onClose={handleLabelSelectionClose}
          applications={applications as Array<ApplicationProps>}
          mode={mode}
        />
      ) : (
        <Button
          startIcon={<ComparisonIcon sx={styles.primaryButtonIcon} />}
          onClick={handleLabelComparison}
          sx={styles.primaryButton}>
          Compare Labels
        </Button>
      )}
    </Stack>
  );
};

export default React.memo(CompareLabelButton);
