// @ts-nocheck
import React from 'react';

// mui
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

// child components
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import StatsCountTile from '../../../helpers/baseComponents/StatsCountTile';

// other components
import CustomBarGraph from '../../../components/CustomComponents/Graphs/CustomBarGraph';
import CustomPieChart from '../../../components/CustomComponents/Graphs/CustomPieChart';
import { PIECHARTCOLOR } from '../../../components/CustomComponents/Graphs/constant';

// utils
// styles
import styles from '../../PediatricsFDA/styles/Insights.styles';

// context
import VivproDatagrid from '../../../components/Datagrid';

const Insights = ({ insights }: any) => {
  const { search } = useParams();
  const isEmptyDesignations =
    insights?.designations &&
    Object.values(insights?.designations)?.every(x => x === null || x === 0);
  const isEmptyIndications =
    insights?.indications &&
    Object.values(insights?.indications)?.every(x => x.value === null || x.value === 0);

  return (
    <Stack>
      <Grid container>
        <Grid item xs={12} lg={3}>
          {insights && 'active_ingredients' in insights ? (
            <StatsCountTile
              text='Active Ingredients'
              count={`${insights.active_ingredients}`}
              toolTipValue=''
            />
          ) : (
            <Skeleton sx={styles.paper} />
          )}
          {insights && 'applications' in insights ? (
            <StatsCountTile
              text='Applications'
              count={`${insights.applications}`}
              toolTipValue=''
            />
          ) : (
            <Skeleton sx={styles.paper} />
          )}
          {insights && 'orig_designations' in insights ? (
            <StatsCountTile
              text='Original Designations'
              count={`${insights.orig_designations}`}
              toolTipValue=''
            />
          ) : (
            <Skeleton sx={styles.paper} />
          )}
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box display='flex' flexDirection='column' sx={styles.box}>
            <Box display='flex' flexDirection='column'>
              <Box display='flex' justifyContent='space-between'>
                {insights && 'year_graph' in insights ? (
                  <Typography sx={styles.graphHeading}>Designation(s) by Year</Typography>
                ) : (
                  <>
                    <Skeleton height={32} width={200} sx={styles.graphHeading} />
                    <Skeleton height={28} width={150} sx={styles.reportText} />
                  </>
                )}
              </Box>
              <Box display='flex' justifyContent='center' alignItems='center' sx={styles.barGraph}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {insights && 'year_graph' in insights ? (
                  insights.year_graph?.length > 0 ? (
                    <CustomBarGraph
                      keys={['value']}
                      index='id'
                      data={insights.year_graph
                        ?.map((item: any) => ({ id: item.name, value: item.value }))
                        .sort((a, b) => a.id - b.id)}
                      paddings={0.8}
                      colors={['#B1BBE4']}
                      tickRotationValue={0}
                      labelTextColor='#ffffff'
                      axisText='#A3A3A3'
                    />
                  ) : (
                    <Typography variant='subtitle2' sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                      No data
                    </Typography>
                  )
                ) : (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-end'
                    alignItems='space-between'
                    sx={styles.loaderBox}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      flexWrap='wrap-reverse'
                      sx={styles.barGraphRect}>
                      <Skeleton variant='rectangular' height='90%' width='.2%' />
                      <Skeleton variant='rectangular' height='60%' width='3%' />
                      <Skeleton variant='rectangular' height='80%' width='3%' />
                      <Skeleton variant='rectangular' height='30%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='60%' width='3%' />
                      <Skeleton variant='rectangular' height='80%' width='3%' />
                      <Skeleton variant='rectangular' height='30%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='90%' width='3%' />
                      <Skeleton variant='rectangular' height='60%' width='3%' />
                      <Skeleton variant='rectangular' height='80%' width='3%' />
                    </Box>
                    <Skeleton variant='rectangular' height='.2%' width='100%' />
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              display='flex'
              flexDirection='row'
              sx={{ ...styles.pieCharts, height: '50vh !important' }}>
              <Box sx={styles.pieContainers} display='flex' flexDirection='column'>
                <Box display='flex' justifyContent='space-between'>
                  {insights?.designations && Object.keys(insights.designations).length > 0 ? (
                    <Typography sx={styles.graphHeading}>Designations</Typography>
                  ) : (
                    <Skeleton height={32} width={200} sx={styles.graphHeading} />
                  )}
                </Box>
                {insights?.designations ? (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {!isEmptyDesignations ? (
                      // @ts-ignore
                      <CustomPieChart
                        data={Object.entries(insights.designations).reduce((acc, [key, value]) => {
                          if (isEmpty(acc) || value !== 0) {
                            acc.push({ id: key.toUpperCase(), value });
                          } else {
                            const index = acc.findIndex((item: any) => item.value === 0);
                            if (index !== -1) {
                              acc[index] = { id: `${acc[index].id.toUpperCase()}, ${key}`, value };
                            } else {
                              acc.push({ id: key.toUpperCase(), value });
                            }
                          }
                          return acc;
                        }, [])}
                        innerRadius={0.6}
                        colors={PIECHARTCOLOR}
                        margin={{ top: 20, right: 40, bottom: 50, left: 30 }}
                      />
                    ) : (
                      <Typography
                        variant='subtitle2'
                        alignItems='center'
                        textAlign='center'
                        sx={{ fontSize: '20px', fontWeight: 'bold', width: '100%', mt: 10 }}>
                        No data
                      </Typography>
                    )}
                  </>
                ) : (
                  <Box display='flex' alignSelf='center' pt={4}>
                    <CircularProgress
                      value={90}
                      thickness={5}
                      size='5rem'
                      variant='indeterminate'
                      sx={{
                        color: '#adadac',
                        opacity: 0.5
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Box sx={styles.pieContainers} display='flex' flexDirection='column'>
                <Box display='flex' justifyContent='space-between'>
                  {insights?.indications && Object.keys(insights.indications).length > 0 ? (
                    <Typography sx={styles.graphHeading}>Designations by Indications</Typography>
                  ) : (
                    <Skeleton height={32} width={200} sx={styles.graphHeading} />
                  )}
                </Box>
                {insights?.indications ? (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {!isEmptyIndications ? (
                      // @ts-ignore
                      <Box>
                        <VivproDatagrid
                          rows={Object.entries(insights.indications).map(([key, value], index) => ({
                            id: `${index}-${key}`,
                            indication: key,
                            value
                          }))}
                          csvFileName={`${search}-indications.csv`}
                          columnsMapping={[
                            {
                              field: 'indication',
                              headerName: 'Indications',
                              headerClassName: 'table-header',
                              width: 400,
                              cellClassName: 'table-row'
                            },
                            {
                              field: 'value',
                              headerName: 'Count',
                              headerClassName: 'table-header',
                              width: 450,
                              cellClassName: 'table-row'
                            }
                          ]}
                          rowId='id'
                        />
                      </Box>
                    ) : (
                      <Typography
                        variant='subtitle2'
                        alignItems='center'
                        textAlign='center'
                        sx={{ fontSize: '20px', fontWeight: 'bold', width: '100%', mt: 10 }}>
                        No data
                      </Typography>
                    )}
                  </>
                ) : (
                  <Box display='flex' alignSelf='center' pt={4}>
                    <CircularProgress
                      value={90}
                      thickness={5}
                      size='5rem'
                      variant='indeterminate'
                      sx={{
                        color: '#adadac',
                        opacity: 0.5
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default React.memo(Insights);
