import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

// other components
import Styles from '../styles/DataGrid.styles';

// components
import VivproDatagrid from '../../../components/Datagrid';
import { formatDate } from '../../../helpers/base';

// api
import { getGenerics } from '../../../api/pages/CardsPage';
import { useParams } from 'react-router-dom';
import { UrlParameters } from '../../../types/reactRouterDom';
import { GENERICS_COLUMN_LIST } from '../const';

const GenericsTab = ({ cardState }: any) => {
  const classes = Styles() as any;
  const [genericData, setGenericData] = useState([]) as Array<any>;
  const { source }: any = useParams<UrlParameters>();
  const getData = async () => {
    try {
      const fieldData: Array<any> = [];
      const response = await getGenerics(
        cardState.extendedCard.appl_type + cardState.extendedCard.applno
      );
      response.data.Success.dataListing.forEach((row: any) => {
        // eslint-disable-next-line no-param-reassign
        row.firstApprovalDate = formatDate(row.firstApprovalDate);
        fieldData.push(row);
      });
      setGenericData(fieldData);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  const handlePMDAData = () => {
    const fieldData: Array<any> = [];
    cardState.extendedCard.generics.forEach((row: any) => {
      fieldData.push({
        activeIngredients: row.active_ingredients,
        tradeName: row.trade_name,
        anda: row.yj_code,
        approvalPathway: row.approval_pathway,
        firstApprovalDate: row.sales_date,
        formulation: row.formulation,
        route: row.route,
        sponsorName: row.sponsor_name,
        price: row.price
      });
    });
    setGenericData(fieldData);
  };
  useEffect(() => {
    if (source?.toLowerCase() === 'jp') {
      handlePMDAData();
    } else {
      getData();
    }
  }, [source]);

  return (
    <Box className={classes.datagrid} display='flex' alignItems='center' height='35vh'>
      <VivproDatagrid
        rowId='anda'
        rows={genericData}
        columnsMapping={GENERICS_COLUMN_LIST[source] || GENERICS_COLUMN_LIST['default']}
      />
    </Box>
  );
};

export default GenericsTab;
