const snapshotStyles = {
  layout: {
    main: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      zIndex: 100,
      display: 'none'
    },
    moveIconContainer: {
      position: 'absolute',
      top: '10px',
      left: '10px',
      zIndex: 100,
      display: 'none'
    },
    approvalTimeText: { fontWeight: 500, lineHeight: '21px', color: 'gray.900' }
  },
  administrativeSummary: {
    main: {
      borderRadius: '12px',
      py: '20px'
    },
    titleText: {
      lineHeight: '24px',
      fontSize: '22px',
      fontWeight: 500,
      mb: '8px !important'
    },
    valueText: {
      fontWeight: 500,
      lineHeight: '21px',
      color: 'gray.900'
    },
    section: { minWidth: '30%', fontWeight: 400, lineHeight: '21px', color: 'gray.600' }
  },
  SubmissionSummary: {
    main: {
      borderRadius: '12px',
      py: '20px'
    },
    titleText: {
      lineHeight: '24px',
      fontSize: '22px',
      mb: '8px !important'
    },
    textDescription: {
      mb: 1,
      fontWeight: 700,
      lineHeight: '24px'
    },
    labelText: {
      minWidth: '30%',
      fontWeight: 400,
      lineHeight: '21px',
      color: 'gray.600'
    },
    valueText: {
      fontWeight: 500,
      lineHeight: '21px',
      color: 'gray.900'
    }
  },
  clinicalSummary: {
    tableTitle: {
      mb: 3,
      fontSize: 22,
      lineHeight: '24px'
    },
    table: { width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed' },
    tableHead: {
      display: 'table-row-group',
      pageBreakBefore: 'avoid',
      pageBreakAfter: 'avoid'
    },
    tableCellIndicationText: {
      border: '1px solid black',
      textAlign: 'center',
      wordWrap: 'break-word',
      fontSize: '12px',
      fontWeight: 600
    },
    tableCellHeader: {
      fontWeight: 600,
      fontSize: '12px',
      border: '1px solid black',
      wordWrap: 'break-word'
    },
    tableCellValue: {
      border: '1px solid black',
      fontSize: '12px',
      wordBreak: 'break-word',
      padding: '4px'
    },
    diagonalDividedCell: {
      tableCell: {
        position: 'relative',
        padding: 0,
        border: '1px solid black',
        '& > *': { position: 'relative', zIndex: 2 }
      },
      diagonalCellTextContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        height: '100%',
        overflow: 'hidden'
      },
      bottomLeftText: {
        fontSize: '12px',
        fontWeight: 600,
        mt: 5
      },
      topRightText: {
        mb: 5,
        fontSize: '12px',
        fontWeight: 600
      }
    }
  }
};

export default snapshotStyles;
