import SearchLogo from '../../assets/svgs/UserProfile/SearchLogo';
import AdvanceSearchLogo from '../../assets/svgs/UserProfile/AdvanceSearchLogo';
import AriaLogo from '../../assets/svgs/UserProfile/AriaLogo';
import ReportLogo from '../../assets/svgs/UserProfile/ReportLogo';

// eslint-disable-next-line react/prop-types
const UserProfileListIcons = ({ type }) => {
  switch (type) {
    case 'quickSearch':
      return <SearchLogo />;
    case 'advanceSearch':
      return <AdvanceSearchLogo />;
    case 'ariaSearch':
      return <AriaLogo />;
    case 'reports':
      return <ReportLogo />;
    default:
      return null;
  }
};
export default UserProfileListIcons;
