import { format } from 'date-fns';
import { v4 } from 'uuid';
import capitalize from 'lodash/capitalize';
import { isArray, isEmpty } from 'lodash';
import { CATEGORY_INPUT_FORMATTING } from '../const';
import { encodeObjectToBase64 } from '../../../utils/encodeDecodeObject';
import RESULT_VIEW_TYPES from '../../../pages/SearchResults/components/constants';
import { AdvancedSearchValue } from '../../../pages/Home/types';

const AND_LITERAL = ' AND ';
const OR_LITERAL = ' OR ';

interface Payload {
  type: 'formulation' | 'route';
  value: string;
}

interface ApprovalPathwayPayload {
  route?: {
    from?: string;
    to?: string;
  };
  formulations?: {
    from?: string;
    to?: string;
  };
  dates?: {
    from?: string;
    to?: string;
  };
}

export const getOptionLabel = (option: string) => {
  return capitalize(option?.replaceAll('_', ' ') ?? 'NA');
};

export const decryptQuery = (encodedQuery: string) => {
  const decodedString = Buffer.from(encodedQuery, 'base64').toString();
  return decodeURIComponent(decodedString);
};
export const encryptQuery = (query: string) => {
  return encodeURIComponent(Buffer.from(decodeURI(encodeURIComponent(query))).toString('base64'));
};
export const urlDateToLocalDate = (date: string): Date => {
  if (!date) return new Date();
  const dateParts = date.split('-');
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Month is zero-based in JavaScript
  const day = parseInt(dateParts[2], 10);
  return new Date(year, month, day, 0, 0, 0, 0);
};
export const getApprovalPayload = (payload: string) => {
  const returnVal: {
    route?: {
      from?: string;
      to?: string;
    };
    formulations?: {
      from?: string;
      to?: string;
    };
  } = {
    route: {},
    formulations: {}
  };
  const [type, value] = payload.split(':') as [Payload['type'], Payload['value']];
  const [from, to] = value.split('TO');
  // @ts-ignore
  let typeKey: 'formulations' | 'route' = type;
  if (type === 'formulation') {
    typeKey = 'formulations';
  }
  returnVal[typeKey] = {
    from: from?.replace('From', '').trim(),
    to: to?.replace('To', '').trim()
  };
  return returnVal;
};

export const generateCNFQuery = (
  listData: any[],
  categoryOptions: any | null = null,
  advancedSearch: AdvancedSearchValue | null = null,
  titleText = false
) => {
  /*
  Loop through the listData array and extract the category, condition, searchTerm, and categoryKey from each item.
  Add the searchTerm to the queryDict object if it has a category, condition, searchTerm, and categoryKey.
  the queryDict object looks like this:
  {
    categoryKey1: [searchTerm1, searchTerm2],
    categoryKey2: [searchTerm1, searchTerm2]
  }
  */
  const groups: string[] = [];
  let currentGroupCondition = '';
  const preBracket = categoryOptions ? ' ( ' : '(';
  const postBracket = categoryOptions ? ' ) ' : ')';
  listData.forEach(item => {
    // Extract properties from the item object
    const { category, subCategory, condition, searchTerm, categoryKey, compareCondition } = item; // Normalize the searchTerm by replacing certain keywords
    if (
      category === 'approval_date' &&
      advancedSearch &&
      advancedSearch.startDate &&
      advancedSearch.endDate &&
      categoryOptions
    ) {
      groups.push(preBracket);
      groups.push(
        `Approval date: ${format(advancedSearch.startDate, 'yyyy-MM-dd')} TO ${format(
          advancedSearch.endDate,
          'yyyy-MM-dd'
        )}`
      );
      groups.push(postBracket);
      groups.push(AND_LITERAL);
      return;
    }
    if (category === 'approval_date' && !categoryOptions) {
      return;
    }
    // Normalize the searchTerm by replacing certain keywords (some search terms might have AND or OR in them and cause issues in elastic search)
    let normalizedSearchTerm = searchTerm
      .replace(/AND/gi, 'and')
      .replace(/OR/gi, 'or')
      .replace(/NOT/gi, 'not');
    // @ts-ignore
    normalizedSearchTerm = CATEGORY_INPUT_FORMATTING[category] // @ts-ignore
      ? CATEGORY_INPUT_FORMATTING[category](normalizedSearchTerm)
      : normalizedSearchTerm;
    let categoryKeyProcessed = categoryKey;
    if (categoryOptions && !isEmpty(categoryOptions)) {
      if (isArray(categoryOptions)) {
        categoryKeyProcessed = categoryOptions.find((option: any) => option.id === category)?.label;
      } else {
        categoryKeyProcessed = categoryOptions[category] || getOptionLabel(category);
      }
      if (categoryKeyProcessed === undefined) {
        categoryKeyProcessed = '';
      }
      if (categoryKeyProcessed) {
        const subOption: Array<string> = [];
        if (
          category &&
          category === 'label_section_search' &&
          subCategory &&
          subCategory?.length !== 0
        ) {
          subCategory.forEach((opt: { id: string; label: string }) => {
            subOption.push(opt.label);
          });
          categoryKeyProcessed = `${categoryKeyProcessed}:[${subOption.join(',')}]`;
        }
        if (condition === 'doesNotContain') {
          categoryKeyProcessed = `NOT ${categoryKeyProcessed}`;
        }
        categoryKeyProcessed = `${categoryKeyProcessed}: `;
      }
    } else {
      const subOption: Array<string> = [];
      if (category === 'label_section_search' && subCategory.length !== 0) {
        subCategory
          .filter(
            (itemValue: { id: string; label: string }) => itemValue.id !== 'all_label_sections'
          )
          .forEach((opt: { id: string; label: string }) => {
            subOption.push(opt.id);
          });
        categoryKeyProcessed =
          subOption.length !== 0
            ? `${categoryKeyProcessed}:[${subOption.join(',')}]`
            : categoryKeyProcessed;
      }
      categoryKeyProcessed = `${categoryKeyProcessed}:`;
    }
    const searchTermCategoryPair = `${categoryKeyProcessed}${normalizedSearchTerm}`;
    currentGroupCondition = compareCondition;
    if (searchTermCategoryPair) {
      if (groups.length === 0) {
        groups.push(preBracket);
      } else {
        const lastItem = groups[groups.length - 1];
        if (lastItem === AND_LITERAL) {
          groups.push(preBracket);
        }
      }
      groups.push(searchTermCategoryPair);
      if (!currentGroupCondition || currentGroupCondition === 'AND') {
        groups.push(postBracket);
        groups.push(AND_LITERAL);
      } else {
        groups.push(OR_LITERAL);
      }
    }
  });

  if (groups[groups.length - 1] === AND_LITERAL) {
    groups.pop();
  }
  if (titleText) {
    return groups;
  }
  return groups.join('');
};

const isSourceCt = (sources: any) => {
  return (
    Object.keys(sources).length === 1 &&
    (sources?.ct || (sources?.eu && sources?.eu.includes('euctr')))
  );
};

export const handleAdvancedSearch = (
  listData: any[],
  advancedSearch: any,
  selectedSources: any,
  searchId: string | null = null
) => {
  /**

   Generates a URL path with query parameters for an advanced search.
   @param {AdvancedSearchRow[]} listData - An array of objects representing the advanced search rows.
   @param {any} advancedSearch - The advanced search object containing the date range and synonyms enabled.
   @param {any} selectedSources - The sources for the advanced search.
   @param {string} searchId - The search ID for the advanced search.
   */

  const isSourceOnlyCt = isSourceCt(selectedSources);

  const payload: any = {
    search_type: 'advanced',
    search_term: generateCNFQuery(listData, null),
    source: selectedSources,
    approval_date: {
      start_date: advancedSearch?.startDate ? format(advancedSearch.startDate, 'yyyy-MM-dd') : '',
      end_date: advancedSearch?.endDate ? format(advancedSearch.endDate, 'yyyy-MM-dd') : ''
    },
    use_synonyms: advancedSearch?.useSynonyms,
    view_type: isSourceOnlyCt ? RESULT_VIEW_TYPES.CT : RESULT_VIEW_TYPES.APPLICATION,
    application_search_id: searchId
  };
  if (!searchId && 'application_search_id' in payload) {
    delete payload.application_search_id;
  }

  if (isSourceOnlyCt && searchId) {
    payload.ct_search_id = searchId;
  }

  const searchString = encodeObjectToBase64(payload);
  return `/search/${searchString}`;
};

export const advancedSearchLogicForResults = (urlParams: any, module: string, user: any) => {
  /**
   * Generates the logic payload for the results of an advanced search.
   * @param {any} urlParams - The URL parameters containing the search parameters.
   * @param {string} module - The module or section of the application for the search.
   * @param {any} user - The user object containing user information.
   * @returns {Object} The logic payload for the advanced search results.
   * @description This function takes the URL parameters, module, and user information and generates the logic payload for the results of an advanced search. The urlParams parameter contains the search parameters extracted from the URL. The module parameter specifies the module or section of the application. The user parameter contains the user object with user information.
   * The function decrypts the ElasticSearch query from the URL parameters using the decryptQuery function. If the module is '505b2', it extracts additional information like the approval pathway, route, formulations, and dates from the decrypted query. The extracted information is assigned to the approvalPathway object.
   * The function creates a newAdvanceSearch object that includes the decrypted query, date filter, use of synonyms, approval pathway, module, and user role. The date filter is added if the module is not '505b2'.
   * The resulting logic payload, newAdvanceSearch, is returned as an object.
   */
  const decryptedElasticQuery = decryptQuery(urlParams.get('cnfQuery') || '');
  // 505b2 query required for new advance search
  let approvalPathway: ApprovalPathwayPayload = {};
  if (module === '505b2') {
    // Extracts the approval pathway, route, formulations, and dates from the decrypted query.
    const { route, formulations } = getApprovalPayload(decryptedElasticQuery);
    approvalPathway = {
      route,
      formulations,
      dates: {}
    };
    if (approvalPathway?.route) {
      if (approvalPathway?.route.from) {
        approvalPathway.route.from = approvalPathway.route.from.toUpperCase();
      }
      if (approvalPathway.route.to) {
        approvalPathway.route.to = approvalPathway.route.to.toUpperCase();
      }
    }
    if (approvalPathway.formulations) {
      if (approvalPathway.formulations.from) {
        approvalPathway.formulations.from = approvalPathway.formulations.from.toUpperCase();
      }
      if (approvalPathway.formulations.to) {
        approvalPathway.formulations.to = approvalPathway.formulations.to.toUpperCase();
      }
    }
    if (urlParams.has('startDate')) {
      approvalPathway.dates = {
        ...approvalPathway.dates,
        from: urlParams.get('startDate') || ''
      };
    }
    if (urlParams.has('endDate')) {
      approvalPathway.dates = {
        ...approvalPathway.dates,
        to: urlParams.get('endDate') || ''
      };
    }
  }
  // cnf query required for new advance search
  const newAdvanceSearch = {
    query: decryptedElasticQuery,
    date_filter: {},
    use_synonyms: urlParams?.get('useSynonyms') === 'true',
    approvalPathway,
    module,
    role: user.attributes['custom:role']
  };
  if (module !== '505b2') {
    if (urlParams.has('startDate')) {
      newAdvanceSearch.date_filter = {
        ...newAdvanceSearch.date_filter,
        start_date: urlParams.get('startDate') || ''
      };
    }
    if (urlParams.has('endDate')) {
      newAdvanceSearch.date_filter = {
        ...newAdvanceSearch.date_filter,
        end_date: urlParams.get('endDate') || ''
      };
    }
  }
  return newAdvanceSearch;
};

function splitCNFQuery(cnfQuery: string) {
  return cnfQuery.split(/\s*AND(?!\w)\s*/).map(s => s.trim());
}

const parseClause = (s: any) => {
  let category = '';
  let searchTerm = '';
  let exactMatch = false;
  let condition = 'contains';
  let categoryKey = '';
  const subCategory: Array<any> = [];
  let subCategoryValue = '';
  const queryList = s.split(':');
  // since we store query in this format catgeory:[sections list]:searchterm
  if (queryList.length === 3) {
    [category, subCategoryValue, searchTerm] = queryList;
  } else {
    [category, searchTerm] = queryList;
  }
  categoryKey = category;
  if (searchTerm.startsWith('"') && searchTerm.endsWith('"')) {
    exactMatch = true;
    searchTerm = searchTerm.slice(1, -1);
  }

  if (category.startsWith('~')) {
    categoryKey = category;
    category = category.slice(1);
    condition = 'doesNotContain';
  }
  if (subCategoryValue) {
    const subCategoryList = subCategoryValue.slice(1, -1).split(','); // converting string list of section to list of section "[indication and uasge]" to ['indication and usage' ]
    subCategoryList.forEach((item: string) => {
      subCategory.push({
        id: item,
        label: getOptionLabel(item)
      });
    });
  }
  if (category === 'label_section_search' && !subCategoryValue) {
    subCategory.push({
      id: 'all_label_sections',
      label: 'All Label Sections'
    });
  }
  return {
    category,
    categoryKey,
    subCategory,
    condition,
    id: v4(),
    searchTerm,
    exactMatch
  };
};
export const cnfToAdvancedSearch = (cnfQuery: string) => {
  if (!cnfQuery) return [];
  const resultArrayList: any = [];
  const clauses = splitCNFQuery(cnfQuery);
  clauses.forEach((clause: any) => {
    // eslint-disable-next-line no-param-reassign
    clause = clause.replace(/[()]/g, '').replace('primary', '(primary)'); // Remove the parentheses
    const orSplit = clause.split(OR_LITERAL);
    if (orSplit.length > 1) {
      orSplit.forEach((orClause: any, index: number) => {
        resultArrayList.push({
          ...parseClause(orClause),
          createdOrder: resultArrayList.length + 1,
          compareCondition: orSplit.length > index + 1 ? 'OR' : 'AND'
        });
      });
    } else {
      resultArrayList.push({
        ...parseClause(clause),
        createdOrder: resultArrayList.length + 1,
        compareCondition: 'AND'
      });
    }
  });
  return resultArrayList;
};

export const elasticQueryToAdvancedSearch = (urlParams: any) => {
  const returnData: {
    advanceSearchRowData: any[];
    dateRange: {
      startDate: Date | null;
      endDate: Date | null;
    };
    synonymsEnabled: boolean;
  } = {
    advanceSearchRowData: [],
    dateRange: {
      startDate: null,
      endDate: null
    },
    synonymsEnabled: false
  };
  if (urlParams.has('useSynonyms')) {
    returnData.synonymsEnabled = urlParams.get('useSynonyms').trim().toLowerCase() === 'true';
  }
  if (urlParams.has('startDate')) {
    const startDate: string = urlParams.get('startDate') as string;
    returnData.dateRange.startDate = urlDateToLocalDate(startDate);
  }
  if (urlParams.has('endDate')) {
    const endDate: string = urlParams.get('endDate') as string;
    returnData.dateRange.endDate = urlDateToLocalDate(endDate);
  }
  const cnfQuery = decryptQuery(urlParams.get('cnfQuery') || '');

  returnData.advanceSearchRowData = cnfToAdvancedSearch(cnfQuery);
  return returnData;
};

export const handle505b2AdvancedSearch = (
  searchText: {
    from: string;
    to: string;
  },
  type: 'route' | 'formulation' | string,
  dateRange: {
    startDate: Date | null;
    endDate: Date | null;
  },
  searchId: string | null
) => {
  const route = {
    from: '',
    to: ''
  };
  const formulations = {
    from: '',
    to: ''
  };

  const elasticQuery: any = {
    route: {},
    formulations: {}
  };
  if (!searchText.from || !searchText.to) {
    return null;
  }
  let searchTerm = '';
  if (type === 'route') {
    route.from = searchText.from.toUpperCase();
    route.to = searchText.to.toUpperCase();
    elasticQuery.route = { from: `${route.from}`, to: `${route.to}` };
    searchTerm = `${type}: From ${route.from} TO ${route.to}`;
  }
  if (type === 'formulation') {
    formulations.from = searchText.from.toUpperCase();
    formulations.to = searchText.to.toUpperCase();
    elasticQuery.formulations = { from: `${formulations.from}`, to: `${formulations.to}` };
    searchTerm = `formulations: From ${formulations.from} TO ${formulations.to}`;
  }
  const approvalDate: any = {};
  if (dateRange?.startDate) {
    approvalDate.start_date = format(dateRange.startDate, 'yyyy-MM-dd');
  }
  if (dateRange?.endDate) {
    approvalDate.end_date = format(dateRange.endDate, 'yyyy-MM-dd');
  }
  const searchString = encodeObjectToBase64({
    search_type: 'advanced',
    search_term: searchTerm,
    approvalPathway: elasticQuery,
    source: { us: ['505b2', 'sbas'] },
    approval_date: approvalDate,
    searchId
  });
  return `/search/${searchString}`;
};
