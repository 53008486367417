const styles = {
  toolsHeader: {
    display: 'flex',
    bgcolor: 'gray.scrollBarBg',
    p: 1.5,
    pl: 5,
    alignItems: 'center',
    ' @keyframes rotate-animation': {
      '50%': { transform: 'rotate(-180deg)' },
      '100%': { transform: 'rotate(-360deg)' }
    }
  },
  syncIcon: {
    color: 'gray.main',
    fontSize: 16,
    animation: 'rotate-animation 3s infinite'
  },
  downloadButton: {
    bgcolor: 'primary.main',
    color: 'white.main',
    ml: 2,
    fontSize: 14,
    pl: 2,
    pr: 2,
    fontWeight: 'bold'
  },
  downloadOption: {
    fontSize: 10.5,
    textTransform: 'none',
    fontWeight: 600,
    border: '1px solid'
  },
  shareIcon: { position: 'absolute', right: 64, top: 14, zIndex: 1001 },
  saveButton: { color: 'primary.main', fontSize: 14, pl: 2, pr: 2, fontWeight: 'bold' },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    pt: 2,
    pb: 2,
    width: '100%'
  }
};

export default styles;
