import { isEqual } from 'lodash';
import {
  getSubscriptionSourcePreference,
  getSubscriptionStatus
} from '../../api/modules/userNotification';
import {
  cnfToAdvancedSearch,
  generateCNFQuery,
  handle505b2AdvancedSearch,
  handleAdvancedSearch,
  urlDateToLocalDate
} from '../../components/Header/utils/advanceSearchHelpers';
import { SearchData } from './components/NotificationInterface';
import { CHANNELS } from './constants';
import { ELASTIC_SEARCH_ATTRIBUTES_LABELS } from '../../components/Header/utils/advanceSearchConstants';
import RESULT_VIEW_TYPES from '../SearchResults/components/constants';
import { encodeObjectToBase64 } from '../../utils/encodeDecodeObject';

const getSourceSubscriptions = async () => {
  try {
    const response = await getSubscriptionSourcePreference();
    if (response?.status !== 200) return {};
    const preferencesList = response?.data?.body?.source_preferences ?? [];
    const subscriptions = preferencesList.reduce(
      (acc: Record<string, boolean>, preference: any) => {
        if (preference?.source) {
          acc[preference.source] = !!preference.subscribed;
        }
        return acc;
      },
      {}
    ) as Record<string, boolean>;
    return subscriptions;
  } catch (e) {
    return {};
  }
};

const getEntitySubscriptionStatus = async ({
  source,
  entityType,
  entityId
}: {
  source: string;
  entityType: (typeof CHANNELS)[number];
  entityId: string;
}): Promise<boolean> => {
  try {
    const subscriptionStatus = await getSubscriptionStatus({ source, entityId, entityType });
    return subscriptionStatus?.data?.body?.is_subscribed ?? false;
  } catch (e) {
    return false;
  }
};

const getQuickSearchTitle = (searchInfo: SearchData | undefined) => {
  if (searchInfo?.category === 'first_approval_date') {
    return searchInfo?.searchData?.first_approval_date?.join(' - ') ?? '';
  }
  if (
    searchInfo?.category === 'approval_date' &&
    Array.isArray(searchInfo?.searchData?.approval_date)
  )
    return searchInfo?.searchData?.approval_date?.join(' - ') ?? '';
  return searchInfo?.term ?? '';
};

const getQuickSearchSubtitle = (searchInfo: SearchData | undefined) => {
  let localPath = '';
  if (searchInfo?.category_label) {
    localPath += `${searchInfo?.category_label ?? ''}`;
  }
  if (searchInfo?.filters && Object.keys(searchInfo?.filters)?.length !== 0) {
    if (localPath) localPath += ` | `;
    localPath += 'With filters';
  }
  return localPath;
};

const get505b2Data = (searchInfo: SearchData | undefined) => {
  const { searchData, source: searchSource } = searchInfo ?? {};

  const is505b2Data = {} as any;
  if (isEqual(searchSource, { us: ['505b2'] })) {
    Object.entries(searchData).forEach(([key, items]) => {
      if (items) {
        const from = (items as any)?.from;
        const to = (items as any)?.to;
        if (from && to) {
          is505b2Data[key] = `${from} -> ${to}`;
        }
      }
    });
  }
  return is505b2Data;
};

const getAdvancedSearchTitle = (searchInfo: SearchData | undefined) => {
  let localSearch: string | string[] = '';
  let payload = [];
  const { cnf_query: cnfQuery, source: searchSource } = searchInfo ?? {};
  const currentSource = Object.keys(searchSource ?? {})?.[0] ?? '';
  if (!currentSource)
    return { localSearch: 'Advanced Search | No source found', advanceSearchPayload: [] }; // This should never happen

  const is505b2Data = get505b2Data(searchInfo);
  if (Object.keys(is505b2Data).length > 0) {
    Object.entries(is505b2Data).forEach(([key, value]) => {
      if (localSearch) localSearch += ` | `;
      localSearch += `${key}: ${value}`;
    });
  } else {
    payload = cnfToAdvancedSearch(cnfQuery ?? '');
    localSearch = generateCNFQuery(
      payload,
      // @ts-ignore
      ELASTIC_SEARCH_ATTRIBUTES_LABELS[currentSource.toLowerCase()]
    );
  }
  return { localSearch, advanceSearchPayload: payload };
};

const getAdvancedSearchSubtitle = (searchInfo: SearchData | undefined) => {
  let localPath = 'Advanced Search';
  const { filters, dateFilter, source: searchSource } = searchInfo ?? {};
  const currentSource = Object.keys(searchSource ?? {})?.[0];
  if (!currentSource) return 'Advanced Search | No source found';
  const is505b2Data = get505b2Data(searchInfo);
  if (Object.keys(is505b2Data).length > 0) localPath = '505(b)(2)';
  if (dateFilter && Object.keys(dateFilter).length !== 0) {
    const localDateFilter = {
      startDate: '',
      endDate: ''
    };
    if (dateFilter.start_date) {
      localDateFilter.startDate = urlDateToLocalDate(dateFilter.start_date)
        .toISOString()
        ?.split('T')[0];
    }
    if (dateFilter.end_date) {
      localDateFilter.endDate = urlDateToLocalDate(dateFilter.end_date)
        .toISOString()
        ?.split('T')[0];
    }
    localPath += ` | ${localDateFilter.startDate} - ${localDateFilter.endDate}`;
  }
  if (filters && Object.keys(filters)?.length !== 0) {
    if (localPath) localPath += ` | `;
    localPath += 'With filters';
  }

  return localPath;
};

const handleSearchClick = ({ searchInfo }: { searchInfo: SearchData | undefined }) => {
  const { term, source: searchSource, id: searchId } = searchInfo ?? {};
  const payload = {
    search_term: term,
    source: searchSource,
    application_search_id: searchId,
    view_type: RESULT_VIEW_TYPES.APPLICATION
  };
  const encodedPayload = encodeObjectToBase64(payload) ?? '';
  const url = `/search/${encodedPayload}`;
  window.open(url, '_blank');
};

const handleAdvancedSearchClick = async ({
  searchInfo,
  advanceSearchPayload
}: {
  searchInfo: SearchData | undefined;
  advanceSearchPayload: any[];
}) => {
  const {
    searchData,
    dateFilter = {},
    id: searchId,
    use_synonyms: useSynonyms = true,
    source: searchSource
  } = searchInfo ?? {};
  let url = '';
  if ('formulations' in searchData || 'route' in searchData) {
    url =
      handle505b2AdvancedSearch(
        (searchData as { [key: string]: { from: string; to: string } })[Object.keys(searchData)[0]],
        'formulations' in searchData ? 'formulation' : 'route',
        {
          startDate: dateFilter.start_date ? new Date(dateFilter.start_date) : null,
          endDate: dateFilter.end_date ? new Date(dateFilter.end_date) : null
        },
        searchId ?? ''
      ) ?? '';
  } else {
    const otherData: {
      startDate: string | Date;
      endDate: string | Date;
      useSynonyms: boolean;
    } = {
      startDate: '',
      endDate: '',
      useSynonyms
    };
    if ('start_date' in dateFilter) {
      otherData.startDate = urlDateToLocalDate(dateFilter.start_date);
    }
    if ('end_date' in dateFilter) {
      otherData.endDate = urlDateToLocalDate(dateFilter.end_date);
    }
    url = handleAdvancedSearch(advanceSearchPayload, otherData, searchSource, searchId ?? '');
  }
  window.open(url, '_blank');
};

export {
  getAdvancedSearchSubtitle,
  getAdvancedSearchTitle,
  getEntitySubscriptionStatus,
  getSourceSubscriptions,
  getQuickSearchSubtitle,
  getQuickSearchTitle,
  handleAdvancedSearchClick,
  handleSearchClick
};
