import React, { useState, useContext } from 'react';
import { isObject, has } from 'lodash';

import { Typography, Divider, Stack, Tab, Tabs, Link, Box, Button, Popover } from '@mui/material';

import GlobalActions from '../../../store/actions';
import GlobalStore from '../../../store';
import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import { PlusIcon } from '../../../assets/svgs/Icons';
import CustomDropdown from './CustomDropdown';

import styles from './styles';

interface AttributeDetails {
  title: string;
  description?: string;
}
type AttributeValue = string | null | AttributeDetails;

const Attribute: React.FC<{
  attributeName: string;
  attributeValue: AttributeValue;
  attrValueStyleMapping: { [key: string]: any };
}> = ({ attributeName, attributeValue, attrValueStyleMapping }) => {
  if (!attributeValue || attributeName === 'id') return null;

  return (
    <Stack mb={1} spacing={2}>
      {attributeValue && isObject(attributeValue) ? (
        <Stack spacing={2}>
          {has(attributeValue, 'title') && (
            <Stack direction='row' spacing={2}>
              <Typography variant='subtitle2' sx={styles.snapshotLabel}>
                {attributeName}:&nbsp;
              </Typography>
              <Typography variant='subtitle2' sx={styles.snapshotText}>
                {attributeValue.title}
              </Typography>
            </Stack>
          )}
          {has(attributeValue, 'description') && (
            <Stack direction='row' spacing={2}>
              <Typography variant='subtitle2' sx={styles.snapshotLabel}>
                Description:&nbsp;
              </Typography>
              <Typography variant='subtitle2' sx={styles.snapshotText}>
                {attributeValue?.description}
              </Typography>
            </Stack>
          )}
        </Stack>
      ) : (
        <Stack mb={1} direction='row' spacing={2}>
          <Typography variant='subtitle2' sx={styles.snapshotLabel}>
            {attributeName}:&nbsp;
          </Typography>
          {attributeName === 'NCT ID' ? (
            <Link
              target='_blank'
              href={`${window.location.origin}/regulatory360/core/${attributeValue}/ct?tabname=approvalHistory`}>
              {attributeValue}
            </Link>
          ) : (
            <Typography
              variant='subtitle2'
              sx={attrValueStyleMapping[attributeValue] || attrValueStyleMapping.default}>
              {attributeValue}
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
};

const ClinicalStudies = ({ item, payload }: any) => {
  const { state, dispatch } = useContext<any>(GlobalStore);
  const { regulatoryState } = useContext<any>(RegulatoryInformationStore);
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const areAllKeysNonEmpty = (data: any, index: any, group: any) => {
    const obj = data?.studies?.[index]?.[group];
    return obj
      ? Object.values(obj).every(val => val !== '' && val !== null && val !== undefined)
      : false;
  };

  const attrValueStyleMapping: {
    [key: string]: any;
  } = {
    Yes: styles.snapshotYesText,
    No: styles.snapshotNoText,
    default: styles.snapshotText
  };

  const addIndicationToReport = () => {
    const studiesIdentifier = item?.studies?.flatMap((obj: any) =>
      Object.values(obj)
        .map((subObj: any) => subObj.Identifier)
        .filter(identifier => identifier !== undefined)
    );
    const id = `snapshot_clinical_summary_${payload?.source}_${payload?.number}_${payload?.submission_type}_${payload?.submission_num}`;
    dispatch({
      type:
        state?.reportLayout?.sections?.length > 0 &&
        state?.reportLayout?.sections?.some((section: any) => section?.id === id)
          ? GlobalActions.UPDATE_SNAPSHOT_REPORT_LAYOUT
          : GlobalActions.ADD_TEMPLATE_TO_REPORT,
      value: {
        id,
        sectionType: 'SNAPSHOT',
        data: {
          ...payload,
          indicationStudies: { [item?.text]: studiesIdentifier },
          summaryType: 'CLINICAL_SUMMARY'
        },
        style: {
          placement: {
            h: 45,
            w: 12
          },
          title: `Clinical Summary - ${regulatoryState?.applicationDetails?.product_name} - ${payload?.submission_type} - ${payload?.submission_num}`
        }
      }
    });
    dispatch({
      type: GlobalActions.SET_ALERT,
      value: { status: true, message: 'Successfully added to Report', color: 'success' }
    });
  };

  const addStudyToReport = (selectedStudies: number[]) => {
    const indicationStudy: string[] = item?.studies
      ?.filter((each: any, index: number) => selectedStudies?.includes(index))
      .map((study: any) =>
        Object.values(study).find((subObj: any) => subObj.Identifier !== undefined)
      );
    const id = `snapshot_clinical_summary_${payload.source}_${payload.number}_${payload.submission_type}_${payload.submission_num}`;
    dispatch({
      type:
        state?.reportLayout?.sections?.length > 0 &&
        state?.reportLayout?.sections?.some((section: any) => section?.id === id)
          ? GlobalActions.UPDATE_SNAPSHOT_REPORT_LAYOUT
          : GlobalActions.ADD_TEMPLATE_TO_REPORT,
      value: {
        id,
        sectionType: 'SNAPSHOT',
        data: {
          ...payload,
          indicationStudies: {
            [item?.text]: indicationStudy?.map((each: any) => each.Identifier)
          },
          summaryType: 'CLINICAL_SUMMARY'
        },
        style: {
          placement: {
            h: 45,
            w: 12
          },
          title: `Clinical Summary - ${regulatoryState?.applicationDetails?.product_name} - ${payload?.submission_type} - ${payload?.submission_num}`
        }
      }
    });
    dispatch({
      type: GlobalActions.SET_ALERT,
      value: { status: true, message: 'Successfully added to Report', color: 'success' }
    });
  };

  const handleAddToReport = (selectedOption: any, selectedStudies: number[]) => {
    if (selectedOption === 'all') {
      addIndicationToReport();
    } else if (selectedOption === 'selected') {
      addStudyToReport(selectedStudies);
    } else {
      dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Please select an option.', color: 'error' }
      });
    }
    setAnchorEl(null);
  };

  const handleAdd = (ev: any) => {
    if (item?.studies?.length > 1) {
      setAnchorEl(ev.currentTarget);
    } else {
      // we have only one study in indication so directly adding all study of that indication
      addIndicationToReport();
    }
  };

  return (
    <Stack spacing={2} mt={-2}>
      <Box display='flex' justifyContent='space-between'>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          TabIndicatorProps={{
            sx: { borderWidth: '2px', borderStyle: 'solid', borderColor: 'primary.600' }
          }}>
          {item?.studies?.map((study: any, index: number) => {
            return (
              <Tab key={`Study ${index + 1}`} label={`Study ${index + 1}`} sx={styles.tabHeading} />
            );
          })}
        </Tabs>
        <Button sx={{ mr: 2.5 }} size='small' onClick={(ev: any) => handleAdd(ev)}>
          <Box display='flex' gap={0.5} alignItems='center'>
            <PlusIcon sx={{ fontSize: 14, color: 'primary.700' }} />
            <Typography color='primary.700' fontSize={14} textTransform='none'>
              Add to report
            </Typography>
          </Box>
        </Button>
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: 2
            }
          }}>
          <CustomDropdown data={item} handleAddToReport={handleAddToReport} />
        </Popover>
      </Box>

      <Stack spacing={2} p={1}>
        {Object.keys(item?.studies[value] || {}).map(group => (
          <React.Fragment key={group}>
            {Object.entries(item.studies[value][group]).map(([attributeName, attributeValue]) => (
              <Attribute
                key={`${group}-${attributeName}`} // Construct a stable key based on group and attribute name
                attributeName={attributeName}
                attributeValue={attributeValue as AttributeValue}
                attrValueStyleMapping={attrValueStyleMapping}
              />
            ))}
            {areAllKeysNonEmpty(item, value, group) && <Divider />}
          </React.Fragment>
        ))}
      </Stack>
    </Stack>
  );
};

export default ClinicalStudies;
