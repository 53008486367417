import { Box, List, ListItem, ListItemAvatar, ListItemText, Skeleton } from '@mui/material';
import React, { memo } from 'react';

const Loading = ({ type }) => {
  switch (type) {
    case 'card':
      return (
        <>
          {Array(10)
            .fill(1)
            .map((data, index) => (
              <Skeleton variant='rounded' width={210} height={60} key={index} />
            ))}
        </>
      );
    default:
      return (
        <Box height='100%'>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {Array(10)
              .fill(1)
              .map((data, index) => (
                <ListItem fullWidth disablePadding key={index}>
                  <ListItemAvatar>
                    <Skeleton variant='circular' width={50} height={50} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Skeleton variant='text' sx={{ fontSize: '1rem', width: 100 }} />}
                    secondary={<Skeleton variant='text' sx={{ fontSize: '1rem', width: 500 }} />}
                  />
                </ListItem>
              ))}
          </List>
        </Box>
      );
  }
};
Loading.defaultProps = {
  type: 'list'
};

export default memo(Loading);
