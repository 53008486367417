import { useState } from 'react';
// mui
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Styles
import styles from '../styles/MarketingStatus.styles';

// svg's
import DiscontinuedIcon from '../../../assets/svgs/CardsPage/Discontinued';
import PrescriptionIcon from '../../../assets/svgs/CardsPage/Prescription';
import OverTheCounterIcon from '../../../assets/svgs/CardsPage/OvertheCounter';
import TentativeIcon from '../../../assets/svgs/CardsPage/Tentative';

const iconComponent = {
  Discontinued: <DiscontinuedIcon />,
  'Over-the-counter': <OverTheCounterIcon />,
  Prescription: <PrescriptionIcon />,
  'Prescription Only': <PrescriptionIcon />,
  'None (Tentative Approval)': <TentativeIcon />,
  MARKETED: <PrescriptionIcon />,
  'CANCELLED POST MARKET': <DiscontinuedIcon />,

  APPROVED: <PrescriptionIcon />,
  'AUTHORIZATION BY INTERIM ORDER EXPOSED': <TentativeIcon />,
  'AUTHORIZATION BY INTERIM ORDER REVOKED': <TentativeIcon />,
  'CANCELLED PRE MARKET': <DiscontinuedIcon />,
  'CANCELLED (SAFETY ISSUE)': <DiscontinuedIcon />,
  'CANCELLED (UNRETURNED ANNUAL)': <DiscontinuedIcon />,
  DORMANT: <TentativeIcon />,
  UNKNOWN: <TentativeIcon />,
  'RESTRICTED ACCESS': <DiscontinuedIcon />,
  'Not marketed': <DiscontinuedIcon />,
  Marketed: <PrescriptionIcon />
};

const auxilaryText = {
  Discontinued: 'Discontinued',
  'Over-the-counter': 'Over the Counter',
  Prescription: 'Prescription',
  'Prescription Only': 'Prescription Only',
  'None (Tentative Approval)': 'Tentative',
  MARKETED: 'MARKETED',
  'CANCELLED POST MARKET': 'CANCELLED POST MARKET',
  APPROVED: 'APPROVED',
  'AUTHORIZATION BY INTERIM ORDER EXPOSED': 'AUTHORIZATION BY INTERIM ORDER EXPOSED',
  'AUTHORIZATION BY INTERIM ORDER REVOKED': 'AUTHORIZATION BY INTERIM ORDER REVOKED',
  'CANCELLED PRE MARKET': 'CANCELLED PRE MARKET',
  'CANCELLED (SAFETY ISSUE)': 'CANCELLED (SAFETY ISSUE)',
  'CANCELLED (UNRETURNED ANNUAL)': 'CANCELLED (UNRETURNED ANNUAL)',
  DORMANT: 'DORMANT',
  'RESTRICTED ACCESS': 'RESTRICTED ACCESS',
  'Not marketed': 'NOT MARKETED',
  Marketed: 'MARKETED',
  UNKNOWN: 'UNKNOWN'
};
// eslint-disable-next-line react/prop-types
const MarketingStatus = ({ marketingstatus }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <Stack>
      <Box onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        {iconComponent[marketingstatus]}
      </Box>
      {isHovering && (
        <Typography sx={styles.description}> {auxilaryText[marketingstatus]} </Typography>
      )}
    </Stack>
  );
};

export default MarketingStatus;
