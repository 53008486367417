import * as React from 'react';
import { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Tab, Tabs, Typography } from '@mui/material';
import { FormatListBulleted } from '@mui/icons-material';
import Styles from '../styles/UserTab.styles';
import UserProfileListView from '../UserProfileListView';
import Journey from '../../../assets/svgs/UserProfile/Journey';
import UserProfileActions from '../../../store/UserProfile/actions';
import { toast } from 'react-toastify';
import UserProfileStore from '../../../store/UserProfile';

export const TabPanel = memo(props => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ maxHeight: '90%', overflow: 'auto', height: '100%' }}
      {...other}>
      {value === index && children}
    </Box>
  );
});

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export const a11yProps = index => {
  return {
    id: `activity-tab-${index}`,
    'aria-controls': `activity-tabpanel-${index}`
  };
};

const Activity = () => {
  const classes = Styles();

  const [selectedTab, setSelectedTab] = useState('searches');
  const [loading, setLoading] = useState(false);
  const { location } = window;
  const { profileState, profileDispatch, fetchPaginatedUserActivity } =
    useContext(UserProfileStore);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.set('tab', newValue);
    window.history.pushState({}, '', `${location.pathname}?${updatedSearchParams.toString()}`);
    window.history.replaceState({}, '', `${location.pathname}?${updatedSearchParams.toString()}`);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      await fetchPaginatedUserActivity();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };
  useEffect(() => {
    if (profileState.refetch) {
      fetchData();
      profileDispatch({ type: UserProfileActions.SET_REFETCH, value: false });
    }
  }, [profileState.refetch]);
  useEffect(() => {
    fetchData();
    return () => {
      profileDispatch({ type: UserProfileActions.SET_PAGINATION_ON_SCROLL, value: true });
      profileDispatch({ type: UserProfileActions.CLEAR_USER_ACTIVITY_DATA });
    };
  }, []);
  useEffect(() => {
    const curSelectedTab = new URLSearchParams(location.search).get('tab') || 'searches';
    if (curSelectedTab !== selectedTab) {
      setSelectedTab(curSelectedTab);
    }
  }, [location.search]);
  return (
    <Box sx={{ width: '100%', maxHeight: '100%', height: '100%', overflow: 'hidden' }}>
      <Typography variant='h2' gutterBottom>
        Activity
      </Typography>
      <Divider />
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        aria-label='Activity tabs'
        className={classes.tabs}>
        <Tab
          selected={selectedTab === 'searches'}
          icon={<FormatListBulleted />}
          iconPosition='start'
          label='Searches/Queries'
          value='searches'
          {...a11yProps(0)}
        />
        <Tab
          icon={
            <Box>
              <Journey />
            </Box>
          }
          selected={selectedTab === 'journey'}
          iconPosition='start'
          label=' Journey'
          value='journey'
          {...a11yProps(1)}
        />
      </Tabs>
      <UserProfileListView dateSeparated tab={selectedTab} loading={loading} />
    </Box>
  );
};

export default Activity;
