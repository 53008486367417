const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'all 2s ease-in-out',
    zIndex: 1000,
    minWidth: '90%'
  },
  cardBox: {
    padding: '3px 8px',
    backgroundColor: 'white.main',
    borderRadius: '8px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.400'
  },
  flag: { width: '18px', height: '13px', ml: '12px' },
  name: {
    fontWeight: 700,
    margin: 0,
    fontSize: '16px',
    whiteSpace: 'nowrap',
    color: 'primary.600'
  },

  tagText: {
    maxWidth: '35%',
    pl: 1,
    pr: 1,
    pt: 0.5,
    pb: 0.5,
    borderRadius: '50px',
    backgroundColor: 'gray.light',
    color: 'white.main'
  },
  iconTag: {
    fontSize: 8
  },
  tagContent: {
    color: 'white.dark',
    fontWeight: 'bold'
  },
  activeIngredient: {
    padding: '0 8px',
    whiteSpace: 'nowrap'
  }
};

export default styles;
