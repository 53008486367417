/* eslint-disable no-nested-ternary */
import { useEffect, useState, useCallback, SyntheticEvent } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import { ThemeProvider } from '@mui/material/styles';
import PDFViewer from '../../../components/PDFViewer/index';
import { ReadOnlyMarkdown } from '../../../components/ChatRIA/component/MarkdownBox';

import { getLatestSubmissonSection } from '../../../api/pages/CardsPage';
import themev5 from '../../../themev5';
import styles from '../styles/IndicationsTab.styles';

const PosologyTab = ({ applnum, section, dosageAdministrationField }: any) => {
  const [posologyTab, setPosologyTab] = useState<any>({});
  const [value, setValue] = useState<number>(0);

  const handleChange = useCallback((event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }, []);

  const getData = async () => {
    try {
      const response = await getLatestSubmissonSection(applnum, section);
      if (response && response.status === 200) {
        setPosologyTab(response);
      } else {
        // eslint-disable-next-line no-console
        console.error(response.Error);
        setPosologyTab({});
      }
    } catch (error) {
      setPosologyTab({});
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <ThemeProvider theme={themev5}>
      {posologyTab && posologyTab.data ? (
        <Box sx={styles.indications_container}>
          {Array.isArray(posologyTab?.data.Success) ? (
            <Box display='flex'>
              {posologyTab?.data.Success.length !== 1 ? (
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant='scrollable'
                  scrollButtons={false}
                  orientation='vertical'
                  centered
                  aria-label='scrollable prevent tabs example'
                  sx={styles.tabs}>
                  {posologyTab.data.Success.map((row: any, index: number) => {
                    return (
                      <Tab
                        key={row.key}
                        label={row.key}
                        sx={index === value ? styles.activeTab : styles.inactiveTab}
                      />
                    );
                  })}
                </Tabs>
              ) : (
                <Box display='flex' flexDirection='column' sx={styles.indications_container}>
                  {posologyTab?.data?.Success[0].uri ? (
                    <PDFViewer fileUrl={posologyTab?.data?.Success[0].uri.toString()} />
                  ) : (
                    <Box sx={[styles.indications_container, styles.indications_field_container]}>
                      <ReadOnlyMarkdown markdown={dosageAdministrationField} />
                    </Box>
                  )}
                </Box>
              )}

              {Array.isArray(posologyTab?.data.Success) &&
                posologyTab.data.Success.length !== 1 &&
                // eslint-disable-next-line array-callback-return, consistent-return
                posologyTab.data.Success.map((row: any, index: number) => {
                  if (index === value) {
                    return (
                      <Stack
                        key={row.key}
                        mt={1}
                        flexDirection='row'
                        sx={{ overflow: 'auto', bgcolor: 'background.paper' }}
                        width='100%'>
                        {row.uri ? (
                          <PDFViewer fileUrl={row.uri.toString()} />
                        ) : (
                          <Box
                            sx={[styles.indications_container, styles.indications_field_container]}>
                            <ReadOnlyMarkdown markdown={dosageAdministrationField} />
                          </Box>
                        )}
                      </Stack>
                    );
                  }
                })}
            </Box>
          ) : (
            <Box sx={styles.indications_container}>
              {posologyTab?.data?.Success.uri ? (
                <PDFViewer fileUrl={posologyTab?.data?.Success?.uri.toString()} />
              ) : (
                <Box sx={[styles.indications_container, styles.indications_field_container]}>
                  <ReadOnlyMarkdown markdown={dosageAdministrationField} />
                </Box>
              )}
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={[styles.indications_container, styles.indications_field_container]}>
          Loading...
        </Box>
      )}
    </ThemeProvider>
  );
};

export default PosologyTab;
