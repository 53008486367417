import uniqueId from 'lodash/uniqueId';

/* eslint-disable import/prefer-default-export */
import { Stack, Grid, Skeleton } from '@mui/material';

import styles from '../styles/Loaders.styles';

import GraphSkeleton from '../../PediatricsEMA/components/GraphSkeleton';

export const MeetingSummaryLoader = () => {
  return (
    <Stack spacing={2} sx={styles.meetingSummaryContainer}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Skeleton
          variant='rectangular'
          width='40%'
          height={50}
          animation='wave'
          sx={{ borderRadius: 3 }}
        />
        <Skeleton
          variant='rectangular'
          width='10%'
          height={50}
          animation='wave'
          sx={{ borderRadius: 3 }}
        />
      </Stack>

      {Array.from({ length: 3 }, () => (
        <Stack spacing={1} key={uniqueId()}>
          <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='40%' />
          {Array.from({ length: 3 }, () => (
            <Skeleton key={uniqueId()} variant='rectangular' width='100%' height={40} />
          ))}
        </Stack>
      ))}
    </Stack>
  );
};
export const MeetingsLoader = () => {
  return (
    <Stack direction='column'>
      <Grid container spacing={2}>
        <Grid item xs={2} sx={{ bgcolor: 'none' }}>
          {/* meeting list */}
          <Stack spacing={2} sx={styles.meetingListContainer}>
            {Array.from({ length: 3 }, () => (
              <Stack spacing={1} key={uniqueId()}>
                <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='30%' />
                <Stack spacing={2}>
                  {Array.from({ length: 4 }, () => (
                    <Skeleton key={uniqueId()} variant='rectangular' width='100%' height={40} />
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={10}>
          <MeetingSummaryLoader />
        </Grid>
      </Grid>
    </Stack>
  );
};

export const InsightsLoader = () => {
  return (
    <Stack p={2} mt={2}>
      <Grid container spacing={2}>
        {/* grid for stats counts */}
        <Grid item xs={2}>
          <Stack spacing={4} direction='column'>
            {Array.from({ length: 3 }, () => (
              <Stack key={uniqueId()} sx={styles.statsCountContainer} justifyContent='center'>
                <Stack spacing={1} direction='column'>
                  <Skeleton variant='text' width='70%' />
                  <Skeleton variant='text' width='30%' />
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={10}>
          <Stack mt={0} spacing={4}>
            <Stack spacing={2} direction='row'>
              {/* basic info */}
              <Stack
                justifyContent='flex-start'
                alignItems='flex-start'
                spacing={2}
                sx={styles.basicInfoContainer}>
                <Skeleton variant='rounded' width='70%' />
                <Skeleton variant='rounded' width='100%' />
                <Skeleton variant='rounded' width='60%' />
                <Skeleton variant='rounded' width='30%' />
                <Skeleton variant='rounded' width='80%' />
                <Skeleton variant='rounded' width='60%' />
                <Skeleton variant='rounded' width='40%' />
              </Stack>

              {/* pie chart */}
              <Stack spacing={2} sx={styles.pieCharContainer}>
                <Skeleton variant='rounded' width='30%' />
                <GraphSkeleton />
              </Stack>
            </Stack>

            <Stack spacing={2} direction='row'>
              <Stack spacing={2} sx={styles.pieCharContainer}>
                <Skeleton variant='rounded' width='30%' />
                <GraphSkeleton />
              </Stack>
              <Stack
                justifyContent='flex-start'
                alignItems='flex-start'
                spacing={2}
                sx={styles.basicInfoContainer}>
                <Skeleton variant='rounded' width='70%' />
                <Skeleton variant='rounded' width='100%' />
                <Skeleton variant='rounded' width='60%' />
                <Skeleton variant='rounded' width='30%' />
                <Skeleton variant='rounded' width='80%' />
                <Skeleton variant='rounded' width='60%' />
                <Skeleton variant='rounded' width='40%' />
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};
