// eslint-disable-next-line import/prefer-default-export
export const PIECHARTCOLOR = ['#6868FA', '#EA5252', '#29ABE2', '#0A467C', '#C9D2F6', '#B1BBE4'];

export const SINGLEPLOTLAYOUT = {
  layout: {
    grid: {
      rows: 1,
      columns: 1,
      roworder: 'top to bottom',
      subplots: ['xy'],
      yaxes: ['y']
    },
    margin: {
      t: 50,
      b: 50
    },
    uirevision: 'true',
    autosize: true,
    title: '',
    showlegend: false,
    template: {
      layout: {
        xaxis: {
          ticks: '',
          title: {
            standoff: 15
          },
          automargin: true,
          zerolinewidth: 2
        },
        yaxis: {
          ticks: '',
          title: {
            standoff: 15
          },
          automargin: true,
          zerolinewidth: 2
        },
        hovermode: 'closest',
        hoverlabel: {
          align: 'left'
        }
      }
    },
    annotations: [],
    shapes: [],
    xaxis: {
      anchor: 'y',
      domain: [0, 1],
      type: 'date'
    },
    yaxis: {
      anchor: 'x',
      domain: [1, 1],
      title: {
        text: 'Clinical Trials'
      },
      showticklabels: false,
      categoryorder: 'array',
      categoryarray: '',
      rangemode: 'normal',
      fixedrange: false
    },
    colorway: ['#bdbdbd', '#777777'],
    barmode: 'overlay'
  }
};
export const TWOPLOTLAYOUT = {
  layout: {
    grid: {
      rows: 2,
      columns: 1,
      roworder: 'top to bottom',
      subplots: ['xy', 'xy2'],
      yaxes: ['y', 'y2']
    },
    margin: {
      t: 50,
      b: 50
    },
    uirevision: 'true',
    autosize: true,
    title: '',
    showlegend: false,
    template: {
      layout: {
        xaxis: {
          ticks: '',
          title: {
            standoff: 15
          },
          automargin: true,
          zerolinewidth: 2
        },
        yaxis: {
          ticks: '',
          title: {
            standoff: 15
          },
          automargin: true,
          zerolinewidth: 2
        },
        hovermode: 'closest',
        hoverlabel: {
          align: 'left'
        }
      }
    },
    annotations: [],
    shapes: [],
    xaxis: {
      anchor: 'y',
      domain: [0, 1],
      type: 'date'
    },
    yaxis: {
      anchor: 'x',
      domain: [0.2, 1],
      title: {
        text: 'Clinical Trials'
      },
      showticklabels: false,
      categoryorder: 'array',
      categoryarray: '',
      rangemode: 'normal',
      fixedrange: false
    },
    yaxis2: {
      anchor: 'x',
      domain: [0, 0.15],
      title: {
        text: 'FDA Approvals'
      },
      showticklabels: false,
      categoryorder: 'array',
      categoryarray: '',
      rangemode: 'normal',
      fixedrange: false
    },
    colorway: ['#bdbdbd', '#777777'],
    barmode: 'overlay'
  }
};
