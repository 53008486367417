import { useRef, useState } from 'react';
import { Typography, Box, Stack } from '@mui/material';
import SearchTermBadge from './SearchTermBadge';
import Cards from './Cards';
import ExpandedDrawer from './ExpandedDrawer';
import styles, { horizontalTimelineStyle, plusStyle } from './styles';

const TimelineView = ({ dataByYear, drugs, applicationNumbers, searchTermList }: any) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const centerRef = useRef<HTMLDivElement>(null);
  let maxKey = '';
  let maxSize = -Infinity;
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });
  const [isExpandedCardOpen, setIsExpandedCardOpen] = useState(false);
  const [activeBadge, setActiveBadge] = useState('');
  const [expandedPayload, setExpandedPayload] = useState({});

  Object.keys(dataByYear).forEach(key => {
    if (Object.keys(key).length > maxSize) {
      maxSize = dataByYear[key].length;
      maxKey = key;
    }
  });

  const handleClose = () => {
    setIsExpandedCardOpen(false);
  };

  const handleMouseDown = (event: MouseEvent) => {
    setIsDragging(true);
    setLastPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragging) return;

    const { scrollTop, scrollLeft } = scrollRef.current!;
    scrollRef.current!.scrollTop = scrollTop - (event.clientY - lastPosition.y);
    scrollRef.current!.scrollLeft = scrollLeft - (event.clientX - lastPosition.x);

    setLastPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    // @ts-ignore
    <Stack
      direction='row'
      width='100%'
      height='100%'
      ref={scrollRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      sx={{
        ...horizontalTimelineStyle,
        '&>div': {
          transformOrigin: 'left top',
          transition: 'transform 0.3s ease-in-out',
          minWidth: 180
        }
      }}>
      <Stack sx={styles.timeLineCards}>
        {Object.keys(drugs).length !== 0 &&
          drugs?.map((drug: any) => {
            return (
              <Cards
                key={drug?.identifier}
                tradeName={drug?.title}
                applicationType={drug?.labels ? drug?.labels[0] : drug?.colorScheme}
              />
            );
          })}
      </Stack>
      <Stack flexDirection='row'>
        {Object.entries(dataByYear)
          .sort(([yearA], [yearB]) => {
            return parseInt(yearB, 10) - parseInt(yearA, 10);
          })
          // eslint-disable-next-line no-unused-vars
          .map(([year, data]: any, _) => {
            return (
              <Stack
                direction='column'
                alignItems='center'
                key={year}
                minWidth='115px !important'
                id={`year-${year}`}
                ref={year === maxKey ? centerRef : null}>
                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  width='100%'
                  height={24}
                  sx={styles.yearBox}>
                  <Box>
                    <Typography
                      sx={{ fontWeight: 600, color: 'gray.600', fontSize: '14px' }}
                      variant='caption'>
                      {year === '1900' ? 'NA' : year}
                    </Typography>
                  </Box>
                </Stack>
                <Stack display='flex' justifyContent='center' flexDirection='column' width='100%'>
                  {applicationNumbers.map((item: string) => {
                    return item in data ? (
                      <SearchTermBadge
                        key={item}
                        application={data[item]}
                        year={year}
                        searchTermList={searchTermList}
                        activeBadge={activeBadge}
                        isExpandedCardOpen={isExpandedCardOpen}
                        setIsExpandedCardOpen={setIsExpandedCardOpen}
                        setActiveBadge={setActiveBadge}
                        setExpandedPayload={setExpandedPayload}
                      />
                    ) : (
                      <Box className='eplus' sx={plusStyle}>
                        <Box
                          sx={{
                            zIndex: 1,
                            position: 'relative'
                          }}
                        />
                      </Box>
                    );
                  })}
                </Stack>
              </Stack>
            );
          })}
      </Stack>
      <ExpandedDrawer
        open={isExpandedCardOpen}
        payload={{
          ...expandedPayload
        }}
        onClose={handleClose}
      />
    </Stack>
  );
};

export default TimelineView;
