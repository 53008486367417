import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip
} from '@mui/material';
import { Star, StarBorder } from '@mui/icons-material';
import {
  addApplicationToFavorite,
  addRemoveAriaResultToFavorite,
  addSearchToFavorite,
  removeApplicationFromFavorite,
  removeSearchFromFavorite
} from '../../../api/pages/UserProfile';
import Actions from '../../../store/actions';
import GlobalStore from '../../../store';
import { SaveIcon, SaveIconFilled } from '../../../assets/svgs/Icons/SaveIcon';

const UserFavoriteAction = ({
  isFavorite,
  type,
  tooltipSubtext,
  data,
  onClose,
  iconSize,
  ariaResultsLoading,
  setAriaResultsLoading
}) => {
  const { dispatch } = useContext(GlobalStore);
  const [userFavorite, setUserFavorite] = useState(false);
  const [loading, isLoading] = useState(false);

  // used to show loading icon on individual aria result in aria search
  const getLoading =
    data?.type === 'ariaResult' && ariaResultsLoading ? ariaResultsLoading.favorite : loading;

  const handleFavorite = async () => {
    const sourceToUse = data.isEMAinHPRAFlag ? 'eu' : data.source;
    try {
      let res = null;
      isLoading(true);
      // used to show loading icon on individual aria result in aria search
      if (setAriaResultsLoading) setAriaResultsLoading(prev => ({ ...prev, favorite: true }));
      switch (data.type) {
        case 'application':
        case 'applications':
          if (isFavorite) {
            res = await removeApplicationFromFavorite({
              id: data.id,
              module: data.module,
              source: sourceToUse
            });
          } else {
            res = await addApplicationToFavorite({
              id: data.id,
              module: data.module,
              source: sourceToUse
            });
          }
          break;
        case 'quickSearch':
        case 'advancedSearch':
        case 'ariaSearch':
          if (isFavorite) {
            res = await removeSearchFromFavorite({ id: data.id, type: data.type });
          } else {
            res = await addSearchToFavorite({ id: data.id, type: data.type });
          }
          break;
        case 'ariaResult':
          res = await addRemoveAriaResultToFavorite({
            aria_search_id: data?.id,
            is_favorite: !isFavorite,
            source: sourceToUse,
            details: data?.ariaResultData
          });
          break;
        default:
          break;
      }
      isLoading(false);
      if (setAriaResultsLoading) setAriaResultsLoading(prev => ({ ...prev, favorite: false }));

      if (res && res.status === 200) {
        setUserFavorite(!isFavorite);
        if (onClose) {
          onClose(!isFavorite);
        }
        await dispatch({
          type: Actions.SET_ALERT,
          value: {
            message: !isFavorite ? 'Saved' : 'Removed from Saved items',
            status: true,
            color: 'success'
          }
        });
      } else {
        throw new Error('Something went wrong');
      }
    } catch (e) {
      /* eslint-disable-next-line */
      console.error(e);

      await dispatch({
        type: Actions.SET_ALERT,
        value: { message: e.message, status: true }
      });
      if (onClose) {
        onClose();
      }
    }
  };
  useEffect(() => {
    setUserFavorite(isFavorite);
  }, [isFavorite]);
  switch (type) {
    case 'iconButton':
      return (
        <Tooltip
          title={`${userFavorite ? 'Remove' : 'Save'} ${tooltipSubtext} ${
            userFavorite ? 'from Saved items' : ''
          }`}>
          <IconButton
            id='addToFavourite'
            aria-label='favorite'
            onClick={handleFavorite}
            disabled={getLoading}
            size={iconSize}
            sx={{ color: userFavorite ? 'primary.main' : 'gray.contrastText' }}>
            {getLoading && <CircularProgress size={20} />}
            {/* The star icon gets very small if fontSize is set to small */}
            {!getLoading &&
              (userFavorite ? (
                <SaveIconFilled sx={{ width: '18px', height: '18px', color: 'primary.600' }} />
              ) : (
                <SaveIcon sx={{ width: '18px', height: '18px', color: 'gray.750' }} />
              ))}
          </IconButton>
        </Tooltip>
      );
    case 'menuButton':
      return (
        <MenuItem onClick={handleFavorite} disabled={getLoading}>
          <ListItemIcon>
            {getLoading && <CircularProgress size={20} sx={{ ml: 1 }} />}
            {userFavorite ? (
              <Star fontSize='small' sx={{ color: 'gray.lightVariant6' }} />
            ) : (
              <StarBorder fontSize='small' sx={{ color: 'gray.lightVariant6' }} />
            )}
          </ListItemIcon>
          <ListItemText sx={{ color: 'black.lightVariant4' }}>
            {userFavorite ? 'Save' : 'Remove'}
          </ListItemText>
        </MenuItem>
      );
    default:
      return (
        <Button variant='contained' color='primary' onClick={handleFavorite}>
          {userFavorite ? 'Remove from Saved items' : 'Save'}
        </Button>
      );
  }
};

UserFavoriteAction.defaultProps = {
  isFavorite: false,
  type: 'button',
  tooltipSubtext: '',
  onClose: null,
  iconSize: 'medium',
  ariaResultsLoading: {
    favorite: false,
    collaborate: false
  },
  setAriaResultsLoading: () => {}
};
UserFavoriteAction.propTypes = {
  isFavorite: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'iconButton', 'menuButton']),
  tooltipSubtext: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  iconSize: PropTypes.string,
  ariaResultsLoading: PropTypes.shape({
    favorite: PropTypes.bool,
    collaborate: PropTypes.bool
  }),
  setAriaResultsLoading: PropTypes.func
};
export default UserFavoriteAction;
