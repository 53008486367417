import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import {
  Stack,
  DialogContent,
  DialogTitle,
  Box,
  Divider,
  IconButton,
  MenuItem,
  Tooltip,
  Dialog,
  Button,
  DialogContentText,
  Grow,
  ListItem,
  ListItemText,
  ListItemButton,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import { AddCircleOutlined, RemoveCircleOutline } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { v4 } from 'uuid';
import {
  SOURCE_MAPPING,
  DISABLED_LABEL_SECTION_SOURCES
} from '../../pages/SearchResults/constants';

import dialogStyles from './styles/SectionCompilationDialog.styles';
import DropDown from '../../assets/svgs/Icons/DropDown';
import { CrossIcon } from '../../assets/svgs/Icons';
import CustomCheckbox from '../CustomComponents/Checkbox';
import getAllSections from './utils';
import { ComparisonCategory } from './PDFComparisonDialog';

interface CompileSectionDialogProps {
  open: boolean;
  onClose: () => void;
  applicationSources: any;
  handleCompilingSections: () => void;
  categories: ComparisonCategory[];
  setCategories: Dispatch<SetStateAction<ComparisonCategory[]>>;
  resetCategories: () => void;
}

const CompileSectionDialog = ({
  open,
  onClose,
  applicationSources,
  handleCompilingSections,
  categories,
  setCategories,
  resetCategories
}: CompileSectionDialogProps) => {
  const [selectedSections, setSelectedSections] = useState<any>([]);
  const [openSelectId, setOpenSelectId] = useState(null);

  const changeSource = (event: SelectChangeEvent, id: any) => {
    const updatedCategories = categories.map((currentCategory: any) => {
      return currentCategory.id === id
        ? { ...currentCategory, source: event.target.value, sections: [] }
        : { ...currentCategory };
    });

    setCategories([...updatedCategories]);
  };

  const changeSection = (sections: any, id: any) => {
    const updatedCategories = categories?.map((currentCategory: any) => {
      return currentCategory.id === id ? { ...currentCategory, sections } : { ...currentCategory };
    });

    setCategories([...updatedCategories]);
  };

  const removeItem = (row: any) => {
    const remainingLabelCategories = categories?.filter(
      (currentCategory: any) => currentCategory.id !== row.id
    );

    setCategories(remainingLabelCategories);
  };

  const addNewLabelRow = () => {
    setCategories([
      ...categories,
      {
        id: v4(),
        source: '',
        sections: []
      }
    ]);
  };

  const isSourceSelectionDisabled = (source: string) => {
    return categories?.some((currentCategory: any) => currentCategory.source === source);
  };

  const handleSectionSelection = (currentSection: any) => {
    let newSelected: any = [];

    const isSectionSelected = selectedSections.includes(currentSection);

    if (isSectionSelected) {
      newSelected = selectedSections.filter((section: any) => section !== currentSection);
    } else {
      newSelected = [...selectedSections, currentSection];
    }

    setSelectedSections(newSelected);
  };

  const handleSectionClose = () => {
    setOpenSelectId(null);
    setSelectedSections([]);
  };

  const handleSelectionDone = (sourceId: any) => {
    changeSection(selectedSections, sourceId);
    setOpenSelectId(null);
  };

  const handleSelectionReset = (sourceId: any) => {
    changeSection([], sourceId);
    setSelectedSections([]);
  };

  const isSectionChecked = (section: any) => {
    return selectedSections?.includes(section);
  };

  const handleOpen = (id: any, categoryId: any) => {
    setOpenSelectId(id);

    const alreadySelectedSections = categories?.findIndex(
      (category: any) => category.id === categoryId
    );

    if (alreadySelectedSections === -1) {
      return;
    }

    const newSections = categories[alreadySelectedSections]?.sections ?? [];

    setSelectedSections(newSections);
  };

  const isResetButtonDisabled = useMemo(() => {
    return categories.every((category: any) => isEmpty(category.sections));
  }, [categories]);

  const isSubmitButtonDisabled = useMemo(() => {
    return categories.some((category: any) => isEmpty(category.sections));
  }, [categories]);

  const isAddNewRowButtonDisabled = useMemo(
    () =>
      categories[categories.length - 1].source === '' ||
      categories[categories.length - 1].sections.length === 0,
    [categories]
  );

  const isRowRemoveDisabled = useMemo(() => categories.length <= 1, [categories]);

  const showAddNewRowButton = useMemo(
    () =>
      categories.length <
      applicationSources?.filter((source: any) => SOURCE_MAPPING[source]).length,
    [categories, applicationSources]
  );

  const enabledSourceList = useMemo(
    () =>
      applicationSources
        ?.filter((source: any) => SOURCE_MAPPING[source])
        .sort((a: string, b: string) => {
          const sourceOrder = Object.keys(SOURCE_MAPPING);
          return sourceOrder.indexOf(a) - sourceOrder.indexOf(b);
        }),
    [applicationSources]
  );

  const disabledSourceList = useMemo(
    () =>
      applicationSources
        ?.filter((source: any) => DISABLED_LABEL_SECTION_SOURCES[source])
        .sort((a: string, b: string) => {
          const sourceOrder = Object.keys(DISABLED_LABEL_SECTION_SOURCES);
          return sourceOrder.indexOf(a) - sourceOrder.indexOf(b);
        }),
    [applicationSources]
  );

  return (
    <Dialog open={open} fullWidth maxWidth='md' sx={dialogStyles.dialogRoot}>
      <Box>
        <DialogTitle sx={dialogStyles.dialogTitle}>
          Select Section(s) to Compile
          <Tooltip title='Close'>
            <IconButton
              onClick={() => {
                setSelectedSections([]);
                onClose();
              }}
              sx={dialogStyles.iconButton}>
              <CrossIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <Box sx={dialogStyles.dividerWrapper}>
          <Divider sx={dialogStyles.divider} />
        </Box>
      </Box>
      <DialogContent sx={dialogStyles.dialogContent}>
        <DialogContentText sx={dialogStyles.dialogContentText}>
          Select label sections for document compilation (latest label)
        </DialogContentText>
        {categories.map((category: any, index: number) => (
          <Box key={category.id} sx={dialogStyles.allSelectionContainer}>
            <Box sx={dialogStyles.listItemWrapper}>
              <ListItem sx={dialogStyles.listItem}>
                <Stack direction='row' width='100%'>
                  <Select
                    sx={{
                      ...dialogStyles.advanceSearchSelect,
                      ...(isEmpty(category.source)
                        ? { color: 'red.900', '& svg>path': { fill: 'red.900' } }
                        : { color: 'gray.800' }),
                      ...{ width: '106px', fontWeight: 700 }
                    }}
                    value={category.source ?? ''}
                    onChange={(event: SelectChangeEvent) => {
                      changeSource(event, category.id);
                    }}
                    labelId={`${index}-category-select-source`}
                    id={`${index}-category-select`}
                    defaultValue={category.source ?? ''}
                    displayEmpty
                    IconComponent={DropDown}
                    placeholder='Select category'>
                    <MenuItem
                      value=''
                      disabled
                      sx={{
                        '&.Mui-disabled': { color: 'gray.700', opacity: '1' }
                      }}>
                      Select Source
                    </MenuItem>
                    <Divider variant='middle' />
                    {enabledSourceList.map((source: any) => (
                      <MenuItem
                        value={source}
                        itemID={source}
                        aria-label={source}
                        key={source}
                        disabled={isSourceSelectionDisabled(source)}>
                        {SOURCE_MAPPING[source]}
                      </MenuItem>
                    ))}
                    {disabledSourceList.map((source: any) => (
                      <Tooltip title='Coming Soon' placement='left' key={source}>
                        <div>
                          <MenuItem
                            value={source}
                            itemID={source}
                            aria-label={source}
                            key={source}
                            style={dialogStyles.disabledMenuItem}>
                            {DISABLED_LABEL_SECTION_SOURCES[source]}
                          </MenuItem>
                        </div>
                      </Tooltip>
                    ))}
                  </Select>
                  <Divider
                    orientation='vertical'
                    flexItem
                    variant='middle'
                    sx={dialogStyles.horizontalDivider}
                  />
                  <Select
                    key={category.id}
                    sx={{
                      ...dialogStyles.advanceSearchSelect,
                      ...(isEmpty(category.sections)
                        ? { color: 'red.900', '& svg>path': { fill: 'red.900' } }
                        : { color: 'gray.800' }),
                      ...{ width: 'inherit', '& > li': { backgroundColor: 'black' } }
                    }}
                    disabled={isEmpty(category.source)}
                    multiple
                    value={category?.sections ?? ''}
                    onChange={(event: SelectChangeEvent) => {
                      event.stopPropagation();
                    }}
                    labelId={`${index}-category-select-sections`}
                    id={`${index}-category-sections`}
                    defaultValue={category.sections ?? ''}
                    displayEmpty
                    IconComponent={DropDown}
                    placeholder='Select Section'
                    open={`${index}-category-sections` === openSelectId}
                    onOpen={() => handleOpen(`${index}-category-sections`, category.id)}
                    onClose={handleSectionClose}
                    renderValue={() => (
                      <Typography>
                        {category?.sections?.length > 0
                          ? `${category?.sections?.length} sections selected`
                          : 'Select Section'}
                      </Typography>
                    )}>
                    <Box sx={dialogStyles.checkboxParentBox}>
                      {category.source &&
                        getAllSections(category.source)?.map((key: any) => (
                          <ListItemButton
                            dense
                            key={key}
                            sx={{ py: '0px !important' }}
                            onClick={() => handleSectionSelection(key)}>
                            <CustomCheckbox
                              checked={isSectionChecked(key)}
                              value={key}
                              size='small'
                              sx={dialogStyles.checkbox}
                            />
                            <ListItemText
                              primary={key}
                              primaryTypographyProps={dialogStyles.listItemText}
                            />
                          </ListItemButton>
                        ))}
                    </Box>
                    <Divider variant='middle' sx={{ my: 1, mx: '24px' }} />
                    <Box sx={dialogStyles.defaultMenuItemWrapper}>
                      <Button
                        variant='text'
                        disabled={selectedSections.length === 0}
                        sx={dialogStyles.checkboxResetButton}
                        onClick={() => handleSelectionReset(category.id)}>
                        Reset
                      </Button>
                      <Tooltip
                        title={selectedSections.length === 0 ? 'Select at least one section' : ''}>
                        <Button
                          variant='contained'
                          disabled={selectedSections.length === 0}
                          sx={dialogStyles.applyButton}
                          onClick={() => handleSelectionDone(category.id)}>
                          Apply
                        </Button>
                      </Tooltip>
                    </Box>
                  </Select>
                </Stack>
              </ListItem>
            </Box>
            <Tooltip title={isRowRemoveDisabled ? '' : 'Remove row'}>
              <IconButton
                sx={dialogStyles.advanceSearchRemoveListItem}
                color='error'
                onClick={() => removeItem(category)}
                disabled={isRowRemoveDisabled}>
                <RemoveCircleOutline />
              </IconButton>
            </Tooltip>
          </Box>
        ))}
        {showAddNewRowButton ? (
          <Grow in>
            <Stack direction='row' justifyContent='center' width='100%'>
              <Tooltip title={isAddNewRowButtonDisabled ? '' : 'Add new row'}>
                <IconButton
                  color='primary'
                  disabled={isAddNewRowButtonDisabled}
                  sx={dialogStyles.addNewRowButton}
                  onClick={addNewLabelRow}>
                  <AddCircleOutlined fontSize='large' />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grow>
        ) : (
          <Box height='56px' />
        )}
      </DialogContent>
      <Box textAlign='center'>
        <Button
          disabled={isResetButtonDisabled}
          sx={dialogStyles.resetButton}
          onClick={resetCategories}>
          Reset
        </Button>
        <Button
          sx={dialogStyles.submitButton}
          onClick={handleCompilingSections}
          disabled={isSubmitButtonDisabled}>
          View Compiled Result
        </Button>
        {!isResetButtonDisabled && (
          <Typography sx={dialogStyles.selectedSectionInfoMessage}>
            (Selected sections might be absent from some documents and may not appear in the final
            compilation)
          </Typography>
        )}
      </Box>
    </Dialog>
  );
};

export default CompileSectionDialog;
