import { Box, Breadcrumbs, IconButton, Tooltip } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Timeline } from '@material-ui/icons';
import { NavigateNext } from '@mui/icons-material';
import { ELASTIC_SEARCH_ATTRIBUTES_LABELS } from '../../ResultsPage/utils/constants';
import { ListViewButtons } from '../Components/ListView';
import AriaLogo from '../../../assets/svgs/UserProfile/AriaLogo';

const SearchJourney = ({ searchOperationData, searchJourneyClick, isFilterOpen }) => {
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if (searchOperationData) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [searchOperationData]);
  return (
    <Tooltip title={disabled ? 'No Timeline' : 'View Timeline'}>
      <IconButton
        disabled={disabled}
        onClick={searchJourneyClick}
        sx={{
          mt: 1,
          color: isFilterOpen ? '#33B187' : disabled ? '#BDBDBD' : '#808080'
        }}>
        <Timeline />
      </IconButton>
    </Tooltip>
  );
};

const Search = ({ items, fromAria }) => {
  const { source, type } = items;
  const [label, setLabel] = useState(false);
  useEffect(() => {
    if (source && typeof source === 'object') {
      const foundLabel = Object.keys(source).find(currSource => {
        return currSource.toLowerCase() in ELASTIC_SEARCH_ATTRIBUTES_LABELS && ELASTIC_SEARCH_ATTRIBUTES_LABELS[source.toLowerCase()].length>0
      })
      setLabel(foundLabel)
    }
  }, [source]);
  if (label) {
    return <ListViewButtons type={type} item={items} fromAria smallIcon />;
  }
  return null;
};

const AriaSearch = ({ items }) => {
  const { searchOperationData } = items;
  if (searchOperationData) {
    return (
      <Breadcrumbs
        separator={<NavigateNext fontSize='small' />}
        aria-label='breadcrumb'
        sx={{ ml: 5 }}>
        {' '}
        {searchOperationData &&
          (searchOperationData.type === 'quickSearch' ||
            searchOperationData.type === 'advancedSearch') && (
            <Search items={searchOperationData} fromAria />
          )}
        <Box sx={{ width: 30, height: 30, margin: '2em auto' }}>
          <AriaLogo />
        </Box>
      </Breadcrumbs>
    );
  }
  return null;
};
export const FilterList = ({ item }) => {
  return <AriaSearch items={item} />;
};

SearchJourney.prototype = {
  filters: PropTypes.object
};

export default memo(SearchJourney);
