import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

// material ui
import { Box, Popover, Stack, Switch, Tooltip, Typography } from '@mui/material';

// hooks
import useCanadaConsultationData from '../hooks/useCanadaConsultationData';

// Store
import GlobalStore from '../../../store';
import store from '../../../store/CanadaConsultation';

// components
import CanadaConsultationInsights from '../components/Insights';
import CanadaConsultationDetails from '../components/Details';
import ReportWidgets from '../../../components/Report/Widgets';
import ComparisonButtons from '../components/ComparisonButtons';
import CompareDialog from '../components/CompareDialog';
import SubHeader from '../../../components/SubHeader';
import BottomDrawer from '../../../components/CustomComponents/BottomDrawer';
import NewFilters from '../../../components/NewFilters';
import AskRiaButton from '../../../components/Buttons/AskRiaButton';
import AriaDrawerCore from '../../SearchResults/components/AriaDrawerCore';

// // utils
import CANADA_CONSULTATION_FILTERS from '../const';
import { Stat } from '../../SearchResults/types';
import AskRiaButtonStyles from '../../../components/Buttons/styles/AskRiaButton.styles';
import useAriaShareHandler from '../../../components/AriaShare/useAriaShareHandler';

const CanadaConsultationTabs = () => {
  const {
    canadaConsultationfilters,
    isLoading,
    compareEnabled,
    documentsToCompare,
    comparePopupOpen,
    getCanadaConsultationData,
    openComparisonPopup,
    removeFromComparison,
    openConsultationComparison,
    clearComparison,
    clearSearch
  } = useCanadaConsultationData();
  const { CanadaConsultationState } = useContext(store);
  const [ariaModalOpen, setAriaModalOpen] = useState(false);
  const { dispatch } = useContext(GlobalStore) as any;
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] =
    useState<HTMLElement | null>(null);

  const [consultaionStats, setConsultationStats] = useState<Stat[]>([]);

  useEffect(() => {
    if (Object.keys(canadaConsultationfilters).length === 0) {
      getCanadaConsultationData([]);
    }

    if (CanadaConsultationState?.canadaConsultationStats) {
      const newStats: Stat[] = [];
      newStats.push({
        title: 'Total Consultations',
        value: CanadaConsultationState?.canadaConsultationStats?.totalConsultation || 0
      });
      newStats.push({
        title: 'Open Consultations',
        value: CanadaConsultationState?.canadaConsultationStats?.openConsultation || 0
      });
      newStats.push({
        title: 'Recently Published',
        value: CanadaConsultationState?.canadaConsultationStats?.totalLastThirtyDays || 0
      });
      newStats.push({
        title: 'Docs/Page',
        value: CanadaConsultationState?.canadaConsultationStats?.pdfCount,
        value2: CanadaConsultationState?.canadaConsultationStats?.pageCount
      });
      setConsultationStats(newStats);
    }
  }, [canadaConsultationfilters]);

  useAriaShareHandler(isLoading, ariaModalOpen, setAriaModalOpen);

  const exitFromComparisonMode = () => {
    clearComparison();
    openConsultationComparison(false);
    openComparisonPopup(false);
    clearSearch();
  };

  const handleFilterApply = useCallback((currentfilters: any) => {
    clearComparison();
    clearSearch();
    getCanadaConsultationData(currentfilters);
  }, []);

  const handleCompareClick = () => {
    if (compareEnabled) {
      exitFromComparisonMode();
    } else {
      openConsultationComparison(!compareEnabled);
    }
  };

  const getFiltersLabelList = () => {
    return CANADA_CONSULTATION_FILTERS.reduce((acc: any, elem) => {
      acc[elem.value] = elem.label;
      return acc;
    }, {});
  };

  const getAriaDocuments = () => {
    const ariaDocuments = CanadaConsultationState?.tableData?.map(item => ({
      identifier: item.consultation_id
    }));
    return ariaDocuments || [];
  };

  const compareItems: Array<any> = useMemo(
    () =>
      documentsToCompare.map(doc => ({
        title: `${doc?.doc_title.replace(/ \([\s\S]*?\)/g, '')}`,
        doc_title: doc.doc_title,
        pdfLink: doc.s3_url
      })),
    [documentsToCompare]
  );
  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  return (
    <>
      <SubHeader
        title='CA Consultations'
        subTitle='Showing list of Consultations published by CA'
        stats={consultaionStats}
        handleVisualizeOpen={handleVisualizeOpen}>
        <Stack
          alignItems='center'
          direction='row'
          sx={{ cursor: 'pointer', paddingRight: '16px' }}
          onClick={handleCompareClick}>
          <Typography
            id='filters'
            sx={{ justifySelf: 'flex-end', color: 'gray.contrastText', fontSize: '12px' }}
            variant='subtitle1'>
            Comparison Mode
          </Typography>
          <Switch
            sx={{
              '.Mui-checked': {
                color: (theme: any) => `${theme.palette.primary[650]} !important`
              }
            }}
            checked={compareEnabled}
          />
        </Stack>
      </SubHeader>
      {!isLoading && (
        <Popover
          open={!!reportChoicesOpenAnchorElement}
          anchorEl={reportChoicesOpenAnchorElement}
          onClose={() => setReportChoiceAnchorElement(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          sx={{
            '& .MuiPopover-paper': {
              borderRadius: '16px !important',
              backgroundColor: 'none'
            }
          }}>
          <Box>
            <ReportWidgets
              widgets={['pie-widget', 'bar-widget', 'table-widget']}
              title=''
              dataSource={{
                source: 'canada-consultation',
                moduleName: 'consultation',
                disableSelectionSource: true,
                filters: CanadaConsultationState.availableFilters,
                filterLabelList: getFiltersLabelList()
              }}
              pathMetaData={{
                module: 'canada_consultation',
                href: window.location.href
              }}
              setReportWidgetClose={setReportChoiceAnchorElement}
            />
          </Box>
        </Popover>
      )}
      {comparePopupOpen && (
        <CompareDialog
          open={comparePopupOpen}
          onClose={() => openComparisonPopup(false)}
          compareItems={compareItems}
          onNewCompare={clearComparison}
        />
      )}

      {compareEnabled && (
        <Box marginTop='10px'>
          <ComparisonButtons
            documentsToCompare={documentsToCompare}
            removeFromComparison={removeFromComparison}
            openComparisonPopup={openComparisonPopup}
            clearComparison={clearComparison}
            fromDocumentHistory={false}
          />
        </Box>
      )}

      <CanadaConsultationDetails />
      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}>
        <CanadaConsultationInsights />
      </BottomDrawer>

      {ariaModalOpen && (
        <AriaDrawerCore
          applications={CanadaConsultationState.isFiltersApplied ? getAriaDocuments() : []}
          sources={{ ca: ['consultation'] }}
          groupId='entity_id'
          docCount={CanadaConsultationState?.canadaConsultationStats?.pdfCount}
          docPageCount={CanadaConsultationState?.canadaConsultationStats?.pageCount}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          dispatch={dispatch}
        />
      )}
      <NewFilters
        data={canadaConsultationfilters}
        applyFilters={handleFilterApply}
        isLoading={isLoading}
      />
      <Tooltip title={compareEnabled ? 'Exit compare mode to search ARIA' : ''}>
        <Box sx={AskRiaButtonStyles.bottomFilterPosition}>
          <AskRiaButton
            iconId='aria'
            setOpenAriaSearch={setAriaModalOpen}
            text='Ask RIA'
            disabled={compareEnabled || isLoading}
          />
        </Box>
      </Tooltip>
    </>
  );
};

export default memo(CanadaConsultationTabs);
