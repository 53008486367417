import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Drawer from '@mui/material/Drawer';
import Close from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import SearchIcon from '../../assets/svgs/Icons/SearchIcon';
import SearchBarAutoComplete from '../Home/components/SearchBarAutoComplete';
import SourceDropDown from '../Home/components/SourceDropDown';
import { SourceDropdown } from '../Home/types';
import { categories } from './const';
import useSearchSuggestions from '../Home/hooks/useSearchSuggestions';
import { encodeObjectToBase64 } from '../../utils/encodeDecodeObject';
import styles from './styles/SearchBarStyles';
import searchBarStyles from '../Home/styles/SearchBar.styles';
import {
  createSourceModulePayloadMapping,
  getCompareSourceDropdown,
  getDefaultCompareSourceDropdown
} from '../Home/utils';
import RESULT_VIEW_TYPES from '../SearchResults/components/constants';

const ComparsionSearchBox = ({ modalOpen, setModalOpen }: any) => {
  const history = useHistory();
  const [selectedSources, setSelectedSources] = useState<SourceDropdown[]>(() =>
    getDefaultCompareSourceDropdown()
  );
  const [selectedCategory, setSelectedCategory] = useState<{
    label: string;
    value: string;
  }>(categories[0]);
  const [sourceDropDownAnchorEl, setSourceDropDownAnchorEl] = useState<null | HTMLElement>(null);
  const [searchText, setSearchText] = useState<string>('');
  const {
    searchSuggestions,
    isSuggestionsLoading,
    setIsSearchOpen,
    handleKeyPress,
    clearSearchSuggestions,
    isSearchOpen
  } = useSearchSuggestions(selectedSources);

  const handleAutoCompleteOpen = useCallback(() => setIsSearchOpen(true), [setIsSearchOpen]);

  const handleAutoCompleteClose = useCallback(() => setIsSearchOpen(false), [setIsSearchOpen]);

  const handleSourceChange = useCallback(
    (values: SourceDropdown[]) => {
      setSelectedSources(values);
    },
    [setSelectedSources]
  );


  const compareSourceDropDown = useMemo(() => getCompareSourceDropdown(), []);

  const handleSearch = (value: string = searchText) => {
    const convertedSelectedSources = createSourceModulePayloadMapping(selectedSources);
    const payload = {
      search_term: value,
      source: convertedSelectedSources,
      category_name: selectedCategory.value,
      feature: 'comparsion',
      view_type: RESULT_VIEW_TYPES.APPLICATION
    };
    const encodedPayload = encodeObjectToBase64(payload) ?? '';
    history.push(`/search/${encodedPayload}`);
  };

  return (
    <Drawer
      sx={{
        '& >.MuiDrawer-paper': {
          height: '60vh',
          borderRadius: '16px',
          paddingX: '24px',
          paddingY: '32px',
          position: 'absolute'
        }
      }}
      anchor='bottom'
      onClose={() => setModalOpen('')}
      open={!!modalOpen}>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'stretch'
          }}>
          <Typography
            sx={{
              flexGrow: 1,
              textAlign: 'center',
              alignSelf: 'center',
              fontWeight: '700',
              fontSize: '20px',
              color: 'gray.800',
              textTransform: 'capitalize'
            }}>
            Label Comparison
          </Typography>
          <Tooltip title='Close'>
            <IconButton
              onClick={() => setModalOpen('')}
              sx={{ position: 'absolute', right: '24px', top: '26px' }}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='center'>
          <Divider
            sx={{
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: 'gray.400',
              borderRadius: '2px',
              mt: 1,
              width: '64px'
            }}
          />
        </Box>
        <Stack spacing={1}>
          <form onSubmit={() => handleSearch()}>
            <Box sx={styles.searchBar}>
              <Stack direction='row' alignItems='center'>
                <SourceDropDown
                  selectedSources={selectedSources}
                  handleChange={handleSourceChange}
                  options={compareSourceDropDown}
                  anchorEl={sourceDropDownAnchorEl}
                  setAnchorEl={setSourceDropDownAnchorEl}
                />
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{ mb: '12px', mt: '12px', backgroundColor: 'gray.200' }}
                />
                <Box flex={1}>
                  <SearchBarAutoComplete
                    id='comparsion-search-input'
                    value={searchText}
                    label='Search for drug name here!'
                    disableSearch={selectedSources.length === 0}
                    options={searchSuggestions}
                    loading={isSuggestionsLoading}
                    onOpen={handleAutoCompleteOpen}
                    open={isSearchOpen}
                    onClose={handleAutoCompleteClose}
                    filterOptions={undefined}
                    onInputChange={handleKeyPress}
                    handleSearch={handleSearch}
                    setSearch={value => setSearchText(value)}
                    clearSearchSuggestions={() => {
                      clearSearchSuggestions();
                    }}
                  />
                </Box>
                <Button
                  type='submit'
                  size='small'
                  startIcon={<SearchIcon style={{ fontSize: 12 }} />}
                  sx={searchBarStyles.searchButton}>
                  Search
                </Button>
              </Stack>
            </Box>
          </form>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default React.memo(ComparsionSearchBox);
