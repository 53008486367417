import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Badge, Box, CircularProgress, IconButton, Menu, Stack, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import isEmpty from 'lodash/isEmpty';
import { useLocation, useParams } from 'react-router-dom';
import UserProjectList from './UserProjectList';
import {
  removeAriaResultFromProject,
  removeEntityFromProject
} from '../../../api/pages/UserProfile';
import Actions from '../../../store/actions';
import GlobalStore from '../../../store';
import { projectPayloadGeneration } from '../util';
import DeleteConfirmation from '../Components/DeleteConfirmation';
import MyProjectsIcon from '../../../assets/svgs/UserMenu/MyProjectsIcon';

const UserProjectAction = ({ inProject, data, onClose, type, iconSize = 'medium' }) => {
  const location = useLocation();
  const { projectId } = useParams();
  const { dispatch } = useContext(GlobalStore);
  const [anchorEl, setAnchorEl] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const isCurrentProject = (projectList, currentProjectId) => {
    try {
      if (isEmpty(projectList) || !currentProjectId) return false;
      return projectList?.some(project => project.id === parseInt(currentProjectId, 10));
    } catch (e) {
      return false;
    }
  };
  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      const payload = projectPayloadGeneration(data);
      const res =
        data?.type === 'ariaResult'
          ? await removeAriaResultFromProject({ projectId, payload })
          : await removeEntityFromProject({ projectId, id: data.id, payload });

      if (res && res.data && res.data.body && !res.data.body.error) {
        if (onClose) {
          if (data?.type === 'ariaResult') {
            const localParent = data?.ariaResultData?.inProjects;
            const objWithIdIndex = localParent.findIndex(
              project => project?.id === parseInt(projectId, 10)
            );
            localParent.splice(objWithIdIndex, 1);
            onClose([...localParent]);
          } else {
            const localProjects = data.inProjects;
            const objWithIdIndex = localProjects.findIndex(
              project => project?.id === parseInt(projectId, 10)
            );
            localProjects.splice(objWithIdIndex, 1);
            onClose([...localProjects]);
          }
        }
        await dispatch({
          type: Actions.SET_ALERT,
          value: {
            message: 'Removed from project successfully',
            status: true,
            color: 'success'
          }
        });
      } else {
        throw new Error('Something went wrong');
      }
      setLoading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setLoading(false);

      await dispatch({
        type: Actions.SET_ALERT,
        value: { message: e.message, status: true }
      });
      if (onClose) {
        onClose();
      }
    }
  }, [data]);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOpenMenu = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const open1 = Boolean(anchorEl);

  const getIconButton = useCallback(() => {
    if (loading) return <CircularProgress size={20} sx={{ position: 'absolute' }} />;
    if (isEmpty(inProject))
      return <MyProjectsIcon sx={{ color: 'gray.750', width: '18px', height: '18px' }} />;
    if (location.pathname.startsWith('/account/project') && isCurrentProject(inProject, projectId))
      return <Delete sx={{ color: 'gray.750', width: '18px', height: '18px' }} />;
    return <MyProjectsIcon sx={{ color: 'gray.750', width: '18px', height: '18px' }} />;
  }, [loading, inProject]);

  useEffect(() => {
    if (!isEmpty(inProject) && !location.pathname.startsWith('/account/project')) {
      setCount(inProject.length);
    } else {
      setCount(0);
    }
  }, [inProject]);

  if (type === 'iconButton') {
    return (
      <Stack direction='row' spacing={1}>
        <Box>
          <Tooltip
            title={`${
              // eslint-disable-next-line no-nested-ternary
              isEmpty(inProject)
                ? 'Add to'
                : location.pathname.startsWith('/account/project')
                ? 'Remove from'
                : 'Add / Remove from'
            } project`}>
            <IconButton
              disabled={loading}
              aria-label='project'
              id='long-button-project'
              aria-controls='long-menu-project'
              aria-expanded='true'
              aria-haspopup='true'
              size={iconSize}
              sx={{
                maxWidth: 54,
                maxHeight: 54
              }}
              onClick={e => {
                if (
                  location.pathname.startsWith('/account/project') &&
                  isCurrentProject(inProject, projectId)
                ) {
                  setOpenDeleteConfirmation(true);
                } else {
                  handleOpenMenu(e);
                }
              }}>
              <Badge
                sx={{
                  maxWidth: 45,
                  maxHeight: 45,
                  color: 'white !important',
                  height: '18px',
                  '& .MuiBadge-badge': {
                    fontSize: 10,
                    backgroundColor: 'primary.650',
                    color: 'white !important'
                  }
                }}
                badgeContent={count}
                max={10}>
                {getIconButton()}
              </Badge>
            </IconButton>
          </Tooltip>
          <Menu
            id='long-menu-project'
            MenuListProps={{
              'aria-labelledby': 'long-button'
            }}
            anchorEl={anchorEl}
            open={open1}
            onClose={handleCloseMenu}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: '200px',
                borderRadius: '16px'
              }
            }}>
            <UserProjectList
              showActionButton
              disableDefaultOpen
              type={type}
              data={data}
              inProjects={inProject}
              onClose={onClose}
            />
          </Menu>
          <DeleteConfirmation
            open={openDeleteConfirmation}
            onReject={() => setOpenDeleteConfirmation(false)}
            onAccept={() => {
              setOpenDeleteConfirmation(false);
              handleDelete();
            }}
          />
        </Box>
      </Stack>
    );
  }

  return <UserProjectList showActionButton data={data} inProjects={inProject} onClose={onClose} />;
};

UserProjectAction.defaultProps = {
  inProject: [],
  data: {},
  onClose: () => {},
  type: 'list',
  iconSize: 'medium'
};
UserProjectAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  inProject: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any),
  onClose: PropTypes.func,
  type: PropTypes.string,
  iconSize: PropTypes.string
};
export default UserProjectAction;
