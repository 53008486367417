import {
  Box,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack
} from '@mui/material';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import UserAvatar from './UserAvatar';
import {
  ActivityIcon,
  StarOutlineIcon,
  SubscribeIcon,
  MyReportsIcon
} from '../../../../assets/svgs/Icons';
import styles from '../../styles/Navigation.styles';
import ProjectList from './ProjectsNavigationList';
import ServiceRequestIcon from '../../../../assets/svgs/UserProfile/ServiceRequestIcon';

const USER_PROFILE_NAV_BAR = [
  { text: 'Activity', icon: ActivityIcon, path: 'activity' },
  { text: 'Saved Items', icon: StarOutlineIcon, path: 'saved' },
  { text: 'My Reports', icon: MyReportsIcon, path: 'reports' },
  { text: 'My Subscriptions', icon: SubscribeIcon, path: 'subscriptions' }
];

const Navigation = () => {
  const history = useHistory();
  const location = useLocation();
  const handleClick = (page: string) => {
    if (page) {
      history.push(`/account/${page}`);
    }
  };
  return (
    <Box display='flex' flexDirection='column' sx={styles.rootBox}>
      <UserAvatar />
      <Stack
        spacing='8px'
        sx={styles.rootList}
        component='nav'
        aria-labelledby='nested-list-subheader'>
        {USER_PROFILE_NAV_BAR.map(({ text, icon: Icon, path }) => (
          <ListItem key={path} disablePadding>
            <ListItemButton
              sx={styles.navigationMenuItem}
              selected={Boolean(location.pathname.match(path) || location.pathname === path)}
              onClick={() => handleClick(path)}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={text} sx={styles.listItemText} />
            </ListItemButton>
          </ListItem>
        ))}
      </Stack>
      <ProjectList />
      <Divider sx={styles.divider} />
      <ListItem disablePadding>
        <ListItemButton
          sx={styles.navigationMenuItem}
          onClick={() => {
            history.push('/account/serviceRequests');
          }}>
          <ListItemIcon>
            <ServiceRequestIcon sx={styles.serviceRequestIcon} />
          </ListItemIcon>
          <ListItemText primary='Service Requests' sx={styles.listItemText} />
        </ListItemButton>
      </ListItem>
    </Box>
  );
};

export default React.memo(Navigation);
