import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useStyles from '../styles/UserList.styles';
import UserProfileListIcons from '../UserProfileListIcons';
import FilterToolTip from '../Components/FilterToolTip';
import {
  cnfToAdvancedSearch,
  generateCNFQuery,
  handle505b2AdvancedSearch,
  handleAdvancedSearch,
  urlDateToLocalDate
} from '../../../components/Header/utils/advanceSearchHelpers';
import { SOURCE_MAPPING_USER_PROFILE_ACTIVITY } from '../util';
import { ELASTIC_SEARCH_ATTRIBUTES_LABELS } from '../../../components/Header/utils/advanceSearchConstants';

export const advanceSearchQueryCreator = searchData => {
  let is505b2Data = {};
  Object.entries(searchData).forEach(([key, items]) => {
    if (items && Object.keys(items || {}.length !== 0)) {
      is505b2Data = {
        key505b2: key,
        value: `From: ${items.from} To: ${items.to}`
      };
    }
  });

  return { is505b2Data };
};
// eslint-disable-next-line react/prop-types
const AdvanceSearchButton = ({ search, fromAria, smallIcon }) => {
  // eslint-disable-next-line react/prop-types
  const {
    use_synonyms: useSynonyms = true,
    source,
    id,
    searchData,
    filters,
    dateFilter = {},
    cnf_query: cnfQuery = ''
  } = search;
  const classes = useStyles();

  const [path, setPath] = useState('');
  const [searchText, setSearchText] = useState('');
  const [advanceSearchPayload, setAdvanceSearchPayload] = useState([]);
  const [dateRange, setDateRange] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    // eslint-disable-next-line react/prop-types
    let localPath = '';
    let localSearch = '';

    if (Object.keys(source).length > 1) {
      localPath = 'Multiple Sources';
    } else {
      const currSource = Object.keys(source)[0];
      if (currSource) {
        const subcategory = source[Object.keys(source)[0]];
        localPath = `${
          SOURCE_MAPPING_USER_PROFILE_ACTIVITY[currSource][subcategory[0]?.toLowerCase()] ?? ''
        }`;
      }

      const { is505b2Data } = advanceSearchQueryCreator(searchData);
      if (Object.keys(is505b2Data).length > 0) {
        localPath = `${localPath} ${is505b2Data.key505b2}`;
        localSearch = `${localSearch} ${is505b2Data.value}`;
      } else {
        const payload = cnfToAdvancedSearch(cnfQuery);
        localSearch = generateCNFQuery(
          payload,
          ELASTIC_SEARCH_ATTRIBUTES_LABELS[currSource.toLowerCase()]
        );
        setAdvanceSearchPayload(payload);
      }
      if (
        (filters && Object.keys(filters).length !== 0) ||
        (dateFilter && Object.keys(dateFilter).length !== 0)
      ) {
        if (dateFilter && Object.keys(dateFilter).length !== 0) {
          const localDateFilter = {
            startDate: '',
            endDate: ''
          };
          if (dateFilter.start_date) {
            localDateFilter.startDate = urlDateToLocalDate(dateFilter.start_date);
          }
          if (dateFilter.end_date) {
            localDateFilter.endDate = urlDateToLocalDate(dateFilter.end_date);
          }
          setDateRange(localDateFilter);
        }
        localPath = `${localPath} | With filters`;
      }
    }
    setPath(localPath);
    setSearchText(localSearch);
    setLoading(false);
  }, [source, id]);
  if (loading) {
    return (
      <ListItem fullWidth disablePadding>
        <ListItemAvatar>
          <Skeleton variant='circular' width={50} height={50} />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton variant='text' sx={{ fontSize: '1rem', width: 100 }} />}
          secondary={<Skeleton variant='text' sx={{ fontSize: '1rem', width: 500 }} />}
        />
      </ListItem>
    );
  }
  return (
    <ListItemButton
      disableRipple
      disablePadding
      className={classes.list}
      sx={fromAria ? { width: 'auto' } : {}}
      onClick={async () => {
        let url = '';
        if ('formulations' in searchData || 'route' in searchData) {
          url = handle505b2AdvancedSearch(
            Object.entries(searchData)[0][1],
            'formulations' in searchData ? 'formulation' : 'route',
            {
              startDate: dateFilter.start_date ? new Date(dateFilter.start_date) : '',
              endDate: dateFilter.end_date ? new Date(dateFilter.end_date) : ''
            },
            id
          );
        } else {
          const otherData = {
            startDate: '',
            endDate: '',
            useSynonyms
          };
          if ('start_date' in dateFilter) {
            otherData.startDate = urlDateToLocalDate(dateFilter.start_date);
          }
          if ('end_date' in dateFilter) {
            otherData.endDate = urlDateToLocalDate(dateFilter.end_date);
          }
          url = handleAdvancedSearch(advanceSearchPayload, otherData, source, id);
        }
        window.open(url, '_blank');
      }}>
      <ListItemAvatar
        sx={{
          maxWidth: smallIcon ? 45 : fromAria ? 45 : 56,
          minWidth: smallIcon ? 45 : 56,
          mr: smallIcon ? 1 : 0
        }}>
        <Box
          sx={{
            p: '.6em',
            width: '100%'
          }}>
          <UserProfileListIcons type='advanceSearch' smallIcon={smallIcon} />
        </Box>
      </ListItemAvatar>
      {/* eslint-disable-next-line react/prop-types */}
      <FilterToolTip filters={filters} source={source}>
        <ListItemText
          className={classes.listItem}
          primary={path}
          secondary={searchText}
          primaryTypographyProps={{ variant: 'activityTitle' }}
          secondaryTypographyProps={{ variant: 'activityDescription' }}
        />
      </FilterToolTip>
    </ListItemButton>
  );
};

export default AdvanceSearchButton;
