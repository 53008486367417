const styles = {
  statsTile: {
    width: '100%',
    height: 150,
    borderRadius: '10px',
    p: 2,
    bgcolor: 'white.main',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
  },
  iconColor: {
    color: '#6C9BFD'
  },
  basicInfoContainer: {
    p: 2,
    width: '50%',
    fontWeight: 500,
    bgcolor: 'white.main',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    maxHeight: '30vh',
    overflowY: 'auto',
    borderRadius: '10px'
  },
  dialogText: {
    fontSize: '16px',
    textTransform: 'capitalize',
    color: 'gray.lightVariant',
    fontWeight: 'bold'
  },
  pieCharContainer: {
    width: '50%',
    height: '30vh',
    p: 2,
    bgcolor: 'white.main',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    borderRadius: '10px'
  },
  barGraphContainer: {
    width: '100%',
    height: 300,
    p: 2,
    bgcolor: 'white.main',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    borderRadius: '10px'
  },
  addToReport: {
    height: '35px',
    cursor: 'pointer',
    background: 'none',
    boxShadow: 'none',
    '&:hover': {
      background: 'none',
      border: 1,
      borderColor: 'primary.main',
      borderStyle: 'solid'
    }
  },
  reportText: {
    color: 'primary',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'none'
    }
  }
};

export default styles;
