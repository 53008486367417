const styles = {
  root: {
    backgroundColor: 'white.main',
    borderRadius: '12px',
    border: '1px solid gray',
    borderColor: 'primary.main',
    hr: {
      borderColor: '#fefefe',
      opacity: 0.15,
      mt: 1.5,
      mb: 1.5,
      maxHeight: 146
    },
    ':hover': {
      cursor: 'pointer',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)'
    },
    width: '240px',
    height: '95px',
    p: '12px',
    margin: '16px 16px 16px 8px'
  },
  heading: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  chips: {
    ml: 1,
    fontSize: 10,
    p: 0,
    height: '100%',
    borderRadius: '4px',
    color: 'white.main',
    '& span': {
      padding: '2px 4px'
    }
  }
};

export default styles;
