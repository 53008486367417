import { Header } from './interface';

const HEADERS: Record<string, Header[]> = {
  activity: [
    {
      key: 'searches',
      label: 'Searches'
    },
    {
      key: 'journey',
      label: 'Journeys'
    }
  ],
  saved: [
    {
      key: 'searches',
      label: 'Searches'
    },
    {
      key: 'documents',
      label: 'Documents'
    },
    {
      key: 'applications',
      label: 'Applications'
    }
  ],
  reports: [
    {
      key: 'report',
      label: 'My Reports'
    }
  ],
  project: [
    {
      key: 'searches',
      label: 'Searches'
    },
    {
      key: 'documents',
      label: 'Documents'
    },
    {
      key: 'applications',
      label: 'Applications'
    }
  ]
};

const REPORT_TITLE_MAX_LENGTH = 20;
const REPORT_DESCRIPTION_MAX_LENGTH = 60;
const PROJECT_TITLE_MAX_LENGTH = 100;
const PROJECT_DESCRIPTION_MAX_LENGTH = 500;

const ENBALE_APPLICATION_BASED_ON_SOURCE = ['usnlm', 'euctr'];

// eslint-disable-next-line import/prefer-default-export
export {
  HEADERS,
  PROJECT_TITLE_MAX_LENGTH,
  PROJECT_DESCRIPTION_MAX_LENGTH,
  REPORT_TITLE_MAX_LENGTH,
  REPORT_DESCRIPTION_MAX_LENGTH,
  ENBALE_APPLICATION_BASED_ON_SOURCE
};
