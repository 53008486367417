const CT_REPORT_QUERIES = [
  {
    id: 'number_of_trials_by_completion_year',
    title: {
      title: 'Number of Trials by Completion year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['study_completion_year'], agg_function: 'count' },
    graphStyle: {
      colors: ['#B1BBE4'],
      paddings: 0.7,
      margin: {
        top: 20,
        right: 20,
        bottom: 40,
        left: 30
      },
      tickRotationValue: 90,
      borderRadius: 4,
      labelTextColor: '#ffffff',
      axisText: '#A3A3A3',
      axisBottom: {
        tickRotation: 80
      },
      axisLeft: {
        tickRotation: 0
      }
    }
  },
  {
    id: 'number_of_trials_by_phase',
    title: {
      title: 'Number of Trials by Phase',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['phase'], agg_function: 'count' },
    graphStyle: {
      colors: ['#B1BBE4'],
      paddings: 0.7,
      margin: {
        top: 20,
        right: 20,
        bottom: 40,
        left: 100
      },
      tickRotationValue: 90,
      borderRadius: 4,
      labelTextColor: '#ffffff',
      axisText: '#A3A3A3',
      layout: 'horizontal',
      axisBottom: {
        tickRotation: 80
      },
      axisLeft: {
        tickRotation: 0
      }
    }
  },
  {
    id: 'number_of_trials_by_recruitment_status',
    title: {
      title: 'Number of Trials by Recruitment Status',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['overall_status'], agg_function: 'count' },
    graphStyle: {
      colors: ['#B1BBE4'],
      paddings: 0.7,
      margin: {
        top: 20,
        right: 20,
        bottom: 40,
        left: 120
      },
      tickRotationValue: 90,
      borderRadius: 4,
      labelTextColor: '#ffffff',
      axisText: '#A3A3A3',
      layout: 'horizontal',
      axisBottom: {
        tickRotation: 80
      },
      axisLeft: {
        tickRotation: 0
      }
    }
  },
  {
    id: 'number_of_patients_by_trials',
    title: 'Number of Patients by Trial',
    widget: 'bar-widget',
    query: { 'x-axis': ['nct_id'], 'y-axis': ['enrollment'], agg_function: 'median' }
  }
];

export default CT_REPORT_QUERIES;
