import { Box, Divider } from '@mui/material';
import React from 'react';

const CoverPageTemplate1 = ({ children }: any) => {
  const [
    LogoComponent,
    TitleComponent,
    DescriptionComponent,
    PreparedByName,
    PreparedDate,
    PoweredByLogo
  ]: any = children;

  return (
    <Box p={4} height='100%' width='100%' position='relative'>
      <Box display='flex' pt={4} pl={6}>
        {LogoComponent}
      </Box>
      <Box p={6} pt={14}>
        <Box p={5} pt={2} pb={2}>
          {TitleComponent}
        </Box>
        <Divider sx={{ borderColor: 'black.main' }} />
        <Box p={3} pl={4} pr={4}>
          {DescriptionComponent}
        </Box>
      </Box>
      <Box pt={8} textAlign='center'>
        {PreparedDate}
      </Box>
      <Box pt={16} textAlign='center'>
        {PreparedByName}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 40,
          left: '50%',
          textAlign: 'center',
          transform: 'translate(-50%, 0%)'
        }}>
        <Box display='inline-block' sx={{ scale: 1.3 }}>
          {PoweredByLogo}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(CoverPageTemplate1);
