import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import ResultsStore from '../../../../store/SearchResults';
import mergeMatchSubmission, { checkForMatchSubmission } from '../../utils/getMatchSubmissionData';
import getMatchSubmissions from '../../hooks/getMatchSubmissions';
import ResultActions from '../../../../store/SearchResults/actions';
import TimelineView from './TimlineView';
import { VivproSelect } from '../../../../components/CustomComponents/Selects';
import styles from './styles';
import SOURCE_MAPPING from './constant';
import { DATAGRID_UNIQUE_FIELD } from '../../../ResultsPage/utils/constants';
import transformData from '../../utils/dataTransform';
import FullScreenProcessing from '../../../SpecialityDesignation/components/FullScreenProcessing';

const IndicationApprovalTimline = () => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const [submissionYearData, setSubmissionYearData] = useState({});
  const [applicationNumberList, setApplicationNumberList] = useState<Array<string>>([]);
  const [searchTermList, setSearchTermList] = useState<Array<string>>([]);
  const [applications, setApplication] = useState<Array<any>>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const divRef = React.useRef(null);
  const [source, setSource] = useState<string>('all');
  const [optionList, setOptionList] = useState<Array<any>>([]);

  const getSubmissionData = async () => {
    setLoading(true);
    const searchId = resultsState.searchIds?.applicationSearchId;
    const applicationResults = { ...resultsState.applicationResults };
    if (searchId && !checkForMatchSubmission(applicationResults)) {
      const submissionData = await getMatchSubmissions(searchId);

      const newApplData = mergeMatchSubmission(applicationResults, submissionData);
      resultsDispatch({
        type: ResultActions.SET_APPLICATION_RESULTS,
        value: newApplData
      });
    }
    setLoading(false);
  };

  const groupByYear = (data: any) => {
    let appArray = transformData(data);
    appArray =
      source !== 'all'
        ? appArray.filter(
            (obj: { source: string }) => obj?.source === source && ['us', 'eu'].includes(source)
          )
        : appArray.filter((obj: { source: string }) => ['us', 'eu'].includes(obj?.source));
    const result: any = {};
    const applList: Array<string> = [];
    const searchTerms: Array<string> = [];
    appArray.forEach((item: any) => {
      const key: string = DATAGRID_UNIQUE_FIELD[item?.source];
      applList.push(item[key]);
      item?.matched_submissions?.forEach((submission: { term: string; approval_date: string }) => {
        if (!searchTerms.includes(submission?.term)) {
          searchTerms.push(submission?.term);
        }

        const year = submission?.approval_date.split('-')[0];

        if (!result[year]) {
          result[year] = {};
        }

        if (!result[year][item[key]]) {
          result[year][item[key]] = {
            id: item[key],
            ...item,
            matched_submissions: item?.matched_submissions.filter(
              (itemValue: { approval_year: any }) => itemValue?.approval_year === year
            )
          };
        }
      });
    });

    setSubmissionYearData(result);
    setApplicationNumberList(applList);
    setSearchTermList(searchTerms);
    setApplication(appArray);
  };

  useEffect(() => {
    getSubmissionData();
  }, []);

  useEffect(() => {
    if (resultsState?.applicationResults) {
      groupByYear(resultsState?.applicationResults);
    }

    const itemList: Array<any> = [
      {
        value: 'all',
        label: 'All Region'
      }
    ];
    Object.keys(resultsState?.applicationResults).forEach((key: string) => {
      if (key in SOURCE_MAPPING) {
        itemList.push({
          value: key,
          label: SOURCE_MAPPING[key]
        });
      }
    });
    setOptionList(itemList);
  }, [resultsState?.applicationResults, source]);

  const handleClick = (e: SelectChangeEvent) => {
    setSource(e.target.value);
  };

  if (isLoading) {
    return (
      <FullScreenProcessing loading={isLoading} open={isLoading} message='Generating image...' />
    );
  }
  return (
    <Stack>
      <Box mr={2} sx={styles.dropDownBox}>
        <VivproSelect
          // @ts-ignore
          value={source}
          handleChange={handleClick}
          options={optionList}
          icon={KeyboardArrowDown}
        />
      </Box>
      {Object.keys(submissionYearData).length !== 0 ? (
        <Grid container ref={divRef} mb={3} mt={2} flexWrap='nowrap' sx={styles.gridContainer}>
          <TimelineView
            dataByYear={submissionYearData}
            drugs={applications}
            applicationNumbers={applicationNumberList}
            searchTermList={searchTermList}
          />
        </Grid>
      ) : (
        <Box sx={styles.message}>
          <Typography variant='h6' color='inherit'>
            No Graphs Available
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default IndicationApprovalTimline;
