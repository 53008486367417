import React from 'react';

const CoverPage = () => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width='100%'
      viewBox='0 0 1196 1692'
      enableBackground='new 0 0 1196 1692'
      xmlSpace='preserve'>
      <path
        fill='#FFFFFF'
        opacity='1.000000'
        stroke='none'
        d='
M27.777439,1.296538 
   C34.687645,1.000000 41.375290,1.000000 48.531467,1.000000 
   C56.397575,13.451291 63.224033,26.224079 70.265495,38.874969 
   C84.276108,64.046867 98.381958,89.165848 112.339973,114.366707 
   C124.363174,136.074295 136.454666,157.743607 148.550644,179.410400 
   C162.593719,204.564957 176.526245,229.780853 190.611023,254.912552 
   C202.985565,276.992645 215.187897,299.170258 227.514725,321.277740 
   C241.462830,346.292877 255.431015,371.297089 269.318817,396.345520 
   C281.431152,418.191681 293.740997,439.927734 305.762268,461.824493 
   C309.041626,467.797729 312.361115,473.749939 315.852692,479.604767 
   C306.993713,463.498596 298.097076,447.412170 289.123657,431.371002 
   C278.636963,412.624573 268.240662,393.827606 257.716400,375.101715 
   C241.727295,346.652344 226.007507,318.052155 210.062408,289.577087 
   C197.859756,267.785339 185.805466,245.908722 173.659576,224.084152 
   C159.652145,198.914673 145.578568,173.781693 131.624969,148.582550 
   C119.363411,126.439133 106.998985,104.353172 94.685371,82.239067 
   C80.834099,57.363449 67.163528,32.386463 53.056744,7.653173 
   C51.992218,5.786754 50.754185,3.956227 50.853188,1.337784 
   C57.687645,1.000000 64.375290,1.000000 71.531464,1.000000 
   C78.140274,8.953417 82.271797,18.105225 87.170189,26.782110 
   C103.215111,55.203701 118.895065,83.830215 134.851120,112.303268 
   C148.772415,137.145370 162.469101,162.114685 176.360779,186.974380 
   C190.415054,212.125046 204.388519,237.320648 218.418869,262.484589 
   C232.205292,287.211029 245.991028,311.937988 259.731476,336.690186 
   C273.713318,361.877228 287.798889,387.006714 301.816345,412.173950 
   C307.951508,423.189117 314.120605,434.185913 320.221466,445.220612 
   C309.629669,425.276123 298.368744,405.700409 287.440277,385.942108 
   C276.721497,366.562897 265.976959,347.195862 255.103317,327.901581 
   C237.525726,296.711761 220.296509,265.327545 202.816483,234.082733 
   C185.579437,203.272202 168.561462,172.337494 151.332748,141.521515 
   C134.185257,110.850822 117.262810,80.055138 99.996109,49.450283 
   C91.732651,34.803490 83.669563,20.041136 75.474991,5.353704 
   C74.823990,4.186906 74.161629,3.010576 74.778297,1.291646 
   C81.687645,1.000000 88.375290,1.000000 95.531464,1.000000 
   C103.108444,11.887696 108.869522,23.543543 115.229774,34.855801 
   C129.182220,59.671398 142.999512,84.563217 156.802750,109.462479 
   C168.911743,131.305511 181.172394,153.064117 193.293076,174.900589 
   C207.434448,200.377487 221.657959,225.808487 235.842255,251.261307 
   C248.001083,273.079498 260.057678,294.955170 272.258179,316.750427 
   C281.610321,333.457275 290.917938,350.189209 300.207642,366.930359 
   C309.399902,383.496002 318.681152,400.011169 327.938965,416.539856 
   C315.052002,393.228577 302.123077,369.941071 289.124420,346.691467 
   C278.481445,327.655334 267.960541,308.549316 257.348389,289.495178 
   C243.496490,264.624023 229.605194,239.774612 215.795074,214.880325 
   C203.679993,193.041550 191.437363,171.274109 179.327652,149.432419 
   C165.361496,124.242393 151.256027,99.129494 137.276001,73.947578 
   C124.507378,50.947742 111.559967,28.046009 99.044540,4.905757 
   C98.491516,3.883238 97.847244,2.894648 97.870819,1.344767 
   C105.020950,1.000000 112.041893,1.000000 119.531418,1.000000 
   C127.651039,14.259475 134.955536,27.713045 142.388962,41.093765 
   C156.457748,66.418556 170.667068,91.665070 184.702255,117.008072 
   C198.415924,141.770477 212.205688,166.490295 225.996765,191.209335 
   C239.949585,216.218307 253.848434,241.257401 267.823547,266.254089 
   C281.798370,291.250214 295.622955,316.331665 309.568329,341.344940 
   C318.884125,358.054382 328.139252,374.799286 337.607788,391.423157 
   C328.366974,374.782562 319.161469,358.122528 309.907104,341.490265 
   C295.660583,315.885834 281.322693,290.332184 267.116364,264.705750 
   C255.329895,243.444458 243.475235,222.221664 231.630524,200.993011 
   C217.434235,175.549774 203.322479,150.059647 189.100555,124.630341 
   C176.748611,102.544579 164.514053,80.391518 152.230103,58.266907 
   C143.260651,42.112030 134.246765,25.981455 125.204208,9.867076 
   C123.750145,7.275843 121.976189,4.784803 121.853088,1.336282 
   C128.687637,1.000000 135.375290,1.000000 142.531464,1.000000 
   C147.665878,6.722593 150.597565,13.493215 154.155930,19.859226 
   C174.540237,56.327183 194.814941,92.856712 215.124176,129.366776 
   C239.721497,173.585602 264.367615,217.777252 288.959625,261.999115 
   C308.779236,297.639160 328.690735,333.228241 348.567047,368.837585 
   C337.882294,348.714813 326.520325,328.963104 315.489075,309.031464 
   C304.768829,289.661835 294.002838,270.315765 283.165436,251.010162 
   C263.935486,216.754288 244.919159,182.378891 225.792374,148.065414 
   C208.529770,117.096283 191.299561,86.107964 174.098236,55.104149 
   C165.216217,39.095150 156.367508,23.066196 147.339523,7.137874 
   C146.369781,5.426910 145.092255,3.772165 145.778214,1.291141 
   C152.687637,1.000000 159.375290,1.000000 166.531464,1.000000 
   C174.205795,12.603200 180.467270,24.741154 187.124298,36.653629 
   C201.096497,61.656357 215.077835,86.654305 228.952667,111.711380 
   C241.054260,133.566071 253.289200,155.346375 265.438354,177.174515 
   C279.531250,202.495026 293.625061,227.814926 307.748138,253.118668 
   C321.627625,277.985962 335.402100,302.912445 349.270966,327.786133 
   C353.082611,334.622284 356.948517,341.428253 361.096588,349.129089 
   C360.982178,348.903198 361.504120,347.997284 360.992218,348.424377 
   C360.928650,348.477448 360.822174,348.354004 360.661743,348.060120 
   C344.626709,318.680634 328.115417,289.565796 311.889984,260.292603 
   C296.389801,232.327759 280.843689,204.388306 265.279541,176.459412 
   C249.797577,148.677979 234.349197,120.877502 218.813828,93.125488 
   C204.986343,68.424385 191.268341,43.660408 177.579819,18.881233 
   C174.460709,13.234983 170.607483,7.951415 168.853149,1.336055 
   C175.687637,1.000000 182.375290,1.000000 189.531464,1.000000 
   C195.978683,8.060778 199.575165,16.566927 204.040665,24.519762 
   C220.094925,53.111588 235.986526,81.795303 251.933853,110.447327 
   C267.397125,138.229675 282.782715,166.056198 298.298798,193.809082 
   C313.894592,221.704575 329.314636,249.697723 344.990295,277.549347 
   C354.132294,293.792358 363.200317,310.081390 372.106354,326.457703 
   C372.850586,327.826202 373.467468,329.279907 374.831696,330.688629 
   C365.497864,314.108124 356.460083,297.805634 347.392914,281.519531 
   C335.085205,259.412903 322.785309,237.301971 310.457672,215.206436 
   C298.129700,193.110245 285.911346,170.952026 273.571136,148.862625 
   C259.607941,123.868034 245.660034,98.864769 231.774414,73.827324 
   C219.099579,50.973057 206.167679,28.261673 193.689453,5.298275 
   C193.057281,4.134882 192.225830,3.038865 192.780411,1.292267 
   C199.687637,1.000000 206.375290,1.000000 213.531464,1.000000 
   C219.137924,7.804982 222.603333,15.593584 226.745285,22.971136 
   C242.795395,51.559097 258.711914,80.222610 274.633179,108.882874 
   C290.151276,136.817398 305.706146,164.731613 321.243225,192.655457 
   C336.698303,220.431946 352.178467,248.195007 367.685730,275.942719 
   C374.587585,288.292480 381.328918,300.732056 388.449982,312.960083 
   C376.757874,291.683594 364.929840,270.481964 353.098633,249.282700 
   C340.526642,226.756119 328.062286,204.169601 315.451813,181.664734 
   C301.458160,156.691406 287.594238,131.646027 273.655579,106.642174 
   C259.959351,82.073135 246.255234,57.508347 232.603577,32.914413 
   C228.241119,25.055285 223.894424,17.187454 219.496658,9.347749 
   C218.141510,6.931969 216.185791,4.677153 216.778351,1.290592 
   C223.687637,1.000000 230.375290,1.000000 237.531464,1.000000 
   C245.396194,13.452820 252.234344,26.219912 259.258850,38.880760 
   C272.994202,63.637222 286.863342,88.318977 300.585693,113.082741 
   C312.694122,134.934021 324.875000,156.745377 337.078979,178.543747 
   C351.397461,204.118912 365.522003,229.801910 379.876251,255.357910 
   C387.866730,269.584015 395.823486,283.832642 403.701385,298.123383 
   C391.052063,274.969910 378.198761,251.928711 365.375214,228.871979 
   C353.154724,206.899521 340.856720,184.969742 328.635101,162.997742 
   C314.469147,137.530350 300.267273,112.083084 286.077728,86.628952 
   C273.833679,64.664772 261.602661,42.692364 249.474274,20.663963 
   C246.042664,14.431217 241.699738,8.648441 239.853088,1.335299 
   C246.687637,1.000000 253.375290,1.000000 260.531464,1.000000 
   C267.051758,8.808577 271.130035,17.799168 275.934570,26.333996 
   C292.104401,55.058395 308.006531,83.933357 324.087738,112.708549 
   C339.519348,140.321365 354.772278,168.035141 370.191071,195.655823 
   C386.643463,225.128021 402.972931,254.668976 419.554749,284.069092 
   C409.240265,265.109924 398.696014,246.278046 388.179993,227.431229 
   C375.607117,204.898148 363.125885,182.314056 350.514984,159.802368 
   C336.520691,134.821289 322.639343,109.777405 308.720245,84.754608 
   C293.990997,58.275440 279.217773,31.820850 264.474213,5.349723 
   C263.824249,4.182765 263.160828,3.007199 263.778564,1.290085 
   C573.406067,1.000000 882.812195,1.009580 1192.218262,0.894025 
   C1196.202148,0.892537 1197.119141,1.591223 1197.115845,5.715516 
   C1196.980347,173.164490 1197.000000,340.613586 1197.000000,508.531342 
   C1194.377197,511.104065 1191.259888,510.391052 1188.329834,510.392120 
   C1090.896118,510.427307 993.462280,510.415771 896.028503,510.427551 
   C882.661377,510.429169 884.940247,509.469299 884.339905,521.829956 
   C882.642334,556.781494 874.681091,590.321594 860.823364,622.458008 
   C849.390381,648.971436 834.134521,673.093994 815.260742,694.914978 
   C791.576233,722.297913 763.653076,744.378723 731.622864,761.251953 
   C720.410034,767.158752 708.867920,772.335693 696.519409,776.704346 
   C695.367859,777.130371 694.586975,777.354797 693.451294,777.753784 
   C687.728455,779.479309 682.518250,781.538757 676.702271,782.924927 
   C675.347961,783.253418 674.349487,783.433777 673.013916,783.853333 
   C666.933960,785.683044 661.092773,786.853333 654.879272,788.165405 
   C653.722778,788.455933 652.943115,788.625000 651.777466,788.932251 
   C645.376526,789.688416 639.453796,790.979980 633.001282,791.411255 
   C631.756531,791.543762 630.940125,791.724487 629.706787,792.006104 
   C623.200439,792.359070 617.194824,793.767029 610.620850,793.411743 
   C609.289429,793.434509 608.399475,793.416748 607.059143,793.426514 
   C600.550659,793.603394 594.491821,793.481934 587.984253,793.465942 
   C586.642822,793.400146 585.750549,793.507874 584.418945,793.429138 
   C576.818481,792.723083 569.638306,792.220093 562.074585,791.268921 
   C560.534363,791.115967 559.498596,790.651367 557.986267,790.458252 
   C550.260559,789.835205 543.157959,788.045349 535.609009,786.476624 
   C534.168884,786.236572 533.167847,785.901855 531.785034,785.493896 
   C523.442383,783.466309 515.564331,781.232239 507.483337,778.347107 
   C506.168427,777.934326 505.256989,777.516785 503.940186,777.133179 
   C494.697968,774.127197 486.183533,770.321594 477.376923,766.269897 
   C475.693237,765.609985 474.567230,764.679382 472.932251,763.942383 
   C462.306793,759.015625 452.703705,752.991577 442.683777,747.005066 
   C441.163971,746.223877 440.376099,745.064087 438.935883,744.198730 
   C438.448517,743.932617 438.254425,743.822876 437.777222,743.532715 
   C437.127716,743.089478 436.768738,742.816895 436.143250,742.329956 
   C423.492004,733.193542 411.893585,723.305542 400.120483,713.097534 
   C397.160065,711.064880 396.260254,707.683228 393.402832,705.531860 
   C392.989929,705.130981 392.826843,704.967163 392.424377,704.550537 
   C391.867462,703.951538 391.550964,703.604431 390.981354,703.001770 
   C374.826691,685.785278 361.387787,667.032349 350.001251,646.785034 
   C339.758820,628.572205 331.849030,609.385437 325.933960,589.356628 
   C325.271484,587.113464 324.862366,584.824280 324.194336,582.202576 
   C323.843262,577.620117 320.591919,574.415710 320.196442,569.851685 
   C319.447723,560.064148 316.887329,550.944153 316.117371,541.554626 
   C315.685577,536.289124 314.996155,531.045837 314.600494,525.380066 
   C314.701416,522.488525 314.916595,519.984253 314.606537,517.495544 
   C314.175171,514.033142 313.865021,510.773804 309.747498,509.039185 
   C307.004181,507.883453 306.048950,504.434937 304.609253,501.838562 
   C275.451141,449.254150 246.104446,396.774780 216.863281,344.236786 
   C194.669373,304.360687 172.502075,264.469055 150.327316,224.582001 
   C128.234207,184.841797 106.073418,145.138931 83.937363,105.422508 
   C65.774231,72.834274 47.623322,40.239120 29.495968,7.630937 
   C28.468016,5.781817 27.089104,3.960128 27.777439,1.296538 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M694.828491,776.379333 
   C705.670410,771.530701 716.547424,767.443848 726.909119,762.096436 
   C746.745239,751.859253 765.182007,739.658386 782.345093,725.329468 
   C802.630615,708.393677 820.057739,688.926086 834.789978,667.156799 
   C856.445068,635.157959 870.696960,599.947815 878.024475,561.981262 
   C881.071777,546.192322 883.028137,530.265625 882.901123,514.169983 
   C882.867737,509.941345 884.196777,508.891815 888.279846,508.907227 
   C926.085999,509.049927 963.892944,508.991211 1001.699646,508.991516 
   C1066.487305,508.992004 1131.275024,508.992889 1196.531372,508.996826 
   C1197.000000,872.354248 1197.000000,1235.708496 1197.000000,1599.531372 
   C1195.538086,1601.318481 1193.726196,1601.525757 1191.887695,1601.524902 
   C1180.134399,1601.519531 1168.380005,1601.775757 1156.208618,1601.325928 
   C1152.840210,1600.664185 1151.463989,1598.390869 1150.144531,1596.020752 
   C1122.677490,1546.682983 1095.193359,1497.354614 1067.740234,1448.009155 
   C1039.315918,1396.917725 1010.897766,1345.822510 982.468262,1294.733765 
   C953.633057,1242.916260 924.768494,1191.114624 895.880615,1139.326294 
   C872.266663,1096.992432 848.721863,1054.619873 825.172913,1012.249817 
   C797.175110,961.874939 769.083435,911.552429 741.078491,861.181458 
   C726.105225,834.249756 711.118530,807.324707 696.110352,780.411926 
   C695.460449,779.246399 694.710632,778.131531 694.828491,776.379333 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.220355,550.294434 
   C1.000000,537.645752 1.000000,525.291565 1.000000,512.468628 
   C3.553661,511.916473 4.230289,514.086487 5.121718,515.713074 
   C20.246431,543.310608 35.805374,570.666260 51.023495,598.211914 
   C66.402496,626.048767 82.040886,653.742065 97.464813,681.554077 
   C111.193382,706.309082 124.957893,731.044006 138.768234,755.753296 
   C152.659149,780.606689 166.385208,805.551453 180.331268,830.374695 
   C192.808426,852.583374 205.019440,874.941895 217.459900,897.171875 
   C231.445007,922.162170 245.350525,947.196777 259.261993,972.227905 
   C272.932098,996.824829 286.750885,1021.339050 300.340942,1045.980225 
   C315.781830,1073.977417 331.547668,1101.793213 346.995667,1129.786377 
   C362.443939,1157.780151 378.185760,1185.610718 393.663910,1213.588257 
   C409.141510,1241.564819 424.830658,1269.423706 440.357147,1297.373047 
   C454.022980,1321.972900 467.711914,1346.559692 481.423279,1371.134155 
   C497.082092,1399.198975 512.651611,1427.313354 528.343018,1455.360229 
   C542.327332,1480.355957 556.121826,1505.458008 570.111816,1530.450928 
   C582.068604,1551.811646 593.987061,1573.193848 605.731445,1594.672119 
   C606.689575,1596.424316 608.655334,1598.195312 606.381714,1600.715576 
   C600.393738,1601.958618 594.724121,1601.444336 588.666382,1601.198486 
   C585.787720,1600.449341 584.524231,1598.498901 583.372009,1596.432007 
   C563.345520,1560.508057 543.421387,1524.527100 523.382019,1488.610352 
   C501.233765,1448.913818 479.058624,1409.231812 457.012573,1369.478882 
   C438.277863,1335.696777 419.422485,1301.981934 400.678162,1268.205200 
   C380.236267,1231.369385 359.702942,1194.583252 339.155243,1157.805664 
   C318.851685,1121.464844 298.724945,1085.025391 278.419006,1048.685913 
   C256.246857,1009.006714 234.113068,969.305725 212.047775,929.567444 
   C193.214828,895.650513 174.288605,861.785400 155.469238,827.860840 
   C135.034500,791.024353 114.469429,754.259583 93.946915,717.471436 
   C73.910942,681.555542 53.958202,645.593201 33.963322,609.654358 
   C23.036127,590.013855 11.775595,570.555847 1.220355,550.294434 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.221045,592.294067 
   C1.000000,579.645752 1.000000,567.291565 1.000000,554.468628 
   C3.277820,553.746826 3.612874,555.746460 4.367395,557.073120 
   C15.808208,577.188782 26.849276,597.528076 38.220337,617.683167 
   C55.643936,648.566345 72.644379,679.685059 90.010475,710.601379 
   C102.564705,732.951294 114.831345,755.467346 127.341103,777.843445 
   C142.857681,805.597839 158.287216,833.400757 173.783615,861.166260 
   C185.955353,882.974854 198.128403,904.783264 210.224274,926.634644 
   C224.012146,951.542542 237.983932,976.348755 251.836121,1001.220947 
   C263.825897,1022.749146 275.717133,1044.332275 287.774963,1065.822876 
   C303.498352,1093.846558 318.944580,1122.024292 334.700348,1150.030762 
   C345.475861,1169.184570 356.040283,1188.458862 366.724152,1207.665161 
   C380.724365,1232.833130 394.836182,1257.939331 408.787354,1283.134155 
   C419.109955,1301.776123 429.502777,1320.378662 439.890045,1338.984131 
   C453.847443,1363.984253 467.649536,1389.070435 481.692596,1414.023438 
   C492.469818,1433.173706 503.032990,1452.445679 513.714722,1471.650513 
   C527.713867,1496.819946 541.823975,1521.927734 555.780029,1547.120850 
   C564.654968,1563.141357 573.641968,1579.099731 582.428711,1595.168335 
   C583.290649,1596.744751 585.157471,1598.335327 583.255615,1600.731201 
   C576.970947,1601.957275 571.009705,1601.444458 564.659790,1601.187012 
   C561.703186,1600.405273 560.808594,1598.161865 559.677124,1596.136353 
   C538.159058,1557.612793 516.790405,1519.005737 495.270264,1480.483765 
   C470.096008,1435.420532 444.995911,1390.315796 419.882080,1345.219360 
   C395.981964,1302.302124 372.125000,1259.359619 348.224304,1216.442627 
   C323.110260,1171.347046 297.950439,1126.275879 272.842804,1081.176758 
   C248.950439,1038.260620 225.104034,995.317871 201.191025,952.413452 
   C176.061630,907.326721 150.952942,862.227661 125.825043,817.140076 
   C103.615005,777.288086 81.402977,737.435974 59.285633,697.531616 
   C40.720940,664.036926 22.053314,630.598083 3.320586,597.196350 
   C2.491700,595.718323 2.097039,594.111145 1.221045,592.294067 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.249275,635.269897 
   C1.000000,622.645752 1.000000,610.291565 1.000000,597.468628 
   C3.227684,596.505859 3.888257,598.297180 4.646548,599.678589 
   C14.901563,618.359924 25.293047,636.965637 35.678532,655.574219 
   C48.011234,677.671875 60.371510,699.754395 72.632896,721.891418 
   C82.877762,740.387695 93.221542,758.828186 103.524536,777.291687 
   C117.637947,802.583679 131.609512,827.954163 145.805664,853.200928 
   C156.493118,872.207886 166.992661,891.322754 177.565598,910.395264 
   C189.914520,932.671448 202.454987,954.841248 214.778351,977.130859 
   C226.701370,998.696350 238.742157,1020.195435 250.725647,1041.726807 
   C264.491486,1066.460571 278.173065,1091.241333 292.014862,1115.932739 
   C304.635468,1138.445801 317.050781,1161.073120 329.666534,1183.588745 
   C343.745880,1208.716919 357.752655,1233.885620 371.727936,1259.071289 
   C383.602814,1280.471802 395.638977,1301.781982 407.473816,1323.204346 
   C421.481995,1348.560669 435.664032,1373.820068 449.768372,1399.122681 
   C460.387878,1418.173584 470.895569,1437.287354 481.558716,1456.314087 
   C495.721771,1481.585571 509.727936,1506.944336 523.854553,1532.236084 
   C535.464661,1553.022583 547.038391,1573.829468 558.621155,1594.631226 
   C559.600403,1596.389893 561.234436,1598.256104 559.256714,1600.735596 
   C553.293274,1601.974365 547.642456,1601.361938 541.608765,1601.289429 
   C537.925476,1600.631226 536.846985,1597.752441 535.518005,1595.356323 
   C526.549927,1579.187744 517.551941,1563.035522 508.574615,1546.872070 
   C494.663910,1521.825928 480.604187,1496.862671 466.749695,1471.785522 
   C453.136658,1447.145508 439.265900,1422.649414 425.655121,1398.008423 
   C411.882629,1373.074951 397.950134,1348.230469 384.081055,1323.350830 
   C370.211975,1298.471069 356.445740,1273.534424 342.498993,1248.697632 
   C326.839752,1220.811035 311.457581,1192.770142 295.814850,1164.873657 
   C281.638763,1139.592773 267.714111,1114.170532 253.550735,1088.882202 
   C237.841461,1060.833618 222.328568,1032.676147 206.630127,1004.621765 
   C191.175552,977.003052 175.884766,949.293091 160.456284,921.659973 
   C144.864929,893.735291 129.334305,865.776855 113.769882,837.837158 
   C99.988464,813.098206 86.152290,788.389648 72.437096,763.613770 
   C56.946964,735.631592 41.191360,707.796875 25.756926,679.784241 
   C17.640400,665.053223 9.073532,650.567810 1.249275,635.269897 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.220987,677.293945 
   C1.000000,664.645752 1.000000,652.291565 1.000000,639.468628 
   C3.475505,638.814575 3.838831,641.025085 4.673567,642.467407 
   C12.512815,656.012329 19.976236,669.769043 27.584030,683.444336 
   C39.966896,705.703064 52.414909,727.925415 64.808372,750.177856 
   C75.419319,769.229858 85.911964,788.348755 96.578239,807.369934 
   C109.035439,829.584900 121.403038,851.849915 133.759979,874.119995 
   C145.634537,895.520691 157.654297,916.840210 169.491882,938.261414 
   C181.812866,960.557190 194.258865,982.783386 206.701584,1005.011719 
   C217.355957,1024.045288 227.819931,1043.186401 238.476837,1062.219360 
   C252.631119,1087.498657 266.716309,1112.816650 280.774780,1138.148804 
   C294.348175,1162.607056 308.090424,1186.970703 321.593719,1211.467896 
   C335.499115,1236.694458 349.676392,1261.769897 363.676910,1286.943481 
   C375.573425,1308.334106 387.421112,1329.751587 399.397888,1351.097900 
   C415.284943,1379.413940 430.897491,1407.882812 446.793732,1436.194946 
   C459.183624,1458.262207 471.347137,1480.458252 483.673981,1502.561890 
   C497.702332,1527.716675 511.692627,1552.892822 525.735657,1578.039429 
   C529.063904,1583.999390 532.342896,1589.987671 535.648132,1595.960938 
   C536.456116,1597.421143 537.247803,1598.979980 535.377319,1600.720215 
   C529.387573,1601.951904 523.718750,1601.450317 517.661682,1601.187866 
   C514.238708,1600.145386 513.054626,1597.432129 511.701813,1594.996094 
   C488.250366,1552.766357 464.752777,1510.562378 441.245087,1468.364014 
   C414.091309,1419.620728 386.912689,1370.891235 359.788055,1322.131958 
   C331.285339,1270.895508 302.790619,1219.654297 274.247375,1168.440552 
   C247.490128,1120.431274 220.728622,1072.423706 193.974930,1024.412476 
   C173.382584,987.458069 152.821716,950.485107 132.268951,913.508240 
   C108.479477,870.708252 84.680588,827.913025 60.765377,785.182739 
   C41.568077,750.882141 22.737980,716.376770 3.323134,682.197815 
   C2.485148,680.722595 2.096926,679.111755 1.220987,677.293945 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.220940,719.293823 
   C1.000000,706.979126 1.000000,694.958191 1.000000,682.468628 
   C3.488792,681.543030 4.047851,683.619019 4.884446,685.118713 
   C20.215736,712.601135 35.392036,740.170837 50.783928,767.618469 
   C67.966652,798.259583 84.929550,829.022400 102.091156,859.675049 
   C115.997925,884.514221 129.729279,909.452820 143.598038,934.313354 
   C159.088852,962.081543 174.620804,989.827759 190.048523,1017.630554 
   C203.782471,1042.380981 217.648193,1067.057983 231.333984,1091.835693 
   C246.710983,1119.675537 262.380463,1147.354004 277.788605,1175.176636 
   C291.503693,1199.942017 305.333771,1224.643066 319.095245,1249.382324 
   C333.016907,1274.409546 346.944885,1299.433594 360.929260,1324.426270 
   C373.449005,1346.801270 385.742126,1369.305664 398.286530,1391.667114 
   C412.214935,1416.495850 425.956024,1441.428223 439.865997,1466.266968 
   C451.417664,1486.894287 463.017731,1507.496582 474.400238,1528.217041 
   C486.746582,1550.692505 499.469940,1572.957275 511.726624,1595.481323 
   C512.560669,1597.013916 514.120422,1598.441406 512.415039,1600.652588 
   C506.334717,1602.075806 500.511261,1601.114746 494.345337,1601.516357 
   C491.666290,1601.709351 490.766510,1600.042358 489.885284,1598.419312 
   C475.026367,1571.054321 459.608124,1544.000122 444.522186,1516.761475 
   C429.113556,1488.940063 413.601746,1461.176270 398.111328,1433.400513 
   C384.243347,1408.534058 370.484680,1383.606934 356.544922,1358.780273 
   C340.812286,1330.760742 325.345398,1302.593140 309.635345,1274.560425 
   C295.472260,1249.288086 281.530823,1223.890869 267.397339,1198.601440 
   C253.588425,1173.892822 239.893433,1149.121216 226.100739,1124.403931 
   C212.226852,1099.540894 198.315445,1074.698853 184.521393,1049.791260 
   C170.646835,1024.738403 156.547363,999.810791 142.702332,974.741638 
   C129.018188,949.963684 115.131378,925.298828 101.389297,900.553223 
   C87.647087,875.807495 73.903770,851.062378 60.087486,826.357788 
   C47.490910,803.833984 35.030003,781.234680 22.459616,758.696350 
   C16.944851,748.808594 11.387854,738.944214 5.859383,729.064026 
   C4.147556,726.004639 2.455040,722.961365 1.220940,719.293823 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.220892,762.294189 
   C1.000000,749.645752 1.000000,737.291565 1.000000,724.468628 
   C3.475600,723.814392 3.845750,726.022034 4.672822,727.467529 
   C12.113707,740.472595 19.398125,753.566528 26.706675,766.646545 
   C40.754253,791.787537 54.628365,817.024475 68.756340,842.121521 
   C79.454315,861.125366 89.942673,880.249207 100.528778,899.317200 
   C112.892776,921.587402 125.443016,943.754333 137.745056,966.058289 
   C149.647049,987.636902 161.686783,1009.137878 173.708054,1030.649536 
   C187.758240,1055.791748 201.628647,1081.033569 215.759827,1106.131592 
   C226.459122,1125.134521 236.952728,1144.255249 247.533417,1163.326050 
   C259.890869,1185.599365 272.428436,1207.772461 284.752045,1230.063965 
   C296.594299,1251.484863 308.531128,1272.852417 320.476624,1294.215454 
   C334.615906,1319.501831 348.544983,1344.904663 362.756958,1370.151611 
   C373.455353,1389.156738 383.958801,1408.273804 394.542145,1427.344849 
   C406.903046,1449.619141 419.390045,1471.823242 431.758514,1494.093018 
   C443.643524,1515.492310 455.471832,1536.922852 467.463440,1558.262939 
   C473.581207,1569.149902 479.633453,1580.074463 485.656860,1591.013062 
   C487.223328,1593.857788 490.327881,1596.637329 487.434906,1600.708740 
   C481.762573,1601.988525 476.394440,1601.358643 470.645874,1601.295654 
   C467.792175,1600.855713 466.728302,1598.961182 465.647156,1597.045410 
   C454.186249,1576.736328 442.969971,1556.291016 431.610077,1535.925659 
   C415.948669,1507.848755 400.252502,1479.791016 384.666260,1451.672241 
   C369.402893,1424.136108 353.950989,1396.705444 338.715332,1369.153931 
   C323.077148,1340.874634 307.162048,1312.749146 291.538055,1284.462280 
   C276.155792,1256.612671 260.519379,1228.905151 245.124985,1201.062256 
   C229.731934,1173.222046 214.087326,1145.521118 198.676529,1117.690796 
   C184.960007,1092.919922 171.098389,1068.230347 157.349106,1043.477905 
   C143.599762,1018.725220 129.854279,993.970459 116.030334,969.259216 
   C103.507515,946.873901 91.158401,924.391663 78.613876,902.018555 
   C64.521484,876.884888 50.580986,851.666870 36.557030,826.495239 
   C25.208130,806.125061 13.783418,785.796936 2.512273,765.383423 
   C2.026447,764.503601 1.905962,763.479187 1.220892,762.294189 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.220955,804.294067 
   C1.000000,791.645752 1.000000,779.291565 1.000000,766.468628 
   C3.493383,765.845032 3.756466,768.066711 4.574869,769.528015 
   C23.851755,803.948181 43.065071,838.404114 62.226410,872.888489 
   C77.748634,900.823547 93.244308,928.774292 108.810349,956.684387 
   C127.540428,990.267761 146.190308,1023.895996 164.967575,1057.453247 
   C180.576340,1085.348022 196.000214,1113.346924 211.580688,1141.257324 
   C227.079590,1169.021606 242.541718,1196.806763 258.027130,1224.578491 
   C271.890564,1249.441284 285.746094,1274.309326 299.549561,1299.205933 
   C314.967377,1327.014160 330.608917,1354.699097 345.998383,1382.522583 
   C359.778015,1407.435547 373.750336,1432.240479 387.520538,1457.158447 
   C402.899323,1484.987305 418.479858,1512.704590 433.982635,1540.464966 
   C444.128204,1558.632324 454.116821,1576.887451 464.299622,1595.034790 
   C465.235992,1596.703735 466.504425,1598.551636 464.378113,1600.719727 
   C458.387695,1601.952148 452.718109,1601.450806 446.660187,1601.187134 
   C443.714050,1600.412476 442.732758,1598.190186 441.604858,1596.178467 
   C430.853821,1577.003296 420.099365,1557.829956 409.430450,1538.608887 
   C395.690033,1513.854370 381.925354,1489.113281 368.126831,1464.390991 
   C355.546692,1441.851562 343.114380,1419.229248 330.487701,1396.716309 
   C314.845520,1368.826660 299.436951,1340.807373 283.807770,1312.910767 
   C269.969208,1288.210571 256.250244,1263.443848 242.476761,1238.707397 
   C228.542297,1213.682007 214.512207,1188.709595 200.650085,1163.644165 
   C187.029190,1139.015015 173.196732,1114.504028 159.558975,1089.884521 
   C145.598465,1064.682373 131.512054,1039.550415 117.490753,1014.382385 
   C103.712379,989.650269 90.037514,964.860291 76.195801,940.163269 
   C60.644379,912.415710 45.374332,884.511658 29.752237,856.803101 
   C20.837574,840.991272 12.180431,825.034668 3.241081,809.235962 
   C2.408271,807.764221 2.098909,806.114441 1.220955,804.294067 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.220320,847.294617 
   C1.000000,834.645752 1.000000,822.291565 1.000000,809.468628 
   C3.586402,808.881897 4.240451,811.130188 5.108760,812.723755 
   C16.976595,834.503235 29.204882,856.082031 41.293774,877.737671 
   C55.165813,902.587463 68.891052,927.518677 82.851425,952.320251 
   C93.708603,971.608826 104.335052,991.030823 115.089996,1010.378784 
   C127.300179,1032.344604 139.631851,1054.243530 151.824280,1076.218872 
   C163.695709,1097.615845 175.611328,1118.987793 187.535049,1140.355469 
   C201.568878,1165.504395 215.495361,1190.712891 229.570114,1215.839478 
   C242.017685,1238.061279 254.293259,1260.380249 266.708496,1282.620728 
   C280.744904,1307.765137 294.781799,1332.909546 308.750519,1358.091187 
   C320.700134,1379.632935 332.804321,1401.088013 344.714172,1422.651855 
   C358.635376,1447.857300 372.741669,1472.959961 386.767426,1498.107300 
   C397.388977,1517.151123 407.909576,1536.251465 418.540344,1555.290527 
   C425.844086,1568.370972 433.160431,1581.444214 440.423523,1594.547241 
   C441.434906,1596.371826 443.078888,1598.085693 441.412689,1600.654297 
   C435.329590,1602.073608 429.504395,1601.118408 423.335938,1601.512695 
   C420.058319,1601.670898 419.488770,1599.159424 418.517670,1597.394043 
   C407.999481,1578.272827 397.377838,1559.209106 386.712708,1540.170166 
   C370.919220,1511.976685 355.305878,1483.683350 339.539764,1455.474609 
   C324.017975,1427.703369 308.625671,1399.859619 293.118011,1372.080688 
   C277.610535,1344.301880 262.152069,1316.495972 246.671783,1288.702148 
   C232.973953,1264.108765 219.212891,1239.550659 205.579071,1214.921753 
   C191.622955,1189.710449 177.426361,1164.633057 163.503677,1139.403809 
   C148.212158,1111.694092 132.641296,1084.140625 117.330902,1056.441772 
   C101.859505,1028.451660 86.148849,1000.594727 70.640953,972.625122 
   C56.829102,947.714600 42.903381,922.867493 29.114716,897.944336 
   C19.842918,881.185425 10.164427,864.649414 1.220320,847.294617 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.220951,889.294189 
   C1.000000,876.645752 1.000000,864.291565 1.000000,851.468628 
   C3.040963,850.683105 3.446055,852.431824 4.130104,853.634644 
   C14.089503,871.147156 23.690990,888.859985 33.585087,906.409058 
   C46.094265,928.596313 58.440094,950.875244 70.774536,973.158875 
   C82.787720,994.862244 94.973885,1016.468506 106.988831,1038.170654 
   C119.165596,1060.165161 131.419128,1082.117920 143.703247,1104.053345 
   C156.070068,1126.136841 168.214493,1148.346069 180.587906,1170.426147 
   C194.670700,1195.556641 208.630249,1220.755493 222.641998,1245.925171 
   C234.709564,1267.602417 246.864151,1289.231079 258.861725,1310.947510 
   C272.709900,1336.013794 286.776428,1360.958740 300.697662,1385.984375 
   C312.596649,1407.374634 324.471497,1428.777954 336.421143,1450.140137 
   C350.971191,1476.150879 365.368347,1502.247192 379.885742,1528.276489 
   C392.294556,1550.525391 404.801605,1572.720459 416.941528,1595.118042 
   C417.823639,1596.745483 419.592957,1598.417358 417.381775,1600.715332 
   C411.393341,1601.958008 405.723480,1601.444702 399.665344,1601.198120 
   C396.786621,1600.448486 395.523346,1598.497681 394.371124,1596.430664 
   C374.342529,1560.504883 354.421448,1524.519165 334.374084,1488.603882 
   C312.217407,1448.909790 290.080841,1409.204346 268.009674,1369.463013 
   C244.159317,1326.517944 220.274002,1283.591797 196.356155,1240.684814 
   C174.547836,1201.562012 152.723557,1162.446899 130.929565,1123.316284 
   C108.729607,1083.456787 86.508385,1043.608398 64.379807,1003.708679 
   C44.190273,967.305237 23.892385,930.960938 3.577967,894.626526 
   C2.670638,893.003723 2.111871,891.284668 1.220951,889.294189 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.220341,932.294800 
   C1.000000,919.645752 1.000000,907.291565 1.000000,894.468628 
   C3.227081,893.505615 3.887008,895.296387 4.645776,896.677307 
   C14.825466,915.203369 25.141369,933.653564 35.439922,952.113403 
   C47.927078,974.496094 60.480011,996.843140 72.859261,1019.284851 
   C84.917099,1041.143921 97.173889,1062.891357 109.279823,1084.723145 
   C123.080185,1109.610474 136.938736,1134.466309 150.848206,1159.293579 
   C163.132645,1181.220581 175.204941,1203.268066 187.482788,1225.199585 
   C201.631104,1250.472290 215.677643,1275.801636 229.763489,1301.108765 
   C241.825699,1322.780273 253.962509,1344.410400 265.957153,1366.119751 
   C279.800537,1391.175171 293.861084,1416.109985 307.783844,1441.121216 
   C319.683228,1462.497803 331.528290,1483.904541 343.479919,1505.252319 
   C358.033264,1531.247437 372.378693,1557.358643 386.917511,1583.362305 
   C389.273193,1587.575439 391.771240,1591.717407 393.899323,1596.052490 
   C394.567810,1597.414185 396.139801,1598.774902 394.254028,1600.731934 
   C387.969452,1601.956787 382.008606,1601.444458 375.659058,1601.186768 
   C372.903412,1600.472900 371.966980,1598.473022 370.910400,1596.577026 
   C349.427399,1558.028198 328.028046,1519.432495 306.507965,1480.904907 
   C283.037384,1438.885498 259.651001,1396.818726 236.202560,1354.787109 
   C217.298752,1320.901611 198.413177,1287.004761 179.594574,1253.071167 
   C160.858658,1219.286621 141.903458,1185.623413 123.181862,1151.831177 
   C106.073456,1120.950806 88.787277,1090.169678 71.699310,1059.277832 
   C56.304565,1031.446899 40.647152,1003.760498 25.258644,975.926025 
   C17.283741,961.501160 8.982941,947.252930 1.220341,932.294800 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.220967,974.294434 
   C1.000000,961.645752 1.000000,949.291565 1.000000,936.468628 
   C3.474972,935.813660 3.838586,938.023010 4.672554,939.465210 
   C12.504013,953.007812 19.967655,966.757690 27.571207,980.428650 
   C39.946560,1002.679260 52.388519,1024.892578 64.783752,1047.131958 
   C75.316223,1066.029175 85.700294,1085.009644 96.297287,1103.871094 
   C108.850853,1126.215332 121.262589,1148.638672 133.707550,1171.042969 
   C145.670059,1192.578735 157.739151,1214.055176 169.674911,1235.605957 
   C183.626480,1260.796631 197.692276,1285.923950 211.726715,1311.068604 
   C222.433746,1330.251953 233.013626,1349.507080 243.735367,1368.682373 
   C257.787292,1393.813477 271.793915,1418.970093 285.779846,1444.137573 
   C297.745575,1465.669434 309.826508,1487.137085 321.744751,1508.695557 
   C335.676086,1533.895264 349.691681,1559.048096 363.814941,1584.140991 
   C365.855804,1587.767090 367.817169,1591.438599 369.833099,1595.078979 
   C370.725922,1596.691284 372.272064,1598.458740 370.262939,1600.732422 
   C364.303223,1601.975830 358.655060,1601.358521 352.624329,1601.290771 
   C348.749329,1600.562256 347.675415,1597.468262 346.278320,1594.938721 
   C337.584656,1579.197266 328.771606,1563.522217 320.063934,1547.788818 
   C306.033783,1522.438232 291.785706,1497.209351 277.777985,1471.846558 
   C262.321136,1443.859985 246.601334,1416.020142 231.124832,1388.044434 
   C215.730133,1360.216431 200.080627,1332.529785 184.684509,1304.702515 
   C171.062149,1280.081299 157.263641,1255.558716 143.599716,1230.960938 
   C129.612671,1205.781494 115.612007,1180.609863 101.554932,1155.469238 
   C87.497589,1130.328003 73.646233,1105.071533 59.554352,1079.949341 
   C40.738411,1046.405518 22.325886,1012.636597 3.323394,979.197144 
   C2.485414,977.722473 2.097273,976.112061 1.220967,974.294434 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.221023,1016.294189 
   C1.000000,1003.979126 1.000000,991.958191 1.000000,979.468628 
   C3.487227,978.543579 4.048914,980.617065 4.884778,982.116943 
   C20.197977,1009.596069 35.404224,1037.135498 50.762924,1064.588501 
   C66.281929,1092.328003 81.729431,1120.107544 97.202827,1147.872070 
   C112.678802,1175.641113 128.086609,1203.449219 143.553604,1231.223389 
   C159.020721,1258.997803 174.551682,1286.737671 189.990051,1314.527954 
   C203.812729,1339.410034 217.737045,1364.235596 231.508286,1389.146484 
   C246.890717,1416.972046 262.532227,1444.654663 277.958313,1472.455688 
   C291.769775,1497.346802 305.670959,1522.187866 319.458221,1547.092407 
   C328.245636,1562.965454 337.182037,1578.755981 345.855408,1594.692383 
   C346.856079,1596.531128 348.621674,1598.255493 346.376770,1600.720459 
   C340.386169,1601.951538 334.716675,1601.450806 328.658752,1601.187256 
   C325.072968,1600.045166 323.898224,1597.134399 322.460205,1594.560669 
   C299.885864,1554.161255 277.391846,1513.716675 254.909378,1473.266357 
   C231.052673,1430.343750 207.188049,1387.424561 183.286835,1344.526978 
   C158.170319,1299.448120 133.051971,1254.369507 107.916962,1209.300781 
   C85.701065,1169.466553 63.471554,1129.638672 41.404613,1089.720703 
   C29.405336,1068.014648 17.329723,1046.349365 5.093968,1024.774536 
   C3.611744,1022.161011 2.304667,1019.475403 1.221023,1016.294189 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.220896,1059.293945 
   C1.000000,1046.645752 1.000000,1034.291504 1.000000,1021.468628 
   C3.475451,1020.813049 3.846482,1023.020203 4.671464,1024.465576 
   C12.096430,1037.474243 19.380810,1050.562744 26.695841,1063.633911 
   C40.756435,1088.758179 54.585312,1114.010864 68.726662,1139.091187 
   C79.352577,1157.936768 89.734772,1176.921753 100.248123,1195.832031 
   C114.401833,1221.290405 128.708145,1246.664429 142.786179,1272.164062 
   C154.774780,1293.879150 166.953964,1315.487061 178.996643,1337.171509 
   C192.896057,1362.199097 206.799622,1387.224365 220.807465,1412.192139 
   C231.475296,1431.206421 241.982819,1450.312378 252.567123,1469.374390 
   C264.928680,1491.637329 277.450867,1513.811035 289.765533,1536.099487 
   C300.874847,1556.206299 312.134491,1576.228882 323.248810,1596.332642 
   C323.960632,1597.620239 324.927094,1598.910889 323.417175,1600.651123 
   C317.337097,1602.076782 311.513397,1601.114136 305.347412,1601.516357 
   C302.667755,1601.710449 301.767334,1600.043945 300.885895,1598.420410 
   C286.027191,1571.049438 270.607941,1543.990112 255.508865,1516.752686 
   C239.926071,1488.642822 224.231277,1460.595215 208.586227,1432.519897 
   C194.805908,1407.790894 181.136169,1383.000122 167.281738,1358.312256 
   C151.634796,1330.430298 136.259949,1302.396973 120.598129,1274.522705 
   C108.117874,1252.310791 95.882286,1229.960815 83.460075,1207.715698 
   C69.495407,1182.708618 55.547123,1157.692139 41.651176,1132.646973 
   C28.643925,1109.203491 15.473473,1085.851196 2.512064,1062.382446 
   C2.026269,1061.502808 1.905694,1060.478638 1.220896,1059.293945 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.221011,1101.294434 
   C1.000000,1088.645752 1.000000,1076.291504 1.000000,1063.468628 
   C3.492200,1062.846436 3.757927,1065.066162 4.576691,1066.528198 
   C23.852819,1100.949219 43.067917,1135.404663 62.226185,1169.891113 
   C77.748680,1197.833008 93.251404,1225.786499 108.819069,1253.702637 
   C127.628799,1287.432373 146.415405,1321.175415 165.223724,1354.906006 
   C182.413559,1385.734009 199.456863,1416.645142 216.686752,1447.450195 
   C236.842316,1483.485596 256.680084,1519.697998 277.053467,1555.612549 
   C284.117432,1568.064941 290.953186,1580.649902 297.885101,1593.178467 
   C299.103943,1595.381348 301.031677,1597.760620 298.444763,1600.703613 
   C292.769836,1601.992920 287.397980,1601.357178 281.645752,1601.296143 
   C278.793640,1600.856201 277.728973,1598.963867 276.648712,1597.049072 
   C265.197449,1576.750244 253.983780,1556.319092 242.632050,1535.964844 
   C226.981934,1507.903198 211.283539,1479.868286 195.716522,1451.760376 
   C180.311279,1423.944458 164.710464,1396.237793 149.316330,1368.415649 
   C133.843521,1340.451050 118.103859,1312.634644 102.651863,1284.658691 
   C89.050201,1260.032593 75.201927,1235.544434 61.581917,1210.928833 
   C47.639740,1185.731079 33.496162,1160.644653 19.576424,1135.435425 
   C13.391529,1124.234375 6.439567,1113.429932 1.221011,1101.294434 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M3.779578,1.297544 
   C10.687645,1.000000 17.375290,1.000000 24.531467,1.000000 
   C29.657936,7.115122 32.907471,14.079610 36.630135,20.734699 
   C52.880051,49.785023 68.988800,78.914154 85.231163,107.969559 
   C100.500938,135.285126 115.600700,162.696899 130.868210,190.014618 
   C150.032150,224.304092 169.071899,258.662720 188.229202,292.956055 
   C203.813416,320.853210 219.229553,348.845886 234.820816,376.739716 
   C251.955185,407.394257 268.856964,438.178223 286.146759,468.746674 
   C292.865143,480.624817 299.339996,492.642517 306.017914,504.545959 
   C306.856903,506.041412 308.198212,507.673584 306.259583,509.735657 
   C300.295258,510.973602 294.643341,510.362305 288.607971,510.290405 
   C285.215881,509.657959 283.925323,507.245819 282.646362,504.914276 
   C262.474182,468.139862 241.943665,431.564209 221.552567,394.911652 
   C199.381317,355.059357 177.128769,315.251770 154.947021,275.405243 
   C134.384048,238.466553 113.893913,201.486465 93.291237,164.570435 
   C69.850456,122.569092 46.584759,80.469887 23.011580,38.542034 
   C16.976307,27.807547 10.937273,17.069359 5.167820,6.185988 
   C4.406017,4.748940 3.181059,3.443717 3.779578,1.297544 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M1.220395,41.295048 
   C1.000000,28.645771 1.000000,16.291544 1.000000,3.468657 
   C3.228714,2.503071 3.887299,4.296778 4.646348,5.677001 
   C14.835557,24.204624 25.102377,42.689362 35.451889,61.127426 
   C47.999683,83.481804 60.455379,105.888039 72.875847,128.312332 
   C84.894745,150.011627 97.083260,171.615936 109.073059,193.331497 
   C122.834267,218.255402 136.769699,243.083282 150.651047,267.940857 
   C161.366562,287.129303 171.920151,306.409271 182.681503,325.572266 
   C196.785187,350.686981 210.699997,375.906586 224.739014,401.057159 
   C236.749603,422.573822 248.821976,444.056671 260.698792,465.647980 
   C267.760315,478.485352 275.062103,491.190216 281.945465,504.124969 
   C282.815521,505.759918 284.596436,507.425171 282.375519,509.718811 
   C276.395111,511.001556 270.722015,510.356781 264.667358,510.298828 
   C261.815643,509.860352 260.749573,507.987762 259.673401,506.057434 
   C234.864105,461.556793 210.128082,417.015320 185.320175,372.513916 
   C159.783951,326.706055 134.232330,280.906616 108.736755,235.076187 
   C81.866997,186.775543 54.892281,138.533203 28.146065,90.163826 
   C19.204947,73.994186 9.864947,58.043941 1.220395,41.295048 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.220360,1144.294922 
   C1.000000,1131.645752 1.000000,1119.291504 1.000000,1106.468628 
   C3.586715,1105.882446 4.240225,1108.131836 5.111430,1109.724976 
   C16.854338,1131.198853 28.888134,1152.510620 40.827755,1173.874512 
   C54.796532,1198.869507 68.584030,1223.964844 82.626892,1248.919312 
   C93.404762,1268.071899 103.975784,1287.342651 114.643982,1306.558105 
   C126.927780,1328.683594 139.370224,1350.720825 151.618759,1372.865845 
   C163.546524,1394.431030 175.551865,1415.952515 187.584839,1437.459106 
   C203.359039,1465.652344 218.938309,1493.954102 234.748947,1522.127808 
   C247.134811,1544.198608 259.351227,1566.366211 271.614563,1588.506470 
   C272.825226,1590.692261 274.102142,1592.840454 275.287689,1595.034424 
   C276.199585,1596.721924 277.502258,1598.546265 275.382294,1600.717529 
   C269.391541,1601.953491 263.721252,1601.449951 257.662659,1601.187500 
   C254.717407,1600.414917 253.741318,1598.191895 252.607758,1596.184814 
   C241.886795,1577.200562 231.257477,1558.164917 220.684113,1539.098145 
   C206.720901,1513.918579 192.721237,1488.759521 178.666580,1463.630615 
   C164.692703,1438.646118 150.880371,1413.571045 136.914505,1388.581665 
   C112.882767,1345.581177 88.915581,1302.544556 64.996056,1259.481934 
   C46.167427,1225.584595 27.084913,1191.827759 8.479130,1157.806152 
   C6.083194,1153.425171 3.535631,1149.129639 1.220360,1144.294922 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M1.221037,83.294487 
   C1.000000,70.645775 1.000000,58.291546 1.000000,45.468658 
   C3.582878,44.925045 4.117008,47.138695 5.021925,48.772690 
   C34.914936,102.750076 64.969116,156.638092 95.024452,210.524811 
   C130.186691,273.567749 165.197983,336.694794 200.446136,399.690033 
   C219.534332,433.804260 238.416351,468.034515 257.448395,502.180786 
   C258.534424,504.129242 260.375641,505.979980 259.168213,508.936615 
   C253.095078,511.579071 247.052826,510.196136 240.694519,510.193085 
   C237.737488,509.439484 236.759003,507.216614 235.622574,505.210388 
   C226.106293,488.410797 216.887939,471.444763 207.426041,454.614990 
   C191.764450,426.757874 176.387497,398.742554 160.772446,370.859741 
   C146.946198,346.171021 133.229874,321.421112 119.469261,296.695831 
   C105.547958,271.681793 91.511284,246.731781 77.666069,221.675629 
   C63.981293,196.909821 50.074883,172.267944 36.360016,147.519028 
   C25.952923,128.739090 15.624839,109.914406 4.983428,91.265579 
   C3.572359,88.792709 2.279760,86.306610 1.221037,83.294487 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.220971,1186.294189 
   C1.000000,1173.645752 1.000000,1161.291504 1.000000,1148.468628 
   C3.039214,1147.682373 3.446667,1149.428589 4.129722,1150.630981 
   C14.073649,1168.136230 23.693977,1185.822388 33.552975,1203.375366 
   C46.018616,1225.569336 58.393970,1247.814087 70.731049,1270.079102 
   C82.667526,1291.620972 94.727165,1313.093628 106.689941,1334.620483 
   C120.673241,1359.783203 134.663376,1384.942017 148.723541,1410.062256 
   C159.450424,1429.226929 170.032501,1448.473999 180.727783,1467.657104 
   C193.042648,1489.745239 205.400208,1511.810059 217.661514,1533.927734 
   C229.117218,1554.592163 240.644775,1575.215820 252.184677,1595.833008 
   C252.961044,1597.220215 254.015167,1598.734253 252.416855,1600.651855 
   C246.332428,1602.075684 240.505493,1601.118286 234.335327,1601.512695 
   C231.059616,1601.671021 230.489639,1599.161621 229.519424,1597.397217 
   C219.011200,1578.287354 208.388550,1559.240967 197.730240,1540.215088 
   C181.947067,1512.041016 166.351410,1483.762695 150.595413,1455.573608 
   C136.706970,1430.725830 122.905579,1405.829590 109.062904,1380.956421 
   C95.220474,1356.083496 81.257286,1331.277344 67.484917,1306.365967 
   C53.792606,1281.599365 39.853703,1256.970703 26.193079,1232.186646 
   C18.961344,1219.066284 11.621118,1206.005859 4.372025,1192.895020 
   C3.238641,1190.845215 2.142243,1188.820190 1.220971,1186.294189 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M1.223105,125.293106 
   C1.000000,112.645775 1.000000,100.291542 1.000000,87.468658 
   C3.338229,86.744225 3.499097,88.836166 4.250725,90.150314 
   C19.621214,117.024239 34.415657,144.219955 49.500870,171.253433 
   C100.361877,262.398987 151.086609,353.620422 201.864853,444.812073 
   C212.877930,464.590210 223.834778,484.400208 234.910767,504.143585 
   C235.825729,505.774536 237.579285,507.414429 235.378189,509.719238 
   C229.395264,511.000000 223.719879,510.359467 217.661942,510.300293 
   C213.965622,509.670349 212.903503,506.786652 211.541656,504.402191 
   C202.702316,488.925415 194.155045,473.284424 185.507278,457.700104 
   C169.988358,429.733093 154.280609,401.871368 138.801163,373.882599 
   C125.096191,349.102295 111.200813,324.428284 97.458031,299.669128 
   C83.715660,274.910736 69.958893,250.160370 56.139801,225.444656 
   C43.539989,202.909668 31.043648,180.317108 18.500191,157.750778 
   C13.563780,148.869904 8.404934,140.113068 3.660776,131.125000 
   C2.726713,129.355362 1.747095,127.607430 1.223105,125.293106 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.220456,1229.295044 
   C1.000000,1216.645752 1.000000,1204.291504 1.000000,1191.468628 
   C3.225994,1190.506958 3.886550,1192.295898 4.647105,1193.676880 
   C14.763220,1212.046143 25.009705,1230.342773 35.186981,1248.677979 
   C47.705444,1271.230957 60.365326,1293.704956 72.873123,1316.263428 
   C82.961876,1334.459106 93.066650,1352.644897 103.230522,1370.798950 
   C119.167274,1399.264160 134.864822,1427.862549 150.859131,1456.296997 
   C161.466507,1475.154541 171.872482,1494.127319 182.394501,1513.034058 
   C196.558350,1538.484985 210.702194,1563.947144 224.962433,1589.344238 
   C226.267960,1591.669312 227.418045,1594.075073 228.661240,1596.432617 
   C229.367157,1597.771118 230.140411,1599.131226 228.384827,1600.713623 
   C222.395187,1601.959229 216.724060,1601.442871 210.664612,1601.197876 
   C207.784424,1600.448975 206.520020,1598.495850 205.367142,1596.430176 
   C190.188202,1569.232300 175.208038,1541.923584 159.968781,1514.760254 
   C144.402893,1487.014893 129.055206,1459.148926 113.504196,1431.395630 
   C97.872673,1403.498657 82.413391,1375.505371 66.852234,1347.569092 
   C51.372353,1319.778687 35.817471,1292.029541 20.412266,1264.197876 
   C14.041953,1252.688843 7.370022,1241.341919 1.220456,1229.295044 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M1.221050,168.294693 
   C1.000000,155.645767 1.000000,143.291550 1.000000,130.468658 
   C3.583543,129.926514 4.114150,132.141922 5.023867,133.775467 
   C39.755051,196.140961 74.455750,258.523560 109.192162,320.886017 
   C143.041092,381.655151 176.831390,442.457245 210.737961,503.194397 
   C211.794449,505.086884 213.777145,507.049194 211.382874,509.717285 
   C205.392029,510.956055 199.721024,510.444183 193.661438,510.197205 
   C190.387833,509.311035 189.193726,506.889618 187.896210,504.543610 
   C168.790161,469.997681 149.462982,435.574799 130.303665,401.058563 
   C113.164680,370.182068 96.001389,339.318787 78.792091,308.481873 
   C61.583565,277.646301 44.483517,246.750244 27.219944,215.945297 
   C19.158232,201.560043 11.180650,187.127487 3.075150,172.766678 
   C2.324452,171.436630 2.073377,169.946533 1.221050,168.294693 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.221037,1271.294678 
   C1.000000,1258.645752 1.000000,1246.291504 1.000000,1233.468628 
   C3.277675,1232.747437 3.608491,1234.748291 4.368679,1236.073364 
   C17.625610,1259.178589 30.318184,1282.599487 43.325008,1305.844604 
   C57.227501,1330.690308 71.162117,1355.518799 84.940178,1380.432739 
   C98.719322,1405.348511 112.705673,1430.148315 126.477966,1455.067871 
   C141.858337,1482.896973 157.434753,1510.618042 172.933517,1538.381836 
   C183.483459,1557.280640 193.932007,1576.236816 204.426163,1595.166992 
   C205.296722,1596.737427 207.154419,1598.332397 205.258240,1600.729736 
   C198.972504,1601.958496 193.010208,1601.443726 186.658966,1601.186768 
   C183.701279,1600.404053 182.812057,1598.156616 181.675003,1596.133545 
   C159.067078,1555.910034 136.791504,1515.501343 114.265854,1475.231812 
   C90.033653,1431.911377 66.015800,1388.471558 41.788506,1345.148193 
   C28.943596,1322.178711 16.305689,1299.092896 3.323352,1276.199829 
   C2.486426,1274.723999 2.097644,1273.113159 1.221037,1271.294678 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M1.221123,210.295074 
   C1.000000,197.645767 1.000000,185.291550 1.000000,172.468658 
   C3.593582,172.035294 3.941207,174.360229 4.839444,175.960495 
   C24.628799,211.216721 44.183731,246.603745 63.883739,281.909790 
   C104.993492,355.585968 145.958893,429.342529 187.060745,503.023071 
   C188.033279,504.766510 189.254959,506.425537 188.184448,508.925659 
   C182.449738,511.615845 176.696014,510.076416 170.661774,510.319214 
   C167.665787,509.893799 166.300369,508.085754 165.115524,505.974152 
   C150.364151,479.684723 135.893555,453.239075 121.119354,426.962250 
   C105.528481,399.232941 90.215881,371.348541 74.647629,343.605988 
   C60.547852,318.480255 46.691681,293.217346 32.636639,268.065948 
   C23.618652,251.928329 14.666409,235.754013 5.602129,219.642410 
   C3.964435,216.731415 2.403083,213.803604 1.221123,210.295074 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.221108,1313.295166 
   C1.000000,1300.979126 1.000000,1288.958130 1.000000,1276.468628 
   C3.488028,1275.543823 4.052860,1277.616455 4.885472,1279.118530 
   C20.141537,1306.640137 35.415855,1334.152344 50.761726,1361.623047 
   C69.600555,1395.346802 88.337219,1429.127441 107.194122,1462.841187 
   C122.799446,1490.741699 138.283035,1518.711060 153.801224,1546.660522 
   C162.529800,1562.381226 171.300705,1578.080444 180.125916,1593.748291 
   C181.254089,1595.751343 183.396683,1597.788086 181.267075,1600.729980 
   C175.304794,1601.977661 169.653915,1601.357788 163.620163,1601.290527 
   C159.746567,1600.558716 158.669022,1597.466431 157.276459,1594.935303 
   C148.367371,1578.741943 139.354263,1562.606445 130.343246,1546.470337 
   C114.756020,1518.558350 99.179092,1490.640503 83.667038,1462.686890 
   C69.933052,1437.937378 56.042000,1413.275757 42.354145,1388.500610 
   C30.196436,1366.494873 17.917845,1344.556519 5.601418,1322.639282 
   C3.965623,1319.728394 2.403115,1316.802490 1.221108,1313.295166 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M1.221113,253.295166 
   C1.000000,240.645767 1.000000,228.291550 1.000000,215.468658 
   C3.563425,214.504517 3.955639,216.709961 4.779042,218.185532 
   C24.224382,253.032608 43.634846,287.899139 63.058693,322.758240 
   C85.318611,362.707062 107.482071,402.709595 129.811768,442.619629 
   C141.179199,462.936676 152.466248,483.298798 163.702118,503.688812 
   C164.652954,505.414337 166.692276,507.182220 164.381866,509.715454 
   C158.398682,511.004272 152.722488,510.355927 146.664551,510.298584 
   C143.807999,509.865540 142.739227,507.977997 141.653427,506.061890 
   C126.952812,480.119446 112.600006,453.983093 98.072090,427.944427 
   C84.194359,403.071045 70.231277,378.244720 56.454781,353.315613 
   C42.758976,328.532471 28.749617,303.924164 15.169351,279.077576 
   C10.541561,270.610504 5.375794,262.431549 1.221113,253.295166 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.221136,1356.294922 
   C1.000000,1343.645752 1.000000,1331.291504 1.000000,1318.468628 
   C3.471627,1317.815063 3.836088,1320.019897 4.669868,1321.462036 
   C12.159158,1334.415771 19.331894,1347.548828 26.652792,1360.598145 
   C39.423389,1383.361816 51.991405,1406.238403 64.734337,1429.017944 
   C76.749069,1450.495605 88.691437,1472.014160 100.631325,1493.533936 
   C114.587341,1518.687256 128.695496,1543.755737 142.654266,1568.907349 
   C147.656845,1577.921143 152.662842,1586.933105 157.647781,1595.956665 
   C158.454376,1597.416626 159.242310,1598.971313 157.386230,1600.715454 
   C151.394302,1601.955078 145.722717,1601.448853 139.662537,1601.187866 
   C136.074951,1600.047729 134.898727,1597.137451 133.462494,1594.562988 
   C109.212463,1551.094360 84.997467,1507.606201 60.797302,1464.110107 
   C41.616051,1429.634888 22.258947,1395.256958 3.261620,1360.678955 
   C2.531743,1359.350586 2.038356,1357.956665 1.221136,1356.294922 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M1.221159,295.295624 
   C1.000000,282.645782 1.000000,270.291534 1.000000,257.468658 
   C4.224994,257.198181 4.606493,260.220673 5.687140,262.185303 
   C21.008236,290.039642 36.683914,317.695923 52.107983,345.492371 
   C81.186546,397.896240 110.410843,450.219025 139.547836,502.590393 
   C140.593109,504.469208 141.911118,506.274414 141.088379,508.964447 
   C135.038223,511.607941 128.996841,510.103455 122.659134,510.294983 
   C119.722252,509.894745 118.824455,507.809448 117.793777,505.933868 
   C105.861183,484.219360 93.699318,462.632751 81.629936,440.994537 
   C65.916824,412.823761 50.340328,384.577148 34.538063,356.455597 
   C24.112226,337.901886 13.948262,319.201080 3.471844,300.674927 
   C2.559368,299.061340 2.119791,297.284790 1.221159,295.295624 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M259.209717,508.289124 
   C250.927505,493.081390 242.833481,478.185455 234.616531,463.357605 
   C223.651169,443.570129 212.590454,423.835510 201.570404,404.078308 
   C184.229446,372.988739 166.873230,341.907593 149.551895,310.807098 
   C135.953644,286.391388 122.417915,261.940826 108.819000,237.525497 
   C91.415825,206.280060 73.960640,175.063599 56.558983,143.817322 
   C42.879921,119.255318 29.264212,94.658035 15.586167,70.095467 
   C10.973974,61.813042 6.256801,53.589081 1.293803,45.169201 
   C1.000000,43.933331 1.000000,42.866661 1.000000,41.399994 
   C5.298945,43.858124 6.436677,48.873699 8.742956,52.966293 
   C26.138195,83.834953 43.181961,114.901375 60.420708,145.858582 
   C82.513443,185.532593 104.720360,225.143005 126.812714,264.817200 
   C144.050934,295.774261 161.136642,326.816284 178.385071,357.767639 
   C201.786972,399.760986 225.288330,441.698914 248.725693,483.672546 
   C253.348877,491.952148 257.875305,500.285736 262.718323,508.800232 
   C266.428162,511.217743 267.622925,515.055176 269.443176,518.336182 
   C292.231842,559.412781 315.179047,600.400940 338.021240,641.447693 
   C358.190125,677.690552 378.281769,713.976624 398.505157,750.189026 
   C418.972046,786.837524 439.405762,823.504761 459.804718,860.190674 
   C476.964783,891.051575 494.160004,921.893311 511.296387,952.767578 
   C526.654663,980.438171 542.121216,1008.049194 557.518188,1035.698242 
   C572.996704,1063.493774 588.430176,1091.314453 603.930054,1119.098022 
   C619.431274,1146.883911 634.846741,1174.717285 650.394043,1202.477783 
   C665.941284,1230.238159 681.238953,1258.138306 696.814148,1285.883545 
   C712.389038,1313.628296 727.694153,1341.522949 743.280640,1369.261719 
   C757.153137,1393.950073 770.790771,1418.770386 784.582031,1443.504639 
   C798.373108,1468.238525 812.133850,1492.989624 825.858643,1517.760620 
   C841.036072,1545.153320 856.568726,1572.348755 871.676392,1600.200195 
   C887.884277,1629.491821 904.262268,1658.268921 920.116272,1687.337036 
   C920.974426,1688.910522 922.277893,1690.391846 921.229492,1692.709717 
   C920.555542,1693.000000 920.111084,1693.000000 919.333313,1693.000000 
   C915.230652,1690.188843 913.792358,1685.791382 911.640869,1681.927734 
   C897.017761,1655.667480 882.398743,1629.405029 867.692078,1603.191406 
   C867.202087,1602.318115 866.589050,1601.532593 865.728638,1600.470459 
   C858.060242,1584.891357 849.316711,1570.312134 841.075134,1555.463501 
   C825.554688,1527.500732 810.020996,1499.544800 794.424805,1471.624146 
   C778.828735,1443.703613 763.294800,1415.748657 747.736816,1387.806885 
   C732.260071,1360.010986 716.742737,1332.237549 701.309204,1304.417480 
   C685.956238,1276.742676 670.470093,1249.141724 655.125244,1221.462158 
   C639.538330,1193.345947 623.775391,1165.327759 608.199158,1137.205200 
   C592.784607,1109.374634 577.143188,1081.669922 561.749512,1053.827515 
   C546.355591,1025.984619 530.705566,998.284607 515.329956,970.431519 
   C500.035034,942.724609 484.459991,915.173523 469.135834,887.482727 
   C453.650513,859.500732 437.944550,831.641541 422.456512,803.661011 
   C408.743042,778.886353 394.889984,754.189575 381.115021,729.449219 
   C365.638611,701.652954 350.222778,673.822998 334.670258,646.068970 
   C318.955200,618.024902 303.535278,589.816406 287.751526,561.809875 
   C278.756042,545.848267 269.982758,529.761780 261.041382,513.769226 
   C260.158203,512.189575 259.156921,510.590912 259.209717,508.289124 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M235.861237,509.343262 
   C229.483078,497.034454 223.060989,485.028259 216.455795,473.123718 
   C204.272263,451.165283 191.977173,429.268738 179.736069,407.342224 
   C164.008682,379.170990 148.272598,351.004578 132.570709,322.819122 
   C120.591591,301.316101 108.681633,279.774567 96.701668,258.272003 
   C80.998962,230.087601 65.234215,201.937744 49.540459,173.748352 
   C37.243713,151.660706 25.019531,129.532639 12.751822,107.428802 
   C9.039295,100.739609 5.289106,94.071320 1.278084,87.196732 
   C1.000000,85.933327 1.000000,84.866661 1.000000,83.399994 
   C5.439539,87.619705 7.751544,93.587143 10.809496,99.027504 
   C28.167885,129.909531 45.212494,160.967682 62.444141,191.921234 
   C84.608971,231.736374 106.878792,271.493073 129.047836,311.305847 
   C144.745682,339.497162 160.311508,367.762024 176.008026,395.954102 
   C196.880707,433.442871 217.823196,470.892822 238.907379,508.661438 
   C243.755371,511.941528 245.095306,517.247498 247.577438,521.644958 
   C268.536499,558.776855 289.139130,596.108948 309.896332,633.354309 
   C328.788025,667.252380 347.635559,701.176086 366.483215,735.099121 
   C386.867432,771.787781 407.403839,808.392334 427.767731,845.092102 
   C444.899628,875.967224 462.173035,906.763306 479.265991,937.660278 
   C496.357513,968.554443 513.715881,999.301819 530.814880,1030.191772 
   C547.914185,1061.082275 565.166016,1091.887939 582.298340,1122.759766 
   C601.047424,1156.544800 619.924316,1190.260132 638.731995,1224.013184 
   C657.621887,1257.913696 676.381287,1291.887939 695.341553,1325.748413 
   C713.895752,1358.883545 732.240906,1392.134888 750.828369,1425.251831 
   C768.191772,1456.187744 785.272644,1487.283569 802.556946,1518.264893 
   C817.569153,1545.173340 832.550659,1572.098633 847.646118,1599.381348 
   C864.199463,1629.053101 880.433228,1658.481689 896.830505,1687.819580 
   C897.624451,1689.240234 898.803101,1690.565308 898.220215,1692.704102 
   C897.250000,1693.000000 896.500000,1693.000000 895.375000,1693.000000 
   C890.380798,1688.069336 887.987366,1681.772339 884.732056,1676.025146 
   C870.586792,1651.052002 856.990845,1625.770752 842.491943,1600.573608 
   C829.533447,1577.066650 816.607727,1553.961426 803.752075,1530.817383 
   C790.087524,1506.217041 776.273315,1481.699951 762.672302,1457.064453 
   C748.829468,1431.990967 734.703430,1407.075317 720.866821,1381.998657 
   C707.191040,1357.213501 693.290833,1332.553345 679.545593,1307.807007 
   C665.798889,1283.058228 652.054932,1258.308228 638.237610,1233.598877 
   C622.631775,1205.691162 607.208130,1177.681885 591.562317,1149.796387 
   C575.916382,1121.910645 560.528137,1093.881592 544.888123,1065.992188 
   C530.714844,1040.718262 516.778015,1015.311584 502.624512,990.026306 
   C487.006897,962.125305 471.583954,934.116089 455.961761,906.218018 
   C440.339600,878.319885 424.884796,850.328796 409.299957,822.410034 
   C393.877167,794.781616 378.511047,767.121826 363.121796,739.474792 
   C347.489960,711.392029 331.800995,683.341003 316.219788,655.229858 
   C300.880768,627.555664 285.316315,600.006714 270.037933,572.298767 
   C259.262756,552.757629 248.195190,533.379333 237.504852,513.791260 
   C236.791733,512.484619 235.907166,511.237854 235.861237,509.343262 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M212.099442,509.364960 
   C207.182129,497.716888 200.520630,487.348480 194.660309,476.555389 
   C184.033646,456.983856 172.988098,437.639893 162.129227,418.194305 
   C143.166306,384.236359 124.184525,350.288818 105.263329,316.307617 
   C89.818230,288.569305 74.460220,260.782501 59.045109,233.027466 
   C40.643776,199.895706 22.225630,166.773270 3.798322,133.655960 
   C3.153449,132.496994 2.332467,131.436020 1.296783,130.164688 
   C1.000000,128.611115 1.000000,127.222221 1.000000,125.416664 
   C7.842821,134.844147 13.013405,145.659744 18.892612,156.056763 
   C34.936859,184.429962 50.724766,212.948318 66.574783,241.431122 
   C80.403915,266.282349 94.146400,291.181854 107.986572,316.026917 
   C126.843666,349.878174 145.778320,383.686188 164.638870,417.535492 
   C176.781372,439.327820 188.836182,461.168976 200.959732,482.971863 
   C205.728394,491.547791 210.577362,500.079071 215.655609,508.841553 
   C220.014389,512.095154 221.416168,516.955261 223.718292,521.111572 
   C240.823456,551.993774 258.097229,582.782043 275.233185,613.646973 
   C292.287811,644.365234 309.501862,674.995544 326.537109,705.724304 
   C341.957245,733.539673 357.514313,761.278503 372.942139,789.089478 
   C386.754425,813.988098 400.626282,838.853882 414.516907,863.709167 
   C429.951660,891.327454 445.203949,919.047974 460.668396,946.650024 
   C477.924683,977.450256 494.925201,1008.392639 512.209900,1039.177368 
   C526.396179,1064.443726 540.300598,1089.868530 554.462646,1115.148926 
   C570.008606,1142.899292 585.355164,1170.760376 600.884521,1198.519653 
   C616.576660,1226.569946 632.115479,1254.705444 647.789246,1282.765625 
   C663.300476,1310.534912 678.754578,1338.335815 694.229553,1366.125244 
   C709.785217,1394.059692 725.376160,1421.974365 740.877563,1449.939209 
   C754.441406,1474.408447 768.184326,1498.778076 781.716248,1523.265503 
   C795.650391,1548.480835 809.843872,1573.551758 823.953308,1599.091309 
   C834.656311,1618.614502 845.271118,1637.765137 856.030212,1656.835815 
   C861.654663,1666.805054 867.110901,1676.868408 872.730896,1686.840698 
   C873.686096,1688.535767 874.906860,1690.238892 874.222473,1692.704956 
   C873.555542,1693.000000 873.111084,1693.000000 872.333313,1693.000000 
   C866.608276,1688.857178 864.796021,1682.364746 861.642090,1676.816406 
   C847.372681,1651.714355 833.471863,1626.403687 819.157471,1600.903809 
   C804.926453,1575.232422 790.650574,1550.006592 776.642151,1524.632446 
   C762.955566,1499.840942 749.052063,1475.170654 735.313354,1450.408325 
   C721.655579,1425.791870 707.989685,1401.179810 694.235596,1376.616821 
   C681.621521,1354.089722 669.223633,1331.442383 656.591309,1308.925659 
   C642.655029,1284.084839 628.892517,1259.147583 615.032532,1234.264404 
   C601.252563,1209.525024 587.414856,1184.817993 573.690186,1160.047852 
   C558.188354,1132.070435 542.483643,1104.205688 526.995850,1076.220337 
   C513.282471,1051.441284 499.355774,1026.781006 485.642120,1002.002075 
   C470.153778,974.016663 454.468628,946.140930 438.954559,918.169922 
   C423.602203,890.490479 408.126556,862.879700 392.752441,835.212585 
   C377.297638,807.400208 361.826782,779.596741 346.326935,751.809448 
   C332.287872,726.641052 318.349304,701.416931 304.284882,676.262573 
   C280.058502,632.933350 255.982208,589.520386 231.826157,546.151978 
   C225.106995,534.088806 217.913971,522.270203 212.099442,509.364960 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.221147,1398.294800 
   C1.000000,1385.645752 1.000000,1373.291504 1.000000,1360.468628 
   C3.490258,1359.844360 3.759592,1362.060425 4.575585,1363.522339 
   C25.491495,1400.996582 46.414906,1438.466675 67.274696,1475.972046 
   C82.879562,1504.029175 98.608589,1532.017578 114.039017,1560.172119 
   C120.514359,1571.987183 127.291542,1583.636230 133.723557,1595.476562 
   C134.556442,1597.009766 136.112488,1598.433960 134.423569,1600.647461 
   C128.341599,1602.079956 122.515427,1601.112671 116.346924,1601.516357 
   C113.671883,1601.710205 112.768600,1600.049805 111.891098,1598.427734 
   C98.761398,1574.156250 85.052971,1550.206665 71.660515,1526.081299 
   C57.623203,1500.794189 43.607151,1475.495483 29.438387,1450.281250 
   C20.725906,1434.776489 12.242361,1419.142944 3.469790,1403.671143 
   C2.555313,1402.058350 2.117550,1400.282959 1.221147,1398.294800 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M188.232819,508.272522 
   C182.589096,497.701965 177.165833,487.423187 171.525192,477.265167 
   C159.421783,455.468628 147.191406,433.742584 135.040100,411.972595 
   C117.460442,380.477264 99.884331,348.979980 82.345413,317.461975 
   C68.523552,292.623627 54.787235,267.737640 40.962902,242.900681 
   C27.866865,219.372208 14.705714,195.879974 1.286602,172.185913 
   C1.000000,170.933334 1.000000,169.866653 1.000000,168.399994 
   C5.601358,172.508270 7.760613,178.563507 10.811142,183.997650 
   C29.847376,217.908218 48.577694,251.990311 67.504913,285.962341 
   C87.563255,321.964508 107.772270,357.882660 127.832535,393.883759 
   C145.062836,424.806000 162.161102,455.801788 179.352539,486.745728 
   C183.385345,494.004608 187.558365,501.185577 191.846283,508.694305 
   C197.360733,513.623901 199.556610,520.295532 202.862854,526.182739 
   C220.563736,557.701294 238.134506,589.293579 255.687881,620.895020 
   C272.836639,651.768005 289.988922,682.639282 307.190125,713.482727 
   C324.310333,744.180847 341.354309,774.921570 358.510315,805.599915 
   C372.413208,830.461121 386.214417,855.379089 400.030701,880.288635 
   C413.765381,905.050903 427.706665,929.698730 441.403442,954.481873 
   C455.181885,979.412903 469.142578,1004.241882 482.966919,1029.147095 
   C496.790375,1054.050659 510.658722,1078.929565 524.566711,1103.786499 
   C540.020935,1131.406860 555.270996,1159.141479 570.736267,1186.756226 
   C586.121216,1214.227173 601.282471,1241.822754 616.686157,1269.283569 
   C631.030029,1294.855225 645.115601,1320.571045 659.462036,1346.141113 
   C675.113037,1374.036499 690.513733,1402.071045 706.138306,1429.980957 
   C721.681580,1457.745361 737.065918,1485.598145 752.584595,1513.376099 
   C766.478149,1538.245239 780.322693,1563.141602 794.179138,1588.031372 
   C796.367432,1591.962158 798.509705,1595.917114 800.773682,1600.223633 
   C817.180786,1629.398193 833.211304,1658.361816 849.306763,1687.289307 
   C850.192871,1688.881836 851.273743,1690.440918 850.230164,1692.710205 
   C849.555542,1693.000000 849.111084,1693.000000 848.333313,1693.000000 
   C844.169922,1690.426880 843.007019,1686.003906 840.963684,1682.309082 
   C825.924805,1655.115601 810.690552,1628.031738 795.344360,1600.589844 
   C777.380615,1568.235229 759.505798,1536.251221 741.709106,1504.223755 
   C724.641785,1473.508789 707.445557,1442.864990 690.387634,1412.145020 
   C674.945374,1384.334595 659.446411,1356.555664 643.990112,1328.753052 
   C630.233765,1304.008545 616.482544,1279.260498 602.637756,1254.564453 
   C588.629639,1229.576904 574.830566,1204.471680 560.869873,1179.456909 
   C533.515198,1130.442383 506.219818,1081.394653 478.911438,1032.354370 
   C452.088928,984.186523 425.281555,936.010132 398.477539,887.831970 
   C376.370758,848.096802 354.171478,808.412903 332.074738,768.672180 
   C311.596802,731.842896 291.168488,694.985168 270.612823,658.199890 
   C252.169495,625.194580 233.989532,592.042542 215.363327,559.138672 
   C206.748672,543.920532 198.424515,528.537292 189.845963,513.296997 
   C189.032974,511.852631 188.151596,510.400818 188.232819,508.272522 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M165.016617,509.367126 
   C158.145538,496.307617 151.325760,483.570282 144.321793,470.935028 
   C132.229065,449.119659 120.020393,427.368561 107.863213,405.588898 
   C92.058563,377.274872 76.235184,348.971252 60.460133,320.640747 
   C46.788036,296.086945 33.194942,271.489105 19.520700,246.936493 
   C13.620720,236.342834 7.604250,225.814056 1.320349,215.127899 
   C1.000000,213.611115 1.000000,212.222214 1.000000,210.416656 
   C6.196550,216.330841 9.340697,223.888733 13.302946,230.936066 
   C35.948513,271.213898 58.412323,311.594086 80.880814,351.971252 
   C98.096596,382.908966 115.182411,413.919006 132.412659,444.848633 
   C144.297470,466.182800 156.330215,487.434570 168.584412,508.907532 
   C173.090347,513.690063 175.547333,519.405396 178.523315,524.749329 
   C192.623734,550.069275 206.613052,575.452026 220.772308,600.738892 
   C234.606796,625.445801 248.300323,650.230652 262.098846,674.957336 
   C275.977814,699.828125 289.882721,724.684509 303.670105,749.606323 
   C317.297150,774.238281 331.148590,798.745117 344.767365,823.381775 
   C358.548126,848.311340 372.512299,873.138916 386.364899,898.028564 
   C401.919067,925.975525 417.428711,953.947205 433.043396,981.860657 
   C448.576630,1009.628540 463.920685,1037.501953 479.485229,1065.252808 
   C495.130707,1093.148193 510.522369,1121.184937 526.164429,1149.082642 
   C540.339600,1174.364380 554.282715,1199.776123 568.434570,1225.071045 
   C582.342529,1249.930176 596.124268,1274.859009 609.999146,1299.736206 
   C623.793030,1324.468018 637.634399,1349.173462 651.345703,1373.951172 
   C665.217896,1399.019653 679.333984,1423.952393 693.172913,1449.039185 
   C706.931702,1473.980591 720.901062,1498.804688 734.748047,1523.696899 
   C748.675476,1548.733887 762.451416,1573.855713 776.828125,1599.064453 
   C791.434753,1626.118652 806.353760,1652.577759 821.134644,1679.114136 
   C823.511475,1683.381226 826.915894,1687.237671 827.222351,1692.704712 
   C826.250000,1693.000000 825.500000,1693.000000 824.375000,1693.000000 
   C819.883850,1687.924316 817.108643,1682.014893 813.967834,1676.367676 
   C800.046936,1651.337280 786.120483,1626.309692 772.014771,1600.962158 
   C757.532532,1575.443481 743.577087,1550.050903 729.488220,1524.732666 
   C715.642151,1499.850586 701.682922,1475.031494 687.916260,1450.105225 
   C674.149780,1425.179443 660.141724,1400.388428 646.347717,1375.477783 
   C632.553833,1350.567505 618.645203,1325.720947 604.780579,1300.849976 
   C590.916138,1275.979248 577.153198,1251.052002 563.246521,1226.204712 
   C547.876953,1198.743530 532.697632,1171.176270 517.318298,1143.720215 
   C504.787201,1121.348999 492.404236,1098.895264 479.919220,1076.498291 
   C465.974152,1051.482178 452.014282,1026.474365 438.123016,1001.428162 
   C422.696289,973.613403 407.102600,945.891602 391.690521,918.068604 
   C376.197357,890.099182 360.504456,862.240906 345.013092,834.270386 
   C329.602417,806.445496 313.978973,778.739014 298.590851,750.901489 
   C283.122375,722.918579 267.370026,695.093689 251.912094,667.104980 
   C236.454147,639.116333 220.769241,611.254211 205.241318,583.304504 
   C193.110245,561.469055 181.109482,539.560608 168.755295,517.849426 
   C167.276321,515.250305 165.616531,512.730225 165.016617,509.367126 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M141.174423,508.317017 
   C128.172287,484.689209 115.364227,461.371429 102.454308,438.110229 
   C90.271248,416.158630 77.976723,394.268890 65.738945,372.347656 
   C50.015938,344.183441 34.299282,316.015656 18.582743,287.847839 
   C12.912094,277.684662 7.249706,267.516876 1.291773,257.175598 
   C1.000000,255.933334 1.000000,254.866653 1.000000,253.399994 
   C5.523942,256.953247 7.332004,262.658417 10.130778,267.655457 
   C35.481937,312.918335 60.605335,358.308685 85.841019,403.636292 
   C105.333115,438.647461 124.890617,473.622223 144.689697,508.820557 
   C146.529236,509.816040 147.403793,511.187103 148.225327,512.678101 
   C173.219528,558.039490 198.529114,603.226013 223.730652,648.471802 
   C247.147629,690.513733 270.556519,732.561157 294.008057,774.584106 
   C314.538422,811.372559 335.013977,848.193115 355.449066,885.035522 
   C375.803345,921.732300 396.346008,958.325134 416.718536,995.012024 
   C433.859344,1025.879272 451.046631,1056.720947 468.219513,1087.570068 
   C487.012299,1121.328979 505.793701,1155.094849 524.642029,1188.823120 
   C541.865601,1219.644043 558.875610,1250.584473 576.146362,1281.378174 
   C594.639648,1314.351685 612.837341,1347.489014 631.374451,1380.438354 
   C645.500549,1405.547363 659.324463,1430.827148 673.385559,1455.973511 
   C687.284424,1480.829834 701.100037,1505.732056 714.924255,1530.629761 
   C727.535645,1553.343018 740.424561,1575.902222 752.953918,1599.082397 
   C768.688843,1627.388916 784.310852,1655.336792 799.874878,1683.316895 
   C801.450012,1686.148804 803.700317,1688.817139 803.222473,1692.704956 
   C802.555542,1693.000000 802.111084,1693.000000 801.333313,1693.000000 
   C795.496643,1688.104980 793.213257,1681.151245 789.734009,1675.008179 
   C775.834839,1650.467651 762.285889,1625.729370 748.152832,1600.897217 
   C735.597839,1577.726318 722.722961,1555.153564 710.104004,1532.438843 
   C696.190735,1507.394043 682.314941,1482.328613 668.297913,1457.341309 
   C652.732849,1429.594360 637.432617,1401.699829 621.856445,1373.958374 
   C609.298157,1351.591919 596.961975,1329.100586 584.461792,1306.701172 
   C570.581909,1281.829224 556.693970,1256.961670 542.893311,1232.045654 
   C529.093384,1207.130981 515.103943,1182.322021 501.325714,1157.395264 
   C487.627411,1132.613281 473.709198,1107.953491 459.983612,1083.186523 
   C446.176636,1058.272827 432.295319,1033.400513 418.407593,1008.531738 
   C404.519165,983.661743 390.759216,958.720398 376.827576,933.874084 
   C362.651611,908.591980 348.720642,883.172546 334.564636,857.879272 
   C319.025330,830.114197 303.662872,802.250916 288.120209,774.488220 
   C272.577423,746.725342 257.187775,718.877441 241.668381,691.101746 
   C225.986542,663.035278 210.356308,634.940063 194.751862,606.830688 
   C179.228363,578.867065 163.482590,551.026917 148.129944,522.968872 
   C145.595413,518.336853 142.131760,514.115601 141.174423,508.317017 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M1.000000,295.399994 
   C5.863794,300.352173 8.478738,307.085175 11.940368,313.261108 
   C32.671902,350.248474 53.138744,387.384064 73.755264,424.436066 
   C88.953896,451.751068 104.247620,479.013153 119.484085,506.307098 
   C119.884247,507.023956 120.111710,507.837189 120.696091,508.812561 
   C126.093658,512.531921 127.660553,518.472534 130.466888,523.457764 
   C153.099426,563.662476 175.452637,604.024231 197.888947,644.338928 
   C218.380768,681.159729 238.913315,717.958374 259.332794,754.819763 
   C276.361969,785.561035 293.651794,816.158325 310.663147,846.909302 
   C327.755402,877.806519 345.088287,908.569153 362.160187,939.477661 
   C377.540009,967.322815 393.217010,995.004028 408.613953,1022.839539 
   C424.010956,1050.674927 439.571320,1078.418823 455.006226,1106.232910 
   C470.361542,1133.903442 485.806458,1161.524536 501.215820,1189.165161 
   C516.625916,1216.807251 531.980713,1244.480103 547.408325,1272.112427 
   C562.917236,1299.890259 578.275574,1327.751709 593.832764,1355.502686 
   C609.389771,1383.253174 624.711365,1411.134888 640.280090,1438.879272 
   C656.011475,1466.913452 671.493286,1495.086914 687.204529,1523.132812 
   C701.368469,1548.416870 715.343506,1573.806885 729.643555,1599.438477 
   C738.767212,1616.247070 748.055542,1632.541626 757.132446,1648.953613 
   C764.201538,1661.735352 771.239624,1674.534546 778.423218,1687.253052 
   C779.309448,1688.822021 780.269897,1690.468140 779.230225,1692.709961 
   C778.555542,1693.000000 778.111084,1693.000000 777.333313,1693.000000 
   C772.537109,1688.777466 770.540283,1683.001709 767.631287,1677.833618 
   C753.211914,1652.216309 739.136536,1626.406250 724.381226,1600.554443 
   C708.630127,1572.307495 693.178101,1544.315674 677.556763,1516.417236 
   C663.481750,1491.280273 649.592590,1466.038452 635.553284,1440.880981 
   C621.675781,1416.013550 607.869019,1391.107056 594.008789,1366.230225 
   C578.446106,1338.298218 562.862854,1310.377686 547.347229,1282.419434 
   C533.608276,1257.662598 519.705261,1232.996826 505.998016,1208.222290 
   C490.516418,1180.240845 474.807709,1152.386230 459.320801,1124.407837 
   C443.914551,1096.575195 428.294617,1068.861450 412.883240,1041.031738 
   C397.552582,1013.347717 382.004791,985.784790 366.690338,958.092102 
   C351.214630,930.107910 335.507538,902.252869 320.016144,874.277649 
   C304.605621,846.448486 289.049866,818.700378 273.579620,790.904480 
   C259.729309,766.019104 245.915314,741.113525 232.017380,716.254578 
   C217.794907,690.815002 203.749481,665.276550 189.520142,639.840820 
   C175.696991,615.131165 161.987183,590.358765 148.217300,565.619690 
   C137.930786,547.138855 127.613113,528.675110 117.064728,509.918579 
   C107.990753,492.823669 98.583054,476.334137 89.322166,459.763611 
   C75.024330,434.180481 60.911949,408.494293 46.594166,382.922302 
   C32.600540,357.929291 18.701296,332.883728 4.736304,307.874817 
   C3.262131,305.234833 1.874132,302.612640 1.222515,299.291138 
   C1.000000,297.933319 1.000000,296.866669 1.000000,295.399994 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M1.222556,342.290344 
   C1.000000,340.933319 1.000000,339.866669 1.000000,338.399994 
   C3.623846,337.489471 4.482500,339.572815 5.398221,341.229858 
   C17.297138,362.761719 29.293627,384.239258 41.237236,405.746216 
   C55.117428,430.740387 69.106621,455.673767 82.972824,480.675781 
   C88.051010,489.832153 93.017151,499.051849 98.498520,508.404694 
   C121.208336,548.985291 143.661575,589.288086 166.052887,629.624390 
   C188.201202,669.523010 210.399414,709.394531 232.602982,749.261963 
   C254.320480,788.256653 276.003296,827.271667 297.801636,866.221924 
   C316.673157,899.942444 335.339996,933.779053 354.157440,967.530640 
   C371.352936,998.373108 388.555756,1029.211914 405.733307,1060.064087 
   C424.612518,1093.972534 443.487518,1127.883911 462.336609,1161.809448 
   C481.104736,1195.589111 500.025818,1229.284302 518.761780,1263.081421 
   C537.578613,1297.024292 556.541626,1330.885742 575.366699,1364.823975 
   C594.110229,1398.615356 613.000427,1432.326294 631.794922,1466.089722 
   C648.969238,1496.942627 666.187866,1527.771118 683.271240,1558.674927 
   C690.684265,1572.085205 698.192261,1585.445312 705.941284,1599.091431 
   C718.378540,1622.206177 731.091919,1644.746582 743.739441,1667.322998 
   C747.550232,1674.125488 751.223694,1681.004761 755.153564,1687.741333 
   C755.978821,1689.156006 756.883972,1690.615112 756.222412,1692.704956 
   C755.555542,1693.000000 755.111084,1693.000000 754.333313,1693.000000 
   C749.116333,1689.992920 747.807739,1684.487305 745.242737,1679.971313 
   C730.388245,1653.818237 715.986938,1627.409546 701.043579,1600.883179 
   C678.696045,1561.236450 656.864990,1521.721924 634.906067,1482.279785 
   C611.002991,1439.345581 587.155762,1396.379150 563.239319,1353.452393 
   C538.108276,1308.345337 512.979614,1263.236206 487.837616,1218.135254 
   C463.912018,1175.216309 440.065094,1132.252075 416.195404,1089.301514 
   C388.602753,1039.651855 360.929413,990.046692 333.297821,940.418518 
   C305.258759,890.058350 277.226227,839.694519 249.182861,789.336853 
   C219.842896,736.650879 190.537277,683.945679 161.165665,631.277466 
   C138.609833,590.831055 116.116570,550.349854 93.372177,509.587341 
   C79.239029,483.872284 65.114723,458.572998 51.006702,433.265015 
   C35.275146,405.044556 19.486898,376.855774 3.898010,348.556061 
   C2.854803,346.662231 1.763205,344.777252 1.222556,342.290344 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M98.308624,509.342651 
   C92.757660,502.502319 89.435852,494.854401 85.390831,487.653320 
   C64.165512,449.867218 43.139938,411.968903 22.072502,374.094238 
   C16.179260,363.499451 10.399113,352.841827 4.513741,342.242615 
   C3.719752,340.812714 2.612287,339.556854 1.324988,338.110229 
   C1.000000,325.312439 1.000000,312.624878 0.999999,299.468658 
   C8.415552,312.150208 15.869700,325.278839 23.238697,338.455078 
   C39.051289,366.729095 54.837585,395.017822 70.601295,423.319122 
   C84.264282,447.848877 97.885353,472.402039 111.514389,496.950684 
   C113.611122,500.727325 115.650887,504.535583 117.831512,508.655396 
   C111.622772,511.765991 105.179268,510.882050 98.308624,509.342651 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M1.222314,384.290161 
   C1.000000,382.933319 1.000000,381.866669 1.000000,380.399994 
   C3.484042,379.550598 4.035868,381.632874 4.888787,383.120453 
   C19.632267,408.834900 33.746151,434.901184 48.217426,460.768097 
   C56.997707,476.462494 65.906502,492.085907 74.681854,508.202759 
   C93.711311,542.250427 112.558578,575.979980 131.306152,609.765320 
   C148.356201,640.491577 165.612396,671.103821 182.629227,701.848328 
   C198.033936,729.680115 213.647293,757.395203 229.030701,785.238892 
   C244.412476,813.079712 260.050293,840.779175 275.470886,868.598572 
   C289.277130,893.505615 303.183655,918.356628 317.013031,943.250671 
   C330.357788,967.272400 343.661652,991.317139 357.106659,1015.282959 
   C371.448822,1040.848022 385.576477,1066.532349 399.897583,1092.109253 
   C423.983612,1135.125854 447.896027,1178.239502 471.874695,1221.315796 
   C492.370178,1258.134766 512.894775,1294.938110 533.342102,1331.784180 
   C552.091614,1365.570679 570.972107,1399.285156 589.768982,1433.045776 
   C608.647034,1466.952026 627.507874,1500.867920 646.350220,1534.794067 
   C658.399170,1556.488159 670.648560,1578.070923 682.675537,1600.197266 
   C698.882629,1629.490356 715.261841,1658.267822 731.116272,1687.336792 
   C731.974426,1688.910156 733.277771,1690.391724 732.229553,1692.709717 
   C731.555542,1693.000000 731.111084,1693.000000 730.333313,1693.000000 
   C726.230530,1690.188721 724.793823,1685.790283 722.640259,1681.927490 
   C708.003052,1655.673462 693.455017,1629.369629 678.689636,1603.187012 
   C678.197693,1602.314819 677.585327,1601.528931 676.725952,1600.466187 
   C669.062073,1584.885742 660.331360,1570.300171 652.071777,1555.463135 
   C636.598083,1527.666504 621.187134,1499.834961 605.665222,1472.065186 
   C590.062134,1444.150269 574.531677,1416.194946 558.987061,1388.247437 
   C543.442627,1360.300415 527.845459,1332.382568 512.324890,1304.422241 
   C498.582794,1279.665771 484.684601,1254.996094 470.978455,1230.219482 
   C455.579163,1202.381836 439.925964,1174.685669 424.541534,1146.839722 
   C409.157104,1118.993774 393.510925,1091.293823 378.114624,1063.454468 
   C362.718414,1035.615234 347.086792,1007.906982 331.671112,980.078735 
   C316.093994,951.958923 300.324982,923.946045 284.754517,895.822998 
   C269.264771,867.845886 253.646667,839.940430 238.095978,811.997437 
   C224.489105,787.547302 210.965576,763.050842 197.262100,738.654175 
   C182.987793,713.241333 168.976089,687.681885 154.742508,662.246216 
   C140.834366,637.392151 127.027489,612.481812 113.197777,587.584045 
   C98.640305,561.376221 83.796356,535.327393 69.363403,508.630981 
   C49.705147,473.282410 30.016594,438.370117 10.754960,403.221161 
   C7.419272,397.134125 3.036631,391.546051 1.222314,384.290161 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M1.222157,427.288635 
   C1.000000,425.611115 1.000000,424.222198 1.000000,422.416656 
   C3.734735,422.027435 4.380910,424.384796 5.359580,426.151215 
   C20.442348,453.374451 35.701839,480.499176 50.983139,508.032166 
   C65.376915,533.600647 79.468925,558.916870 93.507469,584.262817 
   C107.304375,609.172363 121.326195,633.956604 135.080276,658.889771 
   C148.914505,683.968384 162.988708,708.913086 176.875473,733.962463 
   C190.196976,757.992126 203.534042,782.013489 216.956879,805.986572 
   C231.437180,831.848389 245.711212,857.824768 260.219696,883.670715 
   C282.634216,923.600891 304.790955,963.675110 327.061981,1003.685059 
   C347.550476,1040.492676 368.002533,1077.321777 388.500916,1114.123779 
   C413.619324,1159.220215 438.730438,1204.321411 463.897797,1249.390747 
   C484.438629,1286.174927 504.874115,1323.019287 525.338745,1359.846680 
   C545.802979,1396.673340 566.430054,1433.409668 586.867737,1470.250977 
   C607.305176,1507.091919 627.997925,1543.791016 648.277710,1580.719604 
   C651.644043,1586.849487 655.136841,1592.910522 658.646667,1599.382080 
   C675.199646,1629.053711 691.433350,1658.482056 707.830566,1687.819580 
   C708.624512,1689.240112 709.803162,1690.565308 709.220215,1692.704102 
   C708.250000,1693.000000 707.500000,1693.000000 706.375000,1693.000000 
   C701.380676,1688.069336 698.987427,1681.772095 695.732056,1676.024902 
   C681.586609,1651.051636 667.990784,1625.770020 653.491455,1600.572876 
   C638.822937,1574.007935 624.221008,1547.825928 609.633789,1521.635864 
   C595.776184,1496.755737 581.935669,1471.865967 568.092041,1446.978149 
   C554.328857,1422.234619 540.429749,1397.566284 526.753418,1372.774902 
   C514.604980,1350.753296 502.244202,1328.850708 490.048126,1306.855835 
   C476.478577,1282.384277 462.885651,1257.924927 449.194183,1233.520630 
   C433.627411,1205.774048 418.305176,1177.891113 402.738617,1150.143677 
   C387.009460,1122.106567 371.524811,1093.932861 355.812073,1065.885742 
   C341.646423,1040.600342 327.721588,1015.179932 313.561188,989.891357 
   C297.935699,961.986328 282.499969,933.975830 266.873138,906.071777 
   C253.037048,881.365234 239.318771,856.593323 225.550751,831.848938 
   C211.701859,806.959229 197.742981,782.130676 183.966690,757.200623 
   C170.270844,732.416077 156.342285,707.761353 142.616562,682.993469 
   C128.810455,658.080566 114.958763,633.193115 101.060387,608.331482 
   C85.373978,580.271362 69.891068,552.097900 54.137733,524.075073 
   C51.525444,519.428223 49.014641,514.725586 46.199375,509.771332 
   C34.797909,488.860840 23.301565,468.423889 11.886447,447.941498 
   C8.176775,441.285095 3.730503,434.974792 1.222157,427.288635 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.220739,1441.296509 
   C1.000000,1428.645752 1.000000,1416.291504 1.000000,1403.468628 
   C3.583041,1402.879272 4.239551,1405.125732 5.110021,1406.714722 
   C17.007896,1428.431885 29.140944,1450.018188 41.216080,1471.636475 
   C53.777897,1494.126099 66.207741,1516.688965 78.802895,1539.160156 
   C88.879547,1557.138184 98.891396,1575.152954 108.889099,1593.175049 
   C110.109505,1595.375000 112.018333,1597.752319 109.457870,1600.696655 
   C103.777893,1601.997681 98.400513,1601.354492 92.642403,1601.295654 
   C89.791306,1600.852783 88.724014,1598.961914 87.645782,1597.046631 
   C76.131767,1576.594971 64.839340,1556.019775 53.377052,1535.539551 
   C39.395828,1510.558594 25.476395,1485.543335 11.583610,1460.513306 
   C8.111943,1454.258667 4.387487,1448.132202 1.220739,1441.296509 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M1.221604,469.286804 
   C1.000000,467.933319 1.000000,466.866669 1.000000,465.399994 
   C3.883212,464.723999 4.448805,467.226135 5.434083,468.950348 
   C12.863575,481.951935 19.951603,495.146271 27.656536,508.415466 
   C45.663155,540.745789 63.441826,572.782349 81.295685,604.776978 
   C98.418915,635.462280 115.512215,666.164429 132.622910,696.856628 
   C148.111542,724.639160 163.509094,752.473145 179.011795,780.248108 
   C194.514160,808.022522 209.917557,835.851868 225.462143,863.602966 
   C239.380020,888.449951 253.076370,913.421509 266.983643,938.274963 
   C291.057983,981.297729 314.996735,1024.396362 338.963196,1067.478882 
   C361.148682,1107.359863 383.336029,1147.240479 405.537689,1187.112427 
   C427.333923,1226.256226 449.160675,1265.384033 470.977203,1304.516724 
   C493.198853,1344.376221 515.366150,1384.267212 537.527954,1424.160645 
   C559.608398,1463.907471 581.814148,1503.584961 603.915344,1543.320312 
   C614.197021,1561.805542 624.632324,1580.205322 634.953003,1599.090698 
   C645.656128,1618.614136 656.270874,1637.764893 667.030151,1656.835571 
   C672.654663,1666.804932 678.110962,1676.868286 683.730896,1686.840698 
   C684.686157,1688.535645 685.906982,1690.238770 685.222412,1692.704956 
   C684.555542,1693.000000 684.111084,1693.000000 683.333313,1693.000000 
   C677.608154,1688.856934 675.795898,1682.364136 672.641541,1676.815552 
   C658.371094,1651.712646 644.470886,1626.400269 630.154663,1600.899902 
   C614.201477,1572.183228 598.230103,1543.897095 582.538086,1515.456177 
   C567.087097,1487.452026 551.334839,1459.616333 535.889954,1431.608887 
   C520.606873,1403.894653 504.985840,1376.367432 489.673584,1348.669312 
   C475.892609,1323.740601 461.927307,1298.915161 448.118134,1274.002441 
   C434.471252,1249.382446 420.747406,1224.804932 407.022705,1200.228027 
   C394.597260,1177.977783 382.309204,1155.650391 369.858521,1133.414185 
   C355.860992,1108.415771 342.011993,1083.335205 328.048676,1058.317993 
   C312.541595,1030.534790 297.046997,1002.744751 281.599976,974.928223 
   C266.152527,947.110840 250.564133,919.372070 235.163422,891.528625 
   C221.214569,866.309998 207.062332,841.204590 193.100464,815.992859 
   C177.686279,788.158752 162.053802,760.445679 146.633423,732.614990 
   C131.212616,704.783569 115.664505,677.023254 100.212181,649.209473 
   C84.759872,621.395813 69.284988,593.594666 53.748173,565.827942 
   C43.336407,547.220581 33.142944,528.491394 22.428471,509.633087 
   C16.495398,498.186707 10.127924,487.397919 4.079311,476.433594 
   C2.867251,474.236481 1.826847,472.051819 1.221604,469.286804 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M607.078003,1600.284668 
   C598.584778,1584.329590 590.109985,1568.705078 581.492920,1553.159546 
   C572.708557,1537.312500 563.768066,1521.552246 554.925232,1505.737549 
   C540.727112,1480.345337 526.528503,1454.953491 512.377197,1429.535156 
   C501.863770,1410.651123 491.457092,1391.707642 480.945190,1372.822754 
   C466.796265,1347.403809 452.574158,1322.025879 438.416992,1296.611450 
   C427.979706,1277.874756 417.625061,1259.091797 407.181274,1240.358765 
   C393.258728,1215.385620 379.252075,1190.459473 365.344757,1165.478027 
   C354.589142,1146.157959 343.973358,1126.759888 333.218201,1107.439575 
   C319.311615,1082.457764 305.300476,1057.534058 291.386078,1032.556641 
   C279.249451,1010.770325 267.217285,988.925842 255.079453,967.140259 
   C241.244537,942.308716 227.312149,917.531555 213.481598,892.697632 
   C202.803482,873.524048 192.254700,854.278442 181.580475,835.102661 
   C167.593399,809.975586 153.523026,784.894836 139.519867,759.776672 
   C129.238480,741.334473 119.029770,722.851624 108.748772,704.409180 
   C94.664566,679.144409 80.513596,653.916870 66.446220,628.642761 
   C55.772980,609.466614 45.236313,590.214355 34.545765,571.047913 
   C23.614986,551.450806 12.570695,531.917053 1.288040,512.177734 
   C1.000000,510.933319 1.000000,509.866669 1.000000,508.399994 
   C1.670103,507.402740 2.383695,507.485046 3.409350,508.106567 
   C14.043615,525.645142 23.438515,543.475037 33.338142,561.020569 
   C49.131294,589.011475 64.560287,617.206787 80.264122,645.248901 
   C94.421478,670.529419 108.380295,695.921631 122.506271,721.220154 
   C136.307205,745.936523 150.025787,770.698547 163.801422,795.428894 
   C177.820175,820.595764 191.891479,845.733215 205.856674,870.930054 
   C219.659286,895.833557 233.654785,920.630249 247.437378,945.544617 
   C261.140137,970.314758 274.963379,995.017639 288.745941,1019.742920 
   C302.609619,1044.613647 316.341675,1069.557373 330.317383,1094.366455 
   C342.821838,1116.563965 355.033264,1138.926758 367.454163,1161.171997 
   C382.960480,1188.943481 398.410370,1216.746216 413.878143,1244.539185 
   C429.426971,1272.477661 445.019409,1300.391968 460.554291,1328.338135 
   C476.008301,1356.138916 491.559875,1383.885376 506.981995,1411.703979 
   C522.484802,1439.667847 538.180542,1467.524414 553.640076,1495.512451 
   C567.328125,1520.293335 581.305908,1544.912842 594.972778,1569.705566 
   C600.519470,1579.767700 606.274353,1589.715576 611.773621,1600.223022 
   C628.180359,1629.397949 644.211304,1658.361572 660.306885,1687.289062 
   C661.192932,1688.881592 662.273682,1690.440918 661.230225,1692.710205 
   C660.555542,1693.000000 660.111084,1693.000000 659.333313,1693.000000 
   C655.697754,1691.091431 654.888123,1687.432129 653.221130,1684.453735 
   C638.117493,1657.468262 623.102417,1630.432983 607.963928,1603.466675 
   C607.473999,1602.593994 607.076233,1601.677124 607.078003,1600.284668 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M583.796997,1600.379150 
   C574.918884,1583.928467 565.912720,1567.828735 556.915588,1551.723999 
   C542.727844,1526.328125 528.520996,1500.942871 514.370361,1475.526367 
   C503.857056,1456.642944 493.452850,1437.698608 482.940948,1418.814453 
   C468.792603,1393.397217 454.564545,1368.024414 440.414062,1342.608276 
   C429.900757,1323.725098 419.496552,1304.781006 408.971710,1285.904297 
   C395.129578,1261.077637 381.179474,1236.311157 367.346130,1211.479614 
   C356.584778,1192.162354 345.974274,1172.761108 335.218781,1153.440552 
   C321.312927,1128.461060 307.307037,1103.537231 293.387054,1078.565552 
   C282.944916,1059.832886 272.601288,1041.045288 262.158295,1022.313049 
   C248.236877,997.341309 234.227402,972.418640 220.320374,947.438904 
   C209.564529,928.119385 198.951447,908.720337 188.196533,889.400269 
   C174.290970,864.420471 160.278885,839.499939 146.365952,814.524170 
   C134.230286,792.739014 122.202431,770.893738 110.065086,749.109497 
   C96.230743,724.279419 82.306686,699.499329 68.461746,674.675171 
   C58.177486,656.235474 47.966362,637.755005 37.706238,619.301819 
   C25.670176,597.654663 13.616281,576.017334 1.285060,554.187866 
   C1.000000,552.933289 1.000000,551.866638 1.000000,550.399963 
   C5.369760,552.811340 6.439698,557.881592 8.756693,561.968079 
   C22.860844,586.843811 36.537685,611.961243 50.456493,636.942749 
   C67.528702,667.583984 84.741615,698.146851 101.812332,728.788940 
   C115.729935,753.771118 129.474792,778.849548 143.392548,803.831665 
   C160.463486,834.473572 177.677231,865.035889 194.748352,895.677734 
   C207.210266,918.046387 219.494446,940.513916 231.943329,962.889832 
   C245.763336,987.730225 259.699249,1012.506104 273.548920,1037.330078 
   C283.755341,1055.624023 293.883972,1073.961182 304.083862,1092.258789 
   C318.249237,1117.670044 332.474030,1143.048340 346.625671,1168.467285 
   C357.139801,1187.352661 367.545380,1206.298462 378.058350,1225.184448 
   C392.208191,1250.603760 406.437836,1275.978882 420.590210,1301.396851 
   C432.722382,1323.186768 444.759735,1345.029419 456.901459,1366.813843 
   C470.822113,1391.790039 484.838043,1416.713013 498.762421,1441.687134 
   C509.126404,1460.275391 519.376343,1478.927368 529.738342,1497.516724 
   C547.059326,1528.590576 564.429138,1559.636963 581.778809,1590.694824 
   C583.399414,1593.595947 585.022217,1596.496094 586.812378,1599.693359 
   C590.735840,1602.406372 591.814575,1606.693848 593.790955,1610.239380 
   C608.280884,1636.232788 622.668091,1662.283447 637.275085,1688.212036 
   C638.006836,1689.510986 638.863586,1690.784180 638.222412,1692.704102 
   C637.250000,1693.000000 636.500000,1693.000000 635.375000,1693.000000 
   C630.699890,1687.655151 627.807068,1681.442505 624.500427,1675.515503 
   C611.456421,1652.134644 598.481384,1628.714844 585.439636,1605.332275 
   C584.629883,1603.880371 583.716858,1602.471924 583.796997,1600.379150 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M560.002930,1600.350586 
   C547.180176,1576.894775 534.311646,1553.815918 521.483887,1530.714355 
   C510.832794,1511.532593 500.267181,1492.303223 489.596161,1473.132568 
   C475.613251,1448.012085 461.546326,1422.938354 447.548920,1397.825928 
   C437.190918,1379.243042 426.910645,1360.616577 416.551788,1342.034180 
   C402.552795,1316.921875 388.488953,1291.845581 374.496521,1266.729492 
   C363.980377,1247.853271 353.560059,1228.923828 343.048187,1210.045166 
   C328.819336,1184.491333 314.518341,1158.977661 300.295685,1133.420410 
   C288.172180,1111.635376 276.153442,1089.792114 264.019592,1068.012939 
   C250.189209,1043.188477 236.258286,1018.419922 222.416962,993.601501 
   C212.054398,975.020691 201.795212,956.382141 191.435608,937.799622 
   C174.037354,906.591614 156.555099,875.430359 139.186050,844.206177 
   C126.662712,821.692993 114.325424,799.076355 101.797165,776.565918 
   C86.361160,748.830994 70.780472,721.176636 55.323845,693.453186 
   C43.183342,671.677551 31.164574,649.834106 19.035120,628.052368 
   C13.301694,617.756348 7.435849,607.534058 1.314997,597.139160 
   C1.000000,595.611084 1.000000,594.222229 1.000000,592.416626 
   C5.652396,596.485352 7.751922,602.586670 10.821061,608.019287 
   C24.887274,632.917419 38.568512,658.032654 52.487442,683.014465 
   C69.559669,713.655884 86.773888,744.218201 103.845581,774.859924 
   C117.764099,799.842102 131.509842,824.920471 145.429001,849.902222 
   C162.501694,880.544006 179.723206,911.102844 196.789139,941.748291 
   C209.327621,964.263794 221.658951,986.894531 234.191956,1009.413086 
   C249.633484,1037.157593 265.221893,1064.820190 280.683746,1092.553467 
   C292.827942,1114.336182 304.838928,1136.193237 316.977051,1157.979248 
   C330.812836,1182.812256 344.753296,1207.587036 358.592987,1232.417725 
   C369.116547,1251.298950 379.513794,1270.250488 390.038757,1289.130859 
   C407.199158,1319.914429 424.446198,1350.649780 441.601746,1381.436157 
   C452.203644,1400.461548 462.665710,1419.564819 473.259216,1438.594971 
   C487.328827,1463.869629 501.485626,1489.095703 515.566711,1514.364014 
   C526.007629,1533.100098 536.388184,1551.869995 546.794495,1570.625244 
   C552.118286,1580.220581 557.439819,1589.817383 562.927063,1599.725830 
   C568.166382,1603.795166 569.843079,1609.826538 572.726685,1614.958130 
   C585.425415,1637.556152 598.083252,1660.178833 610.620544,1682.867676 
   C612.272461,1685.857300 614.643677,1688.643066 614.222656,1692.704346 
   C613.555542,1693.000000 613.111084,1693.000000 612.333313,1693.000000 
   C606.652649,1688.394287 604.473450,1681.728882 601.181763,1675.856689 
   C588.584961,1653.385376 575.992981,1630.910278 563.483032,1608.389893 
   C562.114807,1605.926758 560.552002,1603.545532 560.002930,1600.350586 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M536.089478,1600.278320 
   C530.697937,1589.825195 525.364624,1579.682739 519.811646,1569.662109 
   C510.705566,1553.229492 501.439758,1536.885498 492.271851,1520.486938 
   C474.910370,1489.432617 457.530121,1458.388916 440.235382,1427.297363 
   C427.707031,1404.774536 415.363892,1382.148560 402.830322,1359.628662 
   C387.388031,1331.882568 371.801208,1304.216919 356.338379,1276.482178 
   C344.192993,1254.697876 332.177277,1232.841431 320.038116,1211.053711 
   C306.201416,1186.218994 292.263275,1161.440918 278.422913,1136.608276 
   C267.898895,1117.725952 257.497955,1098.775024 246.972565,1079.893433 
   C229.811554,1049.108521 212.565765,1018.370911 195.409912,987.583130 
   C184.807785,968.556519 174.343826,949.453064 163.749786,930.421936 
   C149.679672,905.146423 135.527573,879.916565 121.440552,854.650452 
   C111.238373,836.352051 101.105545,818.014954 90.904480,799.715942 
   C76.737846,774.303345 62.510227,748.924805 48.358196,723.504150 
   C37.844456,704.618774 27.424557,685.681152 16.932772,666.783508 
   C11.848029,657.625061 6.694220,648.504944 1.285840,639.183716 
   C1.000000,637.933289 1.000000,636.866638 1.000000,635.399963 
   C5.113181,637.049561 5.817904,641.572205 7.763172,645.004456 
   C21.860991,669.879395 35.544922,694.988403 49.460854,719.967102 
   C68.228363,753.654175 87.134140,787.264221 105.890007,820.957764 
   C120.121010,846.522766 134.139832,872.205994 148.380783,897.765442 
   C166.825897,930.870483 185.451080,963.875183 203.898178,996.979126 
   C218.059509,1022.392151 231.996811,1047.930176 246.157379,1073.343628 
   C264.603882,1106.448975 283.226135,1139.456421 301.671631,1172.562378 
   C315.912415,1198.121582 329.928223,1223.806030 344.169220,1249.365234 
   C362.615051,1282.470947 381.225128,1315.485107 399.686768,1348.582031 
   C410.538361,1368.036011 421.185699,1387.603760 432.021088,1407.066772 
   C445.846313,1431.900024 459.806305,1456.658325 473.637939,1481.488159 
   C485.853912,1503.417236 497.947479,1525.414429 510.143921,1547.354370 
   C519.835754,1564.788940 529.601440,1582.182373 539.588013,1599.813599 
   C544.147278,1604.369141 546.449463,1610.002930 549.348206,1615.195190 
   C562.579529,1638.895752 575.691284,1662.663208 588.935913,1686.356934 
   C589.971069,1688.208740 591.345825,1690.049438 590.230469,1692.708740 
   C589.555542,1693.000000 589.111084,1693.000000 588.333313,1693.000000 
   C584.276917,1690.106689 582.968323,1685.616577 580.808044,1681.752075 
   C566.056030,1655.363159 551.354492,1628.945557 536.651062,1602.529297 
   C536.323792,1601.941528 536.130920,1601.310303 536.089478,1600.278320 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M74.421303,509.247284 
   C67.035095,496.765228 59.999016,484.569580 53.140831,472.274750 
   C36.254784,442.002747 19.451584,411.684509 2.602045,381.392090 
   C2.366005,380.967743 1.960559,380.637573 1.317087,380.131714 
   C1.000000,367.645782 1.000000,355.291534 1.000000,342.468658 
   C6.595529,349.288940 10.302560,357.692841 14.769268,365.633606 
   C34.260651,400.284790 53.552994,435.048004 72.868111,469.798065 
   C79.977348,482.588379 86.950104,495.454559 94.041153,508.642212 
   C87.747459,511.721344 81.309799,510.992981 74.421303,509.247284 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M512.821716,1600.364014 
   C499.100128,1575.206909 485.224518,1550.406982 471.398010,1525.579956 
   C459.347137,1503.941162 447.378815,1482.256592 435.324402,1460.619873 
   C421.411560,1435.647339 407.415497,1410.721191 393.510010,1385.744629 
   C382.836700,1366.573486 372.284149,1347.335083 361.612427,1328.163086 
   C347.628204,1303.040039 333.562744,1277.961914 319.564117,1252.846802 
   C309.204926,1234.261230 298.920288,1215.634277 288.559998,1197.049316 
   C274.559540,1171.934448 260.490021,1146.858276 246.504150,1121.735352 
   C235.831726,1102.564453 225.279602,1083.326660 214.608154,1064.155151 
   C200.623917,1039.032227 186.558014,1013.954651 172.559418,988.839600 
   C162.200562,970.254700 151.916809,951.627991 141.556900,933.043701 
   C127.556847,907.929321 113.493233,882.850464 99.499863,857.732422 
   C88.983459,838.855530 78.561394,819.926086 68.049339,801.046753 
   C53.818977,775.489441 39.524242,749.968079 25.293770,724.410889 
   C18.096228,711.484375 10.986577,698.508850 3.813413,685.568665 
   C3.176986,684.420532 2.364183,683.370117 1.316648,682.137146 
   C1.000000,680.611084 1.000000,679.222229 1.000000,677.416626 
   C5.252691,681.130859 7.357619,686.653137 10.159786,691.660522 
   C30.855801,728.643494 51.325207,765.753235 71.908073,802.799683 
   C89.826355,835.050110 107.802811,867.268188 125.748169,899.503662 
   C147.088547,937.837524 168.423294,976.174622 189.760117,1014.510498 
   C211.097717,1052.847778 232.427200,1091.189697 253.774536,1129.521484 
   C274.797668,1167.271118 295.842407,1205.008789 316.870911,1242.755493 
   C334.826172,1274.985596 352.777588,1307.217896 370.720215,1339.454956 
   C392.138397,1377.936279 413.539307,1416.427124 434.959106,1454.907593 
   C451.124451,1483.948364 467.285095,1512.991821 483.491730,1542.009644 
   C494.187714,1561.160522 504.961304,1580.267944 515.858826,1599.702026 
   C519.601440,1602.535278 520.933044,1606.638306 522.914246,1610.212769 
   C537.015442,1635.654175 551.187744,1661.055786 565.404297,1686.432861 
   C566.443481,1688.287842 567.893311,1690.032715 567.222534,1692.704346 
   C566.555542,1693.000000 566.111084,1693.000000 565.333313,1693.000000 
   C560.487854,1690.592163 559.429199,1685.674561 557.214233,1681.744751 
   C542.637146,1655.880859 528.237854,1629.916138 513.838623,1603.952026 
   C513.271973,1602.930420 512.702698,1601.921509 512.821716,1600.364014 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M392.758118,704.248169 
   C396.026306,705.779114 397.817017,708.623230 399.682007,711.829773 
   C404.456451,716.454468 406.642670,722.352478 409.613007,727.699829 
   C427.319336,759.576172 445.269165,791.317261 462.861145,823.256104 
   C479.890808,854.174072 497.223450,884.922546 514.320984,915.802490 
   C533.031616,949.595947 551.974426,983.261841 570.733032,1017.029114 
   C589.574829,1050.946045 608.421814,1084.860840 627.334595,1118.737671 
   C647.464844,1154.795288 667.496338,1190.907959 687.660767,1226.946655 
   C706.605286,1260.805298 725.356445,1294.772949 744.250183,1328.660522 
   C761.440552,1359.492920 778.658691,1390.310303 795.799927,1421.169678 
   C814.639526,1455.086182 833.582886,1488.945190 852.383484,1522.883667 
   C866.423096,1548.227783 880.489746,1573.557983 894.943115,1599.094116 
   C907.379822,1622.208374 920.092773,1644.748047 932.740051,1667.323730 
   C936.550781,1674.125977 940.224060,1681.005005 944.153809,1687.741455 
   C944.979065,1689.156128 945.883850,1690.615234 945.222412,1692.704956 
   C944.555542,1693.000000 944.111084,1693.000000 943.333313,1693.000000 
   C938.116272,1689.992798 936.807678,1684.486938 934.242615,1679.970947 
   C919.387512,1653.817505 904.986206,1627.408081 890.042358,1600.881104 
   C866.089172,1558.328003 842.664001,1515.901245 819.064331,1473.572266 
   C798.546814,1436.771484 778.048523,1399.958984 757.618042,1363.109009 
   C738.883423,1329.318237 719.921448,1295.653076 701.195190,1261.857544 
   C682.388184,1227.916504 663.462341,1194.041382 644.607910,1160.126831 
   C625.834656,1126.358398 607.016541,1092.614014 588.197144,1058.870850 
   C571.000977,1028.038086 553.931152,997.134155 536.710205,966.315674 
   C521.194946,938.549622 505.787506,910.723633 490.260284,882.964294 
   C472.944427,852.007141 455.824921,820.939758 438.540314,789.965393 
   C423.689758,763.352966 408.846069,736.737183 394.115845,710.057983 
   C393.174316,708.352783 391.697479,706.759399 392.758118,704.248169 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M488.282928,1600.176880 
   C487.040222,1593.577515 482.997803,1588.708984 480.063965,1583.371460 
   C462.291260,1551.036743 444.251068,1518.849121 426.307404,1486.608398 
   C404.887085,1448.120605 383.479095,1409.625977 362.055969,1371.139771 
   C345.807312,1341.949829 329.546417,1312.766602 313.285400,1283.583496 
   C292.251251,1245.834351 271.206573,1208.091064 250.175537,1170.340210 
   C232.218094,1138.106445 214.292252,1105.854980 196.318802,1073.630249 
   C174.217529,1034.004517 152.044952,994.418457 129.971756,954.777039 
   C112.666550,923.698547 95.521797,892.530640 78.204269,861.458923 
   C56.520157,822.552734 34.731022,783.705017 12.990159,744.830383 
   C9.177313,738.012695 5.378854,731.186890 1.286868,724.182434 
   C1.000000,722.611084 1.000000,721.222229 1.000000,719.416626 
   C6.368887,725.620667 9.641315,733.488464 13.795161,740.816345 
   C28.223152,766.268982 42.387180,791.871338 56.616760,817.436218 
   C68.664925,839.081848 80.636627,860.770081 92.693459,882.410950 
   C106.609665,907.389160 120.602371,932.324829 134.523468,957.300354 
   C144.966522,976.035950 155.319595,994.821716 165.764847,1013.556152 
   C183.089157,1044.628784 200.496490,1075.655029 217.790176,1106.744629 
   C230.317703,1129.265869 242.657425,1151.891724 255.190216,1174.410156 
   C270.631775,1202.154663 286.220245,1229.817383 301.682098,1257.550781 
   C313.826355,1279.333496 325.837341,1301.190674 337.975494,1322.976807 
   C351.810944,1347.809326 365.746124,1372.586060 379.591705,1397.412842 
   C389.957611,1415.999878 400.220245,1434.644531 410.582642,1453.233521 
   C427.985565,1484.452271 445.474670,1515.623047 462.842316,1546.861450 
   C472.780396,1564.736694 482.549988,1582.705566 492.658417,1600.853271 
   C496.969910,1603.425415 497.946869,1607.952637 499.981903,1611.593384 
   C513.997864,1636.669800 528.158020,1661.667603 541.870544,1686.912964 
   C542.794006,1688.613037 544.322510,1690.182861 543.229797,1692.708618 
   C542.555542,1693.000000 542.111084,1693.000000 541.333313,1693.000000 
   C537.894653,1691.197388 536.882324,1687.822754 535.319336,1684.986816 
   C520.012024,1657.212158 504.402893,1629.606323 488.988495,1601.891846 
   C488.745972,1601.455933 488.542236,1601.001343 488.282928,1600.176880 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M437.780029,743.268738 
   C439.972290,742.632080 440.902435,743.925781 441.912354,745.662109 
   C447.085236,749.793640 448.857208,755.781860 451.729614,760.943054 
   C471.088013,795.726440 490.619781,830.413696 509.877258,865.252747 
   C528.651245,899.216980 547.615051,933.075439 566.466309,966.996338 
   C585.236267,1000.770996 604.060120,1034.516602 622.880554,1068.263550 
   C640.077393,1099.099487 657.086609,1130.041504 674.362915,1160.831665 
   C692.863892,1193.804565 711.094849,1226.927368 729.610291,1259.892334 
   C746.902100,1290.678711 763.905884,1321.628174 781.125671,1352.456299 
   C798.264221,1383.138672 815.281555,1413.888550 832.442627,1444.558716 
   C848.058289,1472.466797 863.493713,1500.476196 879.096313,1528.392090 
   C892.260864,1551.945557 905.282227,1575.578613 918.644531,1599.444580 
   C927.773254,1616.249268 937.056274,1632.545532 946.133728,1648.956177 
   C953.203369,1661.736694 960.240295,1674.535767 967.423523,1687.253540 
   C968.309631,1688.822266 969.270081,1690.468506 968.230225,1692.710205 
   C967.555542,1693.000000 967.111084,1693.000000 966.333313,1693.000000 
   C961.536621,1688.776978 959.539795,1683.000488 956.630310,1677.831787 
   C942.208801,1652.211670 928.132751,1626.397827 913.373535,1600.544189 
   C897.612549,1572.306641 882.169067,1544.314209 866.547546,1516.419922 
   C852.309998,1490.996826 838.324829,1465.430786 824.074829,1440.014526 
   C810.150330,1415.178833 796.396240,1390.249023 782.526001,1365.383545 
   C768.656128,1340.518555 754.751831,1315.672607 740.980591,1290.752197 
   C727.290710,1265.979126 713.346802,1241.346313 699.640198,1216.582397 
   C685.932007,1191.815552 672.030884,1167.156616 658.321533,1142.390625 
   C644.934448,1118.207153 631.526917,1094.034790 618.002441,1069.927979 
   C603.499451,1044.076904 589.247742,1018.086243 574.762451,992.225464 
   C549.046448,946.314026 523.519714,900.297058 497.911194,854.325684 
   C478.299225,819.119202 458.697052,783.907166 439.085144,748.700562 
   C438.204102,747.119019 437.121155,745.558899 437.780029,743.268738 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.221416,1483.296387 
   C1.000000,1470.645752 1.000000,1458.291504 1.000000,1445.468628 
   C3.036798,1444.687012 3.447870,1446.429321 4.132360,1447.633057 
   C14.083784,1465.134033 23.723486,1482.809326 33.549694,1500.380371 
   C47.601593,1525.507690 61.568333,1550.682617 75.607910,1575.816772 
   C79.178185,1582.208374 82.634705,1588.662720 86.284119,1595.011597 
   C87.237930,1596.671021 88.486374,1598.520264 86.405807,1600.705322 
   C80.407578,1601.962891 74.728355,1601.446411 68.660095,1601.187134 
   C65.717400,1600.413574 64.741043,1598.193359 63.609455,1596.188354 
   C52.824154,1577.077026 42.138142,1557.909912 31.495092,1538.719238 
   C22.464981,1522.436646 13.354529,1506.198486 4.365798,1489.892822 
   C3.236578,1487.844482 2.143662,1485.820801 1.221416,1483.296387 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M465.145081,1600.261230 
   C459.974365,1588.536255 453.293274,1577.990601 447.371796,1567.036865 
   C438.442230,1550.518799 429.110840,1534.217896 419.955353,1517.821777 
   C405.776581,1492.430054 391.584259,1467.045776 377.431580,1441.639526 
   C366.997467,1422.908691 356.646423,1404.131470 346.206421,1385.403931 
   C332.288513,1360.437744 318.289734,1335.516602 304.383636,1310.543945 
   C293.790588,1291.520508 283.325745,1272.425781 272.735321,1253.400879 
   C258.671082,1228.135254 244.524597,1202.915649 230.443176,1177.659668 
   C220.245132,1159.368896 210.115509,1141.039917 199.919235,1122.748047 
   C185.758896,1097.344727 171.535889,1071.976318 157.394547,1046.562378 
   C146.726349,1027.390137 136.185150,1008.147217 125.513718,988.976807 
   C111.530716,963.857483 97.456596,938.788879 83.457100,913.678711 
   C73.178368,895.242371 62.980965,876.760803 52.703381,858.323792 
   C38.623928,833.066711 24.493519,807.838013 10.407118,782.584839 
   C7.410509,777.212646 4.509017,771.787415 1.281855,766.193359 
   C1.000000,764.933289 1.000000,763.866638 1.000000,762.399963 
   C5.566153,765.929626 7.344085,771.664001 10.146067,776.661072 
   C30.876820,813.632141 51.338577,850.754028 71.924347,887.806580 
   C88.149094,917.009583 104.402893,946.196594 120.692726,975.363342 
   C142.411011,1014.249817 164.215714,1053.088135 185.908081,1091.989014 
   C199.832535,1116.959595 213.569336,1142.034668 227.485153,1167.010254 
   C244.553513,1197.643677 261.768311,1228.195679 278.829865,1258.833008 
   C291.365143,1281.342529 303.695465,1303.966064 316.226318,1326.478027 
   C331.664795,1354.213989 347.254791,1381.865479 362.707184,1409.593750 
   C374.924988,1431.517578 386.985443,1453.529175 399.203156,1475.453003 
   C414.655396,1503.181396 430.219604,1530.847412 445.688751,1558.566406 
   C453.304108,1572.212158 460.814178,1585.916870 468.635315,1599.807129 
   C472.883301,1602.917236 474.198578,1607.618652 476.426880,1611.617554 
   C490.657684,1637.156860 504.869019,1662.707275 519.082275,1688.256714 
   C519.797241,1689.541992 520.792603,1690.760498 520.220398,1692.702881 
   C519.250000,1693.000000 518.500000,1693.000000 517.375000,1693.000000 
   C512.674072,1688.677734 510.564240,1682.955811 507.665375,1677.779297 
   C494.287506,1653.889893 481.078186,1629.905640 467.701569,1606.014648 
   C466.731659,1604.282471 465.700958,1602.563843 465.145081,1600.261230 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M472.759247,763.234802 
   C474.392761,762.978516 475.329437,763.898621 476.566528,764.945557 
   C482.098633,771.044800 485.050354,778.379517 488.857056,785.163086 
   C503.197876,810.718506 517.377014,836.364685 531.666870,861.949402 
   C545.469482,886.661499 559.127563,911.454895 572.940002,936.162048 
   C593.577637,973.077820 614.128845,1010.042114 634.675537,1047.008179 
   C655.224609,1083.978638 675.851440,1120.906128 696.372864,1157.892090 
   C716.813232,1194.731812 737.403931,1231.488892 757.918335,1268.287964 
   C779.811401,1307.560181 801.599182,1346.891113 823.504333,1386.156616 
   C845.652222,1425.857056 867.821533,1465.546021 889.876526,1505.297729 
   C907.165344,1536.458862 924.787598,1567.434937 941.955566,1599.084229 
   C957.689209,1627.390625 973.311218,1655.337769 988.875000,1683.317139 
   C990.450134,1686.148804 992.700134,1688.817261 992.222473,1692.704956 
   C991.555542,1693.000000 991.111084,1693.000000 990.333313,1693.000000 
   C984.777893,1688.729736 982.771118,1682.338623 979.670532,1676.760132 
   C965.641968,1651.520508 951.348572,1626.427734 937.308411,1600.776123 
   C918.262024,1566.728882 899.430603,1532.980835 880.665222,1499.195679 
   C863.598755,1468.469360 846.376221,1437.828979 829.331787,1407.090332 
   C813.821899,1379.119019 798.193359,1351.213989 782.693115,1323.237183 
   C765.659790,1292.493164 748.401123,1261.873169 731.354553,1231.136108 
   C715.923523,1203.312134 700.345642,1175.570190 684.945801,1147.728638 
   C669.546021,1119.887451 653.874573,1092.196045 638.496338,1064.342896 
   C623.118652,1036.490479 607.405945,1008.825439 592.082825,980.942810 
   C578.604858,956.417603 564.769104,932.091003 551.225830,907.601624 
   C537.279297,882.383179 523.188477,857.245056 509.167297,832.068176 
   C498.387787,812.712158 487.610352,793.354919 476.876801,773.973572 
   C475.051941,770.678406 472.506287,767.628540 472.759247,763.234802 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M503.738708,776.262207 
   C504.752533,776.252380 505.531860,776.527588 506.592529,776.946655 
   C511.480316,781.232666 513.476624,787.063477 516.368469,792.249023 
   C543.214172,840.387695 570.008728,888.555237 596.831665,936.706482 
   C625.273926,987.764832 653.685730,1038.840454 682.186584,1089.866333 
   C709.878052,1139.443237 737.497620,1189.060913 765.085693,1238.695435 
   C790.569885,1284.544678 816.074585,1330.383179 841.590210,1376.214722 
   C864.999634,1418.263306 888.374756,1460.331665 911.889832,1502.321899 
   C929.138855,1533.122925 946.203918,1564.028320 963.331177,1594.898071 
   C964.059326,1596.210571 964.825256,1597.496826 965.832336,1599.071045 
   C980.437317,1626.123535 995.356018,1652.580444 1010.135376,1679.114868 
   C1012.511963,1683.381714 1015.915955,1687.238037 1016.222290,1692.704712 
   C1015.250000,1693.000000 1014.500000,1693.000000 1013.375000,1693.000000 
   C1008.883545,1687.924438 1006.108704,1682.014648 1002.967896,1676.367432 
   C989.046753,1651.336670 975.120056,1626.308838 961.014038,1600.960938 
   C946.452881,1575.476685 932.598389,1550.018555 918.479675,1524.707764 
   C904.604004,1499.832397 890.649414,1475.000977 876.890137,1450.060913 
   C863.130859,1425.120972 849.092590,1400.336670 835.313477,1375.407837 
   C821.615295,1350.625488 807.767456,1325.926270 793.973694,1301.197021 
   C780.179749,1276.467651 766.493958,1251.678223 752.635132,1226.984741 
   C736.901794,1198.951294 721.439758,1170.766602 705.723206,1142.723022 
   C691.554626,1117.441650 677.627869,1092.024292 663.464417,1066.739868 
   C649.463318,1041.745239 635.627747,1016.659058 621.661621,991.645203 
   C609.644653,970.122437 597.582092,948.624756 585.673645,927.041626 
   C571.914307,902.103821 557.921448,877.296387 544.098083,852.394287 
   C530.921509,828.657410 517.808411,804.884827 504.432190,781.259033 
   C503.607635,779.802734 502.791138,778.318665 503.738708,776.262207 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M694.658813,776.039551 
   C715.624329,813.039185 736.326111,850.026978 756.944824,887.060974 
   C772.552429,915.094177 788.002747,943.215027 803.615479,971.245361 
   C823.997864,1007.839050 844.507385,1044.361938 864.893250,1080.953613 
   C878.809387,1105.932617 892.555054,1131.006592 906.472351,1155.984985 
   C923.542725,1186.622437 940.756042,1217.180054 957.825745,1247.817871 
   C971.742615,1272.796631 985.485901,1297.872192 999.402527,1322.851074 
   C1016.471802,1353.488892 1033.671143,1384.054199 1050.757446,1414.682495 
   C1062.743530,1436.168213 1074.601318,1457.725220 1086.575684,1479.217407 
   C1103.969482,1510.436768 1121.418457,1541.625244 1138.840088,1572.829102 
   C1143.782349,1581.681152 1148.710938,1590.540771 1153.814697,1599.693604 
   C1157.479736,1602.369141 1158.756348,1606.329712 1160.653687,1609.757446 
   C1171.850342,1629.985596 1183.104858,1650.181396 1194.401978,1670.353394 
   C1195.482422,1672.282837 1196.159180,1674.283447 1196.777710,1676.709717 
   C1197.000000,1678.066650 1197.000000,1679.133423 1197.000000,1680.600098 
   C1193.917358,1681.271118 1193.195801,1678.600952 1192.168823,1676.742188 
   C1181.709595,1657.811279 1171.156372,1638.932861 1160.650513,1620.027954 
   C1157.175171,1613.774414 1153.701294,1607.520264 1150.014404,1600.963867 
   C1135.465088,1575.476685 1121.604370,1550.026489 1107.488281,1524.718628 
   C1093.615356,1499.846558 1079.668091,1475.015747 1065.903931,1450.083130 
   C1052.140137,1425.151123 1038.119385,1400.362427 1024.330566,1375.444336 
   C1010.541992,1350.526489 996.628540,1325.677979 982.750671,1300.809692 
   C969.034912,1276.231567 955.453064,1251.579102 941.668396,1227.038940 
   C925.845947,1198.870972 910.366089,1170.512207 894.510742,1142.361450 
   C882.170593,1120.451782 870.106689,1098.386353 857.864685,1076.420654 
   C843.919861,1051.399658 829.958374,1026.387939 816.067017,1001.337158 
   C800.559143,973.371277 784.873596,945.504150 769.388428,917.525574 
   C753.983643,889.692261 738.335083,861.994690 722.937744,834.157166 
   C713.183350,816.521912 703.379150,798.914307 693.479858,781.359802 
   C692.823853,780.196533 692.166016,779.019470 692.705322,777.275452 
   C693.382935,776.654968 693.871216,776.351257 694.658813,776.039551 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M672.722534,783.278992 
   C673.481995,782.190308 674.274353,782.048706 675.473206,782.182495 
   C680.510193,788.670288 683.868286,795.931213 687.721680,802.885559 
   C705.237915,834.497925 723.014404,865.965149 740.488220,897.600464 
   C757.560913,928.509521 774.880432,959.280701 791.994873,990.166504 
   C809.109070,1021.051941 826.424622,1051.826172 843.553345,1082.703735 
   C860.681946,1113.581055 877.923645,1144.395386 895.069153,1175.263184 
   C912.133911,1205.985596 929.285583,1236.660034 946.370483,1267.371216 
   C961.916931,1295.316895 977.490173,1323.247803 993.026306,1351.199219 
   C1008.480957,1379.004150 1024.050903,1406.745605 1039.481812,1434.563843 
   C1054.913330,1462.382935 1070.508911,1490.110352 1085.888062,1517.958618 
   C1099.575928,1542.744141 1113.496826,1567.400513 1127.265503,1592.141479 
   C1128.481812,1594.326904 1129.619507,1596.549194 1130.955688,1599.085449 
   C1146.689941,1627.390991 1162.311401,1655.338135 1177.874878,1683.317261 
   C1179.450073,1686.148926 1181.700317,1688.817261 1181.222412,1692.704956 
   C1180.555542,1693.000000 1180.111084,1693.000000 1179.333252,1693.000000 
   C1173.777466,1688.729858 1171.770996,1682.338379 1168.670410,1676.759888 
   C1154.641357,1651.520020 1140.348633,1626.426392 1126.307129,1600.774902 
   C1108.977539,1569.793457 1091.834229,1539.127197 1074.787231,1508.406616 
   C1057.740356,1477.686279 1040.498657,1447.073608 1023.477234,1416.339233 
   C1008.069153,1388.518188 992.457458,1360.811035 977.085876,1332.969727 
   C963.404663,1308.189941 949.465149,1283.553467 935.739441,1258.798096 
   C922.013672,1234.042847 908.245178,1209.311401 894.426636,1184.607910 
   C880.445618,1159.613892 866.650757,1134.516479 852.639038,1109.538940 
   C838.628113,1084.562744 824.827271,1059.468384 810.875000,1034.458862 
   C796.921936,1009.448059 783.007202,984.416016 769.090088,959.385193 
   C753.553833,931.442261 737.941223,903.541992 722.428406,875.585876 
   C706.996216,847.774841 691.385620,820.062805 676.011475,792.219360 
   C674.508545,789.497498 672.296570,787.026428 672.722534,783.278992 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M531.841797,784.334229 
   C532.833435,784.382935 533.564575,784.692993 534.593506,785.039429 
   C539.077087,788.257874 540.587769,793.247864 542.980408,797.549805 
   C563.541199,834.517151 584.083191,871.495972 604.718933,908.420715 
   C623.161377,941.420776 641.450623,974.506348 659.954956,1007.472351 
   C677.234863,1038.256958 694.177979,1069.232178 711.444763,1100.025269 
   C727.001160,1127.767944 742.298462,1155.653931 757.895691,1183.374512 
   C773.410889,1210.949341 788.594971,1238.711182 804.026306,1266.334473 
   C828.067749,1309.370605 852.045593,1352.442383 876.005188,1395.523804 
   C898.184692,1435.404663 920.439453,1475.243774 942.547546,1515.164673 
   C958.200073,1543.428711 974.169556,1571.517212 989.774170,1600.228638 
   C1006.184937,1629.399658 1022.210693,1658.364258 1038.307007,1687.289551 
   C1039.193237,1688.881958 1040.273682,1690.441162 1039.230225,1692.710205 
   C1038.555542,1693.000000 1038.111084,1693.000000 1037.333252,1693.000000 
   C1033.169678,1690.426758 1032.006592,1686.003418 1029.963135,1682.308350 
   C1014.922974,1655.112793 999.688477,1628.026367 984.339478,1600.583008 
   C966.398865,1568.220459 948.478271,1536.266968 930.695679,1504.236450 
   C913.640381,1473.516235 896.443848,1442.873901 879.384033,1412.156372 
   C863.941406,1384.350708 848.436035,1356.580078 832.988403,1328.777466 
   C817.540466,1300.974243 802.039612,1273.200562 786.531738,1245.430664 
   C772.729126,1220.714111 759.074219,1195.914917 745.241028,1171.215088 
   C729.699829,1143.465332 714.383423,1115.590576 698.809937,1087.858154 
   C684.622620,1062.594238 670.734436,1037.162354 656.565186,1011.887756 
   C641.010437,984.141724 625.709412,956.254578 610.149475,928.511841 
   C594.588745,900.767822 579.206116,872.925049 563.723206,845.137939 
   C553.509155,826.806885 543.100403,808.583679 533.137207,790.113342 
   C532.218323,788.409790 530.667725,786.835938 531.841797,784.334229 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M651.694397,788.271973 
   C652.360901,787.666138 652.843201,787.380066 653.619202,787.085083 
   C657.332458,788.835022 658.278992,792.477844 659.933533,795.439453 
   C681.866028,834.699463 703.681885,874.024719 725.581848,913.302979 
   C747.724304,953.016174 769.908447,992.706604 791.966675,1032.466187 
   C810.792297,1066.398926 829.685913,1100.294434 848.564392,1134.197266 
   C871.980225,1176.248779 895.386536,1218.306396 918.861511,1260.325439 
   C939.413879,1297.113037 959.849365,1333.967651 980.320862,1370.801147 
   C999.093140,1404.577637 1018.022217,1438.267578 1036.756104,1472.064941 
   C1055.570679,1506.007935 1074.637207,1539.810547 1093.345093,1573.812744 
   C1098.001343,1582.275757 1102.670044,1590.734741 1107.644653,1599.444458 
   C1116.772827,1616.249268 1126.056641,1632.545166 1135.133911,1648.955933 
   C1142.203247,1661.736572 1149.240234,1674.535522 1156.423584,1687.253296 
   C1157.309692,1688.822021 1158.270020,1690.468384 1157.230225,1692.710083 
   C1156.555542,1693.000000 1156.111084,1693.000000 1155.333252,1693.000000 
   C1150.536377,1688.777100 1148.539795,1683.000610 1145.630371,1677.831909 
   C1131.208862,1652.212158 1117.133301,1626.398315 1102.374023,1600.545044 
   C1086.652832,1572.287109 1071.132935,1544.338745 1055.544067,1516.428467 
   C1041.335815,1490.990112 1027.324829,1465.439941 1013.080017,1440.022095 
   C999.160950,1415.185547 985.410217,1390.255981 971.528137,1365.399170 
   C957.727112,1340.687500 943.913757,1315.982666 930.219482,1291.211182 
   C916.445374,1266.295288 902.451050,1241.501465 888.647095,1216.602295 
   C875.245056,1192.428345 861.666687,1168.352661 848.273621,1144.174561 
   C834.557312,1119.412720 820.840454,1094.651001 807.008850,1069.953857 
   C792.525513,1044.092896 778.257446,1018.112610 763.772217,992.252808 
   C738.056458,946.343628 712.547058,900.319214 686.930786,854.354492 
   C675.662781,834.135742 664.396179,813.916260 653.099731,793.713318 
   C652.217224,792.134949 651.118408,790.583435 651.694397,788.271973 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M557.882935,789.360474 
   C558.916382,789.328369 559.677917,789.546082 560.716553,789.883911 
   C566.167969,793.693970 567.832825,799.760864 570.697937,804.904419 
   C590.063965,839.671204 609.581360,874.354248 628.834045,909.183716 
   C645.911865,940.078674 663.198853,970.856873 680.312500,1001.731812 
   C697.425110,1032.604614 714.718140,1063.378540 731.853638,1094.239014 
   C750.605835,1128.010742 769.401428,1161.758667 788.188660,1195.510742 
   C805.276917,1226.210693 822.359741,1256.913940 839.497253,1287.586792 
   C856.715759,1318.404541 873.767700,1349.315430 890.999207,1380.125366 
   C906.524292,1407.884155 921.905701,1435.722412 937.437805,1463.477051 
   C953.051086,1491.376709 968.552124,1519.339111 984.063538,1547.295654 
   C993.596436,1564.476929 1003.283203,1581.573120 1012.956665,1599.098511 
   C1023.660461,1618.619629 1034.272827,1637.769653 1045.031738,1656.838501 
   C1050.656006,1666.806885 1056.111816,1676.869507 1061.731079,1686.841187 
   C1062.686279,1688.536133 1063.906738,1690.239136 1063.222412,1692.705078 
   C1062.555542,1693.000000 1062.111084,1693.000000 1061.333252,1693.000000 
   C1055.608154,1688.856812 1053.795654,1682.363892 1050.641357,1676.815308 
   C1036.370483,1651.711914 1022.470764,1626.398560 1008.153198,1600.898193 
   C993.871765,1575.258789 979.636841,1550.014160 965.644287,1524.635254 
   C951.892639,1499.693481 937.871033,1474.902710 924.083557,1449.980835 
   C910.457581,1425.350830 896.751953,1400.764526 882.996948,1376.205811 
   C870.463135,1353.827515 858.131409,1331.336426 845.596802,1308.958740 
   C831.679138,1284.112305 817.908752,1259.184204 804.052124,1234.303833 
   C790.113708,1209.276611 776.106018,1184.287964 762.241760,1159.219238 
   C748.618286,1134.585938 734.772888,1110.075928 721.131348,1085.452393 
   C705.713684,1057.622803 690.109314,1029.897095 674.683777,1002.071960 
   C659.177673,974.101440 643.499695,946.226807 628.005310,918.250061 
   C612.510864,890.273010 596.862122,862.382141 581.325439,834.428711 
   C573.961609,821.179993 566.580505,807.940857 559.258118,794.669128 
   C558.386902,793.090088 557.091492,791.644165 557.882935,789.360474 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M629.752197,791.286621 
   C630.413513,790.707336 630.863159,790.430542 631.588928,790.138672 
   C635.025940,791.594421 636.052612,794.784912 637.546204,797.488098 
   C660.256165,838.588623 683.292236,879.507507 706.059753,920.575500 
   C728.180969,960.477844 750.366150,1000.345093 772.585266,1040.192261 
   C792.695129,1076.256836 812.779175,1112.336792 832.887817,1148.401978 
   C855.106323,1188.251099 877.313354,1228.107910 899.443542,1268.006958 
   C919.796631,1304.702148 940.312927,1341.307251 960.733582,1377.965210 
   C982.613159,1417.242310 1004.408569,1456.566406 1026.329346,1495.820435 
   C1045.489136,1530.130493 1064.416016,1564.570557 1083.944336,1599.097290 
   C1096.381714,1622.210205 1109.093384,1644.749634 1121.740479,1667.324463 
   C1125.551147,1674.126587 1129.224243,1681.005371 1133.153809,1687.741577 
   C1133.979004,1689.156250 1134.884033,1690.615234 1134.222412,1692.704956 
   C1133.555542,1693.000000 1133.111084,1693.000000 1132.333252,1693.000000 
   C1127.116089,1689.992920 1125.807617,1684.487061 1123.242554,1679.971069 
   C1108.387329,1653.817505 1093.986328,1627.407959 1079.041992,1600.881104 
   C1056.651245,1561.280029 1034.917847,1521.733154 1012.936707,1482.324951 
   C990.712891,1442.481812 968.560547,1402.597656 946.408630,1362.713745 
   C924.337952,1322.975952 902.137878,1283.309448 880.027161,1243.593750 
   C861.156494,1209.697876 842.436646,1175.716675 823.474304,1141.873169 
   C803.290405,1105.849243 783.289734,1069.723389 763.151733,1033.674316 
   C742.606079,996.895264 722.151184,960.063965 701.694458,923.234619 
   C681.237793,886.405212 660.702881,849.618958 640.167725,812.832947 
   C636.921204,807.017212 633.737732,801.165649 630.476990,795.357239 
   C629.823425,794.192932 629.165283,793.015625 629.752197,791.286621 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M583.713318,792.264404 
   C584.632629,792.007812 585.346802,792.057800 586.417297,792.142883 
   C590.823792,797.676575 593.837219,803.805176 597.109131,809.747070 
   C619.040283,849.574280 641.308594,889.213623 663.391418,928.956055 
   C685.556091,968.845703 707.843811,1008.667480 730.036560,1048.541748 
   C755.226990,1093.801880 780.419983,1139.060425 805.605286,1184.323486 
   C829.413269,1227.111206 853.304321,1269.853027 877.107544,1312.643433 
   C902.287292,1357.908203 927.468506,1403.172363 952.663330,1448.428711 
   C978.100647,1494.120850 1003.539978,1539.811890 1029.006470,1585.487793 
   C1031.519653,1589.995239 1034.159180,1594.439209 1036.646240,1599.382568 
   C1053.201172,1629.052979 1069.432617,1658.482666 1085.830322,1687.819580 
   C1086.624390,1689.240234 1087.802856,1690.565308 1087.220215,1692.704102 
   C1086.250000,1693.000000 1085.500000,1693.000000 1084.375000,1693.000000 
   C1079.380249,1688.069336 1076.987305,1681.771729 1073.731812,1676.024292 
   C1059.585571,1651.050415 1045.989868,1625.767578 1031.488892,1600.570068 
   C1015.567322,1571.839111 999.811096,1543.436768 983.932434,1515.102051 
   C971.392517,1492.725220 959.039734,1470.243774 946.547546,1447.839966 
   C932.676636,1422.963501 918.767700,1398.108276 904.976990,1373.187378 
   C891.266174,1348.411133 877.327698,1323.761719 863.623047,1298.982178 
   C849.837646,1274.056763 835.919006,1249.205688 822.048279,1224.328003 
   C808.177185,1199.449463 794.420166,1174.507690 780.476318,1149.669434 
   C764.820312,1121.781738 749.446777,1093.736572 733.795715,1065.845337 
   C721.243103,1043.475708 708.894836,1020.991028 696.412048,998.581848 
   C682.633057,973.845520 668.831055,949.122070 655.087646,924.365784 
   C641.343323,899.607788 627.456116,874.929321 613.709290,850.172607 
   C604.733521,834.008118 595.855835,817.789062 586.725830,801.709839 
   C585.128601,798.896912 582.677002,796.281494 583.713318,792.264404 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M606.855591,792.342163 
   C607.799866,792.079651 608.485107,792.080383 609.512817,792.095093 
   C614.367981,796.515381 616.548828,802.401550 619.520752,807.739319 
   C634.990967,835.524170 650.563354,863.252380 665.946716,891.085938 
   C679.638489,915.858948 693.607239,940.478699 707.288269,965.257812 
   C721.049805,990.182617 735.015259,1014.993469 748.834839,1039.885864 
   C762.653809,1064.777222 776.502563,1089.652344 790.404602,1114.497803 
   C804.225464,1139.198364 817.880615,1163.992065 831.687622,1188.700806 
   C854.186401,1228.964355 876.617188,1269.266113 899.026917,1309.578857 
   C922.893616,1352.512573 946.780701,1395.435425 970.676941,1438.352417 
   C995.787842,1483.450684 1020.783203,1528.613892 1046.078369,1573.609985 
   C1050.972534,1582.316040 1055.905029,1591.008301 1060.676758,1600.202881 
   C1076.886475,1629.492432 1093.261597,1658.270264 1109.116455,1687.337158 
   C1109.974609,1688.910400 1111.277832,1690.391846 1110.229492,1692.709717 
   C1109.555542,1693.000000 1109.111084,1693.000000 1108.333252,1693.000000 
   C1104.230469,1690.188477 1102.791870,1685.790771 1100.640137,1681.926758 
   C1086.015625,1655.664185 1071.395508,1629.399292 1056.687256,1603.183472 
   C1056.197266,1602.310059 1055.582642,1601.525635 1054.723389,1600.462524 
   C1045.819214,1582.714233 1035.862305,1565.958862 1026.395020,1548.936646 
   C1012.148682,1523.322144 998.001221,1497.652588 983.671204,1472.084839 
   C968.038147,1444.192383 952.583252,1416.200928 937.005859,1388.277588 
   C921.509583,1360.499512 906.019714,1332.718018 890.574524,1304.911499 
   C875.129150,1277.104736 859.584778,1249.353149 844.163269,1221.532959 
   C828.580383,1193.421631 812.820496,1165.408813 797.248413,1137.291260 
   C781.918579,1109.611206 766.374878,1082.050171 751.059021,1054.362183 
   C735.662598,1026.528564 720.016052,998.834106 704.631409,970.994019 
   C689.166138,943.008118 673.417603,915.180176 657.959473,887.190491 
   C642.501526,859.201050 626.736389,831.382141 611.371887,803.341736 
   C609.505188,799.935059 606.330383,796.998657 606.855591,792.342163 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M441.872437,1600.346069 
   C429.540955,1577.644043 417.060669,1555.298340 404.623688,1532.928589 
   C395.576141,1516.655396 386.622162,1500.330322 377.561523,1484.064453 
   C363.569336,1458.945435 349.504181,1433.867188 335.503052,1408.753174 
   C325.223389,1390.314575 315.019745,1371.833496 304.740845,1353.394531 
   C290.659088,1328.133545 276.508942,1302.910767 262.443695,1277.640625 
   C251.771759,1258.467285 241.225906,1239.223877 230.553131,1220.050903 
   C216.567978,1194.927612 202.494156,1169.853760 188.494598,1144.738525 
   C178.135025,1126.153442 167.859253,1107.521484 157.498978,1088.936768 
   C143.498260,1063.821411 129.421860,1038.748291 115.435562,1013.624939 
   C104.762466,994.452942 94.218307,975.209290 83.545731,956.036987 
   C69.560867,930.914429 55.487011,905.841370 41.487720,880.726807 
   C31.128107,862.141785 20.841782,843.515869 10.489074,824.926941 
   C7.579370,819.702332 4.550446,814.544128 1.288102,809.177734 
   C1.000000,807.611084 1.000000,806.222229 1.000000,804.416626 
   C5.924722,809.509216 8.628642,816.377991 12.183302,822.692444 
   C31.914017,857.741699 51.425507,892.914612 70.966087,928.070679 
   C86.550957,956.109924 102.013969,984.217041 117.631927,1012.237793 
   C139.397827,1051.288696 161.301208,1090.262939 183.063736,1129.315796 
   C200.297684,1160.242188 217.359451,1191.264648 234.586624,1222.194946 
   C254.965225,1258.783569 275.475891,1295.298462 295.859283,1331.884399 
   C309.773712,1356.859009 323.514984,1381.930176 337.430054,1406.904541 
   C354.497559,1437.536865 371.701111,1468.093262 388.778168,1498.720215 
   C400.920227,1520.496460 412.936218,1542.343018 425.036591,1564.142700 
   C431.569733,1575.912720 438.162018,1587.650146 444.888733,1599.712402 
   C450.360504,1604.654785 452.561157,1611.304077 455.848755,1617.178955 
   C468.427155,1639.656006 481.022980,1662.124390 493.512604,1684.651367 
   C494.854401,1687.071411 496.804901,1689.320801 496.222595,1692.704346 
   C495.555542,1693.000000 495.111115,1693.000000 494.333313,1693.000000 
   C489.711090,1690.356689 488.523285,1685.561035 486.280273,1681.568237 
   C472.013794,1656.171997 457.715607,1630.792603 443.744751,1605.231689 
   C442.952911,1603.782959 441.950134,1602.406860 441.872437,1600.346069 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M418.069763,1600.292969 
   C409.580139,1584.338501 401.117737,1568.710938 392.498901,1553.170288 
   C382.020233,1534.275757 371.402130,1515.458740 360.858276,1496.600342 
   C346.663422,1471.211670 332.458008,1445.828857 318.302399,1420.418213 
   C307.866241,1401.684448 297.529419,1382.895386 287.100006,1364.157959 
   C272.873108,1338.598022 258.578156,1313.076172 244.353775,1287.514893 
   C232.229202,1265.726807 220.197449,1243.887085 208.062057,1222.105225 
   C194.229568,1197.277222 180.305283,1172.500244 166.463577,1147.677368 
   C156.019348,1128.947144 145.671707,1110.163086 135.228531,1091.432251 
   C117.907791,1060.365601 100.501823,1029.346436 83.213943,998.261536 
   C70.528664,975.452393 58.029118,952.540100 45.348385,929.728455 
   C30.812346,903.579346 16.167603,877.490662 1.285050,851.187866 
   C1.000000,849.933289 1.000000,848.866638 1.000000,847.399963 
   C5.369489,849.810547 6.439263,854.880127 8.755551,858.965942 
   C22.856176,883.839050 36.527550,908.954956 50.444725,933.932739 
   C67.514900,964.569336 84.725883,995.127441 101.799545,1025.762085 
   C114.101295,1047.834839 126.223305,1070.007446 138.523956,1092.080688 
   C155.595901,1122.716064 172.811920,1153.271118 189.868805,1183.914795 
   C204.098282,1209.479004 218.116302,1235.161011 232.356613,1260.719238 
   C250.801468,1293.823608 269.427429,1326.827148 287.874664,1359.930298 
   C302.035706,1385.341919 315.972900,1410.878174 330.131989,1436.291016 
   C348.576630,1469.395874 367.201904,1502.400146 385.634552,1535.511597 
   C397.676636,1557.143311 409.514740,1578.888794 421.707214,1600.805908 
   C425.680725,1603.206055 426.711700,1607.313965 428.613007,1610.685303 
   C442.892914,1636.004395 456.953430,1661.446899 471.053345,1686.866943 
   C472.009644,1688.591064 473.323730,1690.230103 472.230469,1692.708984 
   C471.555542,1693.000000 471.111115,1693.000000 470.333313,1693.000000 
   C466.574554,1690.970947 465.707825,1687.144287 463.967468,1684.025513 
   C448.979889,1657.167725 434.007355,1630.301392 418.966766,1603.473022 
   C418.477417,1602.600098 418.076965,1601.684570 418.069763,1600.292969 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M394.796844,1600.379150 
   C384.368469,1581.174438 373.802673,1562.325806 363.266998,1543.460571 
   C348.919220,1517.769531 334.577667,1492.075195 320.265442,1466.364258 
   C309.913757,1447.768311 299.646088,1429.125610 289.284149,1410.535400 
   C271.963440,1379.460449 254.562042,1348.430542 237.262283,1317.343994 
   C224.811066,1294.969849 212.515717,1272.508911 200.067429,1250.133179 
   C186.247589,1225.292114 172.315216,1200.513794 158.465607,1175.689331 
   C148.258728,1157.394287 138.125351,1139.057983 127.924797,1120.759399 
   C113.758934,1095.347534 99.536491,1069.967041 85.384674,1044.547363 
   C74.870789,1025.662354 64.462234,1006.718628 53.949787,987.832825 
   C39.800137,962.412720 25.600452,937.020447 11.411123,911.622437 
   C8.173958,905.828064 4.891527,900.059082 1.314978,894.139160 
   C1.000000,892.611084 1.000000,891.222229 1.000000,889.416626 
   C5.650037,893.485474 7.751126,899.584534 10.819692,905.016235 
   C24.883245,929.910156 38.561939,955.021057 52.478722,979.998413 
   C69.548294,1010.634216 86.764618,1041.188232 103.827385,1071.827881 
   C116.363899,1094.339600 128.695068,1116.965698 141.227173,1139.479980 
   C156.667236,1167.218506 172.259415,1194.872314 187.713287,1222.603271 
   C199.931900,1244.528687 211.992355,1266.542358 224.210953,1288.467773 
   C239.664490,1316.198242 255.245377,1343.857666 270.699280,1371.587769 
   C282.918640,1393.513794 294.981506,1415.527100 307.200470,1437.453247 
   C322.653992,1465.183716 338.231476,1492.844971 353.686432,1520.574585 
   C364.450195,1539.887451 375.074188,1559.278198 385.809479,1578.606934 
   C389.681000,1585.577515 393.695007,1592.468994 397.812408,1599.693359 
   C401.736023,1602.406250 402.814697,1606.693970 404.791016,1610.239380 
   C419.280731,1636.232910 433.668396,1662.283569 448.275177,1688.212158 
   C449.006897,1689.511108 449.863495,1690.784424 449.222473,1692.704102 
   C448.250000,1693.000000 447.500000,1693.000000 446.375000,1693.000000 
   C441.699951,1687.655029 438.807159,1681.442261 435.500488,1675.515381 
   C422.456482,1652.134521 409.481201,1628.714844 396.439636,1605.332275 
   C395.629822,1603.880371 394.716675,1602.471924 394.796844,1600.379150 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M371.002991,1600.350586 
   C358.177277,1576.889893 345.314209,1553.801514 332.472382,1530.701172 
   C322.051788,1511.956177 311.687500,1493.179688 301.243439,1474.447754 
   C283.920837,1443.378662 266.512695,1412.357178 249.219772,1381.271729 
   C236.692947,1358.753662 224.353806,1336.131470 211.823318,1313.615479 
   C196.384293,1285.873413 180.801453,1258.211426 165.341141,1230.481079 
   C153.197678,1208.700073 141.181442,1186.847900 129.043869,1165.063599 
   C115.209305,1140.233398 101.269379,1115.461914 87.438332,1090.629761 
   C76.759727,1071.457520 66.218185,1052.208862 55.544151,1033.034058 
   C41.557270,1007.908142 27.498402,982.822266 13.473847,957.717285 
   C9.500220,950.604248 5.538304,943.484680 1.285503,936.184082 
   C1.000000,934.933289 1.000000,933.866638 1.000000,932.399963 
   C4.919902,934.265442 5.894395,938.652588 7.870252,942.160583 
   C23.528257,969.959839 38.857391,997.944031 54.383694,1025.817871 
   C74.839684,1062.541870 95.418701,1099.197144 115.876587,1135.920044 
   C129.786819,1160.889526 143.526901,1185.953613 157.439072,1210.921997 
   C174.502884,1241.546875 191.714417,1272.089722 208.771469,1302.718262 
   C221.303589,1325.221802 233.630783,1347.839355 246.156265,1370.346436 
   C261.588501,1398.076782 277.162201,1425.728271 292.617340,1453.445801 
   C304.756348,1475.216187 316.776184,1497.053101 328.907166,1518.827881 
   C342.734436,1543.647949 356.629608,1568.429932 370.483551,1593.235107 
   C371.619263,1595.268555 372.665283,1597.352173 373.918579,1599.723877 
   C379.166443,1603.786621 380.839844,1609.821167 383.723480,1614.952515 
   C396.423553,1637.552002 409.082489,1660.176270 421.620117,1682.867065 
   C423.272095,1685.856934 425.643616,1688.642700 425.222595,1692.704346 
   C424.555542,1693.000000 424.111115,1693.000000 423.333313,1693.000000 
   C417.652496,1688.394287 415.473511,1681.728882 412.181793,1675.856689 
   C399.584961,1653.385376 386.992950,1630.910278 374.483246,1608.389648 
   C373.114960,1605.926514 371.551910,1603.545532 371.002991,1600.350586 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M50.495171,509.222748 
   C34.790794,481.506561 19.506004,454.047791 4.204569,426.598328 
   C3.396617,425.148956 2.447824,423.778107 1.282309,422.185333 
   C1.000000,409.645782 1.000000,397.291534 1.000001,384.468658 
   C7.882913,396.087952 14.854720,408.126343 21.630999,420.273773 
   C37.788555,449.238464 53.853806,478.254639 70.016281,507.598511 
   C70.117142,509.767792 68.657875,510.175934 67.431969,510.232361 
   C61.910118,510.486481 56.335064,511.478516 50.495171,509.222748 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M347.089478,1600.278320 
   C341.613007,1589.688354 336.191498,1579.411011 330.557190,1569.251587 
   C321.773163,1553.412476 312.819427,1537.667603 303.983063,1521.857422 
   C288.093018,1493.426514 272.210663,1464.991577 256.375305,1436.530273 
   C245.709747,1417.360718 235.171295,1398.120239 224.501968,1378.952759 
   C210.520126,1353.834351 196.453415,1328.763306 182.445923,1303.659180 
   C172.323380,1285.517700 162.247284,1267.350342 152.133713,1249.203857 
   C136.196762,1220.608643 120.228149,1192.031006 104.306885,1163.427124 
   C93.880524,1144.695190 83.540489,1125.915161 73.114700,1107.182983 
   C58.891354,1081.627563 44.606121,1056.106689 30.380486,1030.552612 
   C21.568600,1014.723450 12.823897,998.856873 4.028626,983.018433 
   C3.310759,981.725708 2.435478,980.520264 1.316664,979.137146 
   C1.000000,977.611084 1.000000,976.222229 1.000000,974.416626 
   C5.771772,978.808655 8.069064,985.166626 11.295571,990.896545 
   C32.339909,1028.269653 53.182056,1065.756958 74.033936,1103.238281 
   C89.634209,1131.279663 105.101936,1159.394775 120.714821,1187.429077 
   C142.795944,1227.077881 164.992126,1266.662598 187.071548,1306.312378 
   C204.300644,1337.252075 221.379562,1368.275391 238.610901,1399.213867 
   C258.994934,1435.812988 279.506592,1472.341064 299.894958,1508.937744 
   C313.812286,1533.919067 327.603638,1558.970337 341.473785,1583.977905 
   C344.376495,1589.211426 347.378113,1594.390015 350.587799,1599.813477 
   C355.147491,1604.368896 357.449371,1610.003052 360.348114,1615.195190 
   C373.579437,1638.895630 386.691376,1662.663086 399.935883,1686.356934 
   C400.971008,1688.208740 402.345947,1690.049438 401.230530,1692.708740 
   C400.555542,1693.000000 400.111115,1693.000000 399.333313,1693.000000 
   C395.276886,1690.106689 393.968384,1685.616577 391.807983,1681.752075 
   C377.056061,1655.363037 362.354614,1628.945557 347.650970,1602.529297 
   C347.323730,1601.941528 347.130920,1601.310181 347.089478,1600.278320 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M323.821960,1600.363770 
   C310.107025,1575.217285 296.240936,1550.426392 282.417297,1525.611816 
   C271.986023,1506.886719 261.637390,1488.115479 251.200638,1469.393433 
   C237.287399,1444.435181 223.292557,1419.522339 209.393326,1394.556274 
   C198.725037,1375.393799 188.191040,1356.156616 177.524185,1336.993408 
   C163.545807,1311.880737 149.475922,1286.819092 135.482529,1261.714844 
   C125.127350,1243.137573 114.858101,1224.512451 104.502373,1205.935547 
   C90.507942,1180.831299 76.447807,1155.763794 62.452976,1130.659912 
   C52.178856,1112.229980 41.984066,1093.755859 31.717670,1075.321777 
   C21.693623,1057.322754 11.623618,1039.349487 1.286885,1021.182495 
   C1.000000,1019.611084 1.000000,1018.222229 1.000000,1016.416626 
   C6.366138,1022.622009 9.641292,1030.487915 13.794916,1037.815674 
   C28.222317,1063.268188 42.386429,1088.870117 56.615784,1114.434692 
   C68.663864,1136.080200 80.635483,1157.768188 92.692207,1179.408936 
   C106.607864,1204.386230 120.600433,1229.320679 134.520172,1254.295654 
   C144.962036,1273.030518 155.310852,1291.817383 165.755707,1310.550537 
   C183.079727,1341.621704 200.489120,1372.645264 217.783218,1403.733032 
   C230.310867,1426.252686 242.650269,1448.877075 255.181793,1471.394531 
   C270.621918,1499.138184 286.181824,1526.815308 301.675110,1554.529419 
   C310.031128,1569.476440 318.356018,1584.440796 326.855988,1599.703125 
   C330.601074,1602.535156 331.932983,1606.638062 333.914154,1610.212402 
   C348.015411,1635.653931 362.187866,1661.055542 376.404358,1686.432861 
   C377.443481,1688.287964 378.893341,1690.032715 378.222595,1692.704346 
   C377.555542,1693.000000 377.111115,1693.000000 376.333313,1693.000000 
   C371.488129,1690.591919 370.428986,1685.674683 368.214020,1681.744751 
   C353.637390,1655.880615 339.237518,1629.916016 324.838409,1603.951782 
   C324.271729,1602.930054 323.702606,1601.921265 323.821960,1600.363770 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.220890,1526.296387 
   C1.000000,1513.645752 1.000000,1501.291504 1.000000,1488.468628 
   C3.224381,1487.509155 3.888346,1489.293091 4.649254,1490.676514 
   C14.834689,1509.196899 25.169382,1527.634277 35.407734,1546.125122 
   C44.356647,1562.287231 53.483971,1578.349365 62.411274,1594.523193 
   C63.422401,1596.354980 65.053772,1598.063354 63.436813,1600.640381 
   C57.348362,1602.076050 51.516289,1601.121582 45.340454,1601.512695 
   C41.841656,1601.606567 41.324127,1598.850464 40.279678,1596.965942 
   C29.872372,1578.187866 19.459705,1559.412842 8.960364,1540.686035 
   C6.355376,1536.039917 3.630557,1531.459961 1.220890,1526.296387 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M401.468567,1693.000000 
   C401.335114,1691.391602 400.562927,1690.062744 399.840881,1688.763916 
   C387.571014,1666.691040 375.272980,1644.633911 363.002594,1622.561157 
   C358.968628,1615.304688 354.995453,1608.014282 350.917969,1600.386230 
   C357.256958,1600.020386 363.672424,1600.007568 370.559509,1599.989258 
   C377.969482,1612.224609 384.966278,1624.433105 391.832550,1636.714478 
   C402.186279,1655.233643 412.448822,1673.803833 422.875061,1692.676147 
   C415.979034,1693.000000 408.958099,1693.000000 401.468567,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M1157.468506,1693.000000 
   C1157.335205,1691.391235 1156.562744,1690.062134 1155.840454,1688.762939 
   C1143.566772,1666.684814 1131.265259,1644.622314 1118.990845,1622.544556 
   C1114.955322,1615.286011 1110.979370,1607.994385 1106.912964,1600.360107 
   C1113.219482,1597.269043 1119.677734,1598.004272 1126.591553,1599.745117 
   C1133.966431,1612.222168 1140.963867,1624.430542 1147.831055,1636.711914 
   C1158.186401,1655.231567 1168.450684,1673.802002 1178.876831,1692.675537 
   C1171.979004,1693.000000 1164.958130,1693.000000 1157.468506,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M590.468567,1693.000000 
   C590.334961,1691.391602 589.562927,1690.062622 588.841003,1688.763794 
   C576.571106,1666.691162 564.273010,1644.634033 552.002625,1622.561646 
   C547.968567,1615.304932 543.995422,1608.014282 539.917969,1600.386108 
   C546.256958,1600.020264 552.672363,1600.007568 559.559448,1599.989136 
   C566.969543,1612.224854 573.965942,1624.433716 580.832886,1636.714966 
   C591.187439,1655.233765 601.451477,1673.803101 611.876953,1692.675537 
   C604.979065,1693.000000 597.958069,1693.000000 590.468567,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M937.591675,1599.745117 
   C944.966431,1612.222046 951.963867,1624.430542 958.830933,1636.711670 
   C969.186401,1655.231445 979.450745,1673.802002 989.876953,1692.675537 
   C982.979065,1693.000000 975.958069,1693.000000 968.468567,1693.000000 
   C968.358582,1691.006348 967.235901,1689.465210 966.355835,1687.889404 
   C950.960571,1660.322876 935.539429,1632.770996 920.142944,1605.205078 
   C919.334351,1603.757568 918.694397,1602.215820 917.912842,1600.360107 
   C924.219421,1597.269165 930.677856,1598.004028 937.591675,1599.745117 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M161.651550,1600.317627 
   C168.261063,1599.999146 174.673889,1599.996338 181.556854,1599.988770 
   C184.047165,1599.726929 184.464142,1601.441040 185.141205,1602.662720 
   C201.036423,1631.344727 217.100739,1659.932129 233.077866,1688.568359 
   C233.727615,1689.732788 234.348480,1690.920410 234.147049,1692.659912 
   C226.979050,1693.000000 219.958099,1693.000000 212.468567,1693.000000 
   C204.579025,1680.621582 197.863556,1667.846191 190.791672,1655.271362 
   C181.763519,1639.218018 172.999023,1623.016968 163.870346,1607.019165 
   C162.731537,1605.023438 161.200821,1603.161743 161.651550,1600.317627 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M184.930557,1600.361938 
   C191.730209,1600.023682 198.366943,1600.017700 205.467865,1600.020264 
   C209.213486,1601.636597 210.165100,1605.049561 211.738174,1607.868896 
   C226.091980,1633.595093 240.204071,1659.455811 254.758255,1685.070923 
   C256.043701,1687.333374 257.843597,1689.492188 257.222717,1692.703857 
   C250.312363,1693.000000 243.624710,1693.000000 236.468536,1693.000000 
   C231.457520,1686.816162 228.208084,1679.867310 224.512833,1673.199951 
   C211.780136,1650.226562 198.788727,1627.397217 186.163773,1604.363892 
   C185.522476,1603.193970 184.742310,1602.105347 184.930557,1600.361938 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M232.837936,1601.385254 
   C239.230743,1598.772583 245.713440,1600.589233 252.533737,1600.000000 
   C255.064270,1600.430908 255.842621,1602.154907 256.713440,1603.747314 
   C272.329529,1632.301880 288.399353,1660.604126 304.172150,1689.071533 
   C304.739624,1690.095703 305.320190,1691.098633 305.145874,1692.660156 
   C297.979034,1693.000000 290.958099,1693.000000 283.468567,1693.000000 
   C275.746643,1681.433350 269.540131,1669.272827 262.862854,1657.380615 
   C253.736908,1641.127197 244.745193,1624.797974 235.587173,1608.561768 
   C234.367538,1606.399536 232.876556,1604.340576 232.837936,1601.385254 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M496.468536,1693.000000 
   C492.652100,1683.927612 487.142334,1675.979980 482.557739,1667.566528 
   C470.343140,1645.151245 457.725159,1622.955811 445.158661,1600.347778 
   C451.427704,1600.004395 457.805634,1599.986206 464.666382,1599.930420 
   C469.709778,1605.250122 472.367279,1611.762817 475.775330,1617.793457 
   C489.493134,1642.067871 502.920807,1666.506104 516.445618,1690.889404 
   C516.683472,1691.318237 516.796692,1691.816162 516.984070,1692.640869 
   C510.312347,1693.000000 503.624725,1693.000000 496.468536,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M137.874939,1600.347290 
   C144.407349,1599.992920 150.794479,1599.978760 157.657623,1599.936768 
   C160.410339,1599.997681 161.027298,1601.929688 161.879852,1603.453369 
   C177.596039,1631.540161 193.208221,1659.685059 208.803040,1687.839478 
   C209.582367,1689.246338 210.828979,1690.551758 210.220749,1692.702393 
   C203.312363,1693.000000 196.624710,1693.000000 189.468536,1693.000000 
   C182.967865,1685.189941 178.913422,1676.197998 174.085709,1667.684937 
   C162.680130,1647.572632 151.606659,1627.271362 140.308212,1607.096802 
   C139.178238,1605.079224 137.799515,1603.140869 137.874939,1600.347290 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M255.902222,1600.355469 
   C262.438171,1599.998047 268.819916,1599.976807 275.678589,1599.921387 
   C278.511932,1599.785400 279.120026,1601.729370 279.984741,1603.266724 
   C291.799377,1624.270264 303.440704,1645.370117 315.120300,1666.448853 
   C318.986786,1673.427002 322.908234,1680.374878 326.748840,1687.367798 
   C327.618744,1688.951660 328.905975,1690.380493 328.222656,1692.703247 
   C321.312347,1693.000000 314.624725,1693.000000 307.468536,1693.000000 
   C300.857574,1684.278687 296.328400,1674.621582 291.072540,1665.389404 
   C279.803680,1645.594727 268.877533,1625.606445 257.770020,1605.720337 
   C256.884003,1604.134033 255.796127,1602.619141 255.902222,1600.355469 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M472.468567,1693.000000 
   C471.464294,1687.806030 467.968842,1683.957153 465.617859,1679.668823 
   C451.316406,1653.582031 436.730133,1627.651489 422.104614,1601.348633 
   C428.232971,1598.786011 434.713226,1600.582520 441.533997,1600.000000 
   C449.559326,1613.424683 457.141541,1626.835205 464.666229,1640.277710 
   C474.377808,1657.627075 484.043427,1675.002075 493.864441,1692.683105 
   C486.979034,1693.000000 479.958099,1693.000000 472.468567,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M614.468506,1693.000000 
   C610.396179,1683.132812 604.398071,1674.510620 599.441406,1665.359619 
   C589.021545,1646.122192 578.155273,1627.126587 567.468994,1608.033447 
   C566.093140,1605.575195 564.697998,1603.127808 563.201782,1600.356689 
   C569.728088,1600.028564 576.364441,1600.019165 583.460876,1600.020020 
   C600.010559,1628.837769 616.106567,1657.641479 632.174683,1686.460815 
   C633.220886,1688.337158 634.053528,1690.332764 634.992798,1692.636475 
   C628.312378,1693.000000 621.624695,1693.000000 614.468506,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M425.468536,1693.000000 
   C421.387451,1683.136353 415.396088,1674.510620 410.435638,1665.360840 
   C400.008087,1646.126709 389.151184,1627.125244 378.467072,1608.030273 
   C377.091400,1605.571655 375.695282,1603.124756 374.196411,1600.353882 
   C380.722931,1600.026978 387.361725,1600.018433 394.460693,1600.020142 
   C411.010437,1628.837769 427.106445,1657.641602 443.174683,1686.460815 
   C444.220795,1688.337158 445.053467,1690.332764 445.992737,1692.636475 
   C439.312347,1693.000000 432.624725,1693.000000 425.468536,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M661.468567,1693.000000 
   C660.475342,1687.800171 656.968018,1683.956177 654.616760,1679.667358 
   C640.314758,1653.579590 625.726868,1627.648315 611.101807,1601.343140 
   C610.873352,1599.473633 612.038147,1598.963257 613.150940,1598.872559 
   C618.835571,1598.408691 624.551331,1597.570312 630.576172,1599.757324 
   C638.559265,1613.424927 646.141602,1626.835571 653.666443,1640.278320 
   C663.377930,1657.627441 673.043396,1675.002319 682.864380,1692.683105 
   C675.979065,1693.000000 668.958069,1693.000000 661.468567,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M685.468506,1693.000000 
   C681.651917,1683.928467 676.143372,1675.981079 671.559265,1667.568115 
   C659.345520,1645.152954 646.730713,1622.956421 634.166931,1600.346191 
   C640.388977,1597.222046 646.844910,1598.134888 653.734863,1599.624268 
   C658.696655,1605.252197 661.365173,1611.757446 664.773499,1617.788818 
   C678.491821,1642.064575 691.920532,1666.504150 705.446228,1690.888794 
   C705.684082,1691.317627 705.796936,1691.815674 705.984009,1692.640747 
   C699.312378,1693.000000 692.624695,1693.000000 685.468506,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M709.468506,1693.000000 
   C701.035522,1678.467407 693.110962,1663.912842 685.096008,1649.408081 
   C676.112427,1633.150757 667.047058,1616.938477 657.959717,1600.353760 
   C663.960938,1597.184204 670.160095,1598.193237 676.795715,1599.572998 
   C680.504517,1601.253906 681.033020,1604.764648 682.499512,1607.372070 
   C698.387756,1635.621094 714.043762,1664.000610 729.881592,1692.672241 
   C723.312378,1693.000000 716.624695,1693.000000 709.468506,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M1031.735107,1599.624146 
   C1036.694946,1605.254272 1039.365845,1611.759033 1042.774536,1617.791016 
   C1056.492432,1642.066162 1069.920288,1666.505249 1083.445435,1690.889404 
   C1083.683350,1691.318237 1083.796631,1691.816040 1083.984009,1692.640869 
   C1077.312378,1693.000000 1070.624634,1693.000000 1063.468506,1693.000000 
   C1059.651978,1683.927979 1054.142212,1675.980713 1049.558228,1667.567261 
   C1037.345703,1645.151855 1024.730469,1622.956055 1012.166870,1600.346191 
   C1018.388977,1597.221924 1024.845093,1598.135010 1031.735107,1599.624146 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M1008.576172,1599.757324 
   C1016.559082,1613.424683 1024.141357,1626.835205 1031.666016,1640.277832 
   C1041.377563,1657.627075 1051.043335,1675.002075 1060.864502,1692.682983 
   C1053.979004,1693.000000 1046.958130,1693.000000 1039.468506,1693.000000 
   C1038.464233,1687.805786 1034.968750,1683.956543 1032.617554,1679.667969 
   C1018.314819,1653.580200 1003.727051,1627.648682 989.101929,1601.343018 
   C988.873779,1599.473389 990.038696,1598.963257 991.151428,1598.872437 
   C996.835938,1598.408203 1002.551575,1597.570679 1008.576172,1599.757324 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M779.468567,1693.000000 
   C779.358582,1691.006348 778.235962,1689.465210 777.355896,1687.889404 
   C761.960693,1660.322876 746.539490,1632.770996 731.142883,1605.205200 
   C730.334290,1603.757568 729.694397,1602.215820 728.912842,1600.360107 
   C735.218567,1597.270264 741.675781,1598.002441 748.587036,1599.749268 
   C755.966553,1612.234863 762.971130,1624.448730 769.843201,1636.736572 
   C780.195496,1655.247437 790.454956,1673.810303 800.876953,1692.675537 
   C793.979065,1693.000000 786.958069,1693.000000 779.468567,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M803.468506,1693.000000 
   C799.396545,1683.133301 793.398621,1674.511719 788.442383,1665.361206 
   C778.023438,1646.124878 767.157227,1627.130737 756.472351,1608.038452 
   C755.096680,1605.580322 753.707581,1603.129883 752.214783,1600.354492 
   C758.703796,1597.271240 765.393555,1598.038086 772.518311,1599.774170 
   C788.947205,1628.687988 804.966858,1657.342407 820.955566,1686.013916 
   C822.082642,1688.034912 822.980835,1690.183594 823.992798,1692.636475 
   C817.312378,1693.000000 810.624695,1693.000000 803.468506,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M961.518311,1599.774292 
   C977.947205,1628.688110 993.966919,1657.342407 1009.955566,1686.014038 
   C1011.082581,1688.035034 1011.980896,1690.183594 1012.992798,1692.636475 
   C1006.312378,1693.000000 999.624695,1693.000000 992.468506,1693.000000 
   C988.477722,1683.279297 982.566711,1674.796753 977.696472,1665.788696 
   C966.897827,1645.815308 955.643188,1626.088257 944.566223,1606.265503 
   C943.515686,1604.385498 942.406616,1602.538208 941.214783,1600.354492 
   C947.703857,1597.271484 954.393494,1598.037842 961.518311,1599.774292 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M874.468506,1693.000000 
   C870.651917,1683.928467 865.143372,1675.981079 860.559204,1667.568115 
   C848.345520,1645.152954 835.730713,1622.956421 823.166992,1600.346191 
   C829.388916,1597.221924 835.844910,1598.134888 842.734924,1599.624023 
   C847.696533,1605.252075 850.365173,1611.757324 853.773438,1617.788696 
   C867.491760,1642.064575 880.920532,1666.504028 894.446228,1690.888794 
   C894.684021,1691.317627 894.796875,1691.815674 894.984009,1692.640747 
   C888.312378,1693.000000 881.624695,1693.000000 874.468506,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M850.468567,1693.000000 
   C849.475403,1687.800171 845.968140,1683.956055 843.616882,1679.667358 
   C829.314819,1653.579468 814.726868,1627.648438 800.101807,1601.343140 
   C799.873352,1599.473633 801.038147,1598.963135 802.150940,1598.872437 
   C807.835571,1598.408569 813.551331,1597.570312 819.576172,1599.757324 
   C827.559204,1613.424927 835.141602,1626.835571 842.666443,1640.278320 
   C852.377930,1657.627441 862.043396,1675.002319 871.864380,1692.683105 
   C864.979065,1693.000000 857.958069,1693.000000 850.468567,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M543.468567,1693.000000 
   C540.955139,1685.533936 536.279053,1679.375000 532.659607,1672.726807 
   C519.711365,1648.943115 506.394165,1625.360596 493.068939,1601.388184 
   C499.178772,1598.729370 505.672424,1600.607178 512.490845,1600.012085 
   C529.416565,1629.541260 545.876404,1659.065186 562.348145,1688.582520 
   C563.073486,1689.882446 563.906494,1691.122192 564.844666,1692.695068 
   C557.979065,1693.000000 550.958069,1693.000000 543.468567,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M279.703064,1600.313232 
   C286.024261,1599.974609 292.146545,1599.947998 298.748138,1599.862549 
   C302.108521,1599.027954 302.502380,1601.600098 303.381409,1603.138428 
   C312.710236,1619.464478 321.788513,1635.931885 331.003998,1652.322266 
   C337.283661,1663.490845 343.400299,1674.751221 349.697388,1685.911011 
   C350.833954,1687.925293 352.208374,1689.866943 352.146729,1692.658813 
   C345.312347,1693.000000 338.624725,1693.000000 331.468536,1693.000000 
   C325.746460,1687.500977 323.013123,1680.427734 319.396698,1673.951538 
   C306.694946,1651.205566 294.036407,1628.434448 281.446045,1605.625977 
   C280.588806,1604.073120 279.233551,1602.644165 279.703064,1600.313232 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M898.468506,1693.000000 
   C890.035522,1678.467407 882.111023,1663.912720 874.096008,1649.408081 
   C865.112427,1633.150757 856.046997,1616.938477 846.959717,1600.353760 
   C852.960938,1597.184204 859.160095,1598.193237 865.795715,1599.572998 
   C869.504517,1601.253906 870.032898,1604.764771 871.499451,1607.372070 
   C887.387817,1635.620972 903.043823,1664.000610 918.881592,1692.672241 
   C912.312378,1693.000000 905.624695,1693.000000 898.468506,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M890.541809,1599.765625 
   C907.504089,1629.685303 924.042786,1659.353882 940.592590,1689.016235 
   C941.237000,1690.171265 941.987915,1691.266724 942.844604,1692.695068 
   C935.979065,1693.000000 928.958069,1693.000000 921.468567,1693.000000 
   C918.959839,1685.530396 914.278198,1679.373291 910.657959,1672.724365 
   C897.706787,1648.938354 884.385559,1625.353882 871.061523,1601.375000 
   C870.881165,1599.234985 872.340271,1598.822144 873.569946,1598.766113 
   C879.101440,1598.513794 884.686401,1597.511353 890.541809,1599.765625 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M913.716064,1599.637451 
   C920.525269,1609.408936 925.609924,1619.674194 931.220520,1629.628906 
   C942.945190,1650.431763 954.387573,1671.393799 965.972900,1692.645264 
   C959.312378,1693.000000 952.624695,1693.000000 945.468506,1693.000000 
   C940.419739,1682.314453 934.075439,1672.573242 928.526245,1662.410156 
   C917.245056,1641.749023 905.689819,1621.237427 894.134460,1600.335693 
   C900.362305,1597.235229 906.822998,1598.119141 913.716064,1599.637451 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M323.490845,1600.012085 
   C340.497192,1629.686523 357.037598,1659.355591 373.589355,1689.018433 
   C374.233795,1690.173340 374.986938,1691.267578 375.844727,1692.695068 
   C368.979034,1693.000000 361.958099,1693.000000 354.468567,1693.000000 
   C346.267365,1680.562012 339.580658,1667.533203 332.433777,1654.764893 
   C323.495850,1638.796875 314.418243,1622.906494 305.689575,1606.821289 
   C304.814850,1605.209351 303.771210,1603.688232 303.827026,1601.418701 
   C310.196991,1598.736694 316.680542,1600.603638 323.490845,1600.012085 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M378.468536,1693.000000 
   C373.417542,1682.315186 367.074799,1672.572510 361.525421,1662.409058 
   C350.244324,1641.748047 338.688812,1621.236816 327.131714,1600.336426 
   C333.400574,1599.997314 339.784058,1599.985596 346.648743,1599.943359 
   C353.536835,1609.411011 358.613586,1619.680542 364.223846,1629.634644 
   C375.947479,1650.435547 387.388641,1671.395874 398.972931,1692.645264 
   C392.312347,1693.000000 385.624725,1693.000000 378.468536,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M114.827148,1601.417969 
   C121.198135,1598.737061 127.682190,1600.602905 134.495239,1600.012207 
   C136.997025,1600.532104 137.734070,1602.354370 138.582138,1603.898438 
   C154.146057,1632.236450 170.002228,1660.411865 185.718719,1688.664795 
   C186.358673,1689.815308 187.117752,1690.936279 187.130066,1692.653320 
   C179.979050,1693.000000 172.958099,1693.000000 165.468567,1693.000000 
   C159.647659,1685.342041 155.637619,1676.905151 151.098572,1668.783569 
   C140.139923,1649.176270 129.192169,1629.562500 118.236496,1609.953247 
   C116.787262,1607.359253 115.039940,1604.858398 114.827148,1601.417969 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M732.468567,1693.000000 
   C729.960144,1685.530273 725.278259,1679.373047 721.658020,1672.724121 
   C708.706909,1648.938110 695.385620,1625.353760 682.061523,1601.375000 
   C681.881531,1599.234497 683.341370,1598.821655 684.571167,1598.765747 
   C690.104370,1598.513794 695.691223,1597.510864 701.549927,1599.763184 
   C718.510498,1629.682007 735.045959,1659.352295 751.592590,1689.016235 
   C752.236816,1690.171265 752.987915,1691.266724 753.844604,1692.695068 
   C746.979065,1693.000000 739.958069,1693.000000 732.468567,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M520.468506,1693.000000 
   C512.036926,1678.470093 504.113251,1663.918335 496.100281,1649.415894 
   C487.118622,1633.160522 478.056213,1616.949585 468.963867,1600.369385 
   C475.007599,1599.995117 481.116486,1599.970825 487.711182,1599.883545 
   C491.502380,1601.224976 492.023590,1604.749634 493.489502,1607.355835 
   C509.381104,1635.610229 525.040527,1663.995117 540.881592,1692.672241 
   C534.312378,1693.000000 527.624695,1693.000000 520.468506,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M567.468506,1693.000000 
   C562.420715,1682.313843 556.075378,1672.572998 550.526001,1662.409912 
   C539.244324,1641.748779 527.687805,1621.237671 516.131042,1600.336914 
   C522.400879,1599.997314 528.784180,1599.985596 535.648743,1599.943359 
   C542.543274,1609.406860 547.610962,1619.681274 553.222351,1629.634277 
   C564.948914,1650.433960 576.388428,1671.395386 587.972900,1692.645264 
   C581.312378,1693.000000 574.624695,1693.000000 567.468506,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M1134.468506,1693.000000 
   C1129.301270,1682.188599 1122.907715,1672.287231 1117.266602,1661.984741 
   C1106.040649,1641.482300 1094.600830,1621.097046 1083.135254,1600.335205 
   C1089.362183,1597.235962 1095.822998,1598.118774 1102.716309,1599.637451 
   C1109.524170,1609.410278 1114.611084,1619.674683 1120.221436,1629.629883 
   C1131.945312,1650.432861 1143.387817,1671.394287 1154.972900,1692.645264 
   C1148.312378,1693.000000 1141.624634,1693.000000 1134.468506,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M756.468506,1693.000000 
   C751.419739,1682.314453 745.075439,1672.573242 739.526306,1662.410156 
   C728.245056,1641.749023 716.689819,1621.237549 705.134460,1600.335693 
   C711.362305,1597.235229 717.822998,1598.119141 724.716064,1599.637451 
   C731.525208,1609.408936 736.610046,1619.674072 742.220581,1629.628662 
   C753.945251,1650.431641 765.387512,1671.393677 776.972900,1692.645264 
   C770.312378,1693.000000 763.624695,1693.000000 756.468506,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M1110.468506,1693.000000 
   C1107.954956,1685.532837 1103.278442,1679.373047 1099.658081,1672.724243 
   C1086.706421,1648.938599 1073.385498,1625.353882 1060.061523,1601.375000 
   C1059.881226,1599.235107 1061.340088,1598.821777 1062.569824,1598.765747 
   C1068.101318,1598.513916 1073.686401,1597.511230 1079.541870,1599.765503 
   C1096.503052,1629.686035 1113.040649,1659.355469 1129.589600,1689.018433 
   C1130.233887,1690.173340 1130.986938,1691.267578 1131.844604,1692.695068 
   C1124.979004,1693.000000 1117.958130,1693.000000 1110.468506,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M1054.795654,1599.572998 
   C1058.504517,1601.253662 1059.032959,1604.764648 1060.499390,1607.371948 
   C1076.387817,1635.620972 1092.043823,1664.000488 1107.881592,1692.672241 
   C1101.312378,1693.000000 1094.624634,1693.000000 1087.468506,1693.000000 
   C1079.035645,1678.467773 1071.111328,1663.913452 1063.096436,1649.409180 
   C1054.112671,1633.151489 1045.046997,1616.938843 1035.959717,1600.353760 
   C1041.960938,1597.184448 1048.160034,1598.193115 1054.795654,1599.572998 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M449.468536,1693.000000 
   C444.594513,1682.965820 438.603455,1673.786255 433.383118,1664.193237 
   C421.812256,1642.930176 409.951477,1621.824951 398.094849,1600.322754 
   C404.371521,1599.986450 410.762238,1599.982544 417.632385,1599.953125 
   C425.665741,1613.157471 433.323669,1626.329346 440.747742,1639.631836 
   C450.522278,1657.145874 460.112946,1674.762451 469.891327,1692.667480 
   C463.312347,1693.000000 456.624725,1693.000000 449.468536,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M984.697876,1599.649170 
   C994.741943,1616.927612 1004.429565,1633.904297 1013.975525,1650.960327 
   C1021.666687,1664.702271 1029.186768,1678.539795 1036.891357,1692.667480 
   C1030.312378,1693.000000 1023.624695,1693.000000 1016.468506,1693.000000 
   C1011.594177,1682.965698 1005.603638,1673.785522 1000.382690,1664.192505 
   C988.810608,1642.929443 976.948364,1621.824463 965.089722,1600.323242 
   C971.329834,1597.250732 977.798340,1598.102051 984.697876,1599.649170 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M827.468506,1693.000000 
   C822.593872,1682.965698 816.603638,1673.785278 811.382629,1664.192261 
   C799.810120,1642.929565 787.948303,1621.824341 776.089722,1600.323242 
   C782.329834,1597.250854 788.798279,1598.101685 795.697876,1599.649414 
   C803.667114,1613.148560 811.321533,1626.322998 818.744934,1639.626343 
   C828.519287,1657.142822 838.111938,1674.760620 847.891357,1692.667480 
   C841.312378,1693.000000 834.624695,1693.000000 827.468506,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M638.468506,1693.000000 
   C633.593994,1682.965942 627.603699,1673.785889 622.383179,1664.192749 
   C610.811890,1642.930176 598.951416,1621.824951 587.094849,1600.322876 
   C593.374207,1599.985474 599.767639,1599.980713 606.641602,1599.947754 
   C614.672424,1613.151733 622.325073,1626.327026 629.747864,1639.630493 
   C639.520691,1657.145874 649.112854,1674.762085 658.891357,1692.667480 
   C652.312378,1693.000000 645.624695,1693.000000 638.468506,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M208.840469,1600.335693 
   C215.371887,1599.984497 221.767776,1599.977783 228.638672,1599.948120 
   C231.896759,1600.388550 232.484970,1602.980225 233.534149,1604.872314 
   C248.773331,1632.354858 264.253967,1659.703247 279.353912,1687.263184 
   C280.234558,1688.870361 281.281586,1690.390015 281.146881,1692.659912 
   C274.312347,1693.000000 267.624725,1693.000000 260.468536,1693.000000 
   C254.793121,1686.837646 251.611938,1679.433960 247.732361,1672.493286 
   C235.313110,1650.275391 222.857788,1628.075806 210.647675,1605.740967 
   C209.767685,1604.131226 208.724426,1602.609253 208.840469,1600.335693 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M90.703415,1600.312378 
   C97.024353,1599.973877 103.146629,1599.947510 109.748215,1599.862549 
   C112.556343,1598.962036 113.159737,1600.946289 113.896027,1602.268066 
   C130.088608,1631.338623 146.503342,1660.285522 162.461685,1689.486450 
   C162.950012,1690.379883 163.330246,1691.281250 163.146744,1692.658691 
   C156.312363,1693.000000 149.624710,1693.000000 142.468536,1693.000000 
   C137.965744,1689.715332 136.432709,1684.774048 134.048004,1680.479492 
   C120.188797,1655.521240 106.279732,1630.590576 92.423676,1605.630371 
   C91.558121,1604.071167 90.230659,1602.640381 90.703415,1600.312378 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M43.838051,1601.385254 
   C50.230759,1598.772583 56.713474,1600.589233 63.533775,1600.000000 
   C68.520401,1602.671265 69.631050,1607.709229 71.923759,1611.826172 
   C86.104462,1637.290405 100.502716,1662.633911 114.487221,1688.207153 
   C115.201134,1689.512695 116.090103,1690.758667 116.129623,1692.653320 
   C108.979050,1693.000000 101.958107,1693.000000 94.468582,1693.000000 
   C86.609390,1680.746338 79.931923,1668.089844 72.972763,1655.594360 
   C63.903118,1639.309204 54.742748,1623.074951 45.708206,1606.770264 
   C44.822224,1605.171387 43.737617,1603.663940 43.838051,1601.385254 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M66.905678,1600.356934 
   C73.437744,1599.998535 79.819016,1599.977783 86.677246,1599.923950 
   C89.593674,1600.090332 90.278763,1602.222290 91.203331,1603.858032 
   C99.528587,1618.586426 107.718887,1633.391357 115.908241,1648.195557 
   C123.215698,1661.405273 130.698212,1674.519165 137.805908,1687.838745 
   C138.564484,1689.260254 139.824356,1690.554077 139.220581,1692.702515 
   C132.312347,1693.000000 125.624710,1693.000000 118.468536,1693.000000 
   C112.986969,1686.322510 109.552505,1678.712036 105.492905,1671.486694 
   C93.032776,1649.309570 80.556213,1627.139526 68.417236,1604.782959 
   C67.708565,1603.477661 66.674706,1602.316162 66.905678,1600.356934 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M92.146469,1692.654053 
   C85.312355,1693.000000 78.624710,1693.000000 71.468536,1693.000000 
   C64.941643,1685.211670 60.874424,1676.235474 56.080311,1667.707275 
   C44.837276,1647.707275 33.728996,1627.630615 22.629190,1607.550293 
   C21.283810,1605.116333 18.602659,1602.857300 20.717224,1599.277344 
   C26.693901,1598.017456 32.359295,1598.640747 38.410042,1598.713013 
   C41.072369,1599.091187 42.132225,1600.822144 43.114326,1602.562378 
   C52.567360,1619.312744 61.950481,1636.102295 71.317017,1652.901123 
   C77.533340,1664.050049 83.639885,1675.260132 89.954765,1686.354492 
   C91.013680,1688.214722 92.245399,1690.037231 92.146469,1692.654053 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M1181.471191,1693.000000 
   C1177.478271,1683.278931 1171.566406,1674.796875 1166.696411,1665.788574 
   C1155.898193,1645.814941 1144.643066,1626.088257 1133.566040,1606.265503 
   C1132.515503,1604.385498 1131.406494,1602.538208 1130.214844,1600.354370 
   C1136.703857,1597.271606 1143.393555,1598.037476 1150.518311,1599.774292 
   C1165.583130,1626.278564 1180.228760,1652.528931 1194.891113,1678.769775 
   C1195.289673,1679.483032 1195.867432,1680.095947 1196.680908,1680.877930 
   C1197.000000,1684.935669 1198.546753,1690.692383 1196.604858,1692.341797 
   C1194.228882,1694.359741 1188.929443,1692.938721 1184.894165,1692.997803 
   C1183.910400,1693.012329 1182.926270,1693.000000 1181.471191,1693.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M68.223587,1692.697754 
   C61.312355,1693.000000 54.624710,1693.000000 47.468533,1693.000000 
   C41.718502,1685.502197 37.825798,1677.197998 33.344086,1669.242676 
   C23.878912,1652.441528 14.507298,1635.586670 5.090986,1618.757202 
   C3.626186,1616.139160 2.303484,1613.465332 1.220563,1610.291016 
   C1.000000,1606.969360 1.000000,1603.938843 1.000000,1600.454102 
   C5.432690,1597.363281 10.235763,1598.788330 15.310608,1598.806030 
   C20.467047,1601.027832 21.498989,1606.014282 23.688696,1609.919189 
   C38.136814,1635.685425 52.280273,1661.622437 66.910515,1687.288208 
   C67.804581,1688.856689 68.890465,1690.406738 68.223587,1692.697754 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M303.680969,1600.851318 
   C318.781769,1627.778442 333.632263,1654.479614 348.431824,1681.208984 
   C350.430878,1684.819458 352.173981,1688.571899 354.017822,1692.629272 
   C353.555542,1693.000000 353.111115,1693.000000 352.333313,1693.000000 
   C341.054596,1673.220215 330.122864,1653.432739 319.158112,1633.663696 
   C313.514343,1623.487915 307.813049,1613.344238 302.134033,1603.188110 
   C301.496429,1602.047974 301.182312,1600.672241 299.546326,1599.910156 
   C297.929474,1593.440063 293.843445,1588.408691 290.840942,1582.916992 
   C276.574707,1556.823608 261.991394,1530.903687 247.491196,1504.938599 
   C228.698364,1471.286743 209.839539,1437.671753 191.081055,1404.000854 
   C177.171982,1379.034546 163.428925,1353.975586 149.519257,1329.009521 
   C130.759766,1295.338867 111.872398,1261.739380 93.113853,1228.068115 
   C79.123535,1202.955811 65.284248,1177.759644 51.316254,1152.634888 
   C34.766472,1122.866333 18.150793,1093.134399 1.281827,1063.193359 
   C1.000000,1061.933350 1.000000,1060.866577 1.000000,1059.399902 
   C5.565808,1062.926880 7.340939,1068.660156 10.143326,1073.654419 
   C32.176327,1112.921265 53.958672,1152.328857 75.840401,1191.680542 
   C93.764229,1223.914429 111.698311,1256.142578 129.664261,1288.353027 
   C151.756424,1327.961060 173.921677,1367.528320 195.988312,1407.150635 
   C211.671814,1435.311523 227.224579,1463.545288 242.871780,1491.726440 
   C263.033997,1528.039429 283.232025,1564.332397 303.680969,1600.851318 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M279.639404,1599.794189 
   C288.170807,1615.126831 296.385803,1630.282227 304.723328,1645.369873 
   C313.387054,1661.047852 322.169159,1676.660156 330.949738,1692.650635 
   C330.250000,1693.000000 329.500000,1693.000000 328.375000,1693.000000 
   C325.255066,1685.660400 320.832611,1679.193359 317.099274,1672.381836 
   C304.252045,1648.941772 291.148224,1625.642578 278.129486,1602.296631 
   C277.729858,1601.579956 277.189301,1600.941650 276.435059,1600.076904 
   C270.336243,1589.066406 264.615570,1578.191406 258.674774,1567.437744 
   C249.514740,1550.857056 240.201187,1534.361084 230.957504,1517.826660 
   C213.768005,1487.078979 196.552170,1456.345825 179.405548,1425.574341 
   C168.727982,1406.412354 158.197632,1387.168457 147.528793,1368.001587 
   C133.547791,1342.884277 119.480453,1317.814819 105.478638,1292.708984 
   C95.279297,1274.421265 85.143219,1256.098022 74.948181,1237.807861 
   C59.091156,1209.359985 43.209229,1180.926025 27.335512,1152.487305 
   C18.751532,1137.108643 10.162801,1121.732666 1.288101,1106.177734 
   C1.000000,1104.611084 1.000000,1103.222168 1.000000,1101.416626 
   C5.991270,1106.662231 8.777266,1113.668091 12.417542,1120.124756 
   C30.368320,1151.962891 48.101936,1183.923706 65.861671,1215.869263 
   C79.747833,1240.847290 93.509338,1265.894897 107.418327,1290.860229 
   C124.478683,1321.481934 141.671249,1352.030029 158.737305,1382.648560 
   C170.952377,1404.563843 183.004807,1426.569702 195.217728,1448.486206 
   C210.663498,1476.204224 226.236298,1503.851318 241.683228,1531.568726 
   C254.301926,1554.211304 266.816223,1576.912109 279.639404,1599.794189 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M283.056213,509.365570 
   C276.190918,496.301849 269.362335,483.562408 262.360168,470.919098 
   C251.802658,451.856171 241.121246,432.861877 230.497345,413.835693 
   C214.845779,385.805634 199.172882,357.787445 183.557526,329.737244 
   C171.258438,307.644165 159.062164,285.493866 146.766663,263.398743 
   C131.074738,235.200150 115.314590,207.039536 99.610977,178.847427 
   C87.549034,157.193100 75.549622,135.503922 63.485279,113.850922 
   C47.859673,85.806190 32.204090,57.778160 16.546537,29.751253 
   C11.603664,20.903545 6.613023,12.082525 1.322121,3.124646 
   C0.601308,1.267974 1.267974,0.601308 3.500000,1.000000 
   C10.005258,13.969283 17.595783,26.082918 24.408060,38.615036 
   C34.648613,57.453949 45.349049,76.042480 55.806019,94.764236 
   C71.775154,123.354752 87.724754,151.956223 103.642494,180.575363 
   C117.460716,205.419693 131.187714,230.314743 145.019775,255.151337 
   C160.467697,282.889282 176.015457,310.571625 191.469208,338.306335 
   C203.687149,360.233795 215.802139,382.218628 228.010605,404.151367 
   C243.856781,432.619354 259.747223,461.062714 275.625946,489.512604 
   C279.190948,495.899994 282.786285,502.270477 286.631226,508.860260 
   C290.963745,512.556580 292.566681,517.631226 295.082062,522.077026 
   C303.679108,537.272034 312.178162,552.524109 320.539490,567.851501 
   C320.778473,568.289612 320.866089,568.790466 320.920471,569.656189 
   C321.868164,573.904907 325.150208,576.938171 325.005829,581.616028 
   C322.153931,582.282593 321.559753,579.811951 320.580658,578.082092 
   C311.112061,561.352295 301.786255,544.541748 292.519409,527.699768 
   C289.254944,521.766785 285.408691,516.129822 283.056213,509.365570 
z'
      />
      <path
        fill='#FFFFFF'
        opacity='1.000000'
        stroke='none'
        d='
M1197.000000,1676.531372 
   C1190.341919,1665.356934 1183.599243,1653.761108 1177.047852,1642.058350 
   C1169.348511,1628.305298 1161.815063,1614.459473 1154.096680,1600.322021 
   C1168.010010,1599.993530 1182.036377,1599.996704 1196.531372,1600.000000 
   C1197.000000,1625.354248 1197.000000,1650.708496 1197.000000,1676.531372 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M255.890656,1599.709961 
   C266.662537,1619.146240 277.291687,1638.260498 287.866089,1657.404907 
   C294.273346,1669.004761 300.595734,1680.651733 306.978149,1692.638672 
   C306.555542,1693.000000 306.111115,1693.000000 305.333313,1693.000000 
   C288.261322,1662.874390 271.524231,1632.747803 254.774750,1602.628052 
   C254.373901,1601.907227 253.834427,1601.263428 253.180969,1600.291870 
   C240.546844,1577.652344 228.081497,1555.310059 215.638992,1532.955200 
   C205.213943,1514.225098 194.838013,1495.467773 184.400177,1476.744751 
   C168.786423,1448.737549 153.116928,1420.761719 137.510712,1392.750366 
   C126.997566,1373.880615 116.578491,1354.958496 106.070633,1336.085815 
   C91.846031,1310.537354 77.548706,1285.029297 63.335003,1259.474854 
   C51.138630,1237.547363 39.038361,1215.566528 26.852680,1193.633057 
   C18.461117,1178.528809 9.996316,1163.465088 1.282137,1148.191650 
   C1.000000,1146.933350 1.000000,1145.866577 1.000000,1144.399902 
   C5.365028,1146.813354 6.439716,1151.879395 8.755971,1155.965332 
   C22.853422,1180.834595 36.522957,1205.945801 50.437717,1230.919312 
   C67.505005,1261.550537 84.703339,1292.108765 101.786850,1322.731079 
   C113.771416,1344.213379 125.624588,1365.769287 137.597626,1387.258179 
   C154.988541,1418.470825 172.478790,1449.628296 189.843170,1480.855713 
   C204.059631,1506.421997 218.123520,1532.073120 232.314590,1557.653687 
   C240.054108,1571.604858 247.917374,1585.487183 255.890656,1599.709961 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M306.806274,509.326263 
   C296.491180,490.054047 286.070862,471.108490 275.576294,452.204102 
   C264.678864,432.573944 253.710114,412.983337 242.771637,393.375946 
   C225.349167,362.145966 207.916580,330.921661 190.506058,299.684998 
   C177.063217,275.566895 163.655182,251.429367 150.217987,227.308090 
   C134.271942,198.683136 118.307014,170.068695 102.358284,141.445236 
   C90.861649,120.812004 79.382324,100.169136 67.887634,79.534821 
   C54.936138,56.285389 41.961624,33.048748 29.039873,9.782801 
   C27.589718,7.171761 26.417189,4.406530 25.057957,1.356401 
   C25.750000,1.000000 26.500000,1.000000 27.625000,1.000000 
   C31.096832,10.221444 36.802486,18.099272 41.383091,26.536501 
   C53.602833,49.044621 66.282646,71.302902 78.771790,93.664841 
   C94.342598,121.544533 109.932594,149.413559 125.463417,177.315506 
   C137.841187,199.552856 150.109833,221.850952 162.486328,244.089020 
   C178.096725,272.137756 193.783371,300.144043 209.407120,328.185364 
   C221.470230,349.836121 233.466766,371.524017 245.529709,393.174896 
   C261.153198,421.216339 276.818420,449.234467 292.454651,477.268799 
   C297.721100,486.711090 302.951080,496.173676 308.206238,505.622284 
   C308.689911,506.491943 309.083130,507.908630 309.775513,508.090057 
   C318.452911,510.364197 314.728790,517.686096 315.944519,523.409058 
   C314.440643,524.357239 313.737915,523.205200 313.219360,522.293518 
   C310.875305,518.172546 308.053711,514.290466 306.806274,509.326263 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M281.333313,1693.000000 
   C268.012054,1669.494507 255.034714,1645.983154 242.030029,1622.486938 
   C237.935455,1615.089233 233.767471,1607.732056 229.373398,1600.140625 
   C220.579590,1584.333862 212.118027,1568.702515 203.492584,1553.161987 
   C193.087097,1534.414307 182.525314,1515.753174 172.062408,1497.037231 
   C157.786362,1471.500366 143.518570,1445.958862 129.287781,1420.396851 
   C118.855743,1401.657959 108.526672,1382.861694 98.084251,1364.128662 
   C80.845230,1333.203003 63.515503,1302.327759 46.293381,1271.392700 
   C33.840065,1249.023682 21.512629,1226.584595 9.096366,1204.194824 
   C6.685062,1199.846558 4.122705,1195.581787 1.315002,1191.139282 
   C1.000000,1189.611084 1.000000,1188.222168 1.000000,1186.416626 
   C5.663048,1190.474121 7.744957,1196.582520 10.813148,1202.010376 
   C24.877100,1226.889893 38.546139,1251.992065 52.457611,1276.958252 
   C69.520760,1307.580566 86.732216,1338.120361 103.788185,1368.746582 
   C116.319572,1391.248291 128.644455,1413.865112 141.169128,1436.370605 
   C156.600174,1464.098755 172.173965,1491.747437 187.627853,1519.462891 
   C199.765762,1541.231445 211.824493,1563.044312 223.897781,1584.848877 
   C226.789917,1590.072144 229.599884,1595.341064 232.709015,1600.814087 
   C249.021179,1629.933594 265.069427,1658.829346 281.130768,1687.717773 
   C281.936584,1689.167114 282.956421,1690.510376 283.029602,1692.626221 
   C282.555542,1693.000000 282.111115,1693.000000 281.333313,1693.000000 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M50.666668,1.000000 
   C61.839767,20.656240 72.636353,40.336452 83.531639,59.961872 
   C94.103882,79.005402 104.786766,97.987465 115.404373,117.005844 
   C132.750900,148.077133 150.102982,179.145340 167.416077,210.235260 
   C179.633209,232.174164 191.765289,254.160446 203.979401,276.101044 
   C219.832169,304.577881 235.751114,333.017914 251.599579,361.497162 
   C265.427704,386.345886 279.174622,411.239807 293.002777,436.088531 
   C300.117859,448.874023 307.368256,461.584320 314.468445,474.377991 
   C315.779724,476.740814 317.871643,479.021210 316.335144,482.901459 
   C312.662598,476.443115 309.206482,470.478638 305.856079,464.455383 
   C282.332428,422.165192 258.839203,379.858063 235.319290,337.565765 
   C212.284119,296.145142 189.230301,254.734833 166.182098,213.321442 
   C144.913895,175.106384 123.662918,136.881714 102.365555,98.682930 
   C85.280121,68.038612 68.129608,37.430592 51.039013,6.789151 
   C50.161175,5.215298 49.006908,3.703274 49.010872,1.375486 
   C49.444447,1.000000 49.888889,1.000000 50.666668,1.000000 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M208.810394,1599.694336 
   C220.663940,1621.186035 232.348450,1642.382812 244.041290,1663.575195 
   C249.325104,1673.151855 254.625565,1682.719238 259.959290,1692.645508 
   C259.250000,1693.000000 258.500000,1693.000000 257.375000,1693.000000 
   C254.138321,1684.425049 248.735413,1677.172363 244.497574,1669.326660 
   C233.779266,1649.483521 222.613922,1629.881836 211.625610,1610.184448 
   C209.847748,1606.997559 208.059341,1603.816528 206.103912,1600.330688 
   C196.929504,1583.934448 187.920395,1567.843994 178.925995,1551.745239 
   C164.742157,1526.358154 150.549042,1500.976318 136.396332,1475.571899 
   C126.043106,1456.987671 115.779037,1438.353760 105.420509,1419.772461 
   C88.024124,1388.566528 70.543777,1357.407349 53.176121,1326.185547 
   C40.653782,1303.673950 28.279585,1281.080200 15.792689,1258.548828 
   C11.120758,1250.118774 6.316829,1241.761719 1.286533,1233.185791 
   C1.000000,1231.933350 1.000000,1230.866577 1.000000,1229.399902 
   C4.918624,1231.268433 5.895880,1235.655762 7.872041,1239.165161 
   C23.614641,1267.121216 39.028278,1295.262207 54.639095,1323.292969 
   C76.717636,1362.936890 98.912476,1402.516113 120.992882,1442.158936 
   C136.524033,1470.043579 151.933014,1497.996094 167.423813,1525.903198 
   C180.655106,1549.739624 193.922974,1573.555786 207.189285,1597.372803 
   C207.591064,1598.094238 208.155884,1598.724731 208.810394,1599.694336 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M74.625000,1.000000 
   C77.954033,8.800774 82.644547,15.697799 86.616829,22.954187 
   C98.752296,45.122730 111.196945,67.121994 123.517990,89.188988 
   C139.162552,117.208382 154.826111,145.217209 170.439423,173.254013 
   C182.655746,195.190842 194.775101,217.181686 206.996704,239.115570 
   C224.154587,269.908417 241.393494,300.656158 258.547699,331.451080 
   C270.767029,353.386993 282.903076,375.369293 295.077942,397.329987 
   C303.786316,413.037811 312.502533,428.741333 321.197083,444.456818 
   C321.853241,445.642792 323.778290,447.163239 321.788177,448.174652 
   C320.252563,448.955078 319.581482,446.727295 318.909515,445.517792 
   C301.789307,414.700592 284.735229,383.846527 267.579620,353.049072 
   C247.188477,316.443298 226.680222,279.902771 206.286392,243.298477 
   C192.365326,218.311951 178.600098,193.238586 164.680649,168.251160 
   C147.607437,137.602264 130.389938,107.033699 113.340401,76.371704 
   C99.525520,51.526924 85.866760,26.595337 72.069382,1.351127 
   C72.750000,1.000000 73.500000,1.000000 74.625000,1.000000 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M27.332045,509.328033 
   C18.293756,495.696289 11.417192,481.340881 3.307967,467.689209 
   C2.806138,466.844360 2.196570,466.063507 1.318558,465.126465 
   C1.000000,452.645782 1.000000,440.291534 1.000000,427.468658 
   C6.479713,433.928680 9.920671,442.064270 14.248368,449.662994 
   C25.318825,469.100830 36.042744,488.736084 46.946503,508.643616 
   C40.653522,511.751160 34.205124,510.898315 27.332045,509.328033 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M97.666672,0.999999 
   C107.393074,17.843628 116.782730,34.689163 126.179977,51.530464 
   C141.980530,79.847504 157.796646,108.155876 173.585739,136.479324 
   C185.327194,157.541885 197.027039,178.627655 208.761978,199.693863 
   C224.704086,228.312714 240.675186,256.915405 256.608734,285.539032 
   C268.498657,306.898560 280.350861,328.279114 292.216064,349.652405 
   C304.162476,371.171997 316.120880,392.684967 328.013733,414.234131 
   C328.885284,415.813354 330.457489,417.387512 329.357147,420.041931 
   C325.277405,416.313629 323.721771,411.448761 321.349518,407.234314 
   C305.687439,379.409546 290.346588,351.404297 274.799286,323.514526 
   C256.338165,290.397705 237.732452,257.361481 219.269440,224.245697 
   C205.177704,198.970337 191.256973,173.599655 177.176651,148.317886 
   C158.648346,115.049644 140.024246,81.834740 121.469406,48.581249 
   C113.041313,33.476639 104.677124,18.336351 96.307693,3.199123 
   C96.080956,2.789045 96.127426,2.227904 96.023247,1.368603 
   C96.444443,1.000000 96.888893,1.000000 97.666672,0.999999 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M45.145424,1692.659302 
   C37.979053,1693.000000 30.958105,1693.000000 23.468578,1693.000000 
   C15.709573,1679.915527 7.985795,1667.068115 1.220307,1653.288086 
   C1.000000,1640.645752 1.000000,1628.291504 1.000000,1615.468628 
   C3.476635,1614.812744 3.849116,1617.022705 4.669575,1618.467163 
   C13.711738,1634.386597 22.629606,1650.376465 31.540163,1666.369873 
   C35.671665,1673.785522 39.887676,1681.155640 43.858093,1688.661499 
   C44.480263,1689.837769 45.329292,1690.895386 45.145424,1692.659302 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M184.920639,1599.719727 
   C198.795868,1624.634277 212.522400,1649.225342 226.183563,1673.852783 
   C229.556152,1679.932617 232.716492,1686.130249 235.987183,1692.636841 
   C235.555557,1693.000000 235.111099,1693.000000 234.333313,1693.000000 
   C220.081726,1667.925781 206.165207,1642.850464 192.244049,1617.777832 
   C189.025696,1611.981323 185.791534,1606.193604 182.295807,1600.192871 
   C169.189590,1576.914429 156.342422,1553.850342 143.517975,1530.773560 
   C133.030716,1511.902344 122.603699,1492.997559 112.090889,1474.140503 
   C94.869385,1443.249878 77.571098,1412.402100 60.361378,1381.505005 
   C49.694809,1362.354980 39.181931,1343.119507 28.506983,1323.974243 
   C19.619518,1308.035034 10.596368,1292.171265 1.316647,1276.137085 
   C1.000000,1274.611084 1.000000,1273.222168 1.000000,1271.416626 
   C5.767885,1275.810059 8.060690,1282.167847 11.291380,1287.895264 
   C30.644798,1322.206055 49.798367,1356.629883 68.941795,1391.058716 
   C83.160286,1416.630249 97.192307,1442.305664 111.432983,1467.864746 
   C129.877579,1500.969116 148.466904,1533.992798 166.951447,1567.074951 
   C172.952301,1577.814819 178.819473,1588.629395 184.920639,1599.719727 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M121.666672,1.000000 
   C131.524582,18.347738 140.999969,35.722729 150.586823,53.035980 
   C161.382690,72.532654 172.274658,91.976158 183.132751,111.438362 
   C198.934341,139.761322 214.762405,168.069534 230.542892,196.404251 
   C244.301453,221.108505 257.987915,245.852905 271.746185,270.557312 
   C287.283203,298.455627 302.871735,326.325256 318.423859,354.215149 
   C325.309418,366.563110 332.165222,378.927673 339.018799,391.293427 
   C339.566498,392.281586 340.277802,393.311981 339.183167,394.755157 
   C336.280945,393.534668 335.627686,390.502502 334.380066,388.281830 
   C320.448425,363.484314 306.752014,338.554779 292.911713,313.705688 
   C277.454010,285.952698 261.883759,258.262421 246.417709,230.514084 
   C232.650818,205.814301 218.985367,181.057999 205.227753,156.353027 
   C189.691360,128.453827 174.076355,100.598389 158.543854,72.697021 
   C146.246155,50.606377 134.014786,28.478786 121.779709,6.353368 
   C120.985672,4.917445 119.949570,3.535814 120.004211,1.377270 
   C120.444443,1.000000 120.888893,1.000000 121.666672,1.000000 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M145.625000,1.000000 
   C149.062469,9.493175 154.228073,16.897795 158.493317,24.753286 
   C168.809235,43.752567 179.551651,62.520050 190.094879,81.396194 
   C205.910751,109.712196 221.728149,138.027405 237.497757,166.369171 
   C249.872116,188.608826 262.141510,210.906860 274.518280,233.145187 
   C290.129059,261.194366 305.812195,289.203247 321.443512,317.240997 
   C331.001617,334.385406 340.514771,351.554871 350.071167,368.700256 
   C350.745392,369.909821 351.393860,371.030304 350.091919,372.688232 
   C346.214600,369.280518 344.709991,364.400421 342.361115,360.229706 
   C326.689484,332.402435 311.342865,304.392334 295.805573,276.489044 
   C278.651276,245.681732 261.367126,214.946747 244.211624,184.140106 
   C228.673218,156.237305 213.274353,128.256790 197.736328,100.353767 
   C180.662033,69.691879 163.491104,39.083817 146.391632,8.435920 
   C145.179062,6.262566 144.208038,3.954438 143.062347,1.354490 
   C143.750000,1.000000 144.500000,1.000000 145.625000,1.000000 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M210.333328,1693.000000 
   C204.105545,1680.371826 196.698639,1668.562988 190.067520,1656.334106 
   C180.656189,1638.977905 170.897797,1621.809814 161.270538,1604.570923 
   C160.462524,1603.124023 159.524597,1601.749634 158.390350,1600.125244 
   C152.611694,1589.680176 147.183411,1579.399292 141.546219,1569.234375 
   C132.758255,1553.388306 123.804878,1537.634033 114.964081,1521.817139 
   C99.066025,1493.374390 83.171806,1464.929443 67.329277,1436.455688 
   C56.577667,1417.131836 45.960846,1397.733032 35.203659,1378.412354 
   C24.043913,1358.368652 12.787709,1338.378540 1.286843,1318.182495 
   C1.000000,1316.611084 1.000000,1315.222168 1.000000,1313.416626 
   C6.393816,1319.796387 9.786168,1327.776001 14.012887,1335.250977 
   C28.326172,1360.563965 42.435577,1385.992310 56.587772,1411.396118 
   C67.021706,1430.125732 77.372086,1448.901855 87.811600,1467.628296 
   C101.728622,1492.592773 115.717018,1517.517212 129.634888,1542.481079 
   C140.236710,1561.497070 150.776443,1580.547607 161.596039,1599.792480 
   C166.807159,1610.903198 173.251572,1620.998413 178.988647,1631.475952 
   C189.520782,1650.710693 200.293793,1669.813477 210.931137,1688.990845 
   C211.481644,1689.983154 211.702957,1691.158081 212.038773,1692.624023 
   C211.555557,1693.000000 211.111099,1693.000000 210.333328,1693.000000 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M40.305088,1599.605835 
   C33.714348,1599.948364 27.315079,1599.972046 20.445841,1599.993408 
   C16.642971,1598.920898 16.044918,1595.639404 14.655742,1593.120117 
   C10.169043,1584.983276 5.015187,1577.200195 1.220469,1568.286133 
   C1.000000,1555.645752 1.000000,1543.291504 1.000000,1530.468628 
   C2.782932,1529.635132 3.263715,1531.108032 3.877397,1532.184692 
   C16.109915,1553.644409 28.120981,1575.227783 39.939648,1596.918213 
   C40.345997,1597.663940 40.621517,1598.424194 40.305088,1599.605835 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M168.666687,0.999997 
   C181.258621,23.097908 193.497879,45.206589 205.781784,67.290443 
   C216.208191,86.034966 226.687927,104.749832 237.140900,123.479584 
   C252.941513,151.791321 268.756927,180.094803 284.532776,208.420319 
   C296.587921,230.065308 308.572479,251.749588 320.623352,273.396942 
   C333.320557,296.205444 346.107269,318.964355 358.721405,341.818604 
   C360.372833,344.810608 362.788544,347.679230 362.654022,352.481018 
   C358.287079,348.114197 356.566986,343.169067 354.117004,338.800140 
   C333.784088,302.541809 313.690643,266.149353 293.462372,229.832214 
   C269.675446,187.126007 245.814880,144.460815 222.007294,101.766106 
   C204.029556,69.526199 186.080017,37.270573 168.148956,5.004700 
   C167.599701,4.016369 167.396072,2.835980 167.015656,1.372568 
   C167.444443,1.000000 167.888901,1.000000 168.666687,0.999997 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M192.625000,1.000000 
   C199.098846,14.117264 206.773544,26.381639 213.683350,39.058693 
   C224.109985,58.187904 234.886948,77.126099 245.507507,96.149666 
   C261.154541,124.176598 276.808746,152.199539 292.433685,180.238770 
   C304.335632,201.597031 316.184875,222.984695 328.079254,244.347183 
   C343.775421,272.537628 359.492920,300.716187 375.200134,328.900452 
   C375.604980,329.626862 376.001343,330.358124 376.394196,331.091095 
   C376.849243,331.940155 376.767456,332.792999 375.934662,333.251373 
   C374.864838,333.840210 374.490051,332.773041 374.115845,332.131226 
   C371.687561,327.966522 369.265900,323.796692 366.925232,319.582367 
   C346.331116,282.502533 325.781982,245.397690 305.165192,208.330460 
   C282.528290,167.631210 259.848053,126.956032 237.159485,86.285530 
   C221.765167,58.690331 206.322662,31.122009 190.912766,3.535482 
   C190.591324,2.960043 190.405029,2.309100 190.077850,1.346689 
   C190.750000,1.000000 191.500000,1.000000 192.625000,1.000000 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M187.333328,1693.000000 
   C178.150772,1677.258911 169.277466,1661.531250 160.459183,1645.772949 
   C152.045151,1630.736816 143.688354,1615.668823 135.133789,1600.316406 
   C121.104279,1575.205811 107.225441,1550.406128 93.397064,1525.578247 
   C81.344826,1503.939209 69.376915,1482.253052 57.321995,1460.615601 
   C43.408356,1435.641846 29.425488,1410.706665 15.503666,1385.737427 
   C10.807735,1377.315063 6.206589,1368.839722 1.281051,1360.194336 
   C1.000000,1358.933350 1.000000,1357.866577 1.000000,1356.399902 
   C5.560461,1359.924072 7.340068,1365.649414 10.136045,1370.640747 
   C32.525402,1410.611084 54.649483,1450.729980 76.911125,1490.771851 
   C94.492683,1522.395752 112.147827,1553.978760 129.787003,1585.570435 
   C132.375015,1590.205566 135.055740,1594.789062 137.856888,1599.701660 
   C149.313171,1620.464233 160.608566,1640.919922 171.898224,1661.378906 
   C177.581543,1671.677979 183.254517,1681.982788 188.966095,1692.642456 
   C188.555557,1693.000000 188.111099,1693.000000 187.333328,1693.000000 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M216.625000,1.000000 
   C220.096924,10.221538 225.802628,18.099476 230.383301,26.536808 
   C242.603256,49.045258 255.283173,71.303932 267.772522,93.666214 
   C283.343597,121.546356 298.933990,149.415802 314.465088,177.318207 
   C326.842957,199.555725 339.113159,221.853149 351.488770,244.091919 
   C363.782104,266.182709 376.182709,288.213959 388.415863,310.337891 
   C389.419128,312.152344 392.364441,314.800293 390.052246,316.200439 
   C388.146545,317.354523 387.447235,313.510773 386.482422,311.789795 
   C362.975616,269.860168 339.497101,227.914505 316.098511,185.924377 
   C298.770844,154.828888 281.603333,123.644203 264.289703,92.540871 
   C248.919586,64.928947 233.433990,37.381306 218.039444,9.782944 
   C216.584595,7.174804 215.416336,4.406794 214.057510,1.356503 
   C214.750000,1.000000 215.500000,1.000000 216.625000,1.000000 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M239.666672,0.999998 
   C250.837158,20.652756 261.630585,40.329784 272.523926,59.951355 
   C283.094208,78.991096 293.777252,97.968216 304.392609,116.982979 
   C321.735474,148.048431 339.085083,179.110107 356.390686,210.196304 
   C368.683441,232.277832 380.907715,254.397461 393.163879,276.499359 
   C396.954010,283.334137 400.735687,290.173645 404.551605,296.994019 
   C405.328583,298.382751 406.770782,299.607788 405.048462,301.690948 
   C401.215271,298.200958 399.670410,293.341827 397.310181,289.149048 
   C381.650543,261.330719 366.306519,233.335037 350.774994,205.444290 
   C332.009796,171.746719 313.130890,138.112473 294.357666,104.419388 
   C280.519196,79.582939 266.782288,54.689930 252.976196,29.835417 
   C248.859604,22.424486 244.631485,15.075469 240.523468,7.659853 
   C239.489090,5.792654 238.106903,4.035214 238.010864,1.375486 
   C238.444443,1.000000 238.888901,1.000000 239.666672,0.999998 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M263.625000,1.000000 
   C266.952637,8.798251 271.640930,15.693385 275.612122,22.947069 
   C287.664398,44.961399 300.030182,66.804070 312.268585,88.716522 
   C328.073608,117.014870 343.892487,145.305496 359.669006,173.619736 
   C370.025330,192.206406 380.297241,210.840088 390.643921,229.432129 
   C400.020050,246.280243 409.474609,263.084778 418.817352,279.951294 
   C420.095612,282.258942 421.828400,284.528687 421.834076,288.262573 
   C417.893707,285.796234 417.037079,282.192017 415.438446,279.344360 
   C401.116241,253.832413 387.070282,228.165588 372.844116,202.599396 
   C357.407043,174.857071 341.827148,147.194244 326.378296,119.458458 
   C312.465240,94.480049 298.641388,69.451942 284.785706,44.441597 
   C276.895599,30.199461 269.020569,15.948978 261.069397,1.351127 
   C261.750000,1.000000 262.500000,1.000000 263.625000,1.000000 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M163.333328,1693.000000 
   C152.054642,1673.220215 141.122955,1653.432739 130.158279,1633.663696 
   C124.514412,1623.487915 118.813187,1613.344238 113.134109,1603.188110 
   C112.496468,1602.047974 112.182777,1600.671997 110.546432,1599.910156 
   C108.932205,1593.444458 104.847862,1588.417725 101.847488,1582.930664 
   C87.591652,1556.859131 72.992249,1530.975586 58.535397,1505.013794 
   C40.362766,1472.379150 22.213043,1439.731812 4.035430,1407.100098 
   C3.310610,1405.798950 2.400178,1404.601318 1.288116,1403.177734 
   C1.000000,1401.611084 1.000000,1400.222168 1.000000,1398.416626 
   C5.992839,1403.664917 8.780514,1410.673462 12.422144,1417.133057 
   C30.379990,1448.986328 48.120914,1480.962280 65.887627,1512.923096 
   C79.779373,1537.913452 93.598679,1562.944092 107.445663,1587.959229 
   C109.780602,1592.177368 112.092567,1596.408325 114.681320,1600.850586 
   C129.781952,1627.778198 144.632477,1654.479370 159.432022,1681.208862 
   C161.431061,1684.819580 163.174088,1688.571899 165.017853,1692.629395 
   C164.555557,1693.000000 164.111099,1693.000000 163.333328,1693.000000 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M139.375000,1693.000000 
   C133.926987,1683.514404 128.969635,1673.964722 123.756966,1664.556519 
   C112.979996,1645.105469 102.071487,1625.727417 91.203651,1606.326782 
   C90.069420,1604.301880 88.844864,1602.327637 87.408234,1600.110352 
   C81.425499,1589.234009 75.801086,1578.519653 69.943878,1567.934082 
   C60.949043,1551.677979 51.765743,1535.526489 42.713802,1519.301880 
   C28.974268,1494.675049 15.278680,1470.023926 1.282218,1445.191528 
   C1.000000,1443.933350 1.000000,1442.866577 1.000000,1441.399902 
   C5.488252,1444.139893 6.728005,1449.453613 9.209062,1453.834351 
   C23.280014,1478.678955 36.977840,1503.734619 50.850384,1528.691772 
   C63.995693,1552.340820 77.199791,1575.957031 90.640091,1599.793945 
   C98.864243,1614.534668 106.783760,1629.092529 114.799896,1643.597046 
   C123.786179,1659.857178 132.859772,1676.068848 141.947693,1692.650879 
   C141.250000,1693.000000 140.500000,1693.000000 139.375000,1693.000000 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M66.890640,1599.709961 
   C80.759666,1624.627930 94.486145,1649.223633 108.149483,1673.854370 
   C111.523567,1679.936768 114.692657,1686.132690 117.978004,1692.638184 
   C117.555557,1693.000000 117.111107,1693.000000 116.333359,1693.000000 
   C107.161957,1677.253784 98.303574,1661.518921 89.491692,1645.758057 
   C81.083336,1630.718994 72.723488,1615.652832 64.172348,1600.299316 
   C51.559082,1577.668823 39.101772,1555.345337 26.677963,1533.003418 
   C19.254118,1519.652954 11.890172,1506.269409 4.477620,1492.912720 
   C3.598731,1491.328979 2.582515,1489.821411 1.314984,1488.139282 
   C1.000000,1486.611084 1.000000,1485.222168 1.000000,1483.416626 
   C5.648664,1487.469604 7.735359,1493.562256 10.798310,1498.980225 
   C24.838303,1523.815308 38.525734,1548.849731 52.366112,1573.797852 
   C57.112801,1582.354004 61.936317,1590.867554 66.890640,1599.709961 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M40.374813,1600.142090 
   C31.411997,1584.108032 22.732086,1568.279419 13.999236,1552.479980 
   C9.909742,1545.081421 5.718287,1537.739258 1.286518,1530.185791 
   C1.000000,1528.933350 1.000000,1527.866577 1.000000,1526.399902 
   C5.071413,1528.392822 5.892890,1533.004761 7.960654,1536.662964 
   C19.952826,1557.879395 31.650459,1579.262329 43.709045,1600.814087 
   C59.816578,1629.473022 75.669777,1657.902710 91.493210,1686.349121 
   C92.534050,1688.220215 93.833527,1690.008545 94.025955,1692.624390 
   C93.555557,1693.000000 93.111107,1693.000000 92.333321,1693.000000 
   C79.404778,1670.224976 66.818237,1647.445190 54.209789,1624.677490 
   C49.712486,1616.556396 45.162163,1608.464600 40.374813,1600.142090 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M3.451874,509.237274 
   C2.551052,508.646057 2.076248,508.305908 1.300722,507.982910 
   C1.000000,495.312439 1.000000,482.624878 1.000000,469.468658 
   C8.919888,481.768066 15.959609,495.029541 23.078751,508.638611 
   C16.783854,511.722778 10.340896,510.998474 3.451874,509.237274 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M1.000000,1568.416626 
   C6.007224,1573.406616 8.384095,1580.404419 12.188610,1586.540649 
   C14.798061,1590.749390 17.168011,1595.106812 19.810463,1599.694336 
   C31.270269,1620.454712 42.564640,1640.918335 53.859066,1661.381958 
   C59.545010,1671.683838 65.231049,1681.985718 70.958542,1692.643799 
   C70.250000,1693.000000 69.500000,1693.000000 68.375000,1693.000000 
   C65.509850,1685.356567 60.683403,1678.922607 56.897968,1671.968750 
   C43.894417,1648.080688 30.511549,1624.399292 17.104374,1600.334229 
   C16.105730,1599.247559 14.865481,1598.947754 14.293034,1597.875610 
   C9.976128,1589.790771 4.636067,1582.246460 1.220304,1573.284424 
   C1.000000,1571.611084 1.000000,1570.222168 1.000000,1568.416626 
z'
      />
      <path
        fill='#FEFFFE'
        opacity='1.000000'
        stroke='none'
        d='
M21.146839,1692.657593 
   C15.377978,1693.000000 9.742995,1692.772705 4.139194,1693.092285 
   C1.166139,1693.261841 0.892544,1692.253174 0.931998,1689.684082 
   C1.094481,1679.104004 1.000000,1668.519897 1.000000,1657.468628 
   C2.513946,1656.606079 3.082580,1657.780762 3.606437,1658.739868 
   C9.136111,1668.863647 14.966420,1678.821167 20.358841,1689.020508 
   C20.898960,1690.041992 21.340406,1691.115601 21.146839,1692.657593 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M45.333328,1693.000000 
   C30.531216,1667.112427 16.062437,1641.224731 1.296831,1615.168579 
   C1.000000,1613.611084 1.000000,1612.222168 1.000000,1610.416626 
   C5.927394,1615.885742 8.804597,1623.003540 12.555227,1629.577393 
   C24.135899,1649.875122 35.360054,1670.376221 46.702553,1690.809448 
   C46.932304,1691.223389 46.916512,1691.773560 47.007645,1692.630127 
   C46.555553,1693.000000 46.111111,1693.000000 45.333328,1693.000000 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1.000000,1573.468628 
   C4.856471,1576.600220 6.233628,1581.729004 8.979369,1586.020386 
   C11.749140,1590.349365 14.131117,1594.926636 16.809250,1599.712280 
   C11.934858,1600.019775 6.934229,1600.009888 1.466800,1600.000000 
   C1.000000,1591.312378 1.000000,1582.624878 1.000000,1573.468628 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M21.333324,1693.000000 
   C14.524010,1681.125366 8.048034,1669.250610 1.286029,1657.187988 
   C1.000000,1655.933350 1.000000,1654.866577 1.000000,1653.399902 
   C5.010599,1656.284058 6.491193,1661.211426 8.829953,1665.512085 
   C13.652649,1674.380859 19.476299,1682.727295 23.038605,1692.623169 
   C22.555555,1693.000000 22.111111,1693.000000 21.333324,1693.000000 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M282.619232,508.995758 
   C292.252869,523.650452 299.946808,539.129150 308.587585,554.072021 
   C313.902130,563.262695 318.465271,572.882812 324.708649,581.865662 
   C328.552948,594.230591 332.558746,606.272095 337.438416,618.042542 
   C343.744995,633.255005 351.458649,647.679382 360.273071,661.568237 
   C368.726959,674.888977 378.647369,687.073608 388.753143,699.127319 
   C389.693787,700.249268 390.839447,701.199341 391.935425,702.560303 
   C392.202637,703.153564 392.423431,703.416565 392.839844,703.846680 
   C393.036377,704.013062 392.998260,704.008423 392.999756,703.989014 
   C395.089233,711.605164 399.918701,717.871094 403.581787,724.693787 
   C412.620514,741.528809 422.119476,758.116760 431.438629,774.801208 
   C445.458740,799.902039 459.501740,824.990051 473.499786,850.103210 
   C483.858276,868.686829 494.135864,887.315491 504.494171,905.899231 
   C518.491943,931.012329 532.560852,956.085815 546.545410,981.206299 
   C557.216797,1000.375427 567.767273,1019.611877 578.438843,1038.781006 
   C592.423523,1063.901489 606.492859,1088.974854 620.490784,1114.088013 
   C630.849548,1132.671997 641.129028,1151.300415 651.486084,1169.885376 
   C665.481995,1195.000244 679.539307,1220.080811 693.530701,1245.198120 
   C704.045471,1264.074097 714.471924,1282.999390 724.984253,1301.876709 
   C739.215393,1327.432129 753.516479,1352.948486 767.739807,1378.508179 
   C779.863586,1400.294922 791.881165,1422.140747 804.016235,1443.921021 
   C817.847656,1468.746338 831.786987,1493.511475 845.623108,1518.334106 
   C856.142639,1537.206909 866.559692,1556.136719 877.051147,1575.025146 
   C881.570496,1583.161499 886.162537,1591.257202 890.842529,1599.694580 
   C884.483521,1600.231323 877.982361,1599.478882 871.218872,1600.751465 
   C863.295898,1587.431885 855.663818,1573.812256 848.052307,1560.181152 
   C833.789612,1534.638672 819.515137,1509.102539 805.290283,1483.538940 
   C793.165100,1461.748535 781.138428,1439.903198 769.002747,1418.118652 
   C755.170410,1393.288330 741.234436,1368.515747 727.402344,1343.685425 
   C716.723511,1324.515747 706.172302,1305.275146 695.497925,1286.103027 
   C681.509460,1260.978394 667.437317,1235.900391 653.436035,1210.782837 
   C643.156311,1192.341675 632.956604,1173.855957 622.678223,1155.414185 
   C608.597778,1130.150635 594.449219,1104.925293 580.381897,1079.654541 
   C569.709045,1060.481689 559.154724,1041.242676 548.480469,1022.070496 
   C534.492249,996.946106 520.421448,971.867615 506.417603,946.751953 
   C496.216919,928.457214 486.086945,910.123047 475.889801,891.826416 
   C461.728882,866.417664 447.508911,841.041809 433.362976,815.624756 
   C422.772675,796.596375 412.296051,777.504700 401.702698,758.477966 
   C387.633423,733.208252 373.483215,707.983643 359.399506,682.721924 
   C349.199890,664.427185 339.072723,646.092041 328.875641,627.795837 
   C314.714539,602.387207 300.489899,577.013977 286.348175,551.594604 
   C278.588684,537.647034 270.943512,523.635925 263.118530,509.329712 
   C269.373932,509.001740 275.757568,508.997498 282.619232,508.995758 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M914.060425,1599.554688 
   C907.742065,1599.933350 901.347839,1599.967407 894.487549,1600.004395 
   C875.742004,1567.177246 857.467773,1534.344360 839.181763,1501.518066 
   C818.145264,1463.754272 797.094299,1425.998657 776.057007,1388.235352 
   C758.093689,1355.989990 740.137939,1323.740234 722.183716,1291.489868 
   C700.832825,1253.137939 679.487488,1214.782837 658.139282,1176.429321 
   C638.489868,1141.127930 618.837769,1105.827759 599.190674,1070.525024 
   C579.462585,1035.076660 559.744995,999.622437 540.012329,964.176636 
   C523.757141,934.977539 507.486938,905.786987 491.222748,876.592957 
   C468.484924,835.778809 445.740540,794.968262 423.011566,754.149170 
   C415.332275,740.357849 407.691254,726.545227 399.989044,712.388000 
   C404.940277,713.527344 407.640961,718.046753 411.300293,721.160828 
   C419.563416,728.192627 428.246124,734.656311 436.858826,741.713623 
   C437.250427,742.225037 437.470886,742.454590 437.851013,742.860107 
   C438.034576,743.013062 437.997162,743.007996 437.997620,742.988892 
   C441.213867,751.393616 446.299591,758.841919 450.570984,766.702576 
   C461.043549,785.975098 471.927063,805.024170 482.626434,824.173706 
   C498.270813,852.173706 513.923523,880.169189 529.525208,908.192932 
   C540.197632,927.362610 550.753418,946.597351 561.426025,965.766968 
   C575.411377,990.886902 589.479431,1015.960754 603.475891,1041.074463 
   C613.914001,1059.803711 624.269165,1078.579102 634.707520,1097.308228 
   C648.622925,1122.276245 662.605347,1147.206787 676.517090,1172.176758 
   C687.033997,1191.053345 697.459167,1209.980835 707.971985,1228.859619 
   C722.202759,1254.414917 736.499023,1279.933716 750.727844,1305.489990 
   C761.158020,1324.223755 771.491638,1343.011230 781.928284,1361.741333 
   C795.760376,1386.564819 809.680115,1411.339355 823.506958,1436.165649 
   C834.182922,1455.334351 844.738647,1474.569702 855.411194,1493.740234 
   C869.396362,1518.860840 883.467102,1543.933838 897.453186,1569.053955 
   C903.029358,1579.069336 908.477966,1589.155762 914.060425,1599.554688 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M937.890625,1599.661133 
   C931.624390,1599.987915 925.221497,1599.994873 918.334106,1600.001953 
   C909.587463,1585.401855 901.273010,1570.830811 893.074829,1556.194580 
   C878.777466,1530.669312 864.528748,1505.116699 850.302979,1479.551392 
   C838.177368,1457.760254 826.148376,1435.915405 814.011658,1414.130371 
   C800.178223,1389.300049 786.241333,1364.527344 772.409119,1339.696289 
   C761.729004,1320.523926 751.178894,1301.279053 740.502991,1282.104370 
   C726.513550,1256.978027 712.438416,1231.899292 698.435730,1206.780273 
   C688.155212,1188.338623 677.955994,1169.851440 667.677002,1151.408936 
   C653.595764,1126.144409 639.445129,1100.918701 625.377380,1075.646729 
   C614.703125,1056.470947 604.150330,1037.227539 593.474487,1018.052795 
   C579.485046,992.926758 565.408508,967.849243 551.405884,942.730530 
   C541.125488,924.289124 530.928406,905.801147 520.649414,887.359009 
   C506.568054,862.094543 492.416138,836.869446 478.347656,811.597839 
   C467.753815,792.567871 457.246490,773.489807 446.693115,754.437317 
   C445.243652,751.820496 443.750763,749.227661 442.181458,746.291748 
   C448.364685,747.494324 452.761871,752.419434 458.461975,755.044067 
   C463.265778,757.256165 467.854156,759.936218 472.754822,762.670471 
   C476.550812,772.852966 482.631378,781.473572 487.564301,790.681580 
   C496.588562,807.526855 506.112488,824.104492 515.432861,840.791016 
   C529.454956,865.895020 543.499817,890.986450 557.499634,916.102844 
   C567.859497,934.688904 578.139465,953.319580 588.497742,971.906555 
   C602.495728,997.024597 616.561096,1022.104980 630.547424,1047.229370 
   C641.220215,1066.401611 651.779419,1085.636841 662.453247,1104.808472 
   C676.441101,1129.932373 690.509949,1155.011230 704.509949,1180.128418 
   C714.869995,1198.715088 725.152466,1217.345093 735.511536,1235.932251 
   C749.510315,1261.049805 763.576294,1286.130005 777.562683,1311.254272 
   C788.235535,1330.426514 798.793579,1349.662354 809.467346,1368.833984 
   C823.454956,1393.957764 837.524170,1419.036011 851.524109,1444.152954 
   C861.883972,1462.739380 872.165710,1481.369385 882.524780,1499.956177 
   C896.523438,1525.073364 910.579529,1550.158691 924.575439,1575.277344 
   C929.026733,1583.265991 933.364319,1591.318237 937.890625,1599.661133 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M961.808716,1599.712524 
   C955.296570,1600.023315 948.658020,1600.017090 941.562195,1600.021973 
   C920.443420,1562.820312 899.796997,1525.598999 879.116028,1488.397217 
   C861.183044,1456.138672 843.239258,1423.886108 825.248413,1391.659912 
   C803.530762,1352.757935 781.717712,1313.909058 760.023254,1274.994141 
   C744.397766,1246.965698 728.946594,1218.840088 713.331299,1190.805908 
   C692.944397,1154.205200 672.434570,1117.672974 652.048706,1081.071655 
   C636.434265,1053.036865 620.979370,1024.913086 605.364258,996.878601 
   C584.978516,960.279175 564.470215,923.747925 544.080627,887.150513 
   C530.161926,862.167725 516.343872,837.128845 502.504669,812.101929 
   C493.978973,796.684021 485.498138,781.241455 476.919281,765.464111 
   C485.664825,768.639893 494.488251,772.161499 503.642456,775.830139 
   C506.022552,783.633301 510.868164,789.886169 514.555725,796.697632 
   C523.653320,813.501953 533.112610,830.110352 542.430542,846.795288 
   C556.447571,871.894714 570.484802,896.982971 584.481628,922.093689 
   C594.838684,940.674622 605.122803,959.296143 615.479187,977.877380 
   C631.174255,1006.037354 646.929443,1034.163940 662.610107,1062.331909 
   C673.281311,1081.501099 683.830933,1100.738037 694.503052,1119.906616 
   C708.487610,1145.024902 722.558472,1170.095215 736.556030,1195.206299 
   C746.913818,1213.787842 757.189148,1232.415039 767.545654,1250.997314 
   C781.540771,1276.108276 795.606079,1301.179932 809.588623,1326.297974 
   C820.259705,1345.467163 830.811462,1364.702637 841.483582,1383.871338 
   C855.468018,1408.989502 869.538879,1434.059448 883.534119,1459.171387 
   C893.971191,1477.898926 904.317444,1496.677124 914.757507,1515.402832 
   C930.374512,1543.414062 946.038391,1571.399170 961.808716,1599.712524 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1150.808716,1599.712524 
   C1144.296509,1600.023315 1137.657959,1600.016968 1130.562134,1600.021973 
   C1109.448853,1562.830444 1088.807861,1525.619507 1068.132202,1488.427856 
   C1050.203857,1456.178223 1032.265015,1423.934204 1014.278320,1391.717163 
   C992.565369,1352.825562 970.760254,1313.985352 949.065491,1275.083618 
   C935.140503,1250.114258 921.390198,1225.047607 907.476501,1200.072021 
   C888.710571,1166.386353 869.827637,1132.765869 851.061768,1099.080078 
   C837.148071,1074.104492 823.388367,1049.042969 809.474670,1024.067383 
   C790.708984,990.382324 771.826294,956.762451 753.060547,923.077454 
   C739.146790,898.101807 725.372559,873.048523 711.474182,848.064270 
   C700.728149,828.746948 689.861389,809.496826 679.118774,790.177551 
   C677.838257,787.874695 676.304443,785.631042 675.841309,782.564148 
   C681.237793,780.472595 686.685974,778.735840 692.514465,776.978882 
   C698.115540,786.589539 703.266235,796.259216 708.572571,805.842712 
   C717.829712,822.561584 727.177856,839.230103 736.496643,855.914734 
   C750.515564,881.014648 764.561157,906.099731 778.558960,931.211365 
   C788.917236,949.793640 799.192688,968.422058 809.549683,987.005005 
   C823.545410,1012.116821 837.611877,1037.189209 851.594971,1062.307861 
   C862.266541,1081.478027 872.818115,1100.714966 883.490784,1119.884521 
   C897.476135,1145.004028 911.546997,1170.075806 925.545227,1195.188110 
   C935.903503,1213.770508 946.180176,1232.398193 956.537109,1250.981323 
   C970.532959,1276.093384 984.598267,1301.166748 998.581360,1326.285889 
   C1009.252869,1345.456177 1019.805603,1364.692383 1030.478271,1383.861938 
   C1044.463501,1408.981079 1058.534912,1434.052246 1072.530762,1459.165405 
   C1082.968384,1477.893799 1093.315063,1496.672852 1103.755615,1515.399536 
   C1119.373291,1543.411987 1135.037842,1571.398071 1150.808716,1599.712524 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M985.042297,1599.573120 
   C978.716064,1599.943237 972.316650,1599.969849 965.446045,1599.994141 
   C957.552307,1586.812256 950.100342,1573.649292 942.713318,1560.449829 
   C928.586487,1535.207275 914.469421,1509.959229 900.400085,1484.684570 
   C889.725464,1465.508423 879.168030,1446.267212 868.491943,1427.091919 
   C854.501465,1401.963501 840.429138,1376.880981 826.423157,1351.761230 
   C816.221252,1333.464111 806.089355,1315.127686 795.890808,1296.828613 
   C781.727356,1271.415771 767.507629,1246.034424 753.355835,1220.614990 
   C742.841797,1201.729736 732.428833,1182.788086 721.914673,1163.902954 
   C707.762329,1138.482910 693.540466,1113.101807 679.378052,1087.687378 
   C669.180115,1069.387207 659.053040,1051.047485 648.853638,1032.748047 
   C634.689880,1007.335693 620.454468,981.963196 606.313171,956.538330 
   C595.483093,937.066833 584.802307,917.512146 573.962891,898.045837 
   C560.132996,873.208862 546.179504,848.440796 532.339111,823.609619 
   C523.842285,808.365479 515.457458,793.058899 506.948120,777.435425 
   C515.010986,779.395508 523.148071,781.700500 531.693726,784.039185 
   C534.047485,791.580750 538.812500,797.694580 542.391846,804.362061 
   C550.961304,820.324585 560.084900,835.988892 568.928955,851.804749 
   C583.209961,877.343750 597.476074,902.891174 611.691406,928.466736 
   C622.516235,947.942383 633.201416,967.495728 644.041321,986.962952 
   C657.872131,1011.801331 671.826538,1036.570923 685.671021,1061.401733 
   C697.736145,1083.041138 709.694336,1104.740356 721.751587,1126.384277 
   C735.667725,1151.365234 749.668701,1176.298828 763.579590,1201.282837 
   C774.256592,1220.458740 784.814514,1239.701050 795.491150,1258.877197 
   C809.482483,1284.006958 823.554382,1309.091797 837.561340,1334.212769 
   C847.764221,1352.511353 857.899048,1370.847900 868.098450,1389.148438 
   C882.262573,1414.562866 896.476807,1439.949097 910.635132,1465.366699 
   C921.072815,1484.104858 931.450745,1502.876099 941.863342,1521.628052 
   C956.228027,1547.497437 970.600159,1573.362671 985.042297,1599.573120 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1126.890625,1599.661255 
   C1120.624390,1599.987915 1114.221436,1599.994873 1107.334229,1600.001953 
   C1098.588867,1585.404663 1090.275146,1570.836426 1082.078735,1556.202637 
   C1067.784180,1530.681641 1053.539185,1505.132935 1039.315552,1479.572388 
   C1027.191895,1457.785278 1015.162048,1435.946045 1003.027832,1414.164917 
   C989.197266,1389.338989 975.266113,1364.569092 961.437012,1339.742432 
   C950.759705,1320.573730 940.209290,1301.334595 929.535889,1282.163818 
   C915.549438,1257.042480 901.479370,1231.967896 887.480286,1206.853638 
   C877.121094,1188.269409 866.841431,1169.640869 856.483459,1151.056030 
   C842.486023,1125.941040 828.420166,1100.864502 814.435608,1075.742432 
   C803.763184,1056.570435 793.208313,1037.332886 782.534668,1018.161560 
   C768.547852,993.039673 754.477295,967.964417 740.477783,942.849609 
   C730.118774,924.265747 719.839478,905.637390 709.481689,887.052795 
   C695.484619,861.938171 681.431641,836.854553 667.434021,811.740173 
   C662.981689,803.751770 658.629272,795.707703 654.072021,787.382690 
   C659.982361,785.713562 666.051758,784.350891 672.520691,782.977722 
   C676.498047,792.152161 682.051331,800.298828 686.702576,808.912354 
   C695.468018,825.144897 704.666260,841.143494 713.665894,857.249817 
   C729.314819,885.255920 744.975403,913.255615 760.581421,941.285645 
   C771.256775,960.459717 781.810486,979.701538 792.485901,998.875610 
   C806.474976,1024.001099 820.548889,1049.079590 834.551147,1074.197754 
   C844.831482,1092.639404 855.031433,1111.125977 865.310425,1129.568359 
   C879.391724,1154.833008 893.541748,1180.059326 907.609497,1205.331543 
   C918.283569,1224.507202 928.837097,1243.749878 939.513000,1262.924561 
   C953.502380,1288.050781 967.578369,1313.128540 981.581177,1338.247314 
   C991.861450,1356.688721 1002.059143,1375.176025 1012.338013,1393.618286 
   C1026.419189,1418.882568 1040.555908,1444.115845 1054.642822,1469.376953 
   C1064.764282,1487.526855 1074.857300,1505.692383 1084.937622,1523.865234 
   C1098.888916,1549.016968 1112.816284,1574.182007 1126.890625,1599.661255 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1008.873169,1599.677734 
   C1002.523926,1600.242310 996.024109,1599.497559 989.264771,1600.720093 
   C967.330444,1562.098267 945.700012,1523.170776 924.049866,1484.254150 
   C906.106628,1452.000732 888.135742,1419.762817 870.185242,1387.513550 
   C850.455750,1352.068115 830.741089,1316.614624 811.009277,1281.170654 
   C794.754456,1251.972412 778.483826,1222.782715 762.220764,1193.589111 
   C739.484802,1152.775757 716.748230,1111.962769 694.013733,1071.148560 
   C676.052551,1038.903564 658.111450,1006.647217 640.129883,974.413513 
   C619.720215,937.827209 599.245300,901.277222 578.856567,864.679260 
   C564.938171,839.695618 551.119690,814.656372 537.252991,789.643860 
   C536.530701,788.340942 535.775146,787.056458 534.963135,785.419678 
   C542.347656,786.395325 549.804016,787.714783 557.707581,789.072449 
   C561.022156,796.768250 565.643433,803.515381 569.522156,810.633484 
   C580.100830,830.046936 591.044189,849.261536 601.829224,868.562683 
   C617.399292,896.427002 632.982666,924.283875 648.511475,952.171143 
   C659.189453,971.347229 669.748535,990.589539 680.425415,1009.766174 
   C694.416382,1034.895142 708.490662,1059.977905 722.492065,1085.101074 
   C732.933777,1103.837036 743.290161,1122.620483 753.732605,1141.355957 
   C767.653137,1166.332031 781.648743,1191.266235 795.558228,1216.248413 
   C806.235229,1235.425049 816.792236,1254.668701 827.469299,1273.845337 
   C841.460205,1298.973999 855.532349,1324.057373 869.539551,1349.177002 
   C879.742188,1367.473755 889.877991,1385.807861 900.076233,1404.107178 
   C915.938843,1432.570190 931.856018,1461.002808 947.698303,1489.477173 
   C958.450195,1508.802124 969.087463,1528.191040 979.824402,1547.524536 
   C989.429810,1564.820557 999.101440,1582.079956 1008.873169,1599.677734 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1103.060547,1599.554688 
   C1096.742188,1599.933472 1090.347778,1599.967529 1083.487305,1600.004150 
   C1064.748657,1567.190063 1046.479614,1534.371216 1028.203003,1501.556763 
   C1005.478760,1460.756836 982.748108,1419.960571 960.020081,1379.162720 
   C943.763000,1349.980591 927.497803,1320.802734 911.250183,1291.615112 
   C889.828491,1253.132935 868.426147,1214.639771 847.004517,1176.157593 
   C830.756592,1146.969238 814.485413,1117.793945 798.235413,1088.606812 
   C776.892212,1050.271729 755.559326,1011.930603 734.222290,973.591980 
   C717.815125,944.111389 701.431763,914.617554 684.995239,885.153259 
   C667.426514,853.659363 649.808044,822.193237 632.038208,790.419250 
   C638.249390,789.409790 644.633606,788.696045 651.448242,787.967285 
   C656.632996,798.589111 662.898743,808.412476 668.466492,818.609070 
   C677.465149,835.089172 686.774658,851.399414 695.930908,867.793701 
   C710.192139,893.328613 724.456970,918.861633 738.678772,944.418579 
   C750.800842,966.202332 762.830444,988.037537 774.962769,1009.815491 
   C788.791382,1034.638306 802.719116,1059.406006 816.546143,1084.229736 
   C827.221924,1103.396118 837.772583,1122.632080 848.444519,1141.800659 
   C862.428894,1166.918945 876.497192,1191.990479 890.492676,1217.102539 
   C900.929749,1235.829834 911.282410,1254.604126 921.719666,1273.331421 
   C935.633606,1298.296753 949.615540,1323.224243 963.525818,1348.191650 
   C974.041687,1367.066406 984.465088,1385.992676 994.976562,1404.869873 
   C1009.205444,1430.422974 1023.503906,1455.937256 1037.726074,1481.494141 
   C1049.848511,1503.277588 1061.905151,1525.097534 1073.990967,1546.901367 
   C1083.655518,1564.337524 1093.320068,1581.773682 1103.060547,1599.554688 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1032.076050,1599.536621 
   C1025.766479,1599.921875 1019.374695,1599.963257 1012.520752,1600.012085 
   C992.696411,1565.334839 973.300354,1530.669067 953.982727,1495.959717 
   C938.381409,1467.927979 922.930603,1439.812378 907.312988,1411.789673 
   C885.548706,1372.738281 863.650513,1333.761597 841.886536,1294.709961 
   C824.650818,1263.783691 807.576050,1232.767822 790.347717,1201.837402 
   C769.966614,1165.246582 749.463318,1128.723999 729.078247,1092.135376 
   C715.163391,1067.159912 701.409119,1042.094971 687.494873,1017.119324 
   C670.427490,986.483887 653.232361,955.919739 636.160706,925.286682 
   C623.942749,903.363098 611.871887,881.357666 599.671936,859.424011 
   C586.907043,836.474426 574.066162,813.567139 561.126099,790.322693 
   C568.351990,790.660950 575.710388,791.317810 583.493652,791.966248 
   C584.395081,797.192261 587.881775,801.044006 590.269592,805.347595 
   C607.363953,836.156677 624.653625,866.857117 641.791992,897.641846 
   C654.324402,920.153137 666.661987,942.772888 679.192871,965.285034 
   C694.631653,993.021118 710.210327,1020.679321 725.672729,1048.402344 
   C737.736877,1070.032471 749.686646,1091.726562 761.740723,1113.362427 
   C775.653076,1138.333862 789.645325,1163.260864 803.562622,1188.229492 
   C814.001526,1206.957642 824.347656,1225.737549 834.786926,1244.465576 
   C852.103516,1275.531372 869.500549,1306.552490 886.789246,1337.633667 
   C899.313477,1360.149658 911.658569,1382.765381 924.189270,1405.277588 
   C939.627380,1433.013428 955.206970,1460.670532 970.665466,1488.395142 
   C982.807495,1510.171509 994.870239,1531.992188 1006.945801,1553.805664 
   C1015.314758,1568.923828 1023.645752,1584.062988 1032.076050,1599.536621 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1079.842529,1599.694702 
   C1073.483521,1600.231323 1066.982300,1599.479004 1060.218750,1600.751465 
   C1052.295532,1587.431396 1044.663208,1573.811157 1037.051514,1560.179443 
   C1022.788208,1534.635864 1008.512878,1509.098999 994.287537,1483.534302 
   C982.162170,1461.743652 970.137268,1439.897095 957.999817,1418.113037 
   C944.165466,1393.283447 930.222412,1368.514282 916.389587,1343.683960 
   C905.710266,1324.514038 895.167114,1305.268311 884.493164,1286.095459 
   C870.505371,1260.970215 856.429138,1235.894165 842.427429,1210.776489 
   C832.147339,1192.335083 821.950745,1173.846924 811.672485,1155.404419 
   C797.591980,1130.139526 783.441772,1104.913574 769.373901,1079.641724 
   C758.700562,1060.468140 748.146790,1041.228027 737.472046,1022.055237 
   C723.482910,996.929810 709.409973,971.851135 695.405579,946.734192 
   C685.204651,928.438538 675.077209,910.101807 664.879578,891.804321 
   C650.717773,866.394043 636.497742,841.016357 622.353271,815.596497 
   C618.151733,808.045776 614.120361,800.400452 609.933228,792.453857 
   C616.255798,791.737549 622.656250,791.365967 629.510315,790.989258 
   C635.462097,801.219543 640.850952,811.515503 646.481873,821.677307 
   C657.116638,840.869141 667.901917,859.977539 678.604309,879.131958 
   C694.253052,907.139160 709.908264,935.142822 725.513794,963.174194 
   C736.189148,982.349609 746.748657,1001.589661 757.424683,1020.764832 
   C771.414368,1045.891846 785.487244,1070.972656 799.487549,1096.093750 
   C809.928467,1114.828003 820.284668,1133.609497 830.726135,1152.343506 
   C844.645630,1177.317871 858.639404,1202.250854 872.547913,1227.231323 
   C883.224060,1246.406494 893.781128,1265.647949 904.457153,1284.823120 
   C918.446716,1309.949829 932.519592,1335.030151 946.521851,1360.149902 
   C956.882996,1378.737671 967.164368,1397.369873 977.524414,1415.958130 
   C991.524658,1441.077759 1005.584229,1466.164307 1019.584412,1491.284058 
   C1029.944946,1509.873291 1040.239868,1528.499023 1050.585327,1547.096680 
   C1060.282959,1564.529663 1070.008423,1581.947266 1079.842529,1599.694702 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M1055.170898,1599.463379 
   C1049.104858,1599.882080 1042.982300,1599.941040 1036.380981,1600.000488 
   C1028.948120,1587.715820 1021.945862,1575.457642 1015.049927,1563.139893 
   C1000.756775,1537.608765 986.486816,1512.064697 972.271118,1486.490356 
   C961.446716,1467.016968 950.775635,1447.458252 939.937256,1427.992676 
   C926.108032,1403.155640 912.147217,1378.391968 898.303040,1353.563354 
   C886.238037,1331.925537 874.286804,1310.224243 862.229431,1288.582153 
   C848.313171,1263.603516 834.313416,1238.671265 820.391968,1213.695435 
   C809.949707,1194.961792 799.604309,1176.174072 789.162170,1157.440430 
   C771.840149,1126.364258 754.435364,1095.334229 737.141174,1064.242676 
   C724.613098,1041.719849 712.267578,1019.095337 699.731750,996.576843 
   C684.287048,968.832947 668.693359,941.171997 653.231812,913.437439 
   C641.087280,891.653015 629.080078,869.792114 616.946838,848.001343 
   C608.132629,832.171387 599.186646,816.414734 590.365601,800.588562 
   C588.998962,798.136536 587.376038,795.760254 586.831177,792.535889 
   C593.234070,792.126892 599.694458,792.075806 606.634766,792.051880 
   C610.113831,800.419006 615.231995,807.660278 619.390564,815.386841 
   C627.977051,831.340271 637.093506,847.007690 645.937500,862.823242 
   C660.217834,888.360657 674.484497,913.905945 688.699219,939.479919 
   C699.523682,958.954285 710.206909,978.507141 721.046204,997.973145 
   C734.876221,1022.810120 748.833496,1047.576172 762.673462,1072.407593 
   C774.815552,1094.192627 786.837219,1116.044922 798.974670,1137.832764 
   C812.808899,1162.666382 826.742798,1187.444702 840.575989,1212.278931 
   C851.255798,1231.451904 861.810730,1250.694336 872.486694,1269.869385 
   C886.477051,1294.997681 900.549866,1320.079956 914.553101,1345.201172 
   C924.834412,1363.645264 935.037109,1382.132935 945.317322,1400.577515 
   C959.399963,1425.844482 973.546753,1451.075684 987.620850,1476.347290 
   C998.137085,1495.230469 1008.553101,1514.169312 1019.066284,1533.054077 
   C1029.901978,1552.518188 1040.822632,1571.935059 1051.645630,1591.406250 
   C1053.008911,1593.859009 1054.629028,1596.237427 1055.170898,1599.463379 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M306.481262,508.985291 
   C309.757355,513.824158 312.570587,518.666809 315.654602,523.680664 
   C316.908539,538.092285 318.574493,552.242432 321.610474,566.205688 
   C321.861176,567.358643 322.184143,568.442871 321.224121,569.781250 
   C316.037994,563.672485 312.882599,556.308594 308.955475,549.402527 
   C301.491974,536.277588 294.315460,522.989502 286.957275,509.420471 
   C293.269348,509.044189 299.643890,509.016602 306.481262,508.985291 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M392.934967,703.765686 
   C392.751587,703.807495 392.466797,703.601929 392.081482,703.143799 
   C392.265137,703.100281 392.549347,703.309265 392.934967,703.765686 
z'
      />
      <path
        fill='#C7D7C3'
        opacity='1.000000'
        stroke='none'
        d='
M437.941833,742.777832 
   C437.709137,742.911560 437.442932,742.725281 437.121033,742.236450 
   C437.287079,742.193176 437.568054,742.367920 437.941833,742.777832 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M866.171143,1599.463379 
   C860.104980,1599.882080 853.982300,1599.941162 847.380920,1600.000488 
   C839.948669,1587.716919 832.945801,1575.460693 826.051758,1563.143433 
   C811.762390,1537.612915 797.499634,1512.067383 783.285400,1486.494995 
   C772.461975,1467.023071 761.782837,1447.470825 750.944702,1428.007202 
   C737.115540,1403.172363 723.159851,1378.407959 709.316833,1353.580811 
   C697.252869,1331.944458 685.299500,1310.246338 673.242676,1288.605957 
   C659.327087,1263.629395 645.329590,1238.698486 631.409424,1213.724487 
   C620.968323,1194.992310 610.623718,1176.206421 600.181763,1157.474731 
   C582.860901,1126.402466 565.456482,1095.377075 548.163879,1064.289185 
   C535.636841,1041.768433 523.293762,1019.145325 510.760101,996.628296 
   C495.318176,968.886353 479.729340,941.226257 464.268555,913.494812 
   C452.124939,891.713257 440.118042,869.855530 427.980011,848.070801 
   C414.144836,823.240112 400.198059,798.471619 386.363129,773.640808 
   C375.763336,754.616333 365.300690,735.515503 354.707214,716.487488 
   C340.637604,691.215637 326.480225,665.992737 312.398468,640.727661 
   C302.038055,622.139282 291.767731,603.500610 281.404663,584.913696 
   C267.400635,559.796631 253.346146,534.707642 239.196213,509.285034 
   C245.541656,508.763611 252.024551,509.515961 258.773499,508.248291 
   C266.552185,521.272400 274.042725,534.589661 281.500946,547.924988 
   C295.538239,573.023621 309.575409,598.122314 323.577057,623.240784 
   C333.856842,641.682373 344.055328,660.169312 354.334167,678.611450 
   C368.415009,703.875000 382.565582,729.099792 396.633423,754.370605 
   C407.226501,773.399475 417.698608,792.495728 428.293030,811.523865 
   C442.363098,836.794556 456.517120,862.018555 470.602020,887.281067 
   C480.802338,905.576294 490.927094,923.913635 501.124725,942.210388 
   C515.286255,967.619385 529.508911,992.994446 543.658813,1018.409912 
   C554.171753,1037.292969 564.577637,1056.235596 575.090698,1075.118530 
   C589.241089,1100.534790 603.465027,1125.910034 617.626282,1151.320190 
   C627.823242,1169.617188 637.946167,1187.955322 648.143860,1206.251831 
   C662.306091,1231.661377 676.541077,1257.030518 690.681152,1282.452393 
   C701.510437,1301.921997 712.190186,1321.474854 723.028503,1340.939453 
   C736.856812,1365.773804 750.807678,1390.540039 764.649963,1415.366577 
   C776.713257,1437.002319 788.671509,1458.696655 800.726440,1480.337036 
   C814.639465,1505.313110 828.626465,1530.247803 842.543579,1555.221558 
   C849.827332,1568.292236 857.046448,1581.399048 864.245728,1594.516479 
   C865.034851,1595.954224 865.499390,1597.570312 866.171143,1599.463379 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M843.075867,1599.536621 
   C836.766357,1599.921753 830.374634,1599.963257 823.520691,1600.012085 
   C803.692017,1565.325073 784.292969,1530.648804 764.969116,1495.930786 
   C749.443909,1468.037354 734.065918,1440.062134 718.521606,1412.179443 
   C696.746582,1373.120361 674.832153,1334.139038 653.065186,1295.075439 
   C638.821106,1269.512817 624.800720,1243.825439 610.557129,1218.262573 
   C592.189575,1185.298340 573.659668,1152.424805 555.276550,1119.469360 
   C544.422485,1100.011108 533.770447,1080.440308 522.931213,1060.973755 
   C509.101746,1036.137085 495.140442,1011.373901 481.300751,986.542908 
   C469.159119,964.758606 457.143066,942.904236 445.005554,921.117615 
   C431.171234,896.285278 417.231750,871.511597 403.398438,846.678772 
   C392.717834,827.505554 382.168671,808.259155 371.492249,789.083557 
   C357.501923,763.955933 343.426819,738.875549 329.420776,713.756714 
   C319.218750,695.460144 309.089874,677.122742 298.891388,658.824219 
   C284.728607,633.412720 270.504700,608.035217 256.357391,582.615112 
   C245.765366,563.583374 235.251785,544.507935 224.705841,525.450562 
   C221.810913,520.219238 218.925018,514.982849 215.978210,509.400574 
   C222.298859,509.032623 228.676270,509.012817 235.520721,508.993927 
   C243.496780,522.314758 251.038345,535.616577 258.507812,548.958618 
   C272.555817,574.051453 286.589813,599.152344 300.577026,624.279114 
   C311.251740,643.455200 321.808533,662.696899 332.484070,681.872559 
   C346.472748,706.999390 360.541840,732.081421 374.547424,757.198853 
   C384.748749,775.493835 394.885345,793.824890 405.082977,812.121948 
   C420.945038,840.582336 436.852722,869.017273 452.705658,897.482788 
   C463.140808,916.220154 473.488434,935.006287 483.920441,953.745422 
   C498.151245,979.308472 512.444458,1004.836792 526.674438,1030.400269 
   C538.722900,1052.044678 550.691223,1073.733643 562.746338,1095.374268 
   C576.660217,1120.351685 590.658325,1145.282104 604.566956,1170.262207 
   C615.242676,1189.435913 625.800293,1208.675171 636.475525,1227.848999 
   C650.465027,1252.976074 664.536255,1278.057495 678.538635,1303.177246 
   C688.819336,1321.620239 699.022522,1340.106201 709.302063,1358.549805 
   C723.383606,1383.815308 737.532043,1409.043457 751.600403,1434.316284 
   C762.274475,1453.491699 772.831360,1472.732300 783.507080,1491.906860 
   C797.496826,1517.033691 811.584534,1542.106079 825.563599,1567.238770 
   C831.460999,1577.841553 837.188721,1588.538696 843.075867,1599.536621 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M819.873169,1599.677734 
   C813.523865,1600.242310 807.023987,1599.497559 800.264526,1600.720215 
   C777.627319,1560.978516 755.256104,1520.952026 732.953918,1480.887085 
   C717.348145,1452.851929 701.860229,1424.751343 686.277405,1396.703369 
   C664.883179,1358.195190 643.455200,1319.705688 622.030151,1281.214722 
   C605.779480,1252.019775 589.536011,1222.820801 573.244202,1193.648804 
   C551.523071,1154.755493 529.717529,1115.909180 508.025787,1076.999512 
   C492.402496,1048.975098 476.953705,1020.853638 461.340424,992.823792 
   C440.955963,956.228516 420.451965,919.699768 400.064270,883.106262 
   C386.147888,858.127991 372.390747,833.061096 358.475403,808.082275 
   C339.707581,774.392883 320.817139,740.771851 302.052673,707.080566 
   C286.521881,679.195251 271.144226,651.224731 255.613342,623.339539 
   C238.547729,592.698608 221.373642,562.118225 204.271454,531.497681 
   C200.217773,524.239807 196.262253,516.927185 192.143646,509.312958 
   C198.407776,508.988373 204.789825,508.990540 211.655334,508.994873 
   C219.189560,519.499268 224.694458,530.888794 230.907135,541.869141 
   C245.314133,567.332092 259.498596,592.921265 273.719543,618.489197 
   C285.921265,640.426697 298.010468,662.426697 310.218475,684.360657 
   C324.365875,709.779175 338.619110,735.138733 352.769531,760.555664 
   C364.899994,782.344299 376.909180,804.200439 389.046844,825.985046 
   C402.881531,850.815552 416.829376,875.583069 430.671600,900.409363 
   C442.734619,922.044678 454.690582,943.739624 466.745789,965.379333 
   C480.659454,990.355042 494.658691,1015.283081 508.566589,1040.261963 
   C519.242249,1059.435669 529.796936,1078.676880 540.472229,1097.850830 
   C554.461121,1122.976196 568.534363,1148.054688 582.535889,1173.172974 
   C592.896790,1191.760254 603.176758,1210.392700 613.536682,1228.980469 
   C627.536255,1254.098755 641.605774,1279.178101 655.592346,1304.303467 
   C666.266724,1323.478760 676.821045,1342.720825 687.496826,1361.895264 
   C701.486084,1387.021240 715.561035,1412.099731 729.563599,1437.218262 
   C739.843933,1455.659790 750.042847,1474.146729 760.321716,1492.588989 
   C774.403015,1517.853394 788.540649,1543.086426 802.623352,1568.349976 
   C808.371521,1578.661987 814.039429,1589.018921 819.873169,1599.677734 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M796.042358,1599.573242 
   C789.716125,1599.943359 783.316650,1599.969849 776.446045,1599.994141 
   C768.552917,1586.813477 761.101562,1573.651733 753.715332,1560.453491 
   C739.589844,1535.213257 725.474304,1509.967041 711.406250,1484.694702 
   C700.732300,1465.519531 690.175049,1446.279541 679.499573,1427.105225 
   C665.510193,1401.978760 651.437317,1376.898682 637.434998,1351.779297 
   C627.154480,1333.336670 616.953369,1314.849731 606.673889,1296.406494 
   C592.592407,1271.141479 578.442505,1245.914551 564.374329,1220.642212 
   C553.700256,1201.467163 543.145325,1182.225830 532.469849,1163.051514 
   C518.480469,1137.925415 504.407379,1112.845947 490.403259,1087.728027 
   C480.202454,1069.431763 470.076355,1051.093872 459.878296,1032.796143 
   C445.716095,1007.385925 431.491302,982.010742 417.343597,956.592529 
   C406.751465,937.562500 396.275970,918.467468 385.681396,899.438782 
   C371.610992,874.167358 357.457428,848.942200 343.373474,823.678284 
   C333.173767,805.381958 323.052521,787.041931 312.854431,768.744751 
   C298.692017,743.335022 284.460205,717.963867 270.315430,692.544312 
   C259.563385,673.221802 248.940231,653.827576 238.188293,634.505005 
   C224.366943,609.666321 210.430191,584.891785 196.627426,560.042786 
   C187.346130,543.333740 178.210846,526.543701 168.941132,509.440887 
   C175.071304,508.605072 181.315384,509.607086 187.803955,508.228943 
   C196.895554,523.567627 205.699722,539.198364 214.460388,554.853455 
   C228.503311,579.947815 242.536530,605.047607 256.537354,630.165527 
   C266.897949,648.752686 277.174744,667.386536 287.535370,685.973694 
   C301.536133,711.091492 315.610901,736.168030 329.598236,761.293274 
   C340.271881,780.466125 350.820129,799.708679 361.493958,818.881348 
   C375.481506,844.006409 389.556671,869.082642 403.558167,894.199951 
   C413.838104,912.641235 424.034668,931.128967 434.314209,949.570496 
   C448.396210,974.833618 462.551056,1000.056152 476.618896,1025.327148 
   C487.292114,1044.500000 497.839447,1063.743164 508.512695,1082.916016 
   C522.499695,1108.041626 536.573975,1133.118408 550.575439,1158.235962 
   C560.855591,1176.677856 571.054871,1195.164673 581.333740,1213.607178 
   C595.414734,1238.871460 609.564148,1264.097656 623.632751,1289.368774 
   C634.225464,1308.396240 644.700500,1327.489258 655.294067,1346.516113 
   C669.363953,1371.786865 683.514771,1397.012695 697.599060,1422.275391 
   C707.799133,1440.571045 717.926636,1458.907104 728.124207,1477.204102 
   C742.285767,1502.613525 756.507935,1527.989258 770.654968,1553.406738 
   C779.141479,1568.654175 787.534302,1583.953613 796.042358,1599.573242 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M772.808350,1599.711914 
   C766.296570,1600.023315 759.658020,1600.016968 752.562134,1600.021973 
   C731.443909,1562.821289 710.798096,1525.601074 690.117554,1488.400269 
   C672.184875,1456.142334 654.241516,1423.890381 636.250916,1391.664673 
   C614.533813,1352.763916 592.721191,1313.916260 571.027283,1275.002319 
   C555.402710,1246.975464 539.952515,1218.851562 524.337952,1190.818970 
   C503.951782,1154.220093 483.446472,1117.687622 463.056854,1081.090576 
   C449.139008,1056.109619 435.379333,1031.040405 421.462738,1006.058777 
   C402.693481,972.366211 383.802856,938.741211 365.036804,905.046875 
   C349.504547,877.158936 334.124878,849.186035 318.591797,821.298584 
   C298.126007,784.555115 277.542694,747.877136 257.074280,711.135132 
   C243.157272,686.153320 229.400085,661.082520 215.481979,636.101379 
   C198.410400,605.460205 181.214355,574.888428 164.137161,544.250366 
   C157.741470,532.775879 151.528519,521.199524 145.096283,509.348816 
   C151.340591,509.016571 157.720840,509.005157 164.574646,508.996399 
   C172.012329,521.273193 179.033691,533.515320 185.928665,545.828186 
   C200.223297,571.355225 214.481506,596.902771 228.695953,622.474548 
   C239.520493,641.947876 250.202911,661.500183 261.041718,680.965576 
   C274.870636,705.800903 288.823608,730.567078 302.666595,755.394592 
   C314.730377,777.031067 326.687744,798.726990 338.743164,820.368164 
   C352.657288,845.345947 366.655334,870.277100 380.564209,895.257751 
   C391.239990,914.431763 401.798096,933.671265 412.473450,952.845459 
   C426.463013,977.972351 440.534210,1003.053772 454.536591,1028.173584 
   C464.816956,1046.616211 475.020050,1065.101807 485.299377,1083.545044 
   C499.381195,1108.810791 513.529663,1134.039673 527.598206,1159.312744 
   C538.272278,1178.487915 548.829163,1197.728149 559.504700,1216.902466 
   C573.494629,1242.029785 587.568237,1267.110474 601.571106,1292.230591 
   C611.851990,1310.673828 622.052917,1329.161743 632.332703,1347.605469 
   C646.414856,1372.871216 660.564941,1398.098999 674.634338,1423.371704 
   C685.228333,1442.401611 695.704407,1461.496948 706.299377,1480.526245 
   C720.370361,1505.798584 734.507263,1531.034546 748.613159,1556.287476 
   C756.638672,1570.654907 764.658997,1585.025146 772.808350,1599.711914 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M748.887207,1599.666504 
   C742.620361,1599.992554 736.219421,1599.997192 729.334106,1600.001953 
   C720.587341,1585.401611 712.272705,1570.830322 704.074463,1556.193848 
   C689.777161,1530.668579 675.528564,1505.115845 661.302795,1479.550659 
   C649.177429,1457.760010 637.148499,1435.915527 625.011963,1414.131104 
   C611.178650,1389.300781 597.241760,1364.528076 583.409607,1339.697144 
   C572.729797,1320.525269 562.179749,1301.281128 551.504211,1282.106812 
   C537.515259,1256.981201 523.440979,1231.903320 509.438690,1206.785156 
   C499.158691,1188.344238 488.959564,1169.858032 478.680969,1151.416260 
   C464.600098,1126.152344 450.450165,1100.926880 436.382843,1075.655518 
   C425.709015,1056.480225 415.155548,1037.237915 404.480530,1018.063293 
   C390.492401,992.937622 376.425446,967.855835 362.415131,942.742493 
   C352.291229,924.595642 342.213989,906.422852 332.098877,888.271057 
   C316.158569,859.665771 300.187042,831.077820 284.261322,802.464355 
   C273.831482,783.725159 263.486389,764.938843 253.054733,746.200684 
   C238.824341,720.638794 224.523987,695.115723 210.297958,669.551392 
   C198.172028,647.761047 186.144272,625.916077 174.008499,604.131226 
   C160.175903,579.300476 146.267899,554.511780 132.401199,529.700012 
   C128.670517,523.024658 124.970802,516.331970 121.114677,509.333435 
   C127.454620,508.712830 133.962051,509.516388 140.727325,508.279602 
   C147.116165,518.840271 153.220551,529.690918 159.296249,540.557678 
   C173.410980,565.802612 187.532516,591.043884 201.611435,616.308838 
   C212.049759,635.040710 222.394928,653.824402 232.835602,672.554993 
   C250.154739,703.625427 267.557129,734.649658 284.848358,765.735596 
   C297.374084,788.254333 309.716339,810.875061 322.249664,833.389526 
   C337.691467,861.128540 353.281952,888.784851 368.741669,916.513977 
   C380.884644,938.294067 392.887329,960.152405 405.023956,981.936096 
   C418.857391,1006.765381 432.797150,1031.535400 446.640533,1056.359131 
   C458.542084,1077.700806 470.348785,1099.095215 482.241302,1120.441895 
   C498.016174,1148.757202 513.859192,1177.034424 529.624268,1205.355225 
   C540.297546,1224.529053 550.848755,1243.770996 561.523621,1262.943848 
   C575.512268,1288.068726 589.586304,1313.145874 603.590576,1338.261963 
   C613.791077,1356.556152 623.916992,1374.892090 634.114136,1393.188232 
   C648.275330,1418.596924 662.484680,1443.978882 676.649292,1469.385742 
   C686.768066,1487.535645 696.861572,1505.699829 706.940674,1523.871826 
   C720.890808,1549.022583 734.816895,1574.186768 748.887207,1599.666504 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M725.060425,1599.554688 
   C718.742065,1599.933472 712.347839,1599.967529 705.487549,1600.004395 
   C686.743286,1567.179688 668.470459,1534.349365 650.185608,1501.525513 
   C629.150757,1463.765137 608.101135,1426.012817 587.065491,1388.252808 
   C569.103882,1356.010986 551.149780,1323.765015 533.198181,1291.517700 
   C511.850128,1253.169312 490.509460,1214.817017 469.163574,1176.467407 
   C449.516083,1141.169434 429.864166,1105.873901 410.217194,1070.575562 
   C388.872406,1032.227051 367.540009,993.871582 346.185608,955.528320 
   C325.154449,917.765320 304.097961,880.016479 283.061127,842.256653 
   C265.099152,810.016052 247.145996,777.770569 229.195023,745.523865 
   C207.847900,707.176453 186.508347,668.824829 165.163452,630.476135 
   C145.516571,595.178223 125.865593,559.882568 106.220200,524.583801 
   C103.475548,519.652283 100.752411,514.708679 97.956734,509.422150 
   C104.269768,509.045563 110.645386,509.017456 117.483521,508.985474 
   C134.535721,538.752991 151.135300,568.518921 167.712616,598.297302 
   C189.141281,636.790405 210.546005,675.296936 231.972656,713.791199 
   C248.224365,742.988281 264.493469,772.175720 280.756226,801.366699 
   C303.492035,842.176575 326.230347,882.985107 348.965332,923.795532 
   C365.227448,952.986816 381.492615,982.176331 397.744202,1011.373535 
   C419.170624,1049.867676 440.579163,1088.371704 462.006775,1126.865234 
   C478.259125,1156.061890 494.536072,1185.244751 510.789734,1214.440674 
   C530.520996,1249.883179 550.234131,1285.335938 569.964783,1320.778809 
   C586.218323,1349.975586 602.460388,1379.178589 618.753845,1408.353149 
   C640.476929,1447.249756 662.291321,1486.095459 683.976257,1525.013184 
   C697.730591,1549.698120 711.320801,1574.474487 725.060425,1599.554688 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M701.852173,1599.689819 
   C695.490234,1600.237549 688.985352,1599.476318 682.218872,1600.751465 
   C674.295776,1587.431763 666.663696,1573.811768 659.052124,1560.180542 
   C644.789124,1534.637329 630.514160,1509.100830 616.288940,1483.536621 
   C604.163635,1461.746216 592.137268,1439.900635 580.001404,1418.116089 
   C566.169067,1393.286011 552.233093,1368.513794 538.401062,1343.683594 
   C527.722290,1324.513916 517.171265,1305.273071 506.497040,1286.100830 
   C492.508850,1260.976318 478.437103,1235.898193 464.435852,1210.780884 
   C454.156067,1192.339722 443.955658,1173.854248 433.677094,1155.412354 
   C419.596832,1130.149292 405.449188,1104.923706 391.381256,1079.653809 
   C380.788635,1060.626709 370.312469,1041.534668 359.719147,1022.507874 
   C345.649872,997.238098 331.501007,972.012573 317.416901,946.750977 
   C307.217010,928.456238 297.086700,910.122742 286.889679,891.826355 
   C272.728729,866.417603 258.508759,841.041626 244.362793,815.624512 
   C233.772491,796.596130 223.295898,777.504395 212.702530,758.477722 
   C198.633240,733.207947 184.483032,707.983337 170.399292,682.721619 
   C160.199753,664.426941 150.072693,646.091797 139.875671,627.795715 
   C125.714539,602.387024 111.489822,577.013733 97.348053,551.594299 
   C89.588562,537.646851 81.943413,523.635803 74.118477,509.329712 
   C80.373306,509.001709 86.756371,508.997467 93.618317,508.995728 
   C103.102951,523.365601 110.774246,538.488403 119.097519,553.241638 
   C133.391708,578.578552 147.526886,604.005371 161.679123,629.422058 
   C173.810364,651.209229 185.844727,673.050354 197.971756,694.839844 
   C212.199646,720.404175 226.506500,745.924561 240.732056,771.490173 
   C252.857742,793.282104 264.877899,815.132812 277.015625,836.918030 
   C290.850372,861.749146 304.787567,886.523193 318.635010,911.347229 
   C329.001526,929.931091 339.270874,948.569153 349.628876,967.157776 
   C365.245087,995.182861 380.925690,1023.172058 396.531342,1051.203003 
   C407.206360,1070.377563 417.764099,1089.617432 428.439545,1108.791748 
   C442.428711,1133.917725 456.500336,1158.997803 470.502136,1184.116821 
   C480.863220,1202.704468 491.145721,1221.336060 501.505676,1239.924194 
   C515.505371,1265.043213 529.573059,1290.124268 543.559998,1315.250244 
   C554.234375,1334.426025 564.791443,1353.667114 575.467346,1372.841919 
   C589.456909,1397.968628 603.530762,1423.048218 617.532959,1448.167847 
   C627.894104,1466.755493 638.174500,1485.388306 648.534607,1503.976562 
   C662.534912,1529.095947 676.581360,1554.189819 690.598572,1579.299683 
   C694.327026,1585.978882 698.021301,1592.677246 701.852173,1599.689819 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M677.171143,1599.463379 
   C671.104980,1599.882080 664.982300,1599.941162 658.380920,1600.000488 
   C650.948669,1587.716919 643.945801,1575.460693 637.051758,1563.143433 
   C622.762268,1537.613037 608.499634,1512.067383 594.285400,1486.495117 
   C583.461975,1467.023193 572.782898,1447.470947 561.944763,1428.007324 
   C548.115723,1403.172485 534.160034,1378.408203 520.317017,1353.581055 
   C508.252899,1331.944702 496.299530,1310.246704 484.242584,1288.606323 
   C470.326874,1263.629395 456.329346,1238.698242 442.408966,1213.723755 
   C431.967926,1194.991577 421.623108,1176.205688 411.181305,1157.473999 
   C393.860443,1126.401733 376.455994,1095.376343 359.163513,1064.288452 
   C346.636414,1041.767700 334.293457,1019.144653 321.759827,996.627625 
   C306.317871,968.885803 290.729034,941.225708 275.268250,913.494324 
   C263.124634,891.712830 251.117767,869.855042 238.979782,848.070374 
   C225.144592,823.239685 211.197769,798.471252 197.362869,773.640442 
   C186.763092,754.616028 176.300491,735.515259 165.707031,716.487244 
   C151.637421,691.215454 137.479996,665.992554 123.398285,640.727478 
   C113.037865,622.139099 102.767670,603.500366 92.404549,584.913513 
   C78.400581,559.796509 64.346100,534.707581 50.196167,509.285004 
   C56.541630,508.763641 63.024525,509.515961 69.773483,508.248291 
   C77.552177,521.272400 85.042747,534.589600 92.500977,547.924927 
   C106.538170,573.023438 120.575180,598.122009 134.576859,623.240356 
   C144.856888,641.682251 155.056076,660.169312 165.335129,678.611755 
   C179.416122,703.875549 193.566345,729.100830 207.634186,754.371948 
   C218.227142,773.400818 228.699326,792.497009 239.293686,811.525085 
   C253.363800,836.795776 267.517853,862.019775 281.602722,887.282288 
   C291.803070,905.577576 301.927704,923.914978 312.125305,942.211792 
   C326.286987,967.620911 340.512451,992.994568 354.659088,1018.412048 
   C365.250519,1037.441895 375.722076,1056.538330 386.316498,1075.566406 
   C400.386597,1100.837036 414.542542,1126.059814 428.626984,1151.322510 
   C438.827026,1169.617798 448.947449,1187.957397 459.145111,1206.254028 
   C473.306976,1231.662964 487.541534,1257.031372 501.681244,1282.452637 
   C512.510254,1301.921631 523.189880,1321.473999 534.027893,1340.938110 
   C547.856140,1365.772339 561.806641,1390.538452 575.648987,1415.364990 
   C587.712097,1437.000610 599.670776,1458.694702 611.725586,1480.335083 
   C625.638733,1505.311401 639.625854,1530.246460 653.543091,1555.220703 
   C660.826843,1568.291382 668.046021,1581.398193 675.245300,1594.515625 
   C676.034607,1595.953735 676.499268,1597.569946 677.171143,1599.463379 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M26.977871,509.400970 
   C33.298782,509.032623 39.676220,509.012817 46.520702,508.993896 
   C54.496761,522.314758 62.038315,535.616577 69.507790,548.958618 
   C83.555855,574.051453 97.589867,599.152344 111.577095,624.279114 
   C122.251778,643.455200 132.808517,662.696899 143.484009,681.872559 
   C157.472717,706.999390 171.541840,732.081421 185.547409,757.198914 
   C195.748795,775.493835 205.885391,793.824890 216.082993,812.121948 
   C231.944992,840.582336 247.852768,869.017273 263.705627,897.482727 
   C274.140778,916.220154 284.488434,935.006348 294.920471,953.745544 
   C309.151276,979.308594 323.444489,1004.836914 337.674500,1030.400391 
   C349.722900,1052.044678 361.691315,1073.733765 373.746460,1095.374268 
   C387.660278,1120.351685 401.658386,1145.282227 415.567017,1170.262451 
   C426.242767,1189.436157 436.800476,1208.675537 447.475769,1227.849487 
   C461.465332,1252.976562 475.536682,1278.057861 489.539154,1303.177734 
   C499.819763,1321.620728 510.022919,1340.106812 520.302368,1358.550415 
   C534.383911,1383.815918 548.532410,1409.044067 562.600708,1434.316895 
   C573.274841,1453.492310 583.831848,1472.732910 594.507507,1491.907471 
   C608.497253,1517.034302 622.585022,1542.106567 636.563904,1567.239502 
   C642.461182,1577.842285 648.188171,1588.540039 654.075317,1599.537354 
   C647.766357,1599.921753 641.374573,1599.963257 634.520691,1600.011963 
   C614.692078,1565.325317 595.292236,1530.649780 575.969849,1495.931274 
   C560.364624,1467.891846 544.911133,1439.767822 529.285522,1411.739746 
   C507.591309,1372.825928 485.752960,1333.992676 464.065430,1295.075195 
   C449.820374,1269.513062 435.800659,1243.825439 421.557037,1218.262573 
   C403.189514,1185.298584 384.659729,1152.424927 366.276672,1119.469604 
   C355.422546,1100.011353 344.770569,1080.440674 333.931335,1060.974121 
   C320.101959,1036.137451 306.140656,1011.374268 292.300995,986.543274 
   C280.159393,964.758972 268.143433,942.904663 256.005920,921.118103 
   C242.171631,896.285828 228.232193,871.512207 214.398880,846.679443 
   C203.718323,827.506226 193.169113,808.259949 182.492706,789.084473 
   C168.502426,763.956909 154.427460,738.876465 140.421356,713.757690 
   C130.219299,695.461121 120.090324,677.123718 109.891838,658.825195 
   C95.729095,633.413696 81.505295,608.036255 67.357986,582.616211 
   C56.766041,563.584473 46.252525,544.509033 35.706459,525.451721 
   C32.811359,520.220154 29.924847,514.983765 26.977871,509.400970 
z'
      />
      <path
        fill='#F0F0F1'
        opacity='1.000000'
        stroke='none'
        d='
M3.143841,509.312897 
   C9.407868,508.988373 15.789891,508.990540 22.655361,508.994873 
   C30.189516,519.499329 35.694462,530.888794 41.907162,541.869202 
   C56.314152,567.332153 70.498634,592.921387 84.719566,618.489258 
   C96.921249,640.426758 109.010406,662.426819 121.218414,684.360840 
   C135.365860,709.779358 149.619156,735.138916 163.769592,760.555786 
   C175.900055,782.344421 187.909225,804.200562 200.046860,825.985168 
   C213.881577,850.815674 227.829422,875.583191 241.671677,900.409485 
   C253.734711,922.044800 265.690704,943.739807 277.745880,965.379517 
   C291.659546,990.355286 305.658783,1015.283325 319.566711,1040.262207 
   C330.242401,1059.435913 340.796997,1078.677002 351.472260,1097.850952 
   C365.461182,1122.976440 379.534546,1148.054810 393.536102,1173.173218 
   C403.897034,1191.760498 414.176971,1210.392944 424.536896,1228.980835 
   C438.536499,1254.099243 452.606079,1279.178711 466.592712,1304.304199 
   C477.267029,1323.479492 487.821594,1342.721558 498.497314,1361.895996 
   C512.486694,1387.022095 526.561584,1412.100464 540.564148,1437.219116 
   C550.844482,1455.660767 561.043457,1474.147583 571.322388,1492.589966 
   C585.403381,1517.854126 599.540710,1543.086792 613.623108,1568.350098 
   C619.371399,1578.662109 625.039368,1589.018921 630.873169,1599.677734 
   C624.523865,1600.242310 618.023987,1599.497559 611.264526,1600.720215 
   C588.626709,1560.977295 566.255005,1520.949585 543.952148,1480.883423 
   C528.346313,1452.848389 512.857971,1424.747803 497.275269,1396.699829 
   C475.881042,1358.191650 454.453278,1319.701904 433.028229,1281.210815 
   C416.777802,1252.016113 400.534393,1222.817505 384.242645,1193.645874 
   C362.521759,1154.752808 340.711914,1115.909180 319.027283,1076.995850 
   C304.784485,1051.437134 290.763336,1025.754761 276.520660,1000.195984 
   C258.153748,967.236206 239.621231,934.368591 221.243225,901.414978 
   C208.773239,879.054871 196.494797,856.587952 184.042999,834.217651 
   C168.521286,806.332092 152.894089,778.505249 137.341827,750.636719 
   C127.215256,732.490601 117.156425,714.306702 107.039589,696.155151 
   C92.796844,670.600708 78.495613,645.078735 64.281776,619.508240 
   C53.458694,600.037598 42.765446,580.494751 31.953716,561.017761 
   C22.433493,543.867432 12.829066,526.763855 3.143841,509.312897 
z'
      />
    </svg>
  );
};

export default React.memo(CoverPage);
