import sortDateComparator from './utils/sortDateComparator';
import sortProductComparator from './utils/sortProductComparator';
import sortTextComparator from './utils/sortTextComparator';
import renderCellExpand from '../../components/Datagrid/DatagridPopover';
import getArrayValue from './utils/getArrayValue';
import { Filter } from '../../components/Filters';
import DatagridChipsPopup from '../../components/Datagrid/DatagridChipsPopup';
import { PIECHARTCOLOR } from '../../components/CustomComponents/Graphs/constant';
import { FILTER_TYPE } from '../../components/NewFilters/constants';
import DocumentHistoryPDF from './components/DocumentHistoryPDF';

export const SEARCH_IGNORE_FIELDS = ['doc_hash', 'original_pdf_url', 'guidance_pk'];

export const GUIDANCE_COLUMNS = [
  {
    field: 'documents',
    headerName: 'Documents',
    filterable: false,
    sortable: false,
    disableExport: true,
    width: 100,
    headerClassName: 'table-header',
    cellClassName: 'table-row',
    renderCell: DocumentHistoryPDF
  },
  {
    field: 'title',
    headerName: 'Summary',
    width: 350,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    sortComparator: sortTextComparator,
    renderCell: renderCellExpand
  },
  {
    field: 'issue_datetime',
    headerName: 'Issue Date',
    width: 170,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    sortComparator: sortDateComparator
  },
  {
    field: 'issuing_office_taxonomy',
    headerName: 'FDA Organization',
    width: 350,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridChipsPopup,
    valueGetter: ({ row }: any) =>
      `${
        row.issuing_office_taxonomy && row.issuing_office_taxonomy.length
          ? row.issuing_office_taxonomy.join('; ')
          : ''
      }`
  },
  {
    field: 'topics_product',
    headerName: 'Topic',
    width: 270,
    capitalCasing: false,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: renderCellExpand
  },
  {
    field: 'communication_type',
    headerName: 'Document Type',
    width: 250,
    filterable: false,
    headerClassName: 'table-header'
  },
  {
    field: 'regulated_product_field',
    headerName: 'Product',
    width: 270,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    valueGetter: getArrayValue,
    capitalCasing: false,
    sortComparator: sortProductComparator,
    renderCell: renderCellExpand
  },
  {
    field: 'status',
    headerName: 'Guidance Status',
    width: 150,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell'
  },
  {
    field: 'open_comment',
    headerName: 'Open Comment',
    width: 120,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell'
  },
  {
    field: 'comment_close_date',
    headerName: 'Comment Closing Date',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    sortComparator: sortDateComparator,
    valueGetter: (params: any) => params.value || '-'
  }
];

export interface GuidancesFilters extends Filter {
  apiRequestKey: string;
  apiResponseKey?: string;
}

export const GUIDANCES_FILTERS = [
  {
    id: 1,
    label: 'Product Category',
    value: 'regulated_product_field',
    elasticSearchType: 'regulated_product_field',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Topic',
    value: 'topics_product',
    elasticSearchType: 'topics_product',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'FDA Organization',
    value: 'issuing_office_taxonomy',
    elasticSearchType: 'issuing_office_taxonomy',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Issue Date',
    value: 'issue_datetime',
    elasticSearchType: 'issue_datetime',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Draft or Final',
    value: 'status',
    elasticSearchType: 'status',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 7,
    label: 'Open for Comment',
    value: 'open_comment',
    elasticSearchType: 'open_comment',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 8,
    label: 'Document Type',
    value: 'communication_type',
    elasticSearchType: 'communication_type',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 9,
    label: 'Comment Closing Date on Draft',
    value: 'comment_close_date',
    elasticSearchType: 'comment_close_date',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  }
];

export const GUIDANCE_PIE_CHART = [
  {
    label: 'Document Type',
    apiKey: 'document_type',
    graphItem: 'document_donut',
    count: 3
  },
  {
    label: 'FDA Organization',
    apiKey: 'fda_organization',
    graphItem: 'organization_donut',
    count: 3
  },
  {
    label: 'Products',
    apiKey: 'products',
    graphItem: 'product_donut',
    count: 5
  }
];
export const barChartStyles = {
  colors: ['#B1BBE4'],
  padding: 0.8,
  labelTextColor: '#ffffff',
  axisText: '#A3A3A3',
  axisBottom: {
    tickRotation: 80
  }
};

export const pieChartStyles = {
  colors: PIECHARTCOLOR,
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  pieLabel: true,
  pieLegend: false,
  innerRadius: 0.7,
  enableLabels: 'both'
};

export const GUIDANCE_REPORT_QUERIES = [
  {
    id: 'guidance_documents_by_year',
    title: {
      title: 'Guidance Documents by Year',
      show: true
    },
    graph: 'BAR',
    query: { 'x-axis': ['issue_datetime'], agg_function: 'count', sort_on_axis: 'x' },
    graphStyle: {
      ...barChartStyles,
      axisLeft: {
        tickRotation: 0
      }
    }
  },
  {
    id: 'document_type',
    title: {
      title: 'Document Type',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['communication_type'],
      agg_function: 'count',
      limit: 3
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'fda_organization',
    title: {
      title: 'FDA Organization',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['center'],
      agg_function: 'count',
      limit: 3
    },
    graphStyle: pieChartStyles
  },
  {
    id: 'products',
    title: {
      title: 'Products',
      show: true
    },
    graph: 'PIE',
    query: {
      'x-axis': ['regulated_product_field'],
      agg_function: 'count',
      limit: 6
    },
    graphStyle: pieChartStyles
  }
];

export const ENTITY_MAPPING: { [key: string]: any } = {
  description: { name: 'Description' },
  issue_date: { name: 'Issue Date ' },
  effective_date: { name: 'Legal Effective Date ' },
  last_modified_date: { name: 'Last Updated Date ' },
  date_adopted: { name: 'Adopted Date ' }
};

export const PAGINATION_DEFAULT_OPTIONS = {
  limit: 20,
  offset: 0
};

export const EXPORT_TYPE = {
  csv: 'csv',
  xlsx: 'xlsx'
};

export const FDA_GUIDANCE_COLUMN_NAME_MAPPING = {
  title: 'Summary',
  issue_datetime: 'Issue Date',
  issuing_office_taxonomy: 'FDA Organization',
  topics_product: 'Topic',
  communication_type: 'Document Type',
  regulated_product_field: 'Product',
  status: 'Guidance Status',
  open_comment: 'Open Comment',
  comment_close_date: 'Comment Closing Date'
};
