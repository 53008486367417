/* eslint-disable import/no-named-as-default */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';

import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import Actions from '../../../store/RegulatoryInformation/actions';

// Components and Interfaces
import CustomTimeline from '../../../components/Timeline/CustomTimeline';
import EmptyPage from '../../../components/EmptyPage/EmptyPage';
import { UrlParameters } from '../../../types/reactRouterDom';
import { fileNomenclature } from '../../../components/csvdownload/csvPropsType';
import { Category } from '../../../components/NewFilters/types';
// Constants
import { APPROVAL_HISTORY, SOURCE_MODULE_MAPPING_FOR_ARIA, TIMELINE_COLUMNS } from './constants';

// APIs
import regulatoryInformationAPI, {
  regulatory360ApiWithFilters
} from '../../../api/pages/Regulatory360';

// utils
import getFilters, { getApplicationType } from './utils';
import NewFilters from '../../../components/NewFilters';
import { CHAT_RIA_ALLOWED_SOURCES } from '../../../components/ChatRIA/const';
import AriaDrawer from '../../SearchResults/components/AriaDrawer';
import AskRIA from '../../../components/NewFilters/AskRIA';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';
import { triggerChatRiaForReg360 } from '../../SearchResults/utils/trggerGlobalChatRia';
import transformData from '../../SearchResults/utils/dataTransform';
import ResultsStore from '../../../store/SearchResults';
import ResultActions from '../../../store/SearchResults/actions';
import getAriaFilters_ from '../../SearchResults/utils/getAriaFilters';
import {
  sortFilterCategoriesOptions,
  sortFilterCatgories
} from '../../../components/NewFilters/utils';
import RESULT_VIEW_TYPES from '../../SearchResults/components/constants';
import AuthContext from '../../../store/Auth/AuthProvider';
import { getGenericSearch } from '../../../api/pages/ResultsPage';
import getUserDetails from '../../SearchResults/utils/getUserDetails';
import { BOTTOM_FILTERS_ORDER } from '../../../components/NewFilters/constants';
import { formatDocumentResults } from '../../SearchResults/utils/documentResultsUtils';
import useAriaShareHandler from '../../../components/AriaShare/useAriaShareHandler';

const ApprovalHistory = () => {
  const { regulatoryState, regulatoryDispatch } = useContext(RegulatoryInformationStore);
  const { state, dispatch } = useContext(GlobalStore) as any;
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { currentUser } = useContext(AuthContext);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [askRiaFilters, setAskRiaFilters] = useState([]);
  const regData: any = {};
  regData[regulatoryState?.db] = {
    results: [regulatoryState?.applicationDetails]
  };
  const reg360DocCount = regulatoryState?.applicationDetails?.document_count;
  const reg360PageCount = regulatoryState?.applicationDetails?.document_total_page_count;
  const transformedData =
    regulatoryState?.db !== 'ct' ? transformData(regData) : [regulatoryState?.applicationDetails];
  const [ariaModalOpen, setAriaModalOpen] = useState(false);
  const { module, source, number } = useParams<UrlParameters>();
  const transformedSource = SOURCE_MODULE_MAPPING_FOR_ARIA[regulatoryState.db];
  const [noContent, setNoContent] = useState(false);
  const [filename, setfilename] = useState<fileNomenclature>({
    prefix: module || 'core',
    module: source || 'us',
    postfix: new Date().toISOString().split('T')[0],
    additional: '_approval_history',
    extension: 'csv'
  });

  const triggerChatRIA = () => {
    const tradeName = regulatoryState?.applicationDetails?.trade_name || '';
    let applicationNumber = regulatoryState?.applicationDetails?.application_number;
    if (source?.toLowerCase() === 'eu') {
      if (regulatoryState?.applicationDetails?.center?.toLowerCase() === 'ema-who')
        applicationNumber = `EMEA-H-W-${applicationNumber}`;
      else applicationNumber = `${applicationNumber}`;
    }
    triggerChatRiaForReg360(applicationNumber, tradeName, GlobalActions, source, dispatch);
  };

  useEffect(() => {
    if (
      regulatoryState?.applicationDetails?.application_number &&
      regulatoryState?.db &&
      !regulatoryState?.isApprovalHistoryFilterApplied
    ) {
      const getData = async () => {
        setLoading(true);
        const data: any = await regulatoryInformationAPI(
          source as string,
          number as string,
          APPROVAL_HISTORY
        );
        if (!data?.data?.body?.length) {
          setNoContent(true);
        } else {
          regulatoryDispatch({ type: Actions.SET_APPROVAL_HISTORY, value: data.data.body });
          const filters = getFilters(
            data.data.body,
            getApplicationType(source as string, regulatoryState?.applicationDetails)
          );
          regulatoryDispatch({ type: Actions.SET_APPROVAL_HISTORY_FILTERS, value: filters });
        }
        setLoading(false);
      };
      getData();
      setfilename({
        prefix:
          regulatoryState?.applicationDetails?.product_name ||
          regulatoryState?.applicationDetails?.generic_name ||
          'core',
        module: source || 'us',
        postfix: new Date().toISOString().split('T')[0],
        additional: '_approval_history',
        extension: 'csv'
      });
    } else if (regulatoryState?.approvalHistory.length === 0) {
      setNoContent(true);
    }
  }, [regulatoryState.db, regulatoryState.applicationDetails, source, number]);

  const getAriaFilters = async (selectedFilters = {}, shouldCallAPI = true) => {
    if (!shouldCallAPI) {
      let ariaFilters = getAriaFilters_(resultsState, sortFilterCategoriesOptions);
      const defaultBottomFilters =
        BOTTOM_FILTERS_ORDER[
          `document-${Object.keys(transformedSource)[0]}-${
            transformedSource[Object.keys(transformedSource)[0]][0]
          }`
        ];
      ariaFilters = ariaFilters.sort((a: any, b: any) =>
        sortFilterCatgories(a.label, b.label, defaultBottomFilters)
      );
      setAskRiaFilters(ariaFilters);
    } else {
      resultsDispatch({
        type: ResultActions.SET_TOP_10_SUMMARY,
        value: []
      });
      resultsDispatch({ type: ResultActions.SET_RIA_RESPONSE_TITLE, value: 'Top Result' });
      resultsDispatch({ type: ResultActions.SET_SHOW_TOP_10_SUMMARY, value: false });
      const filtersForAria = {
        ...(resultsState?.ariaEntityIds &&
          resultsState?.ariaEntityIds?.length > 0 && { entity_id: resultsState.ariaEntityIds }), // add entity id list in payload only if it is present
        ...selectedFilters
      };
      resultsDispatch({ type: ResultActions.SET_FILTERS, value: { ...filtersForAria } });
      const userDetails = getUserDetails(currentUser);
      const API_PAYLOAD = {
        source: transformedSource,
        query: resultsState.decryptedPayload.query,
        view_type: RESULT_VIEW_TYPES.DOCUMENT,
        filterSource: 'default',
        userDetails,
        filters: filtersForAria
      };
      resultsDispatch({ type: ResultActions.SET_IS_CONTENT_LOADING, value: true });
      const res = await getGenericSearch(API_PAYLOAD);
      resultsDispatch({
        type: ResultActions.SET_DOCUMENT_RESULTS,
        value: formatDocumentResults(res?.data?.body?.result)
      });
      resultsDispatch({
        type: ResultActions.SET_SEARCH_IDS,
        value: {
          ...resultsState.searchIds,
          ariaSearchId:
            res?.data?.body?.view_type_metadata?.search_id || res?.data?.body?.search_id || ''
        }
      });
      resultsDispatch({ type: ResultActions.SET_FILTERS, value: { ...filtersForAria } });
      resultsDispatch({ type: ResultActions.SET_DECRYPTED_SOURCE, value: transformedSource });
      resultsDispatch({ type: ResultActions.SET_DECRYPTED_PAYLOAD, value: API_PAYLOAD });
      resultsDispatch({ type: ResultActions.SET_IS_CONTENT_LOADING, value: false });
    }
  };

  useEffect(() => {
    getAriaFilters({}, false);
  }, [resultsState.documentResults]);

  useAriaShareHandler(isLoading, ariaModalOpen, setAriaModalOpen);

  const handleFilterApply = useCallback(
    (filters: any) => {
      const getData = async () => {
        try {
          setLoading(true);
          regulatoryDispatch({
            type: Actions.SET_APPROVAL_HISTORY_APPLIED_FILTERS,
            value: filters
          });
          regulatoryDispatch({
            type: Actions.SET_APPROVAL_HISTORY_FILTERS_APPLIED_FLAG,
            value: !!Object.keys(filters)?.length
          });
          const data = await regulatory360ApiWithFilters(
            regulatoryState.db,
            regulatoryState?.applicationDetails?.application_number,
            APPROVAL_HISTORY,
            {
              filters,
              view_type: RESULT_VIEW_TYPES.APPLICATION
            }
          );
          if (data.status === 200) {
            regulatoryDispatch({ type: Actions.SET_APPROVAL_HISTORY, value: data.data.body });
            setNoContent(false);
            const newfilters = getFilters(
              data.data.body,
              getApplicationType(source as string, regulatoryState?.applicationDetails)
            );
            regulatoryDispatch({
              type: Actions.SET_APPROVAL_HISTORY_FILTERS,
              value: newfilters
            });
          } else {
            setNoContent(true);
            regulatoryDispatch({ type: Actions.SET_APPROVAL_HISTORY, value: [] });
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          regulatoryDispatch({ type: Actions.SET_APPROVAL_HISTORY, value: [] });
        } finally {
          setLoading(false);
          regulatoryDispatch({ type: Actions.SET_SORTING_APPROVAL_HISTORY, value: false });
        }
      };
      getData();
    },
    [source]
  );
  return (
    <Box pb='10px'>
      {noContent && (
        <Box sx={{ height: 'calc(100vh - 290px)' }}>
          <EmptyPage
            message={
              regulatoryState?.isApprovalHistoryFilterApplied
                ? 'No submissions are available based on the applied filters'
                : 'No content for Approval History'
            }
          />
        </Box>
      )}
      <Box sx={{ height: 'calc(100vh - 290px)', overflowY: 'scroll' }}>
        <CustomTimeline
          columns={
            TIMELINE_COLUMNS[
              getApplicationType(source as string, regulatoryState?.applicationDetails)
            ]
          }
          timeline={regulatoryState.approvalHistory}
          triggeredBy='approval_history'
          source={source || 'us'}
          filename={filename}
          filtersApplied={regulatoryState?.isApprovalHistoryFilterApplied}
          showFilters
          isLoading={isLoading || regulatoryState.loading}
        />
      </Box>
      <>
        <NewFilters
          data={regulatoryState.filters.approvalHistory as Category[]}
          applyFilters={handleFilterApply}
          isLoading={isLoading}
          selectedFilters={regulatoryState.appliedFilters}
        />
        <AskRIA
          setModalOpen={setAriaModalOpen}
          triggerChatRIA={triggerChatRIA}
          globalDispatch={dispatch}
          state={state}
          disableChatRia={
            !CHAT_RIA_ALLOWED_SOURCES.includes(source || '') ||
            regulatoryState.applicationDetails?.application_type === 'EUA'
          }
        />
      </>
      {ariaModalOpen && (
        <AriaDrawer
          applications={transformedData}
          sources={transformedSource}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          filters={askRiaFilters as Category[]}
          getFilters={getAriaFilters}
          globalDispatch={dispatch}
          isCount
          DocCount={reg360DocCount}
          PageCount={reg360PageCount}
        />
      )}
    </Box>
  );
};

export default React.memo(ApprovalHistory);
