import React from 'react';

// mui
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';

// styles
import styles from '../../styles/homePage.styles';

const GraphSkeleton = () => {
  return (
    <Stack display='flex' flexDirection='row'>
      <Box sx={{ height: '60vh' }}>
        <Box ml={5} display='flex' flexDirection='column' sx={styles.cards}>
          <Box m={2}>
            <Skeleton height={50} width={150} />
          </Box>
          <Skeleton sx={styles.paper} />
          <Skeleton sx={styles.paper} />
          <Skeleton sx={styles.paper} />
          <Skeleton sx={styles.paper} />
        </Box>
      </Box>
      <Box display='flex' flexDirection='column' sx={styles.box}>
        <Box display='flex' flexDirection='column' sx={{ width: '100%' }}>
          <Box display='flex' justifyContent='space-between' flexDirection='row'>
            <Skeleton height={32} width={200} />
            <Skeleton height={28} width={150} />
          </Box>
          <Box display='flex' justifyContent='center' alignItems='center' sx={styles.barGraph}>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-end'
              alignItems='space-between'
              sx={styles.loaderBox}>
              <Box
                display='flex'
                justifyContent='space-between'
                flexWrap='wrap-reverse'
                sx={styles.barGraphRect}>
                <Skeleton variant='rectangular' height='90%' width='.2%' />
                <Skeleton variant='rectangular' height='60%' width='3%' />
                <Skeleton variant='rectangular' height='80%' width='3%' />
                <Skeleton variant='rectangular' height='30%' width='3%' />
                <Skeleton variant='rectangular' height='50%' width='3%' />
                <Skeleton variant='rectangular' height='50%' width='3%' />
                <Skeleton variant='rectangular' height='60%' width='3%' />
                <Skeleton variant='rectangular' height='80%' width='3%' />
                <Skeleton variant='rectangular' height='30%' width='3%' />
                <Skeleton variant='rectangular' height='50%' width='3%' />
                <Skeleton variant='rectangular' height='50%' width='3%' />
              </Box>
              <Skeleton variant='rectangular' height='.2%' width='100%' />
            </Box>
          </Box>
        </Box>
        <Box display='flex' flexDirection='row' sx={styles.pieCharts}>
          <Box sx={styles.pieContainers} display='flex' flexDirection='column'>
            <Box display='flex' justifyContent='space-between'>
              <Skeleton height={32} width={150} />
              <Skeleton height={24} width={150} />
            </Box>
            <Box display='flex' alignSelf='center' pt={4}>
              <CircularProgress
                value={90}
                thickness={5}
                size='5rem'
                variant='indeterminate'
                sx={{
                  color: '#adadac',
                  opacity: 0.5
                }}
              />
            </Box>
          </Box>
          <Box sx={styles.pieContainers} display='flex' flexDirection='column'>
            <Box display='flex' justifyContent='space-between'>
              <Skeleton height={32} width={150} />
              <Skeleton height={24} width={150} />
            </Box>
            <Box display='flex' alignSelf='center' pt={4}>
              <CircularProgress
                value={90}
                thickness={5}
                size='5rem'
                variant='indeterminate'
                sx={{
                  color: '#adadac',
                  opacity: 0.5
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default React.memo(GraphSkeleton);
