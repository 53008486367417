import React from 'react';
import { Backdrop, Box, Typography } from '@mui/material';
import LinearBuffer from './Loader';

const ReportLoader = ({ open, message }: { open: boolean; message: string }) => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        bgcolor: 'rgba(0, 0, 0, 0.35)',
        zIndex: theme => theme.zIndex.drawer + 1
      }}
      open={open}>
      <Box width='50%' sx={{ marginLeft: '20vw' }}>
        <Typography p={2} textAlign='center' fontWeight='bold' fontSize={22}>
          {message}
        </Typography>
        <LinearBuffer />
      </Box>
    </Backdrop>
  );
};

export default React.memo(ReportLoader);
