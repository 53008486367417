import React from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import 'suneditor/dist/css/suneditor.min.css';
import styles from './CoverpageTextEditor.styles';

const CoverPageDescription = ({ onChange, value }: any) => {
  return (
    <Box sx={styles.main}>
      <TextField
        value={value}
        onChange={(event: any) => onChange(event.target.value)}
        multiline
        fullWidth
        variant='standard'
        InputProps={{
          disableUnderline: true,
          sx: {
            fontSize: '1em',
            minHeight: 32,
            textarea: {
              textAlign: 'center'
            }
          }
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ maxLength: 256 }}
        placeholder='Add report description here'
      />
    </Box>
  );
};

export default React.memo(CoverPageDescription);
