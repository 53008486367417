import React, { lazy, Suspense } from 'react';

// material ui
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

// context
// styles
import Styles from '../../../styles/ApplicationContent.styles';
import SuspenseCircularLoader from '../../../../../helpers/SuspenseCircularLoader';

const GaugeChart = lazy(() => import('react-gauge-chart'));

// image

const AdministrativeSum = props => {
  const classes = Styles();

  const getDialValue = value => {
    var result = 0;
    if (value >= 0 && value <= 365) {
      result = getPercentage(value, 0, 365, 0);
    } else if (value > 366 && value < 720) {
      result = getPercentage(value, 366, 720, 0.33);
    } else if (value >= 721) {
      if (value <= 1095) {
        result = getPercentage(value, 721, 1095, 0.66);
      } else if (value >= 1095) {
        result = getPercentage(value, 721, value, 0.66);
      }
    }
    return result;
  };

  function getPercentage(value, lowerBound, upperBound, segmentAdjustment) {
    return (value - lowerBound) / (upperBound - lowerBound) / 3 + segmentAdjustment;
  }

  return (
    <Grid container className={classes.adminsum}>
      <Grid item xs={12}>
        <Typography className={classes.Heading}>Approval Tracks</Typography>
        <Grid display='flex' className={classes.icons}>
          {' '}
          <Tooltip
            title={'Accelerated Approval : ' + props.data['accelerated_approval']}
            aria-label='Button Link'
          >
            <Avatar
              className={
                props.data['accelerated_approval'] === 'No' ? classes.avatarLight : classes.avatar
              }
            >
              AA
            </Avatar>
          </Tooltip>
          <Tooltip
            title={'Breakthrough Designation : ' + props.data['breakthrough_designation']}
            aria-label='Button Link'
          >
            <Avatar
              className={
                props.data['breakthrough_designation'] === 'No'
                  ? classes.avatarLight
                  : classes.avatar
              }
            >
              BT
            </Avatar>
          </Tooltip>
          <Tooltip title={'Fast Track : ' + props.data['fast_track']} aria-label='Button Link'>
            <Avatar
              className={props.data['fast_track'] === 'No' ? classes.avatarLight : classes.avatar}
            >
              FT
            </Avatar>
          </Tooltip>
          <Tooltip title={'Orphan : ' + props.data['orphan_status']} aria-label='Button Link'>
            <Avatar
              className={
                props.data['orphan_status'] === 'No' ? classes.avatarLight : classes.avatar
              }
            >
              OR
            </Avatar>
          </Tooltip>
          {props.data['review_priority'] === 'No' ? (
            <Tooltip title={'Review Priority : ' + 'Standard'} aria-label='Button Link'>
              <Avatar className={classes.avatar}>S</Avatar>
            </Tooltip>
          ) : (
            <Tooltip title={'Review Priority : ' + 'Priority'} aria-label='Button Link'>
              <Avatar className={classes.avatar}>P</Avatar>
            </Tooltip>
          )}
          <Tooltip
            title={'Complete Response : ' + props.data['cr_letter']}
            aria-label='Button Link'
          >
            <Avatar
              className={props.data['cr_letter'] === 'No' ? classes.avatarLight : classes.avatar}
            >
              CR
            </Avatar>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.Heading}>CDER Division</Typography>
        <Typography className={classes.subheadingLabel}> {props.data.cder_division} </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.Heading}>CDER OFFICE</Typography>
        <Typography className={classes.subheadingLabel}> {props.data.cder_office} </Typography>
      </Grid>
      <Grid item xs={12}>
        {' '}
        Approval Time (days)
        {props.data.time_of_Approval_No_of_days &&
        props.data.time_of_Approval_No_of_days != 'N/A' &&
        props.data.time_of_Approval_No_of_days != 'NA' ? (
          <Suspense fallback={<SuspenseCircularLoader />}>
            <GaugeChart
              id='gauge-chart5'
              nrOfLevels={20}
              needleColor='#00A178'
              colors={['#46B163', '#F8B518', '#FF5A48']}
              percent={getDialValue(props.data.time_of_Approval_No_of_days)}
              arcPadding={0.05}
              arcWidth={0.45}
              hideText={true}
              needleBaseColor='#06C66C'
              arcWidth={0.5}
            />
          </Suspense>
        ) : (
          <div>Capture in Progress</div>
        )}
        <Box className={classes.adminDate}>{props.data.time_of_Approval_No_of_days} Days</Box>
      </Grid>
    </Grid>
  );
};

export default AdministrativeSum;
