/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { deepClone } from '@mui/x-data-grid/utils/utils';
import { prepareCSVData, prepareData } from './columnOrder';

// headers based on source for excel download
// Should be moved to an application level utils file.
const getCurrentDate = () => {
  const today = new Date();
  let dd = today.getDate();

  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${mm}-${dd}-${yyyy}`;
};
export const saveScrollPosition = () => {
  return {
    x: window.scrollX,
    y: window.scrollY
  };
};
export const restoreScrollPosition = scrollPosition => {
  window.scrollTo(scrollPosition.x, scrollPosition.y);
};
const handleDownload = async (
  value,
  results,
  source,
  module,
  fileHeader = [],
  uniqueResults = false
) => {
  if (!results) {
    throw new Error('No data found to be downloaded');
  }
  const scrollPosition = saveScrollPosition();

  // do a deep copy of the results use loadash
  // to avoid changing the original
  const localResults = uniqueResults
    ? deepClone(results).filter(item => !item.is_duplicate)
    : deepClone(results);

  localResults.forEach(
    item =>
      (item.text = item.text?.replaceAll('<b>', '')?.replaceAll('</b>', '')?.replaceAll('\n', ''))
  );

  if (value === 'excelFile') {
    const [data, widths] = prepareData(source, localResults, fileHeader);
    const XLSX = await import('xlsx');
    const workSheet = XLSX.utils.aoa_to_sheet(data);
    const workBook = XLSX.utils.book_new();
    workSheet['!cols'] = widths;
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1');
    restoreScrollPosition(scrollPosition);
    return XLSX.writeFile(workBook, `Aria_Results_${module}_${getCurrentDate()}.xlsx`);
  }
  // eslint-disable-next-line no-unused-vars
  const [csvData, columnHeaders] = prepareCSVData(source, localResults, fileHeader);

  const { ExportToCsv } = await import('export-to-csv');
  const options = {
    fieldSeparator: value === 'commaFile' ? ',' : ';',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: fileHeader.length > 0,
    title: fileHeader.join('\n'),
    useTextFile: false,
    useBom: true,
    // headers: columnHeaders,
    filename: `Aria_Results_${module}_${getCurrentDate()}`,
    useKeysAsHeaders: true
  };

  const csvExporter = new ExportToCsv(options);
  restoreScrollPosition(scrollPosition);
  return csvExporter.generateCsv(csvData);
};

export default handleDownload;
