// temp file to be used for making business intelligence work with cards page until new wireframe for this view comes in

import React, { useContext, useEffect, useState } from 'react';
//MATERIAL UI IMPORTS
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import capitalize from 'lodash/capitalize';
import uniqueId from 'lodash/uniqueId';
import Divider from '@mui/material/Divider';

// other components
import BiologicsStyles from '../styles/Biologics.styles';
import Cards from './Cards';
import LineGraph from './LineGraph';
import PlusIcon from '../../../Images/svgs/plus';

// store
import Store from '../../../store';
import Actions from '../../../store/actions';
import { getBlaStatsSubset } from '../../../api/pages/Biologics';
// context provider
import ResultStore from '../../../store/SearchResults';
import PieChart from '../../CustomComponents/Graphs/PieChart';

import { pieChartStyles, REPORT_QUERIES_US_RESULTS } from './constants';

const getPieChartData = data => {
  return Object.keys(data).map(key => ({
    id: capitalize(key),
    label: capitalize(key),
    value: data[key]
  }));
};

const BusinessIntelligence = ({ payload = [] }) => {
  const classes = BiologicsStyles();
  const { dispatch } = useContext(Store);
  const { resultsState } = useContext(ResultStore);
  const [percentages, setPercentages] = useState();
  const [linegraph, setLineGraph] = useState();
  const [cards, setCards] = useState();

  useEffect(() => {
    async function getData() {
      try {
        let response = {};
        // check if bla stats needs to be generated for specific list of BLAs or the whole biologics
        if (payload?.length) {
          response = await getBlaStatsSubset(payload);
        }

        const percentagesData = {
          acceleratedApproval: getPieChartData(
            response.data.Success.business_intelligence.Accelerated_Approval
          ),
          breakthroughDesignation: getPieChartData(
            response.data.Success.business_intelligence.Breakthrough_designation
          ),
          fasttrack: getPieChartData(response.data.Success.business_intelligence.Fast_track),
          orphanStatus: getPieChartData(response.data.Success.business_intelligence.Orphan_status)
        };
        setPercentages(percentagesData);
        let approvaltimeGraphData = [];
        response.data.Success.business_intelligence.median_approval_time.forEach(
          ({ year, count }) => {
            const mean = response.data.Success.business_intelligence.approvaltimeGraph.find(
              elem => elem.year === year
            )?.count;
            approvaltimeGraphData.push({
              year,
              count: parseInt(count),
              mean: mean ? parseInt(mean) : 0
            });
          }
        );
        approvaltimeGraphData.sort((a, b) => parseInt(a.year) - parseInt(b.year));
        let approvalyearGraphData = [];
        for (let elem of response.data.Success.business_intelligence.approvalyearGraph) {
          let year = elem.year;
          let count = parseInt(elem.count);
          approvalyearGraphData.push({ count, year });
        }
        approvalyearGraphData.sort((a, b) => parseInt(a.year) - parseInt(b.year));

        const lineGraphData = {
          approvalTimeGraph: approvaltimeGraphData,
          approvalYearGraph: approvalyearGraphData
        };
        setLineGraph(lineGraphData);
        setCards([
          {
            id: 'Applications',
            count: response.data.Success.business_intelligence.bla_count
          },
          {
            id: 'Active Ingredients',
            count: response.data.Success.business_intelligence.activeIngredients
          },
          {
            id: 'Formulations',
            count: response.data.Success.business_intelligence.formulations
          }
        ]);
      } catch (e) {
        console.error(e);
      }
    }

    getData();
  }, [payload]);

  const handleAddToReport = async id => {
    const addedReport = REPORT_QUERIES_US_RESULTS.find(report => report.id === id);
    const random = Math.floor(Math.random() * 1000);
    dispatch({
      type: Actions.ADD_TEMPLATE_TO_REPORT,
      value: {
        templateType: 'custom',
        chartType: addedReport.graph,
        sectionType: 'CHART',
        id: uniqueId(`bi_${random}_`),
        style: {
          placement: {
            h: 40,
            w: 12
          },
          graphStyle: addedReport.graphStyle,
          title: addedReport.title
        },
        data: {
          source: 'us',
          search_type: 'quicksearch',
          search_id: resultsState.searchIds.applicationSearchId,
          query: addedReport.query
        }
      }
    });
    await dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message: 'Successfully added to Report.', color: 'success' }
    });
  };

  return (
    <Grid container>
      <Grid item lg={2}>
        <Cards data={cards} />
      </Grid>
      <Grid item lg={10}>
        <Grid item lg={12}>
          <LineGraph data={linegraph} onAddToReport={handleAddToReport} />
        </Grid>
        <Grid item lg={12}>
          <Box className={classes.percentageGraphsContainer}>
            {percentages ? (
              <Box display='flex'>
                <Box height='200px' width='200px' flex={1} pr={1}>
                  <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography className={classes.headings} align='left'>
                      Accelerated Approvals
                    </Typography>
                    <Typography
                      onClick={() => handleAddToReport('accelerated_approval_percentage')}
                      className={classes.addPercentage}>
                      <PlusIcon /> Add to Report
                    </Typography>
                  </Box>
                  <PieChart data={percentages?.acceleratedApproval} style={pieChartStyles} />
                </Box>
                <Divider orientation='vertical' sx={{ height: '230px' }} />
                <Box height='200px' width='200px' flex={1} pr={1}>
                  <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography className={classes.headings} align='left'>
                      Breakthrough Designation
                    </Typography>
                    <Typography
                      onClick={() => handleAddToReport('breakthrough_designation_percentage')}
                      className={classes.addPercentage}>
                      <PlusIcon /> Add to Report
                    </Typography>
                  </Box>
                  <PieChart data={percentages?.breakthroughDesignation} style={pieChartStyles} />
                </Box>
                <Divider orientation='vertical' sx={{ height: '230px' }} />
                <Box height='200px' width='200px' flex={1} pr={1}>
                  <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography className={classes.headings} align='left'>
                      Fast Track
                    </Typography>
                    <Typography
                      onClick={() => handleAddToReport('fast_track_percentage')}
                      className={classes.addPercentage}>
                      <PlusIcon /> Add to Report
                    </Typography>
                  </Box>
                  <PieChart data={percentages?.fasttrack} style={pieChartStyles} />
                </Box>
                <Divider orientation='vertical' sx={{ height: '230px' }} />
                <Box height='200px' width='200px' flex={1} pr={1}>
                  <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography className={classes.headings} align='left'>
                      Orphan Designation
                    </Typography>
                    <Typography
                      onClick={() => handleAddToReport('orphan_designation_percentage')}
                      className={classes.addPercentage}>
                      <PlusIcon /> Add to Report
                    </Typography>
                  </Box>
                  <PieChart data={percentages?.orphanStatus} style={pieChartStyles} />
                </Box>
              </Box>
            ) : (
              <Box height='100px' display='flex' justifyContent='space-between'>
                <Skeleton variant='rectangular' width='20%' height='100%' style />
                <Skeleton variant='rectangular' width='20%' height='100%' />
                <Skeleton variant='rectangular' width='20%' height='100%' />
                <Skeleton variant='rectangular' width='20%' height='100%' />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BusinessIntelligence;
