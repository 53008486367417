import { Box, ListItemAvatar, ListItemButton, ListItemText, Skeleton } from '@mui/material';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UserProfileListIcons from '../UserProfileListIcons';
import useStyles from '../styles/UserList.styles';
import { sourceMap } from '../../ResultsPage/components/SearchSuggestions';
import { ELASTIC_SEARCH_ATTRIBUTES_LABELS } from '../../ResultsPage/utils/constants';
import { CORE_SOURCE_MENU_ITEMS_MODULE_REVERSE_MAPPING } from '../../Home/const';
import FilterToolTip from '../Components/FilterToolTip';
import { encodeObjectToBase64 } from '../../../utils/encodeDecodeObject';
import RESULT_VIEW_TYPES from '../../SearchResults/components/constants';

// eslint-disable-next-line react/prop-types
const QuickSearchButton = ({ search, fromAria, smallIcon }) => {
  // eslint-disable-next-line react/prop-types
  const { module, category, term, source, id, filters, searchData } = search;
  const [path, setPath] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const isSourceCt = sources => {
    return (
      Object.keys(sources).length === 1 &&
      (sources?.ct || (sources?.eu && sources?.eu.includes('euctr')))
    );
  };

  const handleClick = () => {
    const isSourceOnlyCt = isSourceCt(source);

    const payload = {
      search_term: term,
      source,
      application_search_id: id,
      view_type: isSourceOnlyCt ? RESULT_VIEW_TYPES.CT : RESULT_VIEW_TYPES.APPLICATION
    };

    if (isSourceOnlyCt) {
      payload.ct_search_id = id;
    }

    const encodedPayload = encodeObjectToBase64(payload) ?? '';
    const url = `/search/${encodedPayload}`;
    window.open(url, '_blank');
  };

  useEffect(() => {
    let localPath = '';

    if (Object.keys(source).length > 1) {
      localPath = 'Multiple Sources';
    } else {
      const currSource = Object.keys(source)[0];
      if (
        Array.isArray(source[currSource]) &&
        source[currSource].length > 0 &&
        ['hma', 'hpra'].includes(source[currSource][0])
      ) {
        localPath = 'EU - NCA & MR';
      }
      if (
        Array.isArray(source[currSource]) &&
        source[currSource].length > 0 &&
        ['euctr'].includes(source[currSource][0])
      ) {
        localPath = `EU Clinical Trials Register`;
      } else {
        localPath = `${sourceMap[currSource.toLowerCase()] ?? ''}`;
      }
      if (Array.isArray(source[currSource]) && source[currSource].length > 0) {
        const sourcePath = source[currSource].map(element => {
          if (element in CORE_SOURCE_MENU_ITEMS_MODULE_REVERSE_MAPPING) {
            return `${CORE_SOURCE_MENU_ITEMS_MODULE_REVERSE_MAPPING[element]}`;
          }
          return '';
        });
        if (localPath.length > 0 && sourcePath.length > 0) {
          localPath += ' | ';
        }
        localPath += `${sourcePath.join(', ')}`;
      }
    }

    if (module) {
      localPath +=
        ` | ${
          ELASTIC_SEARCH_ATTRIBUTES_LABELS[source?.toLowerCase()]
            ? ELASTIC_SEARCH_ATTRIBUTES_LABELS[source?.toLowerCase()][category]
            : 'TBD'
        }` || '';
    }
    if (filters && Object.keys(filters).length !== 0) {
      localPath = `${localPath} | With filters`;
    }
    if (category === 'first_approval_date' || category === 'sales_date') {
      setSearchTerm(
        searchData?.first_approval_date?.join(' - ') || searchData?.sales_date?.join(' - ')
      );
    } else {
      setSearchTerm(term);
    }
    setPath(localPath);
    setLoading(false);
  }, [module, category, term, source, id, filters]);

  if (loading) {
    return (
      <ListItemButton fullWidth disablePadding disabled>
        <ListItemAvatar>
          <Skeleton variant='circular' width={50} height={50} />
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton variant='text' sx={{ fontSize: '1rem', width: 100 }} />}
          secondary={<Skeleton variant='text' sx={{ fontSize: '1rem', width: 500 }} />}
        />
      </ListItemButton>
    );
  }
  return (
    <ListItemButton
      disableRipple
      disablePadding
      className={classes.list}
      sx={fromAria ? { width: 'auto' } : {}}
      onClick={handleClick}>
      <ListItemAvatar
        sx={{
          maxWidth: smallIcon ? 45 : fromAria ? 45 : 50,
          minWidth: smallIcon ? 45 : 56,
          mr: smallIcon ? 1 : 0
        }}>
        <Box
          sx={{
            p: '.6em',
            width: '100%'
          }}>
          <UserProfileListIcons type='quickSearch' smallIcon={smallIcon} />
        </Box>
      </ListItemAvatar>
      {/* eslint-disable-next-line react/prop-types */}
      <FilterToolTip filters={filters} source={source}>
        <ListItemText
          primary={path}
          secondary={searchTerm}
          className={classes.listItem}
          primaryTypographyProps={{ variant: 'activityTitle' }}
          secondaryTypographyProps={{ variant: 'activityDescription' }}
        />
      </FilterToolTip>
    </ListItemButton>
  );
};
QuickSearchButton.prototype = {
  search: PropTypes.object.isRequired
};
export default QuickSearchButton;
