import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  greenButton: {
    color: `${theme.palette.secondary.dark} !important`
  },
  list: {
    width: '100%',
    paddingLeft: '0 !important',
    color: '#4F4F4F !important',
    '& > div >  p': {
      color: `black !important`
    },
    '&:hover': {
      backgroundColor: `initial !important`,
      color: `${theme.palette.secondary.dark} !important`,

      '& > div >  p': {
        color: `${theme.palette.secondary.dark} !important`
      }
    }
  },
  listItem: {
    '&:hover': {
      textDecoration: 'underline !important',
      cursor: 'pointer'
    }
  }
}));
