import React, { MouseEvent, memo } from 'react';
import { Box, Typography, Tooltip, IconButton, Divider, Drawer, Button } from '@mui/material';
import { AddToReportIcon, CrossIcon } from '../../../assets/svgs/Icons';
import styles from './BottomDrawer.styles';
import buttonStyles from '../../../pages/SearchResults/styles/Buttons.styles';

const BottomDrawer = ({
  isDrawerOpen,
  closeDrawer,
  children,
  handleReportChoiceAnchorElement,
  hideReportIcon = false,
  hideDivider = false
}: any) => {
  return (
    <Drawer sx={styles.drawer} anchor='bottom' open={isDrawerOpen}>
      <Box sx={styles.container}>
        <Box sx={styles.headerWrapper}>
          <Box sx={styles.titleWrapper}>
            <Typography sx={styles.title}>Visualize</Typography>
            <Box sx={{ ...styles.actionsWrapper, ...(hideReportIcon && { left: '95%' }) }}>
              {hideReportIcon || (
                <>
                  <Button
                    onClick={(event: MouseEvent<HTMLButtonElement>) =>
                      handleReportChoiceAnchorElement(event.currentTarget)
                    }
                    startIcon={<AddToReportIcon sx={buttonStyles.primaryButtonIcon} />}
                    sx={buttonStyles.primaryButton}>
                    Create Chart
                  </Button>
                  <Box sx={styles.separator} />
                </>
              )}
              <Tooltip title='Close'>
                <IconButton onClick={closeDrawer}>
                  <CrossIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box sx={styles.dividerWrapper}>
            <Divider sx={styles.divider} />
          </Box>
        </Box>
        {!hideDivider && <Divider />}
        <Box sx={styles.childrenWrapper}>{children}</Box>
      </Box>
    </Drawer>
  );
};

export default memo(BottomDrawer);
