const ReportLogo = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 16 18'
      fill='#BDBDBD'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.3108 3.7629L11.6334 0.1512C11.5349 0.054449 11.4024 0.000164289 11.2644 0L1.9674 0C1.44583 0.000714314 0.945831 0.208223 0.577027 0.577027C0.208223 0.945831 0.000714314 1.44583 0 1.9674V16.0326C0.000714314 16.5542 0.208223 17.0542 0.577027 17.423C0.945831 17.7918 1.44583 17.9993 1.9674 18H13.5C14.0216 17.9993 14.5216 17.7918 14.8904 17.423C15.2592 17.0542 15.4667 16.5542 15.4674 16.0326V4.14C15.4674 4.06998 15.4535 4.00065 15.4265 3.93606C15.3994 3.87147 15.3598 3.81291 15.3099 3.7638L15.3108 3.7629ZM11.79 1.7847L13.6503 3.6117H11.9592C11.9149 3.61146 11.8724 3.59375 11.8411 3.56241C11.8097 3.53107 11.792 3.48862 11.7918 3.4443L11.79 1.7847ZM13.5 16.9452H1.9674C1.72551 16.9447 1.49366 16.8484 1.32262 16.6774C1.15158 16.5063 1.05528 16.2745 1.0548 16.0326V1.9674C1.05504 1.72544 1.15126 1.49345 1.32236 1.32236C1.49345 1.15126 1.72544 1.05504 1.9674 1.0548H10.737V3.4443C10.7375 3.7683 10.8664 4.0789 11.0955 4.308C11.3246 4.5371 11.6352 4.66602 11.9592 4.6665H14.4144V16.0317C14.4144 16.1517 14.3907 16.2706 14.3448 16.3815C14.2988 16.4924 14.2314 16.5931 14.1465 16.678C14.0616 16.7628 13.9608 16.8301 13.8499 16.8759C13.7389 16.9218 13.62 16.9453 13.5 16.9452Z'
        fill='inherit'
      />
      <path
        d='M11.3957 8.36731H3.96172C3.82185 8.36731 3.6877 8.42287 3.58879 8.52178C3.48989 8.62068 3.43433 8.75483 3.43433 8.89471C3.43433 9.03458 3.48989 9.16873 3.58879 9.26764C3.6877 9.36655 3.82185 9.42211 3.96172 9.42211H11.3957C11.5356 9.42211 11.6697 9.36655 11.7687 9.26764C11.8676 9.16873 11.9231 9.03458 11.9231 8.89471C11.9231 8.75483 11.8676 8.62068 11.7687 8.52178C11.6697 8.42287 11.5356 8.36731 11.3957 8.36731Z'
        fill='inherit'
      />
      <path
        d='M3.96172 11.5317H10.0232C10.1631 11.5317 10.2972 11.4761 10.3961 11.3772C10.4951 11.2783 10.5506 11.1442 10.5506 11.0043C10.5506 10.8644 10.4951 10.7303 10.3961 10.6314C10.2972 10.5325 10.1631 10.4769 10.0232 10.4769H3.96172C3.82185 10.4769 3.6877 10.5325 3.58879 10.6314C3.48989 10.7303 3.43433 10.8644 3.43433 11.0043C3.43433 11.1442 3.48989 11.2783 3.58879 11.3772C3.6877 11.4761 3.82185 11.5317 3.96172 11.5317Z'
        fill='inherit'
      />
      <path
        d='M10.754 12.5856H3.96172C3.82185 12.5856 3.6877 12.6412 3.58879 12.7401C3.48989 12.839 3.43433 12.9731 3.43433 13.113C3.43433 13.2529 3.48989 13.387 3.58879 13.4859C3.6877 13.5848 3.82185 13.6404 3.96172 13.6404H10.754C10.8939 13.6404 11.028 13.5848 11.127 13.4859C11.2259 13.387 11.2814 13.2529 11.2814 13.113C11.2814 12.9731 11.2259 12.839 11.127 12.7401C11.028 12.6412 10.8939 12.5856 10.754 12.5856Z'
        fill='inherit'
      />
    </svg>
  );
};
export default ReportLogo;
