import { createSvgIcon } from '@mui/material';

const ArrowLeftIcon = createSvgIcon(
  <svg width='17' height='23' viewBox='0 0 17 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='path-1-inside-1_1890_7118' fill='white'>
      <path d='M15 3L4.89504 10.4091C3.70166 11.2841 3.70164 12.7159 4.89504 13.5909L15 21' />
    </mask>
    <path
      d='M15 3L4.89504 10.4091C3.70166 11.2841 3.70164 12.7159 4.89504 13.5909L15 21'
      fill='#0D917D'
    />
    <path
      d='M15.4425 3.6035C15.7758 3.35912 15.8479 2.89081 15.6035 2.55751C15.3591 2.2242 14.8908 2.15212 14.5575 2.3965L15.4425 3.6035ZM4.89504 10.4091L4.45255 9.80556L4.45254 9.80556L4.89504 10.4091ZM4.89504 13.5909L5.33754 12.9874L5.33753 12.9874L4.89504 13.5909ZM14.5575 21.6035C14.8908 21.8479 15.3591 21.7758 15.6035 21.4425C15.8479 21.1092 15.7758 20.6409 15.4425 20.3965L14.5575 21.6035ZM14.5575 2.3965L4.45255 9.80556L5.33753 11.0126L15.4425 3.6035L14.5575 2.3965ZM4.45254 9.80556C3.69549 10.3606 3.25166 11.1479 3.25166 12C3.25165 12.852 3.69548 13.6393 4.45255 14.1944L5.33753 12.9874C4.9012 12.6675 4.74834 12.3013 4.74834 12C4.74834 11.6986 4.90121 11.3325 5.33754 11.0126L4.45254 9.80556ZM4.45254 14.1944L14.5575 21.6035L15.4425 20.3965L5.33754 12.9874L4.45254 14.1944Z'
      fill='#0D917D'
      mask='url(#path-1-inside-1_1890_7118)'
    />
  </svg>,
  'ArrowLeft'
);

export default ArrowLeftIcon;
