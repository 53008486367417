import {
  ROUTE_ARIA_MAIN,
  ROUTE_ARIA_MAIN_SEARCH_ID,
  ROUTE_CDP,
  ROUTE_CHMP_MODULE,
  ROUTE_REGULATORY_360,
  ROUTE_SEARCH_RESULTS,
  ROUTE_SPECIALITY_DESIGNATION,
  ROUTE_SPECIALITY_DESIGNATION_SEARCH
} from './const';
import Aria from './pages/Aria';
import HelpPage from './pages/Help';
import Comparison from './pages/Comparison';
import CDP from './pages/CDP';
import Activity from './pages/UserProfilev2/components/Activity/index';
import SavedItemsAndProjects from './pages/UserProfilev2/components/SavedItemsAndProjects';
import Reports from './pages/UserProfilev2/components/Reports';
import PMRImpact from './components/PMRImpact';
import Messages from './pages/UserProfile/Moxo/Messages';
import ServiceRequests from './pages/UserProfile/Moxo/ServiceRequests';
import NoResultsView from './pages/UserProfilev2/components/NoResultsView';

// route components
import SearchResults from './pages/SearchResults';
import Home from './pages/Home';
import Biologics from './components/Biologics';
import PMRSurveillance from './components/PMRSurveillance';
import EUCTRPediatrics from './components/EUCTRPediatrics';
import PediatricsEMA from './pages/PediatricsEMA';
import AdcommModule from './pages/AdcommModule';
import ApprovalPathway from './components/ApprovalPathway';
import Resgistration from './components/Resgistration';
import PediatricsFDA from './pages/PediatricsFDA';
import GuidanceDocuments from './pages/Guidance';
import EMAGuidanceDocuments from './pages/EMAGuidance';
import CanadaGuidanceDocuments from './pages/CanadaGudiance';
import CanadaConsultationDocuments from './pages/CanadaConsultation';
import HTADocuments from './pages/HTA';
import Letters from './pages/Letters';
import VideoItem from './pages/Help/components/VideoItem/VideoItem';
import ChatRIAReportReference from './components/ChatRIA/component/ChatRIAReportReference';

// Reg360
import RegulatoryInformation from './pages/RegulatoryInformation';
import SpecialityDesignation from './pages/SpecialityDesignation/SpecialityDesignation';
import Resources from './components/Resources';
import PdfLayout from './components/Report/Layout';
import Chmp from './pages/Chmp';
import PDCO from './pages/PDCO';
import DrugDevelopmentTools from './pages/DrugDevelopmentTools';
import Notifications from './pages/Notifications/Notifications';
import OrphanDesignations from './pages/OrphanDesignations';
import Subscriptions from './pages/UserProfilev2/components/Subscriptions';

export const PRIVATE_ROUTES = [
  {
    id: 'home',
    path: '/core',
    component: Home,
    exact: true
  },
  {
    id: 'bilogics',
    path: '/biologics',
    component: Biologics,
    exact: true
  },
  {
    id: 'pmrImpact',
    path: '/pmr/impact',
    component: PMRImpact,
    exact: true
  },
  {
    id: 'pmrSurveillance',
    path: '/pmr/surveillance',
    component: PMRSurveillance,
    exact: true
  },
  {
    id: 'euctrPediatrics',
    path: '/euctr/pediatrics',
    component: EUCTRPediatrics,
    exact: true
  },
  {
    id: 'approvalPathway',
    path: '/505b2',
    component: ApprovalPathway,
    exact: true
  },
  {
    id: 'registration',
    path: '/registration',
    component: Resgistration,
    exact: true
  },
  {
    id: 'adcommModule',
    path: '/adcomm',
    component: AdcommModule,
    exact: true
  },
  /**
   * User Profile is disabled
   * DO NOT REMOVE COMMENTS !!P
   */
  {
    id: 'userProfile',
    path: '/account',
    component: [
      {
        id: 'userProfileActivity',
        path: '/account/activity',
        component: Activity,
        exact: true
      },
      {
        id: 'userProfileSavedItems',
        path: '/account/saved',
        component: () => <SavedItemsAndProjects pageMode='savedItems' />,
        exact: true
      },
      {
        id: 'userProfileSubscriptions',
        path: '/account/subscriptions',
        component: Subscriptions,
        exact: true
      },
      {
        id: 'userProfileReports',
        path: '/account/reports',
        component: Reports,
        exact: true
      },
      {
        id: 'userProfileProject',
        path: '/account/project',
        component: NoResultsView,
        exact: true
      },
      {
        id: 'userProfileProject',
        path: '/account/project/:projectId',
        component: () => <SavedItemsAndProjects pageMode='project' />,
        exact: true
      },
      {
        id: 'userProfileMessages',
        path: '/account/messages',
        component: Messages,
        exact: true
      },
      {
        id: 'serviceRequests',
        path: '/account/serviceRequests',
        component: ServiceRequests,
        exact: true
      }
    ],
    exact: true
  },
  {
    id: 'collaborate',
    path: '/collaborate',
    component: Messages,
    exact: true
  },
  {
    id: 'pediatricsFDA',
    path: '/pediatrics',
    component: PediatricsFDA,
    exact: true
  },
  {
    id: 'pediatricsEMAInsights',
    path: '/pediatricsEMA',
    component: PediatricsEMA,
    exact: true
  },
  {
    id: 'guidanceDocumentsInsights',
    path: '/guidanceDocuments',
    component: GuidanceDocuments,
    exact: true
  },
  {
    id: 'emaGuidanceDocumentsInsights',
    path: '/ema_guidance',
    component: EMAGuidanceDocuments,
    exact: true
  },
  {
    id: 'canadaGuidanceDocumentsInsights',
    path: '/canada-guidance/:tab',
    component: CanadaGuidanceDocuments,
    exact: true
  },
  {
    id: 'canadaGuidanceDocumentsInsights',
    path: '/canada-guidance',
    component: CanadaGuidanceDocuments,
    exact: true
  },
  {
    id: 'canadaConsultationDocumentsInsights',
    path: '/consultation/canada',
    component: CanadaConsultationDocuments,
    exact: true
  },
  {
    id: 'HTADocumentsInsights',
    path: '/htaDocuments',
    component: HTADocuments,
    exact: true
  },
  {
    id: 'FDALettersWarnings',
    path: '/letters',
    component: Letters,
    exact: true
  },
  {
    id: 'comparison',
    path: '/comparison/:sources',
    component: Comparison,
    exact: true
  },
  {
    id: 'comparison-result',
    path: '/comparison/:sources/:category/:term',
    component: Comparison,
    exact: true
  },
  {
    id: 'reg360',
    path: ROUTE_REGULATORY_360,
    component: RegulatoryInformation,
    exact: true
  },
  {
    id: 'ariaSearchId',
    path: ROUTE_ARIA_MAIN_SEARCH_ID,
    component: Aria,
    exact: true
  },
  {
    id: 'aria',
    path: ROUTE_ARIA_MAIN,
    component: Aria,
    exact: true
  },
  {
    id: 'searchResults',
    path: ROUTE_SEARCH_RESULTS,
    component: SearchResults,
    exact: true
  },
  {
    id: 'cdp',
    path: ROUTE_CDP,
    component: CDP,
    exact: true
  },
  {
    id: 'specialityDesignation',
    path: ROUTE_SPECIALITY_DESIGNATION,
    component: SpecialityDesignation,
    exact: true
  },
  {
    id: 'specialityDesignationSearch',
    path: ROUTE_SPECIALITY_DESIGNATION_SEARCH,
    component: SpecialityDesignation,
    exact: true
  },
  {
    id: 'chatRIAReportReference',
    path: '/chatRIA/reports/reference/:type',
    component: ChatRIAReportReference,
    exact: true
  },
  {
    id: 'chmp',
    path: ROUTE_CHMP_MODULE,
    component: Chmp,
    exact: true
  },
  // add pdco route here
  {
    id: 'pdco',
    path: '/pdco',
    component: PDCO,
    exact: true
  },
  {
    id: 'resources',
    path: '/resource',
    component: Resources,
    exact: true
  },
  {
    id: 'app-results',
    path: '/search/:payload',
    component: SearchResults,
    exact: true
  },
  {
    id: 'drugDevelopmentTools',
    path: '/drug-development-tools',
    component: DrugDevelopmentTools,
    exact: true
  },
  {
    id: 'notifications',
    path: '/notifications/:type',
    component: Notifications,
    exact: true
  },
  {
    id: 'orphanDesignations',
    path: '/orphan-designations',
    component: OrphanDesignations,
    exact: true
  }
];

export const WITHOUT_NAV_ROUTES = [
  {
    id: 'helpMe',
    path: '/help',
    component: HelpPage,
    exact: true
  },
  {
    id: 'videoTutorials',
    path: '/help/videos/:id',
    component: VideoItem,
    exact: true
  },
  {
    id: 'report',
    path: '/reports',
    component: PdfLayout,
    exact: true
  }
];
