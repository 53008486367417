import React, { useCallback, useContext } from 'react';

// mui
import uniqueId from 'lodash/uniqueId';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// store
import store from '../../../store/CanadaConsultation';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

// utils
import formatCount from '../../../utils/formatCount';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';
// for reporting feature change this
import { CANADA_CONSULTATION_REPORT_QUERIES } from '../const';
import { PIECHARTCOLOR } from '../../../components/CustomComponents/Graphs/constant';

// components
import StatsCountTile from '../../../helpers/baseComponents/StatsCountTile';
import CustomBarGraph from '../../../components/CustomComponents/Graphs/CustomBarGraph';
import CustomPieChart from '../../../components/CustomComponents/Graphs/CustomPieChart';

// styles
import styles from '../styles/Insights.styles';

const CanadaConsultationInsights: React.FC = () => {
  const { CanadaConsultationState } = useContext(store);
  const { state, dispatch } = useContext(GlobalStore) as any;

  const addToReport = useCallback(
    async (id: string) => {
      const addedReport = CANADA_CONSULTATION_REPORT_QUERIES.find(
        (report: any) => report.id === id
      );
      const random = Math.floor(Math.random() * 1000);
      dispatch({
        type: GlobalActions.ADD_TEMPLATE_TO_REPORT,
        value: {
          templateType: 'custom',
          chartType: addedReport?.graph,
          sectionType: 'CHART',
          id: uniqueId(`canada-consultation_${random}_`),
          style: {
            placement: {
              h: 40,
              w: 12
            },
            graphStyle: addedReport?.graphStyle,
            title: addedReport?.title
          },
          data: {
            source: 'canada-consultation',
            search_type: 'consultation',
            disableSelectionSource: true,
            filters: CanadaConsultationState.availableFilters,
            query: addedReport?.query
          }
        }
      });
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Successfully added to Report.', color: 'success' }
      });
    },
    [state.cartItems, dispatch, CanadaConsultationState.filters]
  );

  return (
    <Stack>
      <Grid container>
        <Grid item xs={12} lg={3}>
          {Object.keys(CanadaConsultationState.canadaConsultationStats).length > 0 &&
          !CanadaConsultationState.loading ? (
            <>
              <StatsCountTile
                text='Total Guidances'
                count={CanadaConsultationState.canadaConsultationStats.totalConsultation}
                toolTipValue=''
              />
              <StatsCountTile
                text='Published in Last 30 Days'
                count={CanadaConsultationState.canadaConsultationStats.totalLastThirtyDays}
                toolTipValue=''
              />
              <StatsCountTile
                text='Total Document'
                count={truncateCountAboveMillions(
                  CanadaConsultationState.canadaConsultationStats.pdfCount
                )}
                toolTipValue={formatCount(CanadaConsultationState.canadaConsultationStats.pdfCount)}
              />
              <StatsCountTile
                text='Total Pages'
                count={truncateCountAboveMillions(
                  CanadaConsultationState.canadaConsultationStats.pageCount
                )}
                toolTipValue={formatCount(
                  CanadaConsultationState.canadaConsultationStats.pageCount
                )}
              />
            </>
          ) : (
            <Box ml={5} display='flex' flexDirection='column' sx={styles.cards}>
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
              <Skeleton sx={styles.paper} />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box display='flex' flexDirection='column' sx={styles.box}>
            {/* # Graph 1 Consultation Documents by Issue Year */}
            <Box display='flex' flexDirection='column'>
              <Box display='flex' justifyContent='space-between'>
                {Object.keys(CanadaConsultationState.issueDate).length > 0 &&
                !CanadaConsultationState.loading ? (
                  <>
                    <Typography sx={styles.graphHeading}>Consultations by Start Year</Typography>
                    <Button
                      onClick={() => addToReport('consultation_documents_by_year')}
                      variant='text'
                      fullWidth={false}
                      sx={styles.addToReport}>
                      <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                        + Add to Report
                      </Typography>
                    </Button>
                  </>
                ) : (
                  <>
                    <Skeleton height={32} width={200} sx={styles.graphHeading} />
                    <Skeleton height={28} width={150} sx={styles.reportText} />
                  </>
                )}
              </Box>
              <Box
                p={2}
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={styles.barGraph}>
                {!CanadaConsultationState.loading ? (
                  <Box sx={{ width: '100%', height: '100%' }}>
                    {Object.keys(CanadaConsultationState?.issueDate).length > 0 ? (
                      <CustomBarGraph
                        keys={['value']}
                        index='id'
                        data={CanadaConsultationState?.issueDate}
                        paddings={0.8}
                        colors={['#B1BBE4']}
                        // @ts-ignore
                        tickRotationValue={25}
                        // @ts-ignore
                        labelTextColor='#ffffff'
                        // @ts-ignore
                        axisText='#A3A3A3'
                      />
                    ) : (
                      <Typography variant='subtitle2' sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                        No data
                      </Typography>
                    )}
                  </Box>
                ) : (
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-end'
                    alignItems='space-between'
                    sx={styles.loaderBox}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      flexWrap='wrap-reverse'
                      sx={styles.barGraphRect}>
                      <Skeleton variant='rectangular' height='90%' width='.2%' />
                      <Skeleton variant='rectangular' height='60%' width='3%' />
                      <Skeleton variant='rectangular' height='80%' width='3%' />
                      <Skeleton variant='rectangular' height='30%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='60%' width='3%' />
                      <Skeleton variant='rectangular' height='80%' width='3%' />
                      <Skeleton variant='rectangular' height='30%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='50%' width='3%' />
                      <Skeleton variant='rectangular' height='90%' width='3%' />
                      <Skeleton variant='rectangular' height='60%' width='3%' />
                      <Skeleton variant='rectangular' height='80%' width='3%' />
                    </Box>
                    <Skeleton variant='rectangular' height='.2%' width='100%' />
                  </Box>
                )}
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' sx={styles.pieCharts}>
              {/* # Graph 2 Pie chart  Status */}
              <Box sx={styles.pieContainers} display='flex' flexDirection='column'>
                <Box display='flex' justifyContent='space-between'>
                  {Object.keys(CanadaConsultationState.donutCharts).length > 0 &&
                  !CanadaConsultationState.loading ? (
                    <>
                      <Typography sx={styles.graphHeading}>
                        {CanadaConsultationState.donutCharts[0]?.label}
                      </Typography>
                      <Button
                        onClick={() => addToReport('status_pie_chart')}
                        variant='text'
                        fullWidth={false}
                        sx={styles.addToReport}>
                        <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                          + Add to Report
                        </Typography>
                      </Button>
                    </>
                  ) : (
                    <>
                      <Skeleton height={32} width={200} sx={styles.graphHeading} />
                      <Skeleton height={24} width={150} sx={styles.reportText} />
                    </>
                  )}
                </Box>
                {!CanadaConsultationState.loading ? (
                  <Box sx={{ width: '100%', height: '100%' }}>
                    {Object.keys(CanadaConsultationState.donutCharts).length > 0 ? (
                      <CustomPieChart
                        data={CanadaConsultationState.donutCharts[0]?.children}
                        innerRadius={0.7}
                        colors={PIECHARTCOLOR}
                        fillText='#707070'
                        radialLabelsTextColor='#707070'
                        sliceLabelsTextColor={{ from: 'color' }}
                        margin={styles.pieChartMargin}
                        arcLabelsSkipAngle={1}
                        arcLinkLabelsSkipAngle={1}
                      />
                    ) : (
                      'No data'
                    )}
                  </Box>
                ) : (
                  <Box display='flex' alignSelf='center' pt={4}>
                    <CircularProgress
                      value={90}
                      thickness={5}
                      size='10rem'
                      variant='indeterminate'
                      sx={{
                        color: '#adadac',
                        opacity: 0.5
                      }}
                    />
                  </Box>
                )}
              </Box>
              {/* # Graph 3 Pie chart  subject */}
              <Box sx={styles.pieContainers} display='flex' flexDirection='column'>
                <Box display='flex' justifyContent='space-between'>
                  {Object.keys(CanadaConsultationState.donutCharts).length > 1 &&
                  !CanadaConsultationState.loading ? (
                    <>
                      <Typography sx={styles.graphHeading}>
                        {CanadaConsultationState.donutCharts[1]?.label}
                      </Typography>
                      <Button
                        onClick={() => addToReport('subject_pie_chart')}
                        variant='text'
                        fullWidth={false}
                        sx={styles.addToReport}>
                        <Typography align='right' variant='subtitle2' sx={styles.reportText}>
                          + Add to Report
                        </Typography>
                      </Button>
                    </>
                  ) : (
                    <>
                      <Skeleton height={32} width={200} sx={styles.graphHeading} />
                      <Skeleton height={24} width={150} sx={styles.reportText} />
                    </>
                  )}
                </Box>
                {!CanadaConsultationState.loading ? (
                  <Box sx={{ width: '100%', height: '100%' }}>
                    {Object.keys(CanadaConsultationState.donutCharts).length > 1 ? (
                      <CustomPieChart
                        data={CanadaConsultationState.donutCharts[1]?.children}
                        innerRadius={0.7}
                        colors={PIECHARTCOLOR}
                        fillText='#707070'
                        radialLabelsTextColor='#707070'
                        sliceLabelsTextColor={{ from: 'color' }}
                        margin={styles.pieChartMargin}
                        arcLabelsSkipAngle={1}
                        arcLinkLabelsSkipAngle={1}
                      />
                    ) : (
                      'No data'
                    )}
                  </Box>
                ) : (
                  <Box display='flex' alignSelf='center' pt={4}>
                    <CircularProgress
                      value={90}
                      thickness={5}
                      size='10rem'
                      variant='indeterminate'
                      sx={{
                        color: '#adadac',
                        opacity: 0.5
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default React.memo(CanadaConsultationInsights);
