import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { Button } from '@mui/material';

const DeleteConfirmation = ({
  open,
  onAccept,
  onReject
}: {
  open: boolean;
  onAccept: any;
  onReject: any;
}) => {
  return (
    <Dialog open={open} onClose={onReject} maxWidth='xs' fullWidth>
      <DialogContent>
        <DialogContentText variant='h3'>
          Are you sure you want to remove this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onReject}
          variant='contained'
          sx={{ textTransform: 'initial !important', color: 'white !important' }}>
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          onClick={onAccept}
          sx={{ textTransform: 'initial !important', color: 'white !important' }}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteConfirmation;
