import React, { useEffect, useMemo, useRef, useState, MouseEvent, useCallback } from 'react';
import {
  Skeleton,
  Stack,
  DialogContent,
  Box,
  Divider,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  Drawer,
  ListItemText,
  Link
} from '@mui/material';
import { Auth } from 'aws-amplify';
import axios, { CancelTokenSource } from 'axios';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { uniqueId } from 'lodash';
import PDFMerger from 'pdf-merger-js/browser';
import { v4 } from 'uuid';
import { getSectionPageNumbers, highlightPDF } from '../../api/pages/Aria';
import styles from './styles/PDFComparisonDialog.style';
import { ALL_SECTIONS_FILTER_OPTIONS } from '../../pages/ResultsPage/utils/constants';
import { sourceFlagMapping } from '../../pages/SearchResults/constants';
import { RoundedMenu } from '../ui/Menu';
import {
  AngleRightIcon,
  DownloadIcon,
  AngleLeftIcon,
  SubHeaderPipeGreyIcon,
  CrossIcon,
  ArrowDownIcon,
  Reg360Icon
} from '../../assets/svgs/Icons';
import {
  downloadCollatedSummary,
  getApplicationsByLabelSection
} from '../../pages/SearchResults/data/collatedSummary';
import downloadLabelFile from '../../pages/SearchResults/utils/downloadLabelFile';
import DropDownButton from '../../pages/SearchResults/components/DropDownButton';
import CompileSectionDialog from './CompileSectionDialog';
import DownloadLoadingModal from './DownloadModal';
import palette from '../../themev5/palette';
import getAllSections from './utils';

interface SectionData {
  section: string;
  page: number;
}

interface PDFSections {
  applicationNumber: string;
  sectionData: Array<SectionData>;
}

interface PDFDataItem {
  url: string;
  heading: string;
  page: number;
  applicationNumber: string;
  submissionNumber?: string;
  submissionType?: string;
  center?: string;
  applno?: string;
  identifier?: string;
  source?: string;
  latest_submission_type?: string;
  latest_submission_number?: string;
  s3_label_url?: string;
  package_insert_num?: string;
  title?: string;
  groupTitle?: string;
}

interface PDFComparisonDialogPropsType {
  open: boolean;
  keywords?: Array<string>;
  pdfData: Array<PDFDataItem>;
  onClose: Function;
  onSwap: Function;
  sources: any;
}

export interface ComparisonCategory {
  id: string;
  source: string;
  sections: string[];
}

const PDFComparisonDialog: React.FC<PDFComparisonDialogPropsType> = ({
  open,
  keywords = [],
  pdfData,
  onClose,
  onSwap,
  sources = {}
}) => {
  const [finalLinks, setFinalLinks] = useState<Array<PDFDataItem>>([]);
  const [loading, setLoading] = useState(false);
  const [pdfSectionData, setPdfSectionData] = useState<Array<PDFSections>>([]);
  const [selectedSection, setSelectedSection] = useState<string>('');
  const [openSwapMenu, setOpenSwapMenu] = useState<{ [k: number]: boolean }>({});
  const [isDownloadOptionsOpen, setIsDownloadOptionsOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [secondaryAnchorEl, setSecondaryAnchorEl] = useState<null | HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [cancel, setCancel] = useState<CancelTokenSource | null>(null);
  const [progress, setProgress] = useState(0);
  const [docLoader, setDocLoader] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  const [downloadType, setDownloadType] = useState<string>('html');
  const [jumpToSections, setJumpToSections] = useState<any>([]);
  const [isSectionCompileModalOpen, setIsSectionCompileModalOpen] = useState(false);
  const [applicationSources, setApplicationSources] = useState<any>([]);
  const [sectionCompileData, setSectionCompileData] = useState<any>([]);
  const [categories, setCategories] = useState<ComparisonCategory[]>([
    {
      id: v4(),
      source: '',
      sections: []
    }
  ]);

  const handleSwapMenuClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setOpenSwapMenu(prevState => {
      return { ...prevState, [index]: true };
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenSwapMenu({});
    setAnchorEl(null);
  };

  const swapPDFs = (
    currentIndex: number,
    targetIndex: number,
    currentHeading: string,
    targetHeading: string
  ) => {
    const finalLinksNew = [...finalLinks];
    const temp = finalLinksNew[currentIndex];
    finalLinksNew[currentIndex] = finalLinksNew[targetIndex];
    finalLinksNew[targetIndex] = temp;
    setFinalLinks(finalLinksNew);
    onSwap({
      message: `Swapped ${currentHeading} with ${targetHeading}`,
      currentIndex,
      targetIndex
    });
    handleClose();
  };

  const highLightPdfs = async () => {
    setLoading(true);
    const newLinks = await Promise.all(
      pdfData.map(async data => {
        const payload = {
          s3_link: data.url,
          text_to_highlight: keywords,
          phrase_to_highlight: keywords[0]
        };
        const res = await highlightPDF(payload);
        if (res?.status === 200) {
          const file = new Blob([res.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          return {
            url: fileURL,
            heading: data.heading,
            page: data.page,
            applicationNumber: data.identifier,
            identifier: data.identifier,
            groupTitle: data.groupTitle,
            title: data.title,
            source: data?.source
          };
        }
        return data;
      })
    );
    setLoading(false);
    return newLinks;
  };

  const handleSectionChange = (option: any) => {
    const newSectionTitle = option.value;

    const newJumpToSectionList: any = [];

    jumpToSections.forEach((item: any) => {
      newJumpToSectionList.push({
        label: item.value === newSectionTitle ? option.value : item.value,
        value: item.value === newSectionTitle ? option.value : item.value,
        selected: item.value === newSectionTitle
      });
    });

    setJumpToSections(newJumpToSectionList);

    setSelectedSection(newSectionTitle);
    if (pdfSectionData.length && finalLinks.length) {
      const newFinalLinks = [...finalLinks].map(prevPDFData => ({
        ...prevPDFData,
        page:
          pdfSectionData
            .find(x => x?.applicationNumber === prevPDFData?.identifier)
            ?.sectionData?.find(data => data.section === newSectionTitle)?.page ?? 1
      }));
      setFinalLinks(newFinalLinks);
    }
  };

  const updateJumpToSectionOptions = () => {
    setJumpToSections(
      ALL_SECTIONS_FILTER_OPTIONS[Object.keys(sources)[0]]?.map(section => ({
        label: section?.value,
        value: section?.value
      }))
    );
  };

  useEffect(() => {
    updateJumpToSectionOptions();

    const newApplicationSources = Array.from(new Set(pdfData.map((item: any) => item.source)));

    setApplicationSources(newApplicationSources);

    if (newApplicationSources.length > 0) {
      setCategories([
        {
          id: v4(),
          source: newApplicationSources[0],
          sections: []
        }
      ]);
    }

    // Function to get all the page numbers of specific sections
    const getSectionPageNumberData = async () => {
      const payload: any = { applications: [] };
      payload.applications = pdfData.map(data => {
        const properties = {
          application_number: data.identifier,
          center: data?.center?.toLowerCase() ?? '',
          source: data.source
        };
        switch (data.source?.toLowerCase()) {
          case 'ca':
            return {
              ...properties,
              submission_type: data.latest_submission_type,
              submission_number: data.latest_submission_number
            };
          case 'eu':
          case 'us':
          case 'hpra':
            return {
              ...properties,
              s3_path: data.url?.split('s3.amazonaws.com/')[1].split('?')[0]
            };
          case 'jp':
            return {
              ...properties,
              s3_path: data.url?.split('s3.amazonaws.com/')[1].split('?')[0],
              package_insert_num: data.package_insert_num
            };
          default:
            return null;
        }
      });

      const result: any = await getSectionPageNumbers(payload);

      if (result?.data?.status === 200 && !result?.data?.error) {
        const newSectionData: PDFSections[] = Object.entries(result?.data?.body)
          ?.filter(([, sections]) => !!sections)
          ?.map(([applNo, sections]) => ({
            applicationNumber: applNo,
            sectionData: Object.entries(sections as any)?.map(([sectionTitle, pageNumber]) => ({
              section: sectionTitle,
              page: pageNumber as number
            }))
          }));
        setPdfSectionData(newSectionData);
      }
    };

    if (keywords.length && pdfData.length) {
      // @ts-ignore
      highLightPdfs().then(res => setFinalLinks(res));
    } else {
      setFinalLinks(pdfData);
    }
    getSectionPageNumberData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 50 && count !== 0) {
        setProgress(progress + 100 / count);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [progress, count]);

  const downloadComparisonHandler = async (payload: any) => {
    const cancelToken = axios.CancelToken.source();
    setCancel(cancelToken);

    try {
      setErrorMsg(false);
      setDocLoader(true);
      setProgress(0);
      setCount(finalLinks.length);

      const res = await downloadCollatedSummary(payload, cancelToken.token);

      if (res?.status === 200) {
        downloadLabelFile(res.data, payload.download_type);
        setProgress(100);
      } else {
        setErrorMsg(true);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setErrorMsg(true);
      if (!cancel) {
        setDocLoader(false);
      }
    }
  };

  const handleDownloadComparison = async (type: string) => {
    setDownloadType(type);
    setIsDownloadOptionsOpen(false);
    const user = await Auth.currentUserInfo();
    const username = user.attributes['custom:user'] ?? '';

    const payload = {
      username,
      download_type: type,
      applications: sectionCompileData
    };

    downloadComparisonHandler(payload);
  };

  const handleDownloadOptions = (event: MouseEvent<HTMLElement>) => {
    setSecondaryAnchorEl(event.currentTarget);
    setIsDownloadOptionsOpen(true);
  };

  const handleCancelCall = useCallback(async () => {
    if (cancel) {
      cancel.cancel('Operation canceled by the user.');
      setCancel(null);
    }
  }, [cancel]);

  const getSourceWiseApplicationsWithSection = () => {
    const allApplications = pdfData;

    if (!allApplications) {
      return [];
    }

    const sourceWiseApplications: any = {};

    categories?.forEach((label: any) => {
      const sourceApplications = sourceWiseApplications[label.source];

      if (sourceApplications) {
        sourceApplications.sections = [...sourceApplications.sections, label.sections];
      } else {
        sourceWiseApplications[label.source] = {
          sections: label.sections,
          applications:
            allApplications?.map((currentApplication: any) => currentApplication.identifier) || []
        };
      }
    });

    return sourceWiseApplications;
  };

  const mergedPdfUrl = async (pdfUrls: any) => {
    if (!pdfUrls) {
      return '';
    }

    const pdfMerger = new PDFMerger();

    // eslint-disable-next-line no-restricted-syntax
    for (const links of Object.values(pdfUrls)) {
      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get(links as any, {
        responseType: 'arraybuffer'
      });
      // eslint-disable-next-line no-await-in-loop
      await pdfMerger.add(Buffer.from(response.data, 'binary'));
    }
    const mergedPdf = await pdfMerger.saveAsBlob();
    const url = URL.createObjectURL(mergedPdf);

    return url;
  };

  const handleCompilingSections = async () => {
    try {
      setLoading(true);
      setIsSectionCompileModalOpen(false);

      const payload = getSourceWiseApplicationsWithSection();

      const res = await getApplicationsByLabelSection(payload);

      if (res?.status !== 200 && !res?.data?.Success?.result) {
        return;
      }

      const applications = res?.data?.Success?.result ?? [];
      // Sort S3 Links based on the order of the sections
      applications.forEach((application: any) => {
        const defaultSectionOrder = getAllSections(application?.source);
        const sortedS3Links: any = {};
        defaultSectionOrder.forEach((section: any) => {
          if (section in (application?.s3_links ?? {})) {
            sortedS3Links[section] = application?.s3_links[section] ?? '';
          }
        });

        // eslint-disable-next-line no-param-reassign
        application.s3_links = sortedS3Links;
      });
      setSectionCompileData(applications);

      const newFinalLinks = await Promise.all(
        applications.map(async (application: any) => {
          const url = await mergedPdfUrl(application?.s3_links ?? '');
          return {
            url,
            heading: `${application?.number} - ${application?.name}`,
            page: undefined,
            applicationNumber: application?.number ?? '',
            identifier: application?.number ?? '',
            groupTitle: application?.active_ingredient ?? '',
            title: application?.name ?? '',
            source: application?.source ?? ''
          };
        })
      );

      setFinalLinks(newFinalLinks);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const resetCategories = () => {
    if (applicationSources.length > 0) {
      setCategories([
        {
          id: v4(),
          source: applicationSources[0],
          sections: []
        }
      ]);
    }
  };

  const swapMenuItems = useMemo(() => {
    const menuData = finalLinks.map((item, index) => {
      const oneSwapMenuData = finalLinks.map((menuItem, menuIndex) => {
        const { heading, title, identifier } = item;
        const parentIndex = index;
        if (parentIndex !== menuIndex)
          return (
            <MenuItem
              onClick={() =>
                swapPDFs(
                  parentIndex,
                  menuIndex,
                  `${title} (${identifier})`,
                  `${menuItem.title} (${menuItem.identifier})`
                )
              }
              key={`${heading}_${menuItem?.applicationNumber}`}>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography>
                  {title} ({identifier})
                </Typography>
                <SwapHorizontalCircleIcon sx={styles.rearrangeMenuIcon} />
                <Typography>
                  {menuItem.title} ({menuItem.identifier})
                </Typography>
              </Stack>
            </MenuItem>
          );
        return null;
      });
      return oneSwapMenuData;
    });
    return menuData;
  }, [finalLinks]);

  const move = (direction: string) => {
    if (containerRef.current === null || containerRef.current === undefined) return;
    containerRef.current.scrollTo({
      left:
        direction === 'left'
          ? containerRef.current.scrollLeft - 200
          : containerRef.current.scrollLeft + 200,
      behavior: 'smooth'
    });
  };

  const resetJumpToSection = () => {
    updateJumpToSectionOptions();
    setSelectedSection('');
    if (finalLinks?.length) {
      const newFinalLinks = [...finalLinks]?.map(prevPDFData => ({
        ...prevPDFData,
        page: 1
      }));
      setFinalLinks(newFinalLinks);
    }
  };

  const resetCompileSection = () => {
    setSectionCompileData([]);
    resetJumpToSection();
    setFinalLinks(pdfData);
    resetCategories();
  };

  return (
    <Box>
      <Drawer
        open={open}
        PaperProps={{
          style: styles.dialogPaper
        }}
        anchor='bottom'
        onClose={() => onClose()}>
        <Box sx={{ pb: 0 }}>
          <Box sx={styles.headerWrapper}>
            <Box sx={styles.titleWrapper}>
              <Typography sx={styles.title}>View Latest Labels</Typography>
              <Box sx={styles.actionsWrapper}>
                <Tooltip title='Close'>
                  <IconButton onClick={() => onClose()}>
                    <CrossIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box sx={styles.dividerWrapper}>
              <Divider sx={styles.divider} />
            </Box>
          </Box>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{ px: '24px' }}>
            {sources && Object.keys(sources).length === 1 ? (
              <Box
                sx={{
                  ...(sectionCompileData.length > 0 && { pointerEvents: 'none', opacity: 0.5 }),
                  display: 'flex'
                }}>
                <DropDownButton
                  label={selectedSection?.length > 0 ? selectedSection : 'Jump to a section'}
                  options={jumpToSections}
                  onOptionSelect={handleSectionChange}
                  tooltip={
                    sectionCompileData.length < 1
                      ? 'Navigate simultaneously to the same section in all selected documents for side-by-side comparison'
                      : 'Not available in compiled sections view'
                  }
                />
                {selectedSection?.length > 0 && (
                  <Typography sx={styles.resetJumpToSectionButton} onClick={resetJumpToSection}>
                    Reset
                  </Typography>
                )}
              </Box>
            ) : (
              <Box />
            )}
            <Stack direction='row' alignItems='center' spacing={1}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '11px',
                  alignItems: 'center'
                }}>
                <Typography sx={styles.sliderText}>Slider</Typography>
                <Tooltip title='Scroll left'>
                  <IconButton onClick={() => move('left')}>
                    <AngleLeftIcon sx={styles.angleIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Scroll right'>
                  <IconButton onClick={() => move('right')}>
                    <AngleRightIcon sx={styles.angleIcon} />
                  </IconButton>
                </Tooltip>
              </Box>
              <SubHeaderPipeGreyIcon sx={styles.pipeIcon1} />
              {sectionCompileData.length < 1 ? (
                <Tooltip title='Choose specific sections from each document to create a new, consolidated document for easier comparison and analysis between products.'>
                  <Typography
                    onClick={() => setIsSectionCompileModalOpen(true)}
                    sx={styles.compileSectionText}>
                    Compile Multiple Section(s)
                  </Typography>
                </Tooltip>
              ) : (
                <Box
                  sx={styles.cancelCompileSectionWrapper}
                  onClick={() => setIsSectionCompileModalOpen(true)}>
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={event => {
                      event.stopPropagation();
                      resetCompileSection();
                    }}>
                    <CancelIcon sx={styles.cancelIcon} />
                  </IconButton>
                  <Typography sx={styles.totalSectionsCompliedText}>
                    {categories.reduce((acc: any, curr: any) => acc + curr.sections.length, 0)}{' '}
                    sections compiled
                  </Typography>
                  <ArrowDownIcon sx={styles.arrowDownIcon} />
                </Box>
              )}
              <SubHeaderPipeGreyIcon sx={styles.pipeIcon2} />
              <Tooltip
                title={
                  sectionCompileData.length < 1
                    ? 'Select and compile multiple sections first to enable download.'
                    : 'Download the compiled section summary as an HTML or Docx file.'
                }>
                <IconButton
                  onClick={event => {
                    if (sectionCompileData.length < 1) {
                      return;
                    }
                    handleDownloadOptions(event);
                  }}>
                  <DownloadIcon
                    sx={{
                      height: '16px',
                      width: '16px',
                      ...(sectionCompileData.length < 1
                        ? { color: 'grey.400' }
                        : { color: 'grey.800' })
                    }}
                  />
                </IconButton>
              </Tooltip>
              <RoundedMenu
                open={isDownloadOptionsOpen}
                anchorEl={secondaryAnchorEl}
                onClose={() => {
                  setSecondaryAnchorEl(null);
                  setIsDownloadOptionsOpen(false);
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                sx={styles.roundedMenu}>
                <MenuItem disabled sx={{ p: '6px 24px' }}>
                  <ListItemText>
                    <Typography sx={{ color: 'gray.800', fontSize: 12, fontWeight: 400 }}>
                      Select Download Option:
                    </Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem sx={{ p: '6px 24px' }} onClick={() => handleDownloadComparison('html')}>
                  HTML file
                </MenuItem>
                <MenuItem sx={{ p: '6px 24px' }} onClick={() => handleDownloadComparison('docx')}>
                  Docx file (.docx)
                </MenuItem>
              </RoundedMenu>
            </Stack>
          </Box>
          <Divider sx={{ mx: '24px' }} />
        </Box>
        <DialogContent sx={{ padding: '10px 24px 20px 24px' }}>
          <Box sx={styles.contentBox} ref={containerRef}>
            {(loading &&
              pdfData.map(() => (
                <Box sx={styles.pdfContainer} key={uniqueId()}>
                  <Skeleton variant='text' width='30%' height={75} />
                  <Skeleton variant='rectangular' width='90%' height={150} />
                  <Skeleton variant='text' width='30%' />
                  <Skeleton variant='rectangular' width='90%' height={150} />
                  <Skeleton variant='text' width='30%' />
                  <Skeleton variant='rectangular' width='90%' height={150} />
                  <Skeleton variant='text' width='30%' />
                  <Skeleton variant='rectangular' width='90%' height={150} />
                  <Skeleton variant='text' width='30%' />
                  <Skeleton variant='rectangular' width='90%' height={150} />
                </Box>
              ))) ||
              finalLinks.map((item, index) => {
                const FlagComponent = sourceFlagMapping[item?.source?.toLowerCase() ?? ''];

                return (
                  <Box sx={styles.pdfContainer} key={item.applicationNumber}>
                    <Typography
                      sx={styles.missingSectionText}
                      textAlign='center'
                      visibility={item.page === 0 ? 'visible' : 'hidden'}>
                      <span style={{ fontWeight: 'bold' }}>{selectedSection}</span> is not available
                      in this label
                    </Typography>
                    <Box
                      sx={{
                        ...styles.pdfWrapper,
                        border: `${item.page === 0 ? `1px solid ${palette.red[900]}` : 'none'}`
                      }}>
                      <Box sx={styles.pdfContainerHeader}>
                        <Tooltip title={`${item.title} (${item.identifier})`}>
                          <Box display='flex' alignItems='baseline' gap='4px'>
                            <Typography sx={styles.applicationName} noWrap>
                              {item.title} ({item.identifier})
                            </Typography>
                            <FlagComponent sx={styles.flagIcon} />
                          </Box>
                        </Tooltip>
                        <Box sx={styles.callToActionsWrapper}>
                          <Tooltip title='Open Regulatory 360 view' arrow placement='right'>
                            <Link
                              href={`/regulatory360/core/${item.identifier}/${item.source}`}
                              target='_blank'>
                              <Reg360Icon sx={{ height: '24px', width: '24px' }} />
                            </Link>
                          </Tooltip>
                          <Tooltip title='Click to rearrange' arrow placement='right'>
                            <IconButton
                              aria-controls={openSwapMenu[index] ? 'long-menu' : undefined}
                              aria-expanded={openSwapMenu[index] ? 'true' : undefined}
                              sx={styles.rearrangeIcon}
                              aria-haspopup='true'
                              onClick={e => handleSwapMenuClick(e, index)}>
                              <SwapHorizontalCircleIcon sx={{ height: '20px', width: '20px' }} />
                            </IconButton>
                          </Tooltip>

                          <Menu
                            aria-labelledby='demo-positioned-button'
                            anchorEl={anchorEl}
                            open={openSwapMenu[index]}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left'
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left'
                            }}>
                            <MenuItem key={`${item?.applicationNumber}_title`} disabled>
                              Rearrange labels
                            </MenuItem>
                            {swapMenuItems[index]}
                          </Menu>
                        </Box>
                      </Box>
                      <iframe
                        key={`${item.url}#view=fitH&navpanes=0&scrollbar=0&page=${item?.page || 1}`}
                        src={`${item.url}#view=fitH&navpanes=0&scrollbar=0&page=${item?.page || 1}`}
                        title={item.heading}
                        width='100%'
                        height='100%'
                      />
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </DialogContent>
      </Drawer>

      <DownloadLoadingModal
        docLoader={docLoader}
        setDocLoader={setDocLoader}
        progress={progress}
        errorMsg={errorMsg}
        setErrorMsg={setErrorMsg}
        downloadType={downloadType}
        handleDownloadComparison={handleDownloadComparison}
        handleCancelCall={handleCancelCall}
      />

      <CompileSectionDialog
        open={isSectionCompileModalOpen}
        onClose={() => setIsSectionCompileModalOpen(false)}
        applicationSources={applicationSources}
        handleCompilingSections={handleCompilingSections}
        categories={categories}
        setCategories={setCategories}
        resetCategories={resetCategories}
      />
    </Box>
  );
};

PDFComparisonDialog.defaultProps = {
  keywords: []
};

export default PDFComparisonDialog;
