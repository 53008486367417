import React, { lazy, Suspense } from 'react';

// material ui imports
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Tooltip } from '@material-ui/core';

// styles
import CardDetailsReportStyles from '../styles/CardDetailsReport.styles';
import SuspenseCircularLoader from '../../../helpers/SuspenseCircularLoader';

const GaugeChart = lazy(() => import('react-gauge-chart'));

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <Box role='tabpanel' hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

const CardDetailsReport = ({ data }) => {
  const classes = CardDetailsReportStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getDialValue = value => {
    var result = 0;
    if (value >= 0 && value <= 365) {
      result = getPercentage(value, 0, 365, 0);
    } else if (value > 366 && value < 720) {
      result = getPercentage(value, 366, 720, 0.33);
    } else if (value >= 721) {
      if (value <= 1095) {
        result = getPercentage(value, 721, 1095, 0.66);
      } else if (value >= 1095) {
        result = getPercentage(value, 721, value, 0.66);
      }
    }
    return result;
  };

  function getPercentage(value, lowerBound, upperBound, segmentAdjustment) {
    return (value - lowerBound) / (upperBound - lowerBound) / 3 + segmentAdjustment;
  }

  return (
    <Box display='flex' flexDirection='column' className={classes.cardDetails}>
      <Paper elevation={0} className={classes.paper}>
        <Typography variant='h3'>
          Details for :{' '}
          <span className={classes.highlightText}>
            {data.heading} - {data.tradeName}
          </span>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Box display='flex' flexDirection='column'>
              <Box display='flex' flexDirection='row' justifyContent='flex-start'>
                <Typography variant='subtitle1' className={classes.title}>
                  {data.activeIngredients}
                </Typography>
              </Box>
              <Typography
                variant='subtitle1'
                align='left'
                className={data.appl_type === 'NDA' ? classes.nameNDA : classes.nameBLA}
              >
                {data.tradeName} {data.applno}
              </Typography>
              <Box mt={1} display='flex' flexDirection='row' justifyContent='space-between'>
                <Box display='flex' flexDirection='row' alignItems='center'>
                  <Box display='flex' alignItems='center' className={classes.tagText}>
                    <FiberManualRecordIcon className={classes.icon} />
                    <Typography variant='body2'>{data.formulation}</Typography>
                  </Box>
                  <Box display='flex' alignItems='center' className={classes.tagText}>
                    <FiberManualRecordIcon className={classes.icon} />
                    <Typography variant='body2'>{data.route}</Typography>
                  </Box>
                  <Box display='flex' alignItems='center' className={classes.tagText}>
                    <FiberManualRecordIcon className={classes.icon} />
                    <Typography variant='body2'>{data.approvalPathway}</Typography>
                  </Box>
                  <Box ml={3} display='flex'>
                    <Avatar
                      className={
                        data.Accelerated_approval === 'No' ? classes.avatarLight : classes.avatar
                      }
                    >
                      AA
                    </Avatar>
                    <Avatar
                      className={data.Breakthrough === 'No' ? classes.avatarLight : classes.avatar}
                    >
                      BT
                    </Avatar>
                    <Avatar
                      className={data.Fasttrack === 'No' ? classes.avatarLight : classes.avatar}
                    >
                      FT
                    </Avatar>
                    <Avatar className={classes.avatarLight}>OR</Avatar>
                  </Box>
                </Box>
              </Box>
              <Typography align='left' variant='body2' className={classes.description}>
                {data.indications}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.meterHeading}>Approval Time (days)</Box>
            <Box
              className={classes.meter}
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              {data.time_to_approval !== 'N/A' ? (
                <Box>
                  <Suspense fallback={<SuspenseCircularLoader />}>
                    <GaugeChart
                      id='gauge-chart5'
                      nrOfLevels={20}
                      needleColor='#00A178'
                      colors={['#46B163', '#F8B518', '#FF5A48']}
                      percent={getDialValue(data.time_to_approval)}
                      arcPadding={0.05}
                      arcWidth={0.45}
                      hideText={true}
                      needleBaseColor='#06C66C'
                      arcWidth={0.5}
                    />
                  </Suspense>
                  <Box className={classes.adminDate}>{data.time_to_approval} Days</Box>
                </Box>
              ) : (
                <Typography> Capture in Progress</Typography>
              )}
            </Box>
          </Grid>
        </Grid>
        <AppBar position='static' color='transparent' elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            classes={{ indicator: classes.indicator }}
            className={classes.tabs}
            aria-label='tabs'
          >
            <Tab
              label='Overall'
              className={value === 0 ? classes.activeTab : classes.inactiveTab}
            />
            {data.patent.length > 0 ? (
              <Tab
                label='Patents'
                className={value === 1 ? classes.activeTab : classes.inactiveTab}
              />
            ) : (
              <Tooltip title={'No Active Patents Info'} aria-label='Button Link'>
                <Tab label='Patents (0)' className={classes.inactiveTab} disabled />
              </Tooltip>
            )}
            {data.exclusivity.length > 0 ? (
              <Tab
                label='Exclusivity'
                className={value === 2 ? classes.activeTab : classes.inactiveTab}
              />
            ) : (
              <Tooltip title={'No Active Exclusivity Info'} aria-label='Button Link'>
                <Tab label='Exclusivity (0)' className={classes.inactiveTab} disabled />
              </Tooltip>
            )}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2} className={classes.headers}>
            <Grid item xs={2}>
              <Typography align='left' className={classes.heading}>
                Products
              </Typography>
            </Grid>
            {Object.keys(data.products).map((item, index) => {
              return (
                <Grid item xs={2}>
                  <Typography align='left' className={classes.heading}>
                    {item}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
          <Grid container spacing={2} className={classes.box}>
            <Grid item xs={2}>
              <Typography align='left' className={classes.description}>
                Reference Status
              </Typography>
            </Grid>
            {Object.keys(data.products).map((item, index) => {
              return (
                <Grid item xs={2}>
                  <Box display='flex' justifyContent='flex-start'>
                    <Avatar
                      className={
                        data.products[item].rld === 'Yes' ? classes.avatar : classes.avatarLight
                      }
                    >
                      RLD
                    </Avatar>
                    <Avatar
                      className={
                        data.products[item].rs === 'Yes' ? classes.avatar : classes.avatarLight
                      }
                    >
                      RS
                    </Avatar>
                    <Avatar
                      className={
                        data.products[item].teCode === 'Yes' ? classes.avatar : classes.avatarLight
                      }
                    >
                      TE
                    </Avatar>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Grid container spacing={2} className={classes.box}>
            <Grid item xs={2}>
              <Typography align='left' className={classes.description}>
                Strength
              </Typography>
            </Grid>
            {Object.keys(data.products).map((item, index) => {
              return (
                <Grid item xs={2}>
                  <Typography align='left' className={classes.description}>
                    {data.products[item].Strength}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
          <Grid container spacing={2} className={classes.box}>
            <Grid item xs={2}>
              <Typography align='left' className={classes.description}>
                Marketing Status
              </Typography>
            </Grid>
            {Object.keys(data.products).map((item, index) => {
              return (
                <Grid item xs={2}>
                  <Typography align='left' className={classes.description}>
                    {data.products[item].marketingstatus}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={2} className={classes.headers}>
            <Grid item xs={2}>
              <Typography align='left' className={classes.heading}>
                Patent Number
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography align='left' className={classes.heading}>
                Code
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align='left' className={classes.heading}>
                Definition
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography align='left' className={classes.heading}>
                Expiration Date
              </Typography>
            </Grid>
            {data.patent[0]?.product_no.map((item, index) => {
              return (
                <Grid item xs={1}>
                  <Typography align='left' className={classes.heading}>
                    {item}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
          {data.patent?.map((item, index) => {
            return (
              <Grid container spacing={2} className={classes.box}>
                <Grid item xs={2}>
                  <Typography align='left' className={classes.description}>
                    {item.patent_no}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography align='left' className={classes.description}>
                    {item.patent_use_code}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography align='left' className={classes.description}>
                    {item.patent_definition}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography align='left' className={classes.description}>
                    {item.patent_expire_date}
                  </Typography>
                </Grid>
                {item.product_no.map(num => {
                  return (
                    <Grid item xs={1}>
                      <Typography align='left' className={classes.description}>
                        Yes
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}{' '}
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container spacing={2} className={classes.headers}>
            <Grid item xs={2}>
              <Typography align='left' className={classes.heading}>
                Code
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align='left' className={classes.heading}>
                Definition
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography align='left' className={classes.heading}>
                Expiration Date
              </Typography>
            </Grid>
            {data.exclusivity[0]?.product_no.map(item => {
              return (
                <Grid item xs={1}>
                  <Typography align='left' className={classes.heading}>
                    {item}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
          {data.exclusivity?.map(item => {
            return (
              <Grid container spacing={2} className={classes.box}>
                <Grid item xs={2}>
                  <Typography align='left' className={classes.description}>
                    {item.exclusivity_code}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography align='left' className={classes.description}>
                    {item.exclusivity_definition}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography align='left' className={classes.description}>
                    {item.exclusivity_date}
                  </Typography>
                </Grid>
                {item.product_no.map(num => {
                  return (
                    <Grid item xs={1}>
                      <Typography align='left' className={classes.description}>
                        Yes
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
        </TabPanel>
      </Paper>
    </Box>
  );
};

export default CardDetailsReport;
