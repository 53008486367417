/* eslint-disable react/require-default-props */
import React, { useEffect } from "react";
import { Divider, Stack } from "@mui/material";
import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import comparisonClasses from "./styles/LabelSelection.style";
import plus from "../../Images/plusWhite.svg";
import useDetectNonChromeBrowser from "../../hooks/useDetectNonChromeBrowser";

interface DraftableComparisonViewPropsType {
  viewerURL: string;
  parentApplicationNumber: string;
  childApplicationNumber: string;
  parentApplicationName?: string;
  childApplicationName?: string;
  onNewCompare: () => void;
  downloadFile: () => void;
  showNewCompare: boolean;
  showFooter?: boolean;
}

const DraftableComparisonView: React.FC<DraftableComparisonViewPropsType> = ({
  viewerURL,
  onNewCompare,
  downloadFile,
  parentApplicationNumber,
  childApplicationNumber,
  parentApplicationName = '',
  childApplicationName = '',
  showNewCompare = true,
  showFooter = true
}) => {
  const { detectBrowserAndDisplayDisclaimer } = useDetectNonChromeBrowser();

  useEffect(() => {
    detectBrowserAndDisplayDisclaimer();
  }, []);

  return (
    <Stack>
      {/* header titles for iframe pdfs */}
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{ width: '100%' }}>
        <Stack direction='row' justifyContent='center' width='50%'>
          <Tooltip title={parentApplicationName || ''}>
            <Typography variant='subtitle2' sx={comparisonClasses.titleTab} align='center' noWrap>
              {parentApplicationNumber}
            </Typography>
          </Tooltip>
        </Stack>
        <Stack direction='row' justifyContent='center' width='50%'>
          <Tooltip title={childApplicationName || ''}>
            <Typography variant='subtitle2' sx={comparisonClasses.titleTab} align='center' noWrap>
              {childApplicationNumber}
            </Typography>
          </Tooltip>
        </Stack>

        <Stack />
      </Stack>

      {/* draftable embedded iframe */}
      <Box sx={{ height: '75vh', border: '1px solid gray' }}>
        <iframe
          style={comparisonClasses.compareIFrame}
          title='compareDocuments'
          src={viewerURL}
          allowFullScreen
        />
      </Box>

      {/* footer */}
      {showFooter && (
        <Stack mt={2} sx={{ width: '100%' }} direction='row' alignContent='flex-end'>
          <Divider />
          {showNewCompare && (
            <Box sx={comparisonClasses.compareN} onClick={onNewCompare}>
              <img style={{ width: '15px' }} alt='compareLogo' src={plus} />
              <Typography sx={comparisonClasses.labelTitle}>New Compare</Typography>
            </Box>
          )}
          <Box sx={comparisonClasses.compareN} onClick={downloadFile}>
            <Typography sx={comparisonClasses.labelTitle}>Download Comparison</Typography>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export default React.memo(DraftableComparisonView);
