import { memo, useContext, useState, MouseEvent } from 'react';
import { Button, Tooltip, Typography, Box } from '@mui/material';
import ResultsStore from '../../../store/SearchResults';
import ResultActions from '../../../store/SearchResults/actions';
import RESULT_VIEW_TYPES from '../components/constants';
import { ComparisonIcon } from '../../../assets/svgs/Icons';
import { RoundedMenu } from '../../../components/ui/Menu';
import styles from '../styles/Buttons.styles';
import { COMPARE_LABEL_METHODS } from '../constants';

const CompareLabelsButton = () => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isComparisonMethodMenuOpen, setIsComparisonMethodMenuOpen] = useState(false);

  const showComparison = () => {
    const keys = Object.keys(resultsState.applicationResults);
    return !(
      (keys.length === 1 && keys.includes('us-devices')) ||
      (keys.length === 2 && keys.includes('us-devices'))
    );
  };

  const isDownloadLabelSummaryOn = () => {
    return (
      resultsState?.collatedSummarySelectedApplications?.length > 0 ||
      resultsState?.collatedSummaryApplications?.length > 0
    );
  };

  const handleCompareLabels = (event: MouseEvent<HTMLElement>) => {
    setIsComparisonMethodMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleComparisonMethod = (method: string) => {
    const selectedComparisonMethod = COMPARE_LABEL_METHODS[method];

    resultsDispatch({
      type: ResultActions.SET_LABEL_COMPARISON_SELECTION_METHOD,
      value: {
        limit: selectedComparisonMethod.limit,
        value: selectedComparisonMethod.value
      }
    });
    resultsDispatch({
      type: ResultActions.SET_LABEL_COMPARISON_SELECTION,
      value: true
    });
    resultsDispatch({
      type: ResultActions.SET_TOGGLE_VIEW_TYPE,
      value: 'grid'
    });
    setIsComparisonMethodMenuOpen(false);
  };

  return resultsState.viewType === RESULT_VIEW_TYPES.APPLICATION && showComparison() ? (
    <>
      <Tooltip title='Compare labels between products' placement='left'>
        <Button
          startIcon={
            <ComparisonIcon
              sx={
                resultsState.isContentLoading
                  ? styles.primaryButtonIconLoading
                  : styles.primaryButtonIcon
              }
            />
          }
          disabled={isDownloadLabelSummaryOn() || resultsState.isContentLoading}
          onClick={handleCompareLabels}
          sx={styles.primaryButton}>
          Compare Labels
        </Button>
      </Tooltip>
      <RoundedMenu
        open={isComparisonMethodMenuOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setIsComparisonMethodMenuOpen(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={styles.compareLabels.menuContainer}>
        <Typography sx={styles.compareLabels.methodDescription}>
          Select a comparison method
        </Typography>
        {Object.keys(COMPARE_LABEL_METHODS).map(method => {
          const Icon = COMPARE_LABEL_METHODS[method].icon;

          return (
            <Box
              key={method}
              sx={styles.compareLabels.methodContainer}
              onClick={() => handleComparisonMethod(method)}>
              <Box>
                <Icon sx={styles.compareLabels.methodIcon} />
              </Box>
              <Box>
                <Typography sx={styles.compareLabels.methodTitle}>
                  {COMPARE_LABEL_METHODS[method].title}
                </Typography>
                <Typography sx={styles.compareLabels.methodDescription}>
                  {COMPARE_LABEL_METHODS[method].description}
                </Typography>
                <Typography sx={styles.compareLabels.methodDescription}>
                  Limit: {COMPARE_LABEL_METHODS[method].limit} docs
                </Typography>
              </Box>
            </Box>
          );
        })}
      </RoundedMenu>
    </>
  ) : null;
};
export default memo(CompareLabelsButton);
