/* eslint-disable import/no-named-as-default */
import React, { useEffect, useContext, useState } from 'react';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';

import RegulatoryInformationStore from '../../../store/RegulatoryInformation';
import Actions from '../../../store/RegulatoryInformation/actions';

// Components and Interfaces
import CustomTimeline from '../../../components/Timeline/CustomTimeline';
import EmptyPage from '../../../components/EmptyPage/EmptyPage';
import { UrlParameters } from '../../../types/reactRouterDom';
import { fileNomenclature } from '../../../components/csvdownload/csvPropsType';
// Constants
import { TIMELINE_COLUMNS } from './constants';

// APIs
import regulatoryInformationAPI from '../../../api/pages/Regulatory360';

const Rems = () => {
  const { regulatoryState, regulatoryDispatch } = useContext(RegulatoryInformationStore);
  const [isLoading, setLoading] = useState<boolean>(false);

  const { module, source } = useParams<UrlParameters>();
  const [noContent, setNoContent] = useState(false);
  const [filename, setfilename] = useState<fileNomenclature>({
    prefix: module || 'core',
    module: source || 'us',
    postfix: new Date().toISOString().split('T')[0],
    additional: '_approval_history',
    extension: 'csv'
  });

  useEffect(() => {
    if (
      regulatoryState?.rems.length === 0 &&
      regulatoryState?.applicationDetails?.application_number &&
      regulatoryState?.db
    ) {
      const getData = async () => {
        setLoading(true);
        const data: any = await regulatoryInformationAPI(
          regulatoryState.db,
          regulatoryState?.applicationDetails?.application_number,
          'rems'
        );
        if (!data?.data?.body?.length) {
          setNoContent(true);
        } else {
          regulatoryDispatch({ type: Actions.SET_REMS, value: data.data.body });
        }
        setLoading(false);
      };
      getData();
      setfilename({
        prefix: 'core',
        module: 'us',
        postfix: new Date().toISOString(),
        additional: '_rems',
        extension: 'csv'
      });
    }
  }, [regulatoryState.db, regulatoryState.applicationDetails, source]);

  if (noContent) {
    return <EmptyPage message='No content for REMS' />;
  }
  return (
    <Box>
      <CustomTimeline
        columns={TIMELINE_COLUMNS[regulatoryState.db]}
        timeline={regulatoryState.rems}
        triggeredBy='rems'
        filename={filename}
        isLoading={isLoading}
        source={source || 'us'}
      />
    </Box>
  );
};

export default React.memo(Rems);
