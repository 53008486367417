import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

// material ui
import { Badge, Box, IconButton, Stack, Tooltip } from '@mui/material';
import { DownloadIcon, FilterEmptyIcon, FilterFilledIcon } from '../../../assets/svgs/Icons';

// store
import Insights from '../components/Insights';
import DesignationStore from '../../../store/Designation';
import DesignationActions from '../../../store/Designation/actions';
import SpecialityTimeLine from '../components/SpecialityTimeLine';
import FullScreenProcessing from '../components/FullScreenProcessing';
import useSpecialityData from '../hooks/useSpecialityData';

import SubHeader from '../components/SubHeader';
import BottomDrawer from '../../../components/CustomComponents/BottomDrawer';
import FilterSidebar from '../../../components/NewFilters/FilterSidebar';
import DesignationSearchBox from '../components/DesignationSearchBox';
import { SelectedCategoryOptions } from '../../../components/NewFilters/types';
import { Stat } from '../../SearchResults/types';

const DesignationTabs = () => {
  const { designationState, designationDispatch } = useContext(DesignationStore);
  const { tab } = useParams() as any;
  const location = useLocation();
  const { search }: any = useParams();

  const [selectedTab, setSelectedTab] = useState(tab || 'insights') as any;
  const [openFilter, setOpenFilter] = useState(false);
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState({});
  const [modalOpen, setModalOpen] = useState('');
  const [previousSelectedCategoryOptions, setPreviousSelectedCategoryOptions] =
    useState<SelectedCategoryOptions>({});
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState<SelectedCategoryOptions>(
    {}
  );

  const {
    getDesignationData,
    insights,
    designation,
    isLoading,
    designationFilters,
    isFiltersApplied
  } = useSpecialityData();

  const designationStats: Stat[] = [];

  if (insights) {
    designationStats.push({ title: 'Applications', value: insights?.applications || 0 });
    designationStats.push({
      title: 'Active Ingredients',
      value: insights?.active_ingredients || 0
    });
    designationStats.push({
      title: 'Original Designations',
      value: insights?.orig_designations || 0
    });
  }

  const handleFilterApply = useCallback(
    async (currentFilters: any) => {
      await getDesignationData(search, currentFilters);
      setOpenFilter(false);
    },
    [setOpenFilter, getDesignationData, search]
  );

  const handleDownload = () => {
    designationDispatch({ type: DesignationActions.OPEN_ALL_SEE_MORE, value: true });
  };

  useEffect(() => {
    const curTab = new URLSearchParams(location.search).get('tab') || 'insights';
    if (curTab !== selectedTab) {
      setSelectedTab(curTab);
    }
    const curView = new URLSearchParams(location.search).get('view') || 'vertical';
    if (curView !== designationState.view) {
      designationDispatch({
        type: DesignationActions.SET_VIEW,
        value: curView
      });
    }
    const curSort = new URLSearchParams(location.search).get('sort') || 'asc';
    if (curSort !== designationState.sortBy) {
      designationDispatch({
        type: DesignationActions.SORT_BY,
        value: curSort
      });
    }
  }, [location.search]);
  useEffect(() => {
    getDesignationData(search, {});
  }, [search]);

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = (newSelection: any) => {
    setAppliedFilter({ ...appliedFilter, ...newSelection });
    setIsVisualizeOpen(false);
  };

  return (
    <>
      <SubHeader
        title='US Expedited Pathway'
        subTitle='Showing Results For Indications'
        stats={designationStats}
        handleVisualizeOpen={handleVisualizeOpen}
        setModalOpen={setModalOpen}
        isLoading={isLoading}
        searchQuery={search}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '14px',
            '& .MuiIconButton-root': {
              padding: '0 !important'
            },
            '& svg': {
              height: '18px',
              width: '18px'
            },
            mr: '12px'
          }}>
          <Box>
            <Tooltip title='Filters'>
              <IconButton onClick={() => setOpenFilter(true)}>
                <Badge
                  variant='dot'
                  invisible={isFiltersApplied}
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: '#D43B2B'
                    }
                  }}>
                  {isFiltersApplied ? (
                    <FilterEmptyIcon sx={{ color: 'gray.650' }} />
                  ) : (
                    <FilterFilledIcon sx={{ color: 'primary.650' }} />
                  )}
                </Badge>
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Tooltip title='Save as png'>
              <IconButton onClick={handleDownload}>
                <DownloadIcon sx={{ height: '18px', width: '18px', color: 'gray.650' }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
      </SubHeader>

      <Box display='flex' flexDirection='column' width='inherit' pt={2}>
        <FullScreenProcessing
          loading={isLoading}
          open={designationState.openAllSeeMore}
          message='Generating image...'
        />
        {!isLoading && <SpecialityTimeLine drugs={designation} />}
      </Box>

      <BottomDrawer isDrawerOpen={isVisualizeOpen} closeDrawer={closeDrawer} hideReportIcon>
        <Insights insights={insights} />
      </BottomDrawer>

      {openFilter && (
        <FilterSidebar
          data={designationFilters}
          applyFilters={handleFilterApply}
          isLoading={isLoading}
          selectedCategoryOptions={selectedCategoryOptions}
          setShowFilterSidebar={() => setOpenFilter(false)}
          showFilterSidebar={openFilter}
          setSelectedCategoryOptions={setSelectedCategoryOptions}
          previousSelectedCategoryOptions={previousSelectedCategoryOptions}
          setPreviousSelectedCategoryOptions={setPreviousSelectedCategoryOptions}
        />
      )}

      {modalOpen === 'expeditedPathway' && (
        <DesignationSearchBox modalOpen={modalOpen} setModalOpen={setModalOpen} />
      )}
    </>
  );
};

export default memo(DesignationTabs);
