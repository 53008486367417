import React from 'react';
import { Box, Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';

import BarChart from '../CustomComponents/Graphs/BarChart';
import PieChart from '../CustomComponents/Graphs/PieChart';

import { STYLE_COMPONENTS } from './Widgets';
import StyleComponent from './StyleComponent';

export const widgetMappings: any = {
  BAR: 'bar-widget',
  PIE: 'pie-widget'
};

const GRAPH_COMPONENTS: any = {
  BAR: BarChart,
  PIE: PieChart
};
const StyleDialogBox = ({
  graphsData,
  style,
  styleFilter,
  openStyle,
  data,
  handleClose,
  chartType,
  handleAddToReport,
  handleStyleChange
}: any) => {
  const GraphComponent = GRAPH_COMPONENTS[chartType];
  return (
    <Dialog
      open={openStyle}
      onClose={handleClose}
      disableAutoFocus={!openStyle}
      fullWidth
      sx={{ '.MuiDialog-paper': { maxWidth: '1350px' } }}>
      <DialogContent sx={{ overflow: 'hidden', p: '0px' }}>
        <Grid container lg={12} alignItems='center' sx={{ width: '100%' }}>
          <Grid item lg={6}>
            <Box sx={{ height: '400px' }}>
              {graphsData && graphsData?.data && (
                <GraphComponent
                  data={graphsData?.Data || graphsData?.data}
                  key='Title'
                  indexKey='Title'
                  keys={graphsData?.keyList || []}
                  yAxis={graphsData?.yAxis || []}
                  title={style.title}
                  columnNameMapping={graphsData?.attribute_name}
                  order={data?.query?.columns}
                  style={styleFilter}
                  referenceText={style.references}
                />
              )}
            </Box>
          </Grid>
          <Grid item lg={6} sx={{ borderLeft: '2px solid', borderColor: '#A8A8A8' }}>
            <Box>
              <Box p={2} sx={{ backgroundColor: '#D9D9D980' }}>
                <Typography sx={{ fontSize: 20, color: 'black.main' }}>Apply Styles:</Typography>
              </Box>
              <Box>
                <Box p={1} sx={{ overflow: 'auto', height: '50vh' }}>
                  <StyleComponent
                    styleList={STYLE_COMPONENTS[widgetMappings[chartType]]}
                    handleStyleChange={handleStyleChange}
                    stylesFilters={styleFilter}
                  />
                </Box>
              </Box>
              <Box m={2} display='flex' flexDirection='row' justifyContent='flex-end'>
                <Button onClick={handleClose} sx={{ mr: 2, fontWeight: 600 }}>
                  Close
                </Button>
                <Button
                  variant='contained'
                  onClick={handleAddToReport}
                  sx={{ ml: 1, color: 'white.main', fontWeight: 600 }}>
                  Save
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(StyleDialogBox);
