const styles = {
  main: {
    width: '100%',
    height: '100%',
    '& .sun-editor': {
      height: '100%',
      border: 'none'
    },
    '& .se-navigation': {
      display: 'none'
    },
    '& .sun-editor-editable': {
      fontFamily: 'Mulish',
      fontSize: 16,
      lineHeight: 1.5,
      overflow: 'hidden !important',
      p: '0 !important'
    },
    '.sun-editor .se-resizing-bar': {
      display: 'none'
    },
    '& .se-container': {
      height: '100%',
      position: 'relative'
    },
    '& .sun-editor .se-wrapper': {
      width: '100%',
      height: '100%',
      top: '0px',
      position: 'absolute !important',
      border: 'none',
      overflow: 'hidden',
      fontFamily: 'Mulish',
      fontSize: 16,
      lineHeight: 1.5
    },
    '& .se-wrapper-inner': {
      height: '100% !important'
    },
    '& .se-toolbar': {
      position: 'absolute',
      transform: 'translate(0, -102%)',
      bottom: '0',
      right: '0',
      zIndex: '1002'
    },
    '& .sun-editor-editable table thead': {
      display: 'contents'
    }
  }
};

export default styles;
