import { Box } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, lazy, Suspense } from 'react';

import { addLabels, getTrialsGraphData } from '../utils';
import useCDPData from '../hooks/useCDPData';
import ReferenceText from '../../../components/Report/components/ReferenceText';
import SuspenseCircularLoader from '../../../helpers/SuspenseCircularLoader';

const TimeSeriesGraph = lazy(
  () => import('../../../components/CustomComponents/Graphs/CustomTimeSeries')
);

const CDPReport = ({
  fdaFilters,
  trialsFiltersCNF,
  applicationNumber,
  activeIngredient,
  labels = [],
  showTrialsInSBAS = false,
  ctFiltersDateRange = {},
  referenceText = {},
  approvals = []
}: any) => {
  const graphRef = useRef();
  const approvalsSelected = useRef(false);
  const { responseData, getCDPData, selectSubmissionLabelOptions } = useCDPData();

  const addRef = useCallback((ref: any) => {
    graphRef.current = ref;
  }, []);

  const { barGraphData, nctIds } = useMemo(() => {
    const { barGraphData: bData, nctIds: ids } = getTrialsGraphData(responseData, showTrialsInSBAS);
    addLabels(bData, labels);

    return { barGraphData: bData, nctIds: ids };
  }, [responseData.barGraphData, showTrialsInSBAS]);

  useEffect(() => {
    getCDPData(trialsFiltersCNF, fdaFilters, applicationNumber, ctFiltersDateRange, true);
  }, [fdaFilters, trialsFiltersCNF, applicationNumber, activeIngredient, ctFiltersDateRange]);

  useEffect(() => {
    const plotlyTester = document.getElementById('js-plotly-tester');
    if (plotlyTester) {
      plotlyTester.style.display = 'none';
    }
  }, [barGraphData]);

  useEffect(() => {
    if (
      responseData.barGraphData &&
      responseData.barGraphData.length > 0 &&
      !approvalsSelected.current
    ) {
      approvalsSelected.current = true;
      selectSubmissionLabelOptions(approvals.map((opt: any) => opt.submission));
    }
  }, [approvals, responseData.barGraphData]);

  return (
    <Box height='100%' width='100%' sx={{ display: 'inline-block', pageBreakInside: 'avoid' }}>
      <Suspense fallback={<SuspenseCircularLoader />}>
        <TimeSeriesGraph
          ref={addRef}
          barGraphData={barGraphData}
          shapes={responseData.shapes}
          annotations={responseData.annotations}
          yAxisIds={nctIds}
          onHorizontalBarClick={null}
          y2AxisIds={responseData.negativeAxisNCTIds}
          plotConfig={{}}
        />
      </Suspense>
      {referenceText.show && <ReferenceText referenceText={referenceText} />}
    </Box>
  );
};

export default React.memo(CDPReport);
